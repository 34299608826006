import React, { FC, useState, useCallback, useMemo } from 'react';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { useTask } from 'src/models/bids/BidTask/hooks';
import { TenderFile } from 'src/shared/Tender';
import { PrivateFilesView } from '../PrivateFilesView';
import { useGetBidAttachments } from 'src/models/bids/BidAttachments/hooks';
import { HighlightsByPage } from 'src/models/bids/BidTask/types';
import classNames from 'classnames';
import { DocumentViewer } from 'src/shared';
import { IHighlight } from 'react-pdf-highlighter';
import { usePreviewFile } from 'src/models/procurements/Tender/hooks';
import { getFileNameFromPath } from 'src/models/procurements/helpers';
import { trackRelatedSources } from 'src/segment/events';

interface DocumentViewerState {
  highlights: IHighlight[];
  previewUrl: string;
  fileTitle: string;
}

interface RelatedSources {
  eventSource: 'preview' | 'editor';
}

export const RelatedSources: FC<RelatedSources> = ({ eventSource }) => {
  const { data: task } = useTask();
  const { url, previewFile, closePreview } = usePreviewFile(task?.originId);
  const { data: bidAttachmentsData } = useGetBidAttachments(task?.bidId ?? '');
  const { t } = useTranslation();

  const references = task?.secondaryReferences;
  const hasReferences = references && references.length > 0 && task;

  const [viewerState, setViewerState] = useState<DocumentViewerState>({
    highlights: [],
    previewUrl: '',
    fileTitle: ''
  });

  const openDocumentViewer = useCallback(
    (highlights: IHighlight[], previewUrl: string, fileUrl: string, fileTitle: string) => {
      trackRelatedSources(eventSource);

      if (fileUrl) {
        setViewerState({ highlights, previewUrl: fileUrl, fileTitle });
      } else {
        previewFile(previewUrl);
        setViewerState({ highlights, previewUrl: '', fileTitle });
      }
    },
    [eventSource, previewFile]
  );

  const closeDocumentViewer = (): void => {
    setViewerState({ highlights: [], previewUrl: '', fileTitle: '' });
    closePreview();
  };

  const renderSourcesByPage = useCallback(
    (pageHighlights: HighlightsByPage[], targetPath: string, fileUrl: string, title: string) => {
      return pageHighlights.map((ph, i) => (
        <button
          className={classNames(styles.sourceLink)}
          onClick={() => {
            openDocumentViewer(ph.highlights, targetPath, fileUrl, title);
          }}
          key={i}
        >
          {`(s. ${ph.page})`}
        </button>
      ));
    },
    [openDocumentViewer]
  );

  const sourcesList = useMemo(() => {
    return references?.map(source => {
      const pathMatch = source?.reference?.path.match(/\/(.+)/);
      const fileId = pathMatch ? pathMatch[1] : null;
      const privateFile = bidAttachmentsData?.find(att => att.document.id === fileId);

      return (
        task && (
          <div className={styles.sourceItem} key={source.reference.path}>
            {privateFile ? (
              <PrivateFilesView
                attachment={privateFile}
                highlights={source?.allHighlights}
                trackHandler={() => trackRelatedSources(eventSource)}
              />
            ) : (
              <TenderFile
                procurementId={task.originId}
                targetPath={source.reference.path}
                eventSource="preview"
                pdfHighlights={source.allHighlights}
                trackHandler={() => trackRelatedSources(eventSource)}
              />
            )}
            {source.highlightsByPage.length > 0 && (
              <div className={styles.pageLinks}>
                {renderSourcesByPage(
                  source.highlightsByPage,
                  source.reference.path,
                  privateFile?.document.getUrl ?? '',
                  privateFile ? privateFile?.document.name : getFileNameFromPath(source.reference.path)
                )}
              </div>
            )}
          </div>
        )
      );
    });
  }, [references, bidAttachmentsData, task, renderSourcesByPage, eventSource]);

  return hasReferences ? (
    <>
      <div className={styles.sourcesList}>
        <span className={styles.title}>
          {references.length} {t('BiddingTool.sourcesCount')}
        </span>
        <div className={styles.filesContainer}>{sourcesList}</div>
      </div>
      {(viewerState.previewUrl || url) && (
        <DocumentViewer
          url={viewerState.previewUrl || url}
          title={viewerState.fileTitle}
          onClose={closeDocumentViewer}
          highlights={viewerState.highlights}
        />
      )}
    </>
  ) : (
    <span className={styles.noSource}>{t('BiddingTool.emptySources')}</span>
  );
};
