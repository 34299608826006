import React, { FC } from 'react';
import styles from './index.module.scss';
import { Header } from 'src/shared';
import { Skeleton } from 'src/common';
import { useIsMobile } from 'src/reactiveVars';

const PageSkeleton: FC = () => {
  const [isMobile] = useIsMobile();
  return (
    <div className={styles.container}>
      <Header />
      {!isMobile && (
        <aside className={styles.sidebar}>
          <Skeleton active loading />
        </aside>
      )}

      <main className={styles.main}>
        <Skeleton active loading />
      </main>
    </div>
  );
};

export default PageSkeleton;
