import { Grid } from '@tendium/component-library';
import { ContentLibraryType } from '@tendium/prom-types/schema';
import { useCurrentEditor } from '@tiptap/react';
import React, { FC, useMemo } from 'react';
import { Content } from 'src/models/contentLibrary/ContentLibrary';
import { useAllUsersAndTeams } from 'src/models/users/hooks';
import ContentLibraryCard from 'src/shared/ContentLibrary/ContentLibraryCard';
import { ContentLibraryDocumentCard } from 'src/shared/ContentLibrary/ContentLibraryDocumentCard';
import { useCurrentSidebar } from 'src/shared/InfoSidebar/hooks';
import { SidebarMode } from 'src/shared/InfoSidebar/types';
import styles from './index.module.scss';
import ToolboxHeader from '../ToolboxHeader';
import { faBooks } from '@fortawesome/pro-light-svg-icons';
import { useGetContentLibraryContentSourcesByIds } from 'src/models/contentLibrary/ContentLibrary/hooks';
import { Skeleton } from 'src/common';
import { useActiveCanvasTask } from 'src/models/canvas/hooks';
import { useTranslation } from 'react-i18next';

const ToolboxCanvasTaskSources: FC = () => {
  const { editor } = useCurrentEditor();
  const { task } = useActiveCanvasTask();
  const { t } = useTranslation();
  const sourceIds = useMemo(() => {
    if (!task?.answer?.sources) return [];
    return task.answer.sources.map(source => source.id);
  }, [task?.answer?.sources]);

  const { data, loading } = useGetContentLibraryContentSourcesByIds(
    sourceIds,
    !task || !task.answer?.sources || task.answer.sources.length === 0
  );
  const sidebarState = useCurrentSidebar();
  const allUsersAndTeams = useAllUsersAndTeams();

  const isSidebarMode = sidebarState?.mode === SidebarMode.CANVAS_TASK_SOURCES;

  if (!editor) return null;
  if (!!loading) return <Skeleton />;

  return (
    <>
      <ToolboxHeader icon={faBooks} title={t('BiddingTool.sourcesUsed')} />
      {data && !!data.length && (
        <Grid className={styles.grid}>
          {data.map(content =>
            content ? (
              content.type === ContentLibraryType.DOCUMENT ? (
                <Grid.Item key={content.id} cols={{ sm: 12, lg: !isSidebarMode ? 6 : undefined }}>
                  <ContentLibraryDocumentCard
                    key={content.id}
                    content={new Content(content, allUsersAndTeams)}
                    id={content.id}
                  />
                </Grid.Item>
              ) : (
                <Grid.Item key={content.id} cols={{ sm: 12, lg: !isSidebarMode ? 6 : undefined }}>
                  <ContentLibraryCard
                    key={content.id}
                    content={new Content(content, allUsersAndTeams)}
                    id={content.id}
                  />
                </Grid.Item>
              )
            ) : null
          )}
        </Grid>
      )}
    </>
  );
};

export default ToolboxCanvasTaskSources;
