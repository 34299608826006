import React, { FC, ReactNode } from 'react';
import styles from './index.module.scss';
import { useCloseSidebar, useCurrentSidebar } from '../hooks';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FullscreenOverlay, Tooltip } from 'src/common';
import { useIsMobile } from 'src/reactiveVars';
import { useTranslation } from 'react-i18next';

interface Props {
  children: ReactNode;
  className?: string;
  onClose?: () => void;
  closeBtnTooltip?: string;
}

export const SidebarView: FC<Props> = props => {
  const { children, className, onClose: onCloseFn, closeBtnTooltip } = props;
  const onClose = useCloseSidebar(onCloseFn);
  const sidebarState = useCurrentSidebar();
  const [isMobile] = useIsMobile();
  const { t } = useTranslation();
  const CloseButton = (
    <button className={styles.closeButton} onClick={onClose}>
      <FontAwesomeIcon icon={faTimes} className={styles.closeIcon} />
    </button>
  );

  if (isMobile)
    return (
      <FullscreenOverlay
        visible={!!sidebarState}
        onVisibleChange={onClose}
        overlayTitle={t('Common.preview')}
        overlayContent={children}
        overlayContentClassname={styles.overlayContentContainer}
      />
    );

  return (
    <div className={classNames(styles.preview, { [styles.isOpen]: sidebarState }, className)}>
      <div className={styles.container}>
        {closeBtnTooltip ? <Tooltip title={closeBtnTooltip}>{CloseButton}</Tooltip> : CloseButton}
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};

export default SidebarView;
