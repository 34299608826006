import { AccessControlPolicy } from 'src/models/acl/types';
import { trackWithSessionReplay } from '../helpers';

export function trackAddCallOffInbox(co: { id: string }): void {
  trackWithSessionReplay('CO add inbox', { co });
}

/** @todo should be used on a link-click, not on a page watch
 * - analytics.track => for recording user actions
 * - analytics.page => for listening page changes
 */
export function trackSwitchCallOffs(co: { id: string }): void {
  trackWithSessionReplay('CO switch call offs', { co });
}

export function trackUpdateInboxSubscribersPolicy(inboxId: string, policy: AccessControlPolicy): void {
  trackWithSessionReplay('Update inbox subscribers policy', { inboxId, policy });
}

export function trackUpdateInboxOwnersPolicy(inboxId: string, policy: AccessControlPolicy): void {
  trackWithSessionReplay('Update inbox owners policy', { inboxId, policy });
}

export function trackUpdateInboxSubscribersAndOwners(inboxId: string): void {
  trackWithSessionReplay('Update Inbox subscribers and owners', { inboxId });
}

export function trackUpdateInboxSubscribers(inboxId: string): void {
  trackWithSessionReplay('Update Inbox subscribers', { inboxId });
}

export function trackUpdateInboxOwners(inboxId: string): void {
  trackWithSessionReplay('Update Inbox owners', { inboxId });
}
