import React, { FC, useCallback } from 'react';
import styles from '../index.module.scss';
import classNames from 'classnames';
import { AssignedTo } from 'src/models/users/types';
import Assignee from 'src/shared/Assignee';

interface Props {
  user: AssignedTo;
  onClick: (user: AssignedTo) => void;
  onClose?: () => void;
}
export const UserButton: FC<Props> = props => {
  const { onClick, user, onClose } = props;

  const onClickButton = useCallback(() => {
    onClick(user);
    onClose && onClose();
  }, [onClick, onClose, user]);

  return (
    <button onClick={onClickButton} className={classNames(styles.button, styles.userButton)}>
      <Assignee assignedToData={user} size={'s'} />
    </button>
  );
};

export default UserButton;
