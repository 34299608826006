import { FileExportedSub } from '@tendium/prom-types/subscriptions';

export type EXPORT_STATUS_TYPE = 'pending' | 'finished';

export interface ExportFileStatus {
  [key: string]: { status: EXPORT_STATUS_TYPE | null; operationId: string | null };
}

export interface ApiExportFile {
  operationId: string;
  file: {
    name: string;
    url: string;
  } | null;
  error: string | null;
  eventType: FileExportedSub.ExportType;
  html?: string;
}

/**
 * This is the minimum duration that the notification will stay visible.
 * If the export operation completes in more than this time, the notification will stay visible for the actual duration of the export operation.
 */
export const LOADING_MIN_STARY_DURATION = 750; // milliseconds
/**
 * This is the maximum duration that the notification will stay visible.
 * If the export operation takes longer than this time, the notification will automatically close.
 */
export const LOADING_AUTO_CLOSE_DURATION = 180000; // milliseconds
