import { z } from 'zod';
import {
  isTenderVarsDiff,
  tendersSearchSchema,
  TendersSearchSchemaKeyType,
  TendersSearchSchemaType
} from '../tendersSearchSchema';

export const assignedFilterSchema = tendersSearchSchema.pick({ assignedTo: true });
export type AssignedFilterSchemaType = z.infer<typeof assignedFilterSchema>;
export const assignedFilterKeys = Object.keys(assignedFilterSchema.shape) as TendersSearchSchemaKeyType[];

export function isAssignedFilterDiff(vars1?: TendersSearchSchemaType, vars2?: TendersSearchSchemaType): boolean {
  return isTenderVarsDiff(assignedFilterKeys, vars1, vars2);
}
