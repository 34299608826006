import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import TenderMatchesItem from './MatchesItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faMemoCircleCheck, faRadar } from '@fortawesome/pro-light-svg-icons';
import { Tooltip } from 'src/common';
import classNames from 'classnames';
import { BlockTitle, InfoBox } from 'src/shared';
import { Translations } from 'src/lib/I18nService/types';
import { v4 } from 'uuid';
import { useIsMobile } from 'src/reactiveVars';
import { ITenderHighlight } from 'src/models/procurements/Preview/types';

interface Props {
  procurementId?: string;
  highlights?: ITenderHighlight[];
  matchedCpvs?: string[];
}
export const TenderMatches: FC<Props> = ({ procurementId, highlights, matchedCpvs }) => {
  const { t } = useTranslation();
  const { t: cpvT } = useTranslation(Translations.cpvCodes);
  const [isMobile] = useIsMobile();

  return !!highlights?.length || !!matchedCpvs?.length ? (
    <>
      {!!highlights?.length && (
        <>
          <BlockTitle
            title={t('Tenders.keywords')}
            icon={{ defaultIcon: faRadar, color: 'var(--ui-blue)' }}
            className={styles.blockTitle}
            noBorder
          />
          {highlights?.map(highlight => (
            <div className={styles.keyword} key={highlight.keywords.join('+')}>
              <div className={styles.header}>
                {!!highlight.keywords.length ? (
                  highlight.keywords.map((keyword, idx) => (
                    <span key={v4()}>
                      <span>{keyword}</span>
                      {idx !== highlight.keywords.length - 1 && (
                        <span className={styles.keywordsPlusLabel}>&nbsp;{t('Common.and')}&nbsp;</span>
                      )}
                    </span>
                  ))
                ) : (
                  <Tooltip title={t('Tenders.unrecognisedSectionTooltip')}>
                    {t('Tenders.unrecognisedSection')}
                    <FontAwesomeIcon icon={faInfoCircle} className={styles.unrecognisedIcon} />
                  </Tooltip>
                )}
              </div>
              {highlight.highlights.map((highlight, index) => (
                <TenderMatchesItem highlight={highlight} procurementId={procurementId} key={`highlight_${index}`} />
              ))}
            </div>
          ))}
        </>
      )}
      {!!matchedCpvs?.length && (
        <>
          <BlockTitle
            title={t('Tenders.cpvCodes')}
            icon={{ defaultIcon: faMemoCircleCheck, color: 'var(--ui-blue)' }}
            className={styles.blockTitle}
            noBorder
          />
          <div
            className={classNames(styles.boxes, {
              [styles.boxesMobile]: isMobile
            })}
          >
            {matchedCpvs?.map(cpvCode => (
              <InfoBox
                key={cpvCode}
                className={styles.match}
                title={cpvCode.split('-')[0]}
                value={cpvT(cpvCode.split('-')[0])}
              />
            ))}
          </div>
        </>
      )}
    </>
  ) : (
    <span className={styles.desc}>{t('Tenders.matchesEmpty')}</span>
  );
};

export default TenderMatches;
