import React, { FC, useCallback, useMemo, useState, JSX, useRef, ReactNode, useEffect, KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Select, Tooltip } from 'src/common';
import styles from './index.module.scss';
import { useToggle } from 'react-use';
import {
  useGetOrgs,
  DEFAULT_PAGE_SIZE,
  IApiOrganization,
  isContainOrgId,
  useOrganisationById,
  OrgType,
  getTranslatedOrgLabel
} from 'src/models/organisations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/pro-light-svg-icons';
import classNames from 'classnames';
import { SearchLogic } from '@tendium/prom-types';
import {
  ALL_DASHES_REGEX,
  ALL_SPACES_OR_COMMAS_REGEX,
  SPACE_OR_COMMA_REGEX,
  StrictUnion,
  isObject,
  useDebouncedValue
} from 'src/helpers';
import { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { OrganisationSelector } from '../OrgsSelector';

type FormValue = { value: string; label: ReactNode; key: string };
type FormState = { exact: FormValue[] /*LabelInValueType[]*/ };

type OrgSearchMode = 'single' | 'multiple';
type CommonProps = {
  disabled?: boolean;
  placeholder?: string;
  mode: OrgSearchMode;
  orgType: OrgType;
  allowFreeTextSearch?: boolean;
};
export type MultiOrgSearchProps = {
  exact: string[];
  prefix: string[];
  onSelect: (exact?: string[], prefix?: string[]) => void;
} & CommonProps;
export type SingleOrgSearchProps = {
  exact?: string;
  prefix?: string;
  onSelect: (exact?: string, prefix?: string, org?: IApiOrganization) => void;
} & CommonProps;
export type OrgsSearchProps = StrictUnion<MultiOrgSearchProps | SingleOrgSearchProps> & CommonProps;
function isSingleOrgSearch(u: unknown): u is SingleOrgSearchProps {
  return isObject(u) && 'mode' in u && u.mode === 'single';
}
function isMultiOrgSearch(u: unknown): u is MultiOrgSearchProps {
  return isObject(u) && 'mode' in u && u.mode === 'multiple';
}

export const OrgsSearch: FC<OrgsSearchProps> = props => {
  const { t } = useTranslation();
  const [form] = Form.useForm<FormState>();
  const [searchPhrase, setSearchPhrase] = useState('');
  const [isBulkMode, toggleBulkMode] = useToggle(false);
  const { data: currentOrg } = useOrganisationById(isSingleOrgSearch(props) && props.exact ? props.exact : undefined);
  const debouncedSearchPhrase = useDebouncedValue(searchPhrase.replace(ALL_DASHES_REGEX, ''), 150);
  const { data: orgs, loading } = useGetOrgs(debouncedSearchPhrase);

  const formRef = useRef(null);

  const initialValues = useMemo(() => {
    if (!isSingleOrgSearch(props)) return { exact: [] };

    const exactLabel = (() => {
      if (props.exact && currentOrg) {
        return [{ label: currentOrg.organisationName, value: currentOrg.organisationNumber }];
      }
      if (props.prefix) {
        return [{ label: props.prefix, value: props.exact ?? props.prefix }];
      }
      return [];
    })();

    return { exact: exactLabel };
  }, [currentOrg, props]);

  useEffect(() => {
    const formValue = form.getFieldValue('exact');
    if (isSingleOrgSearch(props) && formValue.length > 1) {
      form.setFieldsValue({ exact: formValue.slice(-1) });
    }
  }, [form, props]);

  const onValuesChange = useCallback(() => {
    form.submit();
  }, [form]);

  const onFinish = useCallback(
    (values: FormState) => {
      const { exact } = values;
      const formattedExact = exact
        .filter(item => item.value !== SearchLogic.PrefixMatch)
        .map(item => item.value.replace(ALL_DASHES_REGEX, '').split(ALL_SPACES_OR_COMMAS_REGEX))
        .flat();
      const uniqueExact = [...new Set(formattedExact)];
      const prefix = exact.some(item => item.value === SearchLogic.PrefixMatch) ? [searchPhrase] : [];
      const uniquePrefix = [...new Set(prefix)];

      if (isMultiOrgSearch(props)) {
        props.onSelect(uniqueExact, uniquePrefix);
        form.setFieldsValue({ exact: [] });
      } else {
        const singleExact = uniqueExact.length ? uniqueExact[uniqueExact.length - 1] : undefined;
        const singlePrefix = uniquePrefix.length ? uniquePrefix[uniquePrefix.length - 1] : undefined;
        props.onSelect(
          singleExact,
          singlePrefix,
          singleExact ? orgs.find(org => org.organisationNumber === singleExact) : undefined
        );
      }

      setSearchPhrase('');
      toggleBulkMode(false);
    },
    [searchPhrase, props, form, toggleBulkMode, orgs]
  );

  const onSearch = useCallback(
    (value: string) => {
      if (isContainOrgId(value) || isBulkMode) {
        if (value.match(SPACE_OR_COMMA_REGEX)) {
          const inputParts = value.split(SPACE_OR_COMMA_REGEX).filter(Boolean);
          const selectedValues: { value: string; label: ReactNode }[] = form.getFieldValue('exact');
          const companiesIdsSet = new Set([...selectedValues.map(item => item.value), ...inputParts]);

          form.setFieldsValue({ exact: [...companiesIdsSet].map(item => ({ value: item, label: item })) });

          setSearchPhrase('');
          isMultiOrgSearch(props) && toggleBulkMode(true);
        } else {
          setSearchPhrase(value);
        }
      } else {
        setSearchPhrase(value);
        toggleBulkMode(false);
      }
    },
    [form, isBulkMode, props, toggleBulkMode]
  );

  const onSelect = useCallback(() => {
    form.submit();
  }, [form]);

  const onDeselect = useCallback(() => {
    const selectedValues = form.getFieldValue('exact');

    if (!selectedValues.length) {
      setSearchPhrase('');
      toggleBulkMode(false);
    }
  }, [form, toggleBulkMode]);

  const onBlur = useCallback(() => {
    setSearchPhrase('');
    toggleBulkMode(false);
  }, [toggleBulkMode]);

  const onKeyDown = useCallback(
    (e: KeyboardEvent<HTMLFormElement>) => (e.key === 'Enter' && !isSingleOrgSearch(props) ? form.submit() : null),
    [form, props]
  );

  const isMore = useMemo(() => {
    return !isBulkMode && orgs.length % DEFAULT_PAGE_SIZE === 0 && orgs.length >= DEFAULT_PAGE_SIZE;
  }, [orgs.length, isBulkMode]);

  const getSelectOption = (org: IApiOrganization): JSX.Element => {
    const matchById = searchPhrase.replace(ALL_DASHES_REGEX, '') === org.organisationNumber;
    const splitMatch = new RegExp(searchPhrase || '', 'ig');
    const values = org.organisationName && searchPhrase ? org.organisationName.match(splitMatch) : [];
    const parts = org.organisationName ? org.organisationName.split(splitMatch) : null;
    const isDisabled = !!props.exact && props.exact.includes(org.organisationNumber);

    return (
      <Select.Option
        value={org.organisationNumber}
        key={org.organisationNumber}
        className={classNames(styles.option, { [styles.isDisabled]: isDisabled })}
        disabled={isDisabled}
      >
        <span className={styles.item}>
          <span className={styles.itemValue}>
            {isDisabled || matchById
              ? org.organisationName
              : parts?.map((item, idx) => {
                  return parts.length > idx + 1 ? (
                    <span key={idx}>
                      {item}
                      <span className={styles.bold}>{values?.[idx]}</span>
                    </span>
                  ) : (
                    item
                  );
                })}
          </span>
          <span className={styles.itemKey}>{org.organisationNumber}</span>
        </span>
      </Select.Option>
    );
  };

  const renderTag = useCallback(
    (tagProps: Omit<CustomTagProps, 'isMaxTag'>) => {
      const { label, value, onClose: onDefaultClose } = tagProps;
      const onPreventMouseDown = (event: React.MouseEvent<HTMLDivElement>): void => {
        event.preventDefault();
        event.stopPropagation();
      };
      const onClose = (): void => {
        form.submit();
        onDefaultClose();
      };

      return (
        <div className={styles.tag} key={value} onMouseDown={onPreventMouseDown}>
          <span className={styles.tagLabel}>{label}</span>
          <Tooltip title={isSingleOrgSearch(props) ? t('Common.Blocks.deleteValue') : ''}>
            <Button
              icon={<FontAwesomeIcon icon={faTimes} className={styles.tagIcon} />}
              type={'text'}
              onClick={isSingleOrgSearch(props) ? onClose : onDefaultClose}
              className={styles.tagButton}
            />
          </Tooltip>
        </div>
      );
    },
    [form, props, t]
  );

  const translatedOrg = useMemo(() => getTranslatedOrgLabel(t, props.orgType), [props.orgType, t]);

  const freeTextSearchOption =
    props.allowFreeTextSearch && searchPhrase ? (
      <Select.Option value={SearchLogic.PrefixMatch} className={styles.option}>
        <span className={styles.item}>
          <span className={styles.prefixValue}>{searchPhrase}</span>
          {!isSingleOrgSearch(props) && (
            <span className={styles.prefixKey}>
              <span>&nbsp;-&nbsp;</span>
              {isMultiOrgSearch(props)
                ? t('Common.freeTextSearch')
                : t('Common.FilterOrg.addPrefix', { org: translatedOrg })}
            </span>
          )}
        </span>
      </Select.Option>
    ) : null;

  const specificBuyersOption =
    !loading && debouncedSearchPhrase.length > 3 ? (
      <Select.Option value="disabled" disabled className={classNames(styles.option, styles.specificBuyers)}>
        <span>{props.placeholder ?? t('Common.FilterOrg.selectSpecific', { org: translatedOrg })}</span>
        {!orgs.length ? <div className={styles.notFound}>{t('Common.noResultsFound')}</div> : null}
      </Select.Option>
    ) : null;

  return (
    <div translate="no">
      <Form
        form={form}
        onFinish={onFinish}
        className={styles.form}
        layout={'vertical'}
        initialValues={initialValues}
        ref={formRef}
        tabIndex={0}
        onKeyDown={onKeyDown}
        onValuesChange={onValuesChange}
      >
        <Form.Item name={'exact'}>
          <OrganisationSelector
            mode={isBulkMode ? 'tags' : 'multiple'}
            className={classNames(styles.select, { [styles.isSingle]: isSingleOrgSearch(props) })}
            disabled={props.disabled}
            placeholder={t(`Common.FilterOrg.${isMultiOrgSearch(props) ? 'searchPlaceholder' : 'addPlaceholder'}`, {
              org: translatedOrg
            })}
            selectOption={orgs.map(org => getSelectOption(org))}
            renderTag={renderTag}
            showSearch={!isBulkMode}
            filterOption={false}
            searchPhrase={searchPhrase}
            debouncedSearchPhrase={debouncedSearchPhrase}
            showArrow
            suffixIcon={<FontAwesomeIcon className={styles.searchIcon} icon={faSearch} />}
            onBlur={isBulkMode ? undefined : onBlur}
            onSearch={onSearch}
            labelInValue
            open={!isBulkMode}
            onSelect={onSelect}
            onDeselect={onDeselect}
            freeTextSearch={freeTextSearchOption}
            specificBuyers={specificBuyersOption}
            isMore={isMore}
            dropdownClassName={styles.dropdown}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default OrgsSearch;
