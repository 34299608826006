import { LazyQueryResultTuple } from '@apollo/client/react/types/types';
import { ApolloError, MutationUpdaterFn, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { CREATE_TASKS, DELETE_TASK, GET_TASK, GET_TASK_GROUP, SET_TASK_ANSWER, UPDATE_TASK } from './queries';
import { useCallback, useContext, useMemo } from 'react';
import { notification } from 'src/common';
import { useTranslation } from 'react-i18next';
import { TasksCachedResult, TasksFilterArgs } from '../BidFull/types';

import {
  ApiTaskNav,
  ApiTaskResponse,
  ApiTaskVars,
  BidTaskFnInput,
  BidTaskInput,
  CreateBidTasks,
  CreateBidTasksData,
  CreateBidTasksInput,
  CreateBidTasksVars,
  DEFAULT_TASK_PARAMS,
  QuestionStatus,
  TaskFulfillment,
  GetTaskInput,
  GetTaskResponse,
  ApiBidTaskAnswer
} from './types';
import { TaskTrackPickerData, trackTaskCreate, trackBTDeleteTask } from 'src/segment/events';
import { useLocation, useParams } from 'react-router-dom';
import {
  TaskContext,
  TaskContextValue,
  TaskFormContexValue,
  TaskFormContext,
  TaskNavContext,
  TasksNavCtx
} from './context';
import { BidTask } from '.';
import { Paths } from 'src/pages/paths';
import { useTasksFilter } from '../BidFull/hooks';
import { getTaskFiltersQueryParamsStr, isTasksFilterApplied } from '../BidFull/helpers';
import { getTasksTitle } from './helpers';
import { reOrderRefs } from '../helpers';

interface IUpdateBidQuestionsResponse {
  __typename: 'Mutation';
  updateBidQuestions: boolean;
}

export function useCreateBidTasks(): [(input: CreateBidTasks) => void, { loading: boolean; error?: ApolloError }] {
  const { t } = useTranslation();
  const [createTasks, { loading, error }] = useMutation<CreateBidTasksData, { input: CreateBidTasksInput }>(
    CREATE_TASKS
  );

  const createTasksFn = useCallback(
    ({ questionGroupId, tasks, onComplete, bidId, isSubmitted, eventSource, mode, document }: CreateBidTasks) => {
      const taskData: CreateBidTasksVars[] = tasks.map(task => ({
        ...DEFAULT_TASK_PARAMS,
        title: document ? '' : getTasksTitle(task),
        content: task,
        questionGroupId,
        isSubmitted,
        document
      }));

      createTasks({
        variables: {
          input: {
            questionGroupId,
            questions: taskData
          }
        },
        update: updateCacheOnCreateBidTasks(questionGroupId)
      })
        .then(({ data }) => {
          const taskId = data?.createBidQuestions[0]?.id;
          taskId &&
            trackTaskCreate({
              groupId: questionGroupId,
              bidId,
              taskId,
              eventSource,
              mode
            });
          onComplete?.(taskId);
        })
        .catch(() => {
          notification.error({
            description: t('Common.unknownErrorDesc'),
            message: t('Common.unknownError')
          });
        });
    },
    [createTasks, t]
  );

  return useMemo(() => [createTasksFn, { loading, error }], [createTasksFn, loading, error]);
}

function updateCacheOnCreateBidTasks(groupId: string): MutationUpdaterFn<CreateBidTasksData> {
  return (cache, { data }) => {
    if (!data) {
      return null;
    }
    const createdTasks = data.createBidQuestions;
    cache.modify({
      fields: {
        getGroupQuestions(cached: TasksCachedResult, { toReference }) {
          if (cached.variables?.groupId === groupId) {
            return {
              ...cached,
              value: {
                ...cached.value,
                totalCount: cached.value.totalCount + createdTasks.length,
                questions: [
                  ...cached.value.questions,
                  ...createdTasks.map((task: { id: string }) =>
                    toReference({ __typename: 'ProcurementBidQuestion', id: task.id })
                  )
                ]
              }
            };
          } else {
            return cached;
          }
        }
      }
    });

    cache.modify({
      id: cache.identify({
        __typename: 'QuestionGroupStatistics',
        groupId
      }),
      fields: {
        totalTasks(count: number) {
          return count + createdTasks.length;
        },
        unAssigned(count: number) {
          return count + createdTasks.length;
        },
        todo(count: number) {
          return count + createdTasks.length;
        }
      }
    });
  };
}

export function useUpdateBidTasks(): [(input: BidTaskFnInput) => void, { loading: boolean; error?: ApolloError }] {
  const { t } = useTranslation();
  const { filter: filters } = useTasksFilter();
  const [updateTasks, { loading, error }] = useMutation<IUpdateBidQuestionsResponse, BidTaskInput>(UPDATE_TASK);
  const updateTasksFn = useCallback(
    (input: BidTaskFnInput) => {
      const { questions, newCacheSortIndex, prevGroupId, ...variables } = input;
      updateTasks({
        variables: {
          ...variables,
          questionIds: questions.map(item => item.id)
        },
        update: updateCacheOnUpdateTasks(
          input,
          isTasksFilterApplied(filters) ? filters : undefined,
          newCacheSortIndex,
          prevGroupId
        )
      }).catch(() => {
        notification.error({
          description: t('Common.unknownErrorDesc'),
          message: t('Common.unknownError')
        });
      });
    },
    [filters, t, updateTasks]
  );
  return useMemo(() => [updateTasksFn, { loading, error }], [updateTasksFn, error, loading]);
}

type TaskFilters = Omit<TasksFilterArgs, 'fulfillment'> & { fulfilment: TaskFulfillment[] };
type TaskArrayFilters = Omit<TaskFilters, 'isGenerated'>;
export function updateCacheOnUpdateTasks(
  input: BidTaskFnInput,
  filters?: TasksFilterArgs,
  newCacheSortIndex?: number,
  prevGroupId?: string
): MutationUpdaterFn<IUpdateBidQuestionsResponse> {
  const { questions, groupId, bidId, ...values } = input;

  const valueMatchFilter = (
    filterField: keyof Omit<TasksFilterArgs, 'isGenerated'>,
    value: unknown
  ): boolean | undefined => {
    if (!filters || filters?.[filterField] === undefined) return true;
    const fieldValue = filters[filterField];
    if (Array.isArray(filters[filterField])) {
      return fieldValue?.some(i => i === value);
    }
  };

  return (cache, { data }) => {
    if (!data?.updateBidQuestions) {
      return;
    }

    if (!!prevGroupId) {
      cache.modify({
        fields: {
          getGroupQuestions(cached: TasksCachedResult, { toReference, readField }) {
            if (cached.variables?.groupId === groupId) {
              return {
                ...cached,
                value: {
                  ...cached.value,
                  totalCount: cached.value.totalCount + 1,
                  questions: [
                    ...cached.value.questions,
                    toReference({ __typename: 'ProcurementBidQuestion', id: input.questions[0].id })
                  ]
                }
              };
            } else if (cached.variables?.groupId === prevGroupId) {
              const filteredQuestions = cached.value.questions.filter(
                taskRef => readField('id', taskRef) !== input.questions[0].id
              );
              return {
                ...cached,
                totalCount: cached.value.totalCount - 1,
                value: {
                  ...cached.value,
                  questions: filteredQuestions
                }
              };
            }
          }
        }
      });
    }

    if (newCacheSortIndex !== undefined && !prevGroupId) {
      cache.modify({
        fields: {
          getGroupQuestions(existing: TasksCachedResult) {
            if (!existing || !existing.value || !existing.value.questions) {
              return existing;
            }

            const taskRef = cache.identify({
              __typename: 'ProcurementBidQuestion',
              id: input.questions[0].id
            });

            const questions = reOrderRefs(
              existing.value.questions,
              taskRef ?? '',
              task => task.__ref,
              newCacheSortIndex
            );

            return {
              ...existing,
              value: {
                ...existing.value,
                questions
              }
            };
          }
        }
      });
    }

    for (const fieldToUpdate in values) {
      if (fieldToUpdate !== undefined) {
        const field = fieldToUpdate as keyof Omit<TasksFilterArgs, 'isGenerated'>;
        questions.forEach(question => {
          const id = cache.identify({
            __typename: 'ProcurementBidQuestion',
            id: question.id
          });

          // If current item should not be present in the list anymore
          const itemWillDisappear =
            valueMatchFilter(
              field,
              field === 'assignedTo' ? question.assignedTo : question[field as keyof TaskArrayFilters]
            ) && !valueMatchFilter(field, values[field as keyof TaskArrayFilters]);

          if (filters) {
            cache.modify({
              fields: {
                getGroupQuestions(cached: TasksCachedResult) {
                  const filterValue =
                    cached.variables !== undefined &&
                    cached.variables.filters !== undefined &&
                    cached.variables.groupId === groupId
                      ? cached.variables.filters[field]
                      : undefined;
                  const currentValue =
                    field === 'assignedTo' ? question['assignedTo'] : question[field as keyof TaskArrayFilters];
                  if (Array.isArray(filterValue)) {
                    if (
                      filterValue &&
                      filterValue.some(value => value === currentValue) &&
                      filterValue.every(value => value !== values[field as keyof TaskArrayFilters])
                    ) {
                      const cachedQuestions = cached.value.questions.filter(question => question.__ref !== id);

                      return {
                        ...cached,
                        value: {
                          ...cached.value,
                          questions: cachedQuestions,
                          totalCount: cachedQuestions.length
                        }
                      };
                    } else {
                      return cached;
                    }
                  }
                }
              }
            });
          }

          if (field === 'assignedTo') {
            cache.modify({
              id,
              fields: {
                assignedTo() {
                  return values[field]
                    ? {
                        __ref: cache.identify({
                          __typename: 'AssignedTo',
                          id: values[field]
                        })
                      }
                    : null;
                }
              }
            });

            const newStatusValue = (status: QuestionStatus) => {
              return (count: number) => {
                return valueMatchFilter(field, question['assignedTo']) &&
                  !valueMatchFilter(field, values[field]) &&
                  question.status === status
                  ? count - 1
                  : count;
              };
            };
            cache.modify({
              id: cache.identify({
                __typename: 'QuestionGroupStatistics',
                groupId
              }),
              fields: {
                todo: newStatusValue(QuestionStatus.ToDo),
                inProgress: newStatusValue(QuestionStatus.InProgress),
                review: newStatusValue(QuestionStatus.Review),
                done: newStatusValue(QuestionStatus.Done),
                unAssigned(count: number) {
                  if (valueMatchFilter(field, values[field as keyof TaskArrayFilters])) {
                    return values[field] === null
                      ? count + 1
                      : values[field] && !question['assignedTo']
                      ? count - 1
                      : count;
                  }

                  if (itemWillDisappear && !question['assignedTo']) {
                    return count - 1;
                  }

                  return count;
                },
                totalTasks(count: number) {
                  return itemWillDisappear ? count - 1 : count;
                }
              }
            });
          } else {
            cache.modify({
              id,
              fields: {
                [field]() {
                  return values[field as keyof typeof values];
                }
              }
            });

            if (field === 'status' || filters) {
              const newStatusValue = (status: QuestionStatus) => {
                return (count: number) => {
                  if (field === 'status') {
                    if (valueMatchFilter(field, values[field as keyof TaskArrayFilters])) {
                      return values['status'] === status ? count + 1 : question.status === status ? count - 1 : count;
                    }

                    if (itemWillDisappear && question.status === status) {
                      return count - 1;
                    }

                    return count;
                  }

                  return itemWillDisappear && question.status === status ? count - 1 : count;
                };
              };
              cache.modify({
                id: cache.identify({
                  __typename: 'QuestionGroupStatistics',
                  groupId
                }),
                fields: {
                  todo: newStatusValue(QuestionStatus.ToDo),
                  inProgress: newStatusValue(QuestionStatus.InProgress),
                  review: newStatusValue(QuestionStatus.Review),
                  done: newStatusValue(QuestionStatus.Done),
                  totalTasks(count: number) {
                    return itemWillDisappear ? count - 1 : count;
                  },
                  unAssigned(count: number) {
                    return itemWillDisappear && question['assignedTo'] === null ? count - 1 : count;
                  }
                }
              });
              cache.evict({ fieldName: 'getUserAssignedQuestions' });
              cache.gc();
            }
          }
        });
      }
    }
  };
}
type DeleteBidTasksVars = {
  questionIds: string[];
  groupId: string;
};
type DeleteBidTasksData = {
  deleteBidQuestions: boolean;
};
type DeleteBidTasksInput = DeleteBidTasksVars &
  Omit<TaskTrackPickerData, 'taskProp' | 'groupId'> & {
    classificationId?: string;
    isSubmitted?: boolean;
  };
export function useDeleteBidTasks(): [(input: DeleteBidTasksInput) => void, { loading: boolean; error?: ApolloError }] {
  const { t } = useTranslation();
  const { filter: filters } = useTasksFilter();
  /** @todo move out to layouts, it's not necessarily bidId in params on different pages, eg on 'Task Page' */
  const { id: bidId } = useParams<{ id: string }>();
  /** @todo end */
  const [deleteTasks, { loading, error }] = useMutation<DeleteBidTasksData, DeleteBidTasksVars>(DELETE_TASK);
  const deleteTasksFn = useCallback(
    (input: DeleteBidTasksInput) => {
      const { questionIds, groupId, mode, eventSource, isSubmitted, classificationId } = input;
      trackBTDeleteTask({ mode, groupId, bidId, eventSource });
      deleteTasks({
        variables: { questionIds, groupId },
        update: updateCacheOnDeleteBidTasks(
          { groupId, questionIds, mode, isSubmitted, classificationId },
          isTasksFilterApplied(filters) ? filters : undefined
        )
      }).catch(() => {
        notification.error({
          description: t('Common.unknownErrorDesc'),
          message: t('Common.unknownError')
        });
      });
    },
    [bidId, deleteTasks, t, filters]
  );
  return useMemo(() => [deleteTasksFn, { loading, error }], [deleteTasksFn, error, loading]);
}
export function updateCacheOnDeleteBidTasks(
  input: Omit<DeleteBidTasksInput, 'type'>,
  filters?: TasksFilterArgs
): MutationUpdaterFn<DeleteBidTasksData> {
  return (cache, { data }) => {
    if (!data) {
      return null;
    }

    const { groupId, questionIds, mode, classificationId, isSubmitted } = input;
    const modifyGroupStatistics = (questionId: string): void => {
      cache.modify({
        id: cache.identify({
          __typename: 'QuestionGroupStatistics',
          groupId
        }),
        fields: {
          totalTasks(count: number) {
            return Math.max(0, count - 1);
          },
          unAssigned(count: number, { readField, toReference }) {
            const taskRef = toReference({ __typename: 'ProcurementBidQuestion', id: questionId });
            return readField('assignedTo', taskRef) ? count : count - 1;
          },
          todo(count: number, { readField, toReference }) {
            const taskRef = toReference({ __typename: 'ProcurementBidQuestion', id: questionId });
            const taskStatus = readField('status', taskRef);
            return taskStatus === QuestionStatus.ToDo ? count - 1 : count;
          },
          inProgress(count: number, { readField, toReference }) {
            const taskRef = toReference({ __typename: 'ProcurementBidQuestion', id: questionId });
            const taskStatus = readField('status', taskRef);
            return taskStatus === QuestionStatus.InProgress ? count - 1 : count;
          },
          review(count: number, { readField, toReference }) {
            const taskRef = toReference({ __typename: 'ProcurementBidQuestion', id: questionId });
            const taskStatus = readField('status', taskRef);
            return taskStatus === QuestionStatus.Review ? count - 1 : count;
          },
          done(count: number, { readField, toReference }) {
            const taskRef = toReference({ __typename: 'ProcurementBidQuestion', id: questionId });
            const taskStatus = readField('status', taskRef);
            return taskStatus === QuestionStatus.Done ? count - 1 : count;
          }
        }
      });
    };

    if (classificationId) {
      cache.modify({
        id: cache.identify({ classificationId, __typename: 'DocumentCoordinates' }),
        fields: {
          createdTask() {
            return false;
          }
        }
      });
    }
    if (mode === 'bulk') {
      questionIds.forEach(questionId => modifyGroupStatistics(questionId));

      cache.evict({
        id: 'ROOT_QUERY',
        fieldName: 'getGroupQuestions',
        args: { input: { groupId, filters } }
      });
      cache.evict({
        id: 'ROOT_QUERY',
        fieldName: 'getGroupQuestions',
        args: { input: { groupId, filters } }
      });
      cache.gc();
      return;
    }

    questionIds.forEach(questionId => {
      cache.modify({
        fields: {
          getGroupQuestions(cached: TasksCachedResult, { readField }) {
            if (
              cached.variables?.groupId === groupId &&
              cached.variables.isSubmitted === isSubmitted &&
              cached.value.questions.some(task => readField('id', task) === questionId)
            ) {
              return {
                ...cached,
                value: {
                  ...cached.value,
                  totalCount: Math.max(cached.value.totalCount - 1, 0),
                  questions: [...cached.value.questions].filter(task => readField('id', task) !== questionId)
                }
              };
            } else {
              return cached;
            }
          }
        }
      });
      modifyGroupStatistics(questionId);
      cache.evict({ id: cache.identify({ id: questionId, __typename: 'ProcurementBidQuestion' }) });
    });
    cache.gc();
  };
}

export interface ISetBidQuestionAnswerOutput {
  setBidQuestionAnswer: ApiBidTaskAnswer;
}
export interface ISetBidQuestionAnswerInput {
  questionId: string;
  questionGroupId: string;
  answer: ISetBidQuestionAnswer;
}
export interface ISetBidQuestionAnswer {
  answer?: boolean | null;
  content?: string | null;
  files?: string[] | null;
  richContent?: string | null;
  sourceIds?: string[] | null;
  pendingContent?: string | null;
  pendingSourceIds?: string[] | null;
}
export interface IDeleteBidQuestionAnswerInput extends Omit<ISetBidQuestionAnswerInput, 'answer'> {}

export function useSetBidQuestionAnswer(): [
  (input: ISetBidQuestionAnswerInput, onFinish?: () => void) => void,
  { loading: boolean; error?: ApolloError }
] {
  const { t } = useTranslation();
  const [setAnswer, { loading, error }] = useMutation<ISetBidQuestionAnswerOutput, ISetBidQuestionAnswerInput>(
    SET_TASK_ANSWER
  );
  const setAnswerFn = useCallback(
    (input: ISetBidQuestionAnswerInput, onFinish?: () => void) => {
      setAnswer({
        variables: input,
        update: updateCacheOnSetBidQuestionAnswer(input)
      })
        .then(() => {
          onFinish && onFinish();
        })
        .catch(() => {
          notification.error({
            description: t('Common.unknownErrorDesc'),
            message: t('Common.unknownError')
          });
        });
    },
    [t, setAnswer]
  );
  return useMemo(() => [setAnswerFn, { loading, error }], [setAnswerFn, error, loading]);
}

export function updateCacheOnSetBidQuestionAnswer(
  input: ISetBidQuestionAnswerInput
): MutationUpdaterFn<ISetBidQuestionAnswerOutput> {
  const { questionId } = input;
  return (cache, { data }) => {
    const bidQuestionAnswerId = data?.setBidQuestionAnswer?.id;
    if (!bidQuestionAnswerId) {
      return;
    }

    const BidQuestion = cache.identify({
      __typename: 'ProcurementBidQuestion',
      id: questionId
    });

    cache.modify({
      id: BidQuestion,
      fields: {
        answer(cached, { toReference }) {
          if (cached) return cached;
          return toReference({
            __typename: 'BidQuestionAnswer',
            id: bidQuestionAnswerId
          });
        }
      }
    });
  };
}

export function generateTaskUrl(taskId?: string): string | undefined {
  const qpStr = getTaskFiltersQueryParamsStr();
  return taskId ? `${Paths.TASK_ROUTE}${Paths.TASK_PAGE.replace(':id', taskId)}${qpStr}` : undefined;
}
export interface BidTaskResponse {
  data: BidTask | null;
  loading: boolean;
}

export function useTaskNavCtx(): TasksNavCtx {
  const { pathname } = useLocation();
  const [, , taskId] = pathname.split('/');

  const { data: navData, loading } = useQuery<{ getBidQuestion: ApiTaskNav }, ApiTaskVars>(GET_TASK_GROUP, {
    variables: { taskId: taskId },
    skip: !taskId
  });
  const groupId = navData?.getBidQuestion.questionGroup.id ?? '';

  return useMemo(
    () => ({
      data: navData
        ? {
            groupId,
            bidId: navData.getBidQuestion.questionGroup.bid.id,
            groupName: navData.getBidQuestion.questionGroup.title,
            activeTaskId: taskId
          }
        : null,
      loading
    }),
    [groupId, loading, navData, taskId]
  );
}

export function useTaskNav(): TasksNavCtx {
  return useContext(TaskNavContext);
}

export function useLoadTask(id?: string, skip?: boolean): BidTaskResponse {
  const { pathname } = useLocation();
  const [, , taskIdFromUrl] = pathname.split('/');
  const taskId = id ?? taskIdFromUrl;
  const query = useQuery<ApiTaskResponse, ApiTaskVars>(GET_TASK, {
    variables: { taskId: id ?? taskId ?? '' },
    skip: !taskId || !!skip
  });

  return useMemo(
    () => ({
      data: query.data?.getBidQuestion ? new BidTask(query.data.getBidQuestion) : null,
      loading: query.loading
    }),
    [query]
  );
}

export function useTask(): TaskContextValue {
  return useContext(TaskContext);
}

export function useTaskForm(): TaskFormContexValue {
  return useContext(TaskFormContext);
}

export function useGetTask(): LazyQueryResultTuple<GetTaskResponse, GetTaskInput> {
  return useLazyQuery<GetTaskResponse, GetTaskInput>(GET_TASK);
}
