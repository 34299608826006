import { OperationVariables, useSubscription } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { notification, Spinner } from 'src/common';
import { useExportFileStatus } from 'src/reactiveVars';
import { downloadFile, FeatureFlag, useFeatureFlag } from 'src/helpers';
import { FileExportedSub } from '@tendium/prom-types/subscriptions';
import { ApiExportFile, LOADING_AUTO_CLOSE_DURATION, LOADING_MIN_STARY_DURATION } from './types';
import { ON_EXPORT_FILE_READY } from './queries';
import { hasExportPending, isOperationPending, printExportedPdf } from './helpers';
import { createElement, useEffect, useMemo, useRef } from 'react';
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons';
import styles from 'src/common/Spinner/index.module.scss';

export function useFileExportingAvailable(): boolean {
  const isWorkspaceExport = useFeatureFlag(FeatureFlag.Workspace_Export);
  const isBidTasksExport = useFeatureFlag(FeatureFlag.BidTasks_Export);
  return isWorkspaceExport || isBidTasksExport || false;
}

export function useFileExportSubscription(skip: boolean): void {
  const enabled = useFileExportingAvailable();
  const [exportStatus, updateExportStatus] = useExportFileStatus();
  const { t } = useTranslation();

  useSubscription<{ filesExported: ApiExportFile }, OperationVariables>(ON_EXPORT_FILE_READY, {
    onData: ({ data: response }) => {
      const filesExported = response.data?.filesExported;
      if (!filesExported) {
        return;
      }

      // Only proceed when the operation was made in this session (Avoid proceeding on other open tabs)
      if (!isOperationPending(exportStatus, filesExported.operationId)) {
        return;
      }

      // Set this operation to finished
      updateExportStatus(filesExported.operationId, 'finished');

      const fileName = filesExported.file?.name ?? 'exported-file';

      // Prioritize file download
      if (filesExported.file?.url) {
        downloadFile(filesExported.file.url, fileName);
        return;
      }

      // If no file is available, print the PDF from received html
      if (filesExported.html) {
        printExportedPdf({ html: filesExported.html, fileName });
        return;
      }

      // Notify the exporting failure if no url or html was returned
      notification.error({
        description: t(
          `BidSpaces.${
            filesExported.error === FileExportedSub.ExportingError.PayloadTooLarge
              ? 'exportBidSpacePayloadTooLargeError'
              : 'exportBidSpaceUnexpectedError'
          }`
        ),
        message: t('BidSpaces.exportBidSpaceErrorMessage')
      });
    },
    onError: () => {
      if (hasExportPending(exportStatus)) {
        updateExportStatus('all', 'finished');
        notification.error({
          description: t('BidSpaces.exportBidSpaceUnexpectedError'),
          message: t('BidSpaces.exportBidSpaceErrorMessage')
        });
      }
    },
    skip: !enabled || skip
  });
}

export function useExportLoading(key: string): void {
  const { t } = useTranslation();
  const [exportStatus] = useExportFileStatus();
  const isExporting = useMemo(() => hasExportPending(exportStatus), [exportStatus]);
  const exportIndicatorLastShown = useRef<null | number>(null);
  const minIndicatorDuration = LOADING_MIN_STARY_DURATION;
  const loadingAutoCloseDurationSeconds = LOADING_AUTO_CLOSE_DURATION / 1000;

  useEffect(() => {
    if (isExporting) {
      exportIndicatorLastShown.current = new Date().getTime();
      notification.info({
        description: t('BidSpaces.exportBidSpaceNotificationDescription'),
        message: t('BidSpaces.exportBidSpaceNotificationMessage'),
        duration: loadingAutoCloseDurationSeconds,
        key,
        icon: createElement(Spinner, {
          icon: faSpinnerThird,
          className: styles.spinnerMedium
        })
      });
    } else {
      const timeoutDuration = exportIndicatorLastShown.current
        ? minIndicatorDuration - Math.min(minIndicatorDuration, new Date().getTime() - exportIndicatorLastShown.current)
        : minIndicatorDuration;

      const timeout = setTimeout(() => {
        notification.close(key);
      }, timeoutDuration);

      return () => clearTimeout(timeout);
    }
  }, [t, isExporting, exportIndicatorLastShown, key, minIndicatorDuration, loadingAutoCloseDurationSeconds]);
}
