import React, { FC, useCallback } from 'react';
import AssignTo, { IAssignToProps } from 'src/shared/AssignTo';
import { useUpdateChecklistItem } from 'src/models/bids/BidChecklist/hooks';
import { useWsUsersAndTeams } from 'src/models/workspace/hooks';
import { trackAssignTo, TrackEventSource } from 'src/segment/events';
import { AssignedTo } from 'src/models/users/types';
import { trackAssignedChecklistItem } from 'src/segment/events/checklist';

interface Props extends IAssignToProps {
  id: string;
  bidId: string;
  wsId?: string;
  eventSource: TrackEventSource;
}

export const AssignToChecklistItem: FC<Props> = ({ size = 'm', mode = 'short', ...restProps }) => {
  const { id, wsId, bidId, eventSource } = restProps;
  const wsUsers = useWsUsersAndTeams(wsId);
  const [updateTodo, { loading }] = useUpdateChecklistItem();

  const onUpdateTodo = useCallback(
    (assignedTo: string | null) => {
      updateTodo({
        id,
        bidId,
        assignedTo
      });
    },
    [id, bidId, updateTodo]
  );

  const onAssign = useCallback(
    (assignedTo: AssignedTo) => {
      trackAssignTo({ eventSource, assignedTo });
      trackAssignedChecklistItem(bidId);
      onUpdateTodo(assignedTo.id);
    },
    [eventSource, onUpdateTodo, bidId]
  );

  const onUnAssign = useCallback(() => {
    onUpdateTodo(null);
  }, [onUpdateTodo]);

  return (
    <AssignTo
      onAssign={onAssign}
      onUnAssign={onUnAssign}
      isUpdating={loading}
      size={size}
      mode={mode}
      users={wsUsers}
      defaultPopupContainer
      {...restProps}
    />
  );
};
export default AssignToChecklistItem;
