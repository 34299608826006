import { gql } from '@apollo/client/core';
import { BIDDING_FIELDS, BID_GROUP_FIELDS } from './fragments';
import { TASK_FIELDS } from '../BidTask/queries';

export const GET_BIDDING = gql`
  query getBidding($bidId: String!) {
    getBid(input: { bidId: $bidId }) {
      ...biddingFields
    }
  }
  ${BIDDING_FIELDS}
`;

export const GET_TASKS_GENERATION_STATUS = gql`
  query getTasksGenStatus($bidId: String!) {
    getTasksGenerationStatus(input: { bidId: $bidId }) {
      bidId
      status
    }
  }
`;

export const START_TASKS_GENERATION_JOB = gql`
  mutation startTasksGenJob($bidId: String!, $publicFiles: [String!]!, $privateFiles: [PrivateFileInput!]!) {
    startTasksGenerationJob(input: { bidId: $bidId, publicFiles: $publicFiles, privateFiles: $privateFiles }) {
      bidId
    }
  }
`;

export const GET_BIDDING_STATS = gql`
  query getBiddingStats($bidId: String!, $filters: GetBidQuestionsFilters, $isSubmitted: Boolean) {
    getQuestionGroupsStatistics(input: { bidId: $bidId, filters: $filters, isSubmitted: $isSubmitted }) {
      groupId
      title
      totalTasks
      todo
      inProgress
      review
      done
      unAssigned
    }
  }
`;

export const GENERATE_TASKS = gql`
  mutation generateBidQuestions($bidId: String!, $selectedFiles: [String!]) {
    generateBidQuestions(input: { procurementBidId: $bidId, selectedFiles: $selectedFiles }) {
      ...bidQuestionGroupFields
    }
  }
  ${BID_GROUP_FIELDS}
`;

export const CREATE_BID_GROUP = gql`
  mutation createBidTasksGroup($bidId: String!, $title: String!, $filePath: String) {
    createBidQuestionGroup(input: { procurementBidId: $bidId, title: $title, filePath: $filePath }) {
      ...bidQuestionGroupFields
    }
  }
  ${BID_GROUP_FIELDS}
`;

export const UPDATE_BID_GROUP = gql`
  mutation updateBidTasksGroup($bidId: String!, $groupId: String!, $title: String, $rank: String) {
    updateBidQuestionGroup(input: { procurementBidId: $bidId, questionGroupId: $groupId, title: $title, rank: $rank }) {
      ...bidQuestionGroupFields
    }
  }
  ${BID_GROUP_FIELDS}
`;

export const DELETE_BID_GROUP = gql`
  mutation deleteBidTasksGroup($groupId: String!) {
    deleteBidQuestionGroup(input: { questionGroupId: $groupId })
  }
`;

export const GET_GROUP_TASKS = gql`
  query getGroupTasks(
    $groupId: String!
    $isSubmitted: Boolean
    $offset: Float!
    $limit: Float!
    $filters: GetBidQuestionsFilters
  ) {
    getGroupQuestions(
      input: {
        groupId: $groupId
        isSubmitted: $isSubmitted
        pagination: { offset: $offset, limit: $limit }
        filters: $filters
      }
    ) {
      totalCount
      nextRank
      questions {
        ...questionFields
      }
    }
  }
  ${TASK_FIELDS}
`;
