import { makeVar } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { useReactiveVariable } from 'src/helpers/reactiveVariables';
import { EXPORT_STATUS_TYPE, ExportFileStatus } from 'src/models/fileExport/types';

export const exportFileStatusVar = makeVar<ExportFileStatus>({});

export function useExportFileStatus(): [
  ExportFileStatus,
  (operationId: string, status: EXPORT_STATUS_TYPE, id?: string) => void
] {
  const [exportStatus, setExportStatus] = useReactiveVariable<ExportFileStatus>(exportFileStatusVar);

  const updateExportStatus = useCallback(
    (operationId: string, status: EXPORT_STATUS_TYPE, exportingKey?: string) => {
      // If we have a completely done state, set all pending operations' status to finished
      if (operationId === 'all' && status === 'finished') {
        Object.entries(exportStatus).forEach(([key, value]) => {
          if (value.status === 'pending') {
            setExportStatus({
              ...exportStatus,
              [key]: {
                operationId: value.operationId,
                status
              }
            });
          }
        });
        return;
      }

      // Explicitly setting status for a exportingKey
      if (exportingKey) {
        setExportStatus({
          ...exportStatus,
          [exportingKey]: {
            operationId,
            status
          }
        });
        return;
      }

      const currentItem = Object.entries(exportStatus).find(([, value]) => value.operationId === operationId);

      if (!currentItem) return;

      const [currentExportKey, currentExportValue] = currentItem;

      if (currentExportValue.status === 'finished') return;

      setExportStatus({
        ...exportStatus,
        [currentExportKey]: {
          operationId,
          status
        }
      });
    },
    [exportStatus, setExportStatus]
  );

  return useMemo(() => [exportStatus, updateExportStatus], [exportStatus, updateExportStatus]);
}

export default useExportFileStatus;
