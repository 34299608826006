import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import BlockTitle from '../Blocks/BlockTitle';
import { faEnvelope, faLinkSimple, faPenNib } from '@fortawesome/pro-light-svg-icons';
import classNames from 'classnames';
import { Avatar, Button, Skeleton, TenderStatusBadge, Tooltip } from 'src/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useBidPreview } from 'src/models/bids/BidPreview/hooks';
import { BidItemType } from '@tendium/prom-types/tender';
import { Paths } from 'src/pages/paths';
import { useNavigate } from 'react-router';
import { isFieldDate, isFieldString, TenderCatName } from 'src/models/procurements/Tender/types';
import { formatDate } from 'src/helpers/dates';
import { useTenderMinimal } from 'src/models/procurements/Tender/hooks';
import { useLoadInboxEmail } from 'src/models/callOffs/hooks';
import { useOverviewBackLink } from 'src/models/procurements/hooks';
import { ProcurementStatus } from 'src/models/procurements/types';

export const LinkedSource: FC = () => {
  const { t } = useTranslation();
  const { data: bidData, loading } = useBidPreview();
  const originType = bidData?.origin.originType;

  const { data: procurementSourceData } = useTenderMinimal(bidData?.origin.id, originType !== BidItemType.Procurement);
  const { data: emailSourceData } = useLoadInboxEmail(bidData?.origin.id ?? null, originType !== BidItemType.CallOff);

  const isAwarded = procurementSourceData?.procurementStatus?.includes(ProcurementStatus.Award) ?? false;

  const restStatuses =
    procurementSourceData?.procurementStatus?.filter(status => status !== ProcurementStatus.Award) ?? [];

  const { set: setBackLink } = useOverviewBackLink();
  const navigate = useNavigate();

  const onClick = useCallback(
    (hash: TenderCatName) => {
      if (bidData?.origin) {
        navigate(`${Paths.TENDER_ROUTE}${bidData.origin.id}#${hash}`);
        setBackLink();
      }
    },
    [bidData?.origin, navigate, setBackLink]
  );

  const procurementSourceTitle =
    procurementSourceData && procurementSourceData.name && isFieldString(procurementSourceData.name.firstField)
      ? procurementSourceData.name.firstField?.string?.toString()
      : '';
  const procurementSourcePublished =
    procurementSourceData?.published &&
    isFieldDate(procurementSourceData?.published.firstField) &&
    !!procurementSourceData?.published.firstField.date
      ? procurementSourceData?.published.firstField.date
      : null;

  const emailSourceTitle = emailSourceData?.getInboxEmail?.userMails?.[0]?.subject || '';
  const emailSourceReceived = emailSourceData?.getInboxEmail?.userMails?.[0]?.date || '';

  return (
    <div className={styles.container}>
      <BlockTitle
        title={t('MultipleBids.linkedSource')}
        icon={{ defaultIcon: faLinkSimple, color: 'var(--brand-re)' }}
        noBorder
      />
      {!!loading ? (
        <Skeleton />
      ) : (
        <>
          {bidData?.origin.originType && (
            <div className={classNames(styles.container, styles.sourceContainer)}>
              <div className={classNames(styles.subContainer, styles.titleContainer)}>
                {originType === BidItemType.CallOff && <FontAwesomeIcon icon={faEnvelope} color="var(--brand-re)" />}
                {originType === BidItemType.Manual && <FontAwesomeIcon icon={faPenNib} color="var(--brand-re)" />}
                {originType === BidItemType.Procurement && (
                  <>
                    {isAwarded && (
                      <Button type={'text'} onClick={() => onClick(TenderCatName.Outcome)} className={styles.button}>
                        <TenderStatusBadge statuses={[ProcurementStatus.Award]} mode={'icon'} />
                      </Button>
                    )}
                    {(!!procurementSourceData?.isPlanningProcess || !!restStatuses) && (
                      <TenderStatusBadge
                        statuses={restStatuses}
                        isPIN={procurementSourceData?.isPlanningProcess}
                        mode={'icon'}
                      />
                    )}
                  </>
                )}
                <Button
                  className={styles.title}
                  type={'link'}
                  onClick={() => onClick(TenderCatName.Overview)}
                  disabled={originType === BidItemType.Manual || originType === BidItemType.CallOff}
                >
                  <div className={styles.title}>
                    {originType === BidItemType.CallOff && emailSourceTitle}
                    {originType === BidItemType.Manual && t('MultipleBids.manualBid')}
                    {originType === BidItemType.Procurement && procurementSourceTitle}
                  </div>
                </Button>
              </div>

              <div className={classNames(styles.subContainer, styles.createdAt)}>
                {originType === BidItemType.CallOff && (
                  <>
                    <span>{t('MultipleBids.received')}</span>
                    <span>{formatDate(emailSourceReceived)}</span>
                  </>
                )}
                {originType === BidItemType.Manual && (
                  <>
                    <span>{t('Common.createdBy')}</span>
                    <span>{formatDate(bidData.createdAt)}</span>
                    {bidData.createdBy && (
                      <Tooltip title={`${t('≈')} ${bidData.createdBy.userName}`}>
                        <div>
                          <Avatar user={bidData.createdBy} size="xs" />
                        </div>
                      </Tooltip>
                    )}
                  </>
                )}
                {originType === BidItemType.Procurement && (
                  <>
                    <span>{t('MultipleBids.published')}</span>
                    <span>{formatDate(procurementSourcePublished)}</span>
                  </>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default LinkedSource;
