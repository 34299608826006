import { NodeViewContent, NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import styles from './index.module.scss';
import {
  AssignToQuestion,
  TaskFulfillmentPicker,
  TaskRequirementPicker,
  TaskStatusPicker,
  TenderFile
} from 'src/shared';
import GenerateAnswerPreview from '../GenerateAnswerPreview';
import GenerateMenu from '../GenerateMenu';
import { useActiveCanvasTask, useCanvasState } from 'src/models/canvas/hooks';
import { isTaskDone } from 'src/models/bids/BidTask/types';
import { TaskDatePicker } from 'src/shared/Bid/TaskDatePicker';
import { TaskTrackPickerData } from 'src/segment/events';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-light-svg-icons';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useLoadTask, useUpdateBidTasks } from 'src/models/bids/BidTask/hooks';
import { useTasksFilter } from 'src/models/bids/BidFull/hooks';
import { isTasksFilterApplied } from 'src/models/bids/BidFull/helpers';
import { doesTaskMatchFilters } from 'src/models/bids/BidTask/helpers';
import { useCanvasGenerationStatus } from 'src/reactiveVars';
import { StreamingCommon } from '@tendium/prom-types/subscriptions';
import AttachmentList from './AttachmentList';
import { Form, Input, Skeleton } from 'src/common';
import { useClickAway } from 'react-use';
import { debounce } from 'lodash';
import TaskSummary from 'src/shared/TaskPreview/Summary';
import { PrivateFilesView } from 'src/shared/TaskPreview/PrivateFilesView';
import { useGetBidAttachments } from 'src/models/bids/BidAttachments/hooks';
import { useParams } from 'react-router';
import { CanvasURLParams } from 'src/models/canvas/types';
import { useBidWorkspace } from 'src/models/bids/Bids/hooks';
const { TextArea } = Input;

const CanvasBidTask: FC<NodeViewProps> = props => {
  const { t } = useTranslation();
  const { idGenerateMenuVisibilityMap } = useCanvasState();
  const [updateTasks] = useUpdateBidTasks();
  const { filter } = useTasksFilter();
  const { canvasStatus } = useCanvasGenerationStatus();
  const { id: bidIdParam } = useParams<CanvasURLParams>();
  const { workspace } = useBidWorkspace(bidIdParam ?? '');

  const [form] = Form.useForm();
  const titleRef = useRef<HTMLDivElement>(null);
  const questionRef = useRef<HTMLDivElement>(null);

  const attributes = props.node.attrs;
  const taskId = attributes.id;
  const endOfNodePos = props.getPos() + props.node.nodeSize - 2;

  const { activeTaskId } = useActiveCanvasTask(props.editor);
  const { data: task, loading } = useLoadTask(taskId ?? '', !taskId);
  const { data: bidAttachmentsData } = useGetBidAttachments(task?.bidId ?? '');

  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isEditingQuestion, setIsEditingQuestion] = useState(false);

  const primaryReference = task?.primaryReferences;

  const pathMatch = primaryReference?.[0]?.reference?.path.match(/\/(.+)/); // find fileId from path
  const fileId = pathMatch ? pathMatch[1] : null;
  const privateFile = bidAttachmentsData?.find(att => att.document.id === fileId);

  const bidId = task?.bidId ?? '';
  const groupId = task?.groupId ?? '';
  const bidTaskAnswerId = task?.answer?.id ?? '';

  const isFilterApplied = useMemo(() => isTasksFilterApplied(filter), [filter]);

  const trackData: TaskTrackPickerData = useMemo(
    () => ({ bidId, eventSource: 'Canvas', mode: 'single', groupId }),
    [bidId, groupId]
  );

  // Empty if only one child node and no text in it
  const isEmpty = useMemo(
    () => props.node.content.content.length <= 1 && props.node.content.size <= 2,
    [props.node.content.content.length, props.node.content.size]
  );

  const isTaskGenerating = useMemo(
    () =>
      canvasStatus.taskStatuses[taskId] === StreamingCommon.AnswerStatus.IN_PROGRESS ||
      canvasStatus.taskStatuses[taskId] === StreamingCommon.AnswerStatus.WAITING_FOR_STREAM,
    [canvasStatus.taskStatuses, taskId]
  );

  const onChangeTitle = useMemo(
    () =>
      debounce(() => {
        if (!task) return;

        const title = form.getFieldValue('title');
        updateTasks({
          questions: [task],
          groupId: groupId,
          title: title
        });
      }, 300),
    [form, groupId, task, updateTasks]
  );

  const onChangeQuestion = useMemo(
    () =>
      debounce(() => {
        if (!task) return;

        const question = form.getFieldValue('question');
        updateTasks({
          questions: [task],
          groupId: groupId,
          content: question
        });
      }, 300),
    [form, groupId, task, updateTasks]
  );

  useClickAway(titleRef, () => {
    setIsEditingTitle(false);
  });
  useClickAway(questionRef, () => {
    setIsEditingQuestion(false);
  });

  const handleClickNodeContainer = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      // Prevent editor from refocusing if already focused
      if (
        e.target instanceof HTMLInputElement ||
        e.target instanceof HTMLTextAreaElement ||
        titleRef.current?.contains(e.target as Node) ||
        questionRef.current?.contains(e.target as Node)
      ) {
        e.stopPropagation();
        return;
      }

      if (!props.editor.isFocused) {
        props.editor.chain().focus().setTextSelection(endOfNodePos).run();
      }
    },
    [endOfNodePos, props.editor]
  );

  const handleClickTaskToolbarContainer = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (e.target instanceof HTMLInputElement) {
        e.stopPropagation();
        return;
      }
      e.preventDefault();
      props.editor.chain().focus().setTextSelection(endOfNodePos).run();
    },
    [endOfNodePos, props.editor]
  );

  if (loading) return <Skeleton active loading />;

  if (!task) return null;

  if (!doesTaskMatchFilters(task, filter, isFilterApplied)) return null;

  return (
    <NodeViewWrapper
      className={activeTaskId === taskId ? styles.activeBidTaskNodeContainer : styles.bidTaskNodeContainer}
      key={taskId}
      onMouseDown={handleClickNodeContainer}
    >
      <div className={styles.taskInformationContainer} contentEditable={false} onClick={handleClickNodeContainer}>
        <div className={styles.content}>
          <Form form={form} layout={'vertical'} className={styles.content}>
            <div className={styles.inputRow} ref={titleRef}>
              {isEditingTitle ? (
                <Form.Item name={'title'} initialValue={task.title} className={styles.input}>
                  <TextArea autoSize autoFocus onChange={onChangeTitle} />
                </Form.Item>
              ) : (
                <div
                  className={styles.plainTitle}
                  onClick={() => {
                    if (isTaskGenerating || !props.editor.isEditable) return;
                    setIsEditingTitle(true);
                  }}
                >
                  <h2 className={styles.title}>{!!task.title ? task.title : t('BiddingTool.newTaskPlaceholder')}</h2>
                  {!isTaskGenerating && <FontAwesomeIcon icon={faPen} className={styles.editTitleIcon} />}
                </div>
              )}
            </div>
            <div className={styles.inputRow} ref={questionRef}>
              {isEditingQuestion ? (
                <Form.Item name={'question'} initialValue={task.content} className={styles.input}>
                  <TextArea autoSize autoFocus onChange={onChangeQuestion} />
                </Form.Item>
              ) : (
                <div
                  className={styles.question}
                  onClick={() => {
                    if (isTaskGenerating || !props.editor.isEditable) return;
                    setIsEditingQuestion(true);
                  }}
                >
                  <span className={styles.taskQuestion}>
                    {!!task.content ? (
                      task.content
                    ) : (
                      <div className={styles.emptyDesc}>{t('BiddingTool.addTaskDescriptionPlaceholder')}</div>
                    )}
                  </span>
                  {!isTaskGenerating && <FontAwesomeIcon icon={faPen} className={styles.editTitleIcon} />}
                </div>
              )}
            </div>
          </Form>
          {task?.aiSummary && (
            <TaskSummary
              summary={task.aiSummary}
              bidId={bidId}
              sourcesCount={task.secondaryReferences?.length}
              mode={'canvas'}
            />
          )}
          {!privateFile && !!(task.filePath || (primaryReference && primaryReference.length > 0)) && (
            <TenderFile
              key={task.id}
              pdfHighlights={task.filePath ? task.highlights : primaryReference?.[0].allHighlights}
              procurementId={task.originId}
              targetPath={(task.filePath || primaryReference?.[0]?.reference.path) ?? ''}
              eventSource="Canvas page"
              flexAuto
            />
          )}
          {privateFile && (
            <PrivateFilesView attachment={privateFile} highlights={primaryReference?.[0]?.allHighlights} />
          )}
        </div>
        <div
          className={activeTaskId === taskId ? styles.taskToolbar : styles.unselectedTaskToolbar}
          contentEditable={false}
          onMouseDown={handleClickTaskToolbarContainer}
        >
          <TaskFulfillmentPicker
            groupId={groupId}
            questions={[task]}
            taskValue={task.fulfilment}
            trackData={trackData}
            disabled={isTaskGenerating || !props.editor.isEditable}
          />
          <TaskRequirementPicker
            groupId={groupId}
            questions={[task]}
            taskValue={task.requirementType}
            trackData={trackData}
            disabled={isTaskGenerating || !props.editor.isEditable}
          />
          <TaskDatePicker
            groupId={groupId}
            questions={[task]}
            taskValue={task.deadline}
            trackData={trackData}
            isTaskDone={isTaskDone(task)}
            disabled={isTaskGenerating || !props.editor.isEditable}
          />

          <TaskStatusPicker
            groupId={groupId}
            questions={[task]}
            taskValue={task.status}
            trackData={trackData}
            disabled={isTaskGenerating || !props.editor.isEditable}
          />

          {bidId && (
            <AssignToQuestion
              groupId={groupId}
              assignedTo={task.assignedTo}
              questions={[task]}
              wsId={workspace?.id}
              bidId={bidId}
              eventSource={'Canvas'}
              disabled={isTaskGenerating || !props.editor.isEditable}
            />
          )}
        </div>
      </div>

      <NodeViewContent
        className={classNames(styles.editableContent, isEmpty ? styles.isEmpty : '')}
        data-placeholder={t('Canvas.Misc.taskNodePlaceholder')}
      />

      {canvasStatus.taskStatuses[task.id] &&
        canvasStatus.taskStatuses[task.id] !== StreamingCommon.AnswerStatus.NOT_STARTED && (
          <GenerateAnswerPreview task={task} hasAttachments={!!task.answer?.attachments?.list.length} />
        )}
      {idGenerateMenuVisibilityMap[taskId] && (
        <GenerateMenu taskId={taskId} hasAttachments={!!task.answer?.attachments?.list.length} />
      )}

      {bidTaskAnswerId && (
        <AttachmentList attachments={task.answer?.attachments?.list} bidTaskAnswerId={bidTaskAnswerId} />
      )}
    </NodeViewWrapper>
  );
};

export default CanvasBidTask;
