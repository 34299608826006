import React, { JSX } from 'react';
import styles from './index.module.scss';
import { Checkbox } from 'src/common';
import { IPlainDropdownValue } from '../types';

interface Props<T extends string> {
  values: IPlainDropdownValue<T>[];
  selectedValues?: T[];
  onSelect: (item: T, checked: boolean) => void;
}

export function DropdownWithMultipleValuesOverlay<T extends string>(props: Props<T>): JSX.Element {
  const { onSelect, values, selectedValues } = props;

  return (
    <div className={styles.overlay}>
      <div className={styles.container}>
        {values.map(({ value, label, children }) => (
          <Checkbox
            key={value}
            checked={selectedValues?.includes(value)}
            onChange={e => onSelect(value, e.target.checked)}
            className={styles.checkbox}
          >
            {children || label || value}
          </Checkbox>
        ))}
      </div>
    </div>
  );
}

export default DropdownWithMultipleValuesOverlay;
