import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Radio, confirm } from 'src/common';
import styles from './index.module.scss';
import modalStyles from 'src/common/Modal/index.module.scss';
import {
  useApplyDecisionMatrix,
  useGetDecisionMatrixTemplates,
  useDefaultDecisionMatrixTemplate,
  useDeleteDecisionMatrixTemplate,
  useUpdateDecisionMatrixTemplate
} from 'src/models/decisionMatrix/hooks';
import { TendiumDefaultTemplateId } from 'src/models/decisionMatrix/types';
import { useBidPreview } from 'src/models/bids/BidPreview/hooks';
import { EditableText } from 'src/shared/Blocks';
import classNames from 'classnames';
import { BoxFieldString } from 'src/models/procurements';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-light-svg-icons';

interface Props {
  onClose: () => void;
}

export const ManageTemplatesModal: FC<Props> = props => {
  const { onClose } = props;
  const { t } = useTranslation();

  const { data: bidPreviewData } = useBidPreview();
  const { data: decisionMatrixTemplates } = useGetDecisionMatrixTemplates();
  const [deleteDecisionMatrixTemplate] = useDeleteDecisionMatrixTemplate();
  const [applyDecisionMatrix] = useApplyDecisionMatrix();
  const [updateDecisionMatrixTemplate] = useUpdateDecisionMatrixTemplate();
  const [defaultDecisionMatrixTemplateId, saveDefaultDecisionMatrixTemplate] = useDefaultDecisionMatrixTemplate();

  const [editingTemplateId, setEditingTemplateId] = useState<string | null>(null);

  const onChangeDescription = useCallback(
    (val: string, id: string) => {
      updateDecisionMatrixTemplate(val, id);
      setEditingTemplateId(null);
    },
    [updateDecisionMatrixTemplate]
  );

  const onDeleteTemplate = useCallback(
    (templateId: string) => {
      confirm.confirm({
        title: t('DecisionMatrix.confirmDeletionOfTemplateTitle'),
        okText: t('Common.delete'),
        content: t('DecisionMatrix.confirmDeletionOfTemplate'),
        cancelText: t('Common.cancel'),
        className: modalStyles.confirmModal,
        centered: true,
        onOk: () => deleteDecisionMatrixTemplate(templateId)
      });
    },
    [deleteDecisionMatrixTemplate, t]
  );

  const onApplyTemplate = useCallback(
    (templateId: string) => {
      if (!!bidPreviewData?.id) {
        confirm.confirm({
          title: t('DecisionMatrix.confirmUseTemplateTitle'),
          okText: t('DecisionMatrix.apply'),
          content: t('DecisionMatrix.confirmUseTemplate'),
          cancelText: t('Common.cancel'),
          className: modalStyles.confirmModal,
          centered: true,
          onOk: () => {
            applyDecisionMatrix(bidPreviewData.id, templateId);
            onClose && onClose();
          }
        });
      } else return;
    },
    [applyDecisionMatrix, bidPreviewData?.id, onClose, t]
  );

  return (
    <>
      {decisionMatrixTemplates && (
        <Modal visible title={t('DecisionMatrix.manageTemplates')} onCancel={onClose} width={690} footer={null}>
          <div>
            {decisionMatrixTemplates.map(template => (
              <div key={template.id} className={styles.templateItem}>
                {editingTemplateId === template.id ? (
                  <EditableText
                    className={classNames(styles.templateName, styles.isEditing)}
                    onChange={val => onChangeDescription(val.string as string, template.id)}
                    onFinish={() => setEditingTemplateId(null)}
                    field={new BoxFieldString(template?.title ?? '', template.title)}
                    autoSize={true}
                    autoFocus
                  />
                ) : (
                  <>
                    <div className={styles.templateName}>
                      {template.title}
                      {template.id !== TendiumDefaultTemplateId.DEFAULT_TEMPLATE_ID && (
                        <Button
                          type={'text'}
                          onClick={() => setEditingTemplateId(template.id)}
                          icon={<FontAwesomeIcon icon={faPen} />}
                          className={styles.editButton}
                          disabled={template.id === TendiumDefaultTemplateId.DEFAULT_TEMPLATE_ID}
                        />
                      )}
                    </div>

                    <div className={styles.actionsContainer}>
                      <Radio
                        value={template.id}
                        key={template.id}
                        onClick={() => saveDefaultDecisionMatrixTemplate(template.id)}
                        checked={
                          template.id ===
                          (defaultDecisionMatrixTemplateId || TendiumDefaultTemplateId.DEFAULT_TEMPLATE_ID)
                        }
                      >
                        {t('DecisionMatrix.default')}
                      </Radio>
                      <Button type={'text'} onClick={() => onApplyTemplate(template.id)}>
                        {t('DecisionMatrix.apply')}
                      </Button>
                      <Button
                        type={'text'}
                        onClick={() => onDeleteTemplate(template.id)}
                        disabled={template.id === TendiumDefaultTemplateId.DEFAULT_TEMPLATE_ID}
                      >
                        {t('Common.remove')}
                      </Button>
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        </Modal>
      )}
    </>
  );
};

export default ManageTemplatesModal;
