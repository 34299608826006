import React, { FC, useMemo } from 'react';
import KeywordsItem from './KeywordsItem';
import { HighlightColorDict, MpKeywordAndGroup } from 'src/models/matchingProfiles/types';
import styles from '../index.module.scss';
import { v4 } from 'uuid';
import { SearchFieldTag } from 'src/common';
import { useKeywords } from 'src/models/keywords/hooks';
import { FilterLogic } from '@tendium/prom-types';
import { useTranslation } from 'react-i18next';

interface IKeywordsAreaProps {
  keywords: MpKeywordAndGroup[];
  disabled: boolean;
  newKeywords?: MpKeywordAndGroup[];
  onShowMore: () => void;
}

export const KeywordsPreview: FC<IKeywordsAreaProps> = props => {
  const { keywords, disabled, newKeywords, onShowMore } = props;
  const { data } = useKeywords();
  const groupedKeywordsToShow = useMemo(() => (keywords.length > 10 ? keywords.slice(0, 9) : keywords), [keywords]);
  const { t } = useTranslation();

  return !!groupedKeywordsToShow.length || !!newKeywords?.length ? (
    <>
      {groupedKeywordsToShow.map(group => (
        <KeywordsItem
          keywords={{ values: group.values, groupId: group.groupId ?? null }}
          key={v4()}
          disabled={disabled}
          highlightColor={group.highlightColor}
        />
      ))}
      {newKeywords?.map(group => (
        <KeywordsItem
          keywords={{ values: group.values, groupId: group.groupId ?? null }}
          key={v4()}
          disabled={disabled}
          highlightColor={group.highlightColor}
        />
      ))}
      {keywords.length > 10 ? (
        <li className={styles.keywordsItem}>
          <button type="button" className={styles.showMoreButton} onClick={onShowMore}>
            <SearchFieldTag
              value={`+${keywords.length - 9} ${t('Common.word', { count: keywords.length - 9 })}`}
              color={data.filterLogic === FilterLogic.Filter ? HighlightColorDict.Green : HighlightColorDict.Yellow}
              className={styles.showMoreLabel}
            />
          </button>
        </li>
      ) : null}
    </>
  ) : null;
};

export default KeywordsPreview;
