import React, { useCallback, useState, JSX, useMemo, ReactNode } from 'react';
import { Spinner, Dropdown } from 'src/common';
import styles from './index.module.scss';
import DropdownOverlay from './DropdownOverlay';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { DropdownType, IPlainDropdownValue } from './types';
import DropdownButton from '../DropdownButton';
import DropdownWithMultipleValuesOverlay from './DropdownWithMultipleValuesOverlay';
import { Empty } from 'antd';
import { useIsMobile } from 'src/reactiveVars';
import PlainDropDownMobile from './PlainDropDownMobile';

interface Props<T extends string, DT extends DropdownType> {
  label: ReactNode;
  values: IPlainDropdownValue<T>[];
  onSelect: (item: T, checked?: boolean) => void;
  selectedValue?: DT extends 'multiple' ? T[] : T;
  className?: string;
  disabled?: boolean;
  hideLabel?: boolean;
  dropdownIcon?: boolean;
  loading?: boolean;
  // TODO: switch to render mode (button / label) ??
  button?: boolean;
  placement?: (typeof Placements)[number];
  placeholder?: string;
  buttonClassName?: string;
  type: DT;
}

export function PlainDropDown<T extends string>(props: Props<T, 'multiple'> | Props<T, 'single'>): JSX.Element {
  const {
    label,
    selectedValue,
    values,
    disabled,
    onSelect,
    hideLabel,
    className,
    loading,
    button,
    placement,
    placeholder,
    buttonClassName,
    type,
    dropdownIcon = true
  } = props;

  const [isMobile] = useIsMobile();

  const [menuVisible, setMenuVisible] = useState(false);

  const onVisibleChange = useCallback(() => {
    setMenuVisible(prevState => !prevState);
  }, []);

  const onSelectItem = useCallback(
    (item: T, checked?: boolean) => {
      onSelect(item, checked);

      if (type === 'single') {
        onVisibleChange();
      }
    },
    [type, onSelect, onVisibleChange]
  );

  const isDisabled = !!(disabled || loading);

  const selectedValueLabel = useMemo(() => {
    const selectedItem = values.find(val => selectedValue && val.value === selectedValue);
    return selectedItem?.label || selectedItem?.value;
  }, [selectedValue, values]);

  const overlay = !values.length ? (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className={styles.emptyOverlay} />
  ) : type === 'single' ? (
    <DropdownOverlay values={values} selectedValue={selectedValue} onSelect={onSelectItem} />
  ) : (
    <DropdownWithMultipleValuesOverlay values={values} selectedValues={selectedValue} onSelect={onSelectItem} />
  );

  if (isMobile) {
    return (
      <PlainDropDownMobile
        label={label}
        isDisabled={isDisabled}
        className={className}
        loading={loading}
        placeholder={placeholder}
        selectedValueLabel={selectedValueLabel}
        isMenuVisible={menuVisible}
        dropdownIcon
        onVisibleChange={onVisibleChange}
        overlay={overlay}
      />
    );
  }

  return (
    <div className={classNames(styles.container, className)}>
      <Dropdown
        trigger={['click']}
        overlay={overlay}
        onVisibleChange={onVisibleChange}
        visible={menuVisible}
        placement={placement ?? 'bottomRight'}
        disabled={isDisabled}
        getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
      >
        {button ? (
          <DropdownButton
            label={label}
            isActive={menuVisible || !!selectedValueLabel}
            isOpen={menuVisible}
            disabled={isDisabled}
            className={buttonClassName}
          />
        ) : (
          <button className={styles.button} disabled={isDisabled}>
            {!hideLabel && <span className={styles.buttonLabel}>{label}</span>}
            {selectedValueLabel && <span className={styles.buttonValue}>{selectedValueLabel}</span>}
            {!selectedValueLabel && placeholder && (
              <span className={classNames(styles.placeholder, className)}>{placeholder}</span>
            )}
            {dropdownIcon ? (
              !loading ? (
                <FontAwesomeIcon
                  icon={faCaretDown}
                  className={classNames(styles.buttonIcon, { [styles.isActive]: menuVisible })}
                />
              ) : (
                <Spinner className={classNames(styles.buttonIcon, styles.spinner)} />
              )
            ) : null}
          </button>
        )}
      </Dropdown>
    </div>
  );
}

export default PlainDropDown;
