import React, { useCallback, useState, FC } from 'react';
import { Dropdown, Spinner } from 'src/common';
import styles from './index.module.scss';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { IGenericSearchDropdownProps } from './types';
import { DropdownContext } from './context';

interface Props extends IGenericSearchDropdownProps {
  overlay: React.ReactElement;
  overlayClassName?: string;
}
const GenericSearchDropdown: FC<Props> = props => {
  const { label, disabled, className, loading, isChanged, special, overlay, overlayClassName, buttonClassName } = props;
  const [menuVisible, setMenuVisible] = useState(false);

  const onToggleDropdown = useCallback(() => {
    setMenuVisible(prevState => !prevState);
  }, []);

  const isDisabled = !!(disabled || loading);

  return (
    <div className={classNames(styles.container, className)}>
      <DropdownContext.Provider value={onToggleDropdown}>
        <Dropdown
          trigger={['click']}
          overlay={<div className={classNames(styles.overlay, overlayClassName)}>{overlay}</div>}
          onVisibleChange={onToggleDropdown}
          visible={menuVisible}
          placement={'bottomLeft'}
          disabled={isDisabled}
          getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
        >
          <button
            className={classNames(styles.button, buttonClassName, { [styles.isActiveButton]: !!isChanged })}
            disabled={isDisabled}
          >
            {!!label && <span>{label}</span>}
            {!loading ? (
              <FontAwesomeIcon
                icon={faCaretDown}
                className={classNames(styles.buttonIcon, { [styles.isActive]: menuVisible })}
              />
            ) : (
              <Spinner className={classNames(styles.buttonIcon, styles.spinner)} />
            )}
            {special && !loading && <span className={styles.special} />}
          </button>
        </Dropdown>
      </DropdownContext.Provider>
    </div>
  );
};

export { type IGenericSearchDropdownProps } from './types';
export { useToggleDropdown } from './hooks';
export default GenericSearchDropdown;
