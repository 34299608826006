import { z } from 'zod';
import {
  isTenderVarsDiff,
  tendersSearchSchema,
  TendersSearchSchemaKeyType,
  TendersSearchSchemaType
} from '../tendersSearchSchema';

export const publishedFilterSchema = tendersSearchSchema.pick({ published: true });
export type PublishedFilterSchemaType = z.infer<typeof publishedFilterSchema>;
export const publishedFilterKeys = Object.keys(publishedFilterSchema.shape) as TendersSearchSchemaKeyType[];

export function isPublishedFilterDiff(vars1?: TendersSearchSchemaType, vars2?: TendersSearchSchemaType): boolean {
  return isTenderVarsDiff(publishedFilterKeys, vars1, vars2);
}
