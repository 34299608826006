import { ProfileType, SearchOnlyStatus, SupplierSearchType } from '@tendium/prom-types';
import {
  CreateMpVars,
  MpKeywordGroup,
  MpNewKeyword,
  MpNewKeywordGroup,
  SupplierSearchTerm,
  UpdateMpVars
} from '../../matchingProfiles/types';
import { SupplierOrg, SupplierString, TendersFilterStatus } from '../Tenders/types';
import { TendersSearchSchemaType } from './schemas';
import { searchVarsToSavedSearchProfile } from './mappers';

export function fromTenderFilterStatusToGetTenderVars(status?: TendersFilterStatus): Partial<TendersSearchSchemaType> {
  return {
    onlyStatus:
      status === TendersFilterStatus.all || status === TendersFilterStatus.awarded ? undefined : SearchOnlyStatus.Recent
  };
}

export function toUpdateSavedSearchVars(profileId: string, vars: TendersSearchSchemaType): UpdateMpVars {
  return {
    id: profileId,
    ...searchVarsToSavedSearchProfile(vars)
  };
}

export function toCreateSavedSearchVars(newProfileName: string, vars: TendersSearchSchemaType): CreateMpVars {
  return {
    name: newProfileName,
    profileType: ProfileType.ExpiringContract, //saved search
    ...searchVarsToSavedSearchProfile(vars)
  };
}

export function getQueryParamsFromTenderStatus(status?: TendersFilterStatus): {
  onlyStatus?: SearchOnlyStatus;
  awarded?: boolean;
} {
  const defaultParams = {
    onlyStatus: undefined,
    awarded: undefined
  };
  if (!status) return defaultParams;
  switch (status) {
    case TendersFilterStatus.all:
      return {
        onlyStatus: undefined,
        awarded: undefined
      };
    case TendersFilterStatus.awarded:
      return {
        onlyStatus: undefined,
        awarded: true
      };
    case TendersFilterStatus.expired:
      return {
        onlyStatus: SearchOnlyStatus.Expired,
        awarded: undefined
      };
    case TendersFilterStatus.recent:
      return {
        onlyStatus: SearchOnlyStatus.Recent,
        awarded: undefined
      };
    default:
      return defaultParams;
  }
}

export function toSupplierStrings(terms?: SupplierSearchTerm[]): SupplierString[] | undefined {
  return terms
    ?.filter(term => term.searchType === SupplierSearchType.SearchStrings)
    .map(({ value, status }) => ({ orgName: value, status }));
}
export function toSupplierIds(terms?: SupplierSearchTerm[]): SupplierOrg[] | undefined {
  return terms
    ?.filter(term => term.searchType === SupplierSearchType.OrgIds)
    .map(({ value, status }) => ({ orgId: value, status }));
}

export function toKeywordGroups(KeywordGroups?: MpKeywordGroup[]): MpNewKeywordGroup[] | undefined {
  return KeywordGroups?.map(keywordGroup => ({
    ...keywordGroup,
    values: keywordGroup.values.map(item => item.value)
  }));
}

export function sortSupplierStrings(orgStrs?: SupplierString[]): SupplierString[] | undefined {
  if (!orgStrs) return undefined;

  return [...orgStrs].sort((a, b) => a.orgName.localeCompare(b.orgName));
}

export function sortSupplierIds(orgIds?: SupplierOrg[]): SupplierOrg[] | undefined {
  if (!orgIds) return undefined;

  return [...orgIds].sort((a, b) => a.orgId.localeCompare(b.orgId));
}

export function sortKeywordGroups(keywordGroups?: MpNewKeywordGroup[]): MpNewKeywordGroup[] | undefined {
  if (!keywordGroups) return undefined;

  return [...keywordGroups]
    .map(keywordGroup => {
      return {
        ...keywordGroup,
        groupId: undefined
      };
    })
    .sort((a, b) => a.values.join(',').localeCompare(b.values.join(',')));
}

export function sortKeywordArgs(keywords?: MpNewKeyword[]): MpNewKeyword[] | undefined {
  if (!keywords) return undefined;

  return [...keywords].sort((a, b) => a.value.localeCompare(b.value));
}
