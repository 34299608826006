import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';
import { IBoxFieldEmail } from 'src/models/procurements/Tender/types';
import BlockEmpty from '../../Empty';
import { IBlockReadProps } from 'src/shared/Tender/Boxes/types';
import { Tooltip } from 'src/common';
import { toCorrectEmail } from 'src/models/procurements/helpers';
import { useIsMobile } from 'src/reactiveVars';

interface Props extends IBlockReadProps, Pick<IBoxFieldEmail, 'email'> {}
export const ReadOnlyBlockEmail: FC<Props> = ({ email, className, config }) => {
  const [isMobile] = useIsMobile();

  return email ? (
    config?.isTooltip ? (
      <Tooltip title={email}>
        <a
          className={classNames(styles.block, {
            [styles.blockMobile]: isMobile
          })}
          href={toCorrectEmail(email)}
          rel={'noopener noreferrer'}
          target={'_blank'}
        >
          <span className={classNames(styles.title, className)}>{email}</span>
        </a>
      </Tooltip>
    ) : (
      <a
        className={classNames(styles.block, {
          [styles.blockMobile]: isMobile
        })}
        href={toCorrectEmail(email)}
        rel={'noopener noreferrer'}
        target={'_blank'}
      >
        <span className={classNames(styles.title, className)}>{email}</span>
      </a>
    )
  ) : (
    <BlockEmpty />
  );
};

export default ReadOnlyBlockEmail;
