import React, { FC } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ThemeMode, ownership } from 'src/models/ownership';
import { useIsMobile } from 'src/reactiveVars';

export enum FooterView {
  full = 'full',
  info = 'info',
  logo = 'logo'
}
interface Props {
  view?: FooterView;
  themeMode?: ThemeMode;
}
export const Footer: FC<Props> = ({ themeMode, view = FooterView.info }) => {
  const { t } = useTranslation();
  const [isMobile] = useIsMobile();

  return (
    <footer
      className={classNames(styles.footer, styles[themeMode ?? ThemeMode.light], {
        [styles.mobile]: isMobile
      })}
    >
      <div className={styles.content}>
        {view !== FooterView.info && (
          <span className={classNames(styles.title, styles[ownership.brandName])}>
            <ownership.theme.logo mode={themeMode} />
          </span>
        )}
        {view !== FooterView.logo && (
          <>
            <ul className={styles.links}>
              <li className={styles.link}>
                <a href={ownership.links.LANDING_URL} rel={'noopener noreferrer'} target={'_blank'}>
                  {t('Footer.copyRights')}
                </a>
              </li>
              <li className={styles.link}>
                <a href={ownership.links.CONTACT_URL} rel={'noopener noreferrer'} target={'_blank'}>
                  {t('Footer.contact')}
                </a>
              </li>
              {!!ownership.links.POLICY_URL && (
                <li className={styles.link}>
                  <a href={ownership.links.POLICY_URL} rel={'noopener noreferrer'} target={'_blank'}>
                    {t('Footer.policy')}
                  </a>
                </li>
              )}
            </ul>
            <span className={styles.version}>{`${t('Footer.version')}: ${import.meta.env.VITE_BUILD_VERSION}`}</span>
          </>
        )}
      </div>
    </footer>
  );
};

export default Footer;
