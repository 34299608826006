import classNames from 'classnames';
import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { useContentLibrary } from 'src/models/contentLibrary/ContentLibrary/hooks';
import { PlainDropDown } from 'src/common';
import { useLoadContentLibraryTags } from 'src/models/contentLibrary/ContentLibraryTag/hooks';
import ContentLibraryTag from '../ContentLibraryTags/ContentLibraryTagsArea/ContentLibraryTag';
import { ContentLibraryTrackFilter, trackFilteringCL } from 'src/segment/events';
import { ContentLibraryEventSource } from 'src/models/contentLibrary/ContentLibrary/types';
export interface Props {
  eventSource: ContentLibraryEventSource;
}

const ContentLibraryFilterTag: FC<Props> = props => {
  const { eventSource } = props;
  const { t } = useTranslation();

  const { data: state } = useContentLibrary();
  const currentVars = useMemo(() => state?.currentVars.filters ?? {}, [state]);
  const updateVars = state?.updateVars;

  const { data: tagsData } = useLoadContentLibraryTags();

  const tags = useMemo(() => {
    return tagsData
      ? tagsData?.map(tag => {
          return {
            label: <ContentLibraryTag tag={tag} key={tag.id} disabled isListItem />,
            value: tag.id
          };
        })
      : [];
  }, [tagsData]);
  const selectedTags = useMemo(() => (currentVars?.tagIds ? currentVars.tagIds : undefined), [currentVars]);

  const onChangeTags = useCallback(
    (tagId: string, checked?: boolean) => {
      const previousValue = selectedTags || [];
      const currentValue = checked ? [...previousValue, tagId] : previousValue.filter(item => item !== tagId);

      updateVars?.({ tagIds: currentValue });
      trackFilteringCL(eventSource, ContentLibraryTrackFilter.tags);
    },
    [eventSource, selectedTags, updateVars]
  );

  return (
    <div className={classNames(styles.filter, { [styles.isActive]: selectedTags?.length })}>
      <PlainDropDown
        values={tags}
        selectedValue={selectedTags}
        label={t('ContentLibrary.tags')}
        onSelect={onChangeTags}
        buttonClassName={styles.label}
        placement="bottomLeft"
        type="multiple"
        button
      />
    </div>
  );
};

export default ContentLibraryFilterTag;
