import { trackWithSessionReplay } from '../helpers';
import { ContentLibraryFiltersDTO } from '@tendium/prom-types/schema';
/** When the users opens the editor
 * @param eventSource - The source of the event.
 */
export function trackBTOpenEditor(data: { eventSource: 'Overview' | 'Task' | 'Bid Preview' }): void {
  trackWithSessionReplay('BT open editor', {
    eventSource: data.eventSource
  });
}

/** When users use the outline editor to format their text
 * @param type - The type of outline editor action.
 */
export function trackBTOutlineEditor(data: {
  type:
    | 'heading-1'
    | 'heading-2'
    | 'heading-3'
    | 'paragraph'
    | 'align-left'
    | 'align-center'
    | 'align-right'
    | 'task-list'
    | 'ordered-list'
    | 'bullet-list'
    | 'undo'
    | 'redo'
    | 'bold'
    | 'italic'
    | 'underline';
}): void {
  trackWithSessionReplay('BT outline editor', {
    type: data.type
  });
}

/** When the users selects parts of the written text.
 * @param action - The action the user took.
 * @param question - The question the user asked the AI when using the "Writing assistant" action.
 */
export function trackBTInlineEditor(data: {
  action: 'Writing assistant' | 'Improve Writing';
  question?: string;
}): void {
  trackWithSessionReplay('BT inline editor', {
    action: data.action,
    question: data.question
  });
}

/** When a user bulk generates a group in the editor
 * @param answerType - Whether to empty existing content or overwrite
 * @param customInstructions - Whether custom instructions were provided
 * @param contentLibrary - Whether content library was used
 */
export function trackBTBulkGenerateGroup(data: {
  answerType: 'empty' | 'everything';
  customInstructions: boolean;
  contentLibrary: boolean;
}): void {
  trackWithSessionReplay('BT bulk generate', {
    answerType: data.answerType,
    customInstructions: data.customInstructions ? 'Yes' : 'No',
    contentLibrary: data.contentLibrary ? 'Yes' : 'No'
  });
}
/**
 * When a user trigger the export modal
 * @param format: PDF, DOCX
 */
export function trackBTExportModal(data: { format: 'PDF' | 'DOCX' }): void {
  trackWithSessionReplay('BT export', {
    format: data.format
  });
}
/**
 * Selects a task or a group in the outline
 * @param type - outline type
 */
export function trackBTProjectOutline(data: { type: 'Group' | 'Task' }): void {
  trackWithSessionReplay('BT project outline', {
    type: data.type
  });
}
/** Press the sources in the writing assistant.
 * @param taskId - bid task id
 */
export function trackBTSources(data: { taskId: string }): void {
  trackWithSessionReplay('BT sources', {
    taskId: data.taskId
  });
}

/** When a user uploads an attachment from editor outline
 */
export function trackBTAttachment(): void {
  trackWithSessionReplay('BT attachment');
}

/**
 * When a user use answer from content library and add it to their response
 * - File: click "Attach file" button
 * - Q&A: click "Use answer" button
 */
export function trackBTUseGeneratedAnswer(data: { type: 'File' | 'Q&A' }): void {
  trackWithSessionReplay('BT use generated answer', {
    type: data.type
  });
}

/**
 * When a user click generate response in writing assistant modal
 */
export function trackBTGenerateAnswer(data: {
  amountOfWords?: number;
  contentLibrary?: Array<keyof ContentLibraryFiltersDTO>;
  customInstructions?: boolean;
}): void {
  trackWithSessionReplay('BT generate answer', {
    amountOfWords: data.amountOfWords,
    contentLibrary: data.contentLibrary?.map(key => contentLibraryFilterMap[key]),
    customInstructions: data.customInstructions ? 'Yes' : 'No'
  });
}
const contentLibraryFilterMap: Record<keyof ContentLibraryFiltersDTO, string> = {
  search: 'Search',
  roomIds: 'Rooms',
  tagIds: 'Tags',
  createdBy: 'Created',
  assignedTo: 'Assigned',
  types: 'Type'
};
