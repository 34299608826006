import React, { FC, useCallback } from 'react';
import { useApplyChecklistTemplate, useChecklistTemplates } from 'src/models/bids/BidChecklistTemplates/hooks';
import { Button, Dropdown, Menu } from 'src/common';
import { faList } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useBidChecklist } from 'src/models/bids/BidChecklist/hooks';
import styles from '../index.module.scss';
import { trackUseChecklistTemplate } from 'src/segment/events/checklist';

export const BidChecklistUseTemplate: FC = () => {
  const { t } = useTranslation();
  const { data } = useChecklistTemplates();
  const { data: model } = useBidChecklist();
  const bidId = model?.id;
  const [applyTemplate, { loading: applying }] = useApplyChecklistTemplate();
  const onApply = useCallback(
    (templateId: string) => {
      bidId && !applying && applyTemplate({ bidId, id: templateId });
      trackUseChecklistTemplate();
    },
    [applyTemplate, applying, bidId]
  );
  const menu = (
    <Menu className={styles.templatesMenu} triggerSubMenuAction={'hover'}>
      {data &&
        data.map(template => (
          <Menu.Item key={template.id} disabled={applying}>
            <div className={styles.templateName} onClick={() => onApply(template.id)}>
              {template.name}
            </div>
          </Menu.Item>
        ))}
    </Menu>
  );
  return (
    <Dropdown overlay={menu} trigger={['click']} placement={'bottomRight'} disabled={!data?.length}>
      <Button type={'default'} icon={<FontAwesomeIcon icon={faList} />}>
        {t('Checklist.useTemplate')}
      </Button>
    </Dropdown>
  );
};

export default BidChecklistUseTemplate;
