import { IAccessControl } from '../acl/types';
import { AssignedTo } from '../users/types';
import {
  AwardStatus,
  BuyerType,
  FilterLogic,
  JunctionLogic,
  KeywordWidth,
  Language,
  ProfileType,
  SearchLogic,
  SupplierSearchType
} from '@tendium/prom-types';
import { ApiTendersVars, SupplierOrg, SupplierString } from '../procurements/Tenders/types';
import isObject from 'src/helpers/isObject';
import * as Schema from '@tendium/prom-types/schema';
import { ContractEndFilterInput, ContractEndFilterOutput } from '@tendium/prom-types/schema';
import { ApiAssignedTo } from '../users/AssignedTo/types';

export interface BTag {
  id: string;
  name: string;
  description: string;
}

export type MpCode = {
  code: string;
  description: string;
  children: MpCode[];
};
export type MpCodeTypes = 'cpvCodes' | 'nutsCodes';
export type CpvCodesResponse = { searchCpvCodes: MpCode[] };
export type NutsCodesResponse = { searchNutCodes: MpCode[] };
export function isCpvCodesResponse(u: unknown): u is CpvCodesResponse {
  return isObject(u) && 'searchCpvCodes' in u;
}

export interface CodeFilter {
  codes: string[];
  codesExact: string[];
}

export interface IApiMp extends IMpValues {
  id: string;
  name: string;
  owners: ApiAssignedTo[];
  subscribers: ApiAssignedTo[];
  accessToEdit: boolean;
  accessControl: IAccessControl;
  profileType: ProfileType;
}

export interface IMatchingProfile extends Omit<IApiMp, 'owners' | 'subscribers' | 'keywords' | 'keywordGroups'> {
  owners: AssignedTo[];
  subscribers: AssignedTo[];
  filterKeywords?: MpKeywordAndGroup[];
  highlightKeywords?: MpKeywordAndGroup[];
  apiKeywords?: MpKeyword[];
  apiKeywordGroups?: MpKeywordGroup[];
}

/** @deprecated use MpKeyword */
export interface IKeyword extends MpKeywordSettings {
  id: string;
  value: string;
}
export type MpKeywordSettings = {
  searchType: SearchType;
  searchLogic: SearchLogic;
  filterLogic: FilterLogic;
  highlightColor: HighlightColor;
  groupId?: string;
};
export type MpKeyword = MpKeywordSettings & {
  id: string;
  value: string;
};
export type MpNewKeyword = Schema.NewKeywordDTO;

export type MpKeywordGroup = MpKeywordSettings & {
  values: { id: string; value: string }[];
};

export type MpNewKeywordGroup = Schema.NewKeywordGroupDTO;

export type MpKeywordAndGroup = MpKeywordSettings & {
  values: {
    id: string;
    value: string;
  }[];
  groupId?: string;
};

export type ContractValueRange = {
  currency?: string;
  min?: number;
  max?: number;
};
export type MpContractValue = ContractValueRange & { contractValueExcludeMissingValue?: boolean };

export interface IMpValues {
  nutsCodes?: string[];
  nutsCodesExact?: string[];
  cpvCodes?: string[];
  cpvCodesExact?: string[];
  keywordWidth?: KeywordWidth;
  filterKeywordLanguages?: Language[];
  highlightKeywordLanguages?: Language[];
  keywordGroups?: MpKeywordGroup[];
  keywords?: IKeyword[];
  buyerOrgIds?: string[];
  buyerSearchStrings?: string[];
  buyerTypes?: BuyerType[];
  supplierSearchTerms?: SupplierSearchTerm[];
  supplierSearchLogic?: JunctionLogic;
  contractValueCurrency?: string;
  contractValueMax?: number;
  contractValueMin?: number;
  contractValueExcludeMissingValue?: boolean;
  contractEnd?: ContractEndFilterOutput;
  /** @deprecated */
  bTagIds?: string[];
  matchingProfileEmbedding?: MatchingProfileEmbedding;
}

/**
 * Common mutation variables used for matching profile creation and update
 */
export type MpVars = Omit<
  IMpValues,
  | 'bTagIds'
  | 'keywords'
  | 'keywordGroups'
  | 'supplierSearchTerms'
  | 'contractValueMin'
  | 'contractValueMax'
  | 'contractValueCurrency'
  | 'contractEnd'
> & {
  keywords?: MpNewKeyword[];
  keywordGroups?: MpNewKeywordGroup[];
  supplierOrgIds?: SupplierOrg[];
  supplierSearchStrings?: SupplierString[];
  contractValueRange?: ContractValueRange;
  contractEnd?: ContractEndFilterInput;
};

/*
 * Mutation variables used for matching profile creation
 */
export type CreateMpVars = MpVars & {
  name: string;
  profileType?: ProfileType;
};

/**
 * Mutation variables used for matching profile update
 */
export type UpdateMpVars = MpVars & {
  id: string;
};

export interface IMpSidebarData {
  isMPUpdated: boolean;
  resetMPData: (variables?: ApiTendersVars) => void;
}
export interface SupplierSearchTerm {
  id: string;
  searchType: SupplierSearchType;
  status: AwardStatus;
  value: string;
}

export enum SearchType {
  NoticeAndDocument = 'NoticeAndDocument',
  Notice = 'Notice',
  Document = 'Document'
}

export enum HighlightColorDict {
  Green = '#D9F7BE',
  Yellow = '#FFFB8F',
  Red = '#FFCCC7'
}

export enum HighlightColor {
  Green = 'Green',
  Yellow = 'Yellow',
  Red = 'Red'
}
export const DEFAULT_KEYWORD_SETTING: MpKeywordSettings = {
  searchType: SearchType.Notice,
  searchLogic: SearchLogic.PrefixMatch,
  filterLogic: FilterLogic.Filter,
  highlightColor: HighlightColor.Yellow
};

export const DOCUMENTS_SEARCH_TYPE = [SearchType.Document, SearchType.NoticeAndDocument];
export const SEARCH_TYPES = [...DOCUMENTS_SEARCH_TYPE, SearchType.Notice];
export const ADVANCED_FILTER_LOGIC = [KeywordWidth.Broad, KeywordWidth.Narrow];

export interface IMpSnapshot {
  initial?: IMpSnapshotValues;
  isUpdated: boolean;
}

export interface MatchingProfileEmbedding {
  id: string;
  text: string;
}
export type IMpSnapshotValues = IMpValues & { id?: string };

export const INIT_SNAPSHOT: IMpSnapshot = {
  initial: undefined,
  isUpdated: false
};

export const COMPANY_FILTER_LIMIT = 100;

export const SELECTABLE_LOGIC = [SearchLogic.ExactMatch, SearchLogic.PrefixMatch];
