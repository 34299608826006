import React, { FC, ReactNode } from 'react';
import styles from './index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/pro-solid-svg-icons';

interface Props {
  label: ReactNode;
  id: string;
  onRemove: (id: string) => void;
}

const FilterItem: FC<Props> = ({ label, id, onRemove }) => {
  return (
    <div className={styles.filterItem}>
      {label}
      <button type="button" onClick={() => onRemove(id)} className={styles.removeButton}>
        <FontAwesomeIcon icon={faX} className={styles.removeButtonIcon} />
      </button>
    </div>
  );
};

export default FilterItem;
