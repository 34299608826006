import { useCurrentEditor } from '@tiptap/react';
import React, { FC, useCallback, useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { BidTask } from 'src/models/bids/BidTask';
import { useSetBidQuestionAnswer } from 'src/models/bids/BidTask/hooks';
import { findNodeById, scrollToEditorElement } from 'src/models/canvas/helpers';
import { useCanvasGenerationStatus } from 'src/reactiveVars';
import { StreamingCommon } from '@tendium/prom-types/subscriptions';
import ReactMarkdown from 'react-markdown';
import { useCanvasState, useGenerateAnswers } from 'src/models/canvas/hooks';
import styles from './index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStars } from '@fortawesome/pro-solid-svg-icons';
import { useFloating, offset, shift, autoUpdate } from '@floating-ui/react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

interface Props {
  task: BidTask;
  hasAttachments?: boolean;
}

const GenerateAnswerPreview: FC<Props> = ({ task, hasAttachments = false }) => {
  const { editor } = useCurrentEditor();
  const { canvasStatus, updateCanvasStatus } = useCanvasGenerationStatus();
  const [setAnswer] = useSetBidQuestionAnswer();
  const [generateAnswers] = useGenerateAnswers();
  const { t } = useTranslation();
  const { isBulkGenerating, retryGenerateData } = useCanvasState();
  const previewButtonsId = `generate-preview-buttons-${task.id}`;
  const isTaskGenerating = useMemo(
    () =>
      canvasStatus.taskStatuses[task.id] === StreamingCommon.AnswerStatus.IN_PROGRESS ||
      canvasStatus.taskStatuses[task.id] === StreamingCommon.AnswerStatus.WAITING_FOR_STREAM,
    [canvasStatus.taskStatuses, task.id]
  );
  const isTaskInErrorState = useMemo(
    () =>
      canvasStatus.taskStatuses[task.id] === StreamingCommon.AnswerStatus.ERROR ||
      canvasStatus.taskStatuses[task.id] === StreamingCommon.AnswerStatus.MISSING_CHUNKS_ERROR ||
      canvasStatus.taskStatuses[task.id] === StreamingCommon.AnswerStatus.TOKEN_LIMIT_ERROR,
    [canvasStatus.taskStatuses, task.id]
  );

  // Is done if we get status DONE from the subscription OR
  // the task has pending content but no status at all <- (can happen if we have generated pending content but refresh the page)
  const isTaskDoneGenerating = useMemo(
    () =>
      canvasStatus.taskStatuses[task.id] === StreamingCommon.AnswerStatus.DONE || !canvasStatus.taskStatuses[task.id],
    [canvasStatus.taskStatuses, task.id]
  );

  const isSingleTaskGenerated = useMemo(
    () => !isBulkGenerating && isTaskDoneGenerating && !!task?.answer?.pendingContent,
    [isBulkGenerating, isTaskDoneGenerating, task?.answer?.pendingContent]
  );

  const previewAnchorRef = useRef<HTMLDivElement | null>(null);
  const previewButtonsFloating = useFloating({
    placement: 'bottom-start',
    middleware: [offset(-20), shift()],
    elements: {
      reference: previewAnchorRef.current
    },
    whileElementsMounted: autoUpdate
  });

  const clearPending = useCallback((): void => {
    setAnswer({
      questionId: task.id,
      questionGroupId: task.groupId,
      answer: { pendingContent: null, pendingSourceIds: null }
    });
  }, [setAnswer, task.groupId, task.id]);

  const declineSuggestion = useCallback((): void => {
    updateCanvasStatus({
      taskId: task.id,
      status: StreamingCommon.AnswerStatus.NOT_STARTED
    });
    clearPending();
  }, [clearPending, task.id, updateCanvasStatus]);

  const acceptSuggestion = useCallback((): void => {
    if (!editor || !task.answer?.pendingContent) return;

    const { node, pos } = findNodeById(editor, task.id);
    if (!node || pos === null) return;

    const insertPosition = isBulkGenerating ? pos + 2 : pos + node.nodeSize - 2;

    editor.chain().focus().insertContentAt(insertPosition, task.answer.pendingContent).run();

    const pendingSourceIds = task.answer.pendingSources?.map(pendingSource => pendingSource.id);

    !isBulkGenerating &&
      setAnswer({
        questionId: task.id,
        questionGroupId: task.groupId,
        answer: { sourceIds: pendingSourceIds }
      });

    updateCanvasStatus({
      taskId: task.id,
      status: StreamingCommon.AnswerStatus.NOT_STARTED
    });

    clearPending();
  }, [
    clearPending,
    editor,
    isBulkGenerating,
    setAnswer,
    task.answer?.pendingContent,
    task.answer?.pendingSources,
    task.groupId,
    task.id,
    updateCanvasStatus
  ]);

  const retry = useCallback(() => {
    clearPending();
    generateAnswers(retryGenerateData[task.id]);

    updateCanvasStatus({
      taskId: task.id,
      status: StreamingCommon.AnswerStatus.WAITING_FOR_STREAM
    });

    editor?.setEditable(false);
  }, [clearPending, editor, generateAnswers, retryGenerateData, task.id, updateCanvasStatus]);

  // If we are bulk generating immediately set the pending answer when generation finished for that task
  useEffect(() => {
    if (isBulkGenerating && isTaskDoneGenerating) acceptSuggestion();
  }, [acceptSuggestion, isBulkGenerating, isTaskDoneGenerating]);

  // Focus back on editor when we have finished generating single task
  // Also scroll user to options
  useLayoutEffect(() => {
    scrollToEditorElement(editor, isSingleTaskGenerated, previewButtonsId);
  }, [editor, isSingleTaskGenerated, previewButtonsId]);

  if (!editor) return null;
  if (canvasStatus.taskStatuses[task.id] === StreamingCommon.AnswerStatus.NOT_STARTED) return null;

  return (
    <div
      className={classNames(styles.generateAnswerPreview, {
        [styles.hasAttachments]: hasAttachments
      })}
      ref={previewAnchorRef}
      contentEditable={false}
    >
      {task?.answer?.pendingContent && <ReactMarkdown>{task.answer.pendingContent}</ReactMarkdown>}

      {isTaskGenerating && (
        <div className={styles.writing}>
          <FontAwesomeIcon icon={faStars} color="var(--brand-re)" />
          <span className={styles.writingText}>{t('Canvas.Generation.writing')}</span>
        </div>
      )}

      {isSingleTaskGenerated && (
        <div
          ref={previewButtonsFloating.refs.setFloating}
          style={{
            position: previewButtonsFloating.strategy,
            top: previewButtonsFloating.y ?? 0,
            left: previewButtonsFloating.x ?? 0
          }}
          className={styles.previewButtons}
          id={previewButtonsId}
        >
          <button className={styles.previewButton} onClick={() => acceptSuggestion()}>
            {t('Canvas.Generation.accept')}
          </button>
          <button className={classNames(styles.previewButton, styles.declineButton)} onClick={declineSuggestion}>
            {t('Canvas.Generation.decline')}
          </button>
        </div>
      )}

      {isTaskInErrorState && !!task?.answer?.pendingContent && (
        <div
          ref={previewButtonsFloating.refs.setFloating}
          style={{
            position: previewButtonsFloating.strategy,
            top: previewButtonsFloating.y ?? 0,
            left: previewButtonsFloating.x ?? 0
          }}
          className={styles.errorContainer}
        >
          <span className={styles.errorText}>{t('Canvas.Generation.generationFailed')}</span>
          <div className={styles.previewButtons}>
            {retryGenerateData[task.id] && (
              <button className={styles.previewButton} onClick={retry}>
                {t('Canvas.Generation.retry')}
              </button>
            )}
            <button className={classNames(styles.previewButton, styles.declineButton)} onClick={declineSuggestion}>
              {t('Common.cancel')}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default GenerateAnswerPreview;
