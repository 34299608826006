import { ContractEndFilterOutput } from '@tendium/prom-types/schema';
import { TFunction } from 'i18next';
import { filterTreeNodes } from 'src/common/betaComponents/MpTree/utils';
import { notNull } from 'src/helpers/typescript';
import { CodeFilter, CreateMpVars, MpCode, MpCodeTypes } from 'src/models/matchingProfiles/types';
import { IPreferencesTreeNode } from 'src/shared/MatchingProfile/PreferencesTree/types';
import { MatchingProfile } from './index';
import { ContractEndRangeMode, ProfileType } from '@tendium/prom-types';
import dayjs from '../../helpers/dayjs';

export function toPreferencesTree(
  type: MpCodeTypes,
  nodes: readonly MpCode[],
  t: TFunction,
  filter?: CodeFilter
): IPreferencesTreeNode[] {
  function applySort(values: IPreferencesTreeNode[]): IPreferencesTreeNode[] {
    return type === 'nutsCodes' ? values.sort((a, b) => a.label.localeCompare(b.label)) : values;
  }
  const handleNode = (node: MpCode): IPreferencesTreeNode => {
    const { code, children } = node;
    const [mainCode] = code.split('-');
    return {
      key: code,
      label: t(`${type}|${type === 'cpvCodes' ? mainCode : code}`),
      children: applySort(children.map(handleNode))
    };
  };
  const sortedCodes = applySort(nodes.map(handleNode));

  if (filter) {
    return filterTreeNodes(sortedCodes, filter, true);
  }
  return sortedCodes;
}

export function toCpvCodes(codes: string[]): string[] {
  return codes.map(code => code.replace(/[^\d-]/g, '').match(/^\d{8}-\d/)?.[0] || null).filter(notNull);
}

export function toContractEnd(contractEnd?: ContractEndFilterOutput): ContractEndFilterOutput | undefined {
  if (!contractEnd) return;
  const isRelative = contractEnd?.rangeMode === ContractEndRangeMode.RELATIVE;
  const isAbsolute = contractEnd?.rangeMode === ContractEndRangeMode.ABSOLUTE;

  return {
    ...(isAbsolute
      ? {
          start: contractEnd?.start ? dayjs(contractEnd.start).utc().toDate() : undefined,
          end: contractEnd?.end ? dayjs(contractEnd.end).utc().toDate() : undefined
        }
      : undefined),
    ...(isRelative
      ? {
          relativeStart: contractEnd?.relativeStart ?? undefined,
          relativeEnd: contractEnd?.relativeEnd ?? undefined
        }
      : undefined),
    searchMode: contractEnd?.searchMode,
    rangeMode: contractEnd?.rangeMode,
    strictPeriodOverlap: contractEnd?.strictPeriodOverlap,
    contractDurationYearsFallback: contractEnd?.contractDurationYearsFallback
  };
}

export function toCopyMpVars(newProfileName: string, mp?: MatchingProfile): CreateMpVars {
  return {
    name: newProfileName,
    profileType: ProfileType.Matching,
    //cpvCodes
    cpvCodes: mp?.cpvCodes,
    cpvCodesExact: mp?.cpvCodesExact,
    //nutsCodes
    nutsCodes: mp?.nutsCodes,
    nutsCodesExact: mp?.nutsCodesExact,
    //keywords
    filterKeywordLanguages: mp?.filterKeywordLanguages,
    highlightKeywordLanguages: mp?.highlightKeywordLanguages,
    keywordWidth: mp?.keywordWidth,
    keywordGroups: mp?.apiKeywordGroups?.map(keyword => ({
      searchType: keyword.searchType,
      searchLogic: keyword.searchLogic,
      filterLogic: keyword.filterLogic,
      highlightColor: keyword.highlightColor,
      values: keyword.values.map(item => item.value)
    })),
    keywords: mp?.apiKeywords?.map(keyword => ({
      searchType: keyword.searchType,
      searchLogic: keyword.searchLogic,
      filterLogic: keyword.filterLogic,
      highlightColor: keyword.highlightColor,
      value: keyword.value
    })),
    //buyers
    buyerOrgIds: mp?.buyerOrgIds,
    buyerSearchStrings: mp?.buyerSearchStrings,
    buyerTypes: mp?.buyerTypes,
    //contract value
    contractValueRange: {
      currency: mp?.contractValueCurrency,
      min: mp?.contractValueMin,
      max: mp?.contractValueMax
    },
    contractValueExcludeMissingValue: mp?.contractValueExcludeMissingValue
  };
}
