import React from 'react';
import { FC } from 'react';
import { useBidPreview } from 'src/models/bids/BidPreview/hooks';
import styles from './index.module.scss';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDecisionMatrixScores } from 'src/models/decisionMatrix/hooks';

export const MatrixScore: FC = () => {
  const { t } = useTranslation();

  const { data: bidPreviewData } = useBidPreview();
  const { totalScore, avgScore } = useDecisionMatrixScores(bidPreviewData?.decisionMatrix);

  return (
    <div className={styles.avgScoreContainer}>
      <div className={styles.avgScoreContainerInner}>
        <span>{t('DecisionMatrix.totalPoints')}</span>
        <span>{totalScore}</span>
      </div>
      <div className={styles.avgScoreContainerInner}>
        <span>{t('DecisionMatrix.avgPoints')}</span>
        {!!avgScore && (
          <span className={classNames(styles.avgScoreItem, styles[`avgScore-${Math.floor(avgScore)}`])}>
            {avgScore}
          </span>
        )}
      </div>
    </div>
  );
};

export default MatrixScore;
