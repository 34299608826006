import { Editor } from '@tiptap/core';
import { Transaction } from '@tiptap/pm/state';
import { TenjinSettingsRequest } from '../biddingGPT/types';
import { ContentLibraryFiltersDTO } from '@tendium/prom-types/schema';
import { StreamingCommon } from '@tendium/prom-types/subscriptions';
import { RcFile } from 'antd/lib/upload/interface';
import { ApolloError } from '@apollo/client';

export type UpdateEditorParams = {
  transaction: Transaction;
  editor: Editor;
};

export type CanvasURLParams = {
  id: string;
  groupId: string;
};
export interface GenerateAnswerResponse {
  generateAnswer: boolean;
}

export interface GenerateAnswerRequest {
  settings?: TenjinSettingsRequest;
  taskIds: string[];
  filters?: ContentLibraryFiltersDTO;
}

export interface GenerateIterationContentRequest {
  taskId: string;
  streamId: string;
  settings?: TenjinSettingsRequest;
}

export type EditorCanvasContextType = {
  idGenerateMenuVisibilityMap: Record<string, boolean>; // Track menu visibility per task
  toggleGenerateMenuVisibility: (taskId: string, isVisible?: boolean) => void; // Function to toggle menu visibility
  noTasksMatchFilters: boolean;
  isBulkGenerating: boolean;
  setIsBulkGenerating: React.Dispatch<React.SetStateAction<boolean>>;
  retryGenerateData: Record<string, GenerateAnswerRequest>;
  setRetryGenerateData: (taskId: string, data: GenerateAnswerRequest) => void;
  retryIterateContentData: GenerateIterationContentRequest | null;
  setRetryIterateContentData: React.Dispatch<React.SetStateAction<GenerateIterationContentRequest | null>>;
  isEditorLoading: boolean;
  setIsEditorLoading: React.Dispatch<React.SetStateAction<boolean>>;
  editorError?: ApolloError;
};

export type CanvasTaskGenerationStatus = Record<string, StreamingCommon.AnswerStatus>;
export type CanvasGroupGenerationStatus = {
  groupId: string;
  status: StreamingCommon.AnswerStatus;
};
export type CanvasIterationContentStatus = {
  iterateContentId: string;
  status: StreamingCommon.AnswerStatus;
  iterationPendingContent: string;
  iterationContentTaskId?: string;
};
type CanvasStatus = {
  taskStatuses: CanvasTaskGenerationStatus;
  groupIdStatus: CanvasGroupGenerationStatus;
  iterateContentStatus: CanvasIterationContentStatus;
};

export type UpdateCanvasStatusParams = {
  taskId?: string;
  groupId?: string;
  iterateContentId?: string;
  iterationPendingContent?: string;
  status: StreamingCommon.AnswerStatus;
};

export type UseCanvasGenerationStatusReturn = {
  canvasStatus: CanvasStatus;
  updateCanvasStatus: ({
    taskId,
    groupId,
    iterateContentId,
    iterationPendingContent,
    status
  }: UpdateCanvasStatusParams) => void;
  resetIterationContentStatus: () => void;
};

export interface ExportBidTasksInput {
  bidTaskIds: string[];
  format: string;
}

export interface ExportBidTasksOutput {
  exportBidTasks: { operationId: string };
}

export interface GetBidTaskAnswerAttachmentUploadUrlInput {
  bidTaskAnswerId: string;
  fileName: string;
}

export interface GetBidTaskAnswerAttachmentDownloadUrlInput {
  bidTaskAnswerId: string;
  fileName: string;
}

export interface DeleteBidTaskAnswerAttachmentsInput {
  fileNames: string[];
  bidTaskAnswerId: string;
}

export interface ListAttachment {
  parentId: string;
  fileName: string;
  loading?: boolean; // client side loading state
}

export interface ListAttachmentsResult {
  list: ListAttachment[];
}

export interface DeleteAttachmentsResult {
  deletedPaths: string[];
}

export interface UploadAttachmentFnInput {
  file: RcFile | File;
  attachments: ListAttachment[];
  bidTaskAnswerId?: string;
}

export enum ContentLibraryAskAIResponseLength {
  BRIEF = 'BRIEF',
  DETAILED = 'DETAILED',
  CUSTOM = 'CUSTOM'
}

export enum ContentLibraryAskAIPredefinedSettings {
  IMPROVE_WRITING = 'IMPROVE_WRITING',
  CUSTOM = 'CUSTOM'
}
