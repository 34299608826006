import React, { FC, ReactNode } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './index.module.scss';

interface Props {
  title: string;
  desc?: ReactNode;
  icon?: IconProp;
  tag?: ReactNode;
  actions?: ReactNode;
  link?: ReactNode;
  iconColor?: string;
}
export const PageHeading: FC<Props> = props => {
  const { title, desc, icon, tag, actions, link, iconColor } = props;

  return (
    <div className={styles.header}>
      <div className={styles.heading}>
        <h2 className={styles.title}>
          <span>{title}</span>
          {icon && (
            <FontAwesomeIcon
              icon={icon}
              className={styles.headerIcon}
              color={iconColor ?? 'var(--grey-re)'}
              fixedWidth
            />
          )}
          {tag && <div className={styles.tag}>{tag}</div>}
        </h2>
        {desc && <div className={styles.desc}>{desc}</div>}
        {link && <p className={styles.link}>{link}</p>}
      </div>
      {actions && <div className={styles.actions}>{actions}</div>}
    </div>
  );
};

export default PageHeading;
