import { Button, Skeleton } from 'antd';
import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useLoadNotifications, useMarkNotificationsAsRead } from 'src/models/notifications/hooks';
import { Paths } from 'src/pages/paths';
import { trackClickOnShowAllNotifications } from 'src/segment/events';
import { NotificationsList } from 'src/shared';
import styles from './index.module.scss';
import { useOverviewBackLink } from 'src/models/procurements/hooks';

const NotificationsTab: FC = () => {
  const { t } = useTranslation();

  const { data, loading } = useLoadNotifications();
  const { set: setBackLink } = useOverviewBackLink();

  const [markAllNotificationsAsRead] = useMarkNotificationsAsRead();

  const isUnreadNotifications = useMemo(
    () => !!data && !!data.notifications.filter(notification => !notification.isRead).length,
    [data]
  );
  const markAllAsRead = useCallback(() => {
    markAllNotificationsAsRead();
  }, [markAllNotificationsAsRead]);

  const onClick = useCallback(() => {
    trackClickOnShowAllNotifications();
    setBackLink();
  }, [setBackLink]);

  return (
    <div className={styles.container}>
      {isUnreadNotifications && (
        <div className={styles.markAllAsRead}>
          <Button type={'link'} onClick={markAllAsRead} className={styles.button}>
            {t('Header.markAllAsRead')}
          </Button>
        </div>
      )}
      {data && data.notifications.length ? (
        <>
          <div className={styles.notificationsList}>
            {loading ? (
              <Skeleton loading active className={styles.loader} />
            ) : (
              <NotificationsList showMarkDown={false} limit={10} />
            )}
          </div>

          <div className={styles.footer}>
            {/* FIXME: should we remove this link and corresponding page? */}
            <Link onClick={onClick} to={{ pathname: Paths.NOTIFICATIONS_PAGE }} className={styles.link}>
              {t('Common.showAll')}
            </Link>
          </div>
        </>
      ) : (
        <div className={styles.noNotifications}>
          <p>{t('Notifications.noNotifications')}</p>
        </div>
      )}
    </div>
  );
};

export default NotificationsTab;
