import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetSignedUrlUpload } from 'src/models/contentLibrary/ContentLibrary/hooks';
import UploadFile from '../UploadFile';
import { ApiContentLibraryUploadRequest } from 'src/models/contentLibrary/ContentLibrary/types';
import { FileExt } from 'src/models/file/types';
import { notification } from 'src/common';

interface Props {
  onClose: () => void;
  extensions: FileExt[];
}

export const UploadFileModal: FC<Props> = props => {
  const [getSignedUrl] = useGetSignedUrlUpload();
  const { onClose, extensions } = props;
  const { t } = useTranslation();

  const onUpload = useCallback(
    async (
      queryVariable: ApiContentLibraryUploadRequest
    ): Promise<{ signedUrl: string; contentRowId?: string } | undefined> => {
      try {
        const response = await getSignedUrl({ variables: queryVariable });
        const signedUrl = response?.data?.getSignedUrlUpload?.putUrl;
        const contentRowId = response?.data?.getSignedUrlUpload?.contentRowId;

        if (!signedUrl) {
          return;
        }

        return { signedUrl, contentRowId };
      } catch (err) {
        notification.error({
          description: t('Common.unknownErrorDesc'),
          message: t('Common.unknownError')
        });
        return;
      }
    },
    [getSignedUrl, t]
  );

  return <UploadFile extensions={extensions} onClose={onClose} onUpload={onUpload} />;
};

export default UploadFileModal;
