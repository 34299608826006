import React, { FC, useCallback, useEffect } from 'react';
import { notification, confirm } from 'src/common';
import { useRemoveBid } from 'src/models/bids/hooks';
import modalStyles from 'src/common/Modal/index.module.scss';
import { QuickActions, QuickActionsItem } from 'src/shared/QuickActions';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { BidItemType, WorkspaceStatusCategory } from '@tendium/prom-types/tender';
import { faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { useNavigate } from 'react-router';
import { Paths } from 'src/pages/paths';

interface Props {
  bidId: string;
  wsId: string;
  originId: string;
  originType: BidItemType;
  categoryName?: WorkspaceStatusCategory;
}
const BidQuickActions: FC<Props> = props => {
  const { bidId, wsId, originType, originId, categoryName } = props;
  const { t } = useTranslation();
  const [removeBid, { loading, error }] = useRemoveBid();
  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      notification.error({
        message: t('Common.unknownError'),
        description: t('Common.unknownErrorDesc')
      });
    }
  }, [error, t]);

  const onRemove = useCallback(() => {
    confirm.confirm({
      title: t('BidSpaces.removeBidConfirmationTitle'),
      okText: t('Common.delete'),
      content: t('BidSpaces.removeBidConfirmationContent'),
      cancelText: t('Common.cancel'),
      className: modalStyles.confirmModal,
      centered: true,
      onOk() {
        removeBid(bidId, wsId, originId, originType, categoryName);
        navigate(`${Paths.BIDSPACE_PAGE.replace(':wsId', wsId)}`);
      }
    });
  }, [bidId, categoryName, navigate, originId, originType, removeBid, t, wsId]);
  return (
    <QuickActions className={styles.bidActionsMenu} placement={'bottomRight'}>
      <QuickActionsItem
        key={'removeBid'}
        onClick={onRemove}
        title={t('BidSpaces.removeBid')}
        loading={loading}
        icon={faTrashCan}
        danger
      />
    </QuickActions>
  );
};

export default BidQuickActions;
