import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { generateSchema } from 'src/models/canvas/schemas';
import styles from './index.module.scss';
import { useCanvasState, useGenerateAnswers, useLoadGroupTasksInCanvas } from 'src/models/canvas/hooks';
import { useCloseSidebar, useOpenSidebar } from 'src/shared/InfoSidebar/hooks';
import { SidebarMode } from 'src/shared/InfoSidebar/types';
import { CanvasURLParams, ContentLibraryAskAIResponseLength, GenerateAnswerRequest } from 'src/models/canvas/types';
import { useParams } from 'react-router-dom';
import ContentLibraryGenerateFilters from './ContentLibraryGenerateFilters';
import { useContentLibraryQpVars } from 'src/models/contentLibrary/ContentLibrary/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStars } from '@fortawesome/pro-solid-svg-icons';
import { faX } from '@fortawesome/pro-solid-svg-icons';
import { Button, InfoIcon } from 'src/common';
import { useCurrentEditor } from '@tiptap/react';
import { useCanvasGenerationStatus } from 'src/reactiveVars';
import { StreamingCommon } from '@tendium/prom-types/subscriptions';
import { useSetBidQuestionAnswer } from 'src/models/bids/BidTask/hooks';
import { useTranslation } from 'react-i18next';
import { remToPixels } from 'src/helpers/remToPixels';
import classNames from 'classnames';
import { trackBTGenerateAnswer } from 'src/segment/events';
import { ContentLibraryFiltersDTO } from '@tendium/prom-types/dist/schema/tenjin/content';
interface Props {
  taskId: string;
  hasAttachments?: boolean;
}

type GenerateForm = z.infer<typeof generateSchema>;

const GenerateMenu: FC<Props> = ({ taskId, hasAttachments = false }) => {
  const { toggleGenerateMenuVisibility, setRetryGenerateData } = useCanvasState();
  const openSidebar = useOpenSidebar();
  const closeSideBar = useCloseSidebar();
  const { id: bidId } = useParams<CanvasURLParams>();
  const contentLibraryFilters = useContentLibraryQpVars();
  const { editor } = useCurrentEditor();
  const { updateCanvasStatus } = useCanvasGenerationStatus();
  const [setAnswer] = useSetBidQuestionAnswer();
  const { t } = useTranslation();
  const { data: tasks } = useLoadGroupTasksInCanvas();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = useForm<GenerateForm>({
    resolver: zodResolver(generateSchema),
    defaultValues: {
      responseLength: 'brief',
      wordCount: undefined,
      customInstructions: undefined
    }
  });

  const [generateAnswers] = useGenerateAnswers();
  const selectedResponseLength = watch('responseLength');
  const wordCount = watch('wordCount');
  const customInstructionsMinHeight = 2.6; // In rem
  const customInstructionsMaxHeight = 14; // In rem

  const onSubmit = (data: GenerateForm): void => {
    const wordCount = !!data.wordCount ? data.wordCount : undefined;
    const responseLength = wordCount
      ? ContentLibraryAskAIResponseLength.CUSTOM
      : data.responseLength === 'brief'
      ? ContentLibraryAskAIResponseLength.BRIEF
      : data.responseLength === 'detailed'
      ? ContentLibraryAskAIResponseLength.DETAILED
      : undefined;

    const request: GenerateAnswerRequest = {
      taskIds: [taskId],
      settings: {
        customInstruction: data.customInstructions,
        targetWordCount: wordCount,
        contentLibraryAskAIResponseLength: responseLength
      },
      ...contentLibraryFilters
    };

    tasks.forEach(task => {
      if (
        (task.answer?.pendingContent && task.answer.pendingContent.length) ||
        (task.answer?.pendingSources && task.answer.pendingSources.length)
      ) {
        setAnswer({
          questionId: task.id,
          questionGroupId: task.groupId,
          answer: { pendingContent: null, pendingSourceIds: null }
        });
        updateCanvasStatus({
          taskId: task.id,
          status: StreamingCommon.AnswerStatus.NOT_STARTED
        });
      }
    });
    trackBTGenerateAnswer({
      amountOfWords: wordCount,
      contentLibrary: Object.keys(contentLibraryFilters.filters) as Array<keyof ContentLibraryFiltersDTO>,
      customInstructions: !!data.customInstructions
    });

    generateAnswers(request);

    updateCanvasStatus({
      taskId,
      status: StreamingCommon.AnswerStatus.WAITING_FOR_STREAM
    });

    setRetryGenerateData(taskId, request);

    toggleGenerateMenuVisibility(taskId, false);
    closeSideBar();
    editor?.setEditable(false);
  };

  return (
    <div
      className={classNames(styles.generateMenu, { [styles.hasAttachments]: hasAttachments })}
      contentEditable={false}
    >
      <div className={styles.topRow}>
        <div className={styles.titleGroup}>
          <FontAwesomeIcon icon={faStars} color="var(--brand-re)" />
          <h3 className={styles.title}>{t('Canvas.Generation.askAI')}</h3>
        </div>
        <button className={styles.closeButton} onClick={() => toggleGenerateMenuVisibility(taskId, false)}>
          <FontAwesomeIcon icon={faX} className={styles.closeIcon} />
        </button>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className={styles.generateMenuForm}>
        <div className={styles.formCol}>
          <label className={styles.rowGroup}>
            <span className={styles.formText}>{t('Canvas.Generation.answerType')}</span>
            <InfoIcon
              desc={
                <>
                  <span>{t('Canvas.Generation.briefResponsesInfo')}</span>
                  <br />
                  <span>{t('Canvas.Generation.detailedResponsesInfo')}</span>
                </>
              }
            />
          </label>
          <div className={styles.rowGroup}>
            <Button
              type={'default'}
              {...register('responseLength')}
              onClick={() => setValue('responseLength', 'brief')}
              className={selectedResponseLength === 'brief' && !wordCount ? styles.selectedButton : ''}
            >
              {t('Canvas.Generation.briefResponses')}
            </Button>
            <Button
              type={'default'}
              {...register('responseLength')}
              onClick={() => setValue('responseLength', 'detailed')}
              className={selectedResponseLength === 'detailed' && !wordCount ? styles.selectedButton : ''}
            >
              {t('Canvas.Generation.detailedResponses')}
            </Button>
            <input
              className={styles.formInput}
              type="number"
              placeholder={t('Canvas.Generation.approximateWordCount')}
              {...register('wordCount', { valueAsNumber: true })}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter') e.preventDefault();
              }}
            />
          </div>
          {errors.wordCount && <p>{errors.wordCount.message}</p>}
        </div>

        <div className={styles.formCol}>
          <label className={styles.formText}>{t('Canvas.Generation.contentLibrarySources')}</label>
          <Button
            type={'default'}
            onClick={() => openSidebar({ id: bidId ?? null, mode: SidebarMode.TOOLBOX_CONTENT_LIBRARY })}
            className={styles.sourceButton}
          >
            {t('Canvas.Generation.chooseSources')}
          </Button>
          <ContentLibraryGenerateFilters />
        </div>

        <div className={styles.formCol}>
          <label className={styles.formText}>{t('Canvas.BulkGenerate.customInstructions')}</label>
          <textarea
            rows={1}
            className={styles.formTextarea}
            {...register('customInstructions')}
            placeholder="Add instructions..."
            onInput={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
              const textarea = event.target;
              const minHeight = remToPixels(customInstructionsMinHeight);
              const maxHeight = remToPixels(customInstructionsMaxHeight);

              textarea.style.height = `${minHeight}px`; // Reset height to recalculate
              textarea.style.height = `${Math.min(textarea.scrollHeight, maxHeight)}px`; // Grow until max height
            }}
          />
        </div>

        <Button htmlType="submit" type={'primary'} className={styles.submitButton}>
          {t('Canvas.Generation.generateAnswer')}
        </Button>
      </form>
    </div>
  );
};

export default GenerateMenu;
