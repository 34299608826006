import React, { FC, useMemo, useCallback } from 'react';
import { Tooltip } from 'src/common';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment } from '@fortawesome/pro-light-svg-icons';
import { faComment as faCommentSolid } from '@fortawesome/pro-solid-svg-icons';
import { useUpdateCurrentTenderBox } from 'src/models/procurements/Tender/hooks';
import { SidebarMode } from 'src/shared/InfoSidebar/types';
import { useOpenSidebar } from 'src/shared/InfoSidebar/hooks';
import { ITenderBox } from 'src/models/procurements/Tender/types';
import { useIsMobile } from 'src/reactiveVars';

interface Props {
  box: ITenderBox;
  className?: string;
}

export const BoxActions: FC<Props> = ({ box, className }) => {
  const { id, comments } = box;
  const { t } = useTranslation();
  const [isMobile] = useIsMobile();

  const updateCurrentBox = useUpdateCurrentTenderBox();
  const openSidebar = useOpenSidebar();

  const commentsCount = useMemo(() => (comments || []).length, [comments]);

  const onShowSidebar = useCallback(() => {
    openSidebar({ id, mode: SidebarMode.COMMENTS });
    updateCurrentBox?.(id);
  }, [id, openSidebar, updateCurrentBox]);

  if (isMobile) return null;

  return (
    <div className={classNames(styles.actions, className)}>
      <Tooltip title={t('Comments.addComment')}>
        <button
          className={classNames(styles.action, styles.actionComment, {
            [styles.isActive]: !!commentsCount
          })}
          onClick={onShowSidebar}
        >
          {!!commentsCount && <span className={styles.commentCount}>{commentsCount}</span>}
          <FontAwesomeIcon icon={!!commentsCount ? faCommentSolid : faComment} className={styles.icon} />
        </button>
      </Tooltip>
    </div>
  );
};

export default BoxActions;
