import React, { FC, useCallback, useMemo, useState } from 'react';
import { Button, Dropdown, Spinner, Tooltip } from 'src/common';
import styles from './index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWebhook as faWebhookLight } from '@fortawesome/pro-light-svg-icons';
import { faWebhook as faWebhookSolid } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useSendCreateBidWebhook } from 'src/models/company/Webhooks/hooks';
import { CreateBidWebhookStatus, IApiWebhook, WebhookStatus } from 'src/models/company/Webhooks/types';
import { FeatureFlag } from '@tendium/prom-types';
import { useFeatureFlag } from 'src/helpers';
import SetStatusOverlay from './SetStatusOverlay';

interface Props {
  webhook: IApiWebhook;
  webhookStatus?: WebhookStatus;
  bidId?: string;
  disabled?: boolean;
  size?: 's' | 'l';
}

export const BidWebhooksButton: FC<Props> = props => {
  const { webhook, bidId, disabled, webhookStatus, size } = props;
  const { t } = useTranslation();

  const isWebhooksFeature = useFeatureFlag(FeatureFlag.Users_Webhooks);

  const [sendCreateBidWebhook, { loading }] = useSendCreateBidWebhook();
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const onClick = useCallback(() => {
    if (bidId && !webhookStatus?.status) {
      sendCreateBidWebhook({
        variables: {
          bidId
        }
      });
    } else {
      setDropdownVisible(true);
    }
  }, [bidId, sendCreateBidWebhook, webhookStatus]);

  const onVisibleChange = useCallback((visible: boolean) => {
    if (!visible) {
      setDropdownVisible(false);
    }
  }, []);

  const onClose = useCallback(() => {
    setDropdownVisible(false);
  }, []);

  const statusCase = useMemo(() => {
    switch (webhookStatus?.status) {
      case CreateBidWebhookStatus.Unknown:
        return {
          tooltipContent: `${webhook.name}: ${
            webhookStatus?.message ? webhookStatus?.message : t('BidSpaces.Webhooks.tooltipUnknown')
          }`,
          buttonStyle: styles.unknown
        };
      case CreateBidWebhookStatus.Success:
        return {
          tooltipContent: `${webhook.name}: ${
            webhookStatus?.message ? webhookStatus?.message : t('BidSpaces.Webhooks.tooltipSuccess')
          }`,
          buttonStyle: styles.success
        };
      case CreateBidWebhookStatus.Failed:
        return {
          tooltipContent: `${webhook.name}:  ${
            webhookStatus?.message ? webhookStatus?.message : t('BidSpaces.Webhooks.tooltipFail')
          }`,
          buttonStyle: styles.failed
        };
      default:
        return {
          tooltipContent: `${webhook.name}: ${
            webhookStatus?.message ? webhookStatus?.message : t('BidSpaces.Webhooks.sendBid')
          }`,
          buttonStyle: undefined
        };
    }
  }, [t, webhook.name, webhookStatus?.message, webhookStatus?.status]);

  return isWebhooksFeature && bidId ? (
    !disabled ? (
      <Dropdown
        placement={'bottomRight'}
        getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
        overlay={<SetStatusOverlay bidId={bidId} webhook={webhook} onClose={onClose} />}
        visible={dropdownVisible}
        onVisibleChange={onVisibleChange}
      >
        <Tooltip title={statusCase.tooltipContent} placement="rightBottom">
          <Button
            icon={
              loading ? (
                <Spinner />
              ) : (
                <FontAwesomeIcon
                  icon={!statusCase.buttonStyle ? faWebhookLight : faWebhookSolid}
                  className={size === 'l' ? styles.iconLarge : undefined}
                />
              )
            }
            type="text"
            className={classNames(styles.button, statusCase.buttonStyle)}
            onClick={onClick}
          />
        </Tooltip>
      </Dropdown>
    ) : (
      <FontAwesomeIcon
        icon={!statusCase.buttonStyle ? faWebhookLight : faWebhookSolid}
        className={classNames(statusCase.buttonStyle, styles.icon, size === 'l' ? styles.iconLarge : undefined)}
      />
    )
  ) : null;
};

export default BidWebhooksButton;
