import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, confirm } from 'src/common';
import { useDeleteChecklistTemplate, useChecklistTemplates } from 'src/models/bids/BidChecklistTemplates/hooks';
import styles from '../index.module.scss';
import modalStyles from 'src/common/Modal/index.module.scss';

interface Props {
  onClose: () => void;
}

export const BidChecklistTemplates: FC<Props> = props => {
  const { onClose } = props;
  const { t } = useTranslation();
  const { data } = useChecklistTemplates();
  const [deleteTemplate, { loading: templateDeleting }] = useDeleteChecklistTemplate();
  const [templateId, setTemplateId] = useState<string | null>(null);

  const onDeletionConfirm = useCallback(
    (id: string) => {
      deleteTemplate({ id });
    },
    [deleteTemplate]
  );

  const onDeleteTemplate = useCallback(
    (id: string) => {
      setTemplateId(id);
      confirm.confirm({
        title: t('Checklist.confirmDeletionOfTemplateTitle'),
        okText: t('Common.delete'),
        content: t('Checklist.confirmDeletionOfTemplate'),
        cancelText: t('Common.cancel'),
        className: modalStyles.confirmModal,
        centered: true,
        onOk: () => onDeletionConfirm(id)
      });
    },
    [onDeletionConfirm, t]
  );

  return (
    <>
      {data && (
        <Modal
          visible
          title={t('Checklist.manageTemplates')}
          onCancel={onClose}
          footer={[
            <Button key="done" type={'primary'} onClick={onClose}>
              {t('Common.done')}
            </Button>
          ]}
        >
          <div>
            {data.map(template => (
              <div key={template.id} className={styles.templateItem}>
                <div className={styles.templateName}>{template.name}</div>
                <Button
                  type={'text'}
                  onClick={() => onDeleteTemplate(template.id)}
                  disabled={templateDeleting && templateId === template.id}
                >
                  {t('Common.remove')}
                </Button>
              </div>
            ))}
          </div>
        </Modal>
      )}
    </>
  );
};

export default BidChecklistTemplates;
