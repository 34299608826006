import { gql } from '@apollo/client';
import { USER_NAME_FIELDS } from '../users/queries';
import { WORKSPACE_STATUS_FIELDS } from './WorkspaceStatus/queries';
import { ASSIGNED_TO_FIELDS } from '../users/AssignedTo/queries';

export const WORKSPACE_FIELDS = gql`
  fragment WorkspaceFields on Workspace {
    id
    name
    isActive
    color
    accessControl {
      editPolicy
      subscribePolicy
    }
    owners {
      ...assignedToFields
    }
    subscribers {
      ...assignedToFields
    }
    statuses {
      ...WorkspaceStatusFields
    }
    archivedBy {
      ...userNameFields
    }
    archivedDate
  }
  ${WORKSPACE_STATUS_FIELDS}
  ${USER_NAME_FIELDS}
  ${ASSIGNED_TO_FIELDS}
`;

export const GET_WORKSPACE = gql`
  query getWorkspace($id: String!) {
    getWorkspace(id: $id) {
      ...WorkspaceFields
      accessToEdit
    }
  }
  ${WORKSPACE_FIELDS}
`;

export const UPDATE_WORKSPACE = gql`
  mutation updateWorkspace($workspaceId: String!, $name: String, $color: String) {
    updateWorkspace(workspaceId: $workspaceId, input: { name: $name, color: $color }) {
      ...WorkspaceFields
    }
  }
  ${WORKSPACE_FIELDS}
`;

export const CREATE_WORKSPACE = gql`
  mutation createWorkspace($name: String!, $owners: [String!], $subscribers: [String!], $color: String) {
    createWorkspace(input: { name: $name, owners: $owners, subscribers: $subscribers, color: $color }) {
      ...WorkspaceFields
    }
  }
  ${WORKSPACE_FIELDS}
`;

export const GET_WORKSPACES = gql`
  query getWorkspaces {
    getWorkspaces {
      ...WorkspaceFields
    }
  }
  ${WORKSPACE_FIELDS}
`;

export const GET_ACTIVE_WORKSPACES = gql`
  query getActiveWorkspaces {
    getActiveWorkspaces {
      ...WorkspaceFields
    }
  }
  ${WORKSPACE_FIELDS}
`;

export const GET_WORKSPACES_IDS = gql`
  query getWorkspacesIds {
    getWorkspaces {
      id
    }
  }
`;

export const UPDATE_WS_POLICY = gql`
  mutation updateWorkspace($id: String!, $policy: AccessControlPolicy!) {
    updateWorkspace(workspaceId: $id, input: { accessControl: { subscribePolicy: $policy } }) {
      id
      accessControl {
        editPolicy
        subscribePolicy
      }
    }
  }
`;

export const UPDATE_WS_OWNERS_POLICY = gql`
  mutation updateWorkspace($id: String!, $policy: AccessControlPolicy!) {
    updateWorkspace(workspaceId: $id, input: { accessControl: { editPolicy: $policy } }) {
      id
      accessControl {
        editPolicy
        subscribePolicy
      }
    }
  }
`;

export const UPDATE_WS_OWNERS = gql`
  mutation updateWorkspace($id: String!, $owners: [String!]) {
    updateWorkspace(workspaceId: $id, input: { owners: $owners, accessControl: { editPolicy: ONLY_ADDED } }) {
      id
      owners {
        ...assignedToFields
      }
      subscribers {
        ...assignedToFields
      }
    }
  }
  ${ASSIGNED_TO_FIELDS}
`;

export const UPDATE_WS_SUBSCRIBERS = gql`
  mutation updateWorkspace($id: String!, $subscribers: [String!]) {
    updateWorkspace(workspaceId: $id, input: { subscribers: $subscribers }) {
      id
      subscribers {
        ...assignedToFields
      }
      owners {
        ...assignedToFields
      }
    }
  }
  ${ASSIGNED_TO_FIELDS}
`;

export const MOVE_WS_MEMBERS = gql`
  mutation updateWorkspace($id: String!, $owners: [String!], $subscribers: [String!]) {
    updateWorkspace(workspaceId: $id, input: { subscribers: $subscribers, owners: $owners }) {
      id
      subscribers {
        ...assignedToFields
      }
      owners {
        ...assignedToFields
      }
    }
  }
  ${ASSIGNED_TO_FIELDS}
`;

export const DELETE_WORKSPACE = gql`
  mutation deleteWS($id: String!) {
    deleteWorkspace(workspaceId: $id) {
      id
      isActive
    }
  }
`;

export const ARCHIVE_WORKSPACE = gql`
  mutation archiveWS($id: String!) {
    archiveWorkspace(workspaceId: $id) {
      id
      isActive
      archivedBy {
        ...userNameFields
      }
      archivedDate
    }
  }
  ${USER_NAME_FIELDS}
`;

export const RESTORE_WORKSPACE = gql`
  mutation restireWS($id: String!) {
    restoreWorkspace(workspaceId: $id) {
      id
      isActive
    }
  }
`;

export const EXPORT_BIDSPACES = gql`
  mutation exportBidspaces($bidspaceIds: [String!]!) {
    exportBidspaces(input: { bidspaceIds: $bidspaceIds }) {
      operationId
    }
  }
`;

export const GET_WORKSPACE_STATISTIC = gql`
  query getWorkspaceStats($workspaceId: String!, $currency: Currency!, $rejected: Boolean) {
    getWorkspaceStats(input: { workspaceId: $workspaceId, currency: $currency, rejected: $rejected }) {
      winHitRate
      contractValueSums {
        statusId
        currency
        valueSum
      }
    }
  }
`;
