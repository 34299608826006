import React, { FC, ReactNode } from 'react';
import styles from './index.module.scss';
import { BlockLabel } from 'src/shared/Blocks';
import classNames from 'classnames';
import { TenderBlockSize } from 'src/models/procurements/Tender/types';
import { useIsMobile } from 'src/reactiveVars';

export interface Props {
  value: ReactNode;
  title?: ReactNode;
  size?: TenderBlockSize;
  className?: string;
}
export const InfoBox: FC<Props> = ({ title, value, className, size = TenderBlockSize.Full }) => {
  const [isMobile] = useIsMobile();
  return (
    <div
      className={classNames(
        styles.box,
        styles.infoBox,
        !!className && className,
        styles[isMobile ? TenderBlockSize.Full : size]
      )}
    >
      {title && <BlockLabel label={title} />}
      <span className={styles.content}>{value}</span>
    </div>
  );
};

export default InfoBox;
