import {
  useBidding,
  useCreateBidGroup,
  useOpenTaskGroupsPrefs,
  useStartTaskJob,
  useTasksFilter
} from 'src/models/bids/BidFull/hooks';
import { useGetBidAttachments } from 'src/models/bids/BidAttachments/hooks';
import { faList, faPen, faStars } from '@fortawesome/pro-light-svg-icons';
import { isTasksFilterApplied } from 'src/models/bids/BidFull/helpers';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCreateBidTasks } from 'src/models/bids/BidTask/hooks';
import ButtonGroupDropdown from 'src/common/ButtonGroupDropdown';
import { Button, ConditionalWrapper, Tooltip } from 'src/common';
import { useBidDraftUrl } from 'src/models/procurements/hooks';
import { FeatureFlag, useFeatureFlag } from 'src/helpers';
import { trackNavigateToDraft } from 'src/segment/events';
import PrePopulateModal from '../PrePopulateModal';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styles from './index.module.scss';
import { useTenderFiles } from 'src/models/procurements/TenderFiles/hooks';
import CreateTaskNotification from 'src/shared/Bid/CreateTaskNotification';

export const CreateTasksDropdown: FC = () => {
  const { t } = useTranslation();
  const { data: biddingData, loading } = useBidding();
  const { data: bidAttachmentsData, loading: bidAttachmentsLoading } = useGetBidAttachments(biddingData?.id ?? '');
  const { data: tenderFiles } = useTenderFiles(biddingData?.originId);

  const [createGroup] = useCreateBidGroup();
  const navigate = useNavigate();
  const bidId = biddingData?.id;
  const handleStartJob = useStartTaskJob(bidId ?? '', () => <CreateTaskNotification type="loading" />);

  const bidDraftUrl = useBidDraftUrl(biddingData?.id ?? '');
  const { filter } = useTasksFilter();

  const groups = useMemo(() => biddingData?.questionGroups, [biddingData?.questionGroups]);
  const fileCategories = useMemo(() => tenderFiles?.filesCats.fileCategories || [], [tenderFiles]);
  const isFilterApplied = useMemo(() => isTasksFilterApplied(filter), [filter]);

  const isBidDraftAvailable = useFeatureFlag(FeatureFlag.Release_TaskHighlightPreview);
  const isAutoGenerateTasksAvailable = useFeatureFlag(FeatureFlag.BiddingTool_AutoGenerateTasks);

  const [modalVisible, setModalVisible] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);

  const tenderAndBidDocs = useMemo(() => {
    const tenderDocs = fileCategories.flat();
    const bidDocs = bidAttachmentsData ?? [];
    return [...tenderDocs, ...bidDocs];
  }, [fileCategories, bidAttachmentsData]);

  const isTenderOrAttachmentFilesAvailable = useMemo(
    () => !loading && !bidAttachmentsLoading && tenderAndBidDocs.length > 0,
    [loading, bidAttachmentsLoading, tenderAndBidDocs.length]
  );

  const [createTask] = useCreateBidTasks();
  const [openTaskGroupsIds, setOpenTasksGroups] = useOpenTaskGroupsPrefs();

  const createTaskGroupId = useMemo(() => {
    const firstOpenGroupId = groups?.find(group => openTaskGroupsIds?.includes(group.id))?.id;

    if (firstOpenGroupId) return firstOpenGroupId;
    if (groups?.length) return groups[0].id;

    return null;
  }, [openTaskGroupsIds, groups]);

  const onCreateTask = useCallback(() => {
    if (!createTaskGroupId) return;
    createTask({
      questionGroupId: createTaskGroupId ?? '',
      bidId: biddingData?.id,
      eventSource: 'Bidding Tool page',
      mode: 'task',
      onComplete() {
        if (!openTaskGroupsIds?.includes(createTaskGroupId)) {
          setOpenTasksGroups(createTaskGroupId);
        }
      },
      tasks: ['']
    });
  }, [biddingData?.id, createTask, createTaskGroupId, openTaskGroupsIds, setOpenTasksGroups]);

  const onCreateGroup = useCallback(async () => {
    biddingData?.id &&
      createGroup({
        bidId: biddingData.id,
        title: t('BiddingTool.newGroupWithNum', { num: biddingData?.questionGroups.length + 1 }),
        eventSource: 'Bidding Tool page',
        mode: 'group'
      });
    setMenuVisible(false);
  }, [biddingData?.id, biddingData?.questionGroups.length, createGroup, t]);

  const onCreateTasksFromDocs = useCallback(() => {
    navigate(bidDraftUrl);
    biddingData?.id && trackNavigateToDraft(biddingData?.id, 'Bidding Tool page');
    setMenuVisible(false);
  }, [bidDraftUrl, biddingData?.id, navigate]);

  const toggleMenu = useCallback(() => {
    setMenuVisible(prevState => !prevState);
  }, []);

  const onClose = useCallback(() => {
    setModalVisible(false);
  }, []);

  return (
    <>
      <ButtonGroupDropdown
        mainAction={onCreateTask}
        mainTitle={t('BiddingTool.addTask')}
        menuVisible={menuVisible}
        toggleMenu={toggleMenu}
        dropdownItems={
          <>
            {isBidDraftAvailable && (
              <ConditionalWrapper
                condition={!isTenderOrAttachmentFilesAvailable}
                wrapper={children => <Tooltip title={t('BidDrafts.createTasksDisabled')}>{children}</Tooltip>}
              >
                <div>
                  <Button
                    className={styles.dropdownButton}
                    icon={<FontAwesomeIcon icon={faPen} />}
                    onClick={onCreateTasksFromDocs}
                    type="text"
                    disabled={!isTenderOrAttachmentFilesAvailable}
                  >
                    <span className={styles.buttonText}>{t('BidDrafts.createTasksFromDocumentsDropdown')}</span>
                  </Button>
                </div>
              </ConditionalWrapper>
            )}
            <ConditionalWrapper
              condition={isFilterApplied}
              wrapper={children => <Tooltip title={t('BiddingTool.Filters.newGroupTooltip')}>{children}</Tooltip>}
            >
              <div>
                <Button
                  className={styles.dropdownButton}
                  icon={<FontAwesomeIcon icon={faList} />}
                  onClick={onCreateGroup}
                  type="text"
                  disabled={isFilterApplied}
                >
                  <span className={styles.buttonText}>{t('BiddingTool.newGroup')}</span>
                </Button>
              </div>
            </ConditionalWrapper>
            {isAutoGenerateTasksAvailable && (
              <ConditionalWrapper
                condition={!isTenderOrAttachmentFilesAvailable}
                wrapper={children => (
                  <Tooltip title={t('BiddingTool.prePopulateButtonDisabledDesc')}>{children}</Tooltip>
                )}
              >
                <Button
                  className={styles.dropdownButton}
                  icon={<FontAwesomeIcon icon={faStars} />}
                  onClick={
                    !isTenderOrAttachmentFilesAvailable
                      ? undefined
                      : () => {
                          setModalVisible(true);
                          setMenuVisible(false);
                        }
                  }
                  type="text"
                  disabled={!isTenderOrAttachmentFilesAvailable}
                >
                  <span className={styles.buttonText}>{t('BiddingTool.prePopulateButtonDropdown')}</span>
                </Button>
              </ConditionalWrapper>
            )}
          </>
        }
      />
      {modalVisible && isTenderOrAttachmentFilesAvailable && (
        <PrePopulateModal
          onCloseModal={onClose}
          eventSource="Bidding Tool page"
          onTaskGeneration={(pubFiles, privFiles) => {
            handleStartJob(pubFiles, privFiles);
          }}
        />
      )}
    </>
  );
};

export default CreateTasksDropdown;
