import { Currency } from '@tendium/prom-types';
import { useMemo } from 'react';
import { useApp, isPaidUser } from 'src/models/auth';

export function useCurrency(): { currency: Currency } {
  const { user } = useApp();

  return useMemo(
    () => ({
      currency: isPaidUser(user) && user?.currency ? user.currency : Currency.EUR
    }),
    [user]
  );
}

export default useCurrency;
