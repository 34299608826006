import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';
import { IBoxFieldBoolean } from 'src/models/procurements/Tender/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/pro-light-svg-icons';
import BlockEmpty from '../../Empty';
import { useTranslation } from 'react-i18next';
import { IBlockReadProps } from 'src/shared/Tender/Boxes/types';

interface Props extends IBlockReadProps, Pick<IBoxFieldBoolean, 'boolean'> {}
export const ReadOnlyBlockBoolean: FC<Props> = ({ boolean }) => {
  const { t } = useTranslation();

  return boolean !== undefined && boolean !== null ? (
    <span className={classNames(styles.block, styles.edit)}>
      <FontAwesomeIcon
        icon={boolean ? faCheck : faXmark}
        className={classNames(styles.booleanIcon, { [styles[boolean.toString()]]: boolean })}
      />
      <span>{t(boolean ? 'Common.yes' : 'Common.no')}</span>
    </span>
  ) : (
    <BlockEmpty />
  );
};

export default ReadOnlyBlockBoolean;
