import { faPen, faXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { FC, MouseEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DECISION_MATRIX_ITEM_SCORES, DecisionMatrixItem, DefaultItemPrefix } from 'src/models/decisionMatrix/types';
import { BoxFieldString } from 'src/models/procurements';
import { EditableText } from 'src/shared/Blocks';
import styles from './index.module.scss';
import { useDeleteDecisionMatrixItem, useUpdateDecisionMatrixItems } from 'src/models/decisionMatrix/hooks';
import { IBoxFieldString } from 'src/models/procurements/Tender/types';
import { Tooltip, Button, confirm } from 'src/common';
import modalStyles from 'src/common/Modal/index.module.scss';

interface Props {
  item: DecisionMatrixItem;
  matrixId: string;
  initEdit?: boolean;
  onExitInitEdit?: () => void;
}

export const isDefaultTitle = (title: string): boolean => {
  return title ? title.startsWith(DefaultItemPrefix) : false;
};

const MatrixItem: FC<Props> = props => {
  const { item, matrixId, initEdit, onExitInitEdit } = props;
  const { t } = useTranslation();

  const [updateDecisionMatrixItems] = useUpdateDecisionMatrixItems();
  const [deleteDecisionMatrixItem] = useDeleteDecisionMatrixItem();

  const [isEditing, setEditing] = useState<boolean>(!!initEdit);

  const itemScore = item.score;
  const score = DECISION_MATRIX_ITEM_SCORES.filter(num => num <= itemScore);
  const restScore = DECISION_MATRIX_ITEM_SCORES.filter(num => num > itemScore);

  const enterEditing = useCallback((e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setEditing(true);
  }, []);

  const exitEditing = useCallback(() => {
    setEditing(false);
    onExitInitEdit && onExitInitEdit();
  }, [onExitInitEdit]);

  const onChangeDescription = useCallback(
    (val: IBoxFieldString) => {
      updateDecisionMatrixItems([{ id: item.id, score: item.score, title: val.string as string }]);
      exitEditing();
    },
    [exitEditing, item.id, item.score, updateDecisionMatrixItems]
  );

  const onChangeScore = useCallback(
    (score: number) => {
      updateDecisionMatrixItems([{ id: item.id, score: score, title: item.title }]);
    },
    [item.id, item.title, updateDecisionMatrixItems]
  );

  const onRemoveTemplate = useCallback(
    (matrixId: string) => {
      confirm.confirm({
        title: t('DecisionMatrix.confirmDeleteMatrixItemTitle'),
        okText: t('Common.remove'),
        content: t('DecisionMatrix.confirmDeleteMatrixItem'),
        cancelText: t('Common.cancel'),
        className: modalStyles.confirmModal,
        centered: true,
        onOk: () => {
          deleteDecisionMatrixItem(matrixId, item.id);
        }
      });
    },
    [deleteDecisionMatrixItem, item.id, t]
  );

  const translatedTitle = isDefaultTitle(item.title) ? t(`DecisionMatrix.titles.${item.title}`) : item.title;

  return (
    <div className={styles.item}>
      {isEditing ? (
        <EditableText
          className={classNames(styles.title, styles.isEditing)}
          onChange={onChangeDescription}
          onFinish={exitEditing}
          field={new BoxFieldString(translatedTitle, translatedTitle)}
          autoSize={true}
          autoFocus
        />
      ) : (
        <div className={styles.itemInner}>
          <div className={styles.titleContainer}>
            <div className={classNames(styles.title, { [styles.isEmpty]: !translatedTitle })}>
              {!!translatedTitle ? (
                <Tooltip title={translatedTitle}>{translatedTitle}</Tooltip>
              ) : (
                t('DecisionMatrix.newItemPlaceholder')
              )}
            </div>
            <Button
              type={'text'}
              onClick={enterEditing}
              icon={<FontAwesomeIcon icon={faPen} />}
              className={styles.editButton}
            />
          </div>

          {score &&
            score.map(scoreItem => (
              <button
                key={scoreItem}
                className={classNames(styles.scoreItem, styles[`score-${score.length}`])}
                onClick={() => onChangeScore(scoreItem)}
              >
                {scoreItem}
              </button>
            ))}
          {restScore &&
            restScore.map(scoreItem => (
              <button key={scoreItem} className={styles.scoreItem} onClick={() => onChangeScore(scoreItem)}>
                {scoreItem}
              </button>
            ))}
          <Button
            className={styles.delete}
            type={'text'}
            icon={<FontAwesomeIcon icon={faXmark} onClick={() => onRemoveTemplate(matrixId)} />}
          />
        </div>
      )}
    </div>
  );
};

export default MatrixItem;
