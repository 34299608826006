import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';
import PrePopulateModal from '../PrePopulateModal';
import { Button, ConditionalWrapper, Skeleton, Tag, Tooltip } from 'src/common';
import { useBidding, useCreateBidGroup, useStartTaskJob } from 'src/models/bids/BidFull/hooks';
import { useNavigate } from 'react-router-dom';
import { useBidDraftUrl } from 'src/models/procurements/hooks';
import { FeatureFlag, useFeatureFlag } from 'src/helpers';
import { trackNavigateToDraft } from 'src/segment/events';
import { useGetBidAttachments } from 'src/models/bids/BidAttachments/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faFileLines } from '@fortawesome/pro-light-svg-icons';
import { BlockTitle } from 'src/shared';
import { faStars } from '@fortawesome/pro-solid-svg-icons';
import { useTenderFiles } from 'src/models/procurements/TenderFiles/hooks';
import CreateTaskNotification from 'src/shared/Bid/CreateTaskNotification';

export const BidOnboarding: FC = () => {
  const { t } = useTranslation();
  const { data: biddingData, loading } = useBidding();
  const { data: bidAttachmentsData, loading: bidAttachmentsLoading } = useGetBidAttachments(biddingData?.id ?? '');

  const [createGroup] = useCreateBidGroup();
  const bidId = biddingData?.id ?? '';
  const { data: tenderFiles } = useTenderFiles(biddingData?.originId);
  const handleStartJob = useStartTaskJob(bidId, () => <CreateTaskNotification type="loading" />);

  const navigate = useNavigate();

  const [modalVisible, setModalVisible] = useState(false);
  const isBidDraftAvailable = useFeatureFlag(FeatureFlag.Release_TaskHighlightPreview);
  const isAutoGenerateTasksAvailable = useFeatureFlag(FeatureFlag.BiddingTool_AutoGenerateTasks);

  const fileCategories = useMemo(() => tenderFiles?.filesCats.fileCategories || [], [tenderFiles]);

  const bidDraftUrl = useBidDraftUrl(biddingData?.id ?? '');

  const tenderAndBidDocs = useMemo(() => {
    const tenderDocs = fileCategories.flat();
    const bidDocs = bidAttachmentsData ?? [];
    return [...tenderDocs, ...bidDocs];
  }, [fileCategories, bidAttachmentsData]);

  const isTenderOrAttachmentFilesAvailable = useMemo(
    () => !loading && !bidAttachmentsLoading && tenderAndBidDocs.length > 0,
    [loading, bidAttachmentsLoading, tenderAndBidDocs.length]
  );

  const onClose = useCallback(() => {
    setModalVisible(false);
  }, []);

  const onCreateTasksFromDocs = useCallback(() => {
    navigate(bidDraftUrl);
    biddingData?.id && trackNavigateToDraft(biddingData?.id, 'Bidding Tool page');
  }, [bidDraftUrl, biddingData?.id, navigate]);

  const onCreateGroup = useCallback(async () => {
    biddingData?.id &&
      createGroup({
        bidId: biddingData.id,
        title: t('BiddingTool.newGroupWithNum', { num: biddingData?.questionGroups.length + 1 }),
        eventSource: 'Onboarding',
        mode: 'group'
      });
  }, [biddingData?.id, biddingData?.questionGroups.length, createGroup, t]);

  return !!loading || !!bidAttachmentsLoading ? (
    <Skeleton />
  ) : (
    <>
      <section className={styles.heading}>
        <BlockTitle title={t('BiddingTool.bidSubmission')} className={styles.title} />
        <div className={styles.subHeading}>
          <p className={styles.desc}> {t('BiddingTool.bidSubmissionTips')}</p>
        </div>
      </section>
      <section className={styles.container}>
        {isAutoGenerateTasksAvailable && (
          <ConditionalWrapper
            condition={!isTenderOrAttachmentFilesAvailable}
            wrapper={children => <Tooltip title={t('BiddingTool.prePopulateButtonDisabledDesc')}>{children}</Tooltip>}
          >
            <Button
              className={styles.button}
              onClick={() => setModalVisible(true)}
              disabled={!isTenderOrAttachmentFilesAvailable}
            >
              <div className={styles.card}>
                <div className={styles.cardHeader}>
                  <div>
                    <span className={styles.cardTitle}>{t('BiddingTool.prePopulateButton')}</span>
                    <Tag size="m" label={t('Common.newTag')} className={styles.tag} />
                  </div>

                  <FontAwesomeIcon icon={faStars} size="xl" className={styles.starsIcon} />
                </div>
                <div className={styles.cardInfo}> {t('BiddingTool.prePopulateDesc')}</div>
              </div>
            </Button>
          </ConditionalWrapper>
        )}
        {isBidDraftAvailable && (
          <ConditionalWrapper
            condition={!isTenderOrAttachmentFilesAvailable}
            wrapper={children => <Tooltip title={t('BidDrafts.createTasksDisabled')}>{children}</Tooltip>}
          >
            <div>
              <Button
                className={styles.button}
                onClick={onCreateTasksFromDocs}
                disabled={!isTenderOrAttachmentFilesAvailable}
              >
                <div className={styles.card}>
                  <div className={styles.cardHeader}>
                    <span className={styles.cardTitle}> {t('BidDrafts.createTasksFromDocuments')}</span>
                    <FontAwesomeIcon icon={faFileLines} size="xl" color={'var(--grey-mi)'} />
                  </div>
                  <div className={styles.cardInfo}> {t('BidDrafts.createTasksFromDocumentsDesc')}</div>
                </div>
              </Button>
            </div>
          </ConditionalWrapper>
        )}

        <Button className={styles.button} onClick={onCreateGroup}>
          <div className={styles.card}>
            <div className={styles.cardHeader}>
              <span className={styles.cardTitle}> {t('BiddingTool.newGroup')}</span>
              <FontAwesomeIcon icon={faList} size="xl" color={'var(--grey-mi)'} />
            </div>
            <div className={styles.cardInfo}> {t('BiddingTool.newGroupDesc')}</div>
          </div>
        </Button>

        {modalVisible ? (
          <PrePopulateModal
            onCloseModal={onClose}
            eventSource="Onboarding"
            onTaskGeneration={(pubFiles, privFiles) => {
              handleStartJob(pubFiles, privFiles);
            }}
          />
        ) : null}
      </section>
    </>
  );
};

export default BidOnboarding;
