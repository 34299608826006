import React, { FC } from 'react';
import styles from './index.module.scss';

interface Props {
  backgroundColor: string;
  taskCount: number;
  totalCount: number;
}
export const StatusStackBar: FC<Props> = props => {
  const { backgroundColor, taskCount, totalCount } = props;

  const stackBarWidth = (taskCount / totalCount) * 100;

  return <div className={styles.stackBar} style={{ width: `${stackBarWidth}%`, backgroundColor: backgroundColor }} />;
};

export default StatusStackBar;
