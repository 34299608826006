import { Location } from 'react-router-dom';
import { fromEvent, Observable, Subscriber, timer } from 'rxjs';
import {
  debounceTime,
  distinct,
  map,
  mapTo,
  share,
  // startWith,
  switchMap,
  takeUntil,
  filter,
  take,
  tap
} from 'rxjs/operators';
import { router } from 'src';

const resizeDetector = (container: HTMLElement): Observable<number> =>
  timer(100, 100).pipe(
    map(() => container.clientHeight),
    distinct(),
    share()
  );
const contentBecomeStable = (container: HTMLElement, ms: number): Observable<undefined> =>
  resizeDetector(container).pipe(debounceTime(ms), mapTo(undefined));
const manualScrollHappened$ = fromEvent(document, 'wheel').pipe(share());
// this is from react-router lib types :/
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const historyChanged$ = new Observable((subscriber: Subscriber<Location<any>>) => {
  const unregister = router.subscribe(state => {
    subscriber.next(state.location);
  });

  return function unsubscribe() {
    unregister();
  };
});
// .pipe(startWith(router.state.location), share());

export const scrollToItem = (id: string, behavior?: ScrollBehavior, block?: ScrollLogicalPosition): void => {
  const item = document.getElementById(id);
  if (item) {
    item.scrollIntoView({ behavior: behavior ?? 'smooth', block: block ?? 'start' });
  }
};

/**
 * Handling html anchors (#elementId links) in a SPA is not as easiest as it was not so many time ago
 *
 * 1. Your navigations in an app does not trigger browser's internal support of #anchors automatically
 * 2. Often your page layout changes after navigation just because you loading something in a background which changes the anchor element offset
 *
 * So here is the solution:
 *  each time you hit a link it add listeners
 *  1. it waits until your container stops to change offset.
 *  2. Then it scrolls to the element with id form the #link
 *  3. If user scrolled the page manually - it does not scroll automatically.
 *
 * @param container - container we listen for size changes. We can not listen whole body because our layout keeps it fixed to the viewport size.
 * @see: https://bitbucket.org/tendium/prom-customer-portal/pull-requests/183
 */
function getScrollToItem$(container: HTMLElement, delay?: number): Observable<void> {
  return historyChanged$.pipe(
    switchMap(location =>
      contentBecomeStable(container, delay ?? 1000).pipe(
        take(1),
        map(() => location.hash.replace('#', '')),
        takeUntil(manualScrollHappened$)
      )
    ),
    filter(anchorId => anchorId !== ''),
    map(anchorId => scrollToItem(anchorId)),
    tap(location => {
      console.debug({ getScrollToItem: location });
    })
  );
}

export const passiveScroll$ = (element?: HTMLElement): Observable<Event> =>
  new Observable<Event>(subscriber => {
    const listener = (event: Event): void => {
      subscriber.next(event);
    };
    (element ?? window).addEventListener('scroll', listener, { passive: true });

    return () => {
      (element ?? window).removeEventListener('scroll', listener);
    };
  }).pipe(share());

export const isInViewport = (elem: HTMLElement): boolean => {
  const bounding = elem.getBoundingClientRect();
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export default getScrollToItem$;
