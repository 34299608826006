import React, { FC } from 'react';
import styles from './index.module.scss';
import { HighlightColor } from '@tendium/prom-types';
import { HighlightColorDict } from 'src/models/matchingProfiles/types';
import { SearchFieldTag } from 'src/common';

interface IPreferencesKeywordsItemProps {
  keywords: { id: string; value: string }[];
  color: HighlightColor;
}

export const TooltipKeywordsItem: FC<IPreferencesKeywordsItemProps> = props => {
  const { keywords, color } = props;

  return (
    <li className={styles.keywordsItem}>
      <SearchFieldTag value={keywords} color={HighlightColorDict[color]} />
    </li>
  );
};

export default TooltipKeywordsItem;
