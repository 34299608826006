import React, { FC, useMemo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { Dropdown } from 'src/common';
import { useUpdateBidTasks } from 'src/models/bids/BidTask/hooks';
import { QuestionStatus } from 'src/models/bids/BidTask/types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { TaskTrackPickerData, trackTaskUpdate } from 'src/segment/events';
import { BidTask } from 'src/models/bids/BidTask';

interface Props {
  questions: BidTask[];
  groupId: string;
  taskValue?: QuestionStatus;
  className?: string;
  disabled?: boolean;
  label?: string;
  trackData?: TaskTrackPickerData;
  setActionsActive?: (actionActive: boolean) => void;
}
export const TaskStatusPicker: FC<Props> = ({
  questions,
  groupId,
  taskValue,
  className,
  disabled,
  label: taskStatusLabel,
  trackData,
  setActionsActive
}) => {
  const { t } = useTranslation();
  const [menuVisible, setMenuVisible] = useState(false);
  const [updateTasks] = useUpdateBidTasks();

  const onVisibleChange = useCallback(() => {
    setMenuVisible(prevState => !prevState);
    setActionsActive && setActionsActive(!menuVisible);
  }, [menuVisible, setActionsActive]);

  const onChangeStatus = useCallback(
    (status: QuestionStatus) => {
      if (status !== taskValue && trackData) {
        trackTaskUpdate({ ...trackData, taskProp: 'task status', value: status });
      }

      updateTasks({
        questions: questions.filter(item => item.status !== status),
        groupId,
        status
      });
      onVisibleChange();
    },
    [taskValue, updateTasks, questions, groupId, onVisibleChange, trackData]
  );

  const values = useMemo(
    () =>
      Object.values(QuestionStatus).map(type => {
        const label = Object.keys(QuestionStatus).find(
          key => QuestionStatus[key as keyof typeof QuestionStatus] === type
        );
        return {
          value: type,
          label: label ? `${label}` : undefined
        };
      }),
    []
  );

  const currentValue = useMemo(() => values.find(value => value.value === taskValue), [taskValue, values]);
  const currentLabel = currentValue?.label ?? currentValue?.value;

  return (
    <div className={classNames(styles.container, { [styles.isDisabled]: disabled }, className)}>
      <Dropdown
        trigger={['click']}
        overlay={
          <div className={styles.overlay}>
            <div className={styles.content}>
              {values.map(({ value, label }) => (
                <button
                  key={value}
                  onClick={() => onChangeStatus(value)}
                  className={classNames(styles.button, styles[value])}
                >
                  <span>{t(`BiddingTool.QuestionStatus.${label ?? value}`)}</span>
                </button>
              ))}
            </div>
          </div>
        }
        onVisibleChange={onVisibleChange}
        visible={menuVisible}
        placement={'bottomRight'}
        getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
        disabled={disabled}
      >
        <button
          className={classNames(styles.button, styles[currentValue?.value ?? QuestionStatus.ToDo], {
            [styles.isDisabled]: disabled,
            [styles.defaultLabel]: !!taskStatusLabel
          })}
        >
          <span>{taskStatusLabel || (!!currentLabel && t(`BiddingTool.QuestionStatus.${currentLabel}`))}</span>
          <FontAwesomeIcon
            icon={faCaretDown}
            className={classNames(styles.buttonIcon, { [styles.isActive]: menuVisible })}
          />
        </button>
      </Dropdown>
    </div>
  );
};

export default TaskStatusPicker;
