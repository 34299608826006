import React, { FC, useCallback, useMemo, useState } from 'react';
import styles from './index.module.scss';
import NewItemForm from './NewItemForm';
import { Button } from 'src/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { scrollToItem } from 'src/helpers/scrollToElement';
import { useBidChecklist } from 'src/models/bids/BidChecklist/hooks';
import ChecklistItem from './Item';
import { BidChecklistUseTemplate } from '../Templates';

export const Checklist: FC = () => {
  const { t } = useTranslation();
  const [isNewItemFormVisible, setNewItemFormVisible] = useState(false);
  const { data: model } = useBidChecklist();
  const items = useMemo(() => model?.checklist || [], [model]);

  const onOpenForm = useCallback(() => {
    setNewItemFormVisible(true);
    scrollToItem('new-item-form');
  }, []);

  const onCloseForm = useCallback(
    (createAnother: boolean) => {
      setNewItemFormVisible(false);
      if (createAnother) {
        onOpenForm();
      }
    },
    [onOpenForm]
  );

  return (
    <>
      <section className={styles.checklist}>
        <div className={classNames(styles.content, { [styles.withForm]: isNewItemFormVisible })}>
          <div className={classNames({ [styles.isNotEmpty]: !!items.length })}>
            <div className={styles.statusContent}>
              {!!items.length && (
                <ul className={styles.items}>
                  {items.map(item => (
                    <ChecklistItem data={item} key={item.id} />
                  ))}
                </ul>
              )}
            </div>
          </div>
          {isNewItemFormVisible && <NewItemForm onCloseForm={onCloseForm} />}
        </div>

        {!isNewItemFormVisible && (
          <div className={styles.button}>
            <Button
              type={'default'}
              onClick={() => setNewItemFormVisible(true)}
              icon={<FontAwesomeIcon icon={faPlus} />}
            >
              {t('Checklist.addAnItem')}
            </Button>
          </div>
        )}
        {!items.length && (
          <div className={styles.button}>
            <BidChecklistUseTemplate />
          </div>
        )}
      </section>
    </>
  );
};

export default Checklist;
