import { ListAttachment } from '../types';
import { normalizeFileName } from 'src/helpers/files';

export function getUniqueFileName(fileName: string, existingAttachments: ListAttachment[]): string {
  const normalizedFileName = normalizeFileName(fileName);
  // If no file with this name exists, return original name
  if (!existingAttachments.some(attachment => attachment.fileName === normalizedFileName)) {
    return normalizedFileName;
  }

  // Split filename into name and extension
  const lastDotIndex = normalizedFileName.lastIndexOf('.');
  const name = lastDotIndex !== -1 ? normalizedFileName.slice(0, lastDotIndex) : normalizedFileName;
  const extension = lastDotIndex !== -1 ? normalizedFileName.slice(lastDotIndex) : '';

  // Find next available number
  let counter = 1;
  let newFileName = '';
  do {
    newFileName = `${name} (${counter})${extension}`;
    counter++;
  } while (existingAttachments.some(attachment => attachment.fileName === newFileName));

  return newFileName;
}
