import React, { FC, ReactNode, useCallback } from 'react';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { faColumns, faSquareKanban, faTable } from '@fortawesome/pro-light-svg-icons';
import { PageView } from 'src/models/users/Preferences/types';
import ModeButton from 'src/shared/ModeButton';
import { ConditionalWrapper, Tag, Tooltip } from 'src/common';
import { useBidsFilterVars } from 'src/models/bids/Bids/hooks';
import { FeatureFlag } from '@tendium/prom-types';
import { useFeatureFlag } from 'src/helpers/featureFlag';
import { ownership } from 'src/models/ownership';

interface Props {
  extra?: ReactNode;
  onToggleView: (view: PageView) => void;
  currentView: PageView;
  tableViewText?: string;
  cardViewText?: string;
  isDashboard?: boolean;
}

const ViewOptionsDropDownOverlay: FC<Props> = props => {
  const { extra, onToggleView, currentView, tableViewText, cardViewText, isDashboard } = props;
  const { t } = useTranslation();
  const { updateVars } = useBidsFilterVars();
  const isWorkspaceDashboardFeature = useFeatureFlag(FeatureFlag.Workspace_Dashboard);

  const onChangeView = useCallback(
    (view: PageView) => {
      if (view !== currentView) {
        onToggleView(view);
      }
      updateVars?.({ search: undefined });
    },
    [currentView, onToggleView, updateVars]
  );

  return (
    <div className={styles.container}>
      <div className={styles.title}>{t('Common.viewOptions')}</div>
      <div className={styles.content}>
        <ul className={styles.viewActions}>
          <li className={styles.viewAction}>
            <ModeButton
              icon={faTable}
              onChangeMode={onChangeView}
              title={tableViewText || t('Common.tableView')}
              mode={PageView.table}
              isActive={currentView === PageView.table}
              className={styles.button}
            />
          </li>
          {isDashboard && (
            <ConditionalWrapper
              condition={!isWorkspaceDashboardFeature}
              wrapper={children => (
                <Tooltip title={t('BidSpaces.Dashboard.dashboardDisabledTooltip', { ownership: ownership.name })}>
                  {children}
                </Tooltip>
              )}
            >
              <li className={styles.viewAction}>
                <ModeButton
                  icon={faSquareKanban}
                  onChangeMode={onChangeView}
                  title={
                    <>
                      {t('Common.dashboardView')}
                      <Tag size="s" label={t('Common.betaTag')} className={styles.tag} />
                    </>
                  }
                  mode={PageView.dashboard}
                  isActive={currentView === PageView.dashboard}
                  className={styles.button}
                  disabled={!isWorkspaceDashboardFeature}
                />
              </li>
            </ConditionalWrapper>
          )}

          {!isDashboard && (
            <li className={styles.viewAction}>
              <ModeButton
                icon={faColumns}
                onChangeMode={onChangeView}
                title={cardViewText || t('Common.cardsView')}
                mode={PageView.board}
                isActive={currentView === PageView.board}
                className={styles.button}
              />
            </li>
          )}
        </ul>
        {extra && <div className={styles.extra}>{extra}</div>}
      </div>
    </div>
  );
};

export default ViewOptionsDropDownOverlay;
