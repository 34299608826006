import { useMutation, useQuery } from '@apollo/client';
import { MutationTuple, QueryResult } from '@apollo/client/react/types/types';
import { gqlClient } from 'src/lib/API/graphql/api-client';
import { MutationUpdaterFn } from '@apollo/client/core';
import { useMe, notification } from 'src/common';
import { ICompanyUser, IApiCompanyUser } from './types';
import {
  CHANGE_EMPLOYEE_ACTIVE_STATUS,
  FETCH_EMPLOYEES,
  ADD_EMPLOYEE,
  SEND_INVITE_TO_COMPANY,
  ADD_MULTIPLE_EMPLOYEES
} from './queries';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { IUserName } from 'src/models/users/types';
import { trackUserInvite, trackMultipleUsersInvite } from 'src/segment/events';
import { CompanyUser } from '.';
import { Team } from 'src/models/users/Team';
import { ApiTeam } from 'src/models/users/Team/types';
import { useParams } from 'react-router-dom';
import { COMPANY_USER_FIELDS } from '../queries';

interface IApiGetEmployees {
  getCompany: {
    personel: IApiCompanyUser[];
    teams: ApiTeam[];
    createdBy: IApiCompanyUser;
  };
}
export interface IEmployees {
  personel: ICompanyUser[];
  teams: Team[];
  createdBy?: ICompanyUser;
}
export function useCompanyEmployees(): Omit<QueryResult<IApiGetEmployees>, 'data'> & { data?: IEmployees } {
  const query = useQuery<IApiGetEmployees>(FETCH_EMPLOYEES);

  return useMemo(
    () => ({
      ...query,
      data: query.data
        ? {
            createdBy: query.data.getCompany.createdBy ? new CompanyUser(query.data.getCompany.createdBy) : undefined,
            personel: query.data.getCompany.personel.map(user => new CompanyUser(user)),
            teams: query.data.getCompany.teams.map((team: ApiTeam) => new Team(team))
          }
        : undefined
    }),
    [query]
  );
}

export interface IGetCompanyAdmin {
  getCompany: {
    createdBy: {
      email: string;
      isAdmin: boolean;
    };
  };
}

export interface IChangeEmployeeActiveStatus {
  __typename: 'Mutation';
  updateUser: ICompanyUser & {
    __typename: 'User';
  };
}
export function useChangeEmployeeActiveStatus(): MutationTuple<
  IChangeEmployeeActiveStatus,
  { email: string; enabled: boolean }
> {
  return useMutation(CHANGE_EMPLOYEE_ACTIVE_STATUS);
}
export function getUpdateCacheOnChangeEmployeeActiveStatus(
  email: string
): MutationUpdaterFn<IChangeEmployeeActiveStatus> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }
    const userRef = cache.identify({
      __typename: 'User',
      email
    });
    cache.modify({
      id: userRef,
      fields: {
        enabled() {
          return data.updateUser.enabled;
        }
      }
    });
  };
}

export interface IInvitedUser extends Pick<IUserName, 'email' | 'name' | 'lastName'> {
  inviteSubUser: boolean;
  companyId?: string;
  licenseIds?: string[];
  password?: string;
}
export interface IAddEmployeeResponse {
  __typename: 'Mutation';
  createUser: ICompanyUser & {
    __typename: 'User';
  };
}
export function useAddEmployee(): MutationTuple<IAddEmployeeResponse, IInvitedUser> {
  return useMutation(ADD_EMPLOYEE);
}
export function getUpdateCacheOnAddEmployee(companyId: string): MutationUpdaterFn<IAddEmployeeResponse> {
  return (cache, result) => {
    if (!result.data) {
      console.warn('mutation has no result!');
      return;
    }
    const companyRef = cache.identify({
      __typename: 'Company',
      id: companyId
    });

    const newUser = result.data.createUser;

    const newUserRef = cache.writeFragment({
      id: cache.identify({ __typename: 'User', email: result.data.createUser.email }),
      fragment: COMPANY_USER_FIELDS,
      fragmentName: 'CompanyUserFields',
      data: {
        ...newUser
      }
    });

    cache.modify({
      id: companyRef,
      fields: {
        personel(existingUsersRefs = []) {
          return [...existingUsersRefs, newUserRef];
        }
      }
    });
    gqlClient.clearQueryCache('searchUserList');
  };
}

export function useSendInviteToCompany(): MutationTuple<
  { createInviteCompanyUserRequest: IApiCompanyUser },
  { email: string }
> {
  return useMutation(SEND_INVITE_TO_COMPANY);
}

export interface IAddMultipleEmployeesResponse {
  __typename: 'Mutation';
  createMultipleUsers: IApiCompanyUser[];
}
export function useAddMultipleEmployees(): MutationTuple<IAddMultipleEmployeesResponse, { users: IInvitedUser[] }> {
  return useMutation(ADD_MULTIPLE_EMPLOYEES);
}
export function getUpdateCacheOnAddMultipleEmployees(
  companyId: string
): MutationUpdaterFn<IAddMultipleEmployeesResponse> {
  return (cache, result) => {
    if (!result.data) {
      return;
    }
    const companyRef = cache.identify({
      __typename: 'Company',
      id: companyId
    });

    const newUsersRefs = result.data.createMultipleUsers.map(user => {
      const newUserRef = cache.writeFragment({
        id: cache.identify({ __typename: 'User', email: user.email }),
        fragment: COMPANY_USER_FIELDS,
        fragmentName: 'CompanyUserFields',
        data: {
          ...user
        }
      });
      return newUserRef;
    });

    cache.modify({
      id: companyRef,
      fields: {
        personel(existingUsersRefs = []) {
          return [...existingUsersRefs, ...newUsersRefs];
        }
      }
    });
    gqlClient.clearQueryCache('searchUserList');
  };
}

export function useInviteUsers(): [
  (values: { users: IInvitedUser[]; onInvite?: () => void; onError?: (users: IInvitedUser[]) => void }) => void,
  { loading: boolean }
] {
  const { t } = useTranslation();
  const { data: meData } = useMe();
  const { companyId } = useParams<{ companyId?: string }>();

  const meCompanyId = meData?.company.id;

  const [addUser, { loading: addUserLoading }] = useAddEmployee();
  const [addMultipleUsers, { loading: addMultipleUsersLoading }] = useAddMultipleEmployees();

  const onAddUsers = useCallback(
    (values: { users: IInvitedUser[]; onInvite?: () => void; onError?: (users: IInvitedUser[]) => void }) => {
      const { users, onInvite, onError } = values;
      const usersCount = users.length;
      const companyIdToUse = companyId ? companyId : meCompanyId;

      if (companyIdToUse && !!usersCount) {
        if (usersCount === 1) {
          const { name, lastName, email, licenseIds, password } = users[0];

          addUser({
            variables: {
              name,
              lastName,
              email,
              inviteSubUser: true,
              companyId: companyId ? companyId : undefined,
              licenseIds,
              password
            },
            update: getUpdateCacheOnAddEmployee(companyIdToUse)
          })
            .then(result => {
              if (result.data) {
                const { createUser: user } = result.data;
                trackUserInvite({
                  email: user.email,
                  name: user.name,
                  lastName: user.lastName
                });
              }
              !!onInvite && onInvite();
            })
            .catch(() => {
              notification.error({
                message: t('Users.employeeHasNotAdded'),
                description: t('Common.unknownErrorDesc')
              });
            });
        } else {
          const multipleUsers: IInvitedUser[] = users.map(user => ({
            email: user.email,
            name: user.name,
            lastName: user.lastName,
            inviteSubUser: true,
            companyId: companyId ? companyId : undefined,
            licenseIds: user.licenseIds,
            password: user.password
          }));

          addMultipleUsers({
            variables: { users: multipleUsers },
            update: getUpdateCacheOnAddMultipleEmployees(companyIdToUse)
          })
            .then(result => {
              if (result.data) {
                const trackUsers = result.data.createMultipleUsers.map(user => ({
                  email: user.email,
                  name: user.name,
                  lastName: user.lastName,
                  inviteSubUser: true
                }));
                trackMultipleUsersInvite(trackUsers);
                if (trackUsers.length !== usersCount) {
                  const unAddedUsers = users.filter(
                    user => !trackUsers.some(trackUser => trackUser.email === user.email)
                  );
                  onError && onError(unAddedUsers);
                  throw new Error(
                    t('Users.inviteMultipleUsersError', { users: unAddedUsers.map(user => user.email).join(', ') })
                  );
                }
              }
              !!onInvite && onInvite();
            })
            .catch(() => {
              notification.error({
                message: t('Users.employeeHasNotAdded'),
                description: t('Common.unknownErrorDesc')
              });
            });
        }
      }
    },
    [addMultipleUsers, addUser, companyId, meCompanyId, t]
  );

  return [onAddUsers, { loading: addUserLoading || addMultipleUsersLoading }];
}
