import React, { FC, useMemo } from 'react';
import styles from './index.module.scss';
import { AssignToQuestion, TaskFulfillmentPicker, TaskRequirementPicker, TaskStatusPicker } from 'src/shared';
import { useBidWorkspace } from 'src/models/bids/Bids/hooks';
import { useTask } from 'src/models/bids/BidTask/hooks';
import { TaskTrackPickerData } from 'src/segment/events';
import { TaskDatePicker } from 'src/shared/Bid/TaskDatePicker';
import { isTaskDone } from 'src/models/bids/BidTask/types';

export const TaskToolbar: FC = () => {
  const { data: task } = useTask();

  const bidId = task?.bidId;
  const groupId = task?.groupId;
  const { workspace } = useBidWorkspace(bidId);

  const trackData: TaskTrackPickerData = useMemo(
    () => ({ bidId, eventSource: 'Task Preview', mode: 'single', groupId }),
    [bidId, groupId]
  );

  return (
    <>
      {task && groupId && (
        <div className={styles.topBar}>
          <div className={styles.actions}>
            <TaskFulfillmentPicker
              groupId={groupId}
              questions={[task]}
              taskValue={task.fulfilment}
              trackData={trackData}
            />
            <TaskRequirementPicker
              groupId={groupId}
              questions={[task]}
              taskValue={task.requirementType}
              trackData={trackData}
            />

            <TaskDatePicker
              groupId={groupId}
              questions={[task]}
              taskValue={task.deadline}
              trackData={trackData}
              isTaskDone={isTaskDone(task)}
            />
            <TaskStatusPicker groupId={groupId} questions={[task]} taskValue={task.status} trackData={trackData} />

            {bidId && (
              <AssignToQuestion
                groupId={groupId}
                assignedTo={task.assignedTo}
                questions={[task]}
                wsId={workspace?.id}
                bidId={bidId}
                eventSource={'Task Preview'}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default TaskToolbar;
