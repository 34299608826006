import React, { FC, useState } from 'react';
import styles from './index.module.scss';
import { IBaseWorkspace } from 'src/models/workspace/types';
import { Skeleton, WorkspaceBadge } from 'src/common';
import classNames from 'classnames';
import { useIsMobile } from 'src/reactiveVars';

interface Props {
  ws: IBaseWorkspace;
  className?: string;
  loading?: boolean;
  onMoveToWorkspace: (id: string) => Promise<void>;
}

export const WorkspacePickerItem: FC<Props> = props => {
  const { ws, className, onMoveToWorkspace, loading } = props;
  const [isMobile] = useIsMobile();
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState<string>();

  return (
    <li
      className={classNames(styles.wsItem, {
        [styles.isLoading]: loading && !isMobile,
        [styles.mobile]: isMobile,
        [styles.disabled]: loading && selectedWorkspaceId !== ws.id,
        [styles.active]: isMobile && loading && selectedWorkspaceId === ws.id
      })}
      onClick={() => {
        setSelectedWorkspaceId(ws.id);
        onMoveToWorkspace(ws.id);
      }}
    >
      <WorkspaceBadge
        title={ws.name}
        color={ws.color}
        mode={'full'}
        className={classNames(className, styles.wsItemBadge)}
        size={'xs'}
        tooltip={true}
        isHeadingFontSize={isMobile}
      />
      {!isMobile && <Skeleton className={styles.wsItemLoading} loading={loading} paragraph={false} active={loading} />}
    </li>
  );
};
export default WorkspacePickerItem;
