import { gql } from '@apollo/client';

export const GET_COMPETITORS_PAYMENTS = gql`
  query getCompetitorsPayments($buyerOrgId: String!, $yearTo: Float, $yearFrom: Float, $desiredCurrency: String) {
    getCompetitorsPayments(
      buyerOrgId: $buyerOrgId
      yearTo: $yearTo
      yearFrom: $yearFrom
      desiredCurrency: $desiredCurrency
    ) {
      supplierOrgId
      supplierOrgName
      transactions {
        sumAmount
        sumCurrency
      }
    }
  }
`;

export const ADD_COMPETITORS = gql`
  mutation AddCompetitors($companyId: String!, $orgIds: [String!]!) {
    addCompetitors(input: { companyId: $companyId, orgIds: $orgIds }) {
      orgId
    }
  }
`;

export const REMOVE_COMPETITORS = gql`
  mutation RemoveCompetitors($companyId: String!, $orgIds: [String!]!) {
    removeCompetitors(input: { companyId: $companyId, orgIds: $orgIds }) {
      orgId
    }
  }
`;
