export function reOrderRefs<T>(items: T[], itemRef: string, getRef: (item: T) => string, newIndex: number): T[] {
  const updatedItems = [...items];
  const currentIndex = updatedItems.findIndex(item => getRef(item) === itemRef);

  if (currentIndex === -1) return items;

  const [movedItem] = updatedItems.splice(currentIndex, 1);
  updatedItems.splice(newIndex, 0, movedItem);

  return updatedItems;
}
