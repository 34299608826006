import React, { ReactNode, FC, useMemo, useState } from 'react';
import { BidChecklistContext, ChecklistExpandedContext, ToggleChecklistContext } from './context';
import { useApiBidChecklist } from './hooks';
import { BidChecklist } from '.';

interface Props {
  children: ReactNode;
  bidId: string;
}

export const ChecklistExpandProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [isExpanded, toggleChecklist] = useState(false);
  return (
    <ToggleChecklistContext.Provider value={toggleChecklist}>
      <ChecklistExpandedContext.Provider value={isExpanded}>{children}</ChecklistExpandedContext.Provider>
    </ToggleChecklistContext.Provider>
  );
};

export const BidChecklistProvider: FC<Props> = ({ bidId, children }) => {
  const { data, loading } = useApiBidChecklist(bidId);
  const contextValue = useMemo(
    () => ({ data: data?.getBid ? new BidChecklist(data?.getBid) : null, loading }),
    [data, loading]
  );

  return (
    <BidChecklistContext.Provider value={contextValue}>
      <ChecklistExpandProvider>{children}</ChecklistExpandProvider>
    </BidChecklistContext.Provider>
  );
};
