import React, { FC, ReactNode } from 'react';
import FilterItem from '../FilterItem';
import styles from './index.module.scss';

interface FilterListProps {
  items: { label: ReactNode; id: string }[];
  onRemove: (id: string) => void;
}

const FilterList: FC<FilterListProps> = ({ items, onRemove }) => {
  if (items.length === 0) return null;

  return (
    <div className={styles.filterList}>
      {items.map(item => (
        <FilterItem key={item.id} label={item.label} id={item.id} onRemove={onRemove} />
      ))}
    </div>
  );
};

export default FilterList;
