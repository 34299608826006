import React, { FC, useEffect, useMemo, useState } from 'react';
import { useBidding } from 'src/models/bids/BidFull/hooks';
import TasksStatistic from '../TasksStatistic';
import { AiBadge, Button, Heading, Skeleton, useExistingTranslation } from 'src/common';
import LinkedSource from 'src/shared/LinkedSource';
import { FeatureFlag, useFeatureFlag } from 'src/helpers';
import { BidItemType } from '@tendium/prom-types/tender';
import { BlockTitle, Competitors, PreviousProcurements } from 'src/shared';
import styles from './index.module.scss';
import BidPreviewTenderBoxes from 'src/pages/BidsPage/BidPreview/TenderBoxes';
import CustomBoxes from 'src/pages/BidsPage/BidPreview/CustomBoxes';
import { useBidPreview } from 'src/models/bids/BidPreview/hooks';
import { isFieldString } from 'src/models/procurements/Tender/types';
import { SchemaUtils } from '@tendium/prom-types';
import { useTranslation } from 'react-i18next';
import { DecisionMatrix } from 'src/shared/DecisionMatrix';
import { BillingChartsComponent } from 'src/shared/Tender/Billing/BillingCharts';
import classNames from 'classnames';
import { useGetPreviousTenders } from 'src/models/procurements/PreviousTenders/hooks';
import { Translations } from 'src/lib/I18nService/types';
import ManageTemplatesModal from 'src/shared/DecisionMatrix/ManageTemplatesModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { ShortDescriptionBox } from 'src/shared/ShortDescriptionBox';

const { BuyerBoxFieldNames } = SchemaUtils.TenderBox;

export const BidOverviewContent: FC = () => {
  const { data: biddingData, loading, statsLoading } = useBidding();
  const { data: bidPreviewData } = useBidPreview();
  const { data: previousProcurements } = useGetPreviousTenders(
    biddingData?.originId ?? '',
    biddingData?.originType !== BidItemType.Procurement
  );

  const { t } = useTranslation();
  const { existingT: tenderT } = useExistingTranslation(Translations.procurements);

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const [selectedTenderId, setSelectedTenderId] = useState<string | undefined>(
    !!previousProcurements.length ? previousProcurements[0].id : undefined
  );

  useEffect(() => {
    if (previousProcurements?.length > 0 && !selectedTenderId) {
      setSelectedTenderId(previousProcurements[0].id);
    }
  }, [previousProcurements, selectedTenderId]);

  const buyerBoxes = bidPreviewData?.origin.buyerBoxes;
  const [leadBuyer] = buyerBoxes ?? [];
  const buyerOrgId = useMemo(() => {
    const orgId = leadBuyer?.fields.find(field => field.name === BuyerBoxFieldNames.ORG_ID);
    return isFieldString(orgId) ? (orgId.string as string) : undefined;
  }, [leadBuyer]);

  const isCustomBidFieldsFeature = useFeatureFlag(FeatureFlag.CustomBidFields);
  const isPreviousProcurementsFeature = useFeatureFlag(FeatureFlag.Analytics_Previous_Procurements);
  const isAnalyticsBillingDuringContractFeature = useFeatureFlag(FeatureFlag.Analytics_Billing_During_Contract);
  const isCompetitorsFeature = useFeatureFlag(FeatureFlag.Users_Competitors);
  const isBidFlowFeature = useFeatureFlag(FeatureFlag.BiddingWorkflow_Tasks);
  const isDecisionMatrixFeature = useFeatureFlag(FeatureFlag.Bidding_DecisionMatrix);

  const isInteractivePreviousProcurementsFeature =
    isPreviousProcurementsFeature && isAnalyticsBillingDuringContractFeature;

  return (
    <>
      {loading || statsLoading ? (
        <Skeleton active loading />
      ) : (
        <>
          {isBidFlowFeature && <TasksStatistic />}
          <div className={classNames(styles.linkedSource, { [styles.linkedSourceTop]: !isBidFlowFeature })}>
            <LinkedSource />
          </div>
          {isCustomBidFieldsFeature && (
            <div className={styles.boxes}>
              <CustomBoxes />
            </div>
          )}
          {isDecisionMatrixFeature && <Heading title={t('BiddingTool.toolsForDecision')} className={styles.heading} />}
          {isDecisionMatrixFeature && (
            <div className={styles.decisionMatrix}>
              {bidPreviewData?.decisionMatrix ? (
                <DecisionMatrix />
              ) : (
                <Button
                  onClick={() => setIsModalVisible(true)}
                  type={'default'}
                  icon={<FontAwesomeIcon icon={faPlus} />}
                >
                  {t('BiddingTool.applyDecisionMatrix')}
                </Button>
              )}
            </div>
          )}
          <Heading title={t('BiddingTool.tenderInformation')} className={styles.heading} />
          <ShortDescriptionBox
            boxes={bidPreviewData?.origin.description}
            originType={bidPreviewData?.origin.originType}
            className={styles.shortDescription}
          />
          <div className={styles.boxes}>
            <BidPreviewTenderBoxes />
          </div>
          {(isCompetitorsFeature || isInteractivePreviousProcurementsFeature) && (
            <Heading title={t('BiddingTool.marketAnalysis')} className={styles.heading} />
          )}
          {isCompetitorsFeature && (
            <div className={styles.boxes}>
              <Competitors orgId={buyerOrgId} />
            </div>
          )}
          {biddingData?.originType === BidItemType.Procurement &&
            biddingData?.originId &&
            isInteractivePreviousProcurementsFeature && (
              <div className={styles.previousProcurements}>
                <div>
                  <BlockTitle
                    title={
                      <div className={styles.blockTitle}>
                        <AiBadge size="xs" />
                        {tenderT('PREVIOUS_PROCUREMENTS')}
                      </div>
                    }
                    noBorder
                  />
                  <PreviousProcurements
                    procurementId={biddingData?.originId}
                    onClickAction={setSelectedTenderId}
                    withoutSection={true}
                    isActiveId={selectedTenderId}
                  />
                </div>
                <BillingChartsComponent tenderId={selectedTenderId} onlyCharts={true} />
              </div>
            )}
        </>
      )}
      {isModalVisible && <ManageTemplatesModal onClose={() => setIsModalVisible(false)} />}
    </>
  );
};

export default BidOverviewContent;
