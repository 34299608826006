import { InMemoryCache, gql } from '@apollo/client';
import { createFragmentRegistry } from '@apollo/client/cache';
import { toHSLColor } from 'src/helpers/styles';
import { isString, isStrings } from 'src/helpers';
import { getTendersPolicy } from 'src/models/procurements/Tenders/fieldPolicy';
import { IApiOrganization } from 'src/models/organisations/types';
import { SearchProfileColor, SearchProfilesColorsDict } from 'src/helpers/constants';
import { getAllBidsPolicy } from 'src/models/bids/BidsSelector/fieldPolicy';
import { getCallOffsPolicy } from 'src/models/callOffs/fieldPolicy';
import { getOrgsByIdPolicy, getOrgsPolicy } from 'src/models/organisations';
import { getTasksPolicy, getBiddingStatsPolicy, getTaskReferencesPolicy } from 'src/models/bids/BidFull/fieldPolicy';
import { getCommentsCountPolicy, getParentCommentsPolicy } from 'src/models/comments/fieldPolicy';
import { getContentLibraryPolicy } from 'src/models/contentLibrary/ContentLibrary/fieldPolicy';
import { getAdminUsersPolicy } from 'src/models/admin/Users/fieldPolicy';
import { USER_NAME_FIELDS } from 'src/models/users/queries';
import { COMPANY_FIELDS, COMPANY_USER_FIELDS } from 'src/models/company/queries';
import { getAdminCompaniesPolicy } from 'src/models/admin/Companies/fieldPolicy';
import { getRemindersPolicy } from 'src/models/reminders/fieldPolicy';
import { getFeatureSetsAsAdminPolicy } from 'src/models/admin/FeatureSet/fieldPolicy';
import { getFeatureRolesAsAdminPolicy } from 'src/models/admin/FeatureRole/fieldPolicy';
import { getBuyerSupplierTransactionHistoryPolicy } from 'src/models/buyerProfiles/fieldPolicy';
import crypto from 'crypto';

export const cache = new InMemoryCache({
  // https://github.com/apollographql/apollo-client/issues/7050
  typePolicies: {
    ChecklistItem: {
      fields: {
        attachments: {
          merge(existing, incoming) {
            return incoming;
          }
        }
      }
    },
    ProcurementBidQuestion: {
      fields: {
        document: {
          merge(existing, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming);
          }
        },
        primaryReferences: getTaskReferencesPolicy(),
        secondaryReferences: getTaskReferencesPolicy()
      }
    },
    User: {
      keyFields: ['email'],
      fields: {
        color: {
          read(value, { readField }) {
            const email = readField('email');
            return !!value ? value : isString(email) ? toHSLColor(email) : null;
          }
        },
        settings: {
          merge(existing, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming);
          }
        }
      }
    },
    OCDSTender: {
      fields: {
        tender: {
          merge(existing, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming);
          }
        }
      }
    },
    HashedIntegrationToken: {
      keyFields: ['hash']
    },
    KeywordGroupDTO: {
      keyFields: ['groupId']
    },
    CompanyInbox: {
      keyFields: ['emailAddress']
    },
    WebhookBidspaceSettings: {
      keyFields: ['workspaceId']
    },
    UserMail: {
      keyFields: ['mailId'],
      fields: {
        lots: {
          read() {
            return [];
          }
        }
      }
    },
    MatchingProfile: {
      fields: {
        subscribers: {
          merge(existing, incoming) {
            return incoming;
          }
        },
        owners: {
          merge(existing, incoming) {
            return incoming;
          }
        },
        companies: {
          read(_, { readField }) {
            const companiesIds = readField('buyerOrgIds');
            const companies: IApiOrganization[] =
              !!companiesIds && !!isStrings(companiesIds)
                ? companiesIds.map(companyId => {
                    return {
                      organisationNumber: companyId,
                      organisationName: null
                    };
                  })
                : [];
            return companies;
          }
        }
      }
    },
    SearchProfile: {
      fields: {
        colorValue: {
          read(value, { readField }) {
            const color = readField('color') as SearchProfileColor;
            return color ? SearchProfilesColorsDict[color] : SearchProfilesColorsDict.Gray;
          }
        }
      }
    },
    ListAttachmentDTO: {
      fields: {
        loading: {
          read(existing = false) {
            return existing;
          }
        }
      }
    },
    DirectoryNode: {
      fields: {
        children: {
          merge(existing, incoming) {
            return incoming;
          }
        }
      }
    },
    NotificationEntity: {
      keyFields: ['createdAt']
    },
    Competitor: {
      keyFields: ['orgId']
    },
    GetCommentsCountResult: {
      keyFields: ['parentId']
    },
    QuestionGroupStatistics: {
      keyFields: ['groupId']
    },
    Organisation: {
      keyFields: ['organisationNumber']
    },
    ByggfaktaPrivateCreatedProject: {
      keyFields: ['projectId']
    },
    RequestSettings: {
      keyFields: ['entityId', 'requestType']
    },
    DocumentCoordinates: {
      keyFields: ['classificationId']
    },
    ActiveContractBreakdown: {
      keyFields: ['orgId']
    },
    SupplierSpendData: {
      keyFields: ['supplier', ['organisationNumber']]
    },
    ContentLibraryContent: {
      keyFields: ['id', 'uuid'],
      fields: {
        uuid: {
          read(value, { readField }) {
            const documentPositions = readField('documentPositions');
            const stringifiedPositions = documentPositions ? JSON.stringify(documentPositions) : '';
            const hashedValue = crypto.createHash('sha256').update(stringifiedPositions).digest('hex');
            return documentPositions ? hashedValue : readField('id');
          }
        }
      }
    },
    Query: {
      fields: {
        getTenders: getTendersPolicy(),
        getSimpleTenders: getTendersPolicy(),
        getTender: {
          read(_, { args, toReference }) {
            return args
              ? toReference({
                  __typename: 'Tender',
                  id: args.query.id
                })
              : _;
          }
        },
        searchTerms: {
          keyArgs: ['query', ['procurementId', 'terms']]
        },
        ls: {
          merge(existing, incoming) {
            return incoming;
          }
        },
        getPromptBatteryAnswers: {
          merge(existing, incoming) {
            return incoming;
          }
        },
        getSearchProfiles: {
          merge(existing, incoming) {
            return incoming;
          }
        },
        getAllMyMatchingProfiles: {
          keyArgs: ['input', ['profileType']]
        },
        getAllCustomBidFields: {
          merge(existing, incoming) {
            return incoming;
          }
        },
        getMatchingProfile: {
          read(_, { args, toReference }) {
            return args
              ? toReference({
                  __typename: 'MatchingProfile',
                  id: args.id
                })
              : _;
          }
        },
        getWebhooks: {
          merge(existing, incoming) {
            return incoming;
          }
        },
        getIntegrationTokens: {
          merge(existing, incoming) {
            return incoming;
          }
        },
        getChecklistTemplates: {
          merge(existing, incoming) {
            return incoming;
          }
        },
        getBid: {
          read(_, { args, toReference }) {
            return args
              ? toReference({
                  __typename: 'BidV2',
                  id: args.input.bidId
                })
              : _;
          }
        },
        getBids: getAllBidsPolicy(),
        getInboxEmails: getCallOffsPolicy(),
        getWorkspaceStats: {
          keyArgs: ['input', ['workspaceId', 'rejected', 'currency']]
        },
        findOrganisationsByNameOrId: getOrgsPolicy(),
        getGroupQuestions: getTasksPolicy(),
        getBidQuestion: {
          read(_, { args, toReference }) {
            return args
              ? toReference({
                  __typename: 'ProcurementBidQuestion',
                  id: args.input.questionId
                })
              : _;
          }
        },
        getQuestionGroupsStatistics: getBiddingStatsPolicy(),
        getCommentsCount: getCommentsCountPolicy(),
        getComments: getParentCommentsPolicy(),
        getContentLibraryContents: getContentLibraryPolicy(),
        getOrganisationsByIds: getOrgsByIdPolicy(),
        searchUser: getAdminUsersPolicy(),
        getCompanies: getAdminCompaniesPolicy(),
        getReminders: getRemindersPolicy(),
        getMarketAnalytics: {
          keyArgs: ['filters', ['profileId', 'tenderStatus', 'dateFilter']],
          merge(existing, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming);
          }
        },
        getTasksGenerationStatus: {
          keyArgs: ['input', ['bidId']],
          merge(_existing, incoming) {
            return incoming;
          }
        },
        getFeatureSetsAsAdmin: getFeatureSetsAsAdminPolicy(),
        getFeatureRolesAsAdmin: getFeatureRolesAsAdminPolicy(),
        getBuyerSupplierTransactionHistory: getBuyerSupplierTransactionHistoryPolicy()
      }
    }
  },
  fragments: createFragmentRegistry(gql`
    ${USER_NAME_FIELDS}
    ${COMPANY_USER_FIELDS}
    ${COMPANY_FIELDS}
  `)
});
