import { useCallback, useMemo } from 'react';
import { useQuery, MutationTuple, useMutation, ApolloError, useLazyQuery, LazyQueryResultTuple } from '@apollo/client';
import {
  IBidSorting,
  NewBidspaceRequest,
  BidspaceQueryParams,
  BidsColumn,
  SortOrder,
  ApiGetTotalBidsResponse,
  ApiGetTotalBidsRequest,
  BidspaceStatisticsData,
  AssignedToStatisticsData,
  AssignedToStatisticsResponse,
  BidsCategoryStatistics,
  BidsCategoryStatisticsResponse,
  ContractStartRangesFilter,
  IUpdateBidsWsResponse,
  ISearchBidsRequest,
  IUpdateBidStatusResponse,
  IUpdateBidsStatusRequest,
  ICreateBidsResponse,
  BidspaceQpVars,
  BidsVars,
  BidsResponseData,
  IApiGetBidsResponse,
  ICreateBidsRequest,
  IGetBidResponse,
  IGetBidRequest,
  IUpdateBidsWsRequest,
  IMoveToWorkspaceInput
} from './types';
import {
  CREATE_BIDS,
  UPDATE_BIDS_WS,
  UPDATE_BID_STATUS,
  GET_BIDS,
  GET_BID,
  GET_TOTAL_BIDS,
  GET_ASSIGNED_TO_STATISTICS,
  GET_BIDS_PAGES,
  GET_BIDS_CATEGORY_STATISTICS,
  GET_MONTHLY_BIDS_PAGES
} from './queries';
import { AllAssignedToBidspacesStatistics, AllBidspaceStatistics, BidsCategoryStatistic, BidsData } from '.';
import { useWorkspaces } from 'src/models/workspace/hooks';
import { IWorkspace } from 'src/models/workspace/types';
import { IWorkspaceStatus } from 'src/models/workspace/WorkspaceStatus/types';
import { BidItemType, WorkspaceStatusCategory } from '@tendium/prom-types/tender';
import useBidsSearchArgs from 'src/reactiveVars/BidsSearchArgsVar';
import { useUnAssignMailsToBid } from '../BidsSelector/hooks';
import { useTranslation } from 'react-i18next';
import groupBy from 'lodash/groupBy';
import { trackMoveItemsToWorkspace } from 'src/segment/events';
import { notification } from 'src/common';
import { useSetRequestSettings } from 'src/models/users/RequestSettings/hooks';
import { RequestType } from 'src/models/users/RequestSettings/types';
import { WebhookStatus } from 'src/models/company/Webhooks/types';
import { useParams, useLocation } from 'react-router';
import { FeatureFlag, isString, isStrings, updateQueryParams, useFeatureFlag, useQueryParams } from 'src/helpers';
import { Interactions } from '@tendium/prom-types/schema';
import { Paths } from 'src/pages/paths';
import { formatDateWithTimezoneOffset } from 'src/helpers/dates';
import dayjs from 'src/helpers/dayjs';
import { getLoadingStatesForQuery } from 'src/lib/API/graphql/helpers';
import { updateCacheOnCreateBids, updateCacheOnUpdateBidsWs } from './cacheHandlers';
import { transformAssignedToQpVars, transformQpVars, transformSearchQpVars } from './helpers';
import useCurrency from 'src/common/hooks/useCurrency';
import { useDefaultDecisionMatrixTemplate } from 'src/models/decisionMatrix/hooks';
import { formatToCreateBidItems } from 'src/models/procurements/Preview/helpers';

export function useBidsFilterVars(): BidsVars {
  const vars = useBidspacesQpVars();
  const updateVars = useCallback((vars: Partial<BidspaceQpVars>) => {
    updateQueryParams(vars);
  }, []);

  const clearVars = useCallback(() => {
    updateQueryParams({
      search: undefined,
      category: undefined,
      bidspaceId: undefined,
      bidType: undefined,
      assignedTo: undefined
    });
  }, []);

  return useMemo(
    () => ({
      updateVars,
      currentVars: vars,
      clearVars
    }),
    [clearVars, updateVars, vars]
  );
}

export function useStagesBids(params?: {
  amount?: number;
  stageId?: string;
  assignedToId?: string;
  sort?: IBidSorting;
}): BidsResponseData {
  const { wsId } = useParams<{ wsId: string }>();
  const stageId = params?.stageId;
  const amount = params?.amount;
  const assignedToId = useMemo(() => params?.assignedToId, [params]);
  const [{ amount: defaultAmount, ...restSearchArgs }] = useBidsSearchArgs();
  const vars = useBidspacesQpVars();
  const { pathname: currentUrl } = useLocation();
  const isBidspaces = useMemo(() => currentUrl === Paths.BIDSPACES, [currentUrl]);

  const workspaceIds = useMemo(() => {
    return !isBidspaces && wsId ? [...(vars.bidspaceId ?? []), wsId] : [...(vars.bidspaceId ?? [])];
  }, [isBidspaces, vars.bidspaceId, wsId]);

  const sortOnField = params?.sort ?? restSearchArgs.sortOnField;
  const isWebHooksFeature = !!useFeatureFlag(FeatureFlag.Users_Webhooks);

  const query = useQuery<IApiGetBidsResponse, ISearchBidsRequest>(GET_BIDS, {
    variables: {
      stageIds: stageId ? [stageId] : undefined,
      assignedToIds: assignedToId ? [assignedToId] : transformAssignedToQpVars(vars),
      amount: amount || defaultAmount,
      ...(isBidspaces ? transformQpVars(vars) : undefined),
      textSearch: transformSearchQpVars(vars.search),
      workspaceIds: workspaceIds,
      ...restSearchArgs,
      offset: 0,
      sortOnField,
      isWebHooksFeature
    }
  });

  return useMemo(
    () => ({
      ...query,
      ...getLoadingStatesForQuery(query),
      data: query.data ? new BidsData(query.data.getBids) : undefined
    }),
    [query]
  );
}

export function useDeadlineComingUpBids(params?: {
  amount?: number;
}): Omit<BidsResponseData, 'updateVars' | 'currentVars' | 'clearVars'> {
  const amount = params?.amount;
  const [{ amount: defaultAmount, ...restSearchArgs }] = useBidsSearchArgs();
  const vars = useBidspacesQpVars();
  const isWebHooksFeature = !!useFeatureFlag(FeatureFlag.Users_Webhooks);

  const query = useQuery<IApiGetBidsResponse, ISearchBidsRequest>(GET_BIDS_PAGES, {
    variables: {
      amount: amount ?? defaultAmount,
      ...transformQpVars(vars),
      textSearch: transformSearchQpVars(vars.search),
      assignedToIds: transformAssignedToQpVars(vars),
      ...restSearchArgs,
      sortOnField: {
        name: BidsColumn.deadline,
        order: SortOrder.ASC
      },
      isWebHooksFeature
    }
  });

  return useMemo(
    () => ({
      ...query,
      ...getLoadingStatesForQuery(query),
      data: query.data ? new BidsData(query.data.getBids) : undefined
    }),
    [query]
  );
}

export function useTotalStagesBids(): LazyQueryResultTuple<ApiGetTotalBidsResponse, ApiGetTotalBidsRequest> {
  return useLazyQuery<ApiGetTotalBidsResponse, ApiGetTotalBidsRequest>(GET_TOTAL_BIDS, { fetchPolicy: 'no-cache' });
}

export function useBidWorkspace(bidId?: string): {
  workspace: IWorkspace | null;
  status: IWorkspaceStatus | null;
  didFail: boolean;
  inNoAccessWorkspace: boolean;
  webhookStatus?: WebhookStatus;
} {
  const { data: wssData } = useWorkspaces();
  const { data: bidData } = useQuery<IGetBidResponse, IGetBidRequest>(GET_BID, {
    variables: { bidId: bidId || '' },
    skip: !bidId,
    fetchPolicy: 'cache-first'
  });
  const bidWsId = bidData?.getBid?.workspace.id;
  const statusWsId = bidData?.getBid?.status.id;

  const workspace = useMemo(
    () => (!!bidWsId && wssData && wssData.find(ws => ws.id === bidWsId)) || null,
    [bidWsId, wssData]
  );

  const inNoAccessWorkspace = useMemo(
    () => (!!bidWsId && wssData && !wssData.find(ws => ws.id === bidWsId)) || false,
    [bidWsId, wssData]
  );

  const status = useMemo(
    () => (workspace && workspace.statuses.find(wsStatus => wsStatus.id === statusWsId)) || null,
    [statusWsId, workspace]
  );

  const didFail = useMemo(() => bidData?.getBid === null, [bidData]);

  const webhookStatus = useMemo(() => bidData?.getBid?.webhookStatus, [bidData]);

  return useMemo(() => {
    return { workspace, status, didFail, inNoAccessWorkspace, webhookStatus };
  }, [didFail, inNoAccessWorkspace, status, webhookStatus, workspace]);
}

export interface Buyer {
  orgName?: string;
  orgId?: string;
}

export function useCreateBids(): MutationTuple<ICreateBidsResponse, ICreateBidsRequest> {
  return useMutation(CREATE_BIDS);
}
export function useUpdateBidsWs(): MutationTuple<IUpdateBidsWsResponse, IUpdateBidsWsRequest> {
  return useMutation(UPDATE_BIDS_WS);
}

export function useUpdateBidStatus(): MutationTuple<IUpdateBidStatusResponse, IUpdateBidsStatusRequest> {
  return useMutation(UPDATE_BID_STATUS);
}

export const useBidCreationContext = (): Interactions.BidCreationContext => {
  const { wsId, mpId, inboxId } = useParams<{ wsId: string; mpId: string; inboxId: string }>();
  const { pathname } = useLocation();
  const qp = useQueryParams();

  const profileId = useMemo(() => {
    return isString(qp.profileId) ? qp.profileId : undefined;
  }, [qp]);

  return useMemo(() => {
    switch (true) {
      case pathname.includes(Paths.MONITORING) && !!mpId:
        return { bidContext: Interactions.BidContext.MatchingProfile, bidContextId: mpId };

      case pathname.includes(Paths.ALL_TENDERS):
        return { bidContext: Interactions.BidContext.AllTenders, bidContextId: undefined };

      case pathname.includes(Paths.CALL_OFFS) && !!inboxId:
        return { bidContext: Interactions.BidContext.CallOff, bidContextId: inboxId };

      case pathname.includes(Paths.EXPIRING_CONTRACTS_ROUTE) && !!profileId:
        return { bidContext: Interactions.BidContext.ExpiringContracts_SavedSearch, bidContextId: profileId };

      case pathname.includes(Paths.EXPIRING_CONTRACTS_ROUTE) && !profileId:
        return { bidContext: Interactions.BidContext.ExpiringContracts, bidContextId: undefined };

      case pathname.includes(Paths.BIDSPACES) && !!wsId:
        return { bidContext: Interactions.BidContext.Manual, bidContextId: wsId };

      default:
        return { bidContext: undefined, bidContextId: undefined };
    }
  }, [inboxId, mpId, pathname, profileId, wsId]);
};

export function useMoveToWorkspace(): [(data: IMoveToWorkspaceInput) => Promise<void>, { loading: boolean }] {
  const [createBids, { loading: createBidLoading }] = useCreateBids();
  const [updateBidsWs, { loading: updateBidsWsLoading }] = useUpdateBidsWs();
  const [unassignMailsToBid, { loading: unassignLoading }] = useUnAssignMailsToBid();
  const { bidContext, bidContextId } = useBidCreationContext();
  const [{ rejected: isRejectedTab }] = useBidsSearchArgs();
  const { mpId: activeMpId } = useParams<{ mpId: string }>();
  const loading = createBidLoading || updateBidsWsLoading || unassignLoading;
  const { t } = useTranslation();

  const [defaultTemplateId] = useDefaultDecisionMatrixTemplate();

  const moveToWorkspaceFn = useCallback(
    async (data: IMoveToWorkspaceInput) => {
      const {
        workspaceId,
        eventSource,
        eventType,
        originType,
        originIds,
        bids,
        linkedBids,
        buyer,
        relevanceScores,
        matchData
      } = data;

      try {
        if (!!originType && originType === BidItemType.CallOff && linkedBids) {
          const groups = groupBy(linkedBids, 'id');
          const promises = Object.entries(groups).map(([key, val]) => {
            return unassignMailsToBid({
              variables: { bidId: key, mailIds: val.map(bid => bid.originId) }
            });
          });
          await Promise.all(promises);
        }
        if (!!originType && !!originIds && !!originIds.length) {
          await createBids({
            variables: {
              workspaceId: workspaceId,
              items: formatToCreateBidItems(originIds.map(originId => ({ originId, buyer, matchData }))),
              itemType: originType,
              meta: { bidContext, bidContextId },
              templateId: defaultTemplateId
            },
            update: updateCacheOnCreateBids(originIds, originType, workspaceId, activeMpId)
          });

          trackMoveItemsToWorkspace(
            originIds,
            originType,
            workspaceId,
            eventSource,
            eventType,
            activeMpId,
            relevanceScores
          );
        }
        if (!!bids && !!bids.length) {
          const bidsIds = bids.map(bid => bid.id);
          await updateBidsWs({
            variables: {
              workspaceId,
              bidsIds
            },
            update: updateCacheOnUpdateBidsWs(workspaceId, bids, !!isRejectedTab)
          });
        }
      } catch {
        notification.error({
          message: t('Common.unknownError'),
          description: t('Common.unknownErrorDesc')
        });
      }
    },
    [
      activeMpId,
      bidContext,
      bidContextId,
      createBids,
      defaultTemplateId,
      isRejectedTab,
      t,
      unassignMailsToBid,
      updateBidsWs
    ]
  );
  return [
    moveToWorkspaceFn,
    {
      loading
    }
  ];
}

export function useUpdateWsSorting(): [(sorting: IBidSorting) => void, { loading: boolean; error?: ApolloError }] {
  const [setRequestSettings, { loading, error }] = useSetRequestSettings();
  const { wsId } = useParams<{ wsId: string }>();

  const updateWsSortingFn = useCallback(
    (sorting: IBidSorting) => {
      wsId && setRequestSettings({ entityId: wsId, type: RequestType.BidsSorting, settings: JSON.stringify(sorting) });
    },
    [wsId, setRequestSettings]
  );
  return useMemo(() => [updateWsSortingFn, { loading, error }], [updateWsSortingFn, error, loading]);
}

export function useBidspaceBANSStatistics(): BidspaceStatisticsData {
  const vars = useBidspacesQpVars();
  const [{ rejected }] = useBidsSearchArgs();
  const { currency } = useCurrency();
  const query = useQuery<BidsCategoryStatisticsResponse, NewBidspaceRequest>(GET_BIDS_CATEGORY_STATISTICS, {
    variables: {
      ...transformQpVars(vars),
      assignedToIds: transformAssignedToQpVars(vars),
      textSearch: transformSearchQpVars(vars.search),
      rejected,
      responseCurrency: currency
    },
    nextFetchPolicy: 'cache-only'
  });

  return useMemo(
    () => ({
      ...query,
      ...getLoadingStatesForQuery(query),
      data: query.data?.getBidCategoryStatistics ? new AllBidspaceStatistics(query.data) : undefined
    }),
    [query]
  );
}

export function useAssignedToStatistics(): AssignedToStatisticsData {
  const vars = useBidspacesQpVars();
  const [{ rejected }] = useBidsSearchArgs();
  const query = useQuery<AssignedToStatisticsResponse, NewBidspaceRequest>(GET_ASSIGNED_TO_STATISTICS, {
    variables: { ...transformQpVars(vars), assignedToIds: transformAssignedToQpVars(vars), rejected },
    fetchPolicy: 'network-only'
  });

  return useMemo(
    () => ({
      ...query,
      ...getLoadingStatesForQuery(query),
      data: query.data?.getAssignedToStatistics ? new AllAssignedToBidspacesStatistics(query.data) : undefined
    }),
    [query]
  );
}

export function useBidspacesQpVars(): BidspaceQpVars {
  const qp = useQueryParams();
  const searchQueryParams = qp[BidspaceQueryParams.search];
  const bidspaceQueryParams = qp[BidspaceQueryParams.bidspaceId];
  const categoryQueryParams = qp[BidspaceQueryParams.category];
  const assignedToQueryParams = qp[BidspaceQueryParams.assignedTo];
  const bidTypeQueryParams = qp[BidspaceQueryParams.bidType];

  const queryParams = useMemo(
    () => ({
      search: searchQueryParams ? String(searchQueryParams) : undefined,
      bidspaceId: isStrings(bidspaceQueryParams) ? bidspaceQueryParams.sort() : undefined,
      category: isStrings(categoryQueryParams) ? categoryQueryParams.sort() : undefined,
      assignedTo: isStrings(assignedToQueryParams) ? assignedToQueryParams.sort() : undefined,
      bidType: isStrings(bidTypeQueryParams) ? bidTypeQueryParams.sort() : undefined
    }),
    [assignedToQueryParams, bidTypeQueryParams, bidspaceQueryParams, categoryQueryParams, searchQueryParams]
  );

  return useMemo(() => queryParams, [queryParams]);
}

export const useResetBidsPage = (): (() => void) => {
  const [searchArgs, updateSearchArgs] = useBidsSearchArgs();

  const resetPage = useCallback(() => {
    updateSearchArgs({ ...searchArgs, offset: 0 });
  }, [updateSearchArgs, searchArgs]);

  return resetPage;
};

export function useGetBidsCategoryStatistics(contractStartRanges?: boolean): {
  data?: BidsCategoryStatistics[];
  loading?: boolean;
} {
  const vars = useBidspacesQpVars();
  const [{ rejected }] = useBidsSearchArgs();
  const { currency } = useCurrency();

  const currentYear = dayjs().year();
  const currentMonth = dayjs().month();

  // Periods is from current month to 6 months ahead
  const periods: ContractStartRangesFilter[] = Array.from({ length: 6 }, (_, i) => {
    const start = new Date(currentYear, currentMonth + i, 1);
    const offsettedStartDate = formatDateWithTimezoneOffset(start);

    const end = new Date(currentYear, currentMonth + i + 1, 1);
    const offsettedEndDate = formatDateWithTimezoneOffset(end);

    return {
      start: offsettedStartDate,
      end: offsettedEndDate
    };
  });

  const query = useQuery<BidsCategoryStatisticsResponse, NewBidspaceRequest>(GET_BIDS_CATEGORY_STATISTICS, {
    variables: {
      ...transformQpVars(vars),
      assignedToIds: transformAssignedToQpVars(vars),
      textSearch: transformSearchQpVars(vars.search),
      rejected,
      contractStartRanges: !!contractStartRanges ? periods : undefined,
      responseCurrency: currency
    }
  });

  return useMemo(
    () => ({
      ...query,
      ...getLoadingStatesForQuery(query),
      data: query.data?.getBidCategoryStatistics
        ? query.data.getBidCategoryStatistics.statistics
            .filter(bidCategoryStatistic => bidCategoryStatistic.startDate) // Filter out statistic without startDate
            .map(bidCategoryStatistic => new BidsCategoryStatistic(bidCategoryStatistic))
        : undefined
    }),
    [query]
  );
}

export function useForecastBids(
  contractStartRange: ContractStartRangesFilter,
  params: {
    amount?: number;
    offset?: number;
    statusCategories: WorkspaceStatusCategory[];
  },
  skip?: boolean
): Omit<BidsResponseData, 'updateVars' | 'currentVars' | 'clearVars'> {
  const amount = params?.amount;
  const offset = params?.offset;
  const [{ amount: defaultAmount, ...restSearchArgs }] = useBidsSearchArgs();
  const vars = useBidspacesQpVars();
  const isWebHooksFeature = !!useFeatureFlag(FeatureFlag.Users_Webhooks);

  const query = useQuery<IApiGetBidsResponse, ISearchBidsRequest>(GET_MONTHLY_BIDS_PAGES, {
    variables: {
      amount: amount ?? defaultAmount,
      ...transformQpVars(vars),
      offset,
      textSearch: transformSearchQpVars(vars.search),
      assignedToIds: transformAssignedToQpVars(vars),
      ...restSearchArgs,
      sortOnField: {
        name: BidsColumn.deadline,
        order: SortOrder.ASC
      },
      isWebHooksFeature,
      contractStart: contractStartRange,
      statusCategories: params.statusCategories
    },
    skip: !!skip
  });

  return useMemo(
    () => ({
      ...query,
      ...getLoadingStatesForQuery(query),
      data: query.data ? new BidsData(query.data.getBids) : undefined
    }),
    [query]
  );
}
