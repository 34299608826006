import React from 'react';
import styles from './index.module.scss';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useBidPreview } from 'src/models/bids/BidPreview/hooks';
import TenderMatches from 'src/shared/Matches';
import { Interactions } from '@tendium/prom-types/schema';
import { useLoadMp } from 'src/models/matchingProfiles/hooks';
import { UserName } from 'src/common';
import { toHSLColor } from 'src/helpers/styles';
import ReadOnlyBlockDate from 'src/shared/Blocks/Date/ReadOnlyBlock';
import { BlockLabel } from 'src/shared';
import { BidItemType } from '@tendium/prom-types/tender';

export const BidPreviewMatchData: FC = () => {
  const { t } = useTranslation();
  const { data } = useBidPreview();

  const bidId = data?.id;
  const bidContextType = data?.bidContext;
  const bidContextId = data?.bidContextId;
  const skipMp = bidContextType !== Interactions.BidContext.MatchingProfile;
  const matchData = data?.matchData;

  const { data: mpData } = useLoadMp(bidContextId ?? '', skipMp);

  return bidId ? (
    <>
      <div className={styles.creationContext}>
        {<BlockLabel label={t('BidPreview.matchesHeader')} />}
        {<BlockLabel label={t('BidPreview.matchesCreationContextHeader')} />}
        {data?.createdBy && <UserName user={data?.createdBy} mode="onlyFullName" size="s" />}
        <div className={styles.mpContainer}>
          {mpData?.id && <span className={styles.itemBadge} style={{ backgroundColor: toHSLColor(mpData.id) }}></span>}
          <span className={styles.itemLabel}>{mpData?.name}</span>
        </div>
        <ReadOnlyBlockDate className={styles.createdAt} date={data.createdAt} />
      </div>

      {data.origin.originType === BidItemType.Procurement && (
        <div>
          <TenderMatches
            procurementId={data.origin.id}
            highlights={matchData?.matchedKeywords}
            matchedCpvs={matchData?.matchedCpvs}
          />
        </div>
      )}
    </>
  ) : (
    <span className={styles.desc}>{t('Tenders.matchesEmpty')}</span>
  );
};
