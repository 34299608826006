import React, { FC } from 'react';
import styles from './index.module.scss';
import Skeleton from './skeleton'; // import from comp lib

export const TaskSkeleton: FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.loaderContainer}>
        <Skeleton width="100%" height="22px" />
        <div></div>
        <Skeleton width="24px" height="24px" variant="circle" />
      </div>
    </div>
  );
};
