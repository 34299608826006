import { MutationUpdaterFn, Reference } from '@apollo/client';
import {
  ApplyDecisionMatrixTemplateResponse,
  CreateDecisionMatrixItemResponse,
  CreateDecisionMatrixTemplateResponse,
  DeleteDecisionMatrixItemResponse
} from './types';
import { gql } from '@apollo/client';

export function updateCacheCreateDecisionMatrixItem(
  matrixId: string
): MutationUpdaterFn<CreateDecisionMatrixItemResponse> {
  return (cache, { data }) => {
    if (!data) {
      return null;
    }
    const matrixRef = cache.identify({
      __typename: 'DecisionMatrix',
      id: matrixId
    });
    cache.modify({
      id: matrixRef,
      fields: {
        items(cached, { toReference }) {
          return [...cached, toReference({ __typename: 'DecisionMatrixItem', id: data.createDecisionMatrixItem.id })];
        }
      }
    });
  };
}

export function updateCacheOnDeleteDecisionMatrixItem(
  matrixId: string,
  itemId: string
): MutationUpdaterFn<DeleteDecisionMatrixItemResponse> {
  return (cache, { data }) => {
    if (!data) {
      return null;
    }
    const matrixRef = cache.identify({
      __typename: 'DecisionMatrix',
      id: matrixId
    });
    cache.modify({
      id: matrixRef,
      fields: {
        items(cachedItems: Reference[], { readField }) {
          return [...cachedItems].filter(item => readField('id', item) !== itemId);
        }
      }
    });
  };
}

export function updateCacheOnDeleteDecisionMatrixTemplate(
  templateId: string
): MutationUpdaterFn<DeleteDecisionMatrixItemResponse> {
  return (cache, { data }) => {
    if (!data) {
      return null;
    }

    cache.modify({
      fields: {
        getDecisionMatrixTemplates(cachedItems: Reference[], { readField }) {
          return [...cachedItems].filter(item => readField('id', item) !== templateId);
        }
      }
    });
  };
}

export function updateCacheOnApplyDecisionMatrix(
  bidId: string
): MutationUpdaterFn<ApplyDecisionMatrixTemplateResponse> {
  return (cache, { data }) => {
    if (!data || !data.applyDecisionMatrix) {
      return null;
    }

    const bidRef = cache.identify({
      __typename: 'BidV2',
      id: bidId
    });

    cache.modify({
      id: bidRef,
      fields: {
        decisionMatrix() {
          return cache.writeFragment({
            fragment: gql`
              fragment NewDecisionMatrix on DecisionMatrix {
                id
                __typename
              }
            `,
            data: {
              __typename: 'DecisionMatrix',
              id: data.applyDecisionMatrix.id
            }
          });
        }
      }
    });
  };
}

export function updateCacheCreateDecisionMatrixTemplate(): MutationUpdaterFn<CreateDecisionMatrixTemplateResponse> {
  return (cache, { data }) => {
    if (!data) {
      return null;
    }

    cache.modify({
      fields: {
        getDecisionMatrixTemplates(cachedItems: Reference[], { toReference }) {
          return [
            ...cachedItems,
            toReference({ __typename: 'DecisionMatrixTemplate', id: data.createDecisionMatrixTemplate.id })
          ];
        }
      }
    });
  };
}
