import { EditorProvider, isNodeEmpty, JSONContent } from '@tiptap/react';
import React, { FC, useState } from 'react';
import { useHandleEditorBidTaskUpdate } from 'src/models/canvas/hooks';
import styles from './index.module.scss';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { CANVAS_EXTENSIONS } from 'src/models/canvas/canvasExtensions';

interface Props {
  editorContent: JSONContent;
  groupId: string;
  taskId: string;
}

const TaskAnswerEditor: FC<Props> = ({ editorContent, groupId, taskId }) => {
  const { t } = useTranslation();
  const debouncedOnUpdate = useHandleEditorBidTaskUpdate(groupId, taskId);

  const [isInputFocus, setIsInputFocus] = useState<boolean>(false);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);

  return (
    <EditorProvider
      extensions={CANVAS_EXTENSIONS}
      content={editorContent}
      onUpdate={({ editor, transaction }) => {
        setIsEmpty(
          isNodeEmpty(editor.state.doc, {
            ignoreWhitespace: true
          })
        );
        debouncedOnUpdate({ editor, transaction });
      }}
      editorContainerProps={{
        className: styles.editorContainer
      }}
      editorProps={{
        attributes: {
          class: classNames(styles.editorContent, { [styles.isEmpty]: isEmpty && !isInputFocus }),
          'data-placeholder': t('Canvas.Misc.taskNodePlaceholder')
        }
      }}
      onFocus={() => setIsInputFocus(true)}
      onBlur={() => setIsInputFocus(false)}
    />
  );
};

export default TaskAnswerEditor;
