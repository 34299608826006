import React, { FC, useCallback, useMemo, useState } from 'react';
import { Tooltip, Button } from 'src/common';
import { faCloudDownload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDownloadFile, usePreviewFile } from 'src/models/procurements/Tender/hooks';
import DocumentViewer from 'src/shared/DocumentViewer';
import { splitFileExt } from 'src/helpers/files';
import { getIconByFileType } from 'src/helpers/fileTypeIcon';
import { getFileNameFromPath } from 'src/models/procurements/helpers';
import styles from './index.module.scss';
import { IHighlight } from 'react-pdf-highlighter';
import { useTermsDocumentCoordinates } from 'src/pages/TenderPage/DocumentSearch/hooks';
import { ITenderKeywordHighlight } from 'src/models/procurements/Preview/types';
import { keywordHighlightValueToTerms, toPdfHighlightsData } from 'src/shared/DocumentViewer/helpers';
import { trackViewDocumentPreview } from 'src/segment/events';
import { useIsMobile } from 'src/reactiveVars';
import classNames from 'classnames';

interface Props {
  procurementId: string;
  targetPath: string;
  eventSource: string;
  displayName?: string;
  pdfHighlights?: IHighlight[];
  keywordHighlight?: ITenderKeywordHighlight;
  flexAuto?: boolean;
  trackHandler?: () => void;
}

export const TenderFile: FC<Props> = ({
  procurementId: id,
  targetPath,
  displayName,
  keywordHighlight,
  pdfHighlights,
  eventSource,
  flexAuto,
  trackHandler
}) => {
  const { url, loading: previewLoading, previewFile, closePreview } = usePreviewFile(id);
  const { getFile, loading } = useDownloadFile(id);
  const [isMobile] = useIsMobile();

  const [getCoordinates, { loading: loadingCoords }] = useTermsDocumentCoordinates();
  const [highlights, setHighlights] = useState<IHighlight[]>(pdfHighlights ?? []);

  const fileName = useMemo(() => {
    return !displayName ? getFileNameFromPath(targetPath) : displayName;
  }, [displayName, targetPath]);

  const extIcon = useMemo(() => {
    const [, fileExt] = splitFileExt(targetPath);
    if (fileExt) {
      return getIconByFileType(fileExt);
    }
  }, [targetPath]);

  const onClick = useCallback(async () => {
    if (previewLoading) {
      return false;
    }
    if (keywordHighlight) {
      const searchTerms = keywordHighlightValueToTerms(keywordHighlight?.value);

      const coords = await getCoordinates({
        variables: {
          filePath: targetPath,
          procurementId: id,
          terms: searchTerms
        }
      });

      if (coords.data && coords.data.termsDocumentCoordinates) {
        const { headlinePosition, contentPosition } = coords.data?.termsDocumentCoordinates;
        const pdfHighlights = toPdfHighlightsData(headlinePosition, contentPosition);
        setHighlights(pdfHighlights);
      }
    }

    trackHandler ? trackHandler() : trackViewDocumentPreview(eventSource, [id]);
    previewFile(targetPath);
  }, [previewLoading, keywordHighlight, trackHandler, eventSource, id, previewFile, targetPath, getCoordinates]);

  const onDownloadIconClick = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.stopPropagation();
      !loading && getFile(targetPath);
    },
    [loading, getFile, targetPath]
  );

  return (
    <>
      <div
        className={classNames(styles.file, {
          [styles.flexAuto]: flexAuto
        })}
      >
        {extIcon && <span className={styles.fileIcon}>{extIcon}</span>}
        {isMobile ? (
          <Button
            className={classNames(styles.fileLink, {
              [styles.fileLinkMobile]: isMobile
            })}
            type={'link'}
            onClick={onClick}
          >
            <div>{fileName}</div>
          </Button>
        ) : (
          <>
            <div className={styles.fileName}>
              <Tooltip title={fileName}>
                <Button
                  className={classNames(styles.fileLink, {
                    [styles.fileLinkMobile]: isMobile
                  })}
                  type={'link'}
                  onClick={onClick}
                >
                  <div>{fileName}</div>
                </Button>
              </Tooltip>

              <Button
                type={'text'}
                className={styles.downloadIcon}
                onClick={onDownloadIconClick}
                icon={<FontAwesomeIcon icon={faCloudDownload} />}
              />
            </div>
          </>
        )}
      </div>
      {url && (
        <DocumentViewer
          loading={previewLoading || loadingCoords}
          url={url}
          title={fileName}
          highlights={highlights}
          onClose={closePreview}
        />
      )}
    </>
  );
};

export default TenderFile;
