import { toLatin1 } from '@tendium/prom-types';
import { FileExt } from 'src/models/file/types';

export const downloadFile = function (url: string, fileName: string): void {
  if (!url) return;
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}`);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const splitFileExt = function (filePath: string): string[] {
  const [filePathWithoutExt, fileExt] = filePath.split(/\.(?=[^.]+$)/);
  return [filePathWithoutExt, fileExt ? fileExt.toLowerCase() : ''];
};

const mimeToExtensionMap: { [key: string]: string } = {
  'application/pdf': 'pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
  'text/csv': 'csv',
  'application/xml': 'xml'
};

export const extensionToMimeMap: { [key: string]: string } = Object.entries(mimeToExtensionMap).reduce(
  (acc, [mime, ext]) => {
    acc[ext] = mime;
    return acc;
  },
  {} as { [key: string]: string }
);

export const getAcceptedMimes = (extensions: FileExt[]): string[] => {
  return extensions.map(ext => extensionToMimeMap[ext]).filter(Boolean);
};

export const getAcceptedExtensions = (extensions: FileExt[]): string => {
  return extensions.map(ext => `.${ext}`).join(',');
};

export const normalizeFileName = (fileName: string): string => {
  if (!fileName) return '';
  return toLatin1(fileName.normalize());
};
