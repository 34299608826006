import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Modal, Form, Input, Button, useMe, UserName, Select, notification } from 'src/common';
import { useTranslation } from 'react-i18next';
import { useForm } from 'antd/lib/form/Form';
import { useActiveUsers } from 'src/models/users/hooks';
import AssignTo from 'src/shared/AssignTo';
import styles from './index.module.scss';
import dayjs from 'dayjs';
import { AssignedTo } from 'src/models/users/types';
import {
  useContentLibraryNav,
  useCreateContentLibraryContent,
  useUpdateContentLibrary
} from 'src/models/contentLibrary/ContentLibrary/hooks';
import { useRoomsWithAccess } from 'src/models/contentLibrary/ContentLibraryRoom/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCircle } from '@fortawesome/pro-solid-svg-icons';
import ContentLibraryTag from '../ContentLibraryTags/ContentLibraryTagsArea/ContentLibraryTag';
import { useLoadContentLibraryTags } from 'src/models/contentLibrary/ContentLibraryTag/hooks';
import { Content } from 'src/models/contentLibrary/ContentLibrary';
import { toHSLColor } from 'src/helpers/styles';
import { getUsersWithAccessToRooms } from 'src/models/contentLibrary/ContentLibrary/types';
import { ContentLibraryType } from '@tendium/prom-types/schema';
import { fileToExtIcon } from 'src/helpers/fileExtToIcon';

const { TextArea } = Input;

interface FormState {
  question: string;
  answer: string;
  assignedTo: string;
  roomId: string;
  tagIds: string[];
}

interface Props {
  onClose: () => void;
  content?: Content;
  question?: string;
  answer?: string;
}

export const ContentModal: FC<Props> = props => {
  const { onClose, content, answer, question } = props;
  const { t } = useTranslation();

  const [form] = useForm<FormState>();
  const { data: me } = useMe();
  const roomData = useRoomsWithAccess();
  const [selectedRoom, setSelectedRoom] = useState(content?.room);

  const users = useActiveUsers();

  const usersWithAccess = useMemo(() => {
    return selectedRoom ? getUsersWithAccessToRooms(users, [selectedRoom]) : [];
  }, [selectedRoom, users]);

  const currentUser = useMemo(() => users.find(user => user.email === me?.email), [me, users]);

  const [createContent] = useCreateContentLibraryContent();
  const [updateContent] = useUpdateContentLibrary();
  const { isCanvasPage } = useContentLibraryNav();

  const values = useMemo(() => {
    return roomData
      ? [
          ...(roomData?.map(room => {
            return {
              label: (
                <span>
                  <FontAwesomeIcon className={styles.labelIcon} icon={faCircle} color={toHSLColor(room.id)} />
                  {` ${room.title}`}
                </span>
              ),
              value: room.id,
              title: room.title
            };
          }) ?? [])
        ]
      : [];
  }, [roomData]);

  const [isDisabled, setIsDisabled] = useState(true);
  const onSave = useCallback(async () => {
    form.submit();
    if (isDisabled && !content) return;
    const values = form.getFieldsValue();

    if (content) {
      await updateContent({ id: content.id, ...values }, content.type);
    } else {
      await createContent({ ...values });
    }
    onClose();
    isCanvasPage &&
      notification.success({
        description: t('ContentLibrary.contentModal.cardSavedToLibrary'),
        message: t('')
      });
  }, [content, createContent, form, isCanvasPage, isDisabled, onClose, t, updateContent]);

  const onValuesChange = useCallback(
    (changedValues: Partial<FormState>, values: FormState) => {
      setIsDisabled(!values.question || !values.answer || !values.roomId);
    },
    [setIsDisabled]
  );

  const [assignedTo, setAssignedTo] = useState<AssignedTo | undefined>();
  const onAssign = useCallback(
    ({ id }: AssignedTo) => {
      form.setFieldsValue({ assignedTo: id });
      const user = usersWithAccess.find(user => user.id === id);
      if (user) setAssignedTo(user);
    },
    [form, usersWithAccess]
  );

  const onUnAssign = useCallback(() => {
    form.setFieldsValue({ assignedTo: undefined });
    setAssignedTo(undefined);
  }, [form]);

  const { data: tagsData } = useLoadContentLibraryTags();

  const tags = useMemo(() => {
    return tagsData
      ? tagsData?.map(tag => {
          return {
            label: <ContentLibraryTag tag={tag} key={tag.id} disabled={true} />,
            value: tag.id,
            title: tag.title
          };
        })
      : [];
  }, [tagsData]);

  useEffect(() => {
    if (content) {
      form.setFieldsValue({
        question: content.question,
        answer: content.answer,
        roomId: content.room.id,
        tagIds: content.tags.map(tag => tag.id),
        assignedTo: content.assignedTo?.email
      });
      const user = usersWithAccess.find(user => user.id === content.assignedTo?.id);
      if (user) setAssignedTo(user);
    }

    if (question || answer) {
      form.setFieldsValue({
        question: question,
        answer: answer
      });
    }
  }, [answer, content, form, question, usersWithAccess]);

  const onSelectedRoom = useCallback(
    (id: string) => {
      const room = roomData?.find(room => room.id === id);
      if (room) setSelectedRoom(room);
    },
    [roomData]
  );

  useEffect(() => {
    if (!usersWithAccess.find(user => user.id === form.getFieldsValue().assignedTo)) {
      onUnAssign();
    }
  }, [form, onUnAssign, usersWithAccess]);

  const fileIcon = fileToExtIcon(content?.document?.fileName);

  return (
    <Modal
      title={
        <span>
          {content
            ? content.type === ContentLibraryType.QA
              ? t('ContentLibrary.contentModal.editTitle')
              : t('ContentLibrary.contentModal.editTitleFile')
            : t('ContentLibrary.contentModal.addTitle')}
        </span>
      }
      visible
      maskClosable={false}
      onCancel={onClose}
      width={1240}
      footer={
        <>
          <Button type={'text'} onClick={onClose}>
            {t('Common.cancel')}
          </Button>

          <Button type={'primary'} onClick={onSave}>
            {t('ContentLibrary.contentModal.saveButton')}
          </Button>
        </>
      }
    >
      <Form form={form} layout={'vertical'} onValuesChange={onValuesChange} className={styles.form}>
        <div className={styles.mainForm}>
          <Form.Item
            name={'question'}
            label={content?.type === 'QA' ? t('ContentLibrary.contentModal.labelQuestion') : t('Common.title')}
            required
            rules={[{ required: true, message: t('ContentLibrary.contentModal.ruleQuestion') }]}
          >
            <TextArea autoSize={{ minRows: 2 }} placeholder={t('ContentLibrary.contentModal.placeholderQuestion')} />
          </Form.Item>
          {content?.type === ContentLibraryType.QA || content === undefined ? (
            <Form.Item
              name={'answer'}
              label={t('ContentLibrary.contentModal.labelAnswer')}
              required
              rules={[{ required: true, message: t('ContentLibrary.contentModal.ruleAnswer') }]}
            >
              <TextArea autoSize={{ minRows: 14 }} placeholder={t('ContentLibrary.contentModal.placeholderAnswer')} />
            </Form.Item>
          ) : (
            <>
              <p>{t('Common.file')}</p>
              <FontAwesomeIcon className={styles.fileIcon} icon={fileIcon} />
            </>
          )}
        </div>
        <div className={styles.sideForm}>
          <Form.Item
            name={'roomId'}
            label={t('ContentLibrary.contentModal.labelRoom')}
            required
            rules={[{ required: true, message: t('ContentLibrary.contentModal.ruleRoom') }]}
            className={styles.roomInput}
          >
            <Select
              showSearch
              optionFilterProp={'value'}
              getPopupContainer={trigger => trigger.parentNode}
              listHeight={125}
              filterOption={(input, option) =>
                !!option && !!option.title && option.title.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder={t('ContentLibrary.contentModal.placeholderRoom')}
              allowClear
              suffixIcon={<FontAwesomeIcon className={styles.selectIcon} icon={faCaretDown} />}
              onSelect={e => (e ? onSelectedRoom(e.toString()) : null)}
            >
              {values.map(c => (
                <Select.Option key={c.value} value={c.value} title={c.title}>
                  {c.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name={'tagIds'} label={t('ContentLibrary.contentModal.labelTags')}>
            <Select
              mode={'multiple'}
              className={styles.multiSelect}
              placeholder={t('ContentLibrary.contentModal.placeholderTags')}
              filterOption={(input, option) =>
                !!option && !!option.title && option.title.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {tags.map(({ label, value, title }) => (
                <Select.Option value={value} key={value} className={styles.tag} title={title}>
                  {label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name={'assignedTo'}>
            <span className={styles.label}>{t('ContentLibrary.contentModal.labelAssigned')}</span>
            <AssignTo
              onAssign={onAssign}
              onUnAssign={onUnAssign}
              isUpdating={false}
              size={'m'}
              mode={'short'}
              users={usersWithAccess}
              assignedTo={assignedTo?.id}
            />
          </Form.Item>
          <div className={styles.userInfo}>
            <div className={styles.label}>{t('ContentLibrary.createdBy')}</div>
            <div className={styles.user}>
              <div>{currentUser && <UserName user={content?.createdBy ?? currentUser} />}</div>
              <div className={styles.muted}>{dayjs(new Date()).format('YYYY-MM-DD')}</div>
            </div>
          </div>
          <div className={styles.userInfo}>
            <div className={styles.label}>{t('ContentLibrary.editedBy')}</div>
            <div className={styles.user}>
              <div>{currentUser && <UserName user={content?.modifiedBy ?? currentUser} />}</div>
              <div className={styles.muted}>{dayjs(new Date()).format('YYYY-MM-DD')}</div>
            </div>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default ContentModal;
