import React, { FC, useCallback, useState } from 'react';
import EditableBlockTextSimple from './EditableBlockSimple';
import ReadOnlyBlockText from './ReadOnlyBlock';
import styles from './index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-light-svg-icons';
import { useKey } from 'react-use';
import { IBoxFieldString } from 'src/models/procurements/Tender/types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import AddValueButton from '../AddValueButton';
import { IBlockProps, FieldViewMode } from 'src/shared/Tender/Boxes/types';
import { EditableBlockText } from './EditableBlock';

export interface Props extends IBlockProps<IBoxFieldString> {
  isSimple: boolean;
  renderReadOnly?: (children: React.ReactNode, field: IBoxFieldString) => React.ReactNode;
}
export const BlockText: FC<Props> = ({
  field,
  onChange,
  translation,
  viewMode,
  isEditable,
  isSimple,
  config,
  renderReadOnly
}) => {
  const { t } = useTranslation();

  const [currentMode, setCurrentMode] = useState(viewMode || FieldViewMode.Read);

  const onToggleView = useCallback(() => {
    isEditable && setCurrentMode(prev => (prev === FieldViewMode.Read ? FieldViewMode.Edit : FieldViewMode.Read));
  }, [isEditable]);

  const switchToReadMode = useCallback(() => {
    if (isEditable && currentMode === FieldViewMode.Edit) {
      setCurrentMode(FieldViewMode.Read);
    }
  }, [currentMode, isEditable]);

  const onFinish = useCallback(
    (val: IBoxFieldString) => {
      onChange?.(val);
      switchToReadMode();
    },
    [onChange, switchToReadMode]
  );

  useKey('Escape', () => setCurrentMode(FieldViewMode.Read));

  return (
    <>
      {currentMode === FieldViewMode.Edit &&
        (isSimple ? (
          <EditableBlockTextSimple onChange={onFinish} onFinish={switchToReadMode} field={field} />
        ) : (
          <EditableBlockText onChange={onFinish} onFinish={switchToReadMode} field={field} />
        ))}

      {currentMode === FieldViewMode.Read && (
        <>
          {!!field.string ? (
            <div className={classNames(styles.toggler, { [styles.isEditable]: isEditable })} onClick={onToggleView}>
              {renderReadOnly ? (
                renderReadOnly(
                  <ReadOnlyBlockText
                    string={field.string}
                    translation={translation}
                    className={styles.block}
                    config={config}
                  />,
                  field
                )
              ) : (
                <ReadOnlyBlockText
                  string={field.string}
                  translation={translation}
                  className={styles.block}
                  config={config}
                />
              )}
              {isEditable && (
                <div className={styles.togglerIcon}>
                  <FontAwesomeIcon icon={faPen} />
                </div>
              )}
            </div>
          ) : (
            <AddValueButton onToggleView={onToggleView} isEditable={!!isEditable} title={t('Common.Blocks.addText')} />
          )}
        </>
      )}
    </>
  );
};

export default BlockText;
