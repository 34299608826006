import React, { FC } from 'react';
import styles from './index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { ProcurementStatus } from 'src/models/procurements/types';
import { BadgeStatus, IStatusData, ITenderMultipleStatusBadgeProps, ITenderSingleStatusBadgeProps } from './types';
import { faAward, faInfo } from '@fortawesome/pro-light-svg-icons';
import { faBan } from '@fortawesome/pro-regular-svg-icons';

const getStatusData = (props: ITenderSingleStatusBadgeProps, t: TFunction): IStatusData | null => {
  switch (props.status) {
    case ProcurementStatus.Award:
      return {
        icon: faAward,
        title: t('Tenders.awarded'),
        className: styles.awarded
      };
    case ProcurementStatus.TenderCancellation:
      return {
        icon: faBan,
        title: t('Tenders.cancelled'),
        className: styles.cancelled
      };
    case 'PIN':
      return {
        icon: faInfo,
        title: t('Tenders.PIN'),
        className: styles.PIN
      };
    default:
      return null;
  }
};

const getStatusComp = (props: ITenderSingleStatusBadgeProps, status: IStatusData): React.ReactElement => {
  const { mode, className } = props;
  if (mode === 'text') {
    return <span className={classNames(styles.badgeText, status.className, className)}>({status.title})</span>;
  }
  if (mode === 'icon') {
    return (
      <span className={classNames(styles.badge, styles.badgeIcon, status.className, className)}>
        <FontAwesomeIcon icon={status.icon} />
      </span>
    );
  }
  return (
    <span className={classNames(styles.badge, status.className, className)}>
      <FontAwesomeIcon icon={status.icon} />
      <span className={styles.title}>{status.title}</span>
    </span>
  );
};

export function hasBadgeStatus(statuses?: ProcurementStatus[], isPIN?: boolean): boolean {
  if (isPIN) return true;
  return !!statuses?.some(
    status => status === ProcurementStatus.Award || status === ProcurementStatus.TenderCancellation
  );
}

export const SingleTenderStatusBadge: FC<ITenderSingleStatusBadgeProps> = props => {
  const { t } = useTranslation();
  const status = getStatusData(props, t);
  if (!status) {
    return null;
  }
  const Comp = getStatusComp(props, status);
  if (props.wrapper) {
    const Wrapper = props.wrapper;
    return <Wrapper>{Comp}</Wrapper>;
  }
  return Comp;
};

export const TenderStatusBadge: FC<ITenderMultipleStatusBadgeProps> = ({
  statuses = [],
  isPIN = false,
  ...restProps
}) => {
  if (!statuses.length && !isPIN) {
    return null;
  }
  const badgeStatuses: BadgeStatus[] = isPIN ? [...statuses, 'PIN'] : statuses;
  return (
    <>
      {badgeStatuses.map(status => {
        return <SingleTenderStatusBadge {...restProps} status={status} key={status} />;
      })}
    </>
  );
};

export default TenderStatusBadge;
