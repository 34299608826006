import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useBidding } from 'src/models/bids/BidFull/hooks';
import { Skeleton } from 'src/common';
import { KPI } from 'src/common/KPI';
import { KPIs } from 'src/common/KPIs';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';

export const TasksStatistic: FC = () => {
  const { t } = useTranslation();
  const { data, statsLoading } = useBidding();
  const stats = data?.statistic?.stats ?? [];
  const total = data?.statistic?.total ?? 0;

  if (statsLoading) return <Skeleton active paragraph={{ rows: 1 }} />;
  if (!data?.statistic) return null;

  return (
    <KPIs>
      {stats.map(([statName, statValue]) => (
        <KPI
          key={statName}
          name={t(`BiddingTool.States.${statName}`)}
          icon={<FontAwesomeIcon icon={faCircle} style={{ color: statValue.icon.color }} size="xs" />}
          count={statValue.count}
          additional={
            statName !== 'totalTasks' ? `${total > 0 ? Math.round((100 * statValue.count) / total) : 0}%` : undefined
          }
        />
      ))}
    </KPIs>
  );
};

export default TasksStatistic;
