import { z } from 'zod';
import {
  isTenderVarsDiff,
  tendersSearchSchema,
  TendersSearchSchemaKeyType,
  TendersSearchSchemaType
} from '../tendersSearchSchema';
import { ContractEndRangeMode } from '@tendium/prom-types';

export const contractEndFilterSchema = tendersSearchSchema.pick({ contractEnd: true });
export type ContractEndFilterSchemaType = z.infer<typeof contractEndFilterSchema>;

export const contractEndFilterKeys = Object.keys(contractEndFilterSchema.shape) as TendersSearchSchemaKeyType[];

export function isContractEndFilterNoValue(vars?: TendersSearchSchemaType): boolean {
  if (vars?.contractEnd?.rangeMode === ContractEndRangeMode.ABSOLUTE) {
    return vars?.contractEnd?.start === undefined && vars?.contractEnd?.end === undefined;
  }
  if (vars?.contractEnd?.rangeMode === ContractEndRangeMode.RELATIVE) {
    return vars?.contractEnd?.relativeStart === undefined && vars?.contractEnd?.relativeEnd === undefined;
  }
  return false;
}

export function isContractEndFilterDiff(
  vars?: TendersSearchSchemaType,
  compareToVars?: TendersSearchSchemaType,
  options?: { skipNoValue?: boolean }
): boolean {
  if (options?.skipNoValue && isContractEndFilterNoValue(vars)) {
    return false;
  }
  return isTenderVarsDiff(contractEndFilterKeys, vars, compareToVars);
}
