import React, { FC, useState } from 'react';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { useBidPreview } from 'src/models/bids/BidPreview/hooks';
import { FeatureFlag, useFeatureFlag } from 'src/helpers/featureFlag';
import LinkedSource from 'src/shared/LinkedSource';
import BidPreviewTenderBoxes from '../TenderBoxes';
import CustomBoxes from '../CustomBoxes';
import { DecisionMatrix } from 'src/shared/DecisionMatrix';
import { Button } from 'src/common';
import ManageTemplatesModal from 'src/shared/DecisionMatrix/ManageTemplatesModal';
import { BlockTitle } from 'src/shared';
import { faGavel, faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ShortDescriptionBox } from 'src/shared/ShortDescriptionBox';
import BidPreviewCreation from 'src/shared/BidPreviewCreation';

export const BidPreviewInfo: FC = () => {
  const { t } = useTranslation();
  const { data } = useBidPreview();

  const bidId = data?.id;

  const isCustomBidFieldsFeature = useFeatureFlag(FeatureFlag.CustomBidFields);
  const isDecisionMatrixFeature = useFeatureFlag(FeatureFlag.Bidding_DecisionMatrix);

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  if (!data) return null;

  return bidId ? (
    <>
      <LinkedSource />
      <BidPreviewCreation />
      <ShortDescriptionBox
        boxes={data.origin.description}
        originType={data.origin.originType}
        className={styles.shortDescription}
      />

      {isDecisionMatrixFeature && (
        <>
          {data?.decisionMatrix ? (
            <DecisionMatrix />
          ) : (
            <div className={styles.matrixEmptyState}>
              <BlockTitle
                title={t('DecisionMatrix.decisionMatrix')}
                icon={{ defaultIcon: faGavel, color: 'var(--ui-sunset)' }}
                className={styles.blockTitle}
                noBorder
              />
              <span>
                <Button
                  onClick={() => setIsModalVisible(true)}
                  type={'default'}
                  icon={<FontAwesomeIcon icon={faPlus} />}
                >
                  {t('BiddingTool.applyDecisionMatrix')}
                </Button>
              </span>
            </div>
          )}
        </>
      )}

      <div className={styles.boxes}>
        <BidPreviewTenderBoxes />
        {isCustomBidFieldsFeature && <CustomBoxes />}
      </div>
      {isModalVisible && <ManageTemplatesModal onClose={() => setIsModalVisible(false)} />}
    </>
  ) : (
    <p className={styles.block}>{t('BidSpaces.noBidNote')}</p>
  );
};

export default BidPreviewInfo;
