import React, { ReactNode, FC, useMemo, useEffect } from 'react';
import { BidPreviewContext } from './context';
import { useBidPreviewData } from './hooks';
import { BidPreviewData } from '.';
import useCurrentTenderId from 'src/reactiveVars/CurrentTenderIdVar';
import { useGetTasksGenerationStatus } from '../BidFull/hooks';

interface Props {
  children: ReactNode;
  id: string;
}

export const BidPreviewProvider: FC<Props> = ({ children, id }) => {
  const { data: rawData, loading } = useBidPreviewData(id);
  const [, setTenderId] = useCurrentTenderId();
  const { data: tasksGenerationStatus } = useGetTasksGenerationStatus(id);

  useEffect(() => {
    setTenderId(rawData?.getBid.itemId);
  }, [rawData, setTenderId]);

  const data = useMemo(
    () =>
      rawData
        ? new BidPreviewData({ ...rawData.getBid }, tasksGenerationStatus?.getTasksGenerationStatus.status)
        : null,
    [rawData, tasksGenerationStatus?.getTasksGenerationStatus.status]
  );
  const contextValue = useMemo(() => ({ data, loading }), [loading, data]);

  return <BidPreviewContext.Provider value={contextValue}>{children}</BidPreviewContext.Provider>;
};
