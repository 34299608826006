import { z } from 'zod';
import {
  AwardStatus,
  BuyerType,
  ContractEndRangeMode,
  ContractEndSearchType,
  FilterLogic,
  HighlightColor,
  JunctionLogic,
  KeywordWidth,
  Language,
  SearchLogic,
  SearchOnlyStatus,
  SearchType
} from '@tendium/prom-types';
import {
  booleanLike,
  dateLike,
  epochDateRangeLike,
  oNonNegativeIntLike,
  oNonNegativeNumberLike,
  oNumberLike,
  stringLike,
  zodParseErrorHandler
} from 'src/helpers/zod';
import { OcdsPartyRole } from '../../TenderFree/types';
import { TendersSortName, TendersSortOrder } from '../../Tenders/types';
import { objectDeepClean } from 'src/helpers/objectFilter';
import isEqual from 'lodash/isEqual';

export const partyFilterSchema = z.object({
  orgId: stringLike,
  orgName: stringLike.optional(),
  role: z.nativeEnum(OcdsPartyRole)
});

export const contractEndSchema = z.object({
  searchMode: z.nativeEnum(ContractEndSearchType).optional(),
  rangeMode: z.nativeEnum(ContractEndRangeMode).optional(),
  relativeStart: oNonNegativeIntLike,
  relativeEnd: oNonNegativeIntLike,
  start: dateLike.optional(),
  end: dateLike.optional(),
  strictPeriodOverlap: booleanLike.optional(),
  contractDurationYearsFallback: oNonNegativeIntLike
});

export const contractValueRangeSchema = z.object({
  currency: stringLike.optional(),
  max: oNonNegativeNumberLike,
  min: oNonNegativeNumberLike
});

export const sortSchema = z.object({
  key: z.nativeEnum(TendersSortName), // Cannot use SortKey in prom-types due to key-value not the same
  sort: z.nativeEnum(TendersSortOrder)
});

export const supplierOrgIdSchema = z.object({
  orgId: stringLike,
  status: z.nativeEnum(AwardStatus)
});

export const supplierSearchStringSchema = z.object({
  orgName: stringLike,
  status: z.nativeEnum(AwardStatus)
});

export const keywordSchema = z.object({
  filterLogic: z.nativeEnum(FilterLogic).optional(),
  highlightColor: z.nativeEnum(HighlightColor).optional(),
  searchLogic: z.nativeEnum(SearchLogic).optional(),
  searchType: z.nativeEnum(SearchType).optional()
});

export const keywordArgSchema = keywordSchema.extend({
  value: stringLike
});

export const keywordGroupSchema = keywordSchema.extend({
  groupId: stringLike.optional(),
  values: z.array(stringLike)
});

export const tendersSearchSchema = z.object({
  // pagination
  amount: oNumberLike,
  offset: oNumberLike,
  // sort filter
  sort: sortSchema.optional(),
  // only used for starred tenders page
  isStarred: booleanLike.optional(),
  // only unhandled filter
  onlyUnhandled: booleanLike.optional(),
  // profile filter
  profileFilter: stringLike.optional(),
  // search filter
  search: stringLike.optional(),
  // assigned filter
  assignedTo: z.array(stringLike).optional(),
  // buyers filter
  buyerOrgIds: z.array(stringLike).optional(),
  buyerSearchStrings: z.array(stringLike).optional(),
  procuringAgencyTypes: z.array(z.nativeEnum(BuyerType)).optional(),
  // suppliers filter
  supplierOrgIds: z.array(supplierOrgIdSchema).optional(),
  supplierSearchLogic: z.nativeEnum(JunctionLogic).optional(),
  supplierSearchStrings: z.array(supplierSearchStringSchema).optional(),
  // contract end filter
  contractEnd: contractEndSchema.optional(),
  // contract value filter
  contractValueRange: contractValueRangeSchema.optional(),
  contractValueExcludeMissingValue: booleanLike.optional(),
  // cpv codes filter
  cpvCodes: z.array(stringLike).optional(),
  cpvCodesExact: z.array(stringLike).optional(),
  // nuts codes filter
  nutsCodes: z.array(stringLike).optional(),
  nutsCodesExact: z.array(stringLike).optional(),
  // deadline filter
  deadline: epochDateRangeLike.optional(),
  // publish date filter
  published: epochDateRangeLike.optional(),
  // tender status filter
  awarded: booleanLike.optional(),
  onlyStatus: z.nativeEnum(SearchOnlyStatus).optional(),
  // keyword filter
  filterKeywordLanguages: z.array(z.nativeEnum(Language)).optional(),
  highlightKeywordLanguages: z.array(z.nativeEnum(Language)).optional(),
  keywordArgs: z.array(keywordArgSchema).optional(),
  keywordGroups: z.array(keywordGroupSchema).optional(),
  keywordWidth: z.nativeEnum(KeywordWidth).optional(),
  // only used for analytics features
  partyFilter: partyFilterSchema.optional(),
  // only used for analytics features
  isPlanningProcess: booleanLike.optional(),
  // profile id for saved search and mp tenders page
  profileId: stringLike.optional()
});

export type TendersSearchSchemaType = z.infer<typeof tendersSearchSchema>;
export type TendersSearchSchemaKeyType = keyof TendersSearchSchemaType;

export function isTenderVarsDiff(
  keys: TendersSearchSchemaKeyType[],
  var1?: TendersSearchSchemaType,
  var2?: TendersSearchSchemaType
): boolean {
  let isDiff = false;
  const cleanVar1 = objectDeepClean(var1);
  const cleanVar2 = objectDeepClean(var2);

  for (const key of keys) {
    if (!isEqual(cleanVar1?.[key], cleanVar2?.[key])) {
      isDiff = true;
      break;
    }
  }
  return isDiff;
}

export function tendersSearchSchemaParse(values: TendersSearchSchemaType): TendersSearchSchemaType {
  const parsed = tendersSearchSchema.safeParse(values);
  if (parsed.success) {
    return parsed.data;
  }
  zodParseErrorHandler('getTenderVars', parsed.error);
  return values;
}
