export enum StorageKey {
  // InitRoute: 'startRoute',
  FromLogin = 'return_to',
  Version = 'x-client-version',
  AwsError = 'aws_error',
  AnonUserId = 'prom_anonymousUserId',
  DefaultSearchProfileId = 'defaultSearchProfileId',
  PrimaryBackLink = 'primary_back_link',
  SecondaryBackLink = 'secondary_back_link'
}
