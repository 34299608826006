import React, { FC, useCallback, useMemo } from 'react';
import styles from './index.module.scss';
import { useContentLibrary, useDeleteContentLibrary } from 'src/models/contentLibrary/ContentLibrary/hooks';
import { Button, Checkbox, confirm } from 'src/common';
import { useTranslation } from 'react-i18next';
import AssignToContentLibrary from 'src/shared/ContentLibrary/AssignToContentLibrary';
import modalStyles from 'src/common/Modal/index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import ContentLibraryBulkRoomUpdater from '../ContentLibraryBulkRoomUpdater';
import { getUsersWithAccessToRooms } from 'src/models/contentLibrary/ContentLibrary/types';
import { useActiveUsers } from 'src/models/users/hooks';
import { isNotUndefined } from 'src/helpers';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

export const ContentLibraryBulkActions: FC = () => {
  const { t } = useTranslation();
  const users = useActiveUsers();
  const [deleteContentLibraryContent] = useDeleteContentLibrary();
  const { data: state, selectedPairIds, setSelectedPairIds } = useContentLibrary();

  const content = useMemo(() => {
    return state?.data ? state.data.content : [];
  }, [state?.data]);

  const isAllSelected = useMemo(() => {
    return selectedPairIds.length === content.length;
  }, [content, selectedPairIds]);

  const onSelectAllPairs = useCallback(
    (e: CheckboxChangeEvent) => {
      const isSelected = e.target.checked;
      if (isSelected) {
        const pairIds = content.map(c => c.id);

        setSelectedPairIds(pairIds);
      } else {
        setSelectedPairIds([]);
      }
    },
    [content, setSelectedPairIds]
  );

  const onRemoveRows = useCallback(() => {
    confirm.confirm({
      title: t('ContentLibrary.deleteCards'),
      okText: t('Common.delete'),
      content: t('ContentLibrary.deleteCardsWarning'),
      cancelText: t('Common.cancel'),
      className: modalStyles.confirmModal,
      centered: true,
      onOk() {
        deleteContentLibraryContent({ ids: selectedPairIds });
        setSelectedPairIds([]);
      }
    });
  }, [deleteContentLibraryContent, selectedPairIds, setSelectedPairIds, t]);

  const usersWithAccess = useMemo(() => {
    const selectedRooms = selectedPairIds.map(id => content.find(c => c.id === id)?.room).filter(isNotUndefined);
    return selectedRooms ? getUsersWithAccessToRooms(users, selectedRooms) : [];
  }, [content, selectedPairIds, users]);

  const handleUpdateComplete = useCallback(() => {
    setSelectedPairIds([]);
  }, [setSelectedPairIds]);

  return (
    <>
      <Checkbox
        indeterminate={!!selectedPairIds.length && !isAllSelected}
        onChange={onSelectAllPairs}
        checked={isAllSelected}
        className={styles.checkbox}
      />
      <span className={styles.toolsLabel}>{`${selectedPairIds.length} ${
        selectedPairIds.length === 1
          ? t('BiddingTool.BulkActions.selectedSingular')
          : t('BiddingTool.BulkActions.selectedPlural')
      }`}</span>
      <div className={styles.fulfillment}>
        <ContentLibraryBulkRoomUpdater ids={selectedPairIds} onUpdateComplete={handleUpdateComplete} />
      </div>
      <AssignToContentLibrary
        ids={selectedPairIds}
        usersWithAccess={usersWithAccess}
        renderMode={'button'}
        onUpdateComplete={handleUpdateComplete}
      />
      <Button
        type={'text'}
        icon={<FontAwesomeIcon icon={faTrash} />}
        onClick={() => onRemoveRows()}
        className={styles.button}
        danger
      >
        {t('Common.delete')}
      </Button>
    </>
  );
};

export default ContentLibraryBulkActions;
