import {
  BidTasksGroup,
  IBiddingResponse,
  TasksFilterArgs,
  ApiTasksVars,
  ApiTasksResponse,
  GROUP_TASKS_LIMIT,
  TasksResponseData,
  BiddingStatsResponse,
  BiddingStatsCached,
  UseLoadTasks,
  TasksCachedResult,
  DragItem,
  GetTasksGenerationStatusResponse,
  TasksGenerationJobStatus,
  PrivateFileInput
} from './types';
import {
  MutationUpdaterFn,
  useMutation,
  useQuery,
  ApolloError,
  Reference,
  QueryResult,
  useApolloClient,
  useSubscription,
  OperationVariables,
  LazyQueryResultTuple,
  useLazyQuery
} from '@apollo/client';
import {
  GET_BIDDING,
  GENERATE_TASKS,
  CREATE_BID_GROUP,
  UPDATE_BID_GROUP,
  DELETE_BID_GROUP,
  GET_GROUP_TASKS,
  GET_BIDDING_STATS,
  GET_TASKS_GENERATION_STATUS,
  START_TASKS_GENERATION_JOB
} from './queries';
import { useMemo, useContext, useCallback } from 'react';
import {
  BiddingContext,
  BiddingContextValue,
  GroupTasksContext,
  GroupTasksFilterContext,
  TasksState,
  UpdateGroupTasksFilterContext
} from './context';
import { useTranslation } from 'react-i18next';
import { notification } from 'src/common';
import { TasksData } from '.';
import { scrollToItem } from 'src/helpers/scrollToElement';
import { useCreateBidTasks } from '../BidTask/hooks';
import { CreateBidTaskValues } from '../BidTask/types';
import { TaskTrackCreateData, trackBTDeleteGroup, trackTaskCreate } from 'src/segment/events';
import { isTasksFilterApplied } from './helpers';
import { FeatureFlag, isNotUndefined, isString, updateQueryParams, useFeatureFlag } from 'src/helpers';
import { useLocation, useParams } from 'react-router';
import { usePagePreferences } from 'src/models/users/Preferences/hooks';
import { getLoadingStatesForQuery } from 'src/lib/API/graphql/helpers';
import { ConnectDragSource, ConnectDropTarget, DropTargetMonitor, useDrag, useDrop } from 'react-dnd';
import { DndType } from 'src/types/dnd';
import { reOrderRefs } from '../helpers';
import { TasksGeneratedSub } from '@tendium/prom-types/subscriptions';
import { ON_TASK_GENERATION } from './subscriptions';
import { BidTask } from '../BidTask';

interface IBiddingRequest {
  bidId?: string;
  isSubmitted?: boolean;
  filters?: TasksFilterArgs;
}
export function useApiBidding(bidId?: string): QueryResult<IBiddingResponse, IBiddingRequest> {
  return useQuery<IBiddingResponse, IBiddingRequest>(GET_BIDDING, {
    variables: {
      bidId
    },
    skip: !bidId
  });
}
export function useApiBiddingStats(bidId?: string): QueryResult<BiddingStatsResponse, IBiddingRequest> {
  const { filter: filters } = useTasksFilter();

  return useQuery<BiddingStatsResponse, IBiddingRequest>(GET_BIDDING_STATS, {
    variables: {
      bidId,
      filters: isTasksFilterApplied(filters) ? filters : undefined,
      isSubmitted: true
    },
    skip: !bidId,
    fetchPolicy: 'network-only'
  });
}
export function useBidding(): BiddingContextValue {
  return useContext(BiddingContext);
}

export function useBiddingGroup(id: string): BidTasksGroup | undefined {
  const { data } = useBidding();

  return useMemo(() => data?.questionGroups.find(group => group.id === id), [data, id]);
}

interface IGenerateBidQuestionsResponse {
  generateBidQuestions: BidTasksGroup[];
}
interface IGenerateBidQuestionsInput {
  bidId: string;
  selectedFiles: string[];
}

interface IGenerateGroupFnInput extends Omit<TaskTrackCreateData, 'bidId'>, IGenerateBidQuestionsInput {
  onComplete?: () => void;
}

export function useGenerateBidGroups(): [
  (data: IGenerateGroupFnInput) => void,
  { loading: boolean; error?: ApolloError }
] {
  const { t } = useTranslation();
  const [generateGroups, { loading, error }] = useMutation<IGenerateBidQuestionsResponse, IGenerateBidQuestionsInput>(
    GENERATE_TASKS
  );
  const [, setOpenTasksGroups] = useOpenTaskGroupsPrefs();

  const generateGroupFn = useCallback(
    async ({ bidId, selectedFiles, eventSource, mode, onComplete }: IGenerateGroupFnInput) => {
      return generateGroups({
        variables: { bidId, selectedFiles },
        update: updateCacheOnGenerateBidGroups(bidId)
      })
        .then(({ data }) => {
          if (data) {
            setOpenTasksGroups(data.generateBidQuestions[data.generateBidQuestions.length - 1].id);
            trackTaskCreate({
              bidId,
              eventSource,
              mode
            });
          }
        })
        .catch(() => {
          notification.error({
            description: t('Common.unknownErrorDesc'),
            message: t('Common.unknownError')
          });
        })
        .finally(() => {
          onComplete && onComplete();
        });
    },

    [generateGroups, setOpenTasksGroups, t]
  );

  return useMemo(() => [generateGroupFn, { loading, error }], [error, generateGroupFn, loading]);
}
function updateCacheOnGenerateBidGroups(bidId: string): MutationUpdaterFn<IGenerateBidQuestionsResponse> {
  return (cache, { data }) => {
    if (!data) {
      return null;
    }
    const bidRef = cache.identify({
      __typename: 'BidV2',
      id: bidId
    });

    cache.modify({
      id: bidRef,
      fields: {
        questionGroups(cachedGroups: Reference[], { readField, toReference }) {
          const groups = [...cachedGroups].length
            ? data.generateBidQuestions.filter(
                group =>
                  ![...cachedGroups].some(cachedGroup => readField({ fieldName: 'id', from: cachedGroup }) === group.id)
              )
            : data.generateBidQuestions;
          return [
            ...groups.reverse().map(group =>
              toReference({
                __typename: 'ProcurementBidQuestionGroup',
                id: group.id
              })
            ),
            ...cachedGroups
          ];
        }
      }
    });
    cache.modify({
      fields: {
        getQuestionGroupsStatistics(cached: BiddingStatsCached, { DELETE }) {
          return cached.variables.bidId === bidId ? DELETE : cached;
        }
      }
    });
    cache.gc();
  };
}

interface CreateBidGroupResponse {
  createBidQuestionGroup: BidTasksGroup;
}
interface CreateBidGroupInput {
  bidId: string;
  title: string;
}
type CreateBidGroupData = CreateBidGroupInput &
  Pick<TaskTrackCreateData, 'eventSource' | 'mode'> & {
    task?: CreateBidTaskValues;
    onComplete?: () => void;
  };
export function useCreateBidGroup(): [(input: CreateBidGroupData) => void, { loading: boolean; error?: ApolloError }] {
  const { t } = useTranslation();
  const [createGroup, { loading, error }] = useMutation<CreateBidGroupResponse, CreateBidGroupInput>(CREATE_BID_GROUP);
  const [createTask] = useCreateBidTasks();
  const [, setOpenTasksGroups] = useOpenTaskGroupsPrefs();

  const createGroupFn = useCallback(
    (input: CreateBidGroupData) => {
      const { bidId, title, onComplete, eventSource, mode } = input;
      createGroup({
        variables: { bidId, title },
        update: updateCacheOnCreateBidGroup(bidId)
      })
        .then(({ data }) => {
          if (data) {
            const questionGroupId = data.createBidQuestionGroup.id;
            createTask({ bidId, questionGroupId, onComplete, eventSource, mode, tasks: [''] });
            setTimeout(() => scrollToItem(questionGroupId), 0);
            setOpenTasksGroups(data.createBidQuestionGroup.id);
          }
        })
        .catch(() => {
          notification.error({
            description: t('Common.unknownErrorDesc'),
            message: t('Common.unknownError')
          });
        });
    },
    [createGroup, createTask, setOpenTasksGroups, t]
  );
  return useMemo(() => [createGroupFn, { loading, error }], [createGroupFn, error, loading]);
}

export function updateCacheOnCreateBidGroup(bidId: string): MutationUpdaterFn<CreateBidGroupResponse> {
  return (cache, { data }) => {
    if (!data) {
      return null;
    }
    const bidRef = cache.identify({
      __typename: 'BidV2',
      id: bidId
    });

    const groupId = data.createBidQuestionGroup.id;

    cache.modify({
      id: bidRef,
      fields: {
        questionGroups(cachedGroups: Reference[], { readField, toReference }) {
          return [...cachedGroups].some(ref => readField('id', ref) === groupId)
            ? cachedGroups
            : [toReference({ __typename: 'ProcurementBidQuestionGroup', id: groupId }), ...cachedGroups];
        }
      }
    });
    cache.modify({
      fields: {
        getQuestionGroupsStatistics(cached: BiddingStatsCached, { DELETE }) {
          return cached.variables.bidId === bidId ? DELETE : cached;
        }
      }
    });
  };
}

interface IUpdateBidQuestionGroupResponse {
  updateBidQuestionGroup: BidTasksGroup;
}

interface IUpdateBidQuestionGroupInput {
  bidId: string;
  groupId: string;
  title?: string;
  rank?: string;
  newCacheSortIndex?: number;
}

export function useUpdateBidGroup(): [
  (data: IUpdateBidQuestionGroupInput) => void,
  { loading: boolean; error?: ApolloError }
] {
  const { t } = useTranslation();
  const [updateGroup, { loading, error }] = useMutation<IUpdateBidQuestionGroupResponse, IUpdateBidQuestionGroupInput>(
    UPDATE_BID_GROUP
  );
  const updateGroupFn = useCallback(
    (data: IUpdateBidQuestionGroupInput) => {
      updateGroup({
        variables: data,
        update: updateCacheOnUpdateGroup(data.groupId, data.newCacheSortIndex)
      }).catch(() => {
        notification.error({
          description: t('Common.unknownErrorDesc'),
          message: t('Common.unknownError')
        });
      });
    },
    [t, updateGroup]
  );
  return useMemo(() => [updateGroupFn, { loading, error }], [updateGroupFn, error, loading]);
}

export function updateCacheOnUpdateGroup(
  groupId: string,
  newCacheSortIndex?: number
): MutationUpdaterFn<IUpdateBidQuestionGroupResponse> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }

    const groupRef = cache.identify({
      __typename: 'ProcurementBidQuestionGroup',
      id: groupId
    });

    if (newCacheSortIndex !== undefined) {
      const bidRef = cache.identify({
        __typename: 'BidV2',
        id: data.updateBidQuestionGroup.bid.id
      });

      cache.modify({
        id: bidRef,
        fields: {
          questionGroups(cachedGroups: Reference[]) {
            const groups = reOrderRefs(cachedGroups, groupRef ?? '', group => group.__ref, newCacheSortIndex);

            return groups;
          }
        }
      });
    }
    if (data.updateBidQuestionGroup.title) {
      cache.modify({
        id: groupRef,
        fields: {
          title() {
            return data.updateBidQuestionGroup.title;
          }
        }
      });
    }
  };
}

type DeleteBidGroupData = {
  deleteBidQuestionGroup: boolean;
};
type DeleteBidGroupVars = {
  groupId: string;
};
type DeleteBidGroupInput = DeleteBidGroupVars & {
  bidId: string;
};
type UseDeleteBidGroup = [(input: DeleteBidGroupInput) => void, { loading: boolean; error?: ApolloError }];
export function useDeleteBidGroup(): UseDeleteBidGroup {
  const { t } = useTranslation();
  const [deleteGroup, { loading, error }] = useMutation<DeleteBidGroupData, DeleteBidGroupVars>(DELETE_BID_GROUP);
  const deleteGroupFn = useCallback(
    (input: DeleteBidGroupInput) => {
      const { bidId, groupId } = input;
      trackBTDeleteGroup(groupId, bidId);
      deleteGroup({
        variables: { groupId },
        update: updateCacheOnDeleteBidGroup({ bidId, groupId })
      }).catch(() => {
        notification.error({
          description: t('Common.unknownErrorDesc'),
          message: t('Common.unknownError')
        });
      });
    },
    [deleteGroup, t]
  );
  return useMemo(() => [deleteGroupFn, { loading, error }], [deleteGroupFn, error, loading]);
}

export function updateCacheOnDeleteBidGroup({
  bidId,
  groupId
}: DeleteBidGroupInput): MutationUpdaterFn<DeleteBidGroupData> {
  return (cache, { data }) => {
    if (!data) {
      return null;
    }
    const bidRef = cache.identify({
      __typename: 'BidV2',
      id: bidId
    });
    cache.modify({
      id: bidRef,
      fields: {
        questionGroups(cachedGroups: Reference[], { readField }) {
          return [...cachedGroups].filter(group => readField('id', group) !== groupId);
        }
      }
    });
    let classificationIds: string[] = [];

    cache.modify({
      fields: {
        getQuestionGroupsStatistics(cached: BiddingStatsCached, { readField }) {
          if (cached.variables?.bidId === bidId) {
            return { ...cached, value: cached.value.filter(stat => readField('groupId', stat) !== groupId) };
          } else {
            return cached;
          }
        },
        getGroupQuestions(cached: TasksCachedResult, { readField, DELETE }) {
          if (cached.variables?.groupId === groupId) {
            if (cached.variables.isSubmitted === false) {
              classificationIds = [...cached.value.questions]
                .map(taskRef => readField({ fieldName: 'classificationId', from: taskRef }))
                .filter(isString)
                .filter(isNotUndefined);
            }
            return DELETE;
          } else {
            return cached;
          }
        }
      }
    });

    !!classificationIds.length &&
      classificationIds.forEach(classificationId => {
        cache.modify({
          id: cache.identify({ classificationId, __typename: 'DocumentCoordinates' }),
          fields: {
            createdTask() {
              return false;
            }
          }
        });
      });
    cache.evict({ id: cache.identify({ id: groupId, __typename: 'ProcurementBidQuestionGroup' }) });
    cache.evict({ id: cache.identify({ groupId, __typename: 'QuestionGroupStatistics' }) });
    cache.gc();
  };
}

export function useTasksFilter(): {
  filter?: TasksFilterArgs;
  updateFilter?: <T extends keyof TasksFilterArgs>(key: T, value: TasksFilterArgs[T]) => void;
  resetFilter?: () => void;
} {
  const filter = useContext(GroupTasksFilterContext);
  const setFilter = useContext(UpdateGroupTasksFilterContext);
  const { cache } = useApolloClient();
  const location = useLocation();
  const { id: bidId } = useParams<{ id: string }>();

  const { pathname, hash } = location;

  const updateFilter = useCallback(
    <T extends keyof TasksFilterArgs>(key: T, value: TasksFilterArgs[T]) => {
      setFilter?.(prevFilters => {
        const { [key]: updatedProp, ...restProps } = prevFilters || {};
        if (Array.isArray(value) && value.length === 0) return restProps;
        else {
          cache.evict({
            id: 'ROOT_QUERY',
            fieldName: 'getQuestionGroupsStatistics',
            args: { input: { bidId, filters: prevFilters } }
          });
          cache.gc();

          return {
            ...restProps,
            [key]: Array.isArray(value) ? value.sort() : value
          };
        }
      });

      updateQueryParams({ [key]: value }, false, pathname, hash);
    },
    [bidId, cache, hash, pathname, setFilter]
  );

  const resetFilter = useCallback(() => {
    setFilter?.(() => undefined);
    updateQueryParams(
      {
        assignedTo: null,
        status: null,
        fulfillment: null,
        requirementType: null
      },
      false,
      pathname,
      hash
    );
  }, [hash, pathname, setFilter]);

  return useMemo(() => ({ filter, updateFilter, resetFilter }), [filter, updateFilter, resetFilter]);
}

export function useTasks(): TasksState {
  const ctx = useContext(GroupTasksContext);
  if (!ctx) {
    throw new Error('not in GroupTasksContext provider');
  }

  return ctx;
}

export function useOpenTaskGroupsPrefs(): [string[] | undefined, (groupId?: string | null) => void] {
  const [page, updatePage] = usePagePreferences();

  const openTaskGroupsIds = useMemo(() => {
    return page?.openTaskGroups ? page.openTaskGroups : [];
  }, [page?.openTaskGroups]);

  const setOpenTasksGroups = useCallback(
    (groupId?: string | null) => {
      if (!groupId) {
        updatePage({ openTaskGroups: openTaskGroupsIds });
        return;
      }

      const updatedGroupIds = openTaskGroupsIds.includes(groupId)
        ? openTaskGroupsIds.filter(id => id !== groupId)
        : [...openTaskGroupsIds, groupId];

      updatePage({ openTaskGroups: updatedGroupIds.length ? updatedGroupIds : null });
    },
    [openTaskGroupsIds, updatePage]
  );

  return useMemo(() => [openTaskGroupsIds, setOpenTasksGroups], [openTaskGroupsIds, setOpenTasksGroups]);
}

export function useLoadTasks({
  groupId,
  isSubmitted,
  limit = GROUP_TASKS_LIMIT,
  skip,
  filter
}: UseLoadTasks): TasksResponseData {
  const { filter: storedFilters } = useTasksFilter();
  const selectedFilters = useMemo(() => {
    if (filter) return filter;
    return isTasksFilterApplied(storedFilters) ? storedFilters : undefined;
  }, [filter, storedFilters]);

  const query = useQuery<ApiTasksResponse, ApiTasksVars>(GET_GROUP_TASKS, {
    variables: {
      groupId,
      offset: 0,
      limit,
      filters: selectedFilters,
      isSubmitted
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: skip ?? !groupId,
    errorPolicy: 'ignore'
  });

  return useMemo(
    () => ({
      ...query,
      ...getLoadingStatesForQuery(query),
      data: query.data?.getGroupQuestions ? new TasksData(query.data?.getGroupQuestions) : undefined
    }),
    [query]
  );
}

export function useLazyLoadTasks(): LazyQueryResultTuple<ApiTasksResponse, ApiTasksVars> {
  return useLazyQuery<ApiTasksResponse, ApiTasksVars>(GET_GROUP_TASKS);
}
export function useLazyLoadGroupTasks(): [
  (groupId: string, isSubmitted?: boolean) => Promise<BidTask[]>,
  { loading: boolean; error?: ApolloError }
] {
  const [loadTasks, { loading, error }] = useLazyLoadTasks();

  const loadTasksFn = useCallback(
    async (groupId: string, isSubmitted?: boolean) => {
      return loadTasks({ variables: { groupId, limit: GROUP_TASKS_LIMIT, offset: 0, isSubmitted } }).then(res => {
        const tasksData = res.data?.getGroupQuestions ? new TasksData(res.data.getGroupQuestions) : undefined;
        return tasksData?.tasks.filter(isNotUndefined) ?? [];
      });
    },
    [loadTasks]
  );
  return [loadTasksFn, { loading, error }];
}

export function useDndSortDrop(
  index: number,
  ref: React.RefObject<HTMLDivElement>,
  dropItem: (dragIndex: number, hoverIndex: number) => void,
  moveItem: (dragIndex: number, hoverIndex: number) => void,
  type: DndType
): [{ handlerId: string | symbol | null }, ConnectDropTarget] {
  return useDrop({
    accept: type,
    drop(item: DragItem) {
      if (!ref || !ref.current) return;
      const dragIndex = item.index;
      const hoverIndex = index;
      item.index = hoverIndex;

      dropItem(dragIndex, hoverIndex);
    },
    collect: (monitor: { getHandlerId: () => string | symbol | null }) => ({
      handlerId: monitor.getHandlerId()
    }),
    hover(item: DragItem, monitor: DropTargetMonitor<DragItem, void>) {
      if (!ref || !ref.current) return;

      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) return;

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset?.y ?? 0) - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

      moveItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    }
  });
}

export function useDndSortDrag(
  index: number,
  dndId: number,
  canDrag: boolean,
  type: DndType
): [{ isDragging: boolean }, ConnectDragSource] {
  const [{ isDragging }, drag] = useDrag({
    type: type,
    item: () => ({ dndId, index }),
    collect: monitor => ({
      isDragging: monitor.isDragging()
    }),
    canDrag
  });

  return [{ isDragging }, drag];
}

export function useBidTaskGroups(): {
  loading: boolean;
  groups: BidTasksGroup[];
} {
  const { data, loading: biddingDataLoading, statsLoading } = useBidding();

  const groups = useMemo(() => data?.questionGroups ?? [], [data?.questionGroups]);

  const loading = biddingDataLoading || statsLoading;

  return useMemo(() => {
    return { loading, groups };
  }, [groups, loading]);
}

export function useGetTasksGenerationStatus(
  bidId?: string
): QueryResult<GetTasksGenerationStatusResponse, IBiddingRequest> {
  return useQuery<GetTasksGenerationStatusResponse, IBiddingRequest>(GET_TASKS_GENERATION_STATUS, {
    variables: { bidId },
    skip: !bidId
  });
}

export function useTaskGenerationSubscription(
  successNotification: () => JSX.Element,
  noTasksNotification: (bidId: string) => JSX.Element
): void {
  const { refetch, client } = useApiBidding();
  const { refetch: refetchStats } = useApiBiddingStats();
  const isAutoGenerateTasksAvailable = useFeatureFlag(FeatureFlag.BiddingTool_AutoGenerateTasks);

  const { t } = useTranslation();
  useSubscription<{ tasksGenerated: TasksGeneratedSub.Data }, OperationVariables>(ON_TASK_GENERATION, {
    onData: ({ data }) => {
      if (!data) return;

      const status = data.data?.tasksGenerated.status;
      const bidId = data.data?.tasksGenerated.bidId;
      const taskCount = data.data?.tasksGenerated.taskCount;

      if (status === 'COMPLETED' && bidId) {
        trackTaskCreate({
          bidId,
          eventSource: 'Bidding Tool page',
          mode: 'pre-populate'
        });
        notification.close('taskJobLoading');
        refetchStats({ bidId });
        refetch({ bidId }).then(() => {
          client.cache.writeQuery({
            query: GET_TASKS_GENERATION_STATUS,
            variables: { bidId },
            data: {
              getTasksGenerationStatus: {
                bidId,
                status: TasksGenerationJobStatus.COMPLETED
              }
            }
          });
          notification.open({
            message: '',
            description: taskCount ? successNotification() : noTasksNotification(bidId)
          });
        });
      } else if (status === 'ERROR') {
        notification.error({
          message: t('BidSpaces.CreateTaskNotification.ErrorTitle'),
          description: t('BidSpaces.CreateTaskNotification.ErrorMessage')
        });
        notification.close('taskJobLoading');

        client.cache.writeQuery({
          query: GET_TASKS_GENERATION_STATUS,
          variables: { bidId },
          data: {
            getTasksGenerationStatus: {
              bidId,
              status: TasksGenerationJobStatus.ERROR
            }
          }
        });
      }
    },
    skip: !isAutoGenerateTasksAvailable
  });
}

export function useStartTaskJob(
  bidId: string,
  loadingNotification: () => JSX.Element
): (publicFiles: string[], privateFiles: PrivateFileInput[]) => void {
  const [startTasksJob, { client }] = useMutation(START_TASKS_GENERATION_JOB);
  const { t } = useTranslation();

  const handleStartJob = (publicFiles: string[], privateFiles: PrivateFileInput[]): void => {
    startTasksJob({
      variables: {
        bidId,
        publicFiles,
        privateFiles
      }
    })
      .then(() => {
        client.cache.writeQuery({
          query: GET_TASKS_GENERATION_STATUS,
          variables: { bidId },
          data: {
            getTasksGenerationStatus: {
              bidId,
              status: TasksGenerationJobStatus.IN_PROGRESS
            }
          }
        });

        notification.open({
          message: '',
          description: loadingNotification(),
          key: 'taskJobLoading',
          duration: 0
        });
      })
      .catch(() => {
        notification.error({
          description: t('Common.unknownErrorDesc'),
          message: t('Common.unknownError')
        });
      });
  };

  return handleStartJob;
}
