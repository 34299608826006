import React, { FC, useCallback, useMemo } from 'react';
import styles from './index.module.scss';
import { useBidPreview, useMarkBidAsRejected } from 'src/models/bids/BidPreview/hooks';
import {
  AssignToBid,
  BidQuickActions,
  BlockHeading,
  ByggfaktaProjectButton,
  MultiBuyerBoxes,
  TenderBox,
  WorkspaceStatusPicker
} from 'src/shared';
import { useTranslation } from 'react-i18next';
import { useBidUrl, useOverviewBackLink } from 'src/models/procurements/hooks';
import { Link } from 'react-router-dom';
import { FeatureFlag, useFeatureFlag } from 'src/helpers/featureFlag';
import { isFieldString, TenderBoxMode } from 'src/models/procurements/Tender/types';
import { useCloseSidebar } from 'src/shared/InfoSidebar/hooks';
import { Button } from 'src/common';
import { BidItemType } from '@tendium/prom-types/tender';
import BidWebhooksButton from 'src/shared/Bid/BidWebhooksButton';
import { useLoadWebhooks } from 'src/models/company/Webhooks/hooks';
import { RemindersDropdown } from 'src/shared/Reminders';
import { RemindersProvider } from 'src/models/reminders/providers';
import { BiddingToolTab } from 'src/models/bids/BidFull/types';

export const BidPreviewHeader: FC = () => {
  const { data } = useBidPreview();
  const bidId = data?.id;
  const bidStageId = data?.bidStageId;
  const workspaceId = data?.workspaceId;
  const workspaceStageCategory = data?.status?.category;
  const nameBox = data?.origin.name;
  const webhookStatus = useMemo(() => data?.webhookStatus, [data]);

  const name = useMemo(
    () =>
      nameBox && nameBox.firstField && isFieldString(nameBox.firstField) ? nameBox.firstField?.string?.toString() : '',
    [nameBox]
  );
  const buyerBoxes = data?.origin.buyerBoxes;
  const buyerLinkBox = data?.origin.buyerLinkBox;
  const { t } = useTranslation();

  const bidHref = useBidUrl(bidId || '');
  const { set: setBackLink } = useOverviewBackLink();
  const isEditableBidFeature = useFeatureFlag(FeatureFlag.BiddingWorkflow_Preview);
  const isRemindersFeature = useFeatureFlag(FeatureFlag.Reminders);

  const { data: webhooks } = useLoadWebhooks();

  const isBidRejected = !!data?.isRejected;

  const [markBidAsRejected, { loading }] = useMarkBidAsRejected();
  const closeSidebar = useCloseSidebar();

  const onReject = useCallback(() => {
    workspaceId &&
      bidId &&
      bidStageId &&
      markBidAsRejected(bidId, !isBidRejected, bidStageId, workspaceId, closeSidebar, workspaceStageCategory);
  }, [bidId, bidStageId, closeSidebar, isBidRejected, markBidAsRejected, workspaceId, workspaceStageCategory]);

  return (
    data && (
      <>
        <div className={styles.blockHeading}>
          <div className={styles.heading}>
            {nameBox && isEditableBidFeature && (
              <TenderBox
                actions={false}
                box={nameBox}
                title={false}
                className={styles.titleBox}
                as={TenderBoxMode.Custom}
                isEditable
              />
            )}
            {name && !isEditableBidFeature && <BlockHeading title={name} className={styles.title} />}
            <div className={styles.actions}>
              {!!isRemindersFeature && (
                <RemindersProvider relationId={bidId} sourceTitle={name} wsId={workspaceId} id={bidId}>
                  <RemindersDropdown />
                </RemindersProvider>
              )}
              {webhooks &&
                webhooks.map(webhook => (
                  <BidWebhooksButton
                    key={webhook.id}
                    webhook={webhook}
                    webhookStatus={webhookStatus}
                    bidId={bidId}
                    size={'l'}
                  />
                ))}
              <ByggfaktaProjectButton bidId={bidId} isActive={data?.isBfProject} />
              {bidId && (
                <AssignToBid
                  assignedTo={data.assignedTo}
                  bidId={bidId}
                  wsId={workspaceId}
                  size={'m'}
                  eventSource={'Bid Preview'}
                />
              )}
              {workspaceId && !!bidId && (
                <BidQuickActions
                  originId={data.origin.id}
                  originType={data.origin.originType}
                  wsId={workspaceId}
                  bidId={bidId}
                  categoryName={workspaceStageCategory}
                />
              )}
            </div>
          </div>
          <MultiBuyerBoxes
            buyerBoxes={buyerBoxes}
            linkBox={buyerLinkBox}
            className={styles.buyerBoxes}
            isEditable={!!isEditableBidFeature && data.origin.originType !== BidItemType.Procurement}
          />
        </div>

        <div className={styles.blockActions}>
          {!!bidId && (
            <Link
              className={styles.button}
              to={{ pathname: bidHref, hash: BiddingToolTab.Overview }}
              onClick={setBackLink}
            >
              <span>{t('BiddingTool.biddingTool')}</span>
            </Link>
          )}

          {!!bidId && (
            <ul className={styles.actions}>
              <li className={styles.action}>
                <WorkspaceStatusPicker
                  bidId={bidId}
                  colorful
                  className={styles.wsStatusPicker}
                  eventSource="Bid preview"
                />
              </li>
              <li className={styles.action}>
                <Button onClick={onReject} loading={loading} className={styles.rejectButton}>
                  {t(isBidRejected ? 'BidSpaces.unreject' : 'BidSpaces.reject')}
                </Button>
              </li>
            </ul>
          )}
        </div>
      </>
    )
  );
};

export default BidPreviewHeader;
