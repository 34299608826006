import React, { FC } from 'react';
import { Translations } from 'src/lib/I18nService/types';
import ReactMarkdown from 'src/common/Markdown';
import { useExistingTranslation } from 'src/common/hooks';
import classNames from 'classnames';
import styles from './index.module.scss';
import { IBlockReadProps } from 'src/shared/Tender/Boxes/types';
import { IBoxFieldString } from 'src/models/procurements/Tender/types';
import BlockEmpty from '../../Empty';
import { Tooltip } from 'src/common';

export interface Props extends IBlockReadProps, Pick<IBoxFieldString, 'string'> {}
export const ReadOnlyBlockText: FC<Props> = ({ string, translation, className, config }) => {
  const { existingT: t } = useExistingTranslation(translation ?? Translations.procurements);

  if (!string) return <BlockEmpty />;

  const content = (
    <ReactMarkdown className={classNames(styles.text, !!className && className)}>{t(string)}</ReactMarkdown>
  );

  if (!config?.isTooltip) return content;

  return (
    <Tooltip title={content}>
      <span className={styles.blockTextContainer}>{content}</span>
    </Tooltip>
  );
};

export default ReadOnlyBlockText;
