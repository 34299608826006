import React, { FC, useMemo } from 'react';
import { IApiOrganization } from 'src/models/organisations';
import { useOverlappingTenders } from 'src/models/procurements/Billing/hooks';
import { ApiTenderTransactionsInput } from 'src/models/procurements/Billing/types';
import { SupplierContracts } from 'src/shared';
import { SupplierContractObject } from 'src/models/procurements/Tenders/types';
import { toSupplierContractObjects } from 'src/models/procurements/Billing/helpers';

export interface Props {
  vars?: ApiTenderTransactionsInput;
  suppliers: IApiOrganization[];
  tenderId?: string;
  className?: string;
  loading?: boolean;
}

export const SupplierList: FC<Props> = ({ vars, tenderId, suppliers, className, loading }) => {
  const { data, loading: tendersLoading } = useOverlappingTenders(
    vars?.supplierOrgIds ?? [],
    vars?.buyerOrgIds[0],
    tenderId
  );

  const supplierContractObjects: SupplierContractObject[] = useMemo(() => {
    return toSupplierContractObjects(data?.tenders ?? [], suppliers, tenderId ? [tenderId] : undefined);
  }, [data?.tenders, suppliers, tenderId]);

  const isLoading = useMemo(() => !!loading || tendersLoading, [loading, tendersLoading]);

  return (
    <SupplierContracts
      supplierContractObjects={supplierContractObjects}
      loading={isLoading}
      className={className}
      hideEmptyState
    />
  );
};

export default SupplierList;
