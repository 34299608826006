import React from 'react';
import styles from './index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import classNames from 'classnames';
import { fileToExtIcon } from 'src/helpers/fileExtToIcon';

interface Props {
  fileName: string;
  onDelete: (file: string) => void;
  extensions: string[];
}

const FileItem: React.FC<Props> = ({ fileName, onDelete }) => {
  const fileIcon = fileToExtIcon(fileName);

  return (
    <div className={styles.container}>
      <div className={classNames(styles.fileInfo)}>
        <FontAwesomeIcon icon={fileIcon} />
        {fileName}
      </div>
      <FontAwesomeIcon className={styles.pointer} onClick={() => onDelete(fileName)} icon={faTrash} />
    </div>
  );
};

export default FileItem;
