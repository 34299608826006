import React, { FC, useCallback, useMemo, useState } from 'react';
import { ITenderBoxDataSource } from 'src/models/procurements/Tender/types';
import styles from './index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileMagnifyingGlass } from '@fortawesome/pro-light-svg-icons';
import classNames from 'classnames';
import { usePreviewFile, useTenderData } from 'src/models/procurements/Tender/hooks';
import DocumentViewer from 'src/shared/DocumentViewer';
import { useParams } from 'react-router-dom';
import { useBoxCoordinates } from 'src/pages/TenderPage/DocumentSearch/hooks';
import { IHighlight } from 'react-pdf-highlighter';
import { cleanBoxId, toPdfHighlightsData } from 'src/shared/DocumentViewer/helpers';
import { trackViewDocumentPreview } from 'src/segment/events';
import { useIsMobile } from 'src/reactiveVars';

export interface Props {
  dataSource: ITenderBoxDataSource;
  boxId: string;
  procurementId?: string; // Optional for the case when procurementId is not available in the URL
}

export const BoxDataSource: FC<Props> = ({ dataSource, boxId, procurementId }) => {
  const { filePath, fileName, fileExt } = dataSource;
  const { id: procurementIdParam } = useParams<{ id: string }>();
  const finalProcurementId = useMemo(() => procurementId ?? procurementIdParam, [procurementId, procurementIdParam]);
  const { url, loading, previewFile, closePreview } = usePreviewFile(finalProcurementId);
  const [getCoordinates, { loading: loadingCoords }] = useBoxCoordinates();
  const [highlights, setHighlights] = useState<IHighlight[]>([]);
  const [isMobile] = useIsMobile();

  const { data } = useTenderData();
  const sourceName = useMemo(() => {
    return fileExt ? `${fileName}.${fileExt}` : fileName;
  }, [fileExt, fileName]);

  const onClick = useCallback(async () => {
    const coords = await getCoordinates({
      variables: { boxId: cleanBoxId(boxId), procurementId: finalProcurementId ?? '' }
    });
    if (coords.data && coords.data?.boxCoordinates) {
      const { headlinePosition, contentPosition } = coords.data?.boxCoordinates;
      const pdfHighlights = toPdfHighlightsData(headlinePosition, contentPosition);
      setHighlights(pdfHighlights);
    }
    if (!loading) {
      previewFile(filePath);
      trackViewDocumentPreview(
        data?.bids ? 'Boxes - Customer report page' : 'Boxes - Details page',
        finalProcurementId ? [finalProcurementId] : undefined
      );
    }
  }, [boxId, data?.bids, filePath, finalProcurementId, getCoordinates, loading, previewFile]);

  return (
    <>
      <button
        className={classNames(styles.source, {
          [styles.sourceMobile]: isMobile
        })}
        onClick={onClick}
      >
        <span
          className={classNames(styles.sourceIcon, { [styles.isActive]: loading, [styles.sourceIconMobile]: isMobile })}
        >
          <FontAwesomeIcon icon={faFileMagnifyingGlass} />
        </span>
        <span>{sourceName}</span>
      </button>
      {url && (
        <DocumentViewer
          onClose={closePreview}
          title={sourceName}
          url={url}
          loading={loading || loadingCoords}
          highlights={highlights}
        />
      )}
    </>
  );
};

export default BoxDataSource;
