import React, { ReactNode, FC, Dispatch, SetStateAction } from 'react';
import { KeywordsContext } from './context';
import { useMPKeywordsData, useTendersKeywordsData } from './hooks';
import { FilterLogic } from '@tendium/prom-types';
import { TendersSearchSchemaType } from '../procurements/TendersSearch/schemas';
import { TendersSearchUpdateFn } from '../procurements/TendersSearch/types';
import { MpKeywordAndGroup } from '../matchingProfiles/types';

interface MPKeywordsProviderProps {
  children: ReactNode;
  type: FilterLogic;
}

export const MPKeywordsProvider: FC<MPKeywordsProviderProps> = ({ children, type }) => {
  const contextValue = useMPKeywordsData(type);

  return <KeywordsContext.Provider value={contextValue}>{children}</KeywordsContext.Provider>;
};

interface TendersKeywordsProviderProps extends MPKeywordsProviderProps {
  searchArgs: TendersSearchSchemaType;
  updateSearchArgs: TendersSearchUpdateFn;
  newKeywords: MpKeywordAndGroup[];
  setNewKeywords: Dispatch<SetStateAction<MpKeywordAndGroup[]>>;
}
export const TendersKeywordsProvider: FC<TendersKeywordsProviderProps> = ({
  children,
  type,
  searchArgs,
  updateSearchArgs,
  newKeywords,
  setNewKeywords
}) => {
  const contextValue = useTendersKeywordsData(type, searchArgs, updateSearchArgs, newKeywords, setNewKeywords);

  return <KeywordsContext.Provider value={contextValue}>{children}</KeywordsContext.Provider>;
};
