import classNames from 'classnames';
import React from 'react';
import { FC, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'src/common';
import { InputRef } from 'antd';
import styles from './index.module.scss';
import { Control, useController } from 'react-hook-form';
import NumberInput from 'src/common/NumberInput';

interface Props {
  name: string;
  control: Control;
  disabled?: boolean;
  inputValue?: number | null; // for formatting
  placeholder?: string;
  className?: string;
}
const CurrencyInput: FC<Props> = ({ name, control, disabled, inputValue, placeholder, className }) => {
  const { t } = useTranslation();
  const inputRef = useRef<InputRef>(null);
  const [focus, setFocus] = useState(false);
  const { field } = useController({ name: name, control });

  const onFocus = useCallback(() => {
    setFocus(true);
    inputRef.current?.focus();
  }, []);

  const onBlur = useCallback(() => {
    setFocus(false);
  }, []);

  const text = useMemo(() => {
    return inputValue ? t('Common.Format.formatCurrencyNumber', { number: inputValue }) : null;
  }, [inputValue, t]);

  return (
    <div className={classNames(styles.container, className, { [styles.isFocus]: focus })}>
      <div className={styles.number}>
        <NumberInput {...field} ref={inputRef} placeholder={placeholder} disabled={disabled} onBlur={onBlur} />
      </div>
      {!!text && <Input className={styles.text} type={'text'} value={text} onFocus={onFocus} disabled={disabled} />}
    </div>
  );
};
export default CurrencyInput;
