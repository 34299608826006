import React, { FC, useMemo } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import BidsDndPreview from '../DndPreview';
import { useDragPreview } from 'src/helpers/dragPreview';
import { isBidsTableDndItem } from 'src/models/bids/Bids/types';

interface Props {}

export const BidsDndLayer: FC<Props> = () => {
  const { display, item, style } = useDragPreview();
  const renderItem = useMemo(() => {
    if (isBidsTableDndItem(item)) {
      return <BidsDndPreview bid={item.dndItem} bidIdsCount={item.bidIds?.length || 0} />;
    }

    return null;
  }, [item]);

  if (!display) {
    return null;
  }

  return (
    <div className={classNames(styles.dragLayer)} style={style}>
      {renderItem}
    </div>
  );
};

export default BidsDndLayer;
