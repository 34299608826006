import { z } from 'zod';
import {
  isTenderVarsDiff,
  tendersSearchSchema,
  TendersSearchSchemaKeyType,
  TendersSearchSchemaType
} from '../tendersSearchSchema';
import { sortSupplierIds, sortSupplierStrings } from '../../helpers';

export const supplierFilterSchema = tendersSearchSchema.pick({
  supplierOrgIds: true,
  supplierSearchStrings: true,
  supplierSearchLogic: true
});
export type SupplierFilterSchemaType = z.infer<typeof supplierFilterSchema>;

export const supplierFilterKeys = Object.keys(supplierFilterSchema.shape) as TendersSearchSchemaKeyType[];

export function isSupplierFilterDiff(vars1?: TendersSearchSchemaType, vars2?: TendersSearchSchemaType): boolean {
  const parsed1 = vars1
    ? {
        ...vars1,
        supplierOrgIds: sortSupplierIds(vars1.supplierOrgIds),
        supplierSearchStrings: sortSupplierStrings(vars1.supplierSearchStrings)
      }
    : undefined;
  const parsed2 = vars2
    ? {
        ...vars2,
        supplierOrgIds: sortSupplierIds(vars2.supplierOrgIds),
        supplierSearchStrings: sortSupplierStrings(vars2.supplierSearchStrings)
      }
    : undefined;
  return isTenderVarsDiff(supplierFilterKeys, parsed1, parsed2);
}
