import { z } from 'zod';
import {
  isTenderVarsDiff,
  tendersSearchSchema,
  TendersSearchSchemaKeyType,
  TendersSearchSchemaType
} from '../tendersSearchSchema';

export const contractValueFilterSchema = tendersSearchSchema.pick({
  contractValueRange: true,
  contractValueExcludeMissingValue: true
});
export type ContractValueFilterSchemaType = z.infer<typeof contractValueFilterSchema>;

export const contractValueFilterKeys = Object.keys(contractValueFilterSchema.shape) as TendersSearchSchemaKeyType[];

export function isContractValueFilterDiff(vars1?: TendersSearchSchemaType, vars2?: TendersSearchSchemaType): boolean {
  return isTenderVarsDiff(contractValueFilterKeys, vars1, vars2);
}
