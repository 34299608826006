import { IWorkspaceStatus, IApiWorkspaceStatus } from './WorkspaceStatus/types';
import { IUserName, IApiUser, AssignedTo } from '../users/types';
import { AccessControlPolicy, IAccessControl } from '../acl/types';
import { PageView } from '../users/Preferences/types';
import { ApiAssignedTo } from '../users/AssignedTo/types';
import { Currency } from '@tendium/prom-types';

export const DEFAULT_WORKSPACE_COLORS = [
  '#59BEFC',
  '#F09400',
  '#B221FF',
  '#0A7344',
  '#FF1A1A',
  '#FEA5A5',
  '#1400FF',
  '#F6D42A',
  '#38B45E',
  '#ED47E7',
  '#90663B',
  '#497C9B',
  '#F85D1F',
  '#7700CE',
  '#53D94D',
  '#CBB19F',
  '#FE4D98',
  '#112595',
  '#BC2134'
];

export const DEFAULT_WORKSPACE_SETTING = {
  name: '',
  color: 'hsl(225, 73%, 57%)',
  ownersPolicy: AccessControlPolicy.ALL,
  subscribersPolicy: AccessControlPolicy.ALL
};
export const DEFAULT_WORKSPACE_VIEW = PageView.board;
export const DEFAULT_WORKSPACES_VIEW = PageView.table;

export const EXPORT_BIDSPACES_KEY = 'export-bidspaces';
export const MIN_INDICATOR_DURATION = 750;

export interface IBaseWorkspace {
  id: string;
  name: string;
  color: string;
}
export interface IApiWorkspace extends IBaseWorkspace {
  isActive: boolean;
  statuses: IApiWorkspaceStatus[];
  archivedBy?: IApiUser;
  archivedDate?: number;
  accessControl: IAccessControl;
  owners: ApiAssignedTo[];
  subscribers: ApiAssignedTo[];
  accessToEdit?: boolean;
}

export interface IWorkspace extends Omit<IApiWorkspace, 'archivedBy' | 'owners' | 'subscribers' | 'statuses'> {
  archivedBy?: IUserName;
  owners: AssignedTo[];
  subscribers: AssignedTo[];
  statuses: IWorkspaceStatus[];
}

export interface IWorkspaceStatistic {
  winHitRate: number;
  contractValueSums: {
    statusId: string;
    currency: Currency;
    valueSum: number;
  }[];
}

export interface IExportBidspacesInput {
  bidspaceIds: string[];
}

export interface IExportBidspacesOutput {
  exportBidspaces: { operationId: string };
}

export interface ICreateWorkspaceInput {
  name: string;
  color: string;
  owners: string[] | null;
  subscribers: string[] | null;
}

export interface ICreateWorkspaceOutput {
  createWorkspace: IApiWorkspace;
}
