import React, { FC, useCallback } from 'react';
import styles from './index.module.scss';
import Button from '../Button';
import { MultiBuyerBoxes } from 'src/shared';
import { ITenderBox } from 'src/models/procurements/Tender/types';
import { useTranslation } from 'react-i18next';
import Tooltip from '../Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward } from '@fortawesome/pro-regular-svg-icons';
import { faDash, faUpRightFromSquare } from '@fortawesome/pro-light-svg-icons';
import { useIsMobile } from 'src/reactiveVars';
import classNames from 'classnames';
import { Paths } from 'src/pages/paths';

interface Props {
  id?: string;
  title?: string;
  buyers?: ITenderBox[];
  suppliers?: ITenderBox[];
  contractStart?: Date;
  contractEnd?: Date;
  onClick?: () => void;
  isActive?: boolean;
}
export const ProcurementCard: FC<Props> = props => {
  const { id, title, buyers, suppliers, contractStart, contractEnd, onClick, isActive } = props;

  const { t } = useTranslation();
  const [isMobile] = useIsMobile();

  const onOpenTender = useCallback(() => {
    if (!id) return;
    window.open(`${Paths.TENDER_ROUTE}${id}`, '_blank');
  }, [id]);

  return (
    <Button
      key={id}
      className={classNames(styles.button, {
        [styles.buttonMobile]: isMobile,
        [styles.isActive]: isActive
      })}
      onClick={onClick}
    >
      <div
        className={classNames(styles.procurementCard, {
          [styles.procurementCardMobile]: isMobile
        })}
      >
        <Tooltip title={title}>
          <Button icon={<FontAwesomeIcon icon={faUpRightFromSquare} />} type={'link'} onClick={onOpenTender}>
            <span className={styles.title}>{title}</span>
          </Button>
        </Tooltip>
        <div className={styles.orgInfo}>
          <MultiBuyerBoxes buyerBoxes={buyers} noLink />
        </div>
        <div className={styles.orgInfo}>
          <FontAwesomeIcon icon={faAward} className={styles.awardIcon} />
          <MultiBuyerBoxes buyerBoxes={suppliers} noLink />
        </div>
        <div className={styles.contractPeriod}>
          <div>
            {contractStart ? (
              <span>{t('Common.Format.tableDateFormatForShortMonths', { date: contractStart })}</span>
            ) : (
              <FontAwesomeIcon className={styles.dashIcon} icon={faDash} />
            )}
            <span className={styles.to}>{t('Common.to')}</span>
            {contractEnd ? (
              <span>{t('Common.Format.tableDateFormatForShortMonths', { date: contractEnd })}</span>
            ) : (
              <FontAwesomeIcon className={styles.dashIcon} icon={faDash} />
            )}
          </div>
        </div>
      </div>
    </Button>
  );
};

export default ProcurementCard;
