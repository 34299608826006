import React, { FC, ReactNode } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import { ThemeMode } from 'src/models/ownership';

interface Props {
  children: ReactNode;
  onClick?: () => void;
}

export const HeaderAction: FC<Props> = props => {
  const { children, onClick } = props;

  return (
    <button className={classNames(styles.actionIcon, styles[ThemeMode.light])} onClick={onClick}>
      {children}
    </button>
  );
};

export default HeaderAction;
