import React, { FC, useCallback, useMemo, useState } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import { Content } from 'src/models/contentLibrary/ContentLibrary';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Tooltip, confirm } from 'src/common';
import {
  useContentLibrary,
  useContentLibraryNav,
  useDeleteContentLibrary
} from 'src/models/contentLibrary/ContentLibrary/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import ContentLibraryTag from '../ContentLibraryTags/ContentLibraryTagsArea/ContentLibraryTag';
import AssignToContentLibrary from 'src/shared/ContentLibrary/AssignToContentLibrary';
import { Tag } from 'src/models/contentLibrary/ContentLibraryTag';
import { faCopy, faPen, faTrash } from '@fortawesome/pro-light-svg-icons';
import modalStyles from 'src/common/Modal/index.module.scss';
import { toHSLColor } from 'src/helpers/styles';
import { useActiveUsers } from 'src/models/users/hooks';
import { getUsersWithAccessToRooms } from 'src/models/contentLibrary/ContentLibrary/types';
import { FeatureFlag, useFeatureFlag } from 'src/helpers';
import { useCurrentSidebar } from 'src/shared/InfoSidebar/hooks';
import { SidebarMode } from 'src/shared/InfoSidebar/types';
import { useCurrentEditor } from '@tiptap/react';
import { trackBTUseGeneratedAnswer } from 'src/segment/events/canvas';

function limitTagsAndReturnRest(tags: Tag[], maxChars: number): [Tag[], Tag[]] {
  let currentChars = 0;
  let sliceIndex = 0; // Tracks where to slice the array to get the rest of the tags

  for (let i = 0; i < tags.length; i++) {
    if (currentChars + tags[i].title.length <= maxChars) {
      currentChars += tags[i].title.length;
      sliceIndex = i + 1; // Update sliceIndex to the position after the current tag
    } else {
      break; // Break the loop if adding another tag would exceed the limit
    }
  }

  const limitedTags = tags.slice(0, sliceIndex); // Tags within the limit
  const remainingTags = tags.slice(sliceIndex); // The rest of the tags

  return [limitedTags, remainingTags];
}

export const ContentLibraryCard: FC<{
  content: Content | null;
  id: string;
  onEditContentCard?: (content: Content) => void;
}> = ({ content, id, onEditContentCard }) => {
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState(false);
  const { selectedPairIds, setSelectedPairIds } = useContentLibrary();
  const { editor } = useCurrentEditor();

  const { isCanvasPage } = useContentLibraryNav();

  const sidebarState = useCurrentSidebar();
  const isSidebarMode =
    sidebarState?.mode === SidebarMode.CONTENT_LIBRARY || sidebarState?.mode === SidebarMode.TOOLBOX_CONTENT_LIBRARY;
  const isCanvasTaskSourceMode = sidebarState?.mode === SidebarMode.CANVAS_TASK_SOURCES;

  const isBiddingGPTContentLibrary = useFeatureFlag(FeatureFlag.BiddingTool_BiddingGPT_ContentLibrary);
  const users = useActiveUsers();

  const usersWithAccess = useMemo(() => {
    return content?.room ? getUsersWithAccessToRooms(users, [content?.room]) : [];
  }, [content, users]);

  const onShowMore = useCallback(() => {
    setShowMore(prev => !prev);
  }, []);

  const tags = useMemo(() => content?.tags ?? [], [content]);
  const [limitedTags, remainingTags] = limitTagsAndReturnRest(tags, 40);

  const onChangeSelection = useCallback(
    (checked: boolean) => {
      setSelectedPairIds(checked ? [...selectedPairIds, id] : selectedPairIds.filter(item => item !== id));
    },
    [id, selectedPairIds, setSelectedPairIds]
  );
  const [deleteContentLibraryContent] = useDeleteContentLibrary();

  const onRemoveRows = useCallback(() => {
    confirm.confirm({
      title: t('ContentLibrary.deleteCard.title'),
      okText: t('Common.delete'),
      content: t('ContentLibrary.deleteCard.desc'),
      cancelText: t('Common.cancel'),
      className: modalStyles.confirmModal,
      centered: true,
      onOk() {
        if (content) {
          deleteContentLibraryContent({ ids: [content?.id] });
        }
      }
    });
  }, [content, deleteContentLibraryContent, t]);

  const onUseAnswerCanvas = useCallback(() => {
    if (!editor) return;
    const to = editor.state.selection.to;

    editor
      .chain()
      .focus()
      .insertContentAt(to, content?.answer ?? '')
      .run();

    trackBTUseGeneratedAnswer({ type: 'Q&A' });
  }, [content?.answer, editor]);

  const handleUpdateComplete = useCallback(() => {
    setSelectedPairIds([]);
  }, [setSelectedPairIds]);

  if (!content) return null;

  return (
    <div
      className={classNames(styles.box, {
        [styles.collapsed]: !showMore,
        [styles.fullWidth]: !!isSidebarMode,
        [styles.checkboxSpacing]: !isSidebarMode
      })}
    >
      {!isSidebarMode && !isCanvasTaskSourceMode && content.room.accessToEdit && (
        <Checkbox
          checked={selectedPairIds.includes(id)}
          onChange={e => onChangeSelection(e.target.checked)}
          className={classNames(styles.checkbox, { [styles.isVisible]: selectedPairIds.length })}
        />
      )}
      <div>
        <div className={styles.cardHeader}>
          <h3 className={classNames(styles.question, { [styles.collapsed]: !showMore })}>{content.question}</h3>
          {!isSidebarMode && !isCanvasTaskSourceMode && (
            <Button
              type={'text'}
              className={styles.button}
              icon={<FontAwesomeIcon icon={faTrash} onClick={onRemoveRows} />}
              disabled={!content.room.accessToEdit}
            />
          )}
          {!isSidebarMode && !isCanvasTaskSourceMode && (
            <Button
              type={'text'}
              className={styles.button}
              icon={<FontAwesomeIcon icon={faPen} onClick={() => onEditContentCard?.(content)} />}
              disabled={!content.room.accessToEdit}
            />
          )}
          {isBiddingGPTContentLibrary && (
            <AssignToContentLibrary
              ids={[id]}
              assignedTo={content.assignedTo?.id}
              disabled={!content.room.accessToEdit}
              usersWithAccess={usersWithAccess}
              onUpdateComplete={handleUpdateComplete}
            />
          )}
        </div>
        <p className={classNames(styles.answer, { [styles.collapsed]: !showMore })}>{content.answer}</p>
      </div>
      {!isSidebarMode && (
        <div className={styles.actionBar}>
          <Button type="text" onClick={onShowMore}>
            {showMore ? t('Common.showLess') : t('Common.showMore')}
          </Button>
        </div>
      )}
      <div className={classNames(styles.metaInfoBar)}>
        <div className={styles.metaInfo}>
          <span className={styles.muted}>{`${t('ContentLibrary.room')}:`}</span>
          <span className={styles.roomTitle}>
            <FontAwesomeIcon icon={faCircle} color={toHSLColor(content.room.id)} /> {`${content.room.title} `}
          </span>

          <span>
            {!!limitedTags.length && (
              <>
                <span className={styles.muted}>{`${t('ContentLibrary.tags')}: `}</span>
                {limitedTags.map(tag => (
                  <ContentLibraryTag key={tag.id} tag={tag} disabled={true} />
                ))}
              </>
            )}
            {!!remainingTags.length && (
              <Tooltip
                placement={'bottomRight'}
                title={remainingTags.map(tag => (
                  <ContentLibraryTag key={tag.id} tag={tag} disabled={true} />
                ))}
              >
                <span className={styles.muted}>{`+${remainingTags.length}`}</span>
              </Tooltip>
            )}
          </span>
        </div>
        <div>
          <div className={classNames(styles.metaInfo, styles.metaInfoUser)}>
            <div>
              <span className={styles.muted}>{`${t('ContentLibrary.createdBy')}:`}</span>
              {` ${content.createdBy?.userName} `}
              <span className={styles.muted}>{content.createdAt}</span>
            </div>
            <div>
              <span className={styles.muted}>{`${t('ContentLibrary.editedBy')}:`}</span>
              {` ${content.modifiedBy?.userName} `}
              <span className={styles.muted}>{content.modifiedAt}</span>
            </div>
          </div>
        </div>
        {isSidebarMode && (
          <div className={styles.actionBar}>
            <Button type="link" onClick={onShowMore}>
              {showMore ? t('Common.showLess') : t('Common.showMore')}
            </Button>
            {isCanvasPage && (
              <Button
                type="link"
                onClick={() => {
                  if (isCanvasPage) {
                    onUseAnswerCanvas();
                  }
                }}
                icon={<FontAwesomeIcon icon={faCopy} />}
              >
                {t('ContentLibrary.useAnswer')}
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ContentLibraryCard;
