import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'src/common';
import styles from './index.module.scss';
import { Description } from 'src/shared/index';
import { buyerFilterSchema, TendersSearchSchemaType } from 'src/models/procurements/TendersSearch/schemas';
import { BuyerType } from '@tendium/prom-types';
import { zodParseErrorHandler } from 'src/helpers/zod';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';

const schema = buyerFilterSchema.pick({
  procuringAgencyTypes: true
});

interface Props {
  values: TendersSearchSchemaType;
  onChange: (values: TendersSearchSchemaType) => void;
}

const BuyerTypeFilter: FC<Props> = ({ values, onChange }) => {
  const { t } = useTranslation();

  const onValuesChange = useCallback(
    (values: CheckboxValueType[]) => {
      const parsed = schema.safeParse({ procuringAgencyTypes: values });
      if (!parsed.success) {
        zodParseErrorHandler('BuyerTypeFilter', parsed.error);
        return;
      }
      onChange(parsed.data);
    },
    [onChange]
  );

  const buyerTypeOptions = useMemo(() => {
    return Object.values(BuyerType).map(value => ({
      label: t(`ExpiringContracts.buyerType${value}`),
      value
    }));
  }, [t]);

  return (
    <>
      <Description>{t('ExpiringContracts.buyerTypesWarning')}</Description>
      <form>
        <Checkbox.Group
          value={values.procuringAgencyTypes}
          onChange={onValuesChange}
          options={buyerTypeOptions}
          className={styles.checkboxGroup}
        />
      </form>
    </>
  );
};

export default BuyerTypeFilter;
