import { gql } from '@apollo/client';

export const USER_NAME_FIELDS = gql`
  fragment userNameFields on User {
    email
    name
    lastName
    enabled
    color
    currency
  }
`;
