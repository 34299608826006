import { useCallback } from 'react';
import { ApiContentLibraryUploadRequestBulk } from './types';
import { notification } from 'src/common';
import { useGetSignedUrlUploadBulk } from './hooks';
import { useTranslation } from 'react-i18next';

export function useContentLibrarySignedUrls(): (
  queryVariable: ApiContentLibraryUploadRequestBulk
) => Promise<{ signedUrls: string[]; contentRowIds: string[] } | undefined> {
  const [getSignedUrlBulk] = useGetSignedUrlUploadBulk();
  const { t } = useTranslation();
  return useCallback(
    async (
      queryVariable: ApiContentLibraryUploadRequestBulk
    ): Promise<{ signedUrls: string[]; contentRowIds: string[] } | undefined> => {
      try {
        const response = await getSignedUrlBulk({
          variables: { input: queryVariable }
        });

        const signedUrls = response?.data?.getSignedUrlUploadBulk?.urls?.map(item => item.putUrl);
        const contentRowIds = response?.data?.getSignedUrlUploadBulk?.urls?.map(item => item.contentRowId);

        if (!signedUrls || !contentRowIds) {
          return;
        }

        return { signedUrls, contentRowIds };
      } catch (err) {
        notification.error({
          description: t('Common.unknownErrorDesc'),
          message: t('Common.unknownError')
        });
        return;
      }
    },
    [getSignedUrlBulk, t]
  );
}
