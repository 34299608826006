import React, { FC, useMemo } from 'react';
import { Button, ConditionalWrapper, Menu, Skeleton, Tooltip } from 'src/common';
import { faEye, faChartSimple } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import previewStyles from 'src/shared/InfoSidebar/index.module.scss';
import { getCanvasURL } from 'src/models/canvas/helpers';
import { BiddingToolTab, TasksGenerationJobStatus } from 'src/models/bids/BidFull/types';
import { useNavigate, useLocation } from 'react-router';
import { useBidding } from 'src/models/bids/BidFull/hooks';
import { useBidUrl } from 'src/models/procurements/hooks';
import { useTranslation } from 'react-i18next';
import { FeatureFlag, useFeatureFlag } from 'src/helpers';
import { useBidPreview } from 'src/models/bids/BidPreview/hooks';
import { trackBTOpenEditor } from 'src/segment/events';
import { faPen } from '@fortawesome/pro-regular-svg-icons';

export const BidNav: FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { hash } = useLocation();
  const currentHash = useMemo(() => (!!hash ? hash.slice(1) : null), [hash]);

  const { data: biddingData, loading: biddingDataLoading } = useBidding();
  const { data: bidPreview, loading: bidPreviewLoading } = useBidPreview();

  const loading = biddingDataLoading || bidPreviewLoading;

  const bidId = useMemo(() => biddingData?.id, [biddingData?.id]);
  const groupsWithTasks = useMemo(
    () => biddingData?.questionGroups.filter(group => group.totalTasks && group.totalTasks > 0),
    [biddingData?.questionGroups]
  );
  const bidHref = useBidUrl(bidId || '');
  const isTaskGenerationInProgress = bidPreview?.tasksGenerationStatus === TasksGenerationJobStatus.IN_PROGRESS;
  const isBidFlowFeature = useFeatureFlag(FeatureFlag.BiddingWorkflow_Tasks);

  const isEditorButtonDisabled = useMemo(
    () => !groupsWithTasks?.length || isTaskGenerationInProgress,
    [groupsWithTasks, isTaskGenerationInProgress]
  );

  return loading ? (
    <Skeleton active paragraph={{ rows: 1 }} />
  ) : (
    <div className={previewStyles.container}>
      <Menu
        mode={'horizontal'}
        selectedKeys={[currentHash ?? BiddingToolTab.Overview]}
        className={previewStyles.navTabs}
      >
        <Menu.Item
          key={BiddingToolTab.Overview}
          className={previewStyles.navTab}
          onClick={() => navigate(`${bidHref}#${BiddingToolTab.Overview}`)}
        >
          <span className={previewStyles.navTab}>
            <FontAwesomeIcon icon={faEye} className={previewStyles.navTabIcon} />
            <span className={previewStyles.navTabLabel}>{t('BiddingTool.overview')}</span>
          </span>
        </Menu.Item>
        {isBidFlowFeature && (
          <Menu.Item
            key={BiddingToolTab.Tasks}
            className={classNames(previewStyles.navTab)}
            onClick={() => navigate(`${bidHref}#${BiddingToolTab.Tasks}`)}
          >
            <span className={previewStyles.navTab}>
              <FontAwesomeIcon icon={faChartSimple} className={previewStyles.navTabIcon} />
              <span className={previewStyles.navTabLabel}>{t('BiddingTool.tasksTitle')}</span>
            </span>
          </Menu.Item>
        )}
      </Menu>
      {isBidFlowFeature && (
        <ConditionalWrapper
          condition={isEditorButtonDisabled}
          wrapper={children => (
            <Tooltip title={t('BiddingTool.openEditorDisabled')}>
              <span>{children}</span>
            </Tooltip>
          )}
        >
          <Button
            type={'primary'}
            onClick={() => {
              if (!groupsWithTasks?.length) return;
              trackBTOpenEditor({ eventSource: 'Overview' });
              const url = getCanvasURL(bidId ?? '', groupsWithTasks ? groupsWithTasks[0].id : '');
              url && navigate(url);
            }}
            disabled={isEditorButtonDisabled}
          >
            <FontAwesomeIcon icon={faPen} />
            {t('BiddingTool.openEditor')}
          </Button>
        </ConditionalWrapper>
      )}
    </div>
  );
};

export default BidNav;
