import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { IBoxFieldNumber } from 'src/models/procurements/Tender/types';
import BlockEmpty from '../../Empty';
import { IBlockReadProps } from 'src/shared/Tender/Boxes/types';

interface Props extends IBlockReadProps, Pick<IBoxFieldNumber, 'number' | 'unit'> {}
export const ReadOnlyBlockNumber: FC<Props> = ({ unit, number, className }) => {
  const { t } = useTranslation();

  return (
    <div className={className}>
      <span className={styles.block}>
        {number ? (
          <>
            {`${t('Common.Format.formatNumber', { number })} `}
            {!!unit ? `${t(`Tenders.BoxFieldRangeUnit.${unit}`, { count: number ?? 1 })}` : ''}
          </>
        ) : (
          <BlockEmpty />
        )}
      </span>
    </div>
  );
};

export default ReadOnlyBlockNumber;
