import { ApolloCache, gql, MutationUpdaterFn, Reference } from '@apollo/client';
import { BidItemType, WorkspaceStatusCategory } from '@tendium/prom-types/tender';
import {
  IBidIdentifier,
  IBidsCached,
  ICreateBidsResponse,
  IUpdateBidStatusResponse,
  IUpdateBidsWsResponse
} from './types';
import { evictBidStatisticsCache, subsTotalBidsValue, sumTotalBidsValue } from './helpers';
import { notUndefined } from 'src/helpers/typescript';
import { ITendersCached } from '../../procurements/types';
import { ApiTendersSearchInput } from '../../procurements/Tenders/types';
import { ISearchBidsRequest } from './types';
import { BidCacheUpdateValues } from '../BidPreview/types';
import { BID_CACHE_UPDATE_FIELDS } from '../BidPreview/queries';
import { EmailStatus } from '../../callOffs/types';

export function clearWsStatsCache(cache: ApolloCache<unknown>, wsId: string): void {
  cache.modify({
    fields: {
      getWorkspaceStats(cached, { storeFieldName, DELETE }) {
        return storeFieldName.includes(wsId) ? DELETE : cached;
      }
    }
  });
  cache.gc();
}

/**
 *
 *  Remove handled procurements from unhandled view
 */
export function modifyMpUnhandledTendersCache(
  cache: ApolloCache<unknown>,
  queryName: string,
  handledIds: string[],
  mpId?: string
): void {
  if (!handledIds.length || !mpId) {
    return;
  }
  cache.modify({
    fields: {
      [queryName](cached: ITendersCached, { storeFieldName, fieldName, readField }) {
        const input: ApiTendersSearchInput = JSON.parse(storeFieldName.replace(`${fieldName}:`, ''));
        if (!input.query || input.query.matchingProfileId !== mpId || !input.query.isUnhandled) return cached;

        return {
          ...cached,
          count: cached.count - handledIds.length,
          procurementsWithScore: [...cached.procurementsWithScore].filter(
            proc => proc && !handledIds.some(id => id === readField({ fieldName: 'id', from: proc.procurement }))
          )
        };
      }
    }
  });
}

export function getCurrentBidFromCache(bidId: string, cache: ApolloCache<unknown>): BidCacheUpdateValues | null {
  const bidRef = cache.identify({
    __typename: 'BidV2',
    id: bidId
  });

  return cache.readFragment<BidCacheUpdateValues>({
    id: bidRef,
    fragment: BID_CACHE_UPDATE_FIELDS
  });
}

export function updateCacheOnUpdateBidsWs(
  wsId: string,
  bidIds: IBidIdentifier[],
  rejectedView: boolean
): MutationUpdaterFn<IUpdateBidsWsResponse> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }

    evictBidStatisticsCache(cache);

    const { updateBidsWorkspace: updatedBids } = data;
    if (!updatedBids) {
      return;
    }

    const notInWsBidIds = bidIds.filter(bidIdObj => bidIdObj.wsId !== wsId);

    if (!notInWsBidIds.length) {
      return;
    }

    bidIds.forEach(bid => {
      const currentBid = getCurrentBidFromCache(bid.id, cache);

      cache.modify({
        fields: {
          getBids(cached: IBidsCached, { storeFieldName, toReference, fieldName, readField }) {
            const isWorkspaceFilterApplied = storeFieldName.includes('workspaceIds');
            const prevWsId = bid.wsId;
            if (!isWorkspaceFilterApplied) return cached;
            if (!currentBid) return cached;

            const queryInput: { input?: ISearchBidsRequest } = JSON.parse(storeFieldName.replace(`${fieldName}:`, ''));
            if (!queryInput.input || !notUndefined(queryInput.input.rejected)) return cached;
            if (
              prevWsId &&
              storeFieldName.includes(prevWsId) &&
              currentBid.workspace.id &&
              storeFieldName.includes(currentBid.workspace.id)
            )
              return cached;

            if (currentBid.workspace.id && storeFieldName.includes(currentBid.workspace.id)) {
              if (queryInput.input.rejected !== rejectedView) {
                return {
                  ...cached,
                  totalBids: sumTotalBidsValue(cached.totalBids, 1)
                };
              }

              if (
                (storeFieldName.includes('stageIds') && storeFieldName.includes(currentBid.bidStageId)) ||
                !storeFieldName.includes('stageIds')
              ) {
                const isNotInCache = (bidIdentifier: IBidIdentifier): boolean =>
                  !cached.bids.map(bidRef => readField('id', bidRef)).includes(bidIdentifier.id);

                return {
                  ...cached,
                  total: cached.total + 1,
                  totalBids: sumTotalBidsValue(cached.totalBids, 1),

                  bids: [
                    ...cached.bids,
                    ...notInWsBidIds.filter(isNotInCache).map(bid => {
                      return toReference({
                        __typename: 'BidV2',
                        id: bid.id
                      });
                    })
                  ]
                };
              }
            }

            if (prevWsId && storeFieldName.includes(prevWsId)) {
              if (queryInput.input.rejected !== rejectedView) {
                return {
                  ...cached,
                  totalBids: subsTotalBidsValue(cached.totalBids, 1)
                };
              }
              return {
                ...cached,
                total: cached.total - (cached.bids.length - 1),
                totalBids: subsTotalBidsValue(cached.totalBids, 1),
                bids: [...cached.bids].filter(b => readField('id', b) !== bid.id)
              };
            }
            return cached;
          }
        }
      });
    });

    clearWsStatsCache(cache, wsId);
  };
}

export function updateCacheOnCreateBids(
  originIds: string[],
  originType: BidItemType,
  wsId: string,
  mpId?: string
): MutationUpdaterFn<ICreateBidsResponse> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }

    evictBidStatisticsCache(cache);

    let originIdsData = originIds;
    const { createBids: newBids } = data;
    if (originType === BidItemType.Manual) {
      originIdsData = newBids.map(bid => bid.itemId);
    }
    let origins: string[] = [];
    const modifyTenderCache = (originId: string, typename: string): void => {
      const newBid = newBids.find(bid => bid.itemId === originId);
      origins = newBid?.itemId ? Array.from(new Set([...origins, newBid?.itemId])) : origins;
      cache.modify({
        id: cache.identify({ __typename: typename, id: originId }),
        fields: {
          bids(existingRefs: Reference[], { toReference, readField }) {
            return !newBid || existingRefs.some(ref => readField('id', ref) === newBid.id)
              ? existingRefs
              : [...existingRefs, toReference({ __typename: 'BidV2', id: newBid.id })];
          }
        }
      });
    };
    originIdsData.forEach(originId => {
      if (originType === BidItemType.Procurement) {
        modifyTenderCache(originId, 'Tender');
        modifyTenderCache(originId, 'OCDSTender');
      }
      if (originType === BidItemType.CallOff) {
        const callOffRef = cache.identify({
          __typename: 'UserMail',
          mailId: originId
        });
        cache.modify({
          id: callOffRef,
          fields: {
            bidsV2(existingRefs: Reference[], { toReference, readField }) {
              const newBid = data.createBids.find(bid => bid.itemId === originId);
              return !newBid || existingRefs.some(ref => readField('id', ref) === newBid.id)
                ? existingRefs
                : [
                    ...existingRefs,
                    toReference({
                      __typename: 'BidV2',
                      id: newBid.id
                    })
                  ];
            },
            mailStatus() {
              return EmailStatus.INTERESTING;
            }
          }
        });
      }
    });

    // TODO: ideally we should filter bids references also by origin contained some search match
    // storeFieldName.includes(`search`)

    cache.modify({
      fields: {
        getBids(cached: IBidsCached, { storeFieldName, fieldName, readField, toReference }) {
          if (!storeFieldName.includes(wsId) && storeFieldName.includes('workspaceIds')) return cached;
          if (
            (newBids[0].status.id && storeFieldName.includes(newBids[0].status.id)) ||
            !storeFieldName.includes('stageIds')
          ) {
            const queryInput: { input?: ISearchBidsRequest } = JSON.parse(storeFieldName.replace(`${fieldName}:`, ''));

            if (!queryInput.input || !notUndefined(queryInput.input.rejected)) return cached;

            const isGetRejectedBidsQuery = queryInput.input.rejected;

            if (isGetRejectedBidsQuery) {
              return {
                ...cached,
                totalBids: sumTotalBidsValue(cached.totalBids, originIds.length)
              };
            } else {
              const newBidsRefs = newBids.map(newBid =>
                toReference({
                  __typename: 'BidV2',
                  id: newBid.id
                })
              );
              const bidsToAdd =
                cached.bids && [...cached.bids].length
                  ? newBidsRefs.filter(
                      newBidsRef =>
                        newBidsRef &&
                        ![...cached.bids].some(
                          bid =>
                            readField({ fieldName: 'id', from: bid }) ===
                            readField({ fieldName: 'id', from: newBidsRef })
                        )
                    )
                  : newBidsRefs;
              return {
                ...cached,
                total: cached.total + bidsToAdd.length,
                totalBids: sumTotalBidsValue(cached.totalBids, bidsToAdd.length),
                bids: [...cached.bids, ...bidsToAdd]
              };
            }
          } else {
            return cached;
          }
        }
      }
    });

    modifyMpUnhandledTendersCache(cache, 'getSimpleTenders', origins, mpId);
    modifyMpUnhandledTendersCache(cache, 'getTenders', origins, mpId);

    clearWsStatsCache(cache, wsId);
  };
}

export function updateCacheOnUpdateBidStatus(input: {
  wsId: string;
  bidId: string;
  rejectedView: boolean;
  currentStatusId: string;
  prevStatusId?: string;
}): MutationUpdaterFn<IUpdateBidStatusResponse> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }

    evictBidStatisticsCache(cache);

    const { wsId, bidId, rejectedView, prevStatusId, currentStatusId } = input;
    const bidRef = cache.identify({
      __typename: 'BidV2',
      id: bidId
    });

    const prevWorkspaceStatus = cache.identify({ id: prevStatusId, __typename: 'WorkspaceStatus' });

    const prevStatusCategory = cache.readFragment<{ category: WorkspaceStatusCategory }>({
      id: prevWorkspaceStatus,
      fragment: gql`
        fragment fields on WorkspaceStatus {
          category
        }
      `
    });

    const currentBid = getCurrentBidFromCache(bidId, cache);

    if ((currentStatusId || prevStatusCategory?.category) && bidRef) {
      cache.modify({
        fields: {
          getBids(cached: IBidsCached, { storeFieldName, toReference, readField, fieldName }) {
            const queryInput: { input?: ISearchBidsRequest } = JSON.parse(storeFieldName.replace(`${fieldName}:`, ''));

            if (!queryInput.input || !notUndefined(queryInput.input.rejected)) return cached;

            const queryOnCurrentView = queryInput.input.rejected === rejectedView;

            if (
              storeFieldName.includes(currentStatusId) ||
              (currentBid && storeFieldName.includes(currentBid.status.category))
            ) {
              if (queryOnCurrentView) {
                return {
                  ...cached,
                  total: cached.total + 1,
                  totalBids: sumTotalBidsValue(cached.totalBids, 1),
                  bids: [...cached.bids, toReference(bidRef)]
                };
              } else {
                return { ...cached, totalBids: sumTotalBidsValue(cached.totalBids, 1) };
              }
            } else if (
              (prevStatusId && storeFieldName.includes(prevStatusId)) ||
              (prevStatusCategory && storeFieldName.includes(prevStatusCategory.category))
            ) {
              const newBids = [...cached.bids].filter(
                bid => bid && bidId !== readField({ fieldName: 'id', from: bid })
              );

              if (queryOnCurrentView) {
                return {
                  ...cached,
                  total: cached.total - 1,
                  totalBids: subsTotalBidsValue(cached.totalBids, 1),
                  bids: newBids
                };
              } else {
                return {
                  ...cached,
                  totalBids: subsTotalBidsValue(cached.totalBids, 1)
                };
              }
            } else {
              return cached;
            }
          }
        }
      });
    }

    clearWsStatsCache(cache, wsId);
  };
}
