import React, { FC, ReactNode } from 'react';
import { IIcon } from 'src/types/icon';
import Title from './Title';
import Description from './Description';

interface Props {
  title?: ReactNode;
  description?: ReactNode;
  icon?: IIcon;
  actions?: ReactNode;
  className?: string;
  badge?: ReactNode;
  noBorder?: boolean;
}
export const BlockTitle: FC<Props> = ({ title, description, icon, actions, className, badge, noBorder }) => {
  return (
    <>
      <Title className={className} icon={icon} badge={badge} noBorder={noBorder}>
        <span>{title}</span>
        {!!actions && actions}
      </Title>
      {description ? <Description>{description}</Description> : null}
    </>
  );
};

export default BlockTitle;
