import React, { FC, useMemo } from 'react';
import { CommentParentType } from 'src/models/comments/types';
import { useBidding } from 'src/models/bids/BidFull/hooks';
import { Comments } from 'src/shared';
import { useWsUsersAndTeams } from 'src/models/workspace/hooks';
import { BidItemType } from '@tendium/prom-types/tender';
import { useTranslation } from 'react-i18next';
import ToolboxHeader from '../ToolboxHeader';
import { TrackEventSource } from 'src/segment/events/types';

interface Props {
  bidId: string;
  eventSource: TrackEventSource;
}

export const BidComments: FC<Props> = props => {
  const { bidId, eventSource } = props;
  const { t } = useTranslation();

  const { data } = useBidding();

  const comments = useMemo(() => {
    return data?.comments || [];
  }, [data]);

  const users = useWsUsersAndTeams(data?.workspaceId);

  return (
    <>
      <ToolboxHeader title={t('Comments.comments')} />
      <Comments
        comments={comments}
        parentId={bidId}
        parentType={
          data?.originType === BidItemType.CallOff
            ? CommentParentType.CallOffBid
            : data?.originType === BidItemType.Manual
            ? CommentParentType.ManualBid
            : CommentParentType.ProcurementBid
        }
        users={users}
        eventSource={eventSource}
      />
    </>
  );
};

export default BidComments;
