import { Dispatch, SetStateAction, createContext } from 'react';
import { ContentLibraryAllResponseData } from './types';
import { Content } from '.';

export type ContentLibraryContextValue = {
  question?: string;
  offset: number;
  limit: number;
  data?: ContentLibraryAllResponseData;
  setOffset: (offset: number) => void;
  selectedPairIds: string[];
  setSelectedPairIds: Dispatch<SetStateAction<string[]>>;
  selectedContent: Content | undefined;
  setSelectedContent: Dispatch<SetStateAction<Content | undefined>>;
  showContentCardModal: boolean;
  setShowContentCardModal: Dispatch<SetStateAction<boolean>>;
};

export const ContentLibraryContext = createContext<ContentLibraryContextValue | null>(null);
ContentLibraryContext.displayName = 'ContentLibraryContext';
