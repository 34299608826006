import React, { ReactNode, FC, useMemo, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { BiddingContext, GroupTasksContext, GroupTasksFilterContext, UpdateGroupTasksFilterContext } from './context';
import { Bidding } from '.';
import { useApiBidding, useApiBiddingStats, useLoadTasks, useTasksFilter } from './hooks';
import { GROUP_TASKS_LIMIT, TasksFilterArgs, UseLoadTasks } from './types';
import { BidTask } from '../BidTask';
import { getTaskFiltersFromQueryParams } from './helpers';

interface Props {
  children: ReactNode;
}

export const BiddingDataProvider: FC<Props> = ({ children }) => {
  const { id: bidId } = useParams<{ id: string }>();
  const { data: rawData, loading } = useApiBidding(bidId);
  const { data: statsData, loading: statsLoading } = useApiBiddingStats(bidId);
  const data = useMemo(
    () => (rawData?.getBid ? new Bidding(rawData.getBid, statsData?.getQuestionGroupsStatistics) : null),
    [rawData, statsData]
  );
  const contextValue = useMemo(() => ({ data, loading, statsLoading }), [data, loading, statsLoading]);

  return <BiddingContext.Provider value={contextValue}>{children}</BiddingContext.Provider>;
};

export const GroupTasksFilterProvider: FC<Props> = ({ children }) => {
  const qpFilters = getTaskFiltersFromQueryParams();
  const [filter, setFilter] = useState<TasksFilterArgs | undefined>(qpFilters);

  return (
    <UpdateGroupTasksFilterContext.Provider value={setFilter}>
      <GroupTasksFilterContext.Provider value={filter}>{children}</GroupTasksFilterContext.Provider>
    </UpdateGroupTasksFilterContext.Provider>
  );
};

export const BiddingProvider: FC<Props> = ({ children }) => {
  return (
    <GroupTasksFilterProvider>
      <BiddingDataProvider>{children}</BiddingDataProvider>
    </GroupTasksFilterProvider>
  );
};

interface TasksProviderProps extends Props, Pick<UseLoadTasks, 'groupId' | 'skip'> {}

export const TasksProvider: FC<TasksProviderProps> = ({ children, groupId, skip }) => {
  const { filter: filters } = useTasksFilter();
  const [tasksOffset, setTasksOffset] = useState<number>(0);
  const tasksQuery = useLoadTasks({ groupId, skip, isSubmitted: true });

  const [selectedTaskIds, setSelectedTaskIds] = useState<string[]>([]);

  const tasksMap = useMemo(() => {
    const tasksMap = new Map<string, BidTask>();
    const tasks = tasksQuery.data?.tasks;

    tasks?.forEach(item => item && tasksMap.set(item.id, item));

    return tasksMap;
  }, [tasksQuery]);
  const selectedTasks = useMemo(() => {
    const selectedTasks: BidTask[] = [];
    for (const id of selectedTaskIds) {
      if (tasksMap.has(id)) {
        selectedTasks.push(tasksMap.get(id) as BidTask);
      }
    }
    return selectedTasks;
  }, [tasksMap, selectedTaskIds]);

  useEffect(() => {
    setTasksOffset(0);
  }, [filters]);

  const value = useMemo(
    () => ({
      limit: GROUP_TASKS_LIMIT,
      offset: tasksOffset,
      groupId,
      tasksResponseData: tasksQuery,
      setOffset: setTasksOffset,
      selectedTasks,
      setSelectedTaskIds
    }),
    [groupId, selectedTasks, tasksOffset, tasksQuery]
  );

  return <GroupTasksContext.Provider value={value}>{children}</GroupTasksContext.Provider>;
};
