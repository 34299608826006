import { gql } from '@apollo/client/core';
import { USER_NAME_FIELDS } from 'src/models/users/queries';
import { COMMENT_FIELDS } from '../../comments/queries';
import { ASSIGNED_TO_FIELDS } from 'src/models/users/AssignedTo/queries';

export const ATTACHMENT_FIELDS = gql`
  fragment attachmentFields on ListAttachmentsResultDTO {
    list {
      parentId
      fileName
      loading @client
    }
  }
`;

export const DOCUMENT_COORDINATES_FIELDS = gql`
  fragment documentCoordinatesFields on DocumentPosition {
    page
    start {
      x
      y
    }
    end {
      x
      y
    }
    pageSize {
      width
      height
    }
  }
`;

export const TASK_DOCUMENT_FIELDS = gql`
  fragment questionDocumentFields on Document {
    content
    headline
    path
    name
    contentPosition {
      ...documentCoordinatesFields
    }
    headlinePosition {
      ...documentCoordinatesFields
    }
  }
  ${DOCUMENT_COORDINATES_FIELDS}
`;

export const TASK_GROUP_FIELDS = gql`
  fragment questionGroupFields on ProcurementBidQuestionGroup {
    id
    title
    bid {
      id
      itemId
      itemType
    }
    filePath
  }
`;

export const TASK_FIELDS = gql`
  fragment questionFields on ProcurementBidQuestion {
    id
    title
    assignedTo {
      ...assignedToFields
    }
    question
    requirementType
    classificationId
    fulfilment
    content
    status
    isSubmitted
    primaryReferences {
      ...questionDocumentFields
    }
    secondaryReferences {
      ...questionDocumentFields
    }
    deadline
    aiSummary
    questionGroup {
      ...questionGroupFields
    }
    document {
      ...questionDocumentFields
    }
    answer {
      id
      answer
      content
      richContent
      sources {
        id
      }
      pendingContent
      pendingSources {
        id
      }
      attachments {
        ...attachmentFields
      }
    }
    comments {
      ...commentFields
    }
    rank
  }
  ${ATTACHMENT_FIELDS}
  ${USER_NAME_FIELDS}
  ${TASK_GROUP_FIELDS}
  ${COMMENT_FIELDS}
  ${TASK_DOCUMENT_FIELDS}
  ${ASSIGNED_TO_FIELDS}
`;

export const TASK_ANSWER_FIELDS = gql`
  fragment taskAnswerFields on BidQuestionAnswer {
    id
    answer
    content
    richContent
    sources {
      id
    }
    pendingContent
    pendingSources {
      id
    }
    attachments {
      ...attachmentFields
    }
  }
  ${ATTACHMENT_FIELDS}
`;

export const CREATE_TASKS = gql`
  mutation CreateBidQuestions($input: CreateBidQuestionsInput!) {
    createBidQuestions(input: $input) {
      ...questionFields
    }
  }
  ${TASK_FIELDS}
`;

export const UPDATE_TASK = gql`
  mutation updateBidTask(
    $questionIds: [String!]!
    $groupId: String!
    $bidId: String
    $title: String
    $content: String
    $requirementType: RequirementType
    $fulfilment: TaskFulfillment
    $assignedTo: String
    $status: QuestionStatus
    $deadline: Float
    $ranks: [String!]
  ) {
    updateBidQuestions(
      input: {
        questionIds: $questionIds
        questionGroupId: $groupId
        bidId: $bidId
        title: $title
        content: $content
        requirementType: $requirementType
        fulfilment: $fulfilment
        assignedTo: $assignedTo
        status: $status
        deadline: $deadline
        ranks: $ranks
      }
    ) {
      ...questionFields
    }
  }
  ${TASK_FIELDS}
`;
export const DELETE_TASK = gql`
  mutation deleteBidQuestions($questionIds: [String!]!, $groupId: String!) {
    deleteBidQuestions(input: { questionIds: $questionIds, questionGroupId: $groupId })
  }
`;

export const SET_TASK_ANSWER = gql`
  mutation setBidQuestionAnswer($questionId: String!, $questionGroupId: String!, $answer: AnswerInput!) {
    setBidQuestionAnswer(input: { questionId: $questionId, questionGroupId: $questionGroupId, answer: $answer }) {
      ...taskAnswerFields
    }
  }
  ${TASK_ANSWER_FIELDS}
`;

export const GET_TASK = gql`
  query getTask($taskId: String!) {
    getBidQuestion(input: { questionId: $taskId }) {
      ...questionFields
    }
  }
  ${TASK_FIELDS}
`;

export const GET_TASK_GROUP = gql`
  query getTaskGroup($taskId: String!) {
    getBidQuestion(input: { questionId: $taskId }) {
      id
      questionGroup {
        id
        title
        filePath
        bid {
          id
        }
      }
    }
  }
`;
