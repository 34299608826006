import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { EmptyFilter, List, Paging, Skeleton, Spinner, useLanguage } from 'src/common';
import { useForecastBids } from 'src/models/bids/Bids/hooks';
import { DashboardCard } from '../../DashboardCard';
import styles from './index.module.scss';
import { faSparkles } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';
import BidListItem from '../../BidListItem';
import {
  BidsDashboardCardMode,
  DASHBOARD_TABLE_PAGE_SIZE,
  ForecastBidChartStackState
} from 'src/models/bids/Bids/types';
import { BidCategoryBadge } from 'src/common/BidCategoryBadge';
import { formatDate } from 'src/helpers/dates';
import { formatCurrencyValue } from 'src/models/procurements/Tender/helpers';
import { isPageCached } from 'src/lib/API/graphql/helpers';

export interface Props {
  chartState: ForecastBidChartStackState;
}

export const ForecastBidsCard: FC<Props> = ({
  chartState: { contractStartRange, statusCategory, amount, currency, count, date }
}) => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const [offset, setOffset] = useState(0);
  const limit = DASHBOARD_TABLE_PAGE_SIZE;

  // Clear offset on new stack selection
  useEffect(() => {
    setOffset(0);
  }, [contractStartRange, statusCategory, date]);

  const { fetchMore, data, loading, fetchingMore } = useForecastBids(
    contractStartRange,
    {
      amount: limit,
      offset,
      statusCategories: [statusCategory]
    },
    !contractStartRange.start && !contractStartRange.end
  );

  const bids = useMemo(() => (data ? data.bids : []), [data]);

  const currentBids = useMemo(() => bids.slice(offset, offset + limit), [bids, offset, limit]);

  const currentPage = useMemo(() => {
    return offset / limit + 1;
  }, [offset, limit]);

  const onChange = useCallback(
    (page: number) => {
      const newOffset = (page - 1) * limit;
      setOffset(newOffset);

      if (!isPageCached(bids, newOffset, limit)) {
        fetchMore?.({ variables: { offset: newOffset } });
      }
    },
    [bids, fetchMore, limit]
  );

  if (loading) return <Skeleton active loading />;

  if (!bids || bids.length === 0)
    return <EmptyFilter icon={faSparkles} desc={t('BidSpaces.Dashboard.EmptyFilter.desc')} />;

  return (
    <DashboardCard className={styles.container}>
      <div className={styles.header}>
        {formatDate(date, 'month', { showOnlyMonthName: true })} <BidCategoryBadge status={statusCategory} />
      </div>
      <div className={styles.desc}>
        <span className={styles.count}>
          {count} {count === 1 ? t('BidSpaces.bid').toLowerCase() : t('BidSpaces.bids')}
        </span>
        <span className={styles.estValue}>({formatCurrencyValue(amount as number, language, currency)})</span>
      </div>
      <List items={currentBids} loading={loading}>
        {bid => bid && <BidListItem bid={bid} mode={BidsDashboardCardMode.forecastByContractStart} />}
      </List>

      <Paging
        defaultCurrent={currentPage}
        current={currentPage}
        onChange={onChange}
        pageSize={limit}
        total={data?.total}
        className={styles.paging}
        itemRender={
          fetchingMore
            ? (page, type, originalElement) => (page === currentPage && type === 'page' ? <Spinner /> : originalElement)
            : undefined
        }
      />
    </DashboardCard>
  );
};

export default ForecastBidsCard;
