import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';
import Tooltip from '../Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';

interface Props {
  title?: string;
  className?: string;
  icon?: IconDefinition;
  tooltip?: string | ReactNode;
  theme: 'rejected' | 'cancelled' | 'awarded' | 'info';
}

export const BaseBadge: FC<Props> = props => {
  const { className, title, icon, tooltip, theme } = props;

  return (
    <Tooltip title={tooltip}>
      <span className={classNames(styles.badge, styles[theme], className)}>
        {icon && <FontAwesomeIcon icon={icon} className={styles.icon} />}
        {title && <span className={styles.title}>{title}</span>}
      </span>
    </Tooltip>
  );
};

export default BaseBadge;
