import { Editor } from '@tiptap/core';
import { scrollToItem } from 'src/helpers/scrollToElement';

const scrollToEditorElement = (editor: Editor | null, shouldScroll: boolean, elementId: string): void => {
  if (!shouldScroll || !editor) return;

  const element = document.getElementById(elementId);
  if (!element) return;

  const rect = element.getBoundingClientRect();
  const isVisible =
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth);

  editor.chain().focus().run();

  if (!isVisible) {
    requestAnimationFrame(() => scrollToItem(elementId, 'smooth', 'end'));
  }
};

export default scrollToEditorElement;
