import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Switch, UserPicker } from 'src/common/index';
import ACUserItem from 'src/common/AccessControlItem/components/ACUserItem';
import styles from './index.module.scss';
import { AccessControlPolicy, IMember } from 'src/models/acl/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons';

export enum Mode {
  Owners,
  Subscribers
}

interface Props {
  readOnly: boolean;
  membersOptions: IMember[];
  members: IMember[];
  onUpdate: (members: string[]) => void;
  onMove: (email: string, isOwner?: boolean) => void;
  mode: Mode;
  policy?: AccessControlPolicy;
  onChangePolicy?: (policy: AccessControlPolicy) => void;
  myUserId?: string;
  blueTheme?: boolean;
  description: string;
  policyDescription: string;
  disabled?: boolean;
}

export const AccessControlItem: FC<Props> = props => {
  const {
    membersOptions,
    members,
    onUpdate,
    mode,
    onMove,
    readOnly,
    myUserId,
    policy,
    onChangePolicy,
    policyDescription,
    description,
    disabled
  } = props;
  const { t } = useTranslation();

  const currentMembersIds = useMemo(() => members.map(member => member.user.id), [members]);
  const memberOptionsUsers = useMemo(() => membersOptions.map(member => member.user), [membersOptions]);

  const onAddEverybodyToSubscribers = useCallback(
    (checked: boolean) => {
      if (!onChangePolicy) return;
      if (checked) {
        onChangePolicy(AccessControlPolicy.ALL);
      } else {
        onChangePolicy(AccessControlPolicy.ONLY_ADDED);
      }
    },
    [onChangePolicy]
  );

  const onSelect = (user: { id: string }): void => {
    const arr = Array.from(new Set([...currentMembersIds, user.id]));
    onUpdate(arr);
  };

  const onRemoveMember = useCallback(
    (memberId: string) => {
      const arr = currentMembersIds.filter(id => id !== memberId);
      onUpdate(arr);
    },
    [currentMembersIds, onUpdate]
  );

  const onMoveMember = useCallback(
    (email: string) => {
      onMove(email, mode === Mode.Owners);
    },
    [mode, onMove]
  );

  const isPolicyAll = useMemo(() => {
    return policy === AccessControlPolicy.ALL;
  }, [policy]);

  return (
    <Form className={styles.unit}>
      {readOnly && (
        <>
          <div className={styles.title}>{t(mode === Mode.Owners ? 'Common.ACL.owners' : 'Common.ACL.haveAccess')}</div>
          {mode === Mode.Subscribers && isPolicyAll && (
            <span className={styles.switchLabel}>{t('Common.ACL.everybodyAsSubscribers')}</span>
          )}
        </>
      )}

      {!readOnly && (
        <>
          <Form.Item
            label={t(mode === Mode.Owners ? 'Common.ACL.owners' : 'Common.ACL.haveAccess')}
            tooltip={{
              title: description,
              icon: <FontAwesomeIcon icon={faQuestionCircle} />
            }}
          >
            <UserPicker
              renderMode="input"
              users={memberOptionsUsers}
              label={t('Common.search')}
              selected={'null'}
              userPickerType="single"
              onSelect={onSelect}
              disabled={isPolicyAll || disabled}
            />
          </Form.Item>
          <div className={styles.actions}>
            <div className={styles.switchUnit}>
              <Switch className={styles.switch} checked={isPolicyAll} onChange={onAddEverybodyToSubscribers} />
              <span className={styles.switchLabel}>{policyDescription}</span>
            </div>
          </div>
        </>
      )}

      {!isPolicyAll &&
        !disabled &&
        members.map(member => (
          <ACUserItem
            readOnly={readOnly}
            isMe={member.user.id === myUserId}
            canNotBeDeleted={
              mode === Mode.Owners && (members.length === 1 || member.user.id === myUserId || member.isOwner)
            }
            isOwner={mode === Mode.Owners}
            onRemove={onRemoveMember}
            onMove={onMoveMember}
            user={member.user}
            key={member.user.id}
          />
        ))}
    </Form>
  );
};

export default AccessControlItem;
