import { KeywordWidth } from '@tendium/prom-types';
import { trackWithSessionReplay } from '../helpers';

type KeywordsTrackPickerMode = 'single' | 'bulk';

export function trackSwitchMatchingProfile(mp: { id: string; name: string }): void {
  trackWithSessionReplay('Switch matching profile', { mp });
}

// MP: edit monitoring profile
export function trackMPEditMonitoringProfile(mp: { id: string; name: string }): void {
  trackWithSessionReplay('MP edit monitoring profile', { mp });
}

// MP/EC: edit keyword shortcut
export function trackMPEditKeywordShortcut(
  eventNamePrefix: string,
  eventsource: string,
  type: 'single' | 'group',
  mp?: { id: string; name: string }
): void {
  trackWithSessionReplay(`${eventNamePrefix} edit keyword shortcut`, {
    eventsource,
    type,
    mp
  });
}

// MP/EC: edit add keywords
export function trackMPEditAddKeywords(
  eventNamePrefix: string,
  eventsource: string,
  type: 'single' | 'group',
  mp?: { id: string; name: string }
): void {
  trackWithSessionReplay(`${eventNamePrefix} edit add keywords`, {
    eventsource,
    type,
    mp
  });
}

// MP/EC: edit keyword search logic
export function trackMPEditKeywordSearchLogic(
  eventNamePrefix: string,
  eventsource: string,
  searchLogic: string,
  mode: KeywordsTrackPickerMode,
  mp?: { id: string; name: string }
): void {
  trackWithSessionReplay(`${eventNamePrefix} keyword search logic`, {
    eventsource,
    searchLogic,
    mode,
    mp
  });
}

// MP/EC: edit keyword where to search
export function trackMPEditKeywordWhereToSearch(
  eventNamePrefix: string,
  eventsource: string,
  searchType: string,
  mode: KeywordsTrackPickerMode,
  mp?: { id: string; name: string }
): void {
  trackWithSessionReplay(`${eventNamePrefix} keyword where to search`, {
    eventsource,
    searchType,
    mode,
    mp
  });
}

// MP/EC: edit keyword color
export function trackMPEditKeywordColor(
  eventNamePrefix: string,
  eventsource: string,
  colorType: string,
  mode: KeywordsTrackPickerMode,
  mp?: { id: string; name: string }
): void {
  trackWithSessionReplay(`${eventNamePrefix} keyword color`, {
    eventsource,
    colorType,
    mode,
    mp
  });
}

// MP/EC: edit keyword delete
export function trackMPEditKeywordDelete(
  eventNamePrefix: string,
  eventsource: string,
  mode: KeywordsTrackPickerMode,
  mp?: { id: string; name: string }
): void {
  trackWithSessionReplay(`${eventNamePrefix} keyword delete`, {
    eventsource,
    mode,
    mp
  });
}

// MP/EC: edit keyword width
export function trackMPEditKeywordWidth(
  eventNamePrefix: string,
  eventsource: string,
  keywordWidth: KeywordWidth,
  mp?: { id: string; name: string }
): void {
  trackWithSessionReplay(`${eventNamePrefix} keyword filter logic`, {
    eventsource,
    filterLogic: keywordWidth,
    mp
  });
}

// MP/EC: edit keyword done
export function trackMPEditKeywordDone(
  eventNamePrefix: string,
  eventsource: string,
  mp?: { id: string; name: string }
): void {
  trackWithSessionReplay(`${eventNamePrefix} keyword done`, {
    eventsource,
    mp
  });
}

// MP: edit add CPV-code
export function trackMPEditAddCPVCode(mp: { id: string; name: string }, checked: boolean, optionId: string): void {
  trackWithSessionReplay('MP edit add CPV-code', {
    mp,
    checked,
    optionId
  });
}

// MP: edit search CPV-code
export function trackMPEditSearchCPVCode(mp: { id: string; name: string }): void {
  trackWithSessionReplay('MP edit search CPV-code', { mp });
}

// MP: edit add region
export function trackMPEditAddRegion(mp: { id: string; name: string }, checked: boolean, optionId: string): void {
  trackWithSessionReplay('MP edit add region', {
    mp,
    checked,
    optionId
  });
}

// MP: edit search region
export function trackMPEditSearchRegion(mp: { id: string; name: string }): void {
  trackWithSessionReplay('MP edit search region', { mp });
}

// MP: edit add buyers
export function trackMPEditAddBuyers(mp: { id: string; name: string }): void {
  trackWithSessionReplay('MP edit add buyers', { mp });
}

// MP: edit have access
export function trackMPEditHaveAccess(mp: { id: string; name: string }): void {
  trackWithSessionReplay('MP edit have access', { mp });
}

// MP: edit owners
export function trackMPEditOwners(mp: { id: string; name: string }): void {
  trackWithSessionReplay('MP edit owners', { mp });
}

// MP: edit delete
export function trackMPEditDelete(mp: { id: string; name: string }): void {
  trackWithSessionReplay('MP edit delete', { mp });
}

// MP: edit update results
export function trackMPEditUpdateResults(mp: { id: string; name: string }): void {
  trackWithSessionReplay('MP edit update results', { mp });
}

// MP: edit press cross
export function trackMPEditPressCross(mp: { id: string; name: string }, updated: boolean): void {
  trackWithSessionReplay('MP edit press cross', {
    mp,
    updated
  });
}

// MP: add profile
export function trackMpCreate(mp: { id: string; name: string; eventSource?: string }): void {
  trackWithSessionReplay('MP add profile', mp);
}

// MP: update relevance sorting
export function trackMPEditRelevance(mp: { id: string; name: string }): void {
  trackWithSessionReplay('MP Edit Relevance', { mp });
}

// MP: table sorting
export function trackMPTableSorting(mp: { id: string }, columnName?: string): void {
  trackWithSessionReplay('MP table sorting', {
    mp,
    columnName
  });
}

// MP: card sorting
export function trackMPCardSorting(mp: { id: string }, columnName?: string): void {
  trackWithSessionReplay('MP card sorting', {
    mp,
    columnName
  });
}

export function trackMPSwitchMode(mp: { id: string }, mode?: string): void {
  trackWithSessionReplay('MP Switch mode', {
    mp,
    mode
  });
}

export function trackMPCopy(mp: { id: string }): void {
  trackWithSessionReplay('MP copy', { mp });
}

export function trackMPToggleHandled(mp: { id: string }, eventSource: string, state: boolean): void {
  trackWithSessionReplay('Toggle handled', {
    mp,
    eventSource,
    state
  });
}

export function trackSwitchSavedSearch(mp: { id: string; name: string }): void {
  trackWithSessionReplay('EC Saved Search', { mp });
}

export function trackECPopulate(mp: { id: string; name: string }): void {
  trackWithSessionReplay('EC monitoring profile', { mp });
}
