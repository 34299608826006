import React, { FC, ReactNode } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';

interface Props {
  children: ReactNode;
  className?: string;
}

export const NavMenu: FC<Props> = ({ children, className }) => {
  return <div className={classNames(styles.menu, className)}>{children}</div>;
};

export default NavMenu;
