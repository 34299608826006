import React, { FC, ReactNode } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import { IIcon } from 'src/types/icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  children: ReactNode;
  icon?: IIcon;
  className?: string;
  badge?: ReactNode;
  noBorder?: boolean;
}
export const Title: FC<Props> = ({ children, className, icon, badge, noBorder }) => {
  return (
    <h5 className={classNames(styles.title, !!className && className, { [styles.noBorder]: noBorder })}>
      {icon && icon.defaultIcon && (
        <FontAwesomeIcon icon={icon.defaultIcon} color={icon.color} className={styles.icon} />
      )}
      {badge && badge}
      {children}
    </h5>
  );
};

export default Title;
