import { ApiAssignedTo } from 'src/models/users/AssignedTo/types';
import { ApiBidTasksGroup } from '../BidFull/types';
import { IApiComment } from 'src/models/comments/types';
import { BidTask } from '.';
import { TaskTrackCreateData } from 'src/segment/events';
import { ContentLibraryDTO } from '@tendium/prom-types/schema';
import { ListAttachmentsResult } from 'src/models/canvas/types';
import { IFileNode } from 'src/models/documentsLibrary/types';
import { IHighlight } from 'react-pdf-highlighter';

export enum RequirementType {
  Must = 'Must',
  Should = 'Should',
  Info = 'Info',
  Contract = 'Contract',
  NA = 'NA'
}

export enum QuestionStatus {
  ToDo = 'ToDo',
  InProgress = 'InProgress',
  Review = 'Review',
  Done = 'Done'
}
export enum TaskFulfillment {
  Fulfill = 'Fulfill',
  Unsure = 'Unsure',
  NotFulfill = 'NotFulfill',
  NA = 'NA'
}

export interface ApiBidTask {
  id: string;
  title: string | null;
  content: string | null;
  question: string | null;
  status: QuestionStatus;
  requirementType: RequirementType | null;
  fulfilment: TaskFulfillment | null;
  assignedTo: ApiAssignedTo | null;
  deadline: number | null;
  isSubmitted: boolean | null;
  answer: ApiBidTaskAnswer | null;
  questionGroup: ApiBidTasksGroup;
  comments: IApiComment[];
  document: IBidDocument | null;
  primaryReferences?: IBidDocument[];
  secondaryReferences?: IBidDocument[];
  rank: string;
  aiSummary?: string;
  classificationId?: string;
}

export interface ApiBidTaskAnswer {
  id: string;
  answer: boolean | null;
  content: string | null;
  attachments: ListAttachmentsResult;
  richContent: string | null;
  sources: ContentLibraryDTO[] | null;
  pendingSources: ContentLibraryDTO[] | null;
  pendingContent: string | null;
  /**
   * @deprecated
   * Use attachments instead
   * Remove this in PROM-7350
   */
  files: IFileNode[] | null;
}

export interface IBidDocument {
  path: string;
  content: string;
  headline: string;
  contentPosition: IBidDocumentContent[];
  headlinePosition: IBidDocumentContent[];
  name?: string;
}

export interface HighlightsByPage {
  page: number;
  highlights: IHighlight[];
}

export interface TaskReferences {
  reference: IBidDocument;
  allHighlights: IHighlight[];
  highlightsByPage: HighlightsByPage[];
}

export interface ReferenceGroup extends Omit<TaskReferences, 'highlightsByPage'> {
  highlightsByPage: { [page: number]: IHighlight[] };
}

export interface IBidDocumentContent {
  page: number;
  start: {
    x: number;
    y: number;
  };
  end: {
    x: number;
    y: number;
  };
  pageSize: {
    width: number;
    height: number;
  };
}

export interface CreateBidTaskVars extends CreateBidTaskValues {
  groupId: string;
}

export interface CreateBidTasksVars extends CreateBidTaskValues {
  questionGroupId: string;
}

export interface CreateBidTaskValues {
  question: string;
  title: string;
  content: string;
  requirementType?: RequirementType;
  deadline?: number;
  isSubmitted?: boolean;
  document?: IBidDocument;
}
export const DEFAULT_TASK_PARAMS: CreateBidTaskValues = {
  title: '',
  content: '',
  question: ''
};

export type BidTaskValues = Partial<
  Pick<ApiBidTask, 'fulfilment' | 'title' | 'content' | 'requirementType' | 'status' | 'deadline'>
> & { assignedTo?: string | null };
export type BidTaskInput = BidTaskValues & {
  questionIds: string[];
  groupId: string;
  ranks?: string[];
  bidId?: string;
};
export type BidTaskFnInput = Omit<BidTaskInput, 'questionIds'> & {
  questions: BidTask[];
  newCacheSortIndex?: number;
  prevGroupId?: string;
};

export interface ApiTaskResponse {
  getBidQuestion: ApiBidTask;
}
export type ApiTaskVars = { taskId: string };
export type ApiTaskNav = Pick<ApiBidTask, 'id'> & {
  questionGroup: Pick<ApiBidTasksGroup, 'id' | 'title' | 'filePath'> & { bid: { id: string } };
};
export type TaskNavData = {
  groupId: string;
  bidId: string;
  activeTaskId: string;
  groupName: string;
};

export function isTaskDone(task: BidTask): boolean {
  return task.status === QuestionStatus.Done;
}

export type CreateBidTasksData = {
  createBidQuestions: ApiBidTask[];
};

export type CreateBidTasks = Pick<TaskTrackCreateData, 'eventSource' | 'mode' | 'bidId'> & {
  onComplete?: (taskId?: string) => void;
  questionGroupId: string;
  tasks: string[];
  isSubmitted?: boolean;
  document?: IBidDocument;
};

export type CreateBidTasksInput = {
  questionGroupId: string;
  questions: CreateBidTasksVars[];
  isSubmitted?: boolean;
};

export type CreateBidTaskData = {
  createBidQuestion: ApiBidTask;
};

export type CreateBidTaskInput = Pick<CreateBidTaskVars, 'groupId'> &
  Pick<TaskTrackCreateData, 'eventSource' | 'mode' | 'bidId'> & {
    onComplete?: (taskId?: string) => void;
    task?: CreateBidTaskValues;
    isSubmitted?: boolean;
  };

export const CREATE_TASKS_TITLE_LENGTH = 40;

export interface GetTaskResponse {
  getBidQuestion: ApiBidTask;
}

export interface GetTaskInput {
  taskId: string;
}
