import React, { FC, useMemo, useState } from 'react';
import styles from './index.module.scss';
import ReactMarkdown from 'src/common/Markdown';
import { useTranslation } from 'react-i18next';
import { AITitles, ITenderKeywordHighlight, Summaries } from 'src/models/procurements/Preview/types';
import classNames from 'classnames';
import { TenderFile } from 'src/shared';
import QuickSummaryModal from './QuickSummaryModal';
import { Schema } from '@tendium/prom-types';
import { Button } from 'src/common';
import SourceDescription from 'src/shared/Tender/SourceDescription';

interface Props {
  highlight: ITenderKeywordHighlight;
  procurementId?: string;
  summaries?: Summaries;
  titles?: AITitles;
}
export const TenderMatchesItem: FC<Props> = ({ highlight, procurementId, summaries, titles }) => {
  const { context, value, color, targetPath } = highlight;
  const { t } = useTranslation();
  const source = !targetPath ? t(`Tenders.NoticeSource.${context}`) : context;

  const [quickSummaryModalVisible, setQuickSummaryModalVisible] = useState(false);

  const sourceDescription = useMemo(() => {
    if (!!targetPath && !!procurementId) {
      return (
        <TenderFile
          procurementId={procurementId}
          targetPath={targetPath}
          displayName={source}
          eventSource="Matches - Tender Preview"
          keywordHighlight={highlight}
        />
      );
    }

    if (context === Schema.HighlightContext.QUICK_SUMMARY_ENGLISH) {
      return (
        <Button
          type="link"
          onClick={() => {
            setQuickSummaryModalVisible(true);
          }}
        >
          {`${t('Tenders.quickSummary')} (${t('Common.Lang.enTranslated')})`}
        </Button>
      );
    }

    return <span className={styles.sourceName}>{source}</span>;
  }, [context, procurementId, source, targetPath, t, highlight]);

  return (
    <div className={styles.match}>
      <ReactMarkdown className={classNames(styles.content, color && styles[color])}>{value}</ReactMarkdown>
      {source && (
        <>
          <SourceDescription desc={sourceDescription} />
          {quickSummaryModalVisible && (
            <QuickSummaryModal
              onClose={() => setQuickSummaryModalVisible(false)}
              summaries={summaries}
              titles={titles}
            />
          )}
        </>
      )}
    </div>
  );
};

export default TenderMatchesItem;
