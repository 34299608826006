import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './index.module.scss';
import { useBidding, useTasks, useTasksFilter } from 'src/models/bids/BidFull/hooks';
import { useTranslation } from 'react-i18next';
import { Button, Paging, PagingProps, Spinner, Tooltip } from 'src/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { useCreateBidTasks, useUpdateBidTasks } from 'src/models/bids/BidTask/hooks';
import { calculateNewRank, isTasksFilterApplied } from 'src/models/bids/BidFull/helpers';
import { calcPage } from 'src/helpers/calcPage';
import { isPageCached } from 'src/lib/API/graphql/helpers';
import TaskGroupTableHeader from 'src/shared/Bid/TaskGroupTableHeader';
import { BidTask } from 'src/models/bids/BidTask';
import update from 'immutability-helper';
import DndTask from '../DndTask';
import { TaskSkeleton } from 'src/shared/Bid/TaskSkeleton';

interface DndTaskItem {
  dndId: number;
  task: BidTask;
}
interface ContainerState {
  dndTaskItems: DndTaskItem[];
}

const SKELETON_COUNT = 3;

const mapTasksToContainerState = (tasks: (BidTask | null)[]): ContainerState => ({
  dndTaskItems: tasks
    .filter((task): task is BidTask => task !== null)
    .map((task, index) => ({
      dndId: index,
      task
    }))
});

export const TasksList: FC = () => {
  const { t } = useTranslation();
  const { tasksResponseData, offset, setOffset, groupId, limit, selectedTasks, setSelectedTaskIds } = useTasks();
  const [updateTasks] = useUpdateBidTasks();
  const { data } = useBidding();
  const [createTask] = useCreateBidTasks();
  const { filter } = useTasksFilter();

  const tasks = useMemo(() => (tasksResponseData?.data ? tasksResponseData.data.tasks : []), [tasksResponseData?.data]);
  const currentTasks = useMemo(() => tasks.slice(offset, offset + limit), [limit, offset, tasks]);
  const lastOffset = useMemo(
    () => Math.trunc((tasksResponseData?.data?.totalCount ?? 0) / limit) * limit,
    [tasksResponseData?.data?.totalCount, limit]
  );
  const isFilterApplied = useMemo(() => isTasksFilterApplied(filter), [filter]);
  const wsId = data?.workspaceId;

  const currentPage = useMemo(() => calcPage(offset, limit), [offset, limit]);
  const [newTaskId, setNewTaskId] = useState<string | null>(null);
  const [dndTasks, setDndTasks] = useState<ContainerState>(mapTasksToContainerState(currentTasks));

  useEffect(() => {
    if (offset === tasksResponseData?.data?.totalCount && offset !== 0) {
      const prevOffset = offset - limit;
      setOffset(prevOffset);
    }
  }, [limit, offset, setOffset, tasksResponseData?.data?.totalCount]);

  useEffect(() => {
    setDndTasks(mapTasksToContainerState(currentTasks));
  }, [currentTasks, setDndTasks]);

  const onChangeSelection = useCallback(
    (taskId: string, checked: boolean) => {
      const selectedTaskIds = selectedTasks.map(item => item.id);
      setSelectedTaskIds(checked ? [...selectedTaskIds, taskId] : selectedTaskIds.filter(item => item !== taskId));
    },
    [selectedTasks, setSelectedTaskIds]
  );

  const onCreateTask = useCallback(() => {
    const bidId = !!tasks.length ? tasks[0]?.bidId : undefined;
    if (!isPageCached(tasks, lastOffset, limit)) {
      tasksResponseData?.fetchMore?.({ variables: { offset: lastOffset } }).then(() => {
        lastOffset !== offset && setOffset(lastOffset);

        createTask({
          questionGroupId: groupId,
          bidId,
          eventSource: 'Group',
          mode: 'task',
          tasks: ['']
        });
      });
    } else {
      createTask({
        questionGroupId: groupId,
        bidId,
        eventSource: 'Group',
        mode: 'task',
        onComplete(newTaskId) {
          lastOffset !== offset && setOffset(lastOffset);
          setNewTaskId(newTaskId ?? '');
        },
        tasks: ['']
      });
    }
  }, [tasks, lastOffset, limit, tasksResponseData, offset, setOffset, createTask, groupId]);

  const onChange: PagingProps['onChange'] = useCallback(
    (page: number) => {
      const currentOffset = (page - 1) * limit;
      if (!isPageCached(tasks, currentOffset, limit)) {
        tasksResponseData?.fetchMore?.({ variables: { offset: currentOffset } }).then(() => {
          setOffset(currentOffset);
        });
      } else {
        setOffset(currentOffset);
      }
    },
    [limit, setOffset, tasksResponseData, tasks]
  );

  const moveTask = useCallback((dragIndex: number, hoverIndex: number) => {
    setDndTasks(
      prevState =>
        ({
          dndTaskItems: update(prevState.dndTaskItems, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, prevState.dndTaskItems[dragIndex] as DndTaskItem]
            ]
          })
        } as ContainerState)
    );
  }, []);

  const dropTasks = useCallback(
    (index: number) => {
      if (dndTasks.dndTaskItems.length === 0 || !dndTasks.dndTaskItems[index].task) {
        return;
      }
      const newRank = calculateNewRank(
        dndTasks.dndTaskItems,
        index,
        taskItem => taskItem.task.rank,
        tasksResponseData?.data?.nextRank
      );

      updateTasks({
        questions: [dndTasks.dndTaskItems[index].task],
        groupId: dndTasks.dndTaskItems[index].task.groupId,
        ranks: [newRank],
        newCacheSortIndex: index
      });
    },
    [dndTasks.dndTaskItems, tasksResponseData?.data?.nextRank, updateTasks]
  );

  const taskSkeletons = useMemo(() => [...Array(SKELETON_COUNT)].map((_, i) => <TaskSkeleton key={i} />), []);

  const renderTask = useCallback(
    (dndTask: { dndId: number; task: BidTask }, index: number) => {
      if (!dndTask || !dndTask.task) {
        return;
      } else
        return (
          <DndTask
            key={dndTask.task.id}
            task={dndTask.task}
            selectedTasks={selectedTasks}
            onChangeSelection={onChangeSelection}
            newTask={dndTask.task.id === newTaskId}
            onExitNewTask={() => setNewTaskId(null)}
            bidId={dndTask.task.bidId}
            wsId={wsId ?? ''}
            dndId={dndTask.dndId}
            index={index}
            moveTask={moveTask}
            dropTask={dropTasks}
          />
        );
    },
    [dropTasks, moveTask, newTaskId, onChangeSelection, selectedTasks, wsId]
  );

  return tasksResponseData ? (
    <div>
      {!selectedTasks.length && <TaskGroupTableHeader />}
      {tasksResponseData.loading ? (
        <>{taskSkeletons}</>
      ) : (
        !!tasks.length && (
          <>
            {dndTasks.dndTaskItems
              .filter(dndTaskItem => dndTaskItem.task.isSubmitted)
              .map((dndTaskItem, i) => renderTask(dndTaskItem, i))}
            <Paging
              defaultCurrent={currentPage}
              current={currentPage}
              onChange={onChange}
              pageSize={limit}
              total={tasksResponseData.data?.totalCount ?? 0}
              className={styles.paging}
              itemRender={
                tasksResponseData.fetchingMore
                  ? (page, type, originalElement) =>
                      page === currentPage && type === 'page' ? <Spinner /> : originalElement
                  : undefined
              }
            />
          </>
        )
      )}
      <Tooltip title={isFilterApplied ? t('BiddingTool.Filters.addTaskTooltip') : ''}>
        <div className={styles.addTaskButton}>
          <Button
            type={'text'}
            className={styles.button}
            onClick={onCreateTask}
            icon={<FontAwesomeIcon icon={faPlus} />}
            disabled={isFilterApplied}
          >
            {t('BiddingTool.addTask')}
          </Button>
        </div>
      </Tooltip>
    </div>
  ) : null;
};

export default TasksList;
