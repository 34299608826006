import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUpload } from '@fortawesome/pro-light-svg-icons';
import React, { FC } from 'react';
import styles from './index.module.scss';

interface Props {
  title?: string;
  desc?: string;
}

const UploadContent: FC<Props> = ({ title, desc }) => {
  return (
    <div className={styles.content}>
      <div className={styles.iconContainer}>
        <FontAwesomeIcon icon={faCloudUpload} className={styles.icon} />
      </div>
      {title && <div className={styles.title}>{title}</div>}
      {desc && <div className={styles.desc}>{desc}</div>}
    </div>
  );
};

export default UploadContent;
