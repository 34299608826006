import { trackWithSessionReplay } from '../helpers';

interface ITrackInviteUser {
  email: string;
  name: string | null;
  lastName: string | null;
}

export function trackUserInvite(payload: ITrackInviteUser): void {
  trackWithSessionReplay('Company Invite', { payload });
}

export function trackMultipleUsersInvite(payload: ITrackInviteUser[]): void {
  trackWithSessionReplay('Company Invite Multiple Users:', { payload });
}

export function trackUserInviteByAdmin(payload: ITrackInviteUser[]): void {
  trackWithSessionReplay('Admin Invite', { payload });
}

export function trackUserInviteByCompanyAdmin(payload: ITrackInviteUser): void {
  trackWithSessionReplay('Company Admin Invite', { payload });
}
