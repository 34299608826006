import { SearchProfileColor } from 'src/helpers/constants';
import { ISearchSettings } from 'src/pages/TenderPage/DocumentSearch/types';
import { AggregateBy } from 'src/pages/TenderPage/DocumentSearch/Sidebar/AggregateSelect/types';
import { trackWithSessionReplay } from '../helpers';

export function trackOpenDocumentSearch(procurement: { id: string; name?: string }): void {
  trackWithSessionReplay('DocumentSearch:opened', {
    procurement
  });
}

export function trackDocumentSearchDoSearch(
  procurement: {
    id: string;
    name?: string;
  },
  settings: ISearchSettings
): void {
  trackWithSessionReplay('DocumentSearch:doSearch', {
    procurement,
    settings: {
      ...settings,
      aggregateBy: AggregateBy[settings.aggregateBy]
    }
  });
}

export function trackAddSearchProfile(searchProfile: {
  name: string;
  terms: string[];
  color: SearchProfileColor;
}): void {
  trackWithSessionReplay('DocumentSearch:addSearchProfile', {
    searchProfile
  });
}

export function trackRenameSearchProfile(searchProfile: { id: string; name: string; prevName: string }): void {
  trackWithSessionReplay('DocumentSearch:addSearchProfile', {
    searchProfile
  });
}

export function trackDeleteSearchProfile(searchProfile: { id: string; name: string }): void {
  trackWithSessionReplay('DocumentSearch:deleteSearchProfile', {
    searchProfile
  });
}

export function trackChangeSearchProfileColor(searchProfile: {
  id: string;
  name?: string;
  color: SearchProfileColor;
  prevColor: SearchProfileColor;
}): void {
  trackWithSessionReplay('DocumentSearch:changeSearchProfileColor', {
    searchProfile
  });
}

export function trackUpdateSearchProfileTerms(searchProfile: {
  id: string;
  name?: string;
  terms: string[];
  prevTerms: string[];
}): void {
  trackWithSessionReplay('DocumentSearch:updateSearchProfileTerms', {
    searchProfile
  });
}
