import React, { FC, FocusEvent, MouseEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import modalStyles from 'src/common/Modal/index.module.scss';
import { IChecklistItem, ChecklistItemStatus } from 'src/models/bids/BidChecklist/types';
import classNames from 'classnames';
import { Checkbox, Form, Input, Button, confirm } from 'src/common';
import { useForm } from 'antd/lib/form/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { useBidChecklist, useUpdateChecklistItem } from 'src/models/bids/BidChecklist/hooks';
import AssignToChecklistItem from 'src/shared/Bid/AssignToChecklistItem';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { trackChecklistCheckmark } from 'src/segment/events/checklist';
const { TextArea } = Input;

interface IFormState {
  itemName: string;
}

interface Props {
  data: IChecklistItem;
}

export const ChecklistItem: FC<Props> = props => {
  const { data: item } = props;
  const { t } = useTranslation();
  const [form] = useForm();
  const { data: model } = useBidChecklist();
  const bidId = model?.id;

  const [isEditingMode, setEditingMode] = useState(false);
  const startEditingMode = useCallback(() => setEditingMode(true), []);

  const [updateChecklistItem] = useUpdateChecklistItem();

  const initialItemNameValue: IFormState = useMemo(() => {
    return {
      itemName: item.name
    };
  }, [item.name]);

  const updateChecklist = useCallback(() => {
    const itemName = form.getFieldValue('itemName').trim();
    itemName !== item.name &&
      !!bidId &&
      updateChecklistItem({
        id: item.id,
        bidId,
        name: itemName
      });
    setEditingMode(false);
  }, [form, item.id, item.name, bidId, updateChecklistItem]);

  const onNameChange = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();
      updateChecklist();
    },
    [updateChecklist]
  );

  const onToggle = useCallback(
    (e: CheckboxChangeEvent) => {
      !!bidId &&
        updateChecklistItem({
          id: item.id,
          bidId: bidId,
          status: e.target.checked ? ChecklistItemStatus.Done : ChecklistItemStatus.ToDo
        });
      trackChecklistCheckmark(e.target.checked);
    },
    [item.id, bidId, updateChecklistItem]
  );

  const deleteChecklistItem = useCallback(() => {
    setEditingMode(false);
    !!bidId &&
      updateChecklistItem({
        id: item.id,
        bidId,
        isActive: false
      });
  }, [bidId, item.id, updateChecklistItem]);

  const ondeleteChecklistItem = useCallback(() => {
    confirm.confirm({
      title: t('Checklist.removeChecklistItemConfirmationTitle'),
      okText: t('Common.delete'),
      content: t('Checklist.removeChecklistItemConfirmationContent'),
      cancelText: t('Common.cancel'),
      className: modalStyles.confirmModal,
      centered: true,
      onOk() {
        deleteChecklistItem();
      }
    });
  }, [t, deleteChecklistItem]);

  const onBlurItemInput = useCallback(
    (e: FocusEvent<HTMLTextAreaElement>) => {
      setTimeout(() => {
        const itemName = form.getFieldValue('itemName').trim();
        if (!!itemName) {
          e.preventDefault();
          e.stopPropagation();
          updateChecklist();
        } else {
          deleteChecklistItem();
        }
        // keep the delete button visible in case user wants to delete the item
      }, 200);
    },
    [form, updateChecklist, deleteChecklistItem]
  );

  const onEnter = useCallback(() => {
    form.getFieldInstance('itemName').blur();
  }, [form]);

  return (
    <li
      className={classNames(styles.item, {
        [styles.isDone]: item.status === ChecklistItemStatus.Done,
        [styles.isEditing]: isEditingMode
      })}
    >
      <div className={styles.itemInner}>
        <div className={styles.itemCheckbox}>
          <Checkbox checked={item.status === ChecklistItemStatus.Done} onChange={onToggle} />
        </div>
        <Form
          form={form}
          className={classNames(styles.itemName, styles.itemNameForm)}
          initialValues={initialItemNameValue}
        >
          <Form.Item name={'itemName'} label={''} className={styles.itemNameInner}>
            <TextArea
              bordered={false}
              autoSize={true}
              placeholder={t('Checklist.addChecklistItem')}
              autoFocus={false}
              className={styles.itemInput}
              disabled={item.status === ChecklistItemStatus.Done}
              onFocus={startEditingMode}
              onBlur={onBlurItemInput}
              onPressEnter={onEnter}
            />
          </Form.Item>

          <Form.Item label={''} className={classNames(styles.itemEditControls, { [styles.isHidden]: !isEditingMode })}>
            <Button onClick={onNameChange} type={'primary'}>
              {t('Common.save')}
            </Button>

            <button className={styles.deleteButton} onClick={ondeleteChecklistItem}>
              <FontAwesomeIcon icon={faTimes} className={styles.deleteIcon} />
            </button>
          </Form.Item>
        </Form>
        {bidId && (
          <ul className={classNames(styles.itemActions)}>
            <li className={classNames(styles.itemAction, styles.itemAssign)}>
              <AssignToChecklistItem
                className={styles.itemAssignInner}
                assignedTo={item.assignedTo}
                id={item.id}
                bidId={bidId}
                wsId={model?.workspaceId}
                size={'s'}
                eventSource={'Checklist'}
              />
            </li>
          </ul>
        )}
      </div>
    </li>
  );
};

export default ChecklistItem;
