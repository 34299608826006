import React, { FC, ReactNode, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { getBuyerLink } from 'src/models/organisations';
import styles from './index.module.scss';
import { FeatureFlag, useAnalyticsFeatureFlags, useFeatureFlag } from 'src/helpers/featureFlag';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightFromSquare } from '@fortawesome/pro-light-svg-icons';
import { DEMO_BUYER } from 'src/models/procurements/TendersSearch/types';

interface BuyerLinkProps {
  orgId?: string;
  orgName?: string;
  children?: ReactNode;
}

export const BuyerLink: FC<BuyerLinkProps> = ({ orgId, orgName, children }) => {
  const isBuyerPageAvailable = useAnalyticsFeatureFlags(FeatureFlag.Analytics_BuyerProfilesPage);
  const isAnalyticsPagesDemo = useFeatureFlag(FeatureFlag.Analytics_Pages_Demo);
  const buyerLink = useMemo(() => {
    if (isAnalyticsPagesDemo) return getBuyerLink(DEMO_BUYER.buyerOrgId, DEMO_BUYER.buyerName);
    if (!orgId) return '';
    return getBuyerLink(orgId, orgName);
  }, [isAnalyticsPagesDemo, orgId, orgName]);

  if (!orgId || !isBuyerPageAvailable) return <>{children}</>;

  return (
    <Link to={buyerLink} className={styles.link}>
      <FontAwesomeIcon icon={faUpRightFromSquare} className={styles.icon} />
      {children}
    </Link>
  );
};

export default BuyerLink;
