import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, notification } from 'src/common';
import { useCloseSidebar } from 'src/shared/InfoSidebar/hooks';
import styles from './index.module.scss';
import { useUpdateBidStatus } from 'src/models/bids/Bids/hooks';
import { useBidsSearchArgs } from 'src/reactiveVars';
import { useMarkBidAsRejected } from 'src/models/bids/BidPreview/hooks';
import { useSortedWorkspaceStatuses } from 'src/models/workspace/WorkspaceStatus/hooks';
import { WorkspaceStatusCategory } from '@tendium/prom-types/tender';
import { trackChangeBidStage } from 'src/segment/events';
import { useQueryParams } from 'src/helpers';
import { OverviewQp } from 'src/models/procurements/types';
import { SidebarMode } from 'src/shared/InfoSidebar/types';
import { updateCacheOnUpdateBidStatus } from 'src/models/bids/Bids/cacheHandlers';

interface Props {
  bidId: string;
  isRejected: boolean;
  wsId: string;
  prevStatusId: string;
  statusCategory?: WorkspaceStatusCategory;
}

export const GoNoGoActions: FC<Props> = props => {
  const { bidId, isRejected, wsId, prevStatusId, statusCategory } = props;
  const { t } = useTranslation();

  const [{ rejected: isRejectedTab }] = useBidsSearchArgs();
  const sortedStatuses = useSortedWorkspaceStatuses();
  const statusId = sortedStatuses.find(status => status.category === WorkspaceStatusCategory.InProgress)?.id ?? '';
  const qp = useQueryParams();
  const isBidPreview = !!(qp[OverviewQp.PreviewMode] === SidebarMode.BID_INFO);

  const [moveToWorkspaceStatus] = useUpdateBidStatus();
  const [markBidAsRejected] = useMarkBidAsRejected();

  const closeSidebar = useCloseSidebar();

  const onReject = useCallback(() => {
    markBidAsRejected(bidId, !isRejected, prevStatusId, wsId, closeSidebar, statusCategory);
  }, [markBidAsRejected, bidId, isRejected, prevStatusId, wsId, closeSidebar, statusCategory]);

  const onGo = useCallback(() => {
    moveToWorkspaceStatus({
      variables: {
        bidId,
        statusId
      },
      update: updateCacheOnUpdateBidStatus({
        wsId,
        bidId,
        rejectedView: !!isRejectedTab,
        currentStatusId: statusId,
        prevStatusId
      })
    })
      .then(() => {
        if (wsId) {
          trackChangeBidStage(
            {
              id: wsId
            },
            statusCategory as string,
            isBidPreview ? 'Bid preview' : 'Kanban'
          );
        }
      })
      .catch(() => {
        notification.error({
          description: t('Common.unknownErrorDesc'),
          message: t('Common.unknownError')
        });
      });
  }, [bidId, isBidPreview, isRejectedTab, moveToWorkspaceStatus, prevStatusId, statusCategory, statusId, t, wsId]);

  return (
    <ul className={styles.itemActionsContainer}>
      <li className={styles.itemAction}>
        <Button onClick={onReject} type={'default'}>
          {t('BidSpaces.reject')}
        </Button>
      </li>
      <li className={styles.itemAction}>
        <Button onClick={onGo} type={'primary'}>
          {t('DecisionMatrix.go')}
        </Button>
      </li>
    </ul>
  );
};
export default GoNoGoActions;
