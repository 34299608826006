import { z } from 'zod';

export const bulkGenerateSchema = z.object({
  answerType: z.enum(['empty', 'everything']).default('empty'),
  selectedRooms: z.array(z.string()).optional(),
  customInstructions: z.string().optional()
});

export const generateSchema = z.object({
  responseLength: z.enum(['brief', 'detailed']).default('brief'),
  wordCount: z.union([z.number().int().positive().min(1), z.nan()]).optional(),
  customInstructions: z.string().optional()
});

export const exportSchema = z.object({
  fileFormat: z.enum(['PDF', 'DOCX']),
  chosenTasks: z.array(z.string())
});
