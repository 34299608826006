import React, { FC, useCallback, useMemo, useState } from 'react';
import { DirectoryTree } from 'src/common';
import { DocumentViewer } from 'src/shared';
import { AttachFileToBidResponse } from 'src/models/bids/BidAttachments/types';
import styles from '../TenderFilesTree/index.module.scss';
import { PrivateFileNode, toBidAttachmentsTreeData } from './toBidTreeData';
import { downloadFile } from 'src/helpers';
import { PrivateFileInput } from 'src/models/bids/BidFull/types';
import { useTranslation } from 'react-i18next';
import { trackGenerateSelection } from 'src/segment/events';
import { EventDataNode } from 'antd/lib/tree';

interface MyAttachmentsTreeProps {
  attachments: AttachFileToBidResponse[];
  precheckFiles: string[];
  disabledFiles: string[];
  restCheckedFiles: string[];
  previousGeneratedEmptyGroupFiles: string[];
  onCheck: (checkedKeys: string[], selectedFiles?: PrivateFileInput[]) => void;
  hitLimits?: boolean;
  checkedFiles: string[];
}

export const MyAttachmentsTree: FC<MyAttachmentsTreeProps> = props => {
  const { t } = useTranslation();
  const { attachments, precheckFiles, checkedFiles, onCheck, previousGeneratedEmptyGroupFiles } = props;

  const [fileName, setFileName] = useState<string>('');
  const [previewUrl, setPreviewUrl] = useState<string>('');

  const onGetFile = useCallback((path: string, fileName: string) => {
    downloadFile(path, fileName);
  }, []);

  const onShowDocument = useCallback((path: string) => {
    setFileName(path);
  }, []);

  const onCloseDocument = useCallback(() => {
    setPreviewUrl('');
    setFileName('');
  }, []);

  const isDisabledFn = useCallback(
    (attachment: AttachFileToBidResponse) => {
      const fileId = attachment.document.id;
      const isAlreadyGenerated = precheckFiles.includes(fileId);

      const isChunked = !!attachment.hasChunkedData;

      if (!isChunked) {
        return { disabled: true, tooltips: t('BiddingTool.prePopulateModalNoChunkedData') };
      }

      if (!isAlreadyGenerated) {
        return { disabled: false };
      }

      const isEmptyGroupFile = previousGeneratedEmptyGroupFiles.some(file => file.split('/')[1] === fileId);
      const tooltip = isEmptyGroupFile
        ? t('BiddingTool.prePopulateModalAlreadyGeneratedEmpty')
        : t('BiddingTool.prePopulateModalAlreadyGenerated');

      return { disabled: true, tooltips: tooltip };
    },
    [precheckFiles, previousGeneratedEmptyGroupFiles, t]
  );

  const previewFile = useCallback((url: string) => {
    setPreviewUrl(url);
  }, []);

  const onCheckCB = useCallback(
    (key: string[], checkedNodes: PrivateFileNode[], node: EventDataNode) => {
      const selectedFiles = checkedNodes
        .map(node => node.dataRef)
        .filter((file): file is PrivateFileInput => file !== undefined);

      onCheck(key, selectedFiles);

      trackGenerateSelection({
        type: !node.checked,
        fileName: attachments.find(att => att.document.getUrl === node.key)?.document.name ?? ''
      });
    },
    [attachments, onCheck]
  );

  const treeData: PrivateFileNode[] = useMemo(() => {
    return toBidAttachmentsTreeData(attachments, isDisabledFn, onGetFile, previewFile, onShowDocument);
  }, [attachments, isDisabledFn, onGetFile, previewFile, onShowDocument]);

  return (
    <>
      <DirectoryTree
        className={styles.tree}
        checkable
        showIcon={false}
        selectable={false}
        defaultExpandedKeys={treeData.map(node => node.key)}
        checkedKeys={checkedFiles}
        treeData={treeData}
        onCheck={(key, { checkedNodes, node }) => {
          onCheckCB(key as string[], checkedNodes as PrivateFileNode[], node);
        }}
      />
      {!!previewUrl && <DocumentViewer title={fileName} url={previewUrl} onClose={onCloseDocument} />}
    </>
  );
};

export default MyAttachmentsTree;
