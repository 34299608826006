import { BidderBoxFieldName, ApiTenderOutcome } from './types';
import {
  IBoxFieldString,
  IProcurementFilesCategory,
  ITenderBox,
  ITenderCat,
  ITenderSubCat,
  TenderCatName
} from '../Tender/types';
import { convertApiLotsToLots, groupBoxesToCats, toDocsSubCats } from '../Tender/helpers';
import { FileCategory } from '../types';

const BIDDER_STATUS_ORDER = ['Awarded contract', 'Qualified - No award', 'Not qualified', 'Not evaluated'];

export class TenderOutcomeData {
  public readonly id: string;
  public readonly rows: ITenderBox[];
  public readonly outcomeFilesCategory: IProcurementFilesCategory;
  constructor(private readonly apiTender: ApiTenderOutcome) {
    this.id = this.apiTender.id;
    this.rows = this.toRowsData();
    this.outcomeFilesCategory = this.toFilesCat();
  }

  private toRowsData(): ITenderBox[] {
    const tenderLots = convertApiLotsToLots(this.apiTender.lots);
    const cats: ITenderCat[] =
      groupBoxesToCats(this.apiTender.boxes, tenderLots, false).filter(cat =>
        cat.id.startsWith(TenderCatName.Outcome)
      ) ?? [];
    const subCats: ITenderSubCat[] = (cats[0]?.subCats ?? []).filter(subCat =>
      subCat.id.startsWith(TenderCatName.Outcome)
    );

    const bidderRows = (subCats[0]?.boxes ?? []).filter(field => field.title === 'Bidder information');
    const rows = bidderRows.map(row => ({
      ...row,
      fields: row.fields.filter(field => Object.values(BidderBoxFieldName).some(name => name === field.name))
    }));

    return rows.sort((a, b) => {
      const fieldStringA = a.fields.find(field => field.name === BidderBoxFieldName.BidderStatus) as IBoxFieldString;
      const fieldStringB = b.fields.find(field => field.name === BidderBoxFieldName.BidderStatus) as IBoxFieldString;
      const statusA = fieldStringA?.string
        ? BIDDER_STATUS_ORDER.indexOf(fieldStringA.string)
        : BIDDER_STATUS_ORDER.length;
      const statusB = fieldStringB?.string
        ? BIDDER_STATUS_ORDER.indexOf(fieldStringB.string)
        : BIDDER_STATUS_ORDER.length;
      return statusA - statusB;
    });
  }

  private toFilesCat(): IProcurementFilesCategory {
    return toDocsSubCats(
      this.apiTender.fileCategories.filter(fileCategory => fileCategory.name === FileCategory.TenderEvaluationDocuments)
    );
  }
}
