import { useMemo } from 'react';
import { MutationTuple, MutationUpdaterFn, Reference, useMutation } from '@apollo/client';
import { IWorkspaceStatus, IApiWorkspaceStatus } from './types';
import { WorkspaceStatusCategory } from '@tendium/prom-types/dist/tender';
import { CREATE_WORKSPACE_STATUS, DELETE_WORKSPACE_STATUS, UPDATE_WORKSPACE_STATUS } from './queries';
import { useWorkspace } from '../hooks';
import { toSortedStatuses } from './helpers';

interface IDeleteWorkspaceStatusInput {
  id: string;
}

interface IDeleteWorkspaceStatusOutput {
  deleteWorkspaceStatus: boolean;
  __typename: 'Mutation';
}

export function useDeleteWorkspaceStatus(): MutationTuple<IDeleteWorkspaceStatusOutput, IDeleteWorkspaceStatusInput> {
  return useMutation(DELETE_WORKSPACE_STATUS);
}

export function getUpdateCacheOnDeleteWorkspaceStatus(
  statusId: string,
  wsId: string
): MutationUpdaterFn<IDeleteWorkspaceStatusOutput> {
  return (cache, { data }) => {
    if (!data) {
      return null;
    }
    const wsRef = cache.identify({
      __typename: 'Workspace',
      id: wsId
    });
    cache.modify({
      id: wsRef,
      fields: {
        statuses(existingRefs: Reference[], { readField }) {
          return existingRefs.filter(ref => statusId !== readField('id', ref));
        }
      }
    });
  };
}

interface ICreateWorkspaceStatusInput {
  workspaceId: string;
  name: string;
  rank: string;
  category: WorkspaceStatusCategory;
}

interface ICreateWorkspaceStatusOutput {
  __typename: 'Mutation';
  createWorkspaceStatus: IApiWorkspaceStatus & {
    __typename: 'Workspace';
  };
}

export function useCreateWorkspaceStatus(): MutationTuple<ICreateWorkspaceStatusOutput, ICreateWorkspaceStatusInput> {
  return useMutation(CREATE_WORKSPACE_STATUS);
}

export function getUpdateCacheOnCreateWorkspaceStatus(
  wsId: string,
  beforeUpdate: (id: string | null) => void
): MutationUpdaterFn<ICreateWorkspaceStatusOutput> {
  return (cache, { data }) => {
    beforeUpdate && beforeUpdate(data ? data.createWorkspaceStatus.id : null);

    if (!data) {
      return null;
    }

    const newStatusId = data.createWorkspaceStatus.id;

    const wsRef = cache.identify({
      __typename: 'Workspace',
      id: wsId
    });
    cache.modify({
      id: wsRef,
      fields: {
        statuses(existingRefs: Reference[], { readField, toReference }) {
          return existingRefs.some(ref => readField('id', ref) === newStatusId)
            ? existingRefs
            : [...existingRefs, toReference({ __typename: 'WorkspaceStatus', id: newStatusId })];
        }
      }
    });
  };
}

interface IUpdateWorkspaceStatusInput {
  id: string;
  name?: string;
  rank?: string;
  category?: WorkspaceStatusCategory;
}

interface IUpdateWorkspaceStatusOutput {
  __typename: 'Mutation';
  updateWorkspaceStatus: IApiWorkspaceStatus & {
    __typename: 'Workspace';
  };
}

export function useUpdateWorkspaceStatus(): MutationTuple<IUpdateWorkspaceStatusOutput, IUpdateWorkspaceStatusInput> {
  return useMutation(UPDATE_WORKSPACE_STATUS);
}

export function useSortedWorkspaceStatuses(): IWorkspaceStatus[] {
  const { data: ws } = useWorkspace();
  return useMemo(() => toSortedStatuses(ws?.statuses || []), [ws]);
}
