import { SafeReadonly } from '@apollo/client/cache/core/types/common';
import { FieldFunctionOptions } from '@apollo/client/cache/inmemory/policies';
import { isNotUndefined } from 'src/helpers';
import { NetworkStatus } from '@apollo/client/core';

export const DEFAULT_PAGINATION_LIMIT = 10;

/**
 * Get variables for offset-limit based pagination
 * FIXME: https://tendium.atlassian.net/wiki/spaces/TENDIUM/pages/3126394892/Apollo+cache+Pagination#getPaginationVariables
 */
export function getPaginationVariables(options: FieldFunctionOptions): { offset: number; limit: number } | undefined {
  if (!options.variables) {
    return;
  }
  const { offset, resultOffset, limit, resultLimit, amount, from, size } = options.variables;
  return {
    offset: offset ?? resultOffset ?? from ?? 0,
    limit: limit ?? resultLimit ?? size ?? amount ?? DEFAULT_PAGINATION_LIMIT
  };
}

/**
 * A merge function for offset-limit based pagination, which data in specific page is sensitive.
 * Use cases: Pagination with page numbers
 */
export function offsetLimitPaginationMerge<T>(
  existing: SafeReadonly<(T | null)[]> | undefined,
  incoming: SafeReadonly<(T | null)[]> | undefined,
  options: FieldFunctionOptions
): SafeReadonly<(T | null)[]> {
  let merged = existing ? [...existing] : [];

  if (!incoming) {
    return merged;
  }

  const variables = getPaginationVariables(options);

  if (variables) {
    const { offset } = variables;
    for (let i = 0; i < incoming.length; ++i) {
      merged[offset + i] = incoming[i];
    }
  } else {
    // It's unusual (probably a mistake) for a paginated field not
    // to receive any arguments, so you might prefer to throw an
    // exception here, instead of recovering by appending incoming
    // onto the existing array.
    merged = [...merged, ...incoming];
  }

  // Paginated view, fill with null
  // Why? undefined items in array will not be return from cache
  for (let i = 0; i < merged.length; ++i) {
    merged[i] = merged[i] ?? null;
  }

  return merged;
}

/**
 * A simple merge function without extra checks.
 * It can be used for offset-limit based pagination, which data in specific page is NOT sensitive.
 * Use cases:
 * - Any cases merging incoming data into existing data directly
 * - Pagination with infinite scrolling
 */
export function mergeArray<T>(
  existing: SafeReadonly<T[]> | undefined,
  incoming: SafeReadonly<T[]> | undefined
): SafeReadonly<T[]> {
  const merged = existing ? [...existing] : [];

  if (!incoming) {
    return merged;
  }

  return [...merged, ...incoming];
}

/**
 * A merge function with uniqueness and dangling reference check (see implementation details in toUniqueArray).
 * It can be used for offset-limit based pagination, which cache data in specific page is NOT sensitive.
 * Use cases:
 * - Any cases merging incoming data into existing data that requires uniqueness and integrity.
 * - Pagination with infinite scrolling
 */
export function mergeUniqueArray<EntityT, KeyT = string>(
  existing: SafeReadonly<EntityT[]> | undefined,
  incoming: SafeReadonly<EntityT[]> | undefined,
  options: FieldFunctionOptions,
  keyField?: string | ((entity: EntityT, options: FieldFunctionOptions) => KeyT | undefined)
): SafeReadonly<EntityT[]> {
  return toUniqueArray(mergeArray(existing, incoming), options, keyField);
}

/**
 * Filter cache data with uniqueness and dangling reference checks
 * readKeyField function is used for those checks.
 * - if it is not passed in, the checks use 'id' as key field from the entity.
 * - if it is passed in as a string, the checks use string value as key field from the entity.
 */
export function toUniqueArray<EntityT, KeyT = string>(
  data: SafeReadonly<EntityT[]>,
  options: FieldFunctionOptions,
  keyField?: string | ((entity: EntityT, options: FieldFunctionOptions) => KeyT | undefined)
): SafeReadonly<EntityT[]> {
  const keyFieldStr = typeof keyField === 'string' ? keyField : 'id';
  const readKeyFieldFn =
    !keyField || typeof keyField === 'string'
      ? (entity: EntityT, options: FieldFunctionOptions) =>
          options.isReference(entity) && options.readField({ fieldName: keyFieldStr, from: entity })
      : keyField;
  // unique result
  const mappedData: [KeyT, EntityT][] = data
    // Filter out undefined, null value
    .filter(isNotUndefined)
    // Filter out dangling reference and non-existence entities
    .filter(entity => !!readKeyFieldFn(entity, options))
    // Create mapped value for uniqueness
    .map(entity => [readKeyFieldFn(entity, options) as KeyT, entity]);
  return [...new Map(mappedData).values()];
}

/**  Check if cache exists for current page to address the fetchMore not utilizing cache regardless fetchPolicy and nextFetchPolicy **/
export function isPageCached<T>(data: T[], offset: number, limit: number): boolean {
  return data.slice(offset, offset + limit).some(item => isNotUndefined(item));
}

/**
 * Get loading and fetchingMore from query
 */
export function getLoadingStatesForQuery(query: { loading: boolean; networkStatus: NetworkStatus }): {
  loading: boolean;
  fetchingMore: boolean;
} {
  return {
    loading:
      (query.loading ||
        query === undefined ||
        query.networkStatus === NetworkStatus.setVariables ||
        query.networkStatus === NetworkStatus.refetch) &&
      query.networkStatus !== NetworkStatus.fetchMore,
    fetchingMore: query.networkStatus === NetworkStatus.fetchMore
  };
}
