import React, { FC, useCallback, useState } from 'react';
import { IBoxFieldNumber } from 'src/models/procurements/Tender/types';
import EditableBlockNumber from './EditableBlock';
import styles from './index.module.scss';
import ReadOnlyBlockNumber from './ReadOnlyBlock';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-light-svg-icons';
import { useKey } from 'react-use';
import classNames from 'classnames';
import AddValueButton from '../AddValueButton';
import { IBlockProps, FieldViewMode } from 'src/shared/Tender/Boxes/types';

interface Props extends IBlockProps<IBoxFieldNumber> {}
export const BlockNumber: FC<Props> = ({ field, onChange, viewMode, isEditable }) => {
  const [currentMode, setCurrentMode] = useState(viewMode || FieldViewMode.Read);

  const onToggleView = useCallback(() => {
    isEditable && setCurrentMode(prev => (prev === FieldViewMode.Read ? FieldViewMode.Edit : FieldViewMode.Read));
  }, [isEditable]);

  const switchToReadMode = useCallback(() => {
    if (isEditable && currentMode === FieldViewMode.Edit) {
      setCurrentMode(FieldViewMode.Read);
    }
  }, [currentMode, isEditable]);

  useKey('Escape', () => setCurrentMode(FieldViewMode.Read));

  const onFinish = useCallback(
    (val: IBoxFieldNumber) => {
      onChange?.(val);
      switchToReadMode();
    },
    [onChange, switchToReadMode]
  );

  return (
    <>
      {currentMode === FieldViewMode.Edit && (
        <EditableBlockNumber field={field} onChange={onFinish} onFinish={switchToReadMode} />
      )}

      {currentMode === FieldViewMode.Read && (
        <>
          {!!field.number || field.unit ? (
            <div className={classNames(styles.toggler, { [styles.isEditable]: isEditable })} onClick={onToggleView}>
              <ReadOnlyBlockNumber number={field.number} unit={field.unit} />
              {isEditable && (
                <div className={styles.togglerIcon}>
                  <FontAwesomeIcon icon={faPen} />
                </div>
              )}
            </div>
          ) : (
            <AddValueButton onToggleView={onToggleView} isEditable={!!isEditable} />
          )}
        </>
      )}
    </>
  );
};

export default BlockNumber;
