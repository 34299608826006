import React, { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import UploadContent from 'src/shared/UploadContent';
interface Props {
  onSelect: (files: File[]) => void;
}

const FolderInput: FC<Props> = ({ onSelect }) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLInputElement>(null);

  const handleFolderSelect = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const files = Array.from(event.target.files || []);
    onSelect(files);
  };

  useEffect(() => {
    if (ref.current !== null) {
      ref.current.setAttribute('directory', '');
      ref.current.setAttribute('webkitdirectory', '');
    }
  }, [ref]);

  return (
    <>
      <label htmlFor="folder-input" className={styles.inputFolder}>
        <UploadContent title={t('ContentLibrary.clickFolder')} />
      </label>
      <input
        id="folder-input"
        type="file"
        ref={ref}
        onChange={handleFolderSelect}
        multiple
        style={{ display: 'none' }}
      />
    </>
  );
};

export default FolderInput;
