import React, { FC, useState, ReactNode, useMemo } from 'react';
import { SidebarsContext, UpdateSidebarsContext, ISidebar } from '../context';
import { useMpSnapshot } from 'src/models/matchingProfiles/hooks';

interface Props {
  children: ReactNode;
}

export const SidebarContext: FC<Props> = props => {
  const { children } = props;
  const [sidebars, setSidebars] = useState<ISidebar[]>([]);
  const [isMPUpdated, resetMPData] = useMpSnapshot();

  const contextValue = useMemo(
    () => ({ sidebars, mp: { isMPUpdated, resetMPData } }),
    [sidebars, isMPUpdated, resetMPData]
  );

  return (
    <UpdateSidebarsContext.Provider value={setSidebars}>
      <SidebarsContext.Provider value={contextValue}>{children}</SidebarsContext.Provider>
    </UpdateSidebarsContext.Provider>
  );
};

export default SidebarContext;
