import { TasksFilterArgs, BidTasksGroup, GROUP_LIMIT_COUNT } from './types';
import { getQueryParams, isString } from 'src/helpers';
import { isValueInEnum } from 'src/helpers/isValueInEnum';
import { QuestionStatus, RequirementType, TaskFulfillment } from '../BidTask/types';
import qs from 'qs';
import { LexoRank } from 'lexorank';

export function isTasksFilterApplied(filter?: TasksFilterArgs): boolean {
  return (
    !!filter?.fulfillment?.length ||
    !!filter?.status?.length ||
    !!filter?.requirementType?.length ||
    !!filter?.assignedTo?.length
  );
}

export function hitGroupLimits(existingGroups: BidTasksGroup[], checkedFiles: string[]): boolean {
  return existingGroups.length + checkedFiles.length > GROUP_LIMIT_COUNT;
}

export function isTaskFulfillment(value: unknown): value is TaskFulfillment {
  return isValueInEnum(value, TaskFulfillment);
}

export function isRequirementType(value: unknown): value is RequirementType {
  return isValueInEnum(value, RequirementType);
}

export function isQuestionStatus(value: unknown): value is QuestionStatus {
  return isValueInEnum(value, QuestionStatus);
}

export function getTaskFiltersFromQueryParams(): TasksFilterArgs | undefined {
  const qp = getQueryParams();
  const args = {
    fulfillment:
      Array.isArray(qp.fulfillment) && !!qp.fulfillment.length
        ? qp.fulfillment.filter(value => isTaskFulfillment(value))
        : undefined,
    requirementType:
      Array.isArray(qp.requirementType) && !!qp.requirementType.length
        ? qp.requirementType.filter(value => isRequirementType(value))
        : undefined,
    status:
      Array.isArray(qp.status) && !!qp.status.length ? qp.status.filter(value => isQuestionStatus(value)) : undefined,
    assignedTo:
      Array.isArray(qp.assignedTo) && !!qp.assignedTo.length
        ? qp.assignedTo.filter(value => isString(value))
        : undefined
  };
  return isTasksFilterApplied(args) ? args : undefined;
}

export function getTaskFiltersQueryParamsStr(): string {
  const qp = getTaskFiltersFromQueryParams();
  if (!qp) return '';
  return `?${qs.stringify(qp)}`;
}

export function calculateNewRank<T>(
  items: T[],
  index: number,
  getRank: (item: T) => string,
  nextRank?: string
): string {
  const prevItem = index > 0 ? items[index - 1] : null;
  const nextItem = index < items.length - 1 ? items[index + 1] : null;

  if (prevItem && (nextItem || nextRank)) {
    return LexoRank.parse(getRank(prevItem))
      .between(LexoRank.parse(nextItem ? getRank(nextItem) : nextRank ?? ''))
      .toString();
  }
  if (!prevItem && nextItem) {
    return LexoRank.min()
      .between(LexoRank.parse(getRank(nextItem)))
      .toString();
  }
  if (!nextItem && prevItem) {
    return LexoRank.parse(getRank(prevItem)).between(LexoRank.max()).toString();
  }

  return LexoRank.min().toString();
}
