import { ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { debounce, DebouncedFunc } from 'lodash';
import { useGetTask, useLoadTask, useSetBidQuestionAnswer } from 'src/models/bids/BidTask/hooks';
import {
  CanvasURLParams,
  DeleteAttachmentsResult,
  DeleteBidTaskAnswerAttachmentsInput,
  EditorCanvasContextType,
  ExportBidTasksInput,
  ExportBidTasksOutput,
  GenerateAnswerRequest,
  GenerateAnswerResponse,
  GenerateIterationContentRequest,
  GetBidTaskAnswerAttachmentUploadUrlInput,
  UpdateEditorParams,
  UploadAttachmentFnInput
} from './types';
import {
  getActiveBidTask,
  getActiveBidTaskId,
  getBidTaskNodes,
  doesNodeContainHighlightMark,
  findNodeById
} from './helpers';
import { useParams } from 'react-router-dom';
import { ApolloError, OperationVariables, useLazyQuery, useMutation, useSubscription } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { GenerateBidTaskAnswerSub, StreamingCommon } from '@tendium/prom-types/subscriptions';
import {
  EXPORT_BIDTASKS,
  GENERATE_ANSWER,
  GENERATE_ITERATION_CONTENT,
  GET_BID_TASK_ANSWER_ATTACHMENT_DOWNLOAD_URL,
  GET_BID_TASK_ANSWER_ATTACHMENT_UPLOAD_URL,
  ON_CANVAS_BID_TASK_GENERATE_ANSWER
} from './queries';
import { EditorCanvasContext } from './context';
import { useCanvasGenerationStatus, useExportFileStatus } from 'src/reactiveVars';
import { useTranslation } from 'react-i18next';
import { notification } from 'src/common';
import { Editor, useCurrentEditor } from '@tiptap/react';
import { BidTask } from '../bids/BidTask';
import { Node } from '@tiptap/pm/model';
import { DELETE_BID_TASK_ANSWER_ATTACHMENTS } from './mutations';
import { downloadFile } from 'src/helpers/files';
import {
  updateCacheOnAttachmentAfterUpload,
  updateCacheOnAttachmentBeforeUpload,
  updateCacheOnAttachmentsDelete
} from './cacheHandlers';
import { getUniqueFileName } from './helpers/getUniqueFileName';
import { addBeforeUnloadListener, removeBeforeUnloadListener } from 'src/helpers/handleBeforeUnload';
import { cache } from 'src/lib/API/graphql/cache';
import { FeatureFlag, isNotUndefined, useFeatureFlag } from 'src/helpers';
import { CANVAS_PAGE_TOP_BOUNDARY } from './constants';
import { useLoadTasks } from '../bids/BidFull/hooks';

export const useHandleEditorBidTaskUpdate = (
  groupId: string,
  taskId: string
): DebouncedFunc<({ transaction, editor }: UpdateEditorParams) => void> => {
  const [setAnswer] = useSetBidQuestionAnswer();

  return useMemo(
    () =>
      debounce(({ transaction, editor }) => {
        const previousEditorContentState = transaction.before.content;
        const currentEditorContentState = editor.state.doc.content;

        if (!previousEditorContentState || !currentEditorContentState) {
          console.error('Editor content state is undefined');
          return;
        }

        let prevContent;
        let currentContent;

        try {
          prevContent = JSON.stringify(previousEditorContentState.toJSON());
          currentContent = JSON.stringify(currentEditorContentState.toJSON());
        } catch {
          console.error('Not valid content');
        }

        if (prevContent === currentContent) return;

        setAnswer({
          questionId: taskId,
          questionGroupId: groupId,
          answer: { richContent: currentContent }
        });
      }, 500),
    [groupId, setAnswer, taskId]
  );
};

export const useHandleEditorUpdate = (): DebouncedFunc<({ transaction, editor }: UpdateEditorParams) => void> => {
  const [setAnswer] = useSetBidQuestionAnswer();
  const { groupId } = useParams<CanvasURLParams>();

  return useMemo(
    () =>
      debounce(({ transaction, editor }) => {
        const previousEditorContentState = transaction.before.content.content;
        const currentEditorContentState = editor.state.doc.content.content;

        const previousStateBidTaskNodes = getBidTaskNodes(previousEditorContentState);
        const currentStateBidTaskNodes = getBidTaskNodes(currentEditorContentState);

        // Skip update if highlight mark is present
        if (doesNodeContainHighlightMark(currentStateBidTaskNodes)) return;

        for (const prevNode of previousStateBidTaskNodes) {
          const prevNodeAttributes = prevNode.attrs;

          const currentNode = currentStateBidTaskNodes.find(node => {
            const nodeAttributes = node.attrs;
            return nodeAttributes.id === prevNodeAttributes.id;
          });

          if (!currentNode) continue;

          let prevContent;
          let currentContent;

          try {
            prevContent = JSON.stringify(prevNode.content.toJSON());
            currentContent = JSON.stringify(currentNode.content.toJSON());
          } catch {
            console.error('Not valid content');
          }

          if (prevContent === currentContent) continue;

          // Remove source if no text content
          const isRemoveSource = !currentNode.textContent.trim();

          groupId &&
            setAnswer({
              questionId: prevNodeAttributes.id,
              questionGroupId: groupId,
              answer: {
                richContent: currentContent,
                ...(isRemoveSource && { sourceIds: [] })
              }
            });
        }
      }, 500),
    [groupId, setAnswer]
  );
};

export function useCanvasBidTaskGenerateAnswerSub(skip: boolean): void {
  const isBiddingToolGenerateAnswer = useFeatureFlag(FeatureFlag.BiddingTool_GenerateAnswers);
  const [getTask] = useGetTask();
  const { canvasStatus, updateCanvasStatus, resetIterationContentStatus } = useCanvasGenerationStatus();
  const iterationStreamRef = useRef<string | null>(null);

  useSubscription<{ generateBidTaskAnswer: GenerateBidTaskAnswerSub.Data }, OperationVariables>(
    ON_CANVAS_BID_TASK_GENERATE_ANSWER,
    {
      onData: async ({ data: response }) => {
        const data = response?.data?.generateBidTaskAnswer;

        if (!data) return;

        const status = data.status;

        switch (data.streamType) {
          case GenerateBidTaskAnswerSub.StreamType.TASK:
            if (!isBiddingToolGenerateAnswer) return;
            try {
              cache.modify({
                id: cache.identify({ __typename: 'ProcurementBidQuestion', id: data.bidTaskId }),
                fields: {
                  answer(existingAnswerRef, { readField }) {
                    if (!existingAnswerRef) return existingAnswerRef;

                    const currentPendingContent = readField('pendingContent', existingAnswerRef) ?? '';
                    const newContent =
                      data.answerData?.order === 0
                        ? data.answerData?.content ?? ''
                        : currentPendingContent + (data.answerData?.content ?? '');

                    cache.modify({
                      id: cache.identify({ __typename: 'BidQuestionAnswer', id: readField('id', existingAnswerRef) }),
                      fields: {
                        pendingContent() {
                          return newContent;
                        }
                      }
                    });

                    return existingAnswerRef;
                  }
                }
              });

              if (status === StreamingCommon.AnswerStatus.DONE) {
                await getTask({
                  variables: { taskId: data.bidTaskId },
                  fetchPolicy: 'network-only'
                });
              }

              updateCanvasStatus({
                taskId: data.bidTaskId,
                status
              });
            } catch (error) {
              console.error('error', error);
              Sentry.captureException(error, { extra: { tag: 'subscription-fetch-or-cache-error' } });
            }
            break;
          case GenerateBidTaskAnswerSub.StreamType.GROUP:
            if (!isBiddingToolGenerateAnswer) return;
            updateCanvasStatus({
              groupId: data.groupId,
              status
            });
            break;
          case GenerateBidTaskAnswerSub.StreamType.ITERATE_CONTENT:
            try {
              iterationStreamRef.current =
                data.answerData?.order === 0
                  ? data.answerData.content ?? ''
                  : iterationStreamRef.current + (data.answerData?.content ?? '');

              updateCanvasStatus({
                iterateContentId: data.iterateContentId,
                status,
                iterationPendingContent: iterationStreamRef.current,
                taskId: canvasStatus.iterateContentStatus.iterationContentTaskId // taskId will already be set from calling the generation
              });

              // Reset iteration stream only when streaming is done
              if (status === StreamingCommon.AnswerStatus.DONE) {
                iterationStreamRef.current = null;
              }
            } catch (error) {
              resetIterationContentStatus();
              console.error('Error processing ITERATE_CONTENT stream:', error);
              Sentry.captureException(error, { extra: { tag: 'subscription-fetch-or-cache-error' } });
            }
            break;
          default:
            resetIterationContentStatus();
            console.error('Unexpected streamType received');
            Sentry.captureException(new Error(`Unhandled streamType received`), {
              extra: { data }
            });
            break;
        }
      },
      onError: error => {
        console.error('Subscription error:', error);
        Sentry.captureException(error, { extra: { tag: 'subscription-error' } });
      },
      skip
    }
  );
}

/**
 * A context that holds information about canvas' state excluding streaming state and the actual editable content.
 *
 * See models/canvas/providers.tsx for more detail about the data
 */
export const useCanvasState = (): EditorCanvasContextType => {
  const context = useContext(EditorCanvasContext);
  if (!context) throw new Error('useTasks must be used within TaskEditorProvider');
  return context;
};

export const useFilteredItems = <T extends { id: string }>(
  data: T[] | undefined,
  selectedIds: string[],
  renderLabel: (item: T) => ReactNode
): { label: ReactNode; id: string }[] => {
  return useMemo(() => {
    if (!data) return [];
    return data
      .filter(item => selectedIds.includes(item.id))
      .map(item => ({
        label: renderLabel(item),
        id: item.id
      }));
  }, [data, selectedIds, renderLabel]);
};

export function useGenerateAnswers(): [
  (variables: GenerateAnswerRequest) => void,
  { loading: boolean; error?: ApolloError }
] {
  const { t } = useTranslation();

  const [generateAnswers, { loading, error }] = useMutation<GenerateAnswerResponse, GenerateAnswerRequest>(
    GENERATE_ANSWER
  );

  const generateAnswersFn = useCallback(
    (variables: GenerateAnswerRequest) => {
      generateAnswers({
        variables
      }).catch(() => {
        notification.error({
          description: t('Common.unknownErrorDesc'),
          message: t('Common.unknownError')
        });
      });
    },
    [generateAnswers, t]
  );

  return useMemo(() => [generateAnswersFn, { loading, error }], [generateAnswersFn, loading, error]);
}

export function useGenerateIterationContent(): [
  (variables: GenerateIterationContentRequest) => void,
  { loading: boolean; error?: ApolloError }
] {
  const { t } = useTranslation();

  const [generateIterationContent, { loading, error }] = useMutation<
    GenerateAnswerResponse,
    GenerateIterationContentRequest
  >(GENERATE_ITERATION_CONTENT);

  const generateIterationContentFn = useCallback(
    (variables: GenerateIterationContentRequest) => {
      generateIterationContent({ variables }).catch(() => {
        notification.error({
          description: t('Common.unknownErrorDesc'),
          message: t('Common.unknownError')
        });
      });
    },
    [generateIterationContent, t]
  );

  return useMemo(() => [generateIterationContentFn, { loading, error }], [generateIterationContentFn, loading, error]);
}

export function useExportBidTasks(): {
  exportBidTasks: (variables: ExportBidTasksInput) => Promise<void>;
  isExportInProgress: (exportKeys: string[]) => boolean;
  loading: boolean;
  error?: ApolloError;
} {
  const { t } = useTranslation();
  const [exportStatus, updateExportStatus] = useExportFileStatus();
  const { id: bidId } = useParams<{ id: string }>();
  const [exportBidTasks, { loading, error }] = useMutation<ExportBidTasksOutput, ExportBidTasksInput>(EXPORT_BIDTASKS);

  const isExportInProgress = useCallback(
    (exportKeys: string[]) => {
      return exportStatus[exportKeys[0]]?.status === 'pending';
    },
    [exportStatus]
  );

  const exportBidTasksFn = useCallback(
    async (variables: ExportBidTasksInput) => {
      try {
        const response = await exportBidTasks({ variables });
        if (!response?.data || !bidId) return;
        updateExportStatus(response.data.exportBidTasks.operationId, 'pending', bidId);
      } catch {
        notification.error({
          description: t('BidSpaces.exportBidSpaceUnexpectedError'),
          message: t('BidSpaces.exportBidSpaceErrorMessage')
        });
      }
    },
    [bidId, exportBidTasks, t, updateExportStatus]
  );

  return useMemo(
    () => ({
      exportBidTasks: exportBidTasksFn,
      isExportInProgress,
      loading,
      error
    }),
    [exportBidTasksFn, isExportInProgress, loading, error]
  );
}

export function useUploadAttachment(
  onComplete?: () => void
): [(input: UploadAttachmentFnInput) => void, { error?: Error }] {
  const { t } = useTranslation();
  const [getUploadUrl] = useLazyQuery<
    { getBidTaskAnswerAttachmentUploadUrl: string },
    GetBidTaskAnswerAttachmentUploadUrlInput
  >(GET_BID_TASK_ANSWER_ATTACHMENT_UPLOAD_URL);
  let error;

  const uploadAttachmentFn = useCallback(
    async ({ file, attachments, bidTaskAnswerId }: UploadAttachmentFnInput) => {
      if (!bidTaskAnswerId) return;
      const fileName = getUniqueFileName(file.name, attachments);
      try {
        const { data } = await getUploadUrl({
          variables: {
            bidTaskAnswerId,
            fileName
          },
          fetchPolicy: 'no-cache'
        });

        const uploadUrl = data?.getBidTaskAnswerAttachmentUploadUrl;

        if (uploadUrl) {
          // prep for upload
          addBeforeUnloadListener();
          updateCacheOnAttachmentBeforeUpload(bidTaskAnswerId, fileName);

          // upload the file
          const buffer = await file.arrayBuffer();

          await fetch(uploadUrl, {
            method: 'put',
            body: buffer
          });
        }
      } catch (error) {
        error = error;
        notification.error({
          description: t('Common.unknownErrorDesc'),
          message: t('Common.unknownError')
        });
      } finally {
        // remove the beforeunload listener
        removeBeforeUnloadListener();
        // update the cache
        updateCacheOnAttachmentAfterUpload(bidTaskAnswerId, fileName);

        // run any post upload handlers
        onComplete?.();
      }
    },
    [getUploadUrl, onComplete, t]
  );

  return [uploadAttachmentFn, { error }];
}

export function useDeleteAttachments(): [
  (variables: DeleteBidTaskAnswerAttachmentsInput) => void,
  { loading: boolean; error?: ApolloError }
] {
  const { t } = useTranslation();
  const [deleteAttachments, { loading, error }] = useMutation<
    { deleteBidTaskAnswerAttachments: DeleteAttachmentsResult },
    DeleteBidTaskAnswerAttachmentsInput
  >(DELETE_BID_TASK_ANSWER_ATTACHMENTS);

  const deleteAttachmentsFn = useCallback(
    (variables: DeleteBidTaskAnswerAttachmentsInput) => {
      deleteAttachments({
        variables,
        update: updateCacheOnAttachmentsDelete(variables.bidTaskAnswerId)
      }).catch(() => {
        notification.error({
          description: t('Common.unknownErrorDesc'),
          message: t('Common.unknownError')
        });
      });
    },
    [deleteAttachments, t]
  );

  return [deleteAttachmentsFn, { loading, error }];
}

export const useDownloadAttachment = (): [
  (bidTaskAnswerId: string, fileName: string) => Promise<void>,
  { error?: Error }
] => {
  const { t } = useTranslation();
  const [getDownloadUrl] = useLazyQuery<
    { getBidTaskAnswerAttachmentDownloadUrl: string },
    { bidTaskAnswerId: string; fileName: string }
  >(GET_BID_TASK_ANSWER_ATTACHMENT_DOWNLOAD_URL);
  let error;

  const downloadAttachmentFn = async (bidTaskAnswerId: string, fileName: string): Promise<void> => {
    try {
      const { data } = await getDownloadUrl({
        variables: {
          bidTaskAnswerId,
          fileName
        },
        fetchPolicy: 'no-cache'
      });

      const downloadUrl = data?.getBidTaskAnswerAttachmentDownloadUrl;
      if (downloadUrl) {
        downloadFile(downloadUrl, fileName);
      }
    } catch (error) {
      error = error;
      notification.error({
        description: t('Common.unknownErrorDesc'),
        message: t('Common.unknownError')
      });
    }
  };

  return [downloadAttachmentFn, { error }];
};

export function useActiveCanvasTask(editorFromNode?: Editor): {
  activeTaskId: string | null;
  activeTaskNode: Node | null;
  task: BidTask | null;
  loading: boolean;
} {
  const { editor: currentEditor } = useCurrentEditor();
  const editor = useMemo(() => editorFromNode ?? currentEditor, [editorFromNode, currentEditor]);
  const [activeTaskId, setActiveTaskId] = useState<string | null>(null);
  const [activeTaskNode, setActiveTaskNode] = useState<Node | null>(null);

  const { data: task, loading } = useLoadTask(activeTaskId ?? '', !activeTaskId);

  const updateTask = useCallback(() => {
    setActiveTaskId(getActiveBidTaskId(editor));
    setActiveTaskNode(getActiveBidTask(editor));
  }, [editor]);

  useEffect(() => {
    if (!editor) return;

    editor.on('selectionUpdate', updateTask);
    updateTask(); // Ensure we get the task immediately on mount

    return () => {
      editor.off('selectionUpdate', updateTask);
    };
  }, [editor, updateTask]);

  return useMemo(() => {
    return {
      activeTaskId,
      activeTaskNode,
      task,
      loading
    };
  }, [activeTaskId, activeTaskNode, loading, task]);
}

export function useScrollToTask(editor: Editor | null): {
  scrollToTask: (
    taskId: string | null,
    behavior?: ScrollBehavior,
    block?: ScrollLogicalPosition,
    config?: {
      withOffset?: boolean;
      offset?: number;
      shouldSetTextSelection?: boolean;
      scrollToSelection?: boolean;
    }
  ) => void;
} {
  const scrollAnimationRef = useRef<number | null>(null);

  const scrollToTask = useCallback(
    (
      taskId: string | null,
      behavior: ScrollBehavior = 'auto',
      block: ScrollLogicalPosition = 'start',
      config: {
        withOffset?: boolean;
        offset?: number;
        shouldSetTextSelection?: boolean;
        scrollToSelection?: boolean;
      } = {}
    ) => {
      // Default values of the config
      const {
        withOffset = true,
        offset = CANVAS_PAGE_TOP_BOUNDARY,
        shouldSetTextSelection = true,
        scrollToSelection = false
      } = config;

      if (!editor || !taskId) return;

      const { pos, node } = findNodeById(editor, taskId);

      if (pos === null || pos === undefined || !node) return;

      const domNode = scrollToSelection
        ? (editor.view.domAtPos(editor.state.selection.to).node as HTMLElement)
        : (editor.view.nodeDOM(pos) as HTMLElement);

      if (domNode) {
        if (scrollAnimationRef.current) cancelAnimationFrame(scrollAnimationRef.current);

        scrollAnimationRef.current = requestAnimationFrame(() => {
          domNode.scrollIntoView({ behavior, block });

          // Add an offset to the scrolling
          const rect = domNode.getBoundingClientRect();
          const topPosition = rect.top + window.scrollY - offset;

          withOffset &&
            window.scrollTo({
              top: topPosition,
              behavior
            });
        });
      }

      shouldSetTextSelection &&
        editor
          .chain()
          .focus()
          .setTextSelection(pos + node.nodeSize - 2)
          .run();
    },
    [editor]
  );

  return useMemo(() => {
    return { scrollToTask };
  }, [scrollToTask]);
}

export function useLoadGroupTasksInCanvas(): {
  data: BidTask[];
  loading: boolean;
  error?: ApolloError;
} {
  const { groupId } = useParams<CanvasURLParams>();

  const {
    data: tasksData,
    loading: tasksDataLoading,
    error: tasksDataError
  } = useLoadTasks({
    groupId: groupId ?? '',
    isSubmitted: true,
    skip: !groupId
  });
  return {
    data: tasksData?.tasks.filter(isNotUndefined) ?? [],
    loading: tasksDataLoading,
    error: tasksDataError
  };
}
