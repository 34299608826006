import classNames from 'classnames';
import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { useContentLibrary } from 'src/models/contentLibrary/ContentLibrary/hooks';
import { UserPicker } from 'src/common';
import { useActiveUsers } from 'src/models/users/hooks';
import { ContentLibraryTrackFilter, trackFilteringCL } from 'src/segment/events';
import { ContentLibraryEventSource } from 'src/models/contentLibrary/ContentLibrary/types';
import { AssignedTo } from 'src/models/users/types';

export interface Props {
  eventSource: ContentLibraryEventSource;
}
const ContentLibraryFilterAssignedTo: FC<Props> = props => {
  const { eventSource } = props;
  const { t } = useTranslation();

  const users = useActiveUsers();
  const { data: state } = useContentLibrary();
  const currentVars = useMemo(() => state?.currentVars.filters ?? {}, [state]);
  const updateVars = state?.updateVars;

  const selectedAssignedUsers = useMemo(
    () => (currentVars?.assignedTo ? currentVars.assignedTo : undefined),
    [currentVars]
  );

  const onChangeAssigned = useCallback(
    ({ id: assignedTo }: AssignedTo, checked?: boolean) => {
      const previousValue = selectedAssignedUsers || [];
      const currentValue = checked ? [...previousValue, assignedTo] : previousValue.filter(item => item !== assignedTo);
      updateVars?.({ assignedTo: currentValue });
      trackFilteringCL(eventSource, ContentLibraryTrackFilter.assignedTo);
    },
    [eventSource, selectedAssignedUsers, updateVars]
  );

  return (
    <div className={classNames(styles.filter, { [styles.isActive]: selectedAssignedUsers?.length })}>
      <UserPicker
        label={t('ContentLibrary.assignedTo')}
        users={users}
        onSelect={onChangeAssigned}
        selected={selectedAssignedUsers}
        className={styles.label}
        userPickerType="multiple"
        renderMode={'button'}
      />
    </div>
  );
};

export default ContentLibraryFilterAssignedTo;
