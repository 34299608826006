import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input, Modal } from 'src/common';
import { useCreateDecisionMatrixTemplate } from 'src/models/decisionMatrix/hooks';
import { useBidPreview } from 'src/models/bids/BidPreview/hooks';

interface Props {
  onClose: () => void;
}

export const SaveAsTemplateModal: FC<Props> = props => {
  const { onClose } = props;
  const { t } = useTranslation();

  const { data: bidPreviewData } = useBidPreview();
  const [createDecisionMatrixTemplate] = useCreateDecisionMatrixTemplate();

  const [form] = Form.useForm();
  const [title, setTitle] = useState('');

  const decisionMatrix = bidPreviewData?.decisionMatrix;

  const onSaveAsTemplate = useCallback(() => {
    const items = decisionMatrix?.items.map(item => ({ title: item.title }));
    if (items) {
      createDecisionMatrixTemplate({ items, title });
      onClose && onClose();
    }
  }, [createDecisionMatrixTemplate, decisionMatrix?.items, onClose, title]);

  return (
    <Modal
      visible
      title={t('DecisionMatrix.saveAsTemplate')}
      onCancel={onClose}
      footer={
        <>
          <Button type="text" onClick={onClose} data-action="close">
            {t('Common.cancel')}
          </Button>
          <Button type={'primary'} htmlType="submit" data-action="save" onClick={form.submit}>
            {t('Common.save')}
          </Button>
        </>
      }
    >
      <Form form={form} onFinish={onSaveAsTemplate} layout="vertical">
        <Form.Item
          label={t('DecisionMatrix.templateTitle')}
          name="title"
          rules={[{ required: true, message: t('DecisionMatrix.titleIsRequired') }]}
        >
          <Input
            onChange={e => setTitle(e.target.value)}
            name="title"
            type="text"
            maxLength={30}
            placeholder={t('DecisionMatrix.templateNamePlaceholder')}
            autoFocus
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SaveAsTemplateModal;
