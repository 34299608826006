import React, { FC, useMemo } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { Paths } from 'src/pages/paths';

export const TenderBidRedirect: FC = () => {
  const { id: bidId } = useParams<{ id?: string }>();

  const pathname = useMemo(() => (bidId ? Paths.OPPORTUNITY.replace(':id', bidId) : ''), [bidId]);

  return <Navigate to={{ pathname }} replace />;
};

export default TenderBidRedirect;
