import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import BidFiles from 'src/shared/BidFiles';
import { ToolboxHeader } from '../ToolboxHeader';
import { useCurrentSidebar } from 'src/shared/InfoSidebar/hooks';
import { SidebarMode } from 'src/shared/InfoSidebar/types';
import { TaskProvider } from 'src/models/bids/BidTask/providers';
import { RelatedSources } from 'src/shared/TaskPreview/RelatedSources';
import { useActiveCanvasTask } from 'src/models/canvas/hooks';
import styles from './index.module.scss';

export const ToolboxDocuments: FC = () => {
  const { t } = useTranslation();

  const { task: activeCanvasTask } = useActiveCanvasTask();
  const sidebarState = useCurrentSidebar();

  return sidebarState?.mode === SidebarMode.TOOLBOX_DOCUMENTS ? (
    <>
      <ToolboxHeader title={t('Toolbox.files')} />
      <BidFiles eventSource={'Bidding tool - Toolbox'} />
    </>
  ) : (
    activeCanvasTask && (
      <TaskProvider taskId={activeCanvasTask?.id ?? ''}>
        <ToolboxHeader title={t('BiddingTool.sources')} />
        <div className={styles.heading}>{activeCanvasTask.title}</div>
        <RelatedSources eventSource="editor" />
      </TaskProvider>
    )
  );
};

export default ToolboxDocuments;
