import React, { FC, useCallback } from 'react';
import { Button, Spinner } from 'src/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownload, faTrash } from '@fortawesome/pro-light-svg-icons';
import styles from './index.module.scss';
import { useDeleteAttachments, useDownloadAttachment } from 'src/models/canvas/hooks';
import { getIconByFileName } from 'src/helpers/fileTypeIcon';
import confirm from 'src/common/confirm';
import { useTranslation } from 'react-i18next';
import modalStyles from 'src/common/Modal/index.module.scss';
import { ListAttachment } from 'src/models/canvas/types';

interface AttachmentListProps {
  attachments?: ListAttachment[];
  bidTaskAnswerId: string;
}

const AttachmentList: FC<AttachmentListProps> = ({ attachments, bidTaskAnswerId }) => {
  const { t } = useTranslation();
  const [deleteAttachments] = useDeleteAttachments();
  const [downloadAttachment] = useDownloadAttachment();

  const onDeleteAttachment = useCallback(
    (fileName: string) => {
      confirm.confirm({
        title: t('Canvas.Attachments.deletionConfirmTitle'),
        content: t('Canvas.Attachments.deletionConfirmContent'),
        okText: t('Common.delete'),
        cancelText: t('Common.cancel'),
        className: modalStyles.confirmModal,
        centered: true,
        onOk: () => {
          deleteAttachments({
            fileNames: [fileName],
            bidTaskAnswerId
          });
        }
      });
    },
    [bidTaskAnswerId, deleteAttachments, t]
  );

  if (!attachments?.length) return null;

  return (
    <div className={styles.attachments} contentEditable={false}>
      {attachments.map(file => (
        <div className={styles.attachment} key={file.fileName}>
          <div className={styles.fileInfo}>
            <span className={styles.fileExt}>{file.loading ? <Spinner /> : getIconByFileName(file.fileName)}</span>
            <span className={styles.fileName}>{file.fileName}</span>
          </div>
          {!file.loading && (
            <div className={styles.actions}>
              <Button
                type="text"
                icon={<FontAwesomeIcon icon={faCloudDownload} />}
                onClick={() => downloadAttachment(bidTaskAnswerId, file.fileName)}
              />
              <Button
                type="text"
                icon={<FontAwesomeIcon icon={faTrash} />}
                onClick={() => {
                  onDeleteAttachment(file.fileName);
                }}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default AttachmentList;
