import React, { FC, useCallback, useMemo, useRef } from 'react';
import styles from './index.module.scss';
import {
  useBiddingGroup,
  useDndSortDrag,
  useDndSortDrop,
  useOpenTaskGroupsPrefs,
  useTasks,
  useTasksFilter
} from 'src/models/bids/BidFull/hooks';
import { TaskGroupTitleBulk } from '../TaskGroupTitleBulk';
import classNames from 'classnames';
import TasksList from '../TaskList';
import { DndType } from 'src/types/dnd';
import { isTasksFilterApplied } from 'src/models/bids/BidFull/helpers';
import { useDrop } from 'react-dnd';
import { useUpdateBidTasks } from 'src/models/bids/BidTask/hooks';
import { BidTaskDndItem } from 'src/models/bids/BidFull/types';
import { LexoRank } from 'lexorank';

interface Props {
  groupId: string;
  dndId: number;
  index: number;
  dropGroup: (dragIndex: number, hoverIndex: number) => void;
  moveGroup: (dragIndex: number, hoverIndex: number) => void;
}

export const TasksGroup: FC<Props> = ({ groupId, dndId, index, dropGroup, moveGroup }) => {
  const group = useBiddingGroup(groupId);
  const { filter } = useTasksFilter();
  const [updateTasks] = useUpdateBidTasks();
  const { tasksResponseData } = useTasks();

  const ref = useRef<HTMLDivElement>(null);
  const [openTaskGroupsIds] = useOpenTaskGroupsPrefs();
  const isExpanded = openTaskGroupsIds?.includes(groupId);
  const isFilterApplied = useMemo(() => isTasksFilterApplied(filter), [filter]);

  const [, drop] = useDndSortDrop(index, ref, dropGroup, moveGroup, DndType.BidTaskGroupItem);
  const [, drag] = useDndSortDrag(index, dndId, !isExpanded && !isFilterApplied, DndType.BidTaskGroupItem);

  const lastTaskRank = useMemo(
    () => tasksResponseData?.data?.tasks[tasksResponseData?.data?.tasks.length - 1]?.rank,
    [tasksResponseData?.data?.tasks]
  );

  drop(ref);
  drag(ref);

  const onDrop = useCallback(
    (item: BidTaskDndItem) => {
      if (!item || item.dndItem.groupId === group?.id) {
        return;
      } else {
        updateTasks({
          questions: [item.dndItem],
          groupId: group?.id ?? '',
          prevGroupId: item.dndItem.groupId,
          ranks: [
            LexoRank.parse(lastTaskRank ?? '')
              .between(LexoRank.max())
              .toString()
          ]
        });
      }
    },
    [group?.id, lastTaskRank, updateTasks]
  );

  const [{ isOver, item }, dropTask] = useDrop({
    accept: DndType.BidTaskItem,
    canDrop: () => !isFilterApplied,
    drop: onDrop,
    collect: monitor => ({
      isOver: monitor.isOver(),
      item: monitor.getItem()
    })
  });

  dropTask(ref);

  return group ? (
    <div
      className={classNames(styles.group, {
        [styles.isExpanded]: isExpanded,
        [styles.isOver]:
          isOver &&
          item.dndItem.groupId !== groupId &&
          !!isExpanded &&
          tasksResponseData?.data?.tasks &&
          tasksResponseData?.data?.tasks.length > 0
      })}
      id={groupId}
      ref={ref}
    >
      <TaskGroupTitleBulk groupId={groupId} />
      <div
        className={classNames(styles.content, {
          [styles.isExpanded]: isExpanded
        })}
      >
        <TasksList />
      </div>
    </div>
  ) : null;
};

export default TasksGroup;
