import React, { FC, useCallback, useMemo, useState } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import { Content } from 'src/models/contentLibrary/ContentLibrary';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, ConditionalWrapper, Skeleton, Tooltip, confirm } from 'src/common';
import {
  useContentLibrary,
  useContentLibraryNav,
  useDeleteContentLibrary
} from 'src/models/contentLibrary/ContentLibrary/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import ContentLibraryTag from '../ContentLibraryTags/ContentLibraryTagsArea/ContentLibraryTag';
import AssignToContentLibrary from 'src/shared/ContentLibrary/AssignToContentLibrary';
import { Tag } from 'src/models/contentLibrary/ContentLibraryTag';
import { faCloudArrowDown, faEye, faPen, faTrash, faSpinnerThird, faPaperclip } from '@fortawesome/pro-light-svg-icons';
import modalStyles from 'src/common/Modal/index.module.scss';
import { toHSLColor } from 'src/helpers/styles';
import { useActiveUsers } from 'src/models/users/hooks';
import { getUsersWithAccessToRooms } from 'src/models/contentLibrary/ContentLibrary/types';
import { FeatureFlag, downloadFile, useFeatureFlag } from 'src/helpers';
import DocumentViewer from '../../DocumentViewer/index';
import { ContentLibraryDocumentStatus } from '@tendium/prom-types/schema';
import { useCurrentSidebar } from 'src/shared/InfoSidebar/hooks';
import { SidebarMode } from 'src/shared/InfoSidebar/types';
import { toPdfHighlightsData } from 'src/shared/DocumentViewer/helpers';
import { fileToExtIcon } from 'src/helpers/fileExtToIcon';
import { useActiveCanvasTask, useUploadAttachment } from 'src/models/canvas/hooks';
import { trackBTUseGeneratedAnswer } from 'src/segment/events';

function limitTagsAndReturnRest(tags: Tag[], maxChars: number): [Tag[], Tag[]] {
  let currentChars = 0;
  let sliceIndex = 0;

  for (let i = 0; i < tags.length; i++) {
    if (currentChars + tags[i].title.length <= maxChars) {
      currentChars += tags[i].title.length;
      sliceIndex = i + 1;
    } else {
      break;
    }
  }

  const limitedTags = tags.slice(0, sliceIndex);
  const remainingTags = tags.slice(sliceIndex);

  return [limitedTags, remainingTags];
}

export const ContentLibraryDocumentCard: FC<{
  content: Content | null;
  id: string;
  onEditContentCard?: (content: Content) => void;
}> = ({ content, id, onEditContentCard }) => {
  const { task: activeCanvasTask } = useActiveCanvasTask();
  const [isUploading, setIsUploading] = useState(false);
  const [uploadFile] = useUploadAttachment(() => setIsUploading(false));
  const { t } = useTranslation();
  const { selectedPairIds, setSelectedPairIds } = useContentLibrary();
  const isBiddingGPTContentLibrary = useFeatureFlag(FeatureFlag.BiddingTool_BiddingGPT_ContentLibrary);
  const users = useActiveUsers();
  const { isRoomPage, isCanvasPage, isTaskPage } = useContentLibraryNav();
  const sidebarState = useCurrentSidebar();
  const isSidebarMode =
    sidebarState?.mode === SidebarMode.CONTENT_LIBRARY || sidebarState?.mode === SidebarMode.TOOLBOX_CONTENT_LIBRARY;

  const [isDocumentViewerVisible, setDocumentViewerVisible] = useState(false);

  const usersWithAccess = useMemo(() => {
    return content?.room ? getUsersWithAccessToRooms(users, [content?.room]) : [];
  }, [content, users]);

  const tags = useMemo(() => content?.tags ?? [], [content]);
  const [limitedTags, remainingTags] = limitTagsAndReturnRest(tags, 40);

  const onChangeSelection = useCallback(
    (checked: boolean) => {
      setSelectedPairIds(checked ? [...selectedPairIds, id] : selectedPairIds.filter(item => item !== id));
    },
    [id, selectedPairIds, setSelectedPairIds]
  );

  const [deleteContentLibraryContent] = useDeleteContentLibrary();

  const onRemoveRows = useCallback(() => {
    confirm.confirm({
      title: t('ContentLibrary.deleteCard.title'),
      okText: t('Common.delete'),
      content: t('ContentLibrary.deleteCard.desc'),
      cancelText: t('Common.cancel'),
      className: modalStyles.confirmModal,
      centered: true,
      onOk() {
        if (content) {
          deleteContentLibraryContent({ ids: [content?.id] });
        }
      }
    });
  }, [content, deleteContentLibraryContent, t]);

  const handleUpdateComplete = useCallback(() => {
    setSelectedPairIds([]);
  }, [setSelectedPairIds]);

  const highlights = useMemo(() => {
    if (isRoomPage || isTaskPage) return [];
    if (!isCanvasPage) return [];
    if (isCanvasPage && sidebarState?.mode !== SidebarMode.CANVAS_TASK_SOURCES) return [];
    if (!content?.documentPositions || !content?.documentPositions.length) return [];

    return toPdfHighlightsData([], content.documentPositions);
  }, [content?.documentPositions, isCanvasPage, isRoomPage, isTaskPage, sidebarState?.mode]);

  const onOpenDocumentViewer = useCallback(() => {
    setDocumentViewerVisible(true);
  }, []);
  const onCloseDocumentViewer = useCallback(() => {
    setDocumentViewerVisible(false);
  }, []);

  if (!content) return null;

  const isFilePdf = (filename: string | undefined): boolean => {
    if (!filename) return true;
    const extension = filename?.split('.').pop()?.toLowerCase();
    return extension === 'pdf';
  };

  const fileIcon = fileToExtIcon(content?.document?.fileName);

  const onDownload = (): void => {
    downloadFile(content?.document.getUrl, content?.document.fileName);
  };

  const onAttachFileToTask = async (): Promise<void> => {
    if (!content?.document.getUrl || isUploading) return;
    setIsUploading(true);
    trackBTUseGeneratedAnswer({ type: 'File' });
    const response = await fetch(content.document.getUrl);
    const blob = await response.blob();
    const file = new File([blob], content.document.fileName, { type: blob.type });

    uploadFile({
      file,
      attachments: activeCanvasTask?.answer?.attachments.list ?? [],
      bidTaskAnswerId: activeCanvasTask?.answer?.id
    });
  };

  const hasLongPath = content.question.split('/').length > 2;

  const formatedQuestion = hasLongPath ? '.../' + content.question.split('/').slice(-2).join('/') : content.question;

  const isError = content?.document.status === ContentLibraryDocumentStatus.ERROR;
  const isLoading = content?.document.status === ContentLibraryDocumentStatus.IN_PROGRESS;
  const isDone = content?.document.status === ContentLibraryDocumentStatus.DONE;
  const isWaitingForData = !content?.document.status;

  return (
    <div className={classNames(styles.box, { [styles.checkboxSpacing]: !isSidebarMode })}>
      {!isSidebarMode && content.room.accessToEdit && sidebarState?.mode !== SidebarMode.CANVAS_TASK_SOURCES && (
        <Checkbox
          checked={selectedPairIds.includes(id)}
          onChange={e => onChangeSelection(e.target.checked)}
          className={classNames(styles.checkbox, { [styles.isVisible]: selectedPairIds.length })}
        />
      )}
      {sidebarState?.mode !== SidebarMode.CANVAS_TASK_SOURCES && (
        <div>
          <div className={styles.cardHeader}>
            <ConditionalWrapper
              condition={hasLongPath}
              wrapper={children => (
                <Tooltip title={content.question} placement={'topLeft'}>
                  {children}
                </Tooltip>
              )}
            >
              <h3 className={classNames(styles.question)}>{formatedQuestion}</h3>
            </ConditionalWrapper>
            {!isSidebarMode && (
              <Button
                type={'text'}
                className={styles.button}
                icon={<FontAwesomeIcon icon={faTrash} onClick={onRemoveRows} />}
                disabled={!content.room.accessToEdit}
              />
            )}
            {!isSidebarMode && (
              <Button
                type={'text'}
                className={styles.button}
                icon={<FontAwesomeIcon icon={faPen} onClick={() => onEditContentCard?.(content)} />}
                disabled={!content.room.accessToEdit}
              />
            )}
            {isBiddingGPTContentLibrary && (
              <AssignToContentLibrary
                ids={[id]}
                assignedTo={content.assignedTo?.id}
                disabled={!content.room.accessToEdit}
                usersWithAccess={usersWithAccess}
                onUpdateComplete={handleUpdateComplete}
              />
            )}
          </div>
          <p className={classNames(styles.answer)}>{content.answer}</p>
        </div>
      )}

      <div className={styles.actionBar}>
        {isWaitingForData ? (
          <Skeleton />
        ) : (
          <div className={styles.documentSection}>
            <div className={styles.leftSideWrapper}>
              <FontAwesomeIcon icon={fileIcon} />
            </div>
            <div className={styles.rightSideWrapper}>
              {isError ? (
                <div>
                  <p style={{ width: '70%' }}>
                    <span style={{ color: 'var(--ui-danger)' }}>{t('ContentLibrary.failedToUpload')}</span> <br />
                    {t('ContentLibrary.pleaseTryAgain')}
                  </p>
                  <p></p>
                </div>
              ) : isLoading ? (
                <div className={styles.documentStatus}>
                  <FontAwesomeIcon className={styles.spinnerIcon} icon={faSpinnerThird} spin={true} />
                  <p>{t('ContentLibrary.processingFile')}</p>
                </div>
              ) : isDone ? (
                ''
              ) : (
                ''
              )}
              <div className={styles.buttonWrapper}>
                <Tooltip title={!isFilePdf(content.document.fileName) ? t('ContentLibrary.noPreviewFile') : ''}>
                  <div className={styles.buttonWrapper}>
                    <Button
                      icon={<FontAwesomeIcon icon={faEye} />}
                      type={'default'}
                      onClick={onOpenDocumentViewer}
                      disabled={!isFilePdf(content.document.fileName)}
                    >
                      {t('Common.preview')}
                    </Button>
                  </div>
                </Tooltip>
                <Button icon={<FontAwesomeIcon icon={faCloudArrowDown} />} type={'default'} onClick={onDownload}>
                  {t('Common.download')}
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={classNames(styles.metaInfoBar)}>
        <div className={styles.metaInfo}>
          <span className={styles.muted}>{`${t('ContentLibrary.room')}:`}</span>
          <span className={styles.roomTitle}>
            <FontAwesomeIcon icon={faCircle} color={toHSLColor(content.room.id)} /> {`${content.room.title} `}
          </span>

          <span>
            {!!limitedTags.length && (
              <>
                <span className={styles.muted}>{`${t('ContentLibrary.tags')}: `}</span>
                {limitedTags.map(tag => (
                  <ContentLibraryTag key={tag.id} tag={tag} disabled={true} />
                ))}
              </>
            )}
            {!!remainingTags.length && (
              <Tooltip
                placement={'bottomRight'}
                title={remainingTags.map(tag => (
                  <ContentLibraryTag key={tag.id} tag={tag} disabled={true} />
                ))}
              >
                <span className={styles.muted}>{`+${remainingTags.length}`}</span>
              </Tooltip>
            )}
          </span>
        </div>
        <div className={classNames(styles.metaInfo, styles.metaInfoUser)}>
          <div>
            <span className={styles.muted}>{`${t('ContentLibrary.createdBy')}:`}</span>
            {` ${content.createdBy?.userName} `}
            <span className={styles.muted}>{isWaitingForData ? '' : content.createdAt}</span>
          </div>
          <div>
            <span className={styles.muted}>{`${t('ContentLibrary.editedBy')}:`}</span>
            {` ${content.modifiedBy?.userName} `}
            <span className={styles.muted}>{isWaitingForData ? '' : content.modifiedAt}</span>
          </div>
        </div>
        {activeCanvasTask?.answer?.id && (
          <Button
            className={styles.attachFileButton}
            icon={<FontAwesomeIcon icon={faPaperclip} />}
            type="link"
            onClick={onAttachFileToTask}
            disabled={isUploading}
          >
            {t('BiddingTool.attachFile')}
          </Button>
        )}
      </div>
      {isDocumentViewerVisible && (
        <DocumentViewer
          loading={!content.document.getUrl}
          url={content.document.getUrl}
          title={content.question}
          onClose={onCloseDocumentViewer}
          highlights={highlights}
        />
      )}
    </div>
  );
};
