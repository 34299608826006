import React, { FC } from 'react';
import { useWsUsersAndTeams } from 'src/models/workspace/hooks';
import { CommentParentType } from 'src/models/comments/types';
import { Comments } from 'src/shared';

import { useTask } from 'src/models/bids/BidTask/hooks';
import styles from './index.module.scss';
import { useBidWorkspace } from 'src/models/bids/Bids/hooks';

export const TaskComments: FC = () => {
  const { data: task } = useTask();
  const { workspace } = useBidWorkspace(task?.bidId);
  const users = useWsUsersAndTeams(workspace?.id);

  return (
    <>
      {task && (
        <div className={styles.comments}>
          <Comments
            comments={task.comments}
            parentId={task.id}
            parentType={CommentParentType.BidQuestion}
            users={users}
            eventSource={'Task Preview'}
          />
        </div>
      )}
    </>
  );
};

export default TaskComments;
