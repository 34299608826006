import React, { FC, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, notification, Upload } from 'src/common';
import styles from './index.module.scss';
import { RcFile } from 'antd/lib/upload';
import { useAttachFileToBid } from 'src/models/bids/BidAttachments/hooks';
import { MyFilesContent } from './MyFilesContent';

interface Props {
  bidId: string;
}

const FILE_LIMIT = 500;

export const MyFiles: FC<Props> = props => {
  const { bidId } = props;
  const { t } = useTranslation();
  const ref = useRef<HTMLInputElement>(null);

  const [attachFileToBid] = useAttachFileToBid();

  const onAttachFileToBid = useCallback(
    (file: RcFile | File, files: RcFile[] | File[]) => {
      if (file !== files[0]) return;

      if (files.length > FILE_LIMIT) {
        notification.error({
          description: t('Common.unknownErrorDesc'),
          message: t('Common.unknownError')
        });
        return;
      }

      files && bidId && attachFileToBid(files, bidId);
    },
    [attachFileToBid, bidId, t]
  );

  const handleFolderSelect = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const files = Array.from(event.target.files || []);
    // Filter out hidden files (files that start with a dot)
    const filteredFiles = files.filter(file => !file.name.startsWith('.'));

    onAttachFileToBid(filteredFiles[0], filteredFiles);
  };

  useEffect(() => {
    if (ref.current !== null) {
      ref.current.setAttribute('directory', '');
      ref.current.setAttribute('webkitdirectory', '');
    }
  }, [ref]);

  return (
    <>
      <MyFilesContent bidId={bidId} />
      <div className={styles.uploadContainer}>
        <Upload name={'file'} showUploadList={false} beforeUpload={onAttachFileToBid} multiple>
          <Button type={'default'} className={styles.test}>
            {t('Common.uploadFile')}
          </Button>
        </Upload>
        <>
          <label htmlFor="folder-input" className={styles.inputFolder}>
            {t('Common.uploadFolder')}
          </label>
          <input
            id="folder-input"
            type="file"
            ref={ref}
            onChange={handleFolderSelect}
            multiple
            style={{ display: 'none' }}
          />
        </>
      </div>
    </>
  );
};

export default MyFiles;
