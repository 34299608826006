import React, { FC, useCallback } from 'react';
import styles from '../index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { Button, confirm } from 'src/common';
import modalStyles from 'src/common/Modal/index.module.scss';
import { useTranslation } from 'react-i18next';
import { useKeywords } from 'src/models/keywords/hooks';
import { trackMPEditKeywordDelete } from 'src/segment/events';

interface Props {
  keywords: { values: { id: string; value: string }[]; groupId: string | null }[];
  deleteDescText: string;
  mode: 'single' | 'bulk';
  onBulkDelete?: () => void;
}

export const DeleteButton: FC<Props> = props => {
  const { keywords, deleteDescText, mode, onBulkDelete } = props;
  const { t } = useTranslation();
  const { data, deleteKeywords } = useKeywords();

  const onDelete = useCallback(() => {
    confirm.confirm({
      title: t('Tenders.removeKeyword'),
      okText: t('Common.delete'),
      content: deleteDescText,
      cancelText: t('Common.cancel'),
      className: modalStyles.confirmModal,
      centered: true,
      onOk() {
        deleteKeywords(keywords);
        trackMPEditKeywordDelete(data.eventNamePrefix, data.filterLogic, mode, data.mp);
        onBulkDelete && onBulkDelete();
      }
    });
  }, [
    t,
    deleteDescText,
    deleteKeywords,
    keywords,
    data.eventNamePrefix,
    data.filterLogic,
    data.mp,
    mode,
    onBulkDelete
  ]);

  return (
    <Button
      className={styles.deleteButton}
      type={'text'}
      icon={<FontAwesomeIcon icon={faTrash} onClick={onDelete} />}
    />
  );
};

export default DeleteButton;
