import React, { FC, useCallback, useState } from 'react';
import { Button, FullscreenOverlay, Skeleton, WorkspaceBadge } from 'src/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';
import styles from '../index.module.scss';
import { useWorkspaces } from 'src/models/workspace/hooks';
import { useBidWorkspace, useMoveToWorkspace } from 'src/models/bids/Bids/hooks';
import { WorkspacePickerProps } from '..';
import classNames from 'classnames';
import WorkspacePickerItem from '../WorkspacePickerItem';
import { useIsMobile } from 'src/reactiveVars';

const WorkspacePickerMobile: FC<WorkspacePickerProps> = props => {
  const {
    bidIds,
    originIds,
    eventSource,
    eventType,
    originType,
    relevanceScores,
    disabled,
    isSelected,
    size,
    matchData,
    onChange
  } = props;
  const { t } = useTranslation();
  const [isMobile] = useIsMobile();
  const [menuVisible, setMenuVisible] = useState<boolean>(false);

  const { workspace: currentWorkspace, inNoAccessWorkspace } = useBidWorkspace(
    (bidIds && !!bidIds.length && bidIds[0].id) || undefined
  );

  const { data, loading } = useWorkspaces();
  const workspaces = data && data.filter(ws => ws.isActive).sort((a, b) => a.name.localeCompare(b.name, 'sv'));

  const [moveToWorkspace, { loading: isLoading }] = useMoveToWorkspace();

  const toggleMenuVisible = useCallback(() => {
    setMenuVisible(prevVisible => !prevVisible);
  }, []);

  const onMoveToWorkspace = useCallback(
    async (workspaceId: string) => {
      if (isLoading) return;

      if (workspaceId && (originIds || bidIds)) {
        await moveToWorkspace({
          workspaceId,
          eventSource,
          eventType,
          originType,
          originIds,
          bids: bidIds,
          relevanceScores,
          matchData
        });
        onChange && onChange();
        toggleMenuVisible();
      }
    },
    [
      isLoading,
      originIds,
      bidIds,
      moveToWorkspace,
      eventSource,
      eventType,
      originType,
      relevanceScores,
      matchData,
      onChange,
      toggleMenuVisible
    ]
  );

  const workSpaceBadge =
    !!currentWorkspace && !isSelected ? (
      <WorkspaceBadge
        title={currentWorkspace.name}
        color={currentWorkspace.color}
        mode={'full'}
        loading={loading}
        size={size}
      />
    ) : null;

  const overlayContent = (
    <>
      {workspaces && !loading ? (
        <ul
          className={classNames(styles.wsList, {
            [styles.wsListMobile]: isMobile
          })}
        >
          {workspaces.map(ws => (
            <WorkspacePickerItem key={ws.id} ws={ws} loading={!!isLoading} onMoveToWorkspace={onMoveToWorkspace} />
          ))}
        </ul>
      ) : (
        <Skeleton loading active />
      )}
    </>
  );

  return (
    <>
      <Button
        type={'default'}
        onClick={toggleMenuVisible}
        disabled={inNoAccessWorkspace || disabled}
        className={styles.workspacePickerButtonMobile}
      >
        {workSpaceBadge ? (
          workSpaceBadge
        ) : (
          <div className={styles.wsButtonContent}>
            <FontAwesomeIcon icon={faPlusSquare} className={styles.wsQualifyIcon} />
            <span className={styles.buttonLabel}>{t('BidSpaces.addToBidspace')}</span>
          </div>
        )}
      </Button>
      <FullscreenOverlay
        visible={menuVisible}
        onVisibleChange={toggleMenuVisible}
        overlayTitle={t('BidSpaces.addToBidspace')}
        overlayContent={overlayContent}
      />
    </>
  );
};

export default WorkspacePickerMobile;
