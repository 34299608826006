import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownload } from '@fortawesome/pro-light-svg-icons';
import { downloadFile } from 'src/helpers/files';

interface Props {
  url: string;
  fileName: string;
}

export const DownloadAllAttachments: FC<Props> = props => {
  const { url, fileName } = props;
  const { t } = useTranslation();

  const onDownload = useCallback(() => {
    downloadFile(url, fileName);
  }, [fileName, url]);

  return (
    <Button onClick={onDownload} type={'text'} icon={<FontAwesomeIcon icon={faCloudDownload} />}>
      {t('CallOffs.downloadAllAttachments')}
    </Button>
  );
};

export default DownloadAllAttachments;
