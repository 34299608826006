import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  updateCreateCacheOnFileUploadContentLibrary,
  useContentLibraryNav
} from 'src/models/contentLibrary/ContentLibrary/hooks';
import { ContentLibraryEventSource } from 'src/models/contentLibrary/ContentLibrary/types';
import { FileExt } from 'src/models/file/types';
import { Button, Form, InfoIcon, Modal, notification } from 'src/common';
import FolderInput from 'src/common/FolderInput';
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';
import FileItem from '../UploadFile/FileItem';
import styles from './index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { trackAddFileCL } from 'src/segment/events';
import RoomSelect from 'src/shared/RoomSelect';
import { useApp } from 'src/models/auth';
import { useContentLibrarySignedUrls } from 'src/models/contentLibrary/ContentLibrary/fileUploads';

interface Props {
  onClose: () => void;
  extensions: FileExt[];
}

export const UploadFolderModal: FC<Props> = ({ onClose, extensions }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { user } = useApp();
  const { isRoomPage, isTaskPage } = useContentLibraryNav();

  const onUpload = useContentLibrarySignedUrls();

  const [fileList, setFileList] = useState<File[]>([]);

  const eventSource = useMemo(() => {
    if (isRoomPage) return ContentLibraryEventSource.room;
    if (isTaskPage) return ContentLibraryEventSource.taskPage;
    return ContentLibraryEventSource.contentLibrary;
  }, [isRoomPage, isTaskPage]);

  const onFinish = useCallback(async (): Promise<void> => {
    const roomId = form.getFieldValue('roomId');
    if (!roomId) {
      form.validateFields();
      return;
    }

    onClose();

    const failedFiles: string[] = [];

    const signedUrlVars = fileList.map(file => ({
      targetPath: file.webkitRelativePath.normalize()
    }));

    const response = await onUpload({ targetPaths: signedUrlVars.map(item => item.targetPath), roomId });

    notification.open({
      icon: <FontAwesomeIcon icon={faSpinnerThird} spin={true} className={styles.spinner} />,
      description: t('ContentLibrary.contentUploadModals.importProgressMessage'),
      message: t('ContentLibrary.contentUploadModals.importProgressTitle'),
      key: 'contentUploading'
    });

    const uploadPromises = fileList.map(async (file, index) => {
      try {
        await fetch(response?.signedUrls[index] as RequestInfo, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/octet-stream'
          },
          body: file
        });

        if (response && user) {
          const BATCH_SIZE = 6;
          // Batches created to avoid spammy feeling when uploading a lot of files
          const currentBatch = Math.floor(index / BATCH_SIZE);
          const batchStart = currentBatch * BATCH_SIZE;
          const batchEnd = Math.min(batchStart + BATCH_SIZE, fileList.length);

          // Only update cache when we're at the last file of a batch or at the last file overall
          if ((index + 1) % BATCH_SIZE === 0 || index === fileList.length - 1) {
            for (let i = batchStart; i < batchEnd; i++) {
              updateCreateCacheOnFileUploadContentLibrary(response.contentRowIds[i], roomId, user, fileList[i].name);
            }
          }
        }
      } catch (err) {
        failedFiles.push(file.name);
      }
    });

    await Promise.all(uploadPromises);
    trackAddFileCL(eventSource, ['Room', 'Folder']);

    if (failedFiles.length > 0) {
      notification.error({
        description: `${t('Common.unknownErrorDesc')} ${failedFiles.join(', ')}`,
        message: t('ErrorBoundary.title'),
        key: 'contentUploadingError'
      });
    }
  }, [form, onClose, fileList, onUpload, t, eventSource, user]);

  const onDelete = useCallback((fileToDelete: string) => {
    setFileList(prevFileList => prevFileList.filter(file => file.webkitRelativePath !== fileToDelete));
  }, []);

  const beforeUpload = useCallback(
    (files: File[]) => {
      files.forEach(file => {
        const isFileAlreadyAdded = fileList.some(
          existingFile => existingFile.webkitRelativePath === file.webkitRelativePath
        );

        if (isFileAlreadyAdded) {
          notification.error({
            description: t('ContentLibrary.contentUploadModals.fileAlreadyAddedError'),
            message: t('ErrorBoundary.title'),
            key: 'fileAlreadyAddedError'
          });
          return false;
        }

        setFileList(prev => [...prev, file]);
      });
    },
    [fileList, t]
  );

  return (
    <Modal
      title={fileList.length === 0 ? t('BidDrafts.chooseFolder') : t('ContentLibrary.chooseRoom')}
      visible
      onCancel={onClose}
      footer={
        fileList.length > 0 ? (
          <>
            <Button type="text" onClick={onClose}>
              {t('Common.cancel')}
            </Button>
            <Button onClick={onFinish} type={'primary'} disabled={false}>
              {t('Common.import')}
            </Button>
          </>
        ) : null
      }
    >
      <div className={styles.uploadingFilesContainer}>
        {fileList.map(file => (
          <div key={file.webkitRelativePath}>
            <FileItem extensions={extensions} fileName={file.webkitRelativePath} onDelete={onDelete} />
          </div>
        ))}
      </div>

      {fileList.length === 0 ? (
        <FolderInput onSelect={beforeUpload} />
      ) : (
        <Form layout="horizontal" form={form}>
          <div className={styles.roomContainer}>
            <FolderInput onSelect={beforeUpload} />
            <Form.Item
              name={'roomId'}
              label={t('ContentLibrary.chooseRoom')}
              className={styles.roomError}
              rules={[{ required: true, message: t('ContentLibrary.contentUploadModals.selectRoom') }]}
            >
              <div className={styles.infoWrapper}>
                <InfoIcon desc={t('ContentLibrary.contentUploadModals.roomTooltip')} />
                <RoomSelect form={form} placeholder={t('ContentLibrary.contentUploadModals.roomNotSelected')} />
              </div>
            </Form.Item>
          </div>
        </Form>
      )}
    </Modal>
  );
};

export default UploadFolderModal;
