import { FeatureFlag } from '@tendium/prom-types';
import { Skeleton } from 'antd';
import React, { FC } from 'react';
import { useFeatureFlag } from 'src/helpers';
import { BidChecklistProvider } from 'src/models/bids/BidChecklist/providers';
import BidChecklistActionMenu from 'src/shared/Bid/BidChecklist/ActionMenu';
import Checklist from 'src/shared/Bid/BidChecklist/Checklist';
import { useTranslation } from 'react-i18next';
import { useBidChecklist } from 'src/models/bids/BidChecklist/hooks';
import ToolboxHeader from '../ToolboxHeader';

interface Props {
  bidId: string;
}

export const ToolboxChecklist: FC<Props> = props => {
  const { bidId } = props;
  const { t } = useTranslation();
  const { loading } = useBidChecklist();

  const isChecklistAvailable = useFeatureFlag(FeatureFlag.Checklist);

  return !!loading ? (
    <Skeleton />
  ) : (
    <>
      {isChecklistAvailable && bidId && (
        <BidChecklistProvider bidId={bidId}>
          <ToolboxHeader
            title={t('Checklist.checklist')}
            info={t('Checklist.ChecklistDesc')}
            actions={<BidChecklistActionMenu />}
          />
          <Checklist />
        </BidChecklistProvider>
      )}
    </>
  );
};

export default ToolboxChecklist;
