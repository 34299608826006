import { PreviewDescriptionSetting } from 'src/models/users/Preferences/types';
import { trackWithSessionReplay } from '../helpers';

export type PreviewDescriptionEventSource = 'Bid preview' | 'Tender preview';

export function trackUpdatePreviewDescription(
  eventSource: PreviewDescriptionEventSource,
  type: PreviewDescriptionSetting
): void {
  trackWithSessionReplay('Preview Overview', {
    eventSource,
    type
  });
}
