const handleBeforeUnload = (event: BeforeUnloadEvent): void => {
  event.preventDefault();
  event.returnValue = 'Upload not finished. Are you sure you want to leave?';
};

export const addBeforeUnloadListener = (): void => {
  window.addEventListener('beforeunload', handleBeforeUnload);
};

export const removeBeforeUnloadListener = (): void => {
  window.removeEventListener('beforeunload', handleBeforeUnload);
};
