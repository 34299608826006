import React, { FC, useCallback } from 'react';
import { useCloseSidebar, useCurrentSidebar } from '../InfoSidebar/hooks';
import { SidebarMode } from '../InfoSidebar/types';
import { SidebarView } from '../InfoSidebar';
import BidTaskHeader from 'src/pages/TaskPage/BidTaskHeader';
import { TaskProvider } from 'src/models/bids/BidTask/providers';

import styles from './index.module.scss';
import { Tabs } from './PreviewTabs';
import TaskToolbar from './Toolbar';
import { TaskOverview } from './Overview';
import { ActivePreviewTab } from 'src/models/procurements/types';
import { usePreviewTabState } from 'src/models/procurements/hooks';
import { RelatedSources } from './RelatedSources';
import { useSearchParams } from 'react-router-dom';
import TaskComments from './TaskComments';

interface TaskPreviewProps {
  isDraft?: boolean;
}

export const TaskPreview: FC<TaskPreviewProps> = ({ isDraft = false }) => {
  const sidebarState = useCurrentSidebar();
  const [activeTab] = usePreviewTabState();
  const [searchParams] = useSearchParams();
  const previewId = searchParams.get('previewId');

  const closeSidebar = useCloseSidebar();
  const onClose = useCallback(() => {
    closeSidebar();
  }, [closeSidebar]);

  return sidebarState?.mode === SidebarMode.TASK_PREVIEW && sidebarState.id ? (
    <TaskProvider taskId={previewId ?? ''}>
      <SidebarView onClose={onClose} className={styles.preview}>
        <div className={styles.container}>
          <div className={styles.actions}>
            <BidTaskHeader isDraft={isDraft} />
            <TaskToolbar />
            <Tabs isDraft={isDraft} />
          </div>
          <div className={styles.tabsContainer}>
            {activeTab === ActivePreviewTab.Info && <TaskOverview isDraft={isDraft} />}
            {activeTab === ActivePreviewTab.Comments && <TaskComments />}
            {activeTab === ActivePreviewTab.RelatedSources && <RelatedSources eventSource="preview" />}
          </div>
        </div>
      </SidebarView>
    </TaskProvider>
  ) : null;
};
