import { MutationUpdaterFn } from '@apollo/client';
import { DeleteAttachmentsResult, ListAttachmentsResult } from './types';
import { cache } from 'src/lib/API/graphql/cache';
/**
 * Updates the cache when attachments are deleted
 * @param bidTaskAnswerId - The id of the bid task answer
 * @returns - A mutation updater function
 */
export function updateCacheOnAttachmentsDelete(
  bidTaskAnswerId: string
): MutationUpdaterFn<{ deleteBidTaskAnswerAttachments: DeleteAttachmentsResult }> {
  return (cache, { data }) => {
    const deletedPaths = data?.deleteBidTaskAnswerAttachments?.deletedPaths;
    if (!deletedPaths?.length) {
      return;
    }

    cache.modify({
      id: cache.identify({
        __typename: 'BidQuestionAnswer',
        id: bidTaskAnswerId
      }),
      fields: {
        attachments(existingAttachments) {
          if (!existingAttachments?.list?.length) return existingAttachments;

          const remainingAttachments = existingAttachments.list.filter(
            (attachment: { fileName: string }) => !deletedPaths.includes(attachment.fileName)
          );

          return {
            ...existingAttachments,
            list: remainingAttachments
          };
        }
      }
    });
  };
}

/**
 * Updates the cache before an attachment starts uploading
 * @param bidTaskAnswerId - The id of the bid task answer
 * @param fileName - The name of the file being uploaded
 */
export function updateCacheOnAttachmentBeforeUpload(bidTaskAnswerId: string, fileName: string): void {
  const answerRef = cache.identify({
    __typename: 'BidQuestionAnswer',
    id: bidTaskAnswerId
  });

  cache.modify({
    id: answerRef,
    fields: {
      attachments(existingAttachments: ListAttachmentsResult) {
        if (existingAttachments?.list.some(attachment => attachment.fileName === fileName)) {
          return existingAttachments;
        }

        return {
          ...existingAttachments,
          list: [
            ...existingAttachments.list,
            {
              fileName: fileName,
              parentId: bidTaskAnswerId,
              loading: true
            }
          ]
        };
      }
    }
  });
}
/**
 * Updates the cache after an attachment uploading is finished (can be successful or failed)
 * @param bidTaskAnswerId - The id of the bid task answer
 * @param fileName - The name of the file being uploaded
 */
export function updateCacheOnAttachmentAfterUpload(bidTaskAnswerId: string, fileName: string): void {
  const answerRef = cache.identify({
    __typename: 'BidQuestionAnswer',
    id: bidTaskAnswerId
  });

  cache.modify({
    id: answerRef,
    fields: {
      attachments(existingAttachments: ListAttachmentsResult) {
        return {
          ...existingAttachments,
          list: existingAttachments?.list.map(attachment => {
            if (attachment.fileName === fileName) {
              return {
                ...attachment,
                loading: false
              };
            }
            return attachment;
          })
        };
      }
    }
  });
}
