import { faPen } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'antd';
import classNames from 'classnames';
import React, { FC, MouseEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BoxFieldString } from 'src/models/procurements';
import { EditableText } from 'src/shared/Blocks';
import styles from './index.module.scss';
import { useIsBidStatusInteresting, useUpdateDecisionMatrix } from 'src/models/decisionMatrix/hooks';
import { IBoxFieldString } from 'src/models/procurements/Tender/types';
import { useBidPreview } from 'src/models/bids/BidPreview/hooks';
import { GoNoGoActions } from 'src/shared/Bid';
import { useSearchParams } from 'react-router-dom';

const MatrixComment: FC = () => {
  const { t } = useTranslation();

  const { data: bidPreviewData } = useBidPreview();
  const [updateDecisionMatrix] = useUpdateDecisionMatrix();
  const isBidStatusInterestingCat = useIsBidStatusInteresting(bidPreviewData?.status.id, bidPreviewData?.workspaceId);

  const [searchParams] = useSearchParams();
  const previewId = searchParams.get('previewId');

  const [isEditing, setEditing] = useState<boolean>(false);

  const enterEditing = useCallback((e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setEditing(true);
  }, []);

  const exitEditing = useCallback(() => {
    setEditing(false);
  }, []);

  const onChange = useCallback(
    (val: IBoxFieldString) => {
      if (bidPreviewData?.decisionMatrix?.id) {
        updateDecisionMatrix({ comment: val.string as string, id: bidPreviewData.decisionMatrix.id });
      }
      exitEditing();
    },
    [bidPreviewData?.decisionMatrix?.id, exitEditing, updateDecisionMatrix]
  );

  return (
    <div className={styles.commentSection}>
      {isEditing ? (
        <EditableText
          className={classNames(styles.comment, styles.isEditing)}
          onChange={onChange}
          onFinish={exitEditing}
          field={
            new BoxFieldString(
              bidPreviewData?.decisionMatrix?.comment ?? '',
              bidPreviewData?.decisionMatrix?.comment ?? ''
            )
          }
          autoSize={true}
          autoFocus
        />
      ) : (
        <div className={styles.commentContainer}>
          <div className={classNames(styles.comment, { [styles.isEmpty]: !bidPreviewData?.decisionMatrix?.comment })}>
            {!!bidPreviewData?.decisionMatrix?.comment ? (
              <span>{bidPreviewData.decisionMatrix.comment}</span>
            ) : (
              t('DecisionMatrix.leaveACommentPlaceholder')
            )}
          </div>
          <Button
            type={'text'}
            onClick={enterEditing}
            icon={<FontAwesomeIcon icon={faPen} />}
            className={styles.editButton}
          />
        </div>
      )}
      {!!isBidStatusInterestingCat && !bidPreviewData?.isRejected && !!previewId && (
        <GoNoGoActions
          bidId={bidPreviewData?.id ?? ''}
          isRejected={bidPreviewData?.isRejected ?? false}
          wsId={bidPreviewData?.workspaceId ?? ''}
          prevStatusId={bidPreviewData?.status.id ?? ''}
          statusCategory={bidPreviewData?.status.category}
        />
      )}
    </div>
  );
};

export default MatrixComment;
