import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, DatePicker, Radio, Select } from 'src/common';
import { ContractEndRangeMode, ContractEndSearchType } from '@tendium/prom-types';
import dayjs from 'dayjs';
import GenericSearchDropdown from '../../GenericSearchDropDown';
import styles from './index.module.scss';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { faDash, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useForm, useController } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import debounce from 'lodash/debounce';
import { trackECDateRange } from 'src/segment/events/expiringContracts';
import { trackTendersFilterContractEndTimeSpan } from 'src/segment/events';
import {
  contractEndFilterSchema,
  ContractEndFilterSchemaType,
  contractEndSchema,
  TendersSearchSchemaType
} from 'src/models/procurements/TendersSearch/schemas';
import { dateRangeSchema, oNonNegativeIntLike, zodParseErrorHandler } from 'src/helpers/zod';
import IntegerInput from 'src/common/IntegerInput';
import { TenderFilterRefProps, TendersFilterProps } from '../types';

const formSchema = contractEndSchema.omit({ start: true, end: true, strictPeriodOverlap: true }).extend({
  // Workaround for setValue not able to set to undefined
  // https://react-hook-form.com/docs/useform/setvalue
  absoluteStartEnd: dateRangeSchema.nullable(),
  relativeStart: oNonNegativeIntLike.or(z.string()),
  relativeEnd: oNonNegativeIntLike.or(z.string())
});

type FormSchema = z.infer<typeof formSchema>;

const { RangePicker } = DatePicker;

type Props = Pick<TendersFilterProps, 'eventSource' | 'values' | 'onChange' | 'isDiffFromInit' | 'isDiffFromProfile'>;

const FilterContractEnd = forwardRef<TenderFilterRefProps, Props>(
  ({ eventSource, values, onChange, isDiffFromProfile, isDiffFromInit }, ref) => {
    const { t } = useTranslation();
    const toFormSchema = useCallback(
      (values: TendersSearchSchemaType): FormSchema => ({
        absoluteStartEnd:
          values?.contractEnd?.start && values?.contractEnd?.end
            ? [dayjs(values?.contractEnd?.start), dayjs(values?.contractEnd?.end)]
            : null,
        searchMode: values?.contractEnd?.searchMode,
        rangeMode: values?.contractEnd?.rangeMode,
        relativeStart: values?.contractEnd?.relativeStart ?? '',
        relativeEnd: values?.contractEnd?.relativeEnd ?? ''
      }),
      []
    );
    const defaultValues = useMemo(() => toFormSchema(values), [toFormSchema, values]);

    const { getValues, control, watch, reset, setValue } = useForm<FormSchema>({
      resolver: zodResolver(formSchema),
      defaultValues
    });

    const { field: relativeStart } = useController({ name: 'relativeStart', control });
    const { field: relativeEnd } = useController({ name: 'relativeEnd', control });
    const { field: rangeMode } = useController({ name: 'rangeMode', control });
    const { field: searchMode } = useController({ name: 'searchMode', control });
    const { field: absoluteStartEnd } = useController({ name: 'absoluteStartEnd', control });

    const onChangeFn = useCallback(
      (values: ContractEndFilterSchemaType) => {
        const parsed = contractEndFilterSchema.safeParse(values);
        if (!parsed.success) {
          zodParseErrorHandler('FilterContractEnd', parsed.error);
          return;
        }
        onChange(parsed.data);
      },
      [onChange]
    );

    const onValuesChange = useMemo(
      () =>
        debounce((value: FormSchema, name: string | undefined) => {
          if (name === 'searchMode') {
            onChangeFn({
              contractEnd: {
                ...values?.contractEnd,
                searchMode: value.searchMode
              }
            });
          }

          if (value.rangeMode === ContractEndRangeMode.ABSOLUTE) {
            if (name === 'rangeMode') {
              onChangeFn({
                contractEnd: {
                  ...values?.contractEnd,
                  relativeStart: undefined,
                  relativeEnd: undefined,
                  rangeMode: value.rangeMode
                }
              });
              setValue('relativeStart', '');
              setValue('relativeEnd', '');
            }
            if (name === 'absoluteStartEnd' && value.absoluteStartEnd) {
              onChangeFn({
                contractEnd: {
                  ...values?.contractEnd,
                  start: value.absoluteStartEnd[0].utc().startOf('day').toDate(),
                  end: value.absoluteStartEnd[1].utc().endOf('day').toDate()
                }
              });
              trackECDateRange();
            }
          }

          if (value.rangeMode === ContractEndRangeMode.RELATIVE) {
            if (name === 'rangeMode') {
              onChangeFn({
                contractEnd: {
                  ...values?.contractEnd,
                  start: undefined,
                  end: undefined,
                  rangeMode: value.rangeMode
                }
              });
              setValue('absoluteStartEnd', null);
            }
            if (name === 'relativeStart') {
              const start = oNonNegativeIntLike.safeParse(value.relativeStart)?.data;
              onChangeFn({
                contractEnd: { ...values?.contractEnd, relativeStart: start }
              });
              trackTendersFilterContractEndTimeSpan(eventSource, {
                start,
                end: values?.contractEnd?.relativeEnd
              });
            }
            if (name === 'relativeEnd') {
              const end = oNonNegativeIntLike.safeParse(value.relativeEnd)?.data;
              onChangeFn({
                contractEnd: { ...values?.contractEnd, relativeEnd: end }
              });
              trackTendersFilterContractEndTimeSpan(eventSource, {
                start: values?.contractEnd?.relativeStart,
                end
              });
            }
          }
        }, 300),
      [onChangeFn, values?.contractEnd, setValue, eventSource]
    );

    const onClearAbsoluteStartEnd = useCallback(() => {
      onChangeFn({ contractEnd: { ...values?.contractEnd, start: undefined, end: undefined } });
    }, [values?.contractEnd, onChangeFn]);

    useEffect(() => {
      const subscription = watch((value, { name }) => onValuesChange(value as FormSchema, name));
      return () => subscription.unsubscribe();
    }, [onValuesChange, watch]);

    const handleReset = useCallback(
      (updatedVars: TendersSearchSchemaType) => {
        reset(toFormSchema(updatedVars));
      },
      [reset, toFormSchema]
    );
    useImperativeHandle(ref, () => ({ handleReset }), [handleReset]);

    return (
      <GenericSearchDropdown
        label={t('ExpiringContracts.contractEnd')}
        overlayClassName={styles.overlay}
        isChanged={isDiffFromInit}
        special={isDiffFromProfile}
        overlay={
          <form>
            <div className={styles.formItem}>
              <Radio.Group className={styles.fieldGroup} {...searchMode}>
                <Radio value={ContractEndSearchType.WithoutExtension}>
                  {t('ExpiringContracts.searchModeWithoutExtension')}
                </Radio>
                <Radio value={ContractEndSearchType.WithExtension}>
                  {t('ExpiringContracts.searchModeWithExtension')}
                </Radio>
                <Radio value={ContractEndSearchType.LastPossibleEndDate}>
                  {t('ExpiringContracts.searchModeLastPossibleEndDate')}
                </Radio>
              </Radio.Group>
            </div>
            <div className={styles.rangeContainer}>
              <div className={styles.formItem}>
                <Select
                  {...rangeMode}
                  dropdownMatchSelectWidth={false}
                  optionFilterProp={'value'}
                  getPopupContainer={trigger => trigger.parentNode}
                  className={styles.select}
                  listHeight={125}
                  filterOption={(input, option) =>
                    !!option &&
                    !!option.value &&
                    option.value.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  suffixIcon={<FontAwesomeIcon className={styles.selectIcon} icon={faCaretDown} />}
                  bordered={false}
                >
                  {Object.values(ContractEndRangeMode).map(mode => (
                    <Select.Option key={mode} value={mode}>
                      <span>{t(`ExpiringContracts.ContractEndRangeMode.${mode}`)}</span>
                    </Select.Option>
                  ))}
                </Select>
              </div>
              {getValues('rangeMode') === ContractEndRangeMode.ABSOLUTE && (
                <div className={styles.formItem}>
                  <RangePicker
                    {...absoluteStartEnd}
                    separator={'-'}
                    allowClear={false}
                    getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
                    renderExtraFooter={() => (
                      <Button
                        type={'text'}
                        icon={<FontAwesomeIcon icon={faTimes} />}
                        onClick={onClearAbsoluteStartEnd}
                        className={styles.clearButton}
                        disabled={!getValues('absoluteStartEnd')}
                      >
                        {t('Common.Blocks.deleteDate')}
                      </Button>
                    )}
                  />
                </div>
              )}
              {getValues('rangeMode') === ContractEndRangeMode.RELATIVE && (
                <>
                  <div className={classNames(styles.formItem, styles.number, styles.relativeRangeInput)}>
                    <IntegerInput placeholder={t('Common.Blocks.number')} {...relativeStart} />
                  </div>
                  <FontAwesomeIcon className={styles.dashIcon} icon={faDash} />
                  <div className={classNames(styles.formItem, styles.number, styles.relativeRangeInput)}>
                    <IntegerInput placeholder={t('Common.Blocks.number')} {...relativeEnd} />
                  </div>
                  <div className={styles.unitText}>{t('Tenders.BoxFieldRangeUnit.Months', { count: 2 })}</div>
                </>
              )}
            </div>
          </form>
        }
      />
    );
  }
);

FilterContractEnd.displayName = 'FilterContractEnd';

export default FilterContractEnd;
