import classNames from 'classnames';
import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PlainDropDown } from 'src/common';
import styles from './index.module.scss';
import { useContentLibrary } from 'src/models/contentLibrary/ContentLibrary/hooks';
import { useLoadContentLibraryRooms } from 'src/models/contentLibrary/ContentLibraryRoom/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { toHSLColor } from 'src/helpers/styles';
import { ContentLibraryTrackFilter, trackFilteringCL } from 'src/segment/events';
import { ContentLibraryEventSource } from 'src/models/contentLibrary/ContentLibrary/types';

export interface Props {
  eventSource: ContentLibraryEventSource;
}

const ContentLibraryFilterRoom: FC<Props> = props => {
  const { eventSource } = props;
  const { t } = useTranslation();
  const { data: state } = useContentLibrary();
  const { data: roomData } = useLoadContentLibraryRooms();

  const currentVars = useMemo(() => state?.currentVars.filters ?? {}, [state]);

  const updateVars = state?.updateVars;
  const rooms = useMemo(() => {
    return roomData
      ? roomData?.map(room => {
          return {
            label: (
              <span>
                <FontAwesomeIcon className={styles.labelIcon} icon={faCircle} color={toHSLColor(room.id)} />
                {` ${room.title}`}
              </span>
            ),
            value: room.id
          };
        })
      : [];
  }, [roomData]);
  const selectedRooms = useMemo(() => (currentVars?.roomIds ? currentVars.roomIds : undefined), [currentVars]);

  const onChangeRoom = useCallback(
    (roomId: string, checked?: boolean) => {
      const previousValue = selectedRooms || [];
      const currentValue = checked ? [...previousValue, roomId] : previousValue.filter(item => item !== roomId);

      updateVars?.({ roomIds: currentValue });
      trackFilteringCL(eventSource, ContentLibraryTrackFilter.room);
    },
    [eventSource, selectedRooms, updateVars]
  );

  return (
    <div className={classNames(styles.filter, { [styles.isActive]: selectedRooms?.length })}>
      <PlainDropDown
        values={rooms}
        selectedValue={selectedRooms}
        label={t('ContentLibrary.rooms')}
        onSelect={onChangeRoom}
        buttonClassName={styles.label}
        placement="bottomLeft"
        type="multiple"
        button
      />
    </div>
  );
};

export default ContentLibraryFilterRoom;
