import { gql } from '@apollo/client';
import { DECISION_MATRIX_TEMPLATE_FIELDS } from './fragments';

export const GET_DECISION_MATRIX_TEMPLATES = gql`
  query getDecisionMatrixTemplates {
    getDecisionMatrixTemplates {
      ...decisionMatrixTemplateFields
    }
  }
  ${DECISION_MATRIX_TEMPLATE_FIELDS}
`;
