import { isPaidUser, useApp } from '../models/auth';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { notification } from 'src/common';
import { useUpdateUser } from 'src/models/users/Account/hooks';
import { Language } from '@tendium/prom-types';
import { Currency } from '@tendium/prom-types';

export const languageToCurrencyMap: Record<Language, Currency> = {
  [Language.sv]: Currency.SEK,
  [Language.en]: Currency.EUR,
  [Language.fi]: Currency.EUR,
  [Language.no]: Currency.NOK,
  [Language.dk]: Currency.DKK
};

export function getCurrencyFromLanguage(language: Language): Currency {
  return languageToCurrencyMap[language] || Currency.EUR;
}

export function useSyncUsersCurrency(): void {
  const { t, i18n } = useTranslation();
  const language = i18n.language as Language;
  const { user } = useApp();
  const [updateUser] = useUpdateUser();

  useEffect(() => {
    if (user) {
      if (isPaidUser(user) && !user.currency) {
        updateUser({
          variables: {
            email: user.email,
            currency: getCurrencyFromLanguage(language)
          }
        }).catch(() => {
          notification.error({
            description: t('Common.unknownErrorDesc'),
            message: t('Common.unknownError')
          });
        });
      }
    }
  }, [i18n, language, t, updateUser, user]);
}

export default useSyncUsersCurrency;
