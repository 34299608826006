import { useCallback, useMemo } from 'react';
import { MutationUpdaterFn, useMutation, useQuery, ApolloError, Reference } from '@apollo/client';
import { ADD_COMPETITORS, REMOVE_COMPETITORS, GET_COMPETITORS_PAYMENTS } from './queries';
import { notification } from 'src/common';
import { useTranslation } from 'react-i18next';
import {
  IApiCompetitor,
  COMPETITORS_COLORS,
  IOrgWithPayments,
  IApiOrgWithPayments,
  PAYMENTS_YEARS_RANGE
} from './types';
import { capitalizeFirstLetter } from 'src/helpers/capitalizeFirstLetter';
import { useCompanyCompetitors } from '../hooks';
import { useGetOrganisationsByIds } from 'src/models/organisations/hooks';
import dayjs from 'dayjs';
import useCurrency from 'src/common/hooks/useCurrency';

export function useMyCompanyCompetitors(): { data: IOrgWithPayments[]; loading: boolean; error?: ApolloError } {
  const { data: companyData } = useCompanyCompetitors();
  const orgIds = useMemo(
    () => (companyData ? companyData.getCompany.competitors.map(org => org.orgId) : []),
    [companyData]
  );
  const colorsLength = COMPETITORS_COLORS.length;
  const { data: orgs, loading, error } = useGetOrganisationsByIds(orgIds);

  return useMemo(
    () => ({
      data: orgs
        .map((org, i) => ({
          supplierOrgId: org.organisationNumber,
          supplierOrgName: org.organisationName ? capitalizeFirstLetter(org.organisationName) : '—',
          sumValue: null,
          sumCurrency: undefined,
          color: COMPETITORS_COLORS[i % colorsLength]
        }))
        .sort((a, b) => a.supplierOrgName.localeCompare(b.supplierOrgName)),
      loading,
      error
    }),
    [colorsLength, orgs, error, loading]
  );
}

export interface IGetCompetitorsPaymentsResponse {
  getCompetitorsPayments: IApiOrgWithPayments[];
}
export function useCompetitors(buyerOrgId?: string): {
  data: IOrgWithPayments[];
  loading: boolean;
  error?: ApolloError;
} {
  const colorsLength = COMPETITORS_COLORS.length;
  const currentYear = dayjs().year();
  const { currency } = useCurrency();

  const {
    data: competitorsData,
    loading,
    error
  } = useQuery<IGetCompetitorsPaymentsResponse>(GET_COMPETITORS_PAYMENTS, {
    variables: {
      buyerOrgId,
      yearTo: currentYear,
      yearFrom: currentYear - PAYMENTS_YEARS_RANGE,
      desiredCurrency: currency
    },
    skip: !buyerOrgId
  });

  const competitors = useMemo(
    () =>
      competitorsData?.getCompetitorsPayments
        ? competitorsData.getCompetitorsPayments
            .map((org, i) => ({
              supplierOrgId: org.supplierOrgId,
              supplierOrgName: org.supplierOrgName ? capitalizeFirstLetter(org.supplierOrgName) : '—',
              sumValue: org?.transactions ? org?.transactions.sumAmount : null,
              sumCurrency: org?.transactions ? org.transactions.sumCurrency : undefined,
              color: COMPETITORS_COLORS[i % colorsLength]
            }))
            .sort((a, b) => a.supplierOrgName.localeCompare(b.supplierOrgName))
        : [],
    [colorsLength, competitorsData]
  );

  return useMemo(
    () => ({
      data: competitors,
      loading,
      error
    }),
    [competitors, error, loading]
  );
}

export interface IAddCompetitorsResponse {
  __typename: 'Mutation';
  addCompetitors: IApiCompetitor & {
    __typename: 'Competitor';
  };
}

export function getUpdateCacheOnAddCompetitors(
  companyId: string,
  orgIds: string[]
): MutationUpdaterFn<IAddCompetitorsResponse> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }
    const companyRef = cache.identify({
      __typename: 'Company',
      id: companyId
    });

    cache.modify({
      id: companyRef,
      fields: {
        competitors(existingCompetitorsRefs: Reference[], { readField }) {
          const uniqueRefs =
            !!existingCompetitorsRefs &&
            existingCompetitorsRefs.filter(ref => ref && !orgIds.some(orgId => orgId === readField('orgId', ref)));

          return [
            ...uniqueRefs,
            ...orgIds.map(orgId => ({
              __ref: cache.identify({
                __typename: 'Competitor',
                orgId
              })
            }))
          ];
        }
      }
    });
  };
}

export function useAddCompetitors(): [
  (companyId: string, orgIds: string[], onComplete?: () => void) => void,
  { loading: boolean; error: Error | undefined }
] {
  const { t } = useTranslation();
  const [addCompetitors, { loading, error }] = useMutation(ADD_COMPETITORS);
  const addCompetitorsFn = useCallback(
    (companyId: string, orgIds: string[], onComplete?: () => void) => {
      companyId &&
        orgIds.length &&
        addCompetitors({
          variables: { companyId, orgIds },
          update: getUpdateCacheOnAddCompetitors(companyId, orgIds)
        })
          .then(() => {
            onComplete && onComplete();
          })
          .catch(() => {
            notification.error({
              description: t('Common.unknownErrorDesc'),
              message: t('Common.unknownError')
            });
          });
    },
    [addCompetitors, t]
  );
  return [addCompetitorsFn, { loading, error }];
}

export interface IRemoveCompetitorsResponse {
  __typename: 'Mutation';
  removeCompetitors: IApiCompetitor & {
    __typename: 'Competitor';
  };
}

export function getUpdateCacheOnRemoveCompetitors(
  companyId: string,
  orgIds: string[]
): MutationUpdaterFn<IRemoveCompetitorsResponse> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }
    const companyRef = cache.identify({
      __typename: 'Company',
      id: companyId
    });
    cache.modify({
      id: companyRef,
      fields: {
        competitors(existingCompetitorsRefs: Reference[], { readField }) {
          return (
            !!existingCompetitorsRefs &&
            existingCompetitorsRefs.filter(ref => ref && !orgIds.some(orgId => orgId === readField('orgId', ref)))
          );
        }
      }
    });
  };
}

export function useRemoveCompetitors(): [
  (companyId: string, orgIds: string[], onComplete?: () => void) => void,
  { loading: boolean; error: Error | undefined }
] {
  const { t } = useTranslation();
  const [removeCompetitors, { loading, error }] = useMutation(REMOVE_COMPETITORS);
  const removeCompetitorsFn = useCallback(
    (companyId: string, orgIds: string[], onComplete?: () => void) => {
      companyId &&
        orgIds.length &&
        removeCompetitors({
          variables: { companyId, orgIds },
          update: getUpdateCacheOnRemoveCompetitors(companyId, orgIds)
        })
          .then(() => {
            onComplete && onComplete();
          })
          .catch(() => {
            notification.error({
              description: t('Common.unknownErrorDesc'),
              message: t('Common.unknownError')
            });
          });
    },
    [removeCompetitors, t]
  );
  return [removeCompetitorsFn, { loading, error }];
}
