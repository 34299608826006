import React, { FC, ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './index.module.scss';
import { IIcon } from 'src/types/icon';
import classNames from 'classnames';

interface Props {
  title: string;
  icon?: IIcon;
  actions?: ReactNode;
  className?: string;
}
export const Heading: FC<Props> = ({ title, icon, actions, className }) => {
  return (
    <h3 className={classNames(styles.title, !!className && className)}>
      {icon && icon.defaultIcon && (
        <FontAwesomeIcon icon={icon.defaultIcon} color={icon.color} className={styles.icon} />
      )}
      <span>{title}</span>
      {!!actions && actions}
    </h3>
  );
};

export default Heading;
