import { MpCodeTypes } from 'src/models/matchingProfiles/types';
import { trackWithSessionReplay } from '../helpers';
import { TendersSearchSchemaType } from 'src/models/procurements/TendersSearch/schemas';
import { TendersSearchEventSource } from 'src/models/procurements/TendersSearch/types';

export function trackRejectProcurements(
  procurementIds: readonly string[],
  eventSource: string,
  type: string | undefined,
  relevanceScores?: (number | undefined)[]
): void {
  trackWithSessionReplay('Reject procurements', {
    procurementIds,
    eventSource,
    type,
    relevanceScores
  });
}

export function trackUnrejectProcurements(
  procurementIds: readonly string[],
  eventSource: string,
  type: string | undefined
): void {
  trackWithSessionReplay('Unreject procurements', {
    procurementIds,
    eventSource,
    type
  });
}

export function trackPrintSummary(procurement: { id: string; name: string }): void {
  trackWithSessionReplay('Print summary', {
    procurement
  });
}

export function trackStarProcurement(procurement: { id: string; name?: string }, eventSource: string): void {
  trackWithSessionReplay('Star procurement', {
    procurement,
    eventSource
  });
}

export function trackUnstarProcurement(procurement: { id: string; name?: string }, eventSource: string): void {
  trackWithSessionReplay('Unstar procurement', {
    procurement,
    eventSource
  });
}

export function trackOpenProcurementFromEmail(procurement: { id: string; name?: string }): void {
  trackWithSessionReplay('Open procurement from email', {
    procurement
  });
}

export function trackViewDocumentPreview(eventSource: string, procurementIds?: string[]): void {
  trackWithSessionReplay('View Document Preview', {
    eventSource,
    procurementIds
  });
}

export function trackTendersFilterSearch(eventSource: TendersSearchEventSource): void {
  trackWithSessionReplay(`${eventSource} Search Field`);
}

export function trackTendersFilterBuyers(eventSource: TendersSearchEventSource): void {
  trackWithSessionReplay(`${eventSource} Filtering Buyers`);
}

export function trackTendersFilterCodes(
  eventSource: TendersSearchEventSource,
  type: MpCodeTypes,
  codes?: string[]
): void {
  const optionId = codes?.find(item => !codes?.includes(item)) ?? codes?.[0];
  trackWithSessionReplay(`${eventSource} Filtering ${type}`, { optionId });
}

export function trackTendersFilterAssigned(eventSource: TendersSearchEventSource): void {
  trackWithSessionReplay(`${eventSource} Filtering Assigned`);
}

export function trackTendersFilterDate(
  eventSource: TendersSearchEventSource,
  type: keyof Pick<TendersSearchSchemaType, 'published' | 'deadline'>
): void {
  trackWithSessionReplay(`${eventSource} Filtering ${type}`);
}

export function trackTendersFilterStatuses(eventSource: TendersSearchEventSource, status: string): void {
  trackWithSessionReplay(`${eventSource} Status`, {
    status: {
      [status.toLowerCase()]: true
    }
  });
}

export function trackTendersFilterContractEndTimeSpan(
  eventSource: TendersSearchEventSource,
  data: { start?: number; end?: number }
): void {
  trackWithSessionReplay(`${eventSource} Filtering Contract End: Time Span`, data);
}
