import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { IBoxFieldRange } from 'src/models/procurements/Tender/types';
import { IBlockReadProps } from 'src/shared/Tender/Boxes/types';
import { useExistingTranslation } from 'src/common';
import classNames from 'classnames';
import { Translations } from 'src/lib/I18nService/types';

interface Props extends IBlockReadProps, Pick<IBoxFieldRange, 'from' | 'to' | 'unit'> {}
export const ReadOnlyBlockRange: FC<Props> = ({ from, to, unit, className, translation }) => {
  const { t } = useTranslation();
  const { existingT } = useExistingTranslation(translation ?? Translations.procurements);

  const range = useMemo(() => {
    const fromFormatted = !!from ? existingT(from) : t('Common.notSet');
    const toFormatted = !!to ? existingT(to) : t('Common.notSet');

    return `${fromFormatted} − ${toFormatted} ${unit ? t(`Tenders.BoxFieldRangeUnit.${unit.toLowerCase()}`) : ''}`;
  }, [existingT, from, t, to, unit]);

  return <span className={classNames(styles.block, !!className && className)}>{range}</span>;
};

export default ReadOnlyBlockRange;
