import { gql } from '@apollo/client';

export const ON_CANVAS_BID_TASK_GENERATE_ANSWER = gql`
  subscription GenerateBidTaskAnswer {
    generateBidTaskAnswer {
      bidTaskId
      groupId
      iterateContentId
      status
      streamType
      companyId
      answerData {
        content
        order
      }
    }
  }
`;

export const GENERATE_ANSWER = gql`
  mutation generateContentLibraryResponse(
    $filters: ContentLibraryFilters
    $settings: SettingsRequest
    $taskIds: [String!]!
  ) {
    generateContentLibraryResponse(query: { filters: $filters, settings: $settings, taskIds: $taskIds })
  }
`;

export const GENERATE_ITERATION_CONTENT = gql`
  mutation improveWritingContentLibraryResponse($taskId: String!, $streamId: String!, $settings: SettingsRequest) {
    improveWritingContentLibraryResponse(query: { taskId: $taskId, streamId: $streamId, settings: $settings })
  }
`;

export const EXPORT_BIDTASKS = gql`
  mutation exportBidTasks($bidTaskIds: [String!]!, $format: ExportBidTasksFormat!) {
    exportBidTasks(input: { bidTaskIds: $bidTaskIds, format: $format }) {
      operationId
    }
  }
`;

export const GET_BID_TASK_ANSWER_ATTACHMENT_UPLOAD_URL = gql`
  query getBidTaskAnswerAttachmentUploadUrl($bidTaskAnswerId: String!, $fileName: String!) {
    getBidTaskAnswerAttachmentUploadUrl(input: { bidTaskAnswerId: $bidTaskAnswerId, fileName: $fileName })
  }
`;

export const GET_BID_TASK_ANSWER_ATTACHMENT_DOWNLOAD_URL = gql`
  query getBidTaskAnswerAttachmentDownloadUrl($bidTaskAnswerId: String!, $fileName: String!) {
    getBidTaskAnswerAttachmentDownloadUrl(input: { bidTaskAnswerId: $bidTaskAnswerId, fileName: $fileName })
  }
`;
