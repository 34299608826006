import React, { FC } from 'react';
import NotificationsItem from '../NotificationsItem';
import { useLoadNotifications } from 'src/models/notifications/hooks';
import { List, Skeleton } from 'src/common';

interface Props {
  limit?: number;
  showMarkDown?: boolean;
  onClose?: () => void;
}

export const NotificationsList: FC<Props> = props => {
  const { showMarkDown, onClose } = props;
  const { data, loading } = useLoadNotifications();

  return loading ? (
    <Skeleton loading active />
  ) : !!data ? (
    <List items={data.notifications} bordered>
      {item => (
        <NotificationsItem notification={item} showMarkDown={showMarkDown} key={item.createdAt} onClose={onClose} />
      )}
    </List>
  ) : null;
};

export default NotificationsList;
