import React, { FC, ReactNode } from 'react';
import styles from './index.module.scss';
import StatusStackBar from './StatusStackBar';

interface Props {
  title: string;
  stacks: { backgroundColor: string; count: number }[];
  totalCount: number;
  stackLabel?: ReactNode;
}

export const StatusBarGroupItem: FC<Props> = props => {
  const { title, stacks, totalCount, stackLabel } = props;

  return (
    <div className={styles.group}>
      <div className={styles.groupTitle}>{title}</div>
      <div className={styles.statsBar}>
        {stackLabel}
        {stacks.map(stack => (
          <StatusStackBar
            key={stack.backgroundColor}
            backgroundColor={stack.backgroundColor}
            taskCount={stack.count}
            totalCount={totalCount}
          />
        ))}
      </div>
    </div>
  );
};

export default StatusBarGroupItem;
