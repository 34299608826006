import { FileExt } from 'src/models/file/types';
import {
  IconDefinition,
  faFileAlt,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFileDoc
} from '@fortawesome/pro-light-svg-icons';
import { splitFileExt } from './files';

export const fileToExtIcon = (fileName: string | undefined): IconDefinition => {
  if (!fileName) return faFileAlt;

  const [, fileExt] = splitFileExt(fileName);

  switch (fileExt) {
    case FileExt.pdf:
      return faFilePdf;
    case FileExt.xls:
    case FileExt.xlsx:
    case FileExt.csv:
      return faFileExcel;
    case FileExt.doc:
    case FileExt.docx:
      return faFileDoc;
    case FileExt.jpg:
    case FileExt.jpeg:
    case FileExt.png:
    case FileExt.gif:
    case FileExt.svg:
      return faFileImage;
    default:
      return faFileAlt;
  }
};
