import React, { FC } from 'react';
import styles from './index.module.scss';
import { RcFile } from 'antd/lib/upload';
import Dragger from 'src/common/Upload/Dragger';
import UploadContent from '../UploadContent';

interface Props {
  onUploadFile: (file: RcFile, files: RcFile[]) => void;
  title?: string;
  desc?: string;
  accept?: string;
  fileList?: RcFile[];
  showRemoveIcon?: boolean;
  multiple?: boolean;
}

export const DndUploadArea: FC<Props> = ({ onUploadFile, title, desc, accept, fileList, showRemoveIcon, multiple }) => {
  return (
    <Dragger
      name="file"
      className={styles.docsActionUpload}
      showUploadList={!!showRemoveIcon ? { showRemoveIcon: showRemoveIcon } : false}
      beforeUpload={onUploadFile}
      accept={accept}
      fileList={fileList}
      multiple={multiple}
    >
      <UploadContent title={title} desc={desc} />
    </Dragger>
  );
};

export default DndUploadArea;
