import { gql } from '@apollo/client';
import { BOX_FIELDS } from './fragments';

export const REMOVE_TENDER_BOX = gql`
  mutation removeTenderBox($id: String!, $bidId: String!, $box: TenderBoxToRemoveInput!) {
    removeTenderBox(input: { id: $id, bidId: $bidId, box: $box })
  }
`;

export const ADD_TENDER_BOX = gql`
  mutation addTenderBox($id: String!, $bidId: String!, $box: AddCustomTenderBoxInput!) {
    addTenderBox(input: { id: $id, bidId: $bidId, box: $box }) {
      ...boxFields
    }
  }
  ${BOX_FIELDS}
`;
