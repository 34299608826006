import { z } from 'zod';
import {
  isTenderVarsDiff,
  tendersSearchSchema,
  TendersSearchSchemaKeyType,
  TendersSearchSchemaType
} from '../tendersSearchSchema';

export const deadlineFilterSchema = tendersSearchSchema.pick({ deadline: true });
export type DeadlineFilterSchemaSchemaType = z.infer<typeof deadlineFilterSchema>;
export const deadlineFilterKeys = Object.keys(deadlineFilterSchema.shape) as TendersSearchSchemaKeyType[];

export function isDeadlineFilterDiff(vars1?: TendersSearchSchemaType, vars2?: TendersSearchSchemaType): boolean {
  return isTenderVarsDiff(deadlineFilterKeys, vars1, vars2);
}
