import { JSONContent } from '@tiptap/core';
import { CanvasGroupGenerationStatus, CanvasIterationContentStatus, CanvasTaskGenerationStatus } from './types';
import { StreamingCommon } from '@tendium/prom-types/subscriptions';

export const emptyEditor: JSONContent = {
  type: 'doc',
  content: []
};

export const emptyParagraph: JSONContent[] = [
  {
    type: 'paragraph',
    content: []
  }
];

export const CUSTOM_NODE_NAMES = {
  bidTask: 'bidTask'
};

export const DEFAULT_CANVAS_TASK_GENERATION_STATUS: CanvasTaskGenerationStatus = {};
export const DEFAULT_CANVAS_GROUP_GENERATION_STATUS: CanvasGroupGenerationStatus = {
  groupId: '',
  status: StreamingCommon.AnswerStatus.NOT_STARTED
};
export const DEFAULT_CANVAS_ITERATION_CONTENT_STATUS: CanvasIterationContentStatus = {
  iterateContentId: '',
  iterationPendingContent: '',
  status: StreamingCommon.AnswerStatus.NOT_STARTED
};

export const EXPORT_BIDTASKS_KEY = 'export-bidtasks';

export const CANVAS_MENU_Z_INDEX = 9; // Should be below sidebar preview

export const CANVAS_TOOLBAR_ELEMENT_ID = 'canvas-toolbar';
export const CANVAS_TOOLBAR_ASK_AI_BUTTON_ELEMENT_ID = 'canvas-toolbar-ask-ai-button';
export const CANVAS_MAIN_ELEMENT_ID = 'canvas-main-page';

// 2 here as it reflects the position of the first BidTaskNode's editable content
export const TOP_DOC_POSITION = 2;

// Height of <EditorHeader /> container
export const CANVAS_PAGE_TOP_BOUNDARY = 146;
