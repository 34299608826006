import React, { FC, useMemo } from 'react';
import styles from './index.module.scss';
import { useContentLibrary, useContentLibraryNav } from 'src/models/contentLibrary/ContentLibrary/hooks';
import ContentLibraryFilterSort from '../ContentLibraryFilterSort';
import ContentLibraryBulkActions from '../ContentLibraryBulkActions';
import { useTranslation } from 'react-i18next';
import { ContentLibraryEventSource } from 'src/models/contentLibrary/ContentLibrary/types';

export const ContentLibraryStatusBar: FC = () => {
  const { t } = useTranslation();
  const { isRoomPage } = useContentLibraryNav();

  const { data: state, selectedPairIds } = useContentLibrary();
  const count = useMemo(() => state?.data?.totalCount, [state?.data?.totalCount]);
  const eventSource = useMemo(() => {
    return isRoomPage ? ContentLibraryEventSource.room : ContentLibraryEventSource.contentLibrary;
  }, [isRoomPage]);

  return (
    <div className={styles.container}>
      <div>
        <>{!!count && <span className={styles.total}>{t('Tenders.totalMatch', { count })}</span>}</>
      </div>
      <div className={selectedPairIds.length ? styles.isShown : styles.isHidden}>
        <ContentLibraryBulkActions />
      </div>
      <ul className={styles.actions}>
        <li className={styles.action}>
          <ContentLibraryFilterSort eventSource={eventSource} />
        </li>
      </ul>
    </div>
  );
};

export default ContentLibraryStatusBar;
