import React, { FC, useCallback } from 'react';
import AssignTo, { IAssignToProps } from 'src/shared/AssignTo';
import { useBulkUpdateContentLibrary } from 'src/models/contentLibrary/ContentLibrary/hooks';
import { useTranslation } from 'react-i18next';
import { AssignedTo } from 'src/models/users/types';
import { RenderMode } from 'src/common/UserPicker';
import { trackAssignTo } from 'src/segment/events';

interface Props extends IAssignToProps {
  ids: string[];
  renderMode?: RenderMode;
  usersWithAccess: AssignedTo[];
  onUpdateComplete?: () => void;
}

export const AssignToContentLibrary: FC<Props> = ({ size = 'm', mode = 'short', onUpdateComplete, ...restProps }) => {
  const { ids, renderMode, usersWithAccess } = restProps;
  const [updateContentLibrary, { loading }] = useBulkUpdateContentLibrary();
  const { t } = useTranslation();

  const onUpdateContentLibrary = useCallback(
    (assignedTo: string | null) => {
      updateContentLibrary({ ids, assignedTo });
      if (onUpdateComplete) {
        onUpdateComplete();
      }
    },
    [ids, updateContentLibrary, onUpdateComplete]
  );

  const onAssign = useCallback(
    (assignedTo: AssignedTo) => {
      trackAssignTo({ eventSource: 'Content Library', assignedTo });
      onUpdateContentLibrary(assignedTo.id);
    },
    [onUpdateContentLibrary]
  );

  const onUnAssign = useCallback(() => {
    onUpdateContentLibrary(null);
  }, [onUpdateContentLibrary]);

  return (
    <AssignTo
      onAssign={onAssign}
      onUnAssign={onUnAssign}
      isUpdating={loading}
      size={size}
      mode={mode}
      users={usersWithAccess}
      renderMode={renderMode}
      label={t('ContentLibrary.labelAssigned')}
      {...restProps}
    />
  );
};

export default AssignToContentLibrary;
