import { useCallback, useMemo } from 'react';
import { ApolloError, MutationTuple, MutationUpdaterFn, QueryResult, useMutation, useQuery } from '@apollo/client';
import { ATTACH_FILES_TO_BID, GET_BID_ATTACHMENTS, GET_BID_ATTACHMENTS_STATUS, REMOVE_BID_ATTACHMENT } from './queries';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { FileStatus } from 'src/common/DocumentsLibrary/types';
import {
  ApiAttachFileToBidResponse,
  AttachFileToBidResponse,
  ApiAttachFileToBidStatusResponse,
  AttachFileToBidStatusResponse
} from './types';
import { normalizeFileName } from 'src/helpers/files';
export function useAttachFileToBid(): [
  (files: RcFile[] | File[], bidId: string) => void,
  { loading: boolean; error?: ApolloError }
] {
  const { t } = useTranslation();
  const [attachFileToBid, { loading, error, client }] = useMutation(ATTACH_FILES_TO_BID);

  const attachFileToBidFn = useCallback(
    async (files: RcFile[] | File[], bidId: string) => {
      try {
        if (bidId !== undefined) {
          const putUrlData = await attachFileToBid({
            variables: { fileNames: files.map(file => normalizeFileName(file.name)), bidId },
            update: getUpdateCacheOnUploadBidAttachment(bidId)
          });

          const uploadPromises = files.map(async (file, index) => {
            const url = putUrlData.data?.attachFilesToBid[index].document.putUrl;
            if (!url) {
              throw new Error(`Can't get url`);
            }
            const buffer = await file.arrayBuffer();
            const fetchResponse = await fetch(url, {
              method: 'put',
              body: buffer
            });

            client.cache.modify({
              id: client.cache.identify({
                __typename: 'BidAttachment',
                bidId
              }),
              fields: {
                status() {
                  return fetchResponse.ok ? FileStatus.Done : FileStatus.WaitingForUpload;
                }
              }
            });
          });

          await Promise.all(uploadPromises);
        }
      } catch (err: unknown) {
        if (err instanceof ApolloError) {
          notification.error({
            description: t('Common.unknownErrorDesc'),
            message: t('Common.unknownError')
          });
        } else {
          console.error(err);
        }
      }
    },
    [attachFileToBid, client.cache, t]
  );

  return [attachFileToBidFn, { loading, error }];
}

export function useGetBidAttachments(bidId: string): Omit<
  QueryResult<ApiAttachFileToBidResponse, { bidId: string }>,
  'data'
> & {
  data?: AttachFileToBidResponse[];
} {
  const query = useQuery<ApiAttachFileToBidResponse, { bidId: string }>(GET_BID_ATTACHMENTS, {
    variables: { bidId },
    skip: !bidId
  });

  return useMemo(
    () => ({
      ...query,
      data: query.data?.getBidAttachments ?? undefined
    }),
    [query]
  );
}

export function useGetBidAttachmentsStatus(bidId: string): Omit<
  QueryResult<ApiAttachFileToBidStatusResponse, { bidId: string }>,
  'data'
> & {
  data?: AttachFileToBidStatusResponse[];
} {
  const query = useQuery<ApiAttachFileToBidStatusResponse, { bidId: string }>(GET_BID_ATTACHMENTS_STATUS, {
    variables: { bidId },
    skip: !bidId
  });

  return useMemo(
    () => ({
      ...query,
      data: query.data?.getBidAttachments ?? undefined
    }),
    [query]
  );
}

export function useRemoveBidAttachment(): MutationTuple<
  { removeBidAttachment: boolean; __typename: 'Mutation' },
  { id: string }
> {
  return useMutation(REMOVE_BID_ATTACHMENT);
}

export function getUpdateCacheOnUploadBidAttachment(
  bidId: string
): MutationUpdaterFn<{ attachFilesToBid: AttachFileToBidResponse[] }> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }

    const newAttachments = data.attachFilesToBid;
    const existingData = cache.readQuery<ApiAttachFileToBidResponse>({
      query: GET_BID_ATTACHMENTS,
      variables: { bidId }
    });

    if (existingData) {
      const { getBidAttachments } = existingData;
      cache.writeQuery({
        query: GET_BID_ATTACHMENTS,
        variables: { bidId },
        data: {
          getBidAttachments: [...getBidAttachments, ...newAttachments]
        }
      });
    } else {
      cache.writeQuery({
        query: GET_BID_ATTACHMENTS,
        variables: { bidId },
        data: {
          getBidAttachments: newAttachments
        }
      });
    }
  };
}

export function getUpdateCacheOnDeleteBidAttachment(
  id: string,
  bidId: string
): MutationUpdaterFn<{ removeBidAttachment: boolean }> {
  return (cache, { data }) => {
    if (!data || !data.removeBidAttachment) {
      return;
    }

    const existingData = cache.readQuery<ApiAttachFileToBidResponse>({
      query: GET_BID_ATTACHMENTS,
      variables: { bidId }
    });

    if (existingData) {
      const { getBidAttachments } = existingData;

      cache.writeQuery({
        query: GET_BID_ATTACHMENTS,
        variables: { bidId },
        data: {
          getBidAttachments: getBidAttachments.filter(attachment => attachment.id !== id)
        }
      });
    }

    cache.evict({ id: cache.identify({ id, __typename: 'BidAttachment' }) });
    cache.gc();
  };
}
