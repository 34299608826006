import { trackWithSessionReplay } from '../helpers';

export function trackECSaveNew(ecName: string): void {
  trackWithSessionReplay('EC save new', {
    ecName
  });
}

export function trackECSaveSearch(ecName: string): void {
  trackWithSessionReplay('EC save search', {
    ecName
  });
}

export function trackECClearActiveFilters(mpId: string): void {
  trackWithSessionReplay('EC clear', {
    mpId
  });
}

export function trackECDeleteSavedSearch(ecId: string): void {
  trackWithSessionReplay('EC delete search', {
    ecId
  });
}

export function trackECContractValue(min?: number, max?: number, currency?: string, checkbox?: boolean): void {
  trackWithSessionReplay('EC Contract value', {
    min,
    max,
    currency,
    checkbox: checkbox ? 'checked' : 'unchecked'
  });
}

export function trackECSupplier(supplierId: string): void {
  trackWithSessionReplay('EC Supplier', {
    supplierId
  });
}

export function trackECDateRange(): void {
  trackWithSessionReplay('EC Date range');
}
