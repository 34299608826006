import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Form, Checkbox } from 'src/common';
import styles from './index.module.scss';
import classNames from 'classnames';
import { useForm } from 'antd/lib/form/Form';
import TenderFilesTree from './TenderFilesTree';
import { useBidding } from 'src/models/bids/BidFull/hooks';
import { hitGroupLimits } from 'src/models/bids/BidFull/helpers';
import { IProcurementFileCategory } from 'src/models/procurements/Tender/types';
import { TaskTrackEventSource } from 'src/segment/events';
import { useGetBidAttachments, useGetBidAttachmentsStatus } from 'src/models/bids/BidAttachments/hooks';
import { MyAttachmentsTree } from './MyFilesTree';
import { useTenderFiles } from 'src/models/procurements/TenderFiles/hooks';
import { PrivateFileInput } from 'src/models/bids/BidFull/types';
import { AiDisclaimer } from 'src/common/AiDisclaimer';
import { AttachFileToBidResponse } from 'src/models/bids/BidAttachments/types';
import Accordion from 'src/common/Accordion';

interface IPrePopulateModalProps {
  onCloseModal: () => void;
  eventSource: TaskTrackEventSource;
  onTaskGeneration: (publicFiles: string[], privateFiles: PrivateFileInput[]) => void;
}

const MODAL_WIDTH = '64rem';
const POLLING_INTERVAL = 5000;

const initializePrivateFiles = {
  urls: (attachments?: AttachFileToBidResponse[]): string[] =>
    attachments?.length ? attachments.filter(file => file.hasChunkedData).map(file => file.document.getUrl) : [],
  values: (attachments?: AttachFileToBidResponse[]): PrivateFileInput[] =>
    attachments?.length
      ? attachments
          .filter(file => file.hasChunkedData)
          .map(file => ({
            id: file.document.id,
            name: file.document.name
          }))
      : []
};

export const PrePopulateModal: FC<IPrePopulateModalProps> = ({ onCloseModal, onTaskGeneration }) => {
  const { t } = useTranslation();
  const [form] = useForm();

  const { data: biddingData } = useBidding();
  const { data: bidAttachmentsData } = useGetBidAttachments(biddingData?.id ?? '');
  const { startPolling, stopPolling } = useGetBidAttachmentsStatus(biddingData?.id ?? '');
  const { data: tenderFiles } = useTenderFiles(biddingData?.originId);

  const fileCategories = useMemo(() => tenderFiles?.filesCats.fileCategories || [], [tenderFiles]);
  const previousGeneratedFiles = useMemo(() => biddingData?.previousGeneratedFiles || [], [biddingData]);
  const previousGeneratedEmptyGroupFiles = useMemo(() => biddingData?.emptyQuestionGroupsFiles || [], [biddingData]);
  const manuallyCreatedFiles = useMemo(() => biddingData?.manuallyCreatedFiles ?? [], [biddingData]);
  const isPollingNeeded = useMemo(() => {
    return bidAttachmentsData?.some(file => !file.hasChunkedData) ?? false;
  }, [bidAttachmentsData]);
  const procurementId = biddingData?.originId;

  const [checkedFiles, setCheckedFiles] = useState<string[]>(previousGeneratedFiles);
  const [allTenderFilesLength, setAllTenderFilesLength] = useState<number>();
  const [checkedPrivateFiles, setCheckedPrivateFiles] = useState<string[]>(
    initializePrivateFiles.urls(bidAttachmentsData)
  );
  const [selectedPrivateFiles, setSelectedPrivateFiles] = useState<PrivateFileInput[]>(
    initializePrivateFiles.values(bidAttachmentsData)
  );

  const previousGeneratedPrivateFiles = useMemo(() => {
    return (
      bidAttachmentsData
        ?.filter(file => previousGeneratedFiles.some(el => el.split('/').pop() === file.document.id))
        .map(file => file.document.id) ?? []
    );
  }, [bidAttachmentsData, previousGeneratedFiles]);

  const hitLimits = useMemo(
    () => hitGroupLimits(biddingData?.questionGroups || [], checkedFiles),
    [checkedFiles, biddingData]
  );

  const restCheckedFiles = useMemo(() => {
    return previousGeneratedFiles.length
      ? checkedFiles.filter(file => !previousGeneratedFiles.includes(file))
      : checkedFiles;
  }, [checkedFiles, previousGeneratedFiles]);

  const restPrivateCheckedFiles = useMemo(() => {
    return previousGeneratedPrivateFiles.length
      ? selectedPrivateFiles.filter(file => !previousGeneratedPrivateFiles.includes(file.id))
      : selectedPrivateFiles;
  }, [previousGeneratedPrivateFiles, selectedPrivateFiles]);

  const extractFilePaths = useCallback((categories: IProcurementFileCategory[]): string[] => {
    let totalCount = 0;
    const fileNameMap = new Map<string, string>();
    const collectPaths = (cats: IProcurementFileCategory[]): void => {
      cats.forEach(category => {
        category.children.forEach(file => {
          const fileName = file.fileName;
          const isFile = !file.children || file.children.length === 0;
          if (isFile && fileName && !fileNameMap.has(fileName)) {
            totalCount++;
            fileNameMap.set(fileName, file.targetPath);
          }
          if (file.children?.length) {
            collectPaths([{ name: file.fileName, description: '', children: file.children }]);
          }
        });
      });
    };
    collectPaths(categories);
    setAllTenderFilesLength(totalCount);
    return Array.from(fileNameMap.values());
  }, []);

  const handleCheck = useCallback((checkedKeys: string[]) => setCheckedFiles(checkedKeys), []);
  const handleClose = useCallback(() => {
    onCloseModal();
    setCheckedFiles([]);
  }, [onCloseModal]);

  const handleAutoCreate = useCallback(() => {
    onCloseModal();
    onTaskGeneration(restCheckedFiles, restPrivateCheckedFiles);
  }, [onCloseModal, onTaskGeneration, restCheckedFiles, restPrivateCheckedFiles]);

  const handleTenderFilesCheckboxChange = useCallback(() => {
    const allTenderPaths = extractFilePaths(fileCategories);
    if (restCheckedFiles.length === 0) {
      setCheckedFiles(allTenderPaths);
    } else {
      // Reset to only previously generated files
      const previouslyGeneratedOnly = allTenderPaths.filter(file => previousGeneratedFiles.includes(file));
      setCheckedFiles(previouslyGeneratedOnly);
    }
  }, [extractFilePaths, fileCategories, previousGeneratedFiles, restCheckedFiles.length]);

  const handlePrivateFilesCheckboxChange = useCallback(() => {
    const allPrivateValues = initializePrivateFiles.values(bidAttachmentsData);
    const allPrivateUrls = initializePrivateFiles.urls(bidAttachmentsData);
    if (restPrivateCheckedFiles.length === 0) {
      // Select all files when none are selected
      setSelectedPrivateFiles(allPrivateValues);
      setCheckedPrivateFiles(allPrivateUrls);
    } else {
      // Reset to only previously generated files
      const previouslyGeneratedOnly = allPrivateValues.filter(file => previousGeneratedPrivateFiles.includes(file.id));
      const previouslyGeneratedUrlsOnly = allPrivateUrls.filter(url =>
        previousGeneratedPrivateFiles.some(id => url.includes(id))
      );
      setSelectedPrivateFiles(previouslyGeneratedOnly);
      setCheckedPrivateFiles(previouslyGeneratedUrlsOnly);
    }
  }, [bidAttachmentsData, previousGeneratedPrivateFiles, restPrivateCheckedFiles]);

  useEffect(() => {
    setCheckedFiles(extractFilePaths(fileCategories));
  }, [extractFilePaths, fileCategories]);

  useEffect(() => {
    if (isPollingNeeded) {
      startPolling(POLLING_INTERVAL);
    } else {
      stopPolling();
    }

    return () => stopPolling();
  }, [isPollingNeeded, startPolling, stopPolling]);

  return (
    <Modal
      title={t('BiddingTool.prePopulateModalTitle')}
      visible
      onCancel={handleClose}
      onOk={handleAutoCreate}
      closable
      footer={[
        <Button key="cancel" type={'text'} onClick={handleClose}>
          {t('Common.cancel')}
        </Button>,
        <Button
          key="pre-populate"
          type={'primary'}
          onClick={handleAutoCreate}
          disabled={!restCheckedFiles.length && !restPrivateCheckedFiles.length}
        >
          {t('BiddingTool.prePopulateButton')}
        </Button>
      ]}
      width={MODAL_WIDTH}
    >
      <div className={styles.modalContent}>
        <p className={styles.description}>{t('BiddingTool.prePopulateModalDesc')}</p>
        <AiDisclaimer />
        {!!fileCategories.length && (
          <Accordion
            label={
              <div className={styles.heading}>
                <Checkbox
                  checked={restCheckedFiles.length > 0}
                  onChange={handleTenderFilesCheckboxChange}
                  onClick={e => {
                    e.stopPropagation();
                  }}
                  className={styles.checkbox}
                />
                <span> {t(`Tenders.FileCategory.Tender documents`)}</span>
                <div className={styles.filesTotal}>
                  {restCheckedFiles?.length} {t(`Common.of`)} {allTenderFilesLength}
                </div>
              </div>
            }
            className={styles.accordion}
            initialValue
          >
            <Form form={form} className={styles.form}>
              <div className={classNames(styles.fileTree)}>
                <TenderFilesTree
                  fileCategories={fileCategories}
                  checkedFiles={checkedFiles}
                  precheckFiles={previousGeneratedFiles}
                  previousGeneratedEmptyGroupFiles={previousGeneratedEmptyGroupFiles}
                  disabledFiles={manuallyCreatedFiles}
                  restCheckedFiles={restCheckedFiles}
                  onCheck={handleCheck}
                  hitLimits={hitLimits}
                  procurementId={procurementId}
                />
              </div>
            </Form>
          </Accordion>
        )}
        {!!bidAttachmentsData?.length && (
          <Accordion
            label={
              <div className={styles.heading}>
                <Checkbox
                  checked={restPrivateCheckedFiles?.length > 0}
                  onChange={handlePrivateFilesCheckboxChange}
                  onClick={e => {
                    e.stopPropagation();
                  }}
                  className={styles.checkbox}
                />
                <span>{t('BiddingTool.myUploadedDocuments')}</span>
                <div className={styles.filesTotal}>
                  {restPrivateCheckedFiles?.length} {t(`Common.of`)} {bidAttachmentsData?.length}
                </div>
              </div>
            }
            className={styles.accordion}
          >
            <MyAttachmentsTree
              attachments={bidAttachmentsData ?? []}
              precheckFiles={previousGeneratedPrivateFiles}
              previousGeneratedEmptyGroupFiles={previousGeneratedEmptyGroupFiles}
              restCheckedFiles={[]}
              disabledFiles={[]}
              onCheck={(checkedKeys, selectedFiles) => {
                setCheckedPrivateFiles(checkedKeys);
                setSelectedPrivateFiles(selectedFiles ?? []);
              }}
              checkedFiles={checkedPrivateFiles}
            />
          </Accordion>
        )}
      </div>
    </Modal>
  );
};

export default PrePopulateModal;
