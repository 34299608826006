import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'src/common';
import styles from './index.module.scss';
import TenderDescriptionInfo from 'src/shared/TenderDescriptionInfo';
import { AITitles, Summaries } from 'src/models/procurements/Preview/types';
import TenderSummary from 'src/pages/TendersPage/Sidebars/TenderPreview/Summary';

interface Props {
  onClose: () => void;
  summaries?: Summaries;
  titles?: AITitles;
}

const QuickSummaryModal: FC<Props> = ({ onClose, summaries, titles }) => {
  const { t } = useTranslation();

  return (
    <Modal onCancel={onClose} title={''} visible footer={null} width={670} className={styles.quickSummaryModal}>
      <span className={styles.quickSummaryTitle}>{t('Tenders.quickSummary')}</span>

      <TenderSummary summaries={summaries} titles={titles} showEnglish />
      <TenderDescriptionInfo desc={t(`Tenders.quickSummaryInfo`)} />
    </Modal>
  );
};

export default QuickSummaryModal;
