import { ApiDecisionMatrix, DecisionMatrixItem, ApiDecisionMatrixTemplate, DecisionMatrixTemplateItem } from './types';

export class DecisionMatrix {
  public readonly comment?: string;
  public readonly id: string;
  public readonly items: DecisionMatrixItem[];
  public readonly title: string;

  constructor(apiDecisionMatrixTemplate: ApiDecisionMatrix) {
    this.comment = apiDecisionMatrixTemplate.comment;
    this.id = apiDecisionMatrixTemplate.id;
    this.items = apiDecisionMatrixTemplate.items;
    this.title = apiDecisionMatrixTemplate.title ?? '';
  }
}

export class DecisionMatrixTemplate {
  public readonly id: string;
  public readonly items: DecisionMatrixTemplateItem[];
  public readonly title: string;

  constructor(apiDecisionMatrixTemplate: ApiDecisionMatrixTemplate) {
    this.id = apiDecisionMatrixTemplate.id;
    this.items = apiDecisionMatrixTemplate.items;
    this.title = apiDecisionMatrixTemplate.title ?? '';
  }
}
