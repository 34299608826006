import React, { FC, useState, useCallback } from 'react';
import { IBoxFieldRange } from 'src/models/procurements/Tender/types';
import { useTranslation } from 'react-i18next';
import { IBlockProps, FieldViewMode } from 'src/shared/Tender/Boxes/types';
import styles from './index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddValueButton from '../AddValueButton';
import ReadOnlyBlockRange from './ReadOnlyBlock';
import { faPen } from '@fortawesome/pro-light-svg-icons';

interface Props extends IBlockProps<IBoxFieldRange> {}
export const BlockRange: FC<Props> = ({ field, className }) => {
  const { t } = useTranslation();
  const [currentMode, setCurrentMode] = useState(FieldViewMode.Read);
  const onToggleView = useCallback(() => {
    false && setCurrentMode(prev => (prev === FieldViewMode.Read ? FieldViewMode.Edit : FieldViewMode.Read));
  }, []);

  return (
    <>
      {currentMode === FieldViewMode.Read && (
        <>
          {field.from || field.to ? (
            <div className={styles.toggler}>
              <ReadOnlyBlockRange from={field.from} to={field.to} className={className} />
              {false && (
                <div className={styles.togglerIcon}>
                  <FontAwesomeIcon icon={faPen} />
                </div>
              )}
            </div>
          ) : (
            <AddValueButton onToggleView={onToggleView} isEditable={false} title={t('Common.Blocks.addDuration')} />
          )}
        </>
      )}
    </>
  );
};

export default BlockRange;
