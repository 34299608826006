import {
  TenderSubCatName,
  ITenderSubCat,
  IApiTender,
  ITenderCat,
  ITenderBox,
  IGeneralCat,
  IGeneralSubCat,
  IApiTenderBox,
  isTenderBox,
  TenderCatName,
  ILotsSubCat,
  IDocsCat,
  ITenderDataKeys,
  IAdditionalSubCat,
  ITenderLot,
  IGenericCat,
  ApiTenderMinimal
} from './types';
import {
  convertApiLotsToLots,
  filterBoxes,
  groupBoxesToCats,
  sortDateBoxes,
  groupBoxesByHeliumId,
  toGenericCat,
  toGenericSubCat,
  getQuestionId,
  toDocsCat
} from './helpers';
import { isKeyOfObject, isNotUndefined } from 'src/helpers';
import {
  ADDITION_INFO_ORDER,
  SUB_CATS_BOXES,
  EXCLUDED_FROM_ADDITIONAL_KEYS,
  CUSTOM_BID_FIELDS_ORDER,
  SUB_CAT_ICON_MAPPER
} from './mappers';
import { IApiComment } from 'src/models/comments/types';
import { IBidIdentifier } from 'src/models/bids/Bids/types';
import { IBoxWithComments } from './types';
import { TenderBox, BoxFieldDate, TenderBoxFactory } from '..';
import { BoxSpecId } from '../types';
import { getSortedBuyerBoxes } from '../helpers';
import { AITitles, Summaries } from '../Preview/types';
import { ProcurementStatus } from '@tendium/prom-types/dist/schema/interactions/tender';

export class TenderMinimal {
  public readonly name?: ITenderBox;
  public readonly published?: ITenderBox;
  public readonly procurementStatus?: ProcurementStatus[];
  public readonly isPlanningProcess?: boolean;

  constructor(tenderMinimal: ApiTenderMinimal) {
    this.name = tenderMinimal.general.name ? new TenderBox(tenderMinimal.general.name) : undefined;
    this.published = tenderMinimal.timeline.availableDate
      ? new TenderBox(tenderMinimal.timeline.availableDate)
      : undefined;
    this.procurementStatus = tenderMinimal.procurementStatus;
    this.isPlanningProcess = tenderMinimal.isPlanningProcess;
  }
}

export default class TenderFullData {
  public readonly id: string;
  public readonly name?: ITenderBox;
  public readonly generalCat: IGeneralCat;
  public readonly docsCat: IDocsCat;
  public readonly keywordSearchCat: IGenericCat;
  public readonly cats: ITenderCat[];
  public readonly customBoxes: ITenderBox[];
  public readonly linkToQA?: ITenderBox;
  public readonly comments: IApiComment[];
  public readonly isRead: boolean;
  public readonly flatComments: IApiComment[];
  public readonly bids?: IBidIdentifier[];
  public readonly boxesWithComments: IBoxWithComments[];
  public readonly summaries?: Summaries;
  public readonly titles?: AITitles;
  public currentBox(boxId: string): IBoxWithComments | null {
    const general = this.apiTender.general;
    const timeline = this.apiTender.timeline;
    const apiSpecBoxes = Object.values({ ...general, ...timeline })
      .flat()
      .filter(isNotUndefined);

    const apiBox = [...this.apiTender.boxes, ...apiSpecBoxes].find(box => box.id === boxId);
    const questionId = apiBox ? getQuestionId(apiBox) : undefined;

    return (
      (!!apiBox && {
        comments: apiBox.comments,
        title: apiBox.title,
        id: apiBox.id,
        ...(questionId ? { questionId } : {})
      }) ||
      null
    );
  }
  public readonly isEditable: boolean;
  private tenderLots: ITenderLot[];
  private allCats: ITenderCat[];
  private apiTender: IApiTender;

  constructor(originalApiTender: IApiTender, private readonly options: { editable?: boolean }) {
    const apiTender = this.parseApiTender(originalApiTender);
    this.apiTender = apiTender;
    this.tenderLots = convertApiLotsToLots(this.apiTender.lots);
    this.allCats = groupBoxesToCats(this.apiTender.boxes, this.tenderLots, !!this.apiTender.general.linkToQA.length);
    this.isEditable = this.toEditable(!!this.options.editable);
    this.id = apiTender.id;
    this.name = apiTender.general.name ? new TenderBox(apiTender.general.name) : undefined;
    this.customBoxes = apiTender.custom
      .map(box => !!box && new TenderBox(box))
      .filter(isNotUndefined)
      .sort((a, b) => {
        if (!a) {
          return -1;
        }
        if (!b) {
          return 1;
        }
        const aTypeIdx = CUSTOM_BID_FIELDS_ORDER.findIndex(fieldOrder => fieldOrder === a.firstField?.type);
        const bTypeIdx = CUSTOM_BID_FIELDS_ORDER.findIndex(fieldOrder => fieldOrder === b.firstField?.type);

        return aTypeIdx - bTypeIdx;
      });
    this.linkToQA = !!apiTender.general.linkToQA
      ? filterBoxes(apiTender.general.linkToQA, this.tenderLots)[0]
      : undefined;
    this.generalCat = this.toGeneralCat();
    this.docsCat = this.toDocsCat();
    this.keywordSearchCat = toGenericCat(TenderCatName.KeywordSearch);
    this.cats = this.allCats.filter(cat => !cat.id.startsWith(TenderCatName.Overview)) ?? [];
    this.bids = this.toBids();
    this.comments = apiTender.comments;
    this.isRead = apiTender.isRead;
    this.flatComments = this.toFlatAllComments();
    this.boxesWithComments = this.toBoxesWithComments();
    this.summaries = apiTender.summaries;
    this.titles = apiTender.titles;
  }

  private parseApiTender(apiTender: IApiTender): IApiTender {
    return {
      ...apiTender,
      general: { ...apiTender.general, buyerBoxes: getSortedBuyerBoxes(apiTender.general.buyerBoxes) }
    };
  }

  private toEditable(editable: boolean): boolean {
    const bids = this.toBids();
    return editable && !!bids;
  }

  private toBids(): IBidIdentifier[] | undefined {
    if (this.apiTender.bids.length > 0)
      return this.apiTender.bids.map(bid => ({
        id: bid.id,
        wsId: bid.workspace.id
      }));
    else return undefined;
  }

  private toGeneralCat(): IGeneralCat {
    const generalCat = toGenericCat(TenderCatName.Overview);

    return {
      ...generalCat,
      [TenderSubCatName.Intro]: this.toGeneralSubCat(
        TenderSubCatName.Intro,
        SUB_CATS_BOXES[TenderSubCatName.Intro] || []
      ),
      [TenderSubCatName.ShortDescription]: this.toGeneralSubCat(
        TenderSubCatName.ShortDescription,
        SUB_CATS_BOXES[TenderSubCatName.ShortDescription] || []
      ),
      [TenderSubCatName.ScopeOfContract]: this.toGeneralSubCat(
        TenderSubCatName.ScopeOfContract,
        SUB_CATS_BOXES[TenderSubCatName.ScopeOfContract] || []
      ),
      [TenderSubCatName.Timeline]: this.toTimelineSubCat(),
      [TenderSubCatName.Location]: this.toGeneralSubCat(
        TenderSubCatName.Location,
        SUB_CATS_BOXES[TenderSubCatName.Location] || []
      ),
      [TenderSubCatName.Procurement]: this.toGeneralSubCat(
        TenderSubCatName.Procurement,
        SUB_CATS_BOXES[TenderSubCatName.Procurement] || []
      ),
      [TenderSubCatName.Contact]: this.toGeneralSubCat(
        TenderSubCatName.Contact,
        SUB_CATS_BOXES[TenderSubCatName.Contact] || []
      ),
      [TenderSubCatName.Contract]: this.toGeneralSubCat(
        TenderSubCatName.Contract,
        SUB_CATS_BOXES[TenderSubCatName.Contract] || []
      ),
      [TenderSubCatName.Cpv]: this.toGeneralSubCat(TenderSubCatName.Cpv, SUB_CATS_BOXES[TenderSubCatName.Cpv] || []),
      [TenderSubCatName.Additional]: this.toAdditionalSubCat(),
      [TenderSubCatName.Competitors]: this.toGeneralSubCat(TenderSubCatName.Competitors, []),
      [TenderSubCatName.Lots]: this.toLotsSubCat(),
      [TenderSubCatName.Custom]: this.toCustomSubCat()
    };
  }

  private toTimelineSubCat(): ITenderSubCat {
    const subCat = toGenericSubCat(TenderSubCatName.Timeline);
    const datesMap: [string, IApiTenderBox | IApiTenderBox[] | undefined][] = Object.entries(this.apiTender.timeline);
    const dates = datesMap
      .map(([key, value]) => {
        if (!value) {
          return undefined;
        } else if (isTenderBox(value)) {
          return new TenderBox({ ...value, title: key });
        } else if (Array.isArray(value)) {
          const boxesArray = filterBoxes(
            value.map(box => ({ ...box, title: key })),
            this.tenderLots
          );
          return !!boxesArray.length ? boxesArray[0] : undefined;
        } else {
          return undefined;
        }
      })
      .filter(isNotUndefined)
      .filter(box => box.fields && !!box.fields.length);

    const boxes = [
      new TenderBox({
        id: '',
        title: 'today',
        specificationId: '',
        category: `${TenderCatName.Overview}.${TenderSubCatName.Timeline}`,
        fields: [new BoxFieldDate('today', new Date().getTime())],
        comments: [],
        requirementStatus: null
      }),
      ...dates
    ].sort((aBox, bBox): number => {
      return sortDateBoxes(aBox, bBox);
    });

    return {
      ...subCat,
      boxes
    };
  }

  private toLotsSubCat(): ILotsSubCat {
    const subCat = toGenericSubCat(TenderSubCatName.Lots);
    return {
      ...subCat,
      lots: this.tenderLots
    };
  }

  private toAdditionalSubCat(): IAdditionalSubCat {
    const subCatName = TenderSubCatName.Additional;
    const general = this.apiTender.general;
    const timeline = this.apiTender.timeline;
    const contractOtherBoxes = this.apiTender.boxes.filter(
      box => box.category.includes('GENERAL_INFORMATION') && box.specificationId === BoxSpecId.CONTRACT_PERIOD
    );
    const contractPeriodBoxes = groupBoxesByHeliumId(
      [
        timeline.contractStartAndEndStart,
        timeline.contractStartAndEndEnd,
        ...contractOtherBoxes,
        ...general.contractDurationBoxes,
        ...general.contractRenewalBoxes
      ]
        .filter(isNotUndefined)
        .map(box => new TenderBox(box))
    );

    const apiSpecBoxes = [...Object.values(SUB_CATS_BOXES)]
      .flat()
      .filter(boxName => !EXCLUDED_FROM_ADDITIONAL_KEYS.includes(boxName))
      .map(boxName => {
        return isKeyOfObject(boxName, general)
          ? general[boxName]
          : isKeyOfObject(boxName, timeline)
          ? timeline[boxName]
          : undefined;
      });

    const specBoxesMap: Map<string, ITenderBox> = new Map();
    apiSpecBoxes.forEach(value => {
      if (isTenderBox(value)) {
        const box = new TenderBox(value);
        if (box && box.rawFields.length > 1 && !box.isEmpty) {
          specBoxesMap.set(box.id, box);
        }
      } else if (Array.isArray(value)) {
        const boxesArray = filterBoxes(value, this.tenderLots);
        if (boxesArray.length > 1 || (boxesArray.length === 1 && boxesArray[0].rawFields.length > 1)) {
          boxesArray.forEach(box => {
            if (!box.isEmpty) {
              specBoxesMap.set(box.id, box);
            }
          });
        }
      }
    });

    const otherBoxes =
      this.allCats
        .find(cat => cat.id.startsWith(TenderCatName.Overview))
        ?.subCats.map(subCat => subCat.boxes)
        .flat()
        .filter(box => !!box && !specBoxesMap.has(box.id) && box.specificationId !== BoxSpecId.CONTRACT_PERIOD)
        .filter(
          box =>
            box &&
            [...specBoxesMap.values()]
              .map(specBox => isTenderBox(specBox) && specBox.id)
              .some(specId => specId !== box.id)
        ) ?? [];
    const otherBoxesMap: Map<string, ITenderBox> = new Map();
    otherBoxes.forEach(box => box && otherBoxesMap.set(box.id, box));

    const sortedSpecBoxes = [...specBoxesMap.values(), ...contractPeriodBoxes]
      .sort((aBox, bBox): number => {
        const aLots = Array.isArray(aBox) ? aBox[0].lots : aBox.lots;
        const bLots = Array.isArray(bBox) ? bBox[0].lots : bBox.lots;

        if (aLots === undefined && bLots === undefined) {
          return 0;
        }
        if (aLots === undefined) {
          return -1;
        }
        if (bLots === undefined) {
          return 1;
        }

        const aLotIdxMin = Math.min(...aLots.map(lot => lot.lotItem));
        const bLotIdxMin = Math.min(...bLots.map(lot => lot.lotItem));

        return aLotIdxMin - bLotIdxMin;
      })
      .sort((aBox, bBox) => {
        if (
          (Array.isArray(aBox) && !aBox[0].title) ||
          (isTenderBox(aBox) && !aBox.title) ||
          (Array.isArray(bBox) && !bBox[0].title) ||
          (isTenderBox(bBox) && !bBox.title)
        ) {
          return 0;
        }
        const aBoxIdx = ADDITION_INFO_ORDER.findIndex(
          boxOrder => boxOrder === (Array.isArray(aBox) ? aBox[0].title : aBox.title)
        );
        const bBoxIdx = ADDITION_INFO_ORDER.findIndex(
          boxOrder => boxOrder === (Array.isArray(bBox) ? bBox[0].title : bBox.title)
        );
        return aBoxIdx - bBoxIdx;
      });

    /**
     * FIXME: quick fix for not showing updated buyer boxes twice in additional info
     * Should do proper fix in https://tendium.atlassian.net/browse/PROM-6203
     */
    const filteredOtherBoxes = [...otherBoxesMap.values()].filter(
      box => box.specificationId !== BoxSpecId.PROCURING_AGENCY
    );

    return {
      id: subCatName,
      title: subCatName,
      icon: SUB_CAT_ICON_MAPPER[subCatName],
      boxes: [...sortedSpecBoxes, ...filteredOtherBoxes]
    } as IAdditionalSubCat;
  }

  private toCustomSubCat(): ITenderSubCat {
    const subCat = toGenericSubCat(TenderSubCatName.Custom);
    return {
      ...subCat,
      boxes: this.customBoxes
    };
  }

  private toGeneralSubCat(subCatName: TenderSubCatName, boxesNames: ITenderDataKeys[]): IGeneralSubCat {
    const general = this.apiTender.general;
    const timeline = this.apiTender.timeline;
    const subCat = toGenericSubCat(subCatName);
    const rawBoxes: Record<ITenderDataKeys, IApiTenderBox[] | IApiTenderBox | undefined> = {} as Record<
      ITenderDataKeys,
      IApiTenderBox[] | IApiTenderBox | undefined
    >;

    boxesNames.forEach(boxName => {
      rawBoxes[boxName] = isKeyOfObject(boxName, general)
        ? general[boxName]
        : isKeyOfObject(boxName, timeline)
        ? timeline[boxName]
        : undefined;
    });
    const t = Object.fromEntries(
      Object.entries(rawBoxes).map(([key, value]) => {
        let tempBox: ITenderBox[];
        if (Array.isArray(value)) {
          if (!!value.length) {
            tempBox = filterBoxes(value, this.tenderLots);
          } else {
            tempBox = [new TenderBoxFactory().create(key as ITenderDataKeys)];
          }
        } else if (isTenderBox(value)) {
          tempBox = [new TenderBox(value)];
        } else {
          tempBox = [new TenderBoxFactory().create(key as ITenderDataKeys)];
        }
        return [key, tempBox];
      })
    );

    return {
      ...subCat,
      boxes: t as Record<ITenderDataKeys, ITenderBox[] | undefined>
    };
  }

  private toDocsCat(): IDocsCat {
    return toDocsCat(this.apiTender.general.linkToTenderDocumentBoxes, this.tenderLots, this.apiTender.fileCategories);
  }

  private toFlatAllComments(): IApiComment[] {
    const procComments = this.comments;
    const boxes = this.apiTender.boxes;
    const boxesComments: IApiComment[] = [];
    boxes.forEach(box => {
      box.comments.forEach(comment => {
        boxesComments.push(comment);
      });
    });
    return [...procComments, ...boxesComments];
  }

  private toBoxesWithComments(): IBoxWithComments[] {
    const boxes = this.apiTender.boxes;
    const timelinesBoxes: IApiTenderBox[] = Object.values(this.apiTender.timeline).flat().filter(isNotUndefined);
    const generalBoxes: IApiTenderBox[] = Object.values(this.apiTender.general).flat().filter(isNotUndefined);
    const boxesWithComments: IBoxWithComments[] = [];

    [...boxes, ...timelinesBoxes, ...generalBoxes].forEach(box => {
      const boxComments: IApiComment[] = [];
      box.comments && box.comments.forEach(comment => boxComments.push(comment));
      const questionId = getQuestionId(box);
      if (boxComments.length) {
        boxesWithComments.push({
          comments: boxComments,
          title: box.title,
          id: box.id,
          ...(questionId ? { questionId } : {})
        });
      }
    });

    return boxesWithComments;
  }
}
