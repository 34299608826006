import { IApiBidChecklist } from '../BidChecklist/types';
import { QueryResult } from '@apollo/client/react/types/types';
import { ApolloError, MutationUpdaterFn, useMutation, useQuery } from '@apollo/client';
import {
  APPLY_CHECKLIST_TEMPLATE,
  CREATE_CHECKLIST_TEMPLATE,
  DELETE_CHECKLIST_TEMPLATE,
  GET_CHECKLIST_TEMPLATES
} from './queries';
import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { notification } from 'src/common';
import { IApiChecklistTemplate, IChecklistTemplate } from './types';
import { User } from 'src/models/users';

export function useChecklistTemplates(): Omit<
  QueryResult<{ getChecklistTemplates: IApiChecklistTemplate[] }>,
  'data'
> & {
  data?: IChecklistTemplate[];
} {
  const query = useQuery<{ getChecklistTemplates: IApiChecklistTemplate[] }>(GET_CHECKLIST_TEMPLATES);
  return useMemo(
    () => ({
      ...query,
      data: query.data
        ? query.data.getChecklistTemplates.map(template => ({
            ...template,
            createdBy: template.createdBy ? new User(template.createdBy) : null
          }))
        : undefined
    }),
    [query]
  );
}

interface ICreateChecklistTemplateInput {
  bidId: string;
  name: string;
}
interface ICreateChecklistTemplateOutput {
  createChecklistTemplate: IApiChecklistTemplate;
}
function updateCacheCreateChecklistTemplate(): MutationUpdaterFn<ICreateChecklistTemplateOutput> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }
    const tmplQueryData = cache.readQuery<{ getChecklistTemplates: IApiChecklistTemplate[] } | null>({
      query: GET_CHECKLIST_TEMPLATES
    });
    if (tmplQueryData) {
      const { getChecklistTemplates } = tmplQueryData;
      cache.writeQuery({
        query: GET_CHECKLIST_TEMPLATES,
        data: { getChecklistTemplates: [...getChecklistTemplates, data.createChecklistTemplate] }
      });
    }
  };
}
export function useCreateChecklistTemplate(): [
  (data: ICreateChecklistTemplateInput, onFinish?: () => void) => void,
  { loading: boolean; error?: ApolloError }
] {
  const { t } = useTranslation();
  const [createChecklistTemplate, { loading, error }] = useMutation<
    ICreateChecklistTemplateOutput,
    ICreateChecklistTemplateInput
  >(CREATE_CHECKLIST_TEMPLATE);
  const createChecklistTemplateFn = useCallback(
    (data: ICreateChecklistTemplateInput, onFinish?: () => void) => {
      createChecklistTemplate({
        variables: data,
        update: updateCacheCreateChecklistTemplate()
      })
        .then(() => {
          onFinish && onFinish();
        })
        .catch(() => {
          notification.error({
            description: t('Common.unknownErrorDesc'),
            message: t('Common.unknownError')
          });
        });
    },
    [createChecklistTemplate, t]
  );

  return useMemo(() => [createChecklistTemplateFn, { loading, error }], [error, loading, createChecklistTemplateFn]);
}

interface IApplyChecklistTemplateInput {
  id: string;
  bidId: string;
}
interface IApplyChecklistTemplateOutput {
  applyChecklistTemplate: IApiBidChecklist;
}
function updateCacheApplyChecklistTemplate(bidId: string): MutationUpdaterFn<IApplyChecklistTemplateOutput> {
  return (cache, { data }) => {
    if (!data?.applyChecklistTemplate) {
      return null;
    }
    const bidRef = cache.identify({
      __typename: 'BidV2',
      id: bidId
    });

    cache.modify({
      id: bidRef,
      fields: {
        checklist(prevChecklist, { toReference }) {
          return data.applyChecklistTemplate.checklist.map(item =>
            toReference({ __typename: 'ChecklistItem', id: item.id })
          );
        }
      }
    });
  };
}
export function useApplyChecklistTemplate(): [
  (data: IApplyChecklistTemplateInput, onFinish?: () => void) => void,
  { loading: boolean; error?: ApolloError }
] {
  const { t } = useTranslation();
  const [applyChecklistTemplate, { loading, error }] = useMutation<
    IApplyChecklistTemplateOutput,
    IApplyChecklistTemplateInput
  >(APPLY_CHECKLIST_TEMPLATE);
  const applyChecklistTemplateFn = useCallback(
    (data: IApplyChecklistTemplateInput, onFinish?: () => void) => {
      applyChecklistTemplate({
        variables: data,
        update: updateCacheApplyChecklistTemplate(data.bidId)
      })
        .then(() => {
          onFinish && onFinish();
        })
        .catch(() => {
          notification.error({
            description: t('Common.unknownErrorDesc'),
            message: t('Common.unknownError')
          });
        });
    },
    [applyChecklistTemplate, t]
  );

  return useMemo(() => [applyChecklistTemplateFn, { loading, error }], [error, loading, applyChecklistTemplateFn]);
}
interface IDeleteChecklistTemplateInput {
  id: string;
}
interface IDeleteChecklistTemplateOutput {
  deleteChecklistTemplate: boolean;
}
function updateCacheDeleteChecklistTemplate(id: string): MutationUpdaterFn<IDeleteChecklistTemplateOutput> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }
    const tmplQueryData = cache.readQuery<{ getChecklistTemplates: IApiChecklistTemplate[] } | null>({
      query: GET_CHECKLIST_TEMPLATES
    });
    if (tmplQueryData) {
      const { getChecklistTemplates } = tmplQueryData;
      cache.writeQuery({
        query: GET_CHECKLIST_TEMPLATES,
        data: { getChecklistTemplates: (getChecklistTemplates || []).filter(tmpl => tmpl.id !== id) }
      });
    }
  };
}
export function useDeleteChecklistTemplate(): [
  (data: IDeleteChecklistTemplateInput, onFinish?: () => void) => void,
  { loading: boolean; error?: ApolloError }
] {
  const { t } = useTranslation();
  const [deleteChecklistTemplate, { loading, error }] = useMutation<
    IDeleteChecklistTemplateOutput,
    IDeleteChecklistTemplateInput
  >(DELETE_CHECKLIST_TEMPLATE);
  const deleteChecklistTemplateFn = useCallback(
    (data: IDeleteChecklistTemplateInput, onFinish?: () => void) => {
      deleteChecklistTemplate({
        variables: data,
        update: updateCacheDeleteChecklistTemplate(data.id)
      })
        .then(() => {
          onFinish && onFinish();
        })
        .catch(() => {
          notification.error({
            description: t('Common.unknownErrorDesc'),
            message: t('Common.unknownError')
          });
        });
    },
    [deleteChecklistTemplate, t]
  );

  return useMemo(() => [deleteChecklistTemplateFn, { loading, error }], [error, loading, deleteChecklistTemplateFn]);
}
