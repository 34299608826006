import { Tooltip, Button } from 'src/common';
import classNames from 'classnames';
import React from 'react';
import { DataNode } from 'antd/lib/tree';
import { AttachFileToBidResponse } from 'src/models/bids/BidAttachments/types';
import styles from '../TenderFilesTree/index.module.scss';
import { faCloudDownload, faEye } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { splitFileExt } from 'src/helpers/files';
import { isPreviewableFile } from 'src/models/procurements/helpers';

export function toBidAttachmentsTreeData(
  attachments: AttachFileToBidResponse[],
  isDisabledFn: (attachment: AttachFileToBidResponse) => { disabled: boolean; tooltips?: string },
  getFile: (url: string, fileName: string) => void,
  previewFile: (url: string) => void,
  onShowDocument: (fileName: string) => void
): PrivateFileNode[] {
  return attachments.map(attachment => {
    const fileUrl = attachment.document.getUrl;
    const fileName = attachment.document.name;
    const { disabled, tooltips } = isDisabledFn(attachment);
    const fileObject = { id: attachment.document.id, name: fileName };

    const [, fileExt] = splitFileExt(fileName);
    const fileActions = (
      <>
        <Button
          type="text"
          className={classNames(styles.docAction, styles.fileAction)}
          onClick={e => {
            e.stopPropagation();
            if (fileUrl) {
              getFile(fileUrl, fileName);
            }
          }}
          icon={<FontAwesomeIcon icon={faCloudDownload} />}
        />
        {isPreviewableFile(fileExt) && fileExt === 'pdf' && (
          <Button
            type="text"
            className={classNames(styles.docAction, styles.fileAction)}
            onClick={e => {
              e.stopPropagation();
              if (fileUrl) {
                onShowDocument(fileName);
                previewFile(fileUrl);
              }
            }}
            icon={<FontAwesomeIcon icon={faEye} />}
          />
        )}
      </>
    );

    return {
      key: fileUrl || attachment.id,
      disableCheckbox: disabled,
      checkable: true,
      dataRef: fileObject,
      isLeaf: true,
      title: (
        <>
          <Tooltip title={disabled && tooltips ? tooltips : fileName}>
            <span
              className={classNames(styles.name, {
                [styles.disabled]: disabled && tooltips
              })}
            >
              {fileName}
            </span>
          </Tooltip>
          {fileActions}
        </>
      )
    };
  });
}

export interface PrivateFileNode extends DataNode {
  dataRef?: {
    id: string;
    name: string;
  };
}
