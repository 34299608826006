import { MutationUpdaterFn } from '@apollo/client';
import { IAddTenderBoxResponse, IRemoveTenderBoxResponse, TenderKeys } from './types';
import { Reference } from '@apollo/client/core';
import { clearWsStatsCache } from 'src/models/workspace/hooks';

export function updateCacheRemoveTenderBox(
  tenderId: string,
  boxId: string
): MutationUpdaterFn<IRemoveTenderBoxResponse> {
  return (cache, { data }) => {
    if (!data) {
      return null;
    }
    const tenderRef = cache.identify({
      __typename: 'Tender',
      id: tenderId
    });
    cache.modify({
      id: tenderRef,
      fields: {
        boxes(existingBoxesRefs: Reference[], { readField }) {
          return existingBoxesRefs.filter(ref => boxId !== readField('id', ref));
        }
      }
    });
  };
}

export function updateCacheAddTenderBox(
  itemId: string,
  key?: TenderKeys,
  bidId?: string
): MutationUpdaterFn<IAddTenderBoxResponse> {
  return (cache, { data }) => {
    if (!data) {
      return null;
    }

    const refId = bidId ? `${bidId}#${itemId}` : itemId;
    const newBoxId = data.addTenderBox.id;

    if (key) {
      const generalRef = cache.identify({
        __typename: 'General',
        id: refId
      });
      const timelineRef = cache.identify({
        __typename: 'TimelineRefactored',
        id: refId
      });

      cache.modify({
        id: timelineRef,
        fields: {
          [key]: (keyObj: Reference | Reference[], { toReference }) => {
            const newBoxRef = toReference({ __typename: 'TenderBox', id: newBoxId });
            return Array.isArray(keyObj) ? [newBoxRef] : newBoxRef;
          }
        }
      });
      cache.modify({
        id: generalRef,
        fields: {
          [key]: (keyObj: Reference | Reference[], { toReference }) => {
            const newBoxRef = toReference({ __typename: 'TenderBox', id: newBoxId });
            return Array.isArray(keyObj) ? [newBoxRef] : newBoxRef;
          }
        }
      });
    } else {
      const tenderRef = cache.identify({
        __typename: 'Tender',
        id: refId
      });
      cache.modify({
        id: tenderRef,
        fields: {
          boxes(existingBoxesRefs: Reference[], { readField, toReference }) {
            return existingBoxesRefs.some(ref => readField('id', ref) === newBoxId)
              ? existingBoxesRefs
              : [...existingBoxesRefs, toReference({ __typename: 'TenderBox', id: newBoxId })];
          }
        }
      });
    }

    let wsId;
    bidId &&
      cache.modify({
        id: cache.identify({
          __typename: 'BidV2',
          id: bidId
        }),
        fields: {
          workspace(wsRef, { readField }) {
            wsId = readField('id', wsRef);
            return wsRef;
          }
        }
      });
    wsId && clearWsStatsCache(cache, wsId);
  };
}
