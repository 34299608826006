import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { DEFAULT_BID_TASK_NODE_ATTRIBUTES } from './constants';
import CanvasBidTask from 'src/pages/CanvasPage/CanvasEditor/components/CanvasBidTask';
import { CUSTOM_NODE_NAMES } from '../../constants';
import { BidTaskNodeAttributesConfig } from './types';
import { getBidTaskNodePosition } from '../../helpers';
import { Plugin } from '@tiptap/pm/state';
import { DOMSerializer } from '@tiptap/pm/model';

export default Node.create({
  name: CUSTOM_NODE_NAMES.bidTask,

  group: 'block',

  content: 'block+',

  isolating: true,

  selectable: false,

  addAttributes(): BidTaskNodeAttributesConfig {
    return DEFAULT_BID_TASK_NODE_ATTRIBUTES;
  },

  parseHTML() {
    return [
      {
        tag: 'bid-task'
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['bid-task', mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(CanvasBidTask);
  },

  addKeyboardShortcuts() {
    return {
      'Mod-a': ({ editor }) => {
        const nodPos = getBidTaskNodePosition(editor);
        if (!nodPos) return false;

        const { state } = editor;

        const node = state.doc.nodeAt(nodPos.from);
        if (!node || node.type.name !== CUSTOM_NODE_NAMES.bidTask) return false;

        const isFirstChildList = ['orderedList', 'bulletList', 'taskList'].includes(node.firstChild?.type.name ?? '');

        const fromOffset = isFirstChildList ? 4 : 2;
        const toOffset = node.textContent ? 1 : 0;

        // Find the deepest child node (to account for nested lists)
        let lastPos = nodPos.to;
        node.descendants((child, pos) => {
          lastPos = nodPos.from + pos + child.nodeSize;
        });

        return editor
          .chain()
          .setTextSelection({ from: nodPos.from + fromOffset, to: lastPos + toOffset })
          .focus()
          .run();
      },
      Tab: ({ editor }) => {
        editor
          .chain()
          .sinkListItem('listItem')
          .command(({ tr }) => {
            tr.insertText(' ');

            return true;
          })
          .run();

        return true; // <- return true to prevent the tab from blurring editor.
      },
      Backspace: ({ editor }) => {
        const { state } = editor;
        const { selection } = state;
        const { from, to } = selection;

        const nodePos = getBidTaskNodePosition(editor);
        if (!nodePos) return false; // No bidTask node found

        const node = state.doc.nodeAt(nodePos.from);
        if (!node || node.type.name !== CUSTOM_NODE_NAMES.bidTask) return false;

        const isFirstChildList = ['orderedList', 'bulletList', 'taskList'].includes(node.firstChild?.type.name ?? '');

        // If the first child is not a list, use default behavior (we don't need to account for nested lists)
        if (!isFirstChildList) return false;

        // Find the deepest child node (to account for nested lists)
        let lastPos = nodePos.to;
        node.descendants((child, pos) => {
          lastPos = nodePos.from + pos + child.nodeSize;
        });

        const toSelection = lastPos + 1;
        const fromSelection = nodePos.from + 4;

        const isFullSelection = from === fromSelection && to === toSelection;

        if (isFullSelection) {
          editor.commands.deleteRange({ from: fromSelection, to: toSelection });
          return true; // Prevent default backspace behavior
        }

        return false;
      }
    };
  },

  // Handle copy behavior (multiline copy does not work natively in custom nodes)
  addProseMirrorPlugins() {
    return [
      new Plugin({
        props: {
          handleDOMEvents: {
            copy: (view, event) => {
              const { state } = view;
              const { selection } = state;

              if (selection.empty) return false;

              const slice = state.doc.slice(selection.from, selection.to);
              const serializer = DOMSerializer.fromSchema(state.schema);

              // Convert ProseMirror document fragment to HTML
              const container = document.createElement('div');
              slice.content.forEach(node => {
                container.appendChild(serializer.serializeNode(node));
              });

              const html = container.innerHTML;

              // Set clipboard data
              event.clipboardData?.setData('text/html', html);
              event.preventDefault(); // Prevent default copy behavior

              return true;
            }
          }
        }
      })
    ];
  }
});
