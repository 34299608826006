import React, { FC, useCallback, useMemo } from 'react';
import styles from './index.module.scss';
import { DatePicker, Form, Button } from 'src/common';
import { IBoxFieldDate } from 'src/models/procurements/Tender/types';
import dayjs, { Dayjs } from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';
import { IBlockEditProps } from 'src/shared/Tender/Boxes/types';
import datePickerStyles from 'src/common/DatePicker/index.module.scss';
import classNames from 'classnames';

interface IFormData {
  date: Dayjs;
}

interface Props extends IBlockEditProps<IBoxFieldDate> {
  onlyPicker: boolean;
}

export const EditableBlockDate: FC<Props> = ({ field, onChange, containerRef, onlyPicker, config, className }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { id: fieldId, date } = field;

  const onChangeValues = useCallback(() => {
    const value: IFormData = form.getFieldValue(fieldId);

    onChange && onChange(field.update(value.date.toDate().valueOf()));
  }, [field, fieldId, form, onChange]);

  const initialValues = useMemo(() => {
    return {
      [fieldId]: {
        date: !!date ? dayjs(date) : dayjs.utc().add(1, 'day').hour(0).minute(0).second(0).millisecond(0)
      }
    };
  }, [date, fieldId]);

  const onDeleteField = useCallback(() => {
    onChange && onChange(field.update(null, null));
  }, [field, onChange]);

  return (
    <div className={className} ref={containerRef}>
      <Form form={form} onValuesChange={onChangeValues} initialValues={initialValues} className={styles.container}>
        <Form.Item name={[fieldId, 'date']} label={''} className={styles.field}>
          <DatePicker
            showTime={
              !!config?.date?.showTime
                ? {
                    format: 'HH:mm'
                  }
                : false
            }
            minuteStep={10}
            className={styles.datePicker}
            bordered={false}
            open={onlyPicker}
            getPopupContainer={node => node.parentNode as HTMLElement}
            dropdownClassName={classNames(
              datePickerStyles.datePickerDropDown,
              datePickerStyles.datePickerDropDownFooterExtra
            )}
            renderExtraFooter={() => (
              <Button
                type={'text'}
                icon={<FontAwesomeIcon icon={faTimes} />}
                onClick={onDeleteField}
                className={datePickerStyles.deleteButton}
              >
                {t('Common.Blocks.deleteDate')}
              </Button>
            )}
            // dropdownAlign={{ points: ['br', 'tr']}}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default EditableBlockDate;
