import { Editor } from '@tiptap/core';
import { Node } from '@tiptap/pm/model';

export const findNodeById = (editor: Editor, nodeId: string): { node: Node | null; pos: number | null } => {
  let foundNode: Node | null = null;
  let foundPos: number | null = null;

  editor.state.doc.descendants((node, pos) => {
    if (node.attrs?.id === nodeId) {
      foundNode = node;
      foundPos = pos;
      return false; // Stop iteration early once found
    }
    return true;
  });

  return { node: foundNode, pos: foundPos };
};

export default findNodeById;
