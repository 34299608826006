import React, { FC, useState } from 'react';
import { QuickActions, QuickActionsItem } from 'src/shared/QuickActions';
import { useTranslation } from 'react-i18next';
import ManageTemplatesModal from '../ManageTemplatesModal';
import SaveAsTemplateModal from '../SaveAsTemplateModal';
import { DecisionMatrixModals } from 'src/models/decisionMatrix/types';

const DecisionMatrixQuickActions: FC = () => {
  const { t } = useTranslation();

  const [modalMode, setModalMode] = useState<DecisionMatrixModals | null>(null);

  return (
    <>
      <QuickActions placement={'bottomRight'}>
        <QuickActionsItem
          key={'saveAsTemplate'}
          onClick={() => setModalMode(DecisionMatrixModals.SaveAsTemplate)}
          title={t('DecisionMatrix.saveAsTemplate')}
        />
        <QuickActionsItem
          key={'manageTemplates'}
          onClick={() => setModalMode(DecisionMatrixModals.ManageTemplates)}
          title={t('DecisionMatrix.manageTemplates')}
        />
      </QuickActions>
      {modalMode === DecisionMatrixModals.SaveAsTemplate && <SaveAsTemplateModal onClose={() => setModalMode(null)} />}
      {modalMode === DecisionMatrixModals.ManageTemplates && (
        <ManageTemplatesModal onClose={() => setModalMode(null)} />
      )}
    </>
  );
};

export default DecisionMatrixQuickActions;
