import React, { FC, useMemo } from 'react';
import { TenderBlockSize, TenderSubCatName } from 'src/models/procurements/Tender/types';
import { useCompetitors } from 'src/models/company/Competitors/hooks';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import styles from './index.module.scss';
import ReadOnlyBlockCurrency from 'src/shared/Blocks/Currency/ReadOnlyBlock';
import { InfoBox } from '../Tender/Boxes';
import { BlockEmpty, BlockTitle } from '../Blocks';
import SupplierLink from '../SupplierLink';
import { Translations } from 'src/lib/I18nService/types';
import { faUsdCircle } from '@fortawesome/pro-light-svg-icons';

interface Props {
  orgId?: string;
}
export const Competitors: FC<Props> = ({ orgId }) => {
  const { t } = useTranslation();
  const { t: tenderT } = useTranslation(Translations.procurements);

  const { data: competitors } = useCompetitors(orgId);
  const competitorsLength = competitors.length;
  const noPayments = useMemo(
    () => competitors.filter(org => org.sumValue === 0).length === competitorsLength,
    [competitors, competitorsLength]
  );
  const noData = useMemo(
    () => competitors.filter(org => org.sumValue === null).length === competitorsLength,
    [competitors, competitorsLength]
  );

  if (!competitorsLength) {
    return null;
  }

  return (
    <>
      <BlockTitle
        title={tenderT(TenderSubCatName.Competitors)}
        icon={{ defaultIcon: faUsdCircle, color: 'var(--ui-sunset)' }}
        className={styles.blockTitle}
        noBorder
      />
      {competitors.map(({ supplierOrgId, supplierOrgName, sumValue, sumCurrency, color }) => (
        <InfoBox
          key={supplierOrgId}
          size={TenderBlockSize.Third}
          title={
            <>
              <span className={styles.orgColor} style={{ backgroundColor: color }}></span>
              <SupplierLink orgId={supplierOrgId} orgName={supplierOrgName}>
                {supplierOrgName}
              </SupplierLink>
            </>
          }
          value={
            sumValue && sumCurrency ? (
              <ReadOnlyBlockCurrency amount={sumValue} currency={sumCurrency} />
            ) : (
              <BlockEmpty />
            )
          }
          className={classNames(styles.org, { [styles.orgNoInfo]: noPayments || noData })}
        />
      ))}
      {noPayments && <p className={styles.desc}>{t('Competitors.competitorsNoPayments')}</p>}
      {noData && <p className={styles.desc}>{t('Competitors.competitorsNoData')}</p>}
    </>
  );
};

export default Competitors;
