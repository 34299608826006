import React, { FC, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { PlainDropDown } from 'src/common';
import { useBulkUpdateContentLibrary } from 'src/models/contentLibrary/ContentLibrary/hooks';
import { useRoomsWithAccess } from 'src/models/contentLibrary/ContentLibraryRoom/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { toHSLColor } from 'src/helpers/styles';

interface Props {
  ids: string[];
  roomId?: string | null;
  onUpdateComplete: () => void;
}

export const ContentLibraryBulkRoomUpdater: FC<Props> = ({ ids, roomId, onUpdateComplete }) => {
  const { t } = useTranslation();
  const roomData = useRoomsWithAccess();
  const [updateContentLibrary] = useBulkUpdateContentLibrary();

  const values = useMemo(() => {
    return roomData
      ? [
          ...(roomData?.map(room => {
            return {
              label: (
                <span>
                  <FontAwesomeIcon className={styles.labelIcon} icon={faCircle} color={toHSLColor(room.id)} />
                  {` ${room.title}`}
                </span>
              ),
              value: room.id
            };
          }) ?? [])
        ]
      : [];
  }, [roomData]);

  const onChangeResponse = useCallback(
    (roomId: string | null) => {
      updateContentLibrary({ ids, roomId });
      onUpdateComplete();
    },
    [updateContentLibrary, ids, onUpdateComplete]
  );

  return (
    <PlainDropDown
      values={values}
      selectedValue={roomId ?? undefined}
      label={t('Common.notSet')}
      onSelect={onChangeResponse}
      hideLabel
      placeholder={t('ContentLibrary.room')}
      type="single"
      className={styles.placeholder}
    />
  );
};

export default ContentLibraryBulkRoomUpdater;
