import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RcFile } from 'antd/lib/upload';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, InfoIcon, Modal, notification } from 'src/common';
import { useApp } from 'src/models/auth';
import {
  updateCreateCacheOnFileUploadContentLibrary,
  useContentLibraryNav
} from 'src/models/contentLibrary/ContentLibrary/hooks';
import DndUploadArea from 'src/shared/DndUploadArea';
import FileItem from './FileItem';
import styles from './index.module.scss';
import { FileExt } from 'src/models/file/types';
import { ContentLibraryEventSource } from 'src/models/contentLibrary/ContentLibrary/types';
import { trackAddFileCL } from 'src/segment/events';
import { ContentLibraryType } from '@tendium/prom-types/schema';
import RoomSelect from 'src/shared/RoomSelect';

interface Props {
  extensions: FileExt[];
  onUpload: (queryVariable: { targetPath: string; roomId: string }) => Promise<
    | {
        signedUrl: string;
        contentRowId?: string;
      }
    | undefined
  >;
  onClose: () => void;
  desc?: string;
}

export const UploadFile: FC<Props> = props => {
  const { extensions, onUpload, onClose, desc } = props;

  const { user } = useApp();
  const { t } = useTranslation();
  const { isRoomPage, isTaskPage } = useContentLibraryNav();
  const eventSource = useMemo(() => {
    return isRoomPage
      ? ContentLibraryEventSource.room
      : isTaskPage
      ? ContentLibraryEventSource.taskPage
      : ContentLibraryEventSource.contentLibrary;
  }, [isRoomPage, isTaskPage]);

  const [form] = Form.useForm();

  const [fileList, setFileList] = useState<RcFile[]>([]);
  const [uploading, setUploading] = useState(false);

  const onDelete = useCallback((fileToDelete: string) => {
    setFileList(prevFileList => prevFileList.filter(file => file.name !== fileToDelete));
  }, []);

  const beforeUpload = useCallback(
    (file: RcFile, files: RcFile[]): void => {
      const isFileAlreadyAdded = fileList.some(existingFile => existingFile.name === file.name);
      const MAX_NUMBER_OF_FILES = 100;

      if (isFileAlreadyAdded) {
        notification.error({
          description: t('ContentLibrary.contentUploadModals.fileAlreadyAddedError'),
          message: t('ErrorBoundary.title'),
          key: 'fileAlreadyAddedError'
        });
      }

      if (fileList.length + files.length > MAX_NUMBER_OF_FILES) {
        notification.error({
          description: t('ContentLibrary.contentUploadModals.maxFilesError', { count: MAX_NUMBER_OF_FILES }),
          message: t('ErrorBoundary.title'),
          key: 'maxFilesError'
        });
      }

      setFileList(prev => [...prev, file]);
    },
    [fileList, t]
  );

  const onFinish = useCallback(async (): Promise<void> => {
    const roomId = form.getFieldValue('roomId');
    if (!roomId) {
      form.validateFields();
      return;
    }

    setUploading(true);
    onClose();

    const failedFiles: string[] = [];

    for (const file of fileList) {
      try {
        const queryVariable = {
          targetPath: file.name.normalize(),
          roomId: roomId
        };
        const response = await onUpload(queryVariable);

        const fetchResponse = await fetch(response?.signedUrl as RequestInfo, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/octet-stream'
          },
          body: file
        });

        if (fetchResponse.ok && user) {
          if (response?.contentRowId) {
            updateCreateCacheOnFileUploadContentLibrary(response.contentRowId, roomId, user, file.name);
            trackAddFileCL(eventSource, ['Room', ContentLibraryType.DOCUMENT]);
          } else {
            trackAddFileCL(eventSource, ['Room', ContentLibraryType.QA]);
          }

          notification.open({
            icon: <FontAwesomeIcon icon={faSpinnerThird} spin={true} className={styles.spinner} />,
            description: t('ContentLibrary.contentUploadModals.importProgressMessage'),
            message: t('ContentLibrary.contentUploadModals.importProgressTitle'),
            key: 'contentUploading'
          });
        } else {
          failedFiles.push(file.name);
        }
      } catch (err) {
        failedFiles.push(file.name);
      }
    }

    if (failedFiles.length > 0) {
      notification.error({
        description: `${t('Common.unknownErrorDesc')} ${failedFiles.join(', ')}`,
        message: t('ErrorBoundary.title'),
        key: 'contentUploadingError'
      });
    }

    setUploading(false);
  }, [fileList, onUpload, user, form, t, onClose, setUploading, eventSource]);

  return (
    <Modal
      title={fileList.length === 0 ? t('BidDrafts.chooseFile') : t('ContentLibrary.chooseRoom')}
      visible
      onCancel={onClose}
      footer={
        fileList.length > 0 ? (
          <>
            <Button type="text" onClick={onClose}>
              {t('Common.cancel')}
            </Button>
            <Button onClick={onFinish} type={'primary'} disabled={uploading}>
              {t('Common.import')}
            </Button>
          </>
        ) : null
      }
    >
      <div className={styles.uploadingFilesContainer}>
        {fileList.map(file => (
          <div key={file.uid}>
            <FileItem extensions={extensions} fileName={file.name} onDelete={onDelete} />
          </div>
        ))}
      </div>

      {fileList.length === 0 ? (
        <DndUploadArea
          onUploadFile={beforeUpload}
          title={t('ContentLibrary.contentUploadModals.title')}
          desc={desc}
          fileList={fileList}
          multiple={true}
          showRemoveIcon={true}
        />
      ) : (
        <Form layout="horizontal" form={form}>
          <div className={styles.roomContainer}>
            <DndUploadArea
              onUploadFile={beforeUpload}
              title={t('ContentLibrary.contentUploadModals.title')}
              desc={desc}
              multiple={true}
            />
            <Form.Item
              name={'roomId'}
              label={t('ContentLibrary.chooseRoom')}
              className={styles.roomError}
              rules={[{ required: true, message: t('ContentLibrary.contentUploadModals.selectRoom') }]}
            >
              <div className={styles.infoWrapper}>
                <InfoIcon desc={t('ContentLibrary.contentUploadModals.roomTooltip')} />
                <RoomSelect form={form} placeholder={t('ContentLibrary.contentUploadModals.roomNotSelected')} />
              </div>
            </Form.Item>
          </div>
        </Form>
      )}
    </Modal>
  );
};
export default UploadFile;
