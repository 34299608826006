import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Letter } from 'react-letter';
import { useBidPreview } from 'src/models/bids/BidPreview/hooks';
import BidPreviewAttachments from 'src/shared/BidFiles/Attachments';
import styles from './index.module.scss';
import Accordion from 'src/common/Accordion';
import { formatDate } from 'src/helpers/dates';
import classNames from 'classnames';
import { IInboxEmail } from 'src/models/callOffs/types';

export const BidPreviewEmail: FC = () => {
  const { data } = useBidPreview();
  const mails = data?.mails;
  const procurementId = data?.origin.id;
  const { t } = useTranslation();

  const [activeEmail, setActiveEmail] = useState<IInboxEmail | undefined>();

  return mails ? (
    <div className={styles.emailsContainer}>
      {mails.map(mail => (
        <Accordion
          key={mail.mailId}
          label={
            <div
              className={classNames(styles.emailHeader, {
                [styles.active]: activeEmail && activeEmail.mailId === mail.mailId
              })}
            >
              <div>{formatDate(mail.date)}</div>
              <div>{mail.subject || `(${t('CallOffs.noSubject')})`}</div>
            </div>
          }
          className={styles.accordion}
          onToggleComplete={() => setActiveEmail(prev => (prev === mail ? undefined : mail))}
          value={activeEmail?.mailId === mail.mailId}
        >
          {mail.attachments && mail.attachments.length > 0 && (
            <BidPreviewAttachments attachments={mail.attachments} zip={mail.zip} procurementId={procurementId} />
          )}
          <Letter className={styles.letter} html={mail.htmlContent || mail.content || '—'} />
        </Accordion>
      ))}
    </div>
  ) : null;
};

export default BidPreviewEmail;
