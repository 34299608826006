import { useState, useCallback, useMemo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import styles from './index.module.scss';
import React from 'react';
import { Dropdown } from 'antd';
import { BidTask } from 'src/models/bids/BidTask';
import { RequirementType } from 'src/models/bids/BidTask/types';
import { TaskTrackPickerData, trackTaskUpdate } from 'src/segment/events';
import { useUpdateBidTasks } from 'src/models/bids/BidTask/hooks';
import DropdownOverlay from 'src/common/PlainDropDown/DropdownOverlay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';

interface Props {
  questions: BidTask[];
  groupId: string;
  taskValue?: RequirementType | null;
  className?: string;
  disabled?: boolean;
  trackData?: TaskTrackPickerData;
  setActionsActive?: (active: boolean) => void;
}

export const TaskRequirementPicker: FC<Props> = ({
  questions,
  groupId,
  taskValue,
  className,
  disabled,
  trackData,
  setActionsActive
}) => {
  const { t } = useTranslation();
  const [updateTasks] = useUpdateBidTasks();
  const [menuVisible, setMenuVisible] = useState(false);

  const onVisibleChange = useCallback(() => {
    setMenuVisible(prevState => !prevState);
    setActionsActive && setActionsActive(!menuVisible);
  }, [menuVisible, setActionsActive]);

  const values = useMemo(
    () =>
      Object.values(RequirementType).map(type => {
        const label = Object.keys(RequirementType).find(
          key => RequirementType[key as keyof typeof RequirementType] === type
        );
        return {
          value: type,
          label: t(`BiddingTool.RequirementType.${label}`)
        };
      }),
    [t]
  );

  const onChangeResponse = useCallback(
    (requirementType: RequirementType | 'null') => {
      if (trackData) {
        trackTaskUpdate({ ...trackData, taskProp: 'task requirement', value: requirementType });
      }
      updateTasks({
        questions: questions.filter(item => item.requirementType !== requirementType),
        groupId,
        requirementType: requirementType === 'null' ? null : requirementType
      });
      setMenuVisible(false);
      setActionsActive && setActionsActive(false);
    },
    [groupId, questions, setActionsActive, trackData, updateTasks]
  );

  return (
    <Dropdown
      overlay={<DropdownOverlay values={values} selectedValue={taskValue ?? undefined} onSelect={onChangeResponse} />}
      trigger={['click']}
      onVisibleChange={onVisibleChange}
      visible={menuVisible}
      disabled={disabled}
      placement={'bottomRight'}
      getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
    >
      <button className={classNames(styles.picker, styles.button, { [styles.notSet]: taskValue === null }, className)}>
        <span className={styles.buttonLabel}>
          {taskValue ? t(`BiddingTool.RequirementType.${taskValue}`) : t('Common.notSet')}
        </span>
        <FontAwesomeIcon
          icon={faCaretDown}
          className={classNames(styles.buttonIcon, { [styles.isActive]: menuVisible })}
        />
      </button>
    </Dropdown>
  );
};

export default TaskRequirementPicker;
