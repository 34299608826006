export function getMentionedNames(contents: readonly string[]): Set<string> {
  return contents.reduce((previousValue, currentValue) => {
    const el = document.createElement('html');
    el.innerHTML = currentValue;
    const records = el.getElementsByTagName('span');
    for (const record of records) {
      const res = record.getAttribute('data-id');
      res && previousValue.add(res);
    }
    return previousValue;
  }, new Set<string>());
}
