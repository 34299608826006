import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PlainDropDown } from 'src/common';
import { useContentLibrary } from 'src/models/contentLibrary/ContentLibrary/hooks';
import styles from './index.module.scss';
import { faArrowDownWideShort, faArrowUpWideShort } from '@fortawesome/pro-regular-svg-icons';
import { ContentLibrarySortFields, ContentLibrarySortOrder } from '@tendium/prom-types/schema';
import { trackSortByCL } from 'src/segment/events';
import { ContentLibraryEventSource } from 'src/models/contentLibrary/ContentLibrary/types';

export interface Props {
  eventSource: ContentLibraryEventSource;
}

export const ContentLibraryFilterSort: FC<Props> = props => {
  const { eventSource } = props;
  const { t } = useTranslation();
  const { data: state } = useContentLibrary();

  const currentVars = useMemo(
    () =>
      state?.currentVars.sort ?? {
        key: ContentLibrarySortFields.modified_at,
        sort: ContentLibrarySortOrder.DESCENDING
      },
    [state]
  );
  const updateVars = state?.updateVars;
  const values = useMemo(
    () =>
      Object.values(ContentLibrarySortFields).map(value => {
        return {
          value: value,
          label: value ? t(`ContentLibrary.Sort.${value}`) : undefined
        };
      }),
    [t]
  );

  const onChangeSort = useCallback(
    (field: ContentLibrarySortFields) => {
      updateVars?.({
        sort: {
          key: field,
          sort: currentVars.sort ?? ContentLibrarySortOrder.ASCENDING
        }
      });
      trackSortByCL(eventSource, currentVars.sort);
    },
    [currentVars.sort, eventSource, updateVars]
  );
  const isAscending = useMemo(() => {
    return currentVars?.sort === ContentLibrarySortOrder.ASCENDING;
  }, [currentVars]);

  const onChangeSortDirection = useCallback(() => {
    if (currentVars.key) {
      updateVars?.({
        sort: {
          key: currentVars.key,
          sort:
            currentVars.sort === ContentLibrarySortOrder.ASCENDING
              ? ContentLibrarySortOrder.DESCENDING
              : ContentLibrarySortOrder.ASCENDING
        }
      });
    }
  }, [currentVars.key, currentVars.sort, updateVars]);

  return (
    <div className={styles.container}>
      <PlainDropDown
        values={values}
        selectedValue={currentVars.key}
        label={t('Common.sortBy')}
        onSelect={onChangeSort}
        type="single"
      />
      <button className={styles.button} onClick={onChangeSortDirection}>
        <FontAwesomeIcon icon={isAscending ? faArrowDownWideShort : faArrowUpWideShort} className={styles.icon} />
      </button>
    </div>
  );
};

export default ContentLibraryFilterSort;
