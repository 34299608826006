import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useApiBiddingStats } from 'src/models/bids/BidFull/hooks';
import { Button, Skeleton } from 'src/common';
import { ApiBiddingStats, BiddingToolTab } from 'src/models/bids/BidFull/types';
import styles from './index.module.scss';
import { StatusBarGroupItem } from 'src/shared';
import { useBidUrl, useOverviewBackLink } from 'src/models/procurements/hooks';
import { useNavigate } from 'react-router-dom';
import NavCard from 'src/common/NavCard';
import { getCanvasURL } from 'src/models/canvas/helpers';
import { trackBTOpenEditor } from 'src/segment/events';

interface Props {
  bidId: string;
}
export const TaskGroupsStats: FC<Props> = props => {
  const { bidId } = props;
  const { t } = useTranslation();

  const { data: statsApiData, loading: statsLoading } = useApiBiddingStats(bidId);
  const statsData = statsApiData?.getQuestionGroupsStatistics.filter(group => group.totalTasks > 0);

  const navigate = useNavigate();

  const canvasUrl = getCanvasURL(bidId ?? '', !!statsData?.length ? statsData[0].groupId : '');
  const bidHref = useBidUrl(bidId || '');
  const { set: setBackLink } = useOverviewBackLink();

  const onViewTasks = useCallback(() => {
    setBackLink();
    navigate(`${bidHref}#${BiddingToolTab.Tasks}`);
  }, [bidHref, navigate, setBackLink]);

  const onOpenEditor = useCallback(() => {
    trackBTOpenEditor({ eventSource: 'Bid Preview' });
    setBackLink();
    navigate(canvasUrl);
  }, [canvasUrl, navigate, setBackLink]);

  const getFilteredStatuses = (stats: ApiBiddingStats): { backgroundColor: string; count: number }[] => {
    return [
      { backgroundColor: 'var(--ui-green-medium)', count: stats.done },
      { backgroundColor: 'var(--brand-so)', count: stats.review },
      { backgroundColor: 'var(--ui-gold-medium)', count: stats.inProgress },
      { backgroundColor: 'var(--grey-fa)', count: stats.todo }
    ];
  };

  return statsLoading ? (
    <Skeleton />
  ) : !!statsData?.length ? (
    <div>
      {statsData?.map(group => {
        const percentageDone = (group.done / group.totalTasks) * 100;

        return (
          <StatusBarGroupItem
            key={group.groupId}
            title={group.title}
            stacks={getFilteredStatuses(group)}
            totalCount={group.totalTasks}
            stackLabel={<div className={styles.label}>{`${Math.round(percentageDone)}% ${t('Common.done')}`}</div>}
          />
        );
      })}
      <div className={styles.buttonContainer}>
        <Button type={'primary'} onClick={onViewTasks} disabled={!statsApiData?.getQuestionGroupsStatistics.length}>
          {t('BiddingTool.viewAllTasks')}
        </Button>
        <Button type={'default'} onClick={onOpenEditor}>
          {t('BiddingTool.openEditor')}
        </Button>
      </div>
    </div>
  ) : (
    <NavCard
      title={t('BiddingTool.startCreatingTasks')}
      id={bidId}
      onClick={onViewTasks}
      cardInfo={<div className={styles.onboarding}>{t('BiddingTool.startCreatingTasksDesc')}</div>}
    />
  );
};

export default TaskGroupsStats;
