import React, { FC, useCallback, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-light-svg-icons';
import { IBoxFieldBoolean } from 'src/models/procurements/Tender/types';
// root styles
// no own styles, but here is scss file wtf ??
// styles from Boolean

import styles from './index.module.scss';
import { useClickAway, useKey } from 'react-use';
import EditableBlockBoolean from './EditableBlock';
import ReadOnlyBlockBoolean from './ReadOnlyBlock';
import classNames from 'classnames';
import AddValueButton from '../AddValueButton';
import { IBlockProps, FieldViewMode } from 'src/shared/Tender/Boxes/types';
import { useTranslation } from 'react-i18next';

interface Props extends IBlockProps<IBoxFieldBoolean> {}
export const BlockBoolean: FC<Props> = ({ field, onChange, viewMode, isEditable, config }) => {
  const { t } = useTranslation();

  const [currentMode, setCurrentMode] = useState(viewMode || FieldViewMode.Read);
  const ref = useRef<HTMLDivElement>(null);

  const onToggleView = useCallback(() => {
    isEditable && setCurrentMode(prev => (prev === FieldViewMode.Read ? FieldViewMode.Edit : FieldViewMode.Read));
  }, [isEditable]);

  const switchToReadMode = useCallback(() => {
    if (isEditable && currentMode === FieldViewMode.Edit) {
      setCurrentMode(FieldViewMode.Read);
    }
  }, [currentMode, isEditable]);

  useKey('Escape', () => setCurrentMode(FieldViewMode.Read));

  useClickAway(ref, e => {
    e.stopPropagation();
    e.preventDefault();
    switchToReadMode();
  });

  const onFinish = useCallback(
    (val: IBoxFieldBoolean) => {
      onChange?.(val);
      switchToReadMode();
    },
    [onChange, switchToReadMode]
  );

  return (
    <>
      {currentMode === FieldViewMode.Edit && (
        <EditableBlockBoolean containerRef={ref} field={field} onChange={onFinish} onFinish={switchToReadMode} />
      )}

      {currentMode === FieldViewMode.Read && (
        <>
          {field.boolean !== undefined && field.boolean !== null ? (
            <div className={classNames(styles.toggler, { [styles.isEditable]: isEditable })} onClick={onToggleView}>
              <ReadOnlyBlockBoolean boolean={field.boolean} config={config} />
              {isEditable && (
                <div className={styles.togglerIcon}>
                  <FontAwesomeIcon icon={faPen} />
                </div>
              )}
            </div>
          ) : (
            <AddValueButton onToggleView={onToggleView} isEditable={!!isEditable} title={t('Common.Blocks.addValue')} />
          )}
        </>
      )}
    </>
  );
};

export default BlockBoolean;
