import React, { FC, useCallback, useRef, useState } from 'react';
import AssignTo, { IAssignToProps } from 'src/shared/AssignTo';
import { useUpdateBidTasks } from 'src/models/bids/BidTask/hooks';
import { useWsUsersAndTeams } from 'src/models/workspace/hooks';
import { trackAssignTo, TrackEventSource } from 'src/segment/events';
import { BidTask } from 'src/models/bids/BidTask';
import { AssignedTo } from 'src/models/users/types';
import { useClickAway } from 'react-use';

interface Props extends IAssignToProps {
  questions: BidTask[];
  groupId: string;
  wsId?: string;
  bidId: string;
  eventSource?: TrackEventSource;
  setActionsActive?: (actionActive: boolean) => void;
}

export const AssignToQuestion: FC<Props> = ({ size = 'm', mode = 'short', ...restProps }) => {
  const { questions, groupId, wsId, bidId, eventSource, setActionsActive } = restProps;

  const [updateTasks, { loading }] = useUpdateBidTasks();
  const [menuVisible, setMenuVisible] = useState(false);

  const wsUsers = useWsUsersAndTeams(wsId);
  const ref = useRef<HTMLDivElement>(null);

  const onClick = useCallback(() => {
    setMenuVisible(prev => !prev);
    setActionsActive && setActionsActive(!menuVisible);
  }, [menuVisible, setActionsActive]);

  const onClose = useCallback(() => {
    setMenuVisible(false);
    setActionsActive && setActionsActive(false);
  }, [setActionsActive]);

  const onUpdateTask = useCallback(
    (assignedTo: string | null) => {
      updateTasks({
        questions: questions.filter(item => item.assignedTo !== assignedTo),
        groupId,
        bidId,
        assignedTo
      });
      onClose();
    },
    [bidId, groupId, onClose, questions, updateTasks]
  );

  const onAssign = useCallback(
    (assignedTo: AssignedTo) => {
      if (eventSource) {
        trackAssignTo({ eventSource, assignedTo });
      }
      onUpdateTask(assignedTo.id);
      onClose();
    },
    [eventSource, onClose, onUpdateTask]
  );

  const onUnAssign = useCallback(() => {
    onUpdateTask(null);
    setMenuVisible(false);
  }, [onUpdateTask]);

  useClickAway(ref, () => {
    onClose();
  });

  return (
    <span onClick={onClick} ref={ref}>
      <AssignTo
        onAssign={onAssign}
        onUnAssign={onUnAssign}
        isUpdating={loading}
        size={size}
        mode={mode}
        users={wsUsers}
        {...restProps}
      />
    </span>
  );
};

export default AssignToQuestion;
