import { MutationTuple, useMutation, MutationUpdaterFn, QueryResult, useQuery } from '@apollo/client';
import { UPDATE_COMPANY_INFO, INIT_COMPANY_INFO, GET_MY_COMPANY } from './queries';
import { IApiCompetitor } from './Competitors/types';
import { IApiCompany } from './types';

interface IMyCompanyCompetitorsResponse {
  getCompany: { id: string; competitors: IApiCompetitor[] };
}
export function useCompanyCompetitors(): QueryResult<IMyCompanyCompetitorsResponse> {
  return useQuery(GET_MY_COMPANY);
}

interface IInitCompanyResponse {
  __typename: 'Mutation';
  updateUser: {
    company: {
      id: string;
      companyName: string;
      __typename: 'Company';
    };
  };
}
export function useInitCompanyInfo(): MutationTuple<IInitCompanyResponse, { companyName: string; orgNumber: string }> {
  return useMutation(INIT_COMPANY_INFO);
}

export interface IUpdateCompanyResponse {
  __typename: 'Mutation';
  updateCompanyInfo: IApiCompany & {
    __typename: 'Company';
  };
}
export function useUpdateCompanyInfo(): MutationTuple<
  IUpdateCompanyResponse,
  { id: string; companyName: string; orgNumber: string }
> {
  return useMutation(UPDATE_COMPANY_INFO);
}

export function getUpdateCacheOnUpdateCompany(companyId: string): MutationUpdaterFn<IUpdateCompanyResponse> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }
    const companyRef = cache.identify({
      __typename: 'Company',
      id: companyId
    });
    cache.modify({
      id: companyRef,
      fields: {
        companyName() {
          return data.updateCompanyInfo.companyName;
        }
      }
    });
  };
}
