import {
  LazyQueryResultTuple,
  MutationTuple,
  MutationUpdaterFn,
  OperationVariables,
  QueryResult,
  Reference,
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription
} from '@apollo/client';
import {
  CONVERT_PROMPT_BATTERY_QUESTION_TO_GLOBAL,
  COUNT_PROMPT_BATTERY_QUESTIONS_ASKED_THIS_YEAR,
  CREATE_PROMPT_BATTERY_LOCAL_QUESTION,
  CREATE_PROMPT_BATTERY_QUESTION,
  DELETE_PROMPT_BATTERY_QUESTION,
  GENERATE_PROMPT_BATTERY_ANSWERS,
  GET_PROMPT_BATTERY_ANSWER,
  GET_PROMPT_BATTERY_ANSWERS,
  GET_PROMPT_BATTERY_QUESTION,
  GET_PROMPT_BATTERY_QUESTIONS,
  ON_PROMPT_BATTERY_GENERATE_ANSWER,
  PROMPT_BATTERY_CHUNK_STATUS,
  PROMPT_BATTERY_GROUPED_DOCUMENT_POSITIONS,
  PROMPT_BATTERY_QUESTION_ANSWER_FIELDS,
  UPDATE_PROMPT_BATTERY_QUESTION
} from './queries';
import {
  ConvertPromptBatteryQuestionToGlobalInput,
  ConvertPromptBatteryQuestionToGlobalResponse,
  CreatePromptBatteryLocalQuestionInput,
  CreatePromptBatteryLocalQuestionResponse,
  CreatePromptBatteryQuestionResponse,
  CreateQuestionInput,
  DeleteQuestionInput,
  DeleteQuestionResponse,
  GenerateAnswersInput,
  GeneratePromptBatteryAnswersResponse,
  GetAnswerInput,
  GetAnswersInput,
  GetCountPromptBatteryQuestionsAskedResponse,
  GetGroupedDocumentPositionsInput,
  GetPromptBatteryAnswerResponse,
  GetPromptBatteryAnswersResponse,
  GetPromptBatteryChunkStatus,
  GetPromptBatteryGroupedDocumentPositionsResponse,
  GetPromptBatteryQuestionResponse,
  GetPromptBatteryQuestionsResponse,
  Question,
  QuestionAnswer,
  UpdatePromptBatteryQuestionResponse,
  UpdateQuestionInput
} from './types';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { cache } from 'src/lib/API/graphql/cache';
import { notification } from 'src/common';
import * as Sentry from '@sentry/react';
import { MarsAnswerSub } from '@tendium/prom-types/subscriptions';
import { FeatureFlag } from 'src/helpers';
import { useFeatureFlag } from 'src/helpers';

export function useMutationCreatePromptBatteryQuestion(): MutationTuple<
  CreatePromptBatteryQuestionResponse,
  CreateQuestionInput
> {
  return useMutation<CreatePromptBatteryQuestionResponse, CreateQuestionInput>(CREATE_PROMPT_BATTERY_QUESTION);
}

export function useMutationDeletePromptBatteryQuestion(): MutationTuple<DeleteQuestionResponse, DeleteQuestionInput> {
  return useMutation<DeleteQuestionResponse, DeleteQuestionInput>(DELETE_PROMPT_BATTERY_QUESTION);
}

export function useMutationPromptBatteryUpdateQuestion(): MutationTuple<
  UpdatePromptBatteryQuestionResponse,
  UpdateQuestionInput
> {
  return useMutation<UpdatePromptBatteryQuestionResponse, UpdateQuestionInput>(UPDATE_PROMPT_BATTERY_QUESTION);
}

export function usePromptBatteryQuestions(): QueryResult<GetPromptBatteryQuestionsResponse> {
  return useQuery<GetPromptBatteryQuestionsResponse>(GET_PROMPT_BATTERY_QUESTIONS);
}

export function usePromptBatteryQuestion(): QueryResult<GetPromptBatteryQuestionResponse | null> {
  return useQuery<GetPromptBatteryQuestionResponse | null>(GET_PROMPT_BATTERY_QUESTION);
}

export function useCountPromptBatteryQuestionsAskedThisYear(): QueryResult<GetCountPromptBatteryQuestionsAskedResponse> {
  return useQuery<GetCountPromptBatteryQuestionsAskedResponse>(COUNT_PROMPT_BATTERY_QUESTIONS_ASKED_THIS_YEAR);
}

export function usePromptBatteryChunkStatus(procurementId: string): QueryResult<GetPromptBatteryChunkStatus> {
  return useQuery<GetPromptBatteryChunkStatus>(PROMPT_BATTERY_CHUNK_STATUS, {
    variables: { procurementId }
  });
}

export function usePromptBatteryAnswers(
  procurementId: string
): QueryResult<GetPromptBatteryAnswersResponse, GetAnswersInput> {
  return useQuery<GetPromptBatteryAnswersResponse, GetAnswersInput>(GET_PROMPT_BATTERY_ANSWERS, {
    variables: { procurementId },
    skip: !procurementId,
    fetchPolicy: 'network-only'
  });
}

export function useMutationGeneratePromptBatteryAnswers(): MutationTuple<
  GeneratePromptBatteryAnswersResponse,
  GenerateAnswersInput
> {
  return useMutation<GeneratePromptBatteryAnswersResponse, GenerateAnswersInput>(GENERATE_PROMPT_BATTERY_ANSWERS);
}

export function useMutationCreatePromptBatteryLocalQuestion(): MutationTuple<
  CreatePromptBatteryLocalQuestionResponse,
  CreatePromptBatteryLocalQuestionInput
> {
  return useMutation<CreatePromptBatteryLocalQuestionResponse, CreatePromptBatteryLocalQuestionInput>(
    CREATE_PROMPT_BATTERY_LOCAL_QUESTION
  );
}

export function useMutationConvertPromptBatteryQuestionToGlobal(): MutationTuple<
  ConvertPromptBatteryQuestionToGlobalResponse,
  ConvertPromptBatteryQuestionToGlobalInput
> {
  return useMutation<ConvertPromptBatteryQuestionToGlobalResponse, ConvertPromptBatteryQuestionToGlobalInput>(
    CONVERT_PROMPT_BATTERY_QUESTION_TO_GLOBAL
  );
}

export function usePromptBatteryAnswer(): LazyQueryResultTuple<GetPromptBatteryAnswerResponse, GetAnswerInput> {
  return useLazyQuery<GetPromptBatteryAnswerResponse, GetAnswerInput>(GET_PROMPT_BATTERY_ANSWER);
}

export function usePromptBatteryGroupedDocumentPositions(): LazyQueryResultTuple<
  GetPromptBatteryGroupedDocumentPositionsResponse,
  GetGroupedDocumentPositionsInput
> {
  return useLazyQuery<GetPromptBatteryGroupedDocumentPositionsResponse, GetGroupedDocumentPositionsInput>(
    PROMPT_BATTERY_GROUPED_DOCUMENT_POSITIONS
  );
}

export function useDeletePromptBatteryQuestion(): [
  (id: string, questionAnswerId?: string) => void,
  { loading: boolean; error: Error | undefined; data: boolean }
] {
  const { t } = useTranslation();

  const [deletePromptBatteryQuestion, { loading, error, data }] = useMutationDeletePromptBatteryQuestion();

  const deleteFn = useCallback(
    (id: string, questionAnswerId?: string) => {
      deletePromptBatteryQuestion({
        variables: {
          id
        },
        update: getUpdateCacheOnDeletePromptBatteryQuestion(id, questionAnswerId)
      }).catch(() => {
        notification.error({
          description: t('Common.unknownErrorDesc'),
          message: t('Common.unknownError')
        });
      });
    },
    [t, deletePromptBatteryQuestion]
  );
  return [deleteFn, { loading, error, data: !!data?.deletePromptBatteryQuestion }];
}

export function getUpdateCacheOnDeletePromptBatteryQuestion(
  id: string,
  questionAnswerId?: string
): MutationUpdaterFn<DeleteQuestionResponse> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }
    if (questionAnswerId) {
      cache.modify({
        fields: {
          getPromptBatteryAnswers(existingAnswers: Reference[], { readField }) {
            return [...existingAnswers.filter(ref => readField('id', ref) !== questionAnswerId)];
          }
        }
      });
    }
    const questionsData = cache.readQuery<GetPromptBatteryQuestionsResponse>({
      query: GET_PROMPT_BATTERY_QUESTIONS
    });
    if (!questionsData) {
      return;
    }
    const { getPromptBatteryQuestions } = questionsData;
    const res: Question[] = getPromptBatteryQuestions.filter(field => field.id !== id);
    cache.writeQuery({
      query: GET_PROMPT_BATTERY_QUESTIONS,
      data: { getPromptBatteryQuestions: res }
    });
    cache.evict({
      id: 'ROOT_QUERY',
      fieldName: 'getPromptBatteryAnswers'
    });

    cache.gc();
  };
}

export function getUpdateCacheOnCreateQuestion(): MutationUpdaterFn<CreatePromptBatteryQuestionResponse> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }
    const questionsData = cache.readQuery<GetPromptBatteryQuestionsResponse>({
      query: GET_PROMPT_BATTERY_QUESTIONS
    });
    if (!questionsData) {
      return;
    }

    const { getPromptBatteryQuestions } = questionsData;
    cache.writeQuery({
      query: GET_PROMPT_BATTERY_QUESTIONS,
      data: { getPromptBatteryQuestions: [...getPromptBatteryQuestions, data.createPromptBatteryQuestion] }
    });

    cache.evict({
      id: 'ROOT_QUERY',
      fieldName: 'getPromptBatteryAnswers'
    });

    cache.evict({
      id: 'ROOT_QUERY',
      fieldName: 'generatePromptBatteryAnswers'
    });

    cache.gc();
  };
}

export function getUpdateCacheOnUpdatePromptBatteryQuestion(
  id: string
): MutationUpdaterFn<UpdatePromptBatteryQuestionResponse> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }
    const questionsData = cache.readQuery<GetPromptBatteryQuestionsResponse>({
      query: GET_PROMPT_BATTERY_QUESTIONS
    });
    if (!questionsData) {
      return;
    }
    const { getPromptBatteryQuestions } = questionsData;
    const updatedQuestions = getPromptBatteryQuestions.map(question =>
      question.id === id ? { ...question, ...data.updatePromptBatteryQuestion } : question
    );
    cache.writeQuery({
      query: GET_PROMPT_BATTERY_QUESTIONS,
      data: { getPromptBatteryQuestions: updatedQuestions }
    });
    cache.evict({
      id: 'ROOT_QUERY',
      fieldName: 'getPromptBatteryAnswers'
    });

    cache.gc();
  };
}

export function updateCacheGeneratePromptBatteryAnswers(): MutationUpdaterFn<GeneratePromptBatteryAnswersResponse> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }

    const generatedAnswers = data.generatePromptBatteryAnswers;

    cache.modify({
      fields: {
        getPromptBatteryAnswers(_, { toReference }) {
          return generatedAnswers.map(newAnswer => toReference({ __typename: 'QuestionAnswer', id: newAnswer.id }));
        }
      }
    });
  };
}

export function updateCacheCreateLocalQuestion(): MutationUpdaterFn<CreatePromptBatteryLocalQuestionResponse> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }

    const generatedProcurementSpecificAnswer = data.createPromptBatteryProcurementSpecificQuestionAnswer;

    cache.modify({
      fields: {
        getPromptBatteryAnswers(existingAnswers = [], { toReference }) {
          const newAnswerRef = toReference({ __typename: 'QuestionAnswer', id: generatedProcurementSpecificAnswer.id });

          return [newAnswerRef, ...existingAnswers];
        }
      }
    });
  };
}

export function updateCacheConvertQuestionToGlobal(
  questionId: string
): MutationUpdaterFn<ConvertPromptBatteryQuestionToGlobalResponse> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }

    cache.modify({
      fields: {
        getPromptBatteryQuestions(existingQuestions: Reference[], { toReference }) {
          const newQuestionRef = toReference({ __typename: 'Question', id: questionId });
          return [...existingQuestions, newQuestionRef];
        }
      }
    });
  };
}

export function usePromptBatteryGenerateAnswerSub(skip: boolean): void {
  const [getAnswer] = usePromptBatteryAnswer();
  const isPromptBatteryFeature = useFeatureFlag(FeatureFlag.PromptBattery);

  useSubscription<{ marsAnswer: MarsAnswerSub.Data }, OperationVariables>(ON_PROMPT_BATTERY_GENERATE_ANSWER, {
    onData: async ({ data: response }) => {
      if (!response?.data?.marsAnswer) {
        return;
      }

      const answerData = response.data.marsAnswer;

      if (answerData && answerData.questionAnswerId) {
        try {
          const ref = cache.identify({ __typename: 'QuestionAnswer', id: answerData.questionAnswerId });
          const questionAnswer = cache.readFragment({
            id: ref,
            fragment: PROMPT_BATTERY_QUESTION_ANSWER_FIELDS,
            fragmentName: 'promptBatteryQuestionAnswerFields'
          }) as QuestionAnswer;

          const answer = questionAnswer?.answer;

          cache.modify({
            id: ref,
            fields: {
              answer: () => {
                return {
                  ...answer,
                  answer:
                    answerData.answerData?.order === 0
                      ? answerData.answerData?.content
                      : answer.answer + (answerData.answerData?.content ?? ''),
                  status:
                    answerData.status === MarsAnswerSub.AnswerStatus.IN_PROGRESS
                      ? MarsAnswerSub.AnswerStatus.DONE
                      : answerData.status
                };
              }
            }
          });

          if (answerData.status === MarsAnswerSub.AnswerStatus.DONE) {
            const questionAnswer = await getAnswer({
              variables: { id: answerData.questionAnswerId },
              fetchPolicy: 'network-only'
            });

            // Why is this needed...? Doesn't work properly without it.
            cache.modify({
              id: ref,
              fields: {
                answer: () => {
                  return {
                    ...questionAnswer.data?.getPromptBatteryAnswer.answer
                  };
                }
              }
            });
          }
        } catch (error) {
          console.error('Error fetching content or writing to cache:', error);
          Sentry.captureException(error, { extra: { tag: 'subscription-fetch-or-cache-error' } });
        }
      }
    },
    onError: error => {
      console.error('Subscription error:', error);
      Sentry.captureException(error, { extra: { tag: 'subscription-error' } });
    },
    skip: !isPromptBatteryFeature || skip
  });
}
