import React, { FC } from 'react';
import { useQueryParams } from 'src/helpers';
import { FeatureFlag } from '@tendium/prom-types';
import { useFeatureFlag } from 'src/helpers';
import { useBidPreview } from 'src/models/bids/BidPreview/hooks';
import { useTenderFiles } from 'src/models/procurements/TenderFiles/hooks';
import { FileCategory, OverviewQp } from 'src/models/procurements/types';
import BidPreviewEmail from 'src/shared/BidFiles/Email';
import MyFiles from 'src/shared/MyFiles';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import Accordion from 'src/common/Accordion';
import { Skeleton } from 'src/common';
import { IProcurementFilesCategory } from 'src/models/procurements/Tender/types';
import { useBidding } from 'src/models/bids/BidFull/hooks';
import ProcurementFiles from 'src/pages/TenderPage/ProcurementFiles';

interface Props {
  eventSource: string;
}

export const BidFiles: FC<Props> = props => {
  const { eventSource } = props;
  const { t } = useTranslation();

  const { data: bidPreviewData, loading: bidPreviewDataLoading } = useBidPreview();
  const { data: bidData, loading: bidDataLoading } = useBidding();

  const originId = bidPreviewData ? bidPreviewData.origin.id : bidData ? bidData?.originId : '';
  const { data: tenderFiles, loading: tenderFilesLoading } = useTenderFiles(originId);

  const qp = useQueryParams();
  const bidId = qp[OverviewQp.PreviewId] as string;

  const isAwardDataFeatureAvailable = useFeatureFlag(FeatureFlag.AwardData);
  const isBidAttachmentsFeature = useFeatureFlag(FeatureFlag.BidAttachments);

  const fileCats: IProcurementFilesCategory = !isAwardDataFeatureAvailable
    ? ({
        ...tenderFiles?.filesCats,
        fileCategories:
          tenderFiles?.filesCats?.fileCategories.filter(cat => cat.name !== FileCategory.TenderEvaluationDocuments) ??
          []
      } as IProcurementFilesCategory)
    : tenderFiles?.filesCats ?? { fileCategories: [], filesAmount: 0 };

  if (bidPreviewDataLoading || tenderFilesLoading || bidDataLoading) {
    return <Skeleton />;
  }

  return !!bidId ? (
    <>
      <Accordion
        label={<div className={styles.heading}>{t('Toolbox.tenderDocuments')}</div>}
        className={styles.accordion}
      >
        {(bidPreviewData?.origin.id || bidData?.originId) && !!fileCats.fileCategories.length ? (
          <ProcurementFiles
            procurementFiles={fileCats}
            procurementName={bidPreviewData?.title ?? 'documents'}
            procurementId={originId}
            linkToDocsSource={bidPreviewData?.sourceUrl}
            eventSource={eventSource}
            showTitle={false}
          />
        ) : (
          <div className={styles.emptyState}>{t('Toolbox.tenderDocumentsEmpty')}</div>
        )}
      </Accordion>

      {!!bidPreviewData?.mails.length && (
        <Accordion
          label={<div className={styles.heading}>{t('Toolbox.emailAttachments')}</div>}
          className={styles.accordion}
        >
          <BidPreviewEmail />
        </Accordion>
      )}

      {isBidAttachmentsFeature && (
        <Accordion
          label={<div className={styles.heading}>{t('Toolbox.myFiles')}</div>}
          className={styles.accordion}
          initialValue
        >
          <MyFiles bidId={bidId} />
        </Accordion>
      )}
    </>
  ) : null;
};

export default BidFiles;
