import { ExportFileStatus } from './types';

/**
 * Call window.print() with custom options
 * @param options - The options for the print
 */
export function customPrint(options?: { fileName?: string }): void {
  const originalTitle = document.title;
  if (options?.fileName) {
    document.title = options.fileName;
  }
  window.print();
  if (options?.fileName) {
    document.title = originalTitle;
  }
}

/**
 * Print the exported PDF file
 * @param html - The HTML content of the exported PDF file
 * @param fileName - The name of the file to save as
 */
export function printExportedPdf(data: { html: string; fileName?: string }): void {
  // Create print container
  const printContainer = document.createElement('div');
  printContainer.classList.add('export-pdf-print');
  printContainer.innerHTML = data.html;

  // Create print styles
  const printStyles = document.createElement('style');

  printStyles.textContent = `
    @media print {
      @page {
        size: auto;
        margin: 20mm;
      }
    }
  `;

  // Add styles to header
  document.head.appendChild(printStyles);
  // Add container to body
  document.body.appendChild(printContainer);

  // Wait for all images to load before printing
  const images = printContainer.getElementsByTagName('img');
  const imagePromises = Array.from(images).map(img => {
    if (img.complete) return Promise.resolve();
    return new Promise((resolve, reject) => {
      img.onload = resolve;
      img.onerror = reject;
    });
  });

  Promise.all(imagePromises)
    .catch(error => {
      console.error('Error loading images:', error);
    })
    .finally(() => {
      // still try to print even if some images fail to load
      customPrint({ fileName: data.fileName });
      // Clean up - remove both the container and styles
      document.body.removeChild(printContainer);
      document.head.removeChild(printStyles);
    });
}

export function hasExportPending(exportStatus: ExportFileStatus): boolean {
  return Object.values(exportStatus).some(item => item.status === 'pending');
}

export function isOperationPending(exportStatus: ExportFileStatus, operationId: string): boolean {
  return Object.values(exportStatus).some(item => item.status === 'pending' && item.operationId === operationId);
}
