import classNames from 'classnames';
import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UserPicker } from 'src/common';
import styles from './index.module.scss';
import { useContentLibrary } from 'src/models/contentLibrary/ContentLibrary/hooks';
import { useActiveUsers } from 'src/models/users/hooks';
import { ContentLibraryTrackFilter, trackFilteringCL } from 'src/segment/events';
import { ContentLibraryEventSource } from 'src/models/contentLibrary/ContentLibrary/types';

export interface Props {
  eventSource: ContentLibraryEventSource;
}
const ContentLibraryFilterCreatedBy: FC<Props> = props => {
  const { eventSource } = props;
  const { t } = useTranslation();

  const users = useActiveUsers();
  const { data: state } = useContentLibrary();
  const currentVars = useMemo(() => state?.currentVars.filters ?? {}, [state]);
  const updateVars = state?.updateVars;

  const selectedCreatedByUsers = useMemo(
    () => (currentVars?.createdBy ? currentVars.createdBy : undefined),
    [currentVars]
  );

  const onChangeCreatedBy = useCallback(
    ({ id: createdBy }: { id: string }, checked?: boolean) => {
      const previousValue = selectedCreatedByUsers || [];
      const currentValue = checked ? [...previousValue, createdBy] : previousValue.filter(item => item !== createdBy);

      updateVars?.({ createdBy: currentValue });
      trackFilteringCL(eventSource, ContentLibraryTrackFilter.createdBy);
    },
    [eventSource, selectedCreatedByUsers, updateVars]
  );

  return (
    <div className={classNames(styles.filter, { [styles.isActive]: selectedCreatedByUsers?.length })}>
      <UserPicker
        label={t('ContentLibrary.createdBy')}
        users={users}
        onSelect={onChangeCreatedBy}
        selected={selectedCreatedByUsers}
        className={styles.label}
        userPickerType="multiple"
        renderMode={'button'}
      />
    </div>
  );
};

export default ContentLibraryFilterCreatedBy;
