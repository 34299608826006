import { IApiTenderFileCategory, IGenericCat, IGenericSubCat, ITenderBox, TenderSubCatName } from '../Tender/types';

// OcdsOrganisation === OcdsParty
export type OcdsParty = {
  name: string | null;
  id: string | null;
  identifier: OcdsIdentifier | null;
  address: OcdsAddress | null;
  details: OcdsDetails | null;
  contactPoint: OcdsContactPoint | null;
  roles: OcdsPartyRole[] | null;
};
export type OcdsIdentifier = {
  scheme: string | null;
  id: string | null;
  legalName: string | null;
  uri: string | null;
};
export type OcdsAddress = {
  streetAddress: string | null;
  locality: string | null;
  region: string | null;
  postalCode: string | null;
  countryName: string | null;
  nuts: string[] | null;
};
export type OcdsLocation = {
  description: string | null;
  uri: string | null;
};
export type OcdsContactPoint = {
  name: string | null;
  email: string | null;
  telephone: string | null;
  faxNumber: string | null;
  url: string | null;
};
export type OcdsDetails = {
  url: string | null;
  buyerProfile: string | null;
};
export type OcdsUnit = {
  scheme: string | null;
  id: string | null;
  name: string | null;
  value: OcdsValue | null;
  uri: string | null;
};
export type OcdsPeriod = {
  startDate: number | null;
  endDate: number | null;
  maxExtentDate: number | null;
  durationInDays: number | null;
};
export type OcdsItem = {
  id: string | null;
  description: string | null;
  quantity: number | null;
  unit: OcdsUnit | null;
  value: OcdsValue | null;
  minValue: OcdsValue | null;
  maxValue: OcdsValue | null;
  procurementMethod: OcdsTenderMethod | null;
  procurementMethodDetails: string | null;
  performanceNuts: string[] | null;
  deliveryLocation: OcdsLocation | null;
};
export type OcdsValue = {
  amount: number | null;
  currency: string | null; //TODO: switch to keyof const COUNTRY
};
export type OcdsRenewal = {
  description: string | null;
  maximumRenewals: number | null;
  period: OcdsPeriod | null;
};
export type OcdsFrameworkAgreement = {
  minimumParticipants: number | null;
  maximumParticipants: number | null;
  method: string | null;
};
export type OcdsTechniques = {
  hasFrameworkAgreement: boolean | null; //general.frameworkAgreement
  frameworkAgreement: OcdsFrameworkAgreement | null;
};
export enum OcdsPartyRole {
  Buyer = 'Buyer',
  LeadBuyer = 'LeadBuyer',
  Tenderer = 'Tenderer',
  Supplier = 'Supplier'
}
export interface ApiTenderFreeResponse {
  readonly getSimpleTender: ApiTenderFree;
}
export type ApiTenderFree = {
  id?: string | null;
  date?: number | null; //timeline.availableDate
  tender?: OcdsTender | null;
  parties?: OcdsParty[] | null;
  documents?: IApiTenderFileCategory[] | null;
  __typename?: 'SimpleTender';
};
export type OcdsTenderMethod = 'open' | 'selective' | 'limited' | 'direct';
export type OcdsTenderStatus =
  | 'planning'
  | 'planned'
  | 'active'
  | 'cancelled'
  | 'unsuccessful'
  | 'complete'
  | 'withdrawn';
export type OcdsTender = {
  id: string | null; //general.referenceNumberBoxes
  title: string | null; //general.name
  description: string | null; //general.description
  status: OcdsTenderStatus | null;
  procuringEntity: OcdsParty | null;
  procurementMethod: OcdsTenderMethod | null;
  procurementMethodDetails: string | null; //general.typeOfProcedureBoxes
  awardCriteria: string | null;
  awardCriteriaDetails: string | null;
  documentUrl: string | null; //general.linkToTenderDocumentBoxes
  submissionUrl: string | null; //general.linkForSubmittingTenderBoxes
  questionsUrl: string | null; //general.linkToQA
  techniques: OcdsTechniques | null;
  contractPeriod: OcdsPeriod | null;
  items: OcdsItem[];
  enquiryPeriod: OcdsPeriod | null;
  tenderPeriod: OcdsPeriod | null;
  hasRenewal: boolean | null;
  renewal: OcdsRenewal | null;
  value: OcdsValue | null;
  maxValue: OcdsValue | null; //general.contractValueBoxes[2][toValue]
  minValue: OcdsValue | null; //general.contractValueBoxes[2][fromValue]
};
export type TenderFreeInfo = {
  name?: string;
  estValue?: OcdsValue;
  deadline?: number;
  published?: number;
};

export type TenderFreeBox = {
  buyerBoxes: ITenderBox[];
  buyerLinkBox: ITenderBox;
};

export type FreeGeneralCat = IGenericCat & {
  readonly [TenderSubCatName.ShortDescription]: FreeDescSubCat;
  readonly [TenderSubCatName.Location]: FreeLocationSubCat;
  readonly [TenderSubCatName.Contact]: FreeContactSubCat;
  readonly [TenderSubCatName.Contract]: FreeContractSubCat;
  readonly [TenderSubCatName.Procurement]: FreeProcurementSubCat;
};
export type FreeDescSubCat = IGenericSubCat & {
  description?: string;
};
export type FreeLocationSubCat = IGenericSubCat & {
  placeOfPerformance?: string[];
  location?: string;
};
export type FreeContactSubCat = IGenericSubCat & {
  buyerName?: string;
  buyerId?: string;
  phone?: string;
  contactPerson?: string;
  email?: string;
  city?: string;
  postalAddress?: string;
  buyerNutsCode?: string[];
  postcode?: string;
};
export type FreeContractSubCat = IGenericSubCat & {
  estValue?: OcdsValue;
  contractDuration?: number;
  contractRenewal?: string;
  contractStart?: number;
  contractEnd?: number;
};
export type FreeProcurementSubCat = IGenericSubCat & {
  typeOfProcedure?: string;
  linkForSubmittingTender?: string;
  frameworkAgreement?: boolean;
  deadline?: number;
  published?: number;
  referenceNumber?: string;
  awardCriteria?: string;
  deadlineOfQuestions?: number;
};
