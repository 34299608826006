import { gql } from '@apollo/client';

export const DECISION_MATRIX_ITEM_FIELDS = gql`
  fragment decisionMatrixItemFields on DecisionMatrixItem {
    id
    score
    title
  }
`;

export const DECISION_MATRIX_FIELDS = gql`
  fragment decisionMatrixFields on DecisionMatrix {
    comment
    id
    title
  }
`;

export const DECISION_MATRIX_FIELDS_FULL = gql`
  fragment decisionMatrixFieldsFull on DecisionMatrix {
    ...decisionMatrixFields
    items {
      ...decisionMatrixItemFields
    }
  }
  ${DECISION_MATRIX_ITEM_FIELDS}
  ${DECISION_MATRIX_FIELDS}
`;

export const DECISION_MATRIX_TEMPLATE_FIELDS = gql`
  fragment decisionMatrixTemplateFields on DecisionMatrixTemplate {
    id
    title
  }
`;
