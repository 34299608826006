import {
  ApiTendersData,
  ApiTendersDataFull,
  ApiTendersItem,
  ApiTendersVars,
  TendersSortName,
  TendersSortOrder,
  UpdateTenderSearchVarsOptions
} from '../Tenders/types';
import { KeywordWidth } from '@tendium/prom-types';
import { ownership, Ownerships } from 'src/models/ownership';
import { QueryResult } from '@apollo/client';
import { TendersListData } from '../Tenders';
import { TendersSearchSchemaKeyType, TendersSearchSchemaType } from './schemas';

export type TendersSearchEventSource = 'All' | 'Starred' | 'Mp' | 'ExpContract' | 'SupplierProfile' | 'BuyerProfile';

export type TendersSearchDateRangeFields = keyof Pick<TendersSearchSchemaType, 'published' | 'deadline'>;

export const KEYWORD_WIDTH = ownership.name === Ownerships.Byggfakta ? KeywordWidth.Narrow : KeywordWidth.Broad;

// Only enable Västra Götalandsregionen(2321000131) as a buyer when Analytics_Pages_Demo is acitve
export const DEMO_BUYER = { buyerOrgId: '2321000131', buyerName: 'Västra Götalandsregionen' };
export const DEMO_PARAMS: Partial<TendersSearchSchemaType> = { buyerOrgIds: [DEMO_BUYER.buyerOrgId] };

export const TENDERS_SEARCH_INT_VARS: TendersSearchSchemaType = {
  amount: 30,
  offset: 0,
  sort: {
    key: TendersSortName.published,
    sort: TendersSortOrder.DESCENDING
  }
};

export interface TendersSearchVarsInput {
  eventSource: TendersSearchEventSource;
  initVars: TendersSearchSchemaType;
  mergedVars: TendersSearchSchemaType;
  updateVars?: TendersSearchUpdateFn;
}

export type TendersSearchReturnValueType<QueryReturnT> = Pick<
  QueryResult<QueryReturnT, ApiTendersVars>,
  'fetchMore' | 'loading' | 'error'
> & {
  data?: TendersListData<ApiTendersItem>;
  fetchingMore: boolean;
  vars: TendersSearchSchemaType;
  initVars: TendersSearchSchemaType;
  apiVars: QueryResult<QueryReturnT, ApiTendersVars>['variables'];
  eventSource: TendersSearchEventSource;
};

export type TendersSearchUpdateFn = (
  varsToUpdate: Partial<TendersSearchSchemaType>,
  options?: UpdateTenderSearchVarsOptions
) => void;

export type TendersSearchResetFn = (keys: TendersSearchSchemaKeyType[]) => void;

export type ExpContractQueryData = TendersSearchReturnValueType<ApiTendersDataFull>;
export type TendersSearchQueryData = TendersSearchReturnValueType<ApiTendersData>;

export type TendersSearchReturnType = [TendersSearchQueryData, TendersSearchUpdateFn, TendersSearchResetFn];
export type ExpContractReturnType = [ExpContractQueryData, TendersSearchUpdateFn, TendersSearchResetFn];
