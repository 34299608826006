import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { GenericSearchDropdown } from 'src/shared';
import { TendersKeywordsProvider } from 'src/models/keywords/providers';
import Keywords from 'src/shared/MatchingProfile/Keywords';
import { FilterLogic } from '@tendium/prom-types';
import { TenderFilterRefProps, TendersFilterProps } from '../types';
import { MpKeywordAndGroup } from 'src/models/matchingProfiles/types';

type Props = Pick<TendersFilterProps, 'values' | 'onChange' | 'isDiffFromProfile' | 'isDiffFromInit' | 'disabled'>;

export const FilterKeywords = forwardRef<TenderFilterRefProps, Props>(
  ({ values, onChange, isDiffFromInit, isDiffFromProfile, disabled }, ref) => {
    const { t } = useTranslation();
    const [filterNewKeywords, setFilterNewKeywords] = useState<MpKeywordAndGroup[]>([]);
    const [highlightOnlyNewKeywords, setHighlightOnlyNewKeywords] = useState<MpKeywordAndGroup[]>([]);

    const handleReset = useCallback(() => {
      setFilterNewKeywords([]);
      setHighlightOnlyNewKeywords([]);
    }, []);
    useImperativeHandle(ref, () => ({ handleReset }), [handleReset]);

    return (
      <GenericSearchDropdown
        label={t('ExpiringContracts.keywords')}
        overlay={
          <>
            <TendersKeywordsProvider
              type={FilterLogic.Filter}
              searchArgs={values}
              updateSearchArgs={onChange}
              newKeywords={filterNewKeywords}
              setNewKeywords={setFilterNewKeywords}
            >
              <Keywords disabled={!!disabled} />
            </TendersKeywordsProvider>
            <TendersKeywordsProvider
              type={FilterLogic.HighlightOnly}
              searchArgs={values}
              updateSearchArgs={onChange}
              newKeywords={highlightOnlyNewKeywords}
              setNewKeywords={setHighlightOnlyNewKeywords}
            >
              <Keywords disabled={!!disabled} />
            </TendersKeywordsProvider>
          </>
        }
        overlayClassName={styles.keywordOverlay}
        isChanged={isDiffFromInit}
        special={isDiffFromProfile}
      />
    );
  }
);

FilterKeywords.displayName = 'FilterKeywords';

export default FilterKeywords;
