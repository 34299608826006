import { MutationTuple, useMutation, useQuery, QueryResult, LazyQueryResultTuple, useLazyQuery } from '@apollo/client';
import { useContext, useMemo, Dispatch, SetStateAction, useCallback, useState } from 'react';
import {
  IApiTenderRequest,
  IApiTenderResponse,
  IApiTender,
  ITenderBox,
  IApiBoxField,
  IBoxWithComments,
  IRemoveTenderBoxResponse,
  IAddTenderBoxResponse,
  ApiTenderMinimalResponse,
  TenderMinimalRequest,
  UseTenderMinimalData
} from './types';
import { GET_FULL_TENDER, GET_TENDER_FILE, GET_ZIP, GET_TENDER_MINIMAL } from './queries';
import { TenderContextValue, TenderContext, UpdateCurrentTenderBoxContext, CurrentTenderBoxContext } from './context';
import { FeatureFlag, useFeatureFlag } from 'src/helpers/featureFlag';
import { BoxSpecId, OverviewQp } from '../types';
import { useQueryParams } from 'src/helpers/history';
import { IApiComment } from 'src/models/comments/types';
import { isString } from 'src/helpers';
import { useParams } from 'react-router-dom';
import { downloadFile, splitFileExt } from 'src/helpers/files';
import { isConvertableFile, isPreviewableFile } from '../helpers';
import { ADD_TENDER_BOX, REMOVE_TENDER_BOX } from './mutations';
import { TenderMinimal } from '.';

export function useTenderMinimal(id?: string, skip?: boolean): UseTenderMinimalData {
  const query = useQuery<ApiTenderMinimalResponse, TenderMinimalRequest>(GET_TENDER_MINIMAL, {
    variables: {
      id: id ?? ''
    },
    skip: !!skip || !id
  });

  return useMemo(
    () => ({
      ...query,
      data: query.data ? new TenderMinimal(query.data.getTender) : undefined,
      loading: query.loading
    }),
    [query]
  );
}

export function useApiTender(id?: string): Pick<QueryResult<IApiTenderResponse, IApiTenderRequest>, 'loading'> & {
  data?: IApiTender;
} {
  const isGPTSummaryFeature = useFeatureFlag(FeatureFlag.GPT_Summary);

  const { data: tenderData, loading: tenderLoading } = useQuery<IApiTenderResponse, IApiTenderRequest>(
    GET_FULL_TENDER,
    {
      variables: {
        id: id ?? '',
        includeSummary: !!isGPTSummaryFeature
      },
      skip: !id
    }
  );

  return useMemo(
    () => ({
      data: tenderData?.getTender,
      loading: tenderLoading
    }),
    [tenderData, tenderLoading]
  );
}

export function useTenderData(): TenderContextValue {
  return useContext(TenderContext);
}

export function useCurrentTenderBoxId(): string | null {
  return useContext(CurrentTenderBoxContext);
}

export function useUpdateCurrentTenderBox(): Dispatch<SetStateAction<string | null>> | null {
  return useContext(UpdateCurrentTenderBoxContext);
}

export function useCurrentTenderBox(): IBoxWithComments | null {
  const { data: model } = useTenderData();
  const boxId = useCurrentTenderBoxId();

  return useMemo(() => {
    return (boxId && model && model.currentBox(boxId)) || null;
  }, [boxId, model]);
}

export function useEvaluationModelBox(): ITenderBox | undefined {
  const { data: model } = useTenderData();

  return useMemo(() => {
    const cate = (model?.cats || []).find(cate => cate.id === `BIDDING_PROCESS`);
    const subCate = (cate?.subCats || []).find(subCat => subCat.id === `BIDDING_PROCESS.EVALUATION_PROCESS`);
    const box = (subCate?.boxes || []).find(box => box.specificationId === BoxSpecId.EVALUATION_MODEL_3);
    return box;
  }, [model]);
}

type TenderBoxFieldInput = IApiBoxField;
interface IAddCustomTenderBoxInput {
  title: string;
  fields: TenderBoxFieldInput[];
  category: string;
  specificationId?: BoxSpecId | string;
}
interface IAddTenderBoxInput {
  id: string;
  bidId: string;
  box: IAddCustomTenderBoxInput;
}

export function useAddTenderBox(): MutationTuple<IAddTenderBoxResponse, IAddTenderBoxInput> {
  return useMutation<IAddTenderBoxResponse, IAddTenderBoxInput>(ADD_TENDER_BOX);
}

interface ITenderBoxToRemoveInput {
  specificationId: string;
  boxId: string;
}

interface IRemoveTenderBoxInput {
  id: string;
  bidId: string;
  box: ITenderBoxToRemoveInput;
}

export function useRemoveTenderBox(): MutationTuple<IRemoveTenderBoxResponse, IRemoveTenderBoxInput> {
  return useMutation<IRemoveTenderBoxResponse, IRemoveTenderBoxInput>(REMOVE_TENDER_BOX);
}

export function useQPComment(): IApiComment | null {
  const { data: procurement } = useTenderData();
  const qp = useQueryParams();
  const commentId = qp[OverviewQp.CommentId];

  const existingComment = useMemo(() => {
    return (
      (isString(commentId) && procurement && procurement.flatComments.find(comment => comment.id === commentId)) || null
    );
  }, [commentId, procurement]);

  return existingComment || null;
}

interface GetFileRequest {
  procurementId?: string;
  targetPath?: string | null;
  skipConversion?: boolean | null;
}

export interface GetFileResponse {
  readonly getFile: {
    url: string;
    conversionResult?: boolean;
  };
}

export function useFileUrl(input: GetFileRequest): QueryResult<GetFileResponse, GetFileRequest> {
  return useQuery<GetFileResponse, GetFileRequest>(GET_TENDER_FILE, {
    variables: {
      procurementId: input.procurementId,
      targetPath: input.targetPath,
      skipConversion: input.skipConversion ?? true
    },
    skip: !input.procurementId || !input.targetPath || input.targetPath.startsWith('http') // skip if targetPath is a url (eg: bid attachments)
  });
}

export function useLazyFileUrl(): LazyQueryResultTuple<GetFileResponse, GetFileRequest> {
  return useLazyQuery<GetFileResponse, GetFileRequest>(GET_TENDER_FILE);
}

export function useDownloadFile(id?: string): {
  getFile: (targetPath: string) => void;
  loading: boolean;
} {
  const { id: paramId } = useParams<{ id: string }>();
  const [getFileLazy, { loading }] = useLazyFileUrl();

  const procurementId = id || paramId;

  const getFile = useCallback(
    (targetPath: string) => {
      return getFileLazy({ variables: { procurementId, targetPath, skipConversion: true } }).then(res => {
        if (res.error) {
          return;
        }
        const url = res.data?.getFile?.url;
        if (url) {
          downloadFile(url, targetPath);
        }
      });
    },
    [getFileLazy, procurementId]
  );

  return useMemo(() => ({ getFile, loading }), [getFile, loading]);
}
export function usePreviewFile(id?: string): {
  previewFile: (targetPath: string) => boolean;
  closePreview: () => void;
  url: string;
  loading: boolean;
} {
  const { id: paramId } = useParams<{ id: string }>();
  const procurementId = id || paramId;
  const [getFileLazy, { loading }] = useLazyFileUrl();
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewUrl, setPreviewUrl] = useState('');
  const openPreview = useCallback((url: string) => {
    setPreviewUrl(url);
    setPreviewVisible(true);
  }, []);
  const closePreview = useCallback(() => {
    setPreviewUrl('');
    setPreviewVisible(false);
  }, []);

  const previewFile = useCallback(
    (targetPath: string) => {
      const [, fileExt] = splitFileExt(targetPath);
      const isPreviewable = isPreviewableFile(fileExt);
      const isConvertable = isConvertableFile(fileExt);
      getFileLazy({ variables: { procurementId, targetPath, skipConversion: !isConvertable } }).then(res => {
        if (res.error) {
          return;
        }
        if (!res.data?.getFile?.url) return;
        const { conversionResult, url } = res.data.getFile;
        if (!isPreviewable || (isConvertable && !conversionResult)) {
          downloadFile(url, targetPath);
          return;
        }
        openPreview(url);
      });
      return isPreviewable;
    },
    [getFileLazy, openPreview, procurementId]
  );

  return useMemo(
    () => ({
      url: previewVisible ? previewUrl : '',
      loading,
      previewFile,
      closePreview
    }),
    [closePreview, loading, previewFile, previewUrl, previewVisible]
  );
}

export function useLoadTenderZipFile(): LazyQueryResultTuple<
  { getZip: string },
  { procurementId: string; fileName: string }
> {
  return useLazyQuery<{ getZip: string }, { procurementId: string; fileName: string }>(GET_ZIP);
}

export function useDownloadTenderZipFile(): {
  getFile: (fileName: string, procurementId: string) => void;
  loading: boolean;
} {
  const [getZipFileLazy, { loading }] = useLoadTenderZipFile();

  const getFile = useCallback(
    (fileName: string, procurementId: string) => {
      procurementId &&
        getZipFileLazy({ variables: { procurementId, fileName } }).then(data => {
          const url = data.data?.getZip;
          if (!url) return;

          downloadFile(url, `${fileName}.zip`);
        });
    },
    [getZipFileLazy]
  );

  return {
    getFile,
    loading
  };
}
