import React, { ChangeEvent, FC, useCallback, useState, useMemo } from 'react';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { Form, Input, Modal, useMe } from 'src/common';
import WorkspaceBadge from 'src/common/WorkspaceBadge';
import { useEmployees } from 'src/models/users/hooks';
import { DEFAULT_WORKSPACE_COLORS, DEFAULT_WORKSPACE_SETTING } from 'src/models/workspace/types';
import ColorPicker from 'src/shared/ColorPicker';
import { Button } from 'antd';
import { useCreateWorkspace } from 'src/models/workspace/hooks';

interface Props {
  visible: boolean;
  onCancel?: () => void;
  onFinish?: (id: string | null) => void;
  loading?: boolean;
}

export const CreateWorkspaceModal: FC<Props> = props => {
  const { visible, onCancel, onFinish, loading } = props;
  const { t } = useTranslation();
  const { data: me } = useMe();
  const { data: employees, loading: employeeLoading } = useEmployees();
  const [color, setColor] = useState(DEFAULT_WORKSPACE_SETTING.color);
  const [title, setTitle] = useState(DEFAULT_WORKSPACE_SETTING.name);

  const owners = useMemo(
    () => (me && employees?.createdBy ? (me.isAdmin ? [me.email] : [employees.createdBy.email, me.email]) : null),
    [employees, me]
  );

  const [form] = Form.useForm();
  const [createWorkspace, { loading: isCreating }] = useCreateWorkspace();

  const onCreateWorkspace = useCallback(() => {
    if (!title) {
      form.setFields([
        {
          name: 'title',
          errors: [t('BidSpaces.enterBidSpaceName')]
        }
      ]);
    } else {
      createWorkspace(
        {
          name: title,
          color,
          owners,
          subscribers: null
        },
        DEFAULT_WORKSPACE_SETTING.subscribersPolicy,
        onFinish
      );
    }
  }, [title, form, t, createWorkspace, color, owners, onFinish]);

  const onChangeHandler = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  }, []);

  const onModalCancel = useCallback(() => {
    onCancel && onCancel();
  }, [onCancel]);

  const isLoading = employeeLoading || isCreating || loading;

  return (
    <Modal
      title={<span>{t('BidSpaces.createNewBidSpace')}</span>}
      visible={visible}
      onCancel={onModalCancel}
      width={790}
      onOk={onCreateWorkspace}
      okText={t('BidSpaces.createBidSpace')}
      keyboard={false}
      okButtonProps={{
        loading: !!loading,
        disabled: !title && !isLoading,
        className: styles.okButton
      }}
      cancelButtonProps={{
        className: styles.cancelButton
      }}
      className={styles.modal}
      maskClosable={false}
    >
      <Form initialValues={{ title: DEFAULT_WORKSPACE_SETTING.name }} form={form} onFinish={onCreateWorkspace}>
        <div className={styles.unit}>
          <Form.Item
            label={t('BidSpaces.nameOfBidSpace')}
            name="title"
            rules={[{ required: true, message: t('BidSpaces.bidSpaceNameIsRequired') }]}
            className={styles.formItem}
          >
            <Input
              onChange={onChangeHandler}
              name="title"
              type="text"
              maxLength={30}
              placeholder={t('BidSpaces.nameOfBidSpaceTips')}
              autoFocus
            />
          </Form.Item>
          <Form.Item label={t('Common.color')} className={styles.formItem}>
            <div className={styles.colorPicker}>
              <WorkspaceBadge title={''} color={color} className={styles.badge} />
              <ColorPicker
                currentColor={color}
                onSubmit={setColor}
                onChange={setColor}
                paletteColors={DEFAULT_WORKSPACE_COLORS}
                placement={'float'}
              >
                <Button className={styles.changeButton}>{t('Common.change')}</Button>
              </ColorPicker>
            </div>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default CreateWorkspaceModal;
