import React, { FC, useCallback } from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';
import { SearchFieldTag } from 'src/common';
import { hexToRgb } from 'src/helpers/styles';
import { useDeleteContentLibraryTag } from 'src/models/contentLibrary/ContentLibraryTag/hooks';
import { Tag } from 'src/models/contentLibrary/ContentLibraryTag';

interface Props {
  tag: Tag;
  disabled: boolean;
  isListItem?: boolean;
}

export const ContentLibraryTag: FC<Props> = props => {
  const { tag, disabled, isListItem } = props;

  const [deleteContentLibraryTag] = useDeleteContentLibraryTag();

  const onRemove = useCallback(() => {
    deleteContentLibraryTag({ id: tag.id });
  }, [deleteContentLibraryTag, tag]);

  return (
    <li
      className={classNames(
        styles.keywordsItem,
        disabled ? styles.isDisabled : '',
        isListItem ? styles.isListItem : ''
      )}
    >
      <SearchFieldTag value={tag.title} color={hexToRgb(tag.color, 0.25)} disabled={disabled} onRemove={onRemove} />
    </li>
  );
};

export default ContentLibraryTag;
