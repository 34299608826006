import { ApiAssignedTo } from 'src/models/users/AssignedTo/types';
import { IFileNode } from 'src/models/documentsLibrary/types';
import { IApiComment } from 'src/models/comments/types';

export interface IApiBidChecklist {
  readonly id: string;
  readonly checklist: IApiChecklistItem[];
  readonly workspace: {
    id: string;
  };
}

export interface IBidChecklistResponse {
  readonly getBid: IApiBidChecklist;
}

export enum ChecklistItemStatus {
  ToDo = 'ToDo',
  InProgress = 'InProgress',
  Done = 'Done'
}
export enum ChecklistItemFulfillment {
  Fulfill = 'Fulfill',
  Unsure = 'Unsure',
  NotFulfill = 'NotFulfill'
}

export interface IApiChecklistItem {
  id: string;
  bidId: string;
  name: string;
  description: string;
  status: ChecklistItemStatus;
  assignedTo: ApiAssignedTo | null;
  deadline: number | null;
  isActive: boolean;
  fulfillment: ChecklistItemFulfillment | null;
  attachments: IFileNode[];
  comments: IApiComment[];
  isPreMade: boolean;
}
export interface IChecklistItem extends Omit<IApiChecklistItem, 'assignedTo'> {
  assignedTo: string | null;
}
