import React, { FC } from 'react';
import styles from './index.module.scss';
import { IBoxFieldCurrency } from 'src/models/procurements/Tender/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownFromLine } from '@fortawesome/pro-light-svg-icons';
import BlockEmpty from 'src/shared/Blocks/Empty';
import { formatCurrencyValue } from 'src/models/procurements/Tender/helpers';
import { IBlockReadProps } from 'src/shared/Tender/Boxes/types';
import { useTranslation } from 'react-i18next';
import isBoolean from 'src/helpers/isBoolean';
import { useLanguage } from 'src/common';

interface Props extends IBlockReadProps, Pick<IBoxFieldCurrency, 'amount' | 'currency'> {}
export const ReadOnlyBlockCurrency: FC<Props> = ({ amount, currency, config, className }) => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  return amount || currency ? (
    <div className={className}>
      <span className={styles.block}>
        {config?.icon && (
          <FontAwesomeIcon icon={isBoolean(config.icon) ? faArrowDownFromLine : config.icon} className={styles.icon} />
        )}
        <span className={styles.title}>
          {amount ? (
            <>
              {config?.currency?.isShort ? (
                formatCurrencyValue(amount, language, currency as string)
              ) : (
                <>
                  {t('Common.Format.formatCurrencyNumber', { number: Math.round(amount) })} {currency ?? ''}
                </>
              )}
            </>
          ) : (
            <BlockEmpty />
          )}
        </span>
      </span>
    </div>
  ) : (
    <BlockEmpty />
  );
};

export default ReadOnlyBlockCurrency;
