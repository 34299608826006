import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { KeywordsContext, KeywordsContextValue } from './context';
import {
  useCreateMPKeywordGroups,
  useCreateMPKeywords,
  useDeleteMPKeywords,
  useLoadMp,
  useUpdateMPKeywords,
  useUpdateMpKeywordSearchLanguages,
  useUpdateMpKeywordWidth
} from 'src/models/matchingProfiles/hooks';
import {
  DEFAULT_KEYWORD_SETTING,
  MpKeyword,
  MpKeywordAndGroup,
  MpKeywordGroup
} from 'src/models/matchingProfiles/types';
import { FilterLogic, HighlightColor, KeywordWidth, fillKeywordSettingsWithDefaultValues } from '@tendium/prom-types';
import { useTranslation } from 'react-i18next';
import { notNull, useMyFeaturesAndOwnership } from 'src/helpers';
import { faFilter, faHighlighterLine, faRadar } from '@fortawesome/pro-light-svg-icons';
import { v4 } from 'uuid';
import { BaseKeywordDTO, NewKeywordDTO, NewKeywordGroupDTO } from '@tendium/prom-types/schema';
import { getGroupedKeywords } from './helpers';
import { UpdateKeywordSearchLanguagesParams } from './types';
import { TendersSearchSchemaType } from '../procurements/TendersSearch/schemas';
import { TendersSearchUpdateFn } from '../procurements/TendersSearch/types';

export function useMPKeywordsData(type: FilterLogic): KeywordsContextValue {
  const { t } = useTranslation();
  const { data: mpData } = useLoadMp();
  const [createMPKeywords] = useCreateMPKeywords();
  const [createKeywordGroups] = useCreateMPKeywordGroups();
  const [updateMPKeywords] = useUpdateMPKeywords();
  const [deleteMPKeywords] = useDeleteMPKeywords();
  const [updateMPKeywordWidth] = useUpdateMpKeywordWidth();
  const [updateMPKeywordSearchLanguages] = useUpdateMpKeywordSearchLanguages();
  const [newKeywords, setNewKeywords] = useState<MpKeywordAndGroup[]>([]);

  const createKeywords = useCallback(
    async (keywords: string[], keywordGroups: string[][]) => {
      if (keywords.length && mpData) {
        const result = (await createMPKeywords({
          matchingProfileId: mpData.id,
          values: keywords,
          filterLogic: type
        })) as { data: { createMultipleKeywords: MpKeyword[] } } | undefined;

        if (result) {
          setNewKeywords(keywordsData => [
            ...keywordsData,
            ...result.data.createMultipleKeywords.map(keyword => ({
              values: [{ value: keyword.value, id: keyword.id }],
              filterLogic: keyword.filterLogic,
              searchLogic: keyword.searchLogic,
              searchType: keyword.searchType,
              highlightColor: keyword.highlightColor
            }))
          ]);
        }
      }

      if (keywordGroups.length && mpData) {
        const result = (await createKeywordGroups({
          matchingProfileId: mpData.id,
          values: keywordGroups,
          filterLogic: type
        })) as { data: { createMultipleKeywordGroups: MpKeywordGroup[] } } | undefined;

        if (result) {
          setNewKeywords(keywordsData => [...keywordsData, ...result.data.createMultipleKeywordGroups]);
        }
      }
    },
    [createKeywordGroups, createMPKeywords, mpData, type]
  );

  const updateKeywords = useCallback(
    (
      keywordIds: string[],
      keywordGroupIds: { keywordIds: string[]; groupId: string | null }[],
      fieldToUpdate: BaseKeywordDTO
    ) => {
      if (mpData) {
        updateMPKeywords(
          mpData.id,
          keywordIds,
          keywordGroupIds.map(group => group.groupId ?? null).filter(notNull),
          fieldToUpdate
        );
        setNewKeywords(keywordsState =>
          keywordsState.map(keyword => {
            if (keyword.groupId) {
              const keywordToUpdate = keywordGroupIds.find(group => group.groupId === keyword.groupId);
              return keywordToUpdate ? { ...keyword, ...fieldToUpdate } : keyword;
            }

            const keywordToUpdate = keywordIds.find(id => id === keyword.values[0].id);
            return keywordToUpdate ? { ...keyword, ...fieldToUpdate } : keyword;
          })
        );
      }
    },
    [mpData, updateMPKeywords]
  );

  const deleteKeywords = useCallback(
    (keywords: { values: { id: string; value: string }[]; groupId: string | null }[]) => {
      if (keywords.length && mpData) {
        const singleKeywordIds = keywords.filter(keyword => !keyword.groupId).map(keyword => keyword.values[0].id);
        const keywordGroupIds = keywords
          .filter(keyword => keyword.groupId !== null)
          .map(keyword => keyword.groupId as string);

        deleteMPKeywords(singleKeywordIds, keywordGroupIds, mpData.id);
        setNewKeywords(keywordsState =>
          keywordsState.filter(keyword =>
            keyword.groupId
              ? !keywordGroupIds.includes(keyword.groupId)
              : !singleKeywordIds.includes(keyword.values[0].id)
          )
        );
      }
    },
    [deleteMPKeywords, mpData]
  );

  const updateKeywordWidth = useCallback(
    (keywordWidth: KeywordWidth) => {
      if (mpData) {
        updateMPKeywordWidth(keywordWidth, mpData);
      }
    },
    [mpData, updateMPKeywordWidth]
  );

  const filterKeywords = useCallback(
    (keywords: MpKeywordAndGroup[]) => {
      const newKeywordIds: string[] = [];
      const newKeywordGroupIds: string[] = [];

      newKeywords.forEach(keyword => {
        if (keyword.groupId) {
          newKeywordGroupIds.push(keyword.groupId);
        } else {
          newKeywordIds.push(keyword.values[0].id);
        }
      });

      return keywords.filter(keyword => {
        if (keyword.groupId && newKeywordGroupIds.length) {
          return !newKeywordGroupIds.includes(keyword.groupId);
        } else {
          return !newKeywordIds.includes(keyword.values[0].id);
        }
      });
    },
    [newKeywords]
  );

  const updateKeywordSearchLanguages = useCallback(
    (data: UpdateKeywordSearchLanguagesParams) => {
      if (mpData) {
        updateMPKeywordSearchLanguages(data, mpData);
      }
    },
    [mpData, updateMPKeywordSearchLanguages]
  );

  return useMemo(
    () => ({
      data: {
        keywords:
          type === FilterLogic.HighlightOnly
            ? filterKeywords(mpData?.highlightKeywords ?? [])
            : filterKeywords(mpData?.filterKeywords ?? []),
        newKeywords,
        keywordWidth: mpData?.keywordWidth,
        filterKeywordLanguages: mpData?.filterKeywordLanguages,
        highlightKeywordLanguages: mpData?.highlightKeywordLanguages,
        filterLogic: type,
        texts: {
          sectionTitle: type === FilterLogic.HighlightOnly ? t('Tenders.Keywords.highlightKeywords') : undefined,
          sectionDesc:
            type === FilterLogic.HighlightOnly
              ? t('Tenders.Keywords.highlightKeywordsDescription')
              : t('Tenders.Keywords.keywordsFiltersDescription'),
          sectionIcon: type === FilterLogic.HighlightOnly ? faHighlighterLine : undefined,
          advancedModalTitle:
            type === FilterLogic.HighlightOnly
              ? t('Tenders.Keywords.highlightKeywords')
              : t('Tenders.advancedKeywordSettings'),
          searchFieldPlaceholder:
            type === FilterLogic.HighlightOnly
              ? t('Tenders.Keywords.addNewHighlightingWord')
              : t('Tenders.Keywords.addNewKeywords'),
          searchFieldButtonLabel:
            type === FilterLogic.HighlightOnly ? t('Tenders.Keywords.addHighlightingWord') : t('Common.addKeywords'),
          searchFieldButtonIcon: type === FilterLogic.HighlightOnly ? faHighlighterLine : faFilter,
          deleteButtonDesc:
            type === FilterLogic.HighlightOnly
              ? t('Tenders.Keywords.deleteHighlightKeywordsDesc')
              : t('Tenders.Keywords.deleteKeywordsDesc'),
          noDataDesc:
            type === FilterLogic.HighlightOnly
              ? t('Tenders.Keywords.noDataHighlightKeywordsDesc')
              : t('Tenders.Keywords.noDataKeywordsDesc')
        },
        eventNamePrefix: 'MP',
        mp: mpData ? { id: mpData.id, name: mpData.name } : undefined,
        cpvCodes: mpData?.cpvCodes,
        cpvCodesExact: mpData?.cpvCodesExact
      },
      createKeywords,
      updateKeywords,
      deleteKeywords,
      updateKeywordWidth,
      updateKeywordSearchLanguages
    }),
    [
      createKeywords,
      deleteKeywords,
      filterKeywords,
      mpData,
      newKeywords,
      t,
      type,
      updateKeywordSearchLanguages,
      updateKeywordWidth,
      updateKeywords
    ]
  );
}

export function useTendersKeywordsData(
  type: FilterLogic,
  searchArgs: TendersSearchSchemaType,
  updateSearchArgs: TendersSearchUpdateFn,
  newKeywords: MpKeywordAndGroup[],
  setNewKeywords: Dispatch<SetStateAction<MpKeywordAndGroup[]>>
): KeywordsContextValue {
  const { t } = useTranslation();
  const fillKeywordsInfo = useMyFeaturesAndOwnership();

  useEffect(() => setNewKeywords([]), [searchArgs.profileId, setNewKeywords]);

  const keywords = useMemo(() => {
    const singleKeywords = (searchArgs.keywordArgs ?? [])
      .map(keyword => {
        const keywordIndex = newKeywords.findIndex(
          item => item.filterLogic === type && item.values.length === 1 && item.values[0].value === keyword.value
        );

        if (keywordIndex !== undefined && keywordIndex !== -1) {
          return null;
        }
        return {
          value: keyword.value,
          id: keyword.value,
          filterLogic: keyword.filterLogic ?? type,
          searchLogic: keyword.searchLogic ?? DEFAULT_KEYWORD_SETTING.searchLogic,
          searchType: keyword.searchType ?? DEFAULT_KEYWORD_SETTING.searchType,
          highlightColor: !!keyword.highlightColor
            ? keyword.highlightColor
            : type === FilterLogic.HighlightOnly
            ? HighlightColor.Yellow
            : HighlightColor.Green
        };
      })
      .filter(notNull);

    const keywordGroups = (searchArgs.keywordGroups ?? [])
      .map(group => {
        const keywordIndex = newKeywords.findIndex(
          item =>
            item.filterLogic === type &&
            item.values.length === group.values.length &&
            item.values.every((element, index) => element.value === group.values[index])
        );

        if (keywordIndex !== undefined && keywordIndex !== -1) {
          return null;
        }

        return {
          values: group.values.map(value => ({ value, id: value })),
          groupId: group.groupId,
          filterLogic: group.filterLogic ?? type,
          searchLogic: group.searchLogic ?? DEFAULT_KEYWORD_SETTING.searchLogic,
          searchType: group.searchType ?? DEFAULT_KEYWORD_SETTING.searchType,
          highlightColor: !!group.highlightColor
            ? group.highlightColor
            : type === FilterLogic.HighlightOnly
            ? HighlightColor.Yellow
            : HighlightColor.Green
        };
      })
      .filter(notNull);

    return getGroupedKeywords(singleKeywords, keywordGroups, type);
  }, [newKeywords, searchArgs.keywordArgs, searchArgs.keywordGroups, type]);

  const createKeywords = useCallback(
    (singleKeywords: string[], keywordGroups: string[][]) => {
      if (singleKeywords.length) {
        const newKeywords: NewKeywordDTO[] = singleKeywords.map(keyword => {
          return fillKeywordSettingsWithDefaultValues<NewKeywordDTO>(
            { value: keyword, filterLogic: type },
            fillKeywordsInfo.myFeatureFlags
          );
        });

        updateSearchArgs({ keywordArgs: [...(searchArgs.keywordArgs ?? []), ...newKeywords] });

        setNewKeywords(keywordsData => [
          ...keywordsData,
          ...newKeywords.map(keyword => ({
            values: [
              {
                value: keyword.value,
                id: keyword.value
              }
            ],
            filterLogic: keyword.filterLogic ?? type,
            searchLogic: keyword.searchLogic ?? DEFAULT_KEYWORD_SETTING.searchLogic,
            searchType: keyword.searchType ?? DEFAULT_KEYWORD_SETTING.searchType,
            highlightColor: !!keyword.highlightColor
              ? keyword.highlightColor
              : type === FilterLogic.HighlightOnly
              ? HighlightColor.Yellow
              : HighlightColor.Green
          }))
        ]);
      }

      if (keywordGroups.length) {
        const newKeywordGroups = keywordGroups.map(group => {
          const filledKeyword = fillKeywordSettingsWithDefaultValues<NewKeywordGroupDTO>(
            { values: group, filterLogic: type },
            fillKeywordsInfo.myFeatureFlags
          );

          return { ...filledKeyword, groupId: v4() };
        });

        updateSearchArgs({
          keywordGroups: [...(searchArgs.keywordGroups ?? []), ...newKeywordGroups]
        });

        setNewKeywords(keywordsData => [
          ...keywordsData,
          ...newKeywordGroups.map(keyword => ({
            groupId: keyword.groupId,
            values: keyword.values.map(value => ({ value, id: value })),
            filterLogic: keyword.filterLogic ?? type,
            searchLogic: keyword.searchLogic ?? DEFAULT_KEYWORD_SETTING.searchLogic,
            searchType: keyword.searchType ?? DEFAULT_KEYWORD_SETTING.searchType,
            highlightColor: !!keyword.highlightColor
              ? keyword.highlightColor
              : type === FilterLogic.HighlightOnly
              ? HighlightColor.Yellow
              : HighlightColor.Green
          }))
        ]);
      }
    },
    [
      updateSearchArgs,
      searchArgs.keywordArgs,
      searchArgs.keywordGroups,
      setNewKeywords,
      type,
      fillKeywordsInfo.myFeatureFlags
    ]
  );

  const updateKeywords = useCallback(
    (
      singleKeywordIds: string[],
      keywordGroupIds: { keywordIds: string[]; groupId: string | null }[],
      fieldToUpdate: BaseKeywordDTO
    ) => {
      if (singleKeywordIds.length) {
        const updatedKeywords = [...(searchArgs?.keywordArgs || [])];

        singleKeywordIds.forEach(keyword => {
          const keywordIndex = searchArgs?.keywordArgs?.findIndex(
            item => item.filterLogic === type && item.value === keyword
          );

          if (keywordIndex !== undefined && keywordIndex !== -1) {
            const keywordToUpdate = updatedKeywords[keywordIndex];
            updatedKeywords[keywordIndex] = {
              ...keywordToUpdate,
              ...fieldToUpdate
            };
          }
        });

        updateSearchArgs({ keywordArgs: [...updatedKeywords] });
      }

      if (keywordGroupIds.length) {
        const updatedKeywords = [...(searchArgs?.keywordGroups || [])];

        keywordGroupIds.forEach(keyword => {
          const keywordIndex = searchArgs?.keywordGroups?.findIndex(
            item => item.filterLogic === type && item.groupId === keyword.groupId
          );

          if (keywordIndex !== undefined && keywordIndex !== -1) {
            const keywordToUpdate = updatedKeywords[keywordIndex];
            updatedKeywords[keywordIndex] = {
              ...keywordToUpdate,
              ...fieldToUpdate
            };
          }
        });

        updateSearchArgs({ keywordGroups: [...updatedKeywords] });
      }

      setNewKeywords(keywordsState =>
        keywordsState.map(keyword => {
          if (keyword.groupId) {
            const keywordToUpdate = keywordGroupIds.find(group => group.groupId === keyword.groupId);
            return keywordToUpdate ? { ...keyword, ...fieldToUpdate } : keyword;
          }

          const keywordToUpdate = singleKeywordIds.find(id => id === keyword.values[0].value);
          return keywordToUpdate ? { ...keyword, ...fieldToUpdate } : keyword;
        })
      );
    },
    [searchArgs?.keywordArgs, searchArgs?.keywordGroups, setNewKeywords, type, updateSearchArgs]
  );

  const deleteKeywords = useCallback(
    (keywordIds: { values: { id: string; value: string }[]; groupId: string | null }[]) => {
      const singleKeywords: string[] = [];
      const keywordGroupIds: string[] = [];

      keywordIds.forEach(keyword => {
        if (keyword.groupId) {
          keywordGroupIds.push(keyword.groupId);
        } else {
          singleKeywords.push(keyword.values[0].value);
        }
      });

      if (singleKeywords.length) {
        const newKeywordsState = (searchArgs.keywordArgs ?? []).filter(
          keyword => !(keyword.filterLogic === type && singleKeywords.includes(keyword.value))
        );

        updateSearchArgs({ keywordArgs: newKeywordsState });
      }

      if (keywordGroupIds.length) {
        const newKeywordGroupsState = (searchArgs.keywordGroups ?? []).filter(keyword => {
          return keyword.groupId && !keywordGroupIds.includes(keyword.groupId);
        });

        updateSearchArgs({ keywordGroups: newKeywordGroupsState });
      }

      setNewKeywords(keywordsState =>
        keywordsState.filter(keyword =>
          keyword.groupId
            ? !keywordGroupIds.includes(keyword.groupId)
            : !singleKeywords.includes(keyword.values[0].value)
        )
      );
    },
    [searchArgs.keywordArgs, searchArgs.keywordGroups, setNewKeywords, type, updateSearchArgs]
  );

  const updateKeywordWidth = useCallback(
    (keywordWidth: KeywordWidth) => {
      updateSearchArgs({ keywordWidth });
    },
    [updateSearchArgs]
  );

  const updateKeywordSearchLanguages = useCallback(
    (data: UpdateKeywordSearchLanguagesParams) => {
      updateSearchArgs({
        ...(data.filterKeywordLanguages ? { filterKeywordLanguages: data.filterKeywordLanguages } : {}),
        ...(data.highlightKeywordLanguages ? { highlightKeywordLanguages: data.highlightKeywordLanguages } : {})
      });
    },
    [updateSearchArgs]
  );

  return useMemo(
    () => ({
      data: {
        keywords,
        newKeywords,
        keywordWidth: searchArgs.keywordWidth,
        filterKeywordLanguages: searchArgs.filterKeywordLanguages,
        highlightKeywordLanguages: searchArgs.highlightKeywordLanguages,
        filterLogic: type,
        texts: {
          sectionTitle:
            type === FilterLogic.HighlightOnly ? t('Tenders.Keywords.highlightKeywords') : t('Tenders.keywords'),
          sectionDesc:
            type === FilterLogic.HighlightOnly
              ? t('Tenders.Keywords.highlightKeywordsDescription')
              : t('Tenders.Keywords.keywordsFiltersDescription'),
          sectionIcon: type === FilterLogic.HighlightOnly ? faHighlighterLine : faRadar,
          advancedModalTitle:
            type === FilterLogic.HighlightOnly
              ? t('Tenders.Keywords.highlightKeywords')
              : t('Tenders.advancedKeywordSettings'),
          searchFieldPlaceholder:
            type === FilterLogic.HighlightOnly
              ? t('Tenders.Keywords.addNewHighlightingWord')
              : t('Tenders.Keywords.addNewKeywords'),
          searchFieldButtonLabel:
            type === FilterLogic.HighlightOnly ? t('Tenders.Keywords.addHighlightingWord') : t('Common.addKeywords'),
          searchFieldButtonIcon: type === FilterLogic.HighlightOnly ? faHighlighterLine : faFilter,
          deleteButtonDesc:
            type === FilterLogic.HighlightOnly
              ? t('Tenders.Keywords.deleteHighlightKeywordsDesc')
              : t('Tenders.Keywords.deleteKeywordsDesc'),
          noDataDesc:
            type === FilterLogic.HighlightOnly
              ? t('Tenders.Keywords.noDataHighlightKeywordsDesc')
              : t('Tenders.Keywords.noDataKeywordsDesc')
        },
        eventNamePrefix: 'EC',
        cpvCodes: searchArgs.cpvCodes,
        cpvCodesExact: searchArgs.cpvCodesExact
      },
      createKeywords,
      updateKeywords,
      deleteKeywords,
      updateKeywordWidth,
      updateKeywordSearchLanguages
    }),
    [
      createKeywords,
      deleteKeywords,
      keywords,
      newKeywords,
      searchArgs.cpvCodes,
      searchArgs.cpvCodesExact,
      searchArgs.filterKeywordLanguages,
      searchArgs.highlightKeywordLanguages,
      searchArgs.keywordWidth,
      t,
      type,
      updateKeywordSearchLanguages,
      updateKeywordWidth,
      updateKeywords
    ]
  );
}

export function useKeywords(): KeywordsContextValue {
  const contextValue = useContext(KeywordsContext);
  if (!contextValue) {
    throw new Error('This component should be placed inside KeywordsContext.');
  }

  return contextValue;
}
