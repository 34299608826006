import { ChecklistItemStatus, ChecklistItemFulfillment, IApiChecklistItem, IBidChecklistResponse } from './types';
import { QueryResult } from '@apollo/client/react/types/types';
import { ApolloError, MutationUpdaterFn, useMutation, useQuery } from '@apollo/client';
import { GET_BID_CHECKLIST, UPDATE_CHECKLIST_ITEM, CREATE_CHECKLIST_ITEM, DELETE_CHECKLIST_ITEMS } from './queries';
import { useMemo, useContext, useCallback } from 'react';
import {
  BidChecklistContextValue,
  BidChecklistContext,
  ToggleChecklistContext,
  ChecklistExpandedContext
} from './context';
import { useTranslation } from 'react-i18next';
import { notification } from 'src/common';

interface IBidChecklistRequest {
  bidId?: string;
}

export function useApiBidChecklist(bidId?: string): QueryResult<IBidChecklistResponse, IBidChecklistRequest> {
  return useQuery<IBidChecklistResponse, IBidChecklistRequest>(GET_BID_CHECKLIST, {
    variables: {
      bidId
    },
    skip: !bidId
  });
}

export function useBidChecklist(): BidChecklistContextValue {
  return useContext(BidChecklistContext);
}

interface ICreateChecklistItemInput {
  bidId: string;
  name: string;
  description: string;
  status: ChecklistItemStatus;
  assignedTo: string | null;
  deadline: number | null;
}
interface ICreateChecklistItemOutput {
  createChecklistItem: IApiChecklistItem;
}
function updateCacheCreateChecklistItem(bidId: string): MutationUpdaterFn<ICreateChecklistItemOutput> {
  return (cache, { data }) => {
    if (!data) {
      return null;
    }
    const bidRef = cache.identify({
      __typename: 'BidV2',
      id: bidId
    });

    cache.modify({
      id: bidRef,
      fields: {
        checklist(cached, { toReference }) {
          return [...(cached || []), toReference({ __typename: 'ChecklistItem', id: data.createChecklistItem.id })];
        }
      }
    });
  };
}
export function useCreateChecklistItem(): [
  (data: ICreateChecklistItemInput) => void,
  { loading: boolean; error?: ApolloError }
] {
  const { t } = useTranslation();
  const [createChecklistItem, { loading, error }] = useMutation<ICreateChecklistItemOutput, ICreateChecklistItemInput>(
    CREATE_CHECKLIST_ITEM
  );
  const createChecklistItemFn = useCallback(
    (data: ICreateChecklistItemInput) => {
      createChecklistItem({
        variables: data,
        update: updateCacheCreateChecklistItem(data.bidId)
      }).catch(() => {
        notification.error({
          description: t('Common.unknownErrorDesc'),
          message: t('Common.unknownError')
        });
      });
    },
    [createChecklistItem, t]
  );

  return useMemo(() => [createChecklistItemFn, { loading, error }], [error, loading, createChecklistItemFn]);
}

interface IUpdateChecklistItemInput {
  id: string;
  bidId: string;
  name?: string;
  status?: ChecklistItemStatus;
  assignedTo?: string | null;
  deadline?: number;
  description?: string;
  fulfillment?: ChecklistItemFulfillment;
  isActive?: boolean;
}
interface IUpdateChecklistItemOutput {
  __typename: 'Mutation';
  updateChecklistItem: IApiChecklistItem & {
    __typename: 'ChecklistItem';
  };
}
function updateCacheOnUpdateChecklistItem(
  id: string,
  input: Partial<IUpdateChecklistItemInput>
): MutationUpdaterFn<IUpdateChecklistItemOutput> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }
    const itemRef = cache.identify({
      __typename: 'ChecklistItem',
      id
    });
    for (const field in input) {
      if (field !== undefined) {
        if (field === 'assignedTo') {
          cache.modify({
            id: itemRef,
            fields: {
              assignedTo() {
                return input[field]
                  ? {
                      __ref: cache.identify({
                        __typename: 'AssignedTo',
                        id: input[field]
                      })
                    }
                  : null;
              }
            }
          });
        } else {
          cache.modify({
            id: itemRef,
            fields: {
              [field]() {
                return input[field as keyof typeof input];
              }
            }
          });
        }
      }
    }
  };
}
export function useUpdateChecklistItem(): [
  (data: IUpdateChecklistItemInput) => void,
  { loading: boolean; error?: ApolloError }
] {
  const { t } = useTranslation();
  const [updateChecklistItem, { loading, error }] = useMutation<IUpdateChecklistItemOutput, IUpdateChecklistItemInput>(
    UPDATE_CHECKLIST_ITEM
  );
  const updateChecklistItemFn = useCallback(
    (data: IUpdateChecklistItemInput) => {
      updateChecklistItem({
        variables: data,
        update: updateCacheOnUpdateChecklistItem(data.id, data)
      }).catch(() => {
        notification.error({
          description: t('Common.unknownErrorDesc'),
          message: t('Common.unknownError')
        });
      });
    },
    [updateChecklistItem, t]
  );
  return useMemo(() => [updateChecklistItemFn, { loading, error }], [error, loading, updateChecklistItemFn]);
}

interface IDeleteChecklistItemsInput {
  bidId: string;
}
interface IDeleteChecklistItemsOutput {
  deleteChecklistItems: boolean;
}
function updateCacheDeleteChecklistItem(bidId: string): MutationUpdaterFn<IDeleteChecklistItemsOutput> {
  return (cache, { data }) => {
    if (!data?.deleteChecklistItems) {
      return null;
    }
    const bidRef = cache.identify({
      __typename: 'BidV2',
      id: bidId
    });

    cache.modify({
      id: bidRef,
      fields: {
        checklist() {
          return [];
        }
      }
    });
  };
}
export function useDeleteChecklistItems(): [
  (data: IDeleteChecklistItemsInput, onFinish?: () => void) => void,
  { loading: boolean; error?: ApolloError }
] {
  const { t } = useTranslation();
  const [deleteChecklistItems, { loading, error }] = useMutation<
    IDeleteChecklistItemsOutput,
    IDeleteChecklistItemsInput
  >(DELETE_CHECKLIST_ITEMS);
  const deleteChecklistItemsFn = useCallback(
    (data: IDeleteChecklistItemsInput, onFinish?: () => void) => {
      deleteChecklistItems({
        variables: data,
        update: updateCacheDeleteChecklistItem(data.bidId)
      })
        .then(() => {
          onFinish && onFinish();
        })
        .catch(() => {
          notification.error({
            description: t('Common.unknownErrorDesc'),
            message: t('Common.unknownError')
          });
        });
    },
    [deleteChecklistItems, t]
  );

  return useMemo(() => [deleteChecklistItemsFn, { loading, error }], [error, loading, deleteChecklistItemsFn]);
}

export function useChecklistExpand(): [boolean, (expanded?: boolean) => void] {
  const isExpanded = useContext(ChecklistExpandedContext);
  const toggleChecklist = useContext(ToggleChecklistContext);
  const updateFn = useCallback(
    (expanded?: boolean) => {
      toggleChecklist?.(expanded ?? !isExpanded);
    },
    [isExpanded, toggleChecklist]
  );
  return useMemo(() => [isExpanded, updateFn], [isExpanded, updateFn]);
}
