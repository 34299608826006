import { gql } from '@apollo/client';
import { CATEGORY_FILE_FIELDS } from '../Tender/fragments';

const TENDER_FILES_FIELDS = gql`
  fragment tenderFilesFields on Tender {
    id
    fileCategories {
      ...categoryFileFields
    }
  }
  ${CATEGORY_FILE_FIELDS}
`;

export const GET_TENDER_FILES = gql`
  query getTenderFiles($procurementId: String!) {
    getTender(query: { id: $procurementId }) {
      ...tenderFilesFields
    }
  }
  ${TENDER_FILES_FIELDS}
`;
