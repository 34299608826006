import { Tooltip, SwitcherIcon, Button } from 'src/common';
import classNames from 'classnames';
import React from 'react';
import { DataNode } from 'antd/lib/tree';
import { IProcurementFileCategory, IProcurementFile } from 'src/models/procurements/Tender/types';
import styles from './index.module.scss';
import { faCloudDownload, faEye } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { splitFileExt } from 'src/helpers/files';
import { isPreviewableFile } from 'src/models/procurements/helpers';

export const toTreeData = (
  fileCategories: IProcurementFileCategory[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  t: any,
  isDisabledFn: (file: IProcurementFile) => { disabled: boolean; tooltips?: string },
  getFile: (path: string) => void,
  previewFile: (targetPath: string) => boolean,
  onShowDocument: (val: string) => void
): DataNode[] => {
  const toChildrenFile = (file: IProcurementFile, fileIndex: number, indexConcat: string): DataNode => {
    const isFile = !file.children || file.children.length === 0;
    const { disabled, tooltips } = isDisabledFn(file);
    const title = file.directoryName || file.fileName;
    const indexConcatNext = `${indexConcat}-${fileIndex}`;
    const key = isFile ? file.targetPath : indexConcatNext;
    const [, fileExt] = splitFileExt(file.targetPath);
    const fileActions = (
      <>
        <Button
          type={'text'}
          className={classNames(styles.docAction, styles.fileAction)}
          onClick={e => {
            e.stopPropagation();
            file.targetPath && getFile(file.targetPath);
          }}
          icon={<FontAwesomeIcon icon={faCloudDownload} />}
        />
        {isPreviewableFile(fileExt) && (
          <Button
            type={'text'}
            className={classNames(styles.docAction, styles.fileAction)}
            onClick={e => {
              e.stopPropagation();
              file.targetPath && previewFile(file.targetPath) && onShowDocument(file.targetPath);
            }}
            icon={<FontAwesomeIcon icon={faEye} />}
          />
        )}
      </>
    );
    return {
      key,
      disableCheckbox: disabled,
      checkable: isFile,
      isLeaf: isFile,
      title: isFile ? (
        <>
          <Tooltip title={disabled && tooltips ? tooltips : title}>
            <span className={classNames(styles.name, { [styles.disabled]: disabled && tooltips })}>{title}</span>
          </Tooltip>
          {fileActions}
        </>
      ) : (
        <span className={styles.folderTitle}>{`${title} (${file.children?.length})`}</span>
      ),
      switcherIcon: ({ expanded }) => !isFile && <SwitcherIcon expanded={!!expanded} />,
      children: file.children?.map((fileNode, index) => toChildrenFile(fileNode, index, indexConcatNext))
    };
  };
  return fileCategories.map((fileCategory, index) => {
    return {
      key: `${index}`,
      checkable: false,
      title: (
        <Tooltip title={t(`Tenders.FileCategory.${fileCategory.description}`)}>
          <span className={styles.folderTitle}>{`${t(`Tenders.FileCategory.${fileCategory.name}`)} (${
            fileCategory.children.length
          })`}</span>
        </Tooltip>
      ),
      switcherIcon: ({ expanded }) => <SwitcherIcon expanded={!!expanded} />,
      children: fileCategory.children.map((file, fileIndex) =>
        toChildrenFile(file, fileIndex, `${index}-${fileIndex}`)
      ),
      className: styles.publicFiles
    };
  });
};
