import React, { FC } from 'react';
import { Button, EmptyFilter } from 'src/common';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { router } from 'src';

export const ErrorPage: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.layout}>
      <div className={styles.content}>
        <EmptyFilter title={t('ErrorBoundary.title')} desc={t('ErrorBoundary.desc')}>
          <Button
            type={'primary'}
            onClick={() => {
              router.navigate('/', { replace: true });
              window.location.reload();
            }}
          >
            {t('Common.back')}
          </Button>
        </EmptyFilter>
      </div>
    </div>
  );
};

export default ErrorPage;
