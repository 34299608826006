import { z, ZodError } from 'zod';
import dayjs from './dayjs';
import { Dayjs } from 'dayjs';

export const noEmptyString = z.string().refine(val => val.trim() !== '');

export const stringLike = z.string().or(z.number()).pipe(z.coerce.string());

export const numberLike = z.number().or(noEmptyString).pipe(z.coerce.number()); // Because Number('') === 0

export const oNumberLike = z.preprocess(value => (value === '' ? undefined : value), numberLike.optional());

export const nonNegativeNumberLike = z.number().or(noEmptyString).pipe(z.coerce.number().nonnegative());

export const oNonNegativeNumberLike = z.preprocess(
  value => (value === '' ? undefined : value),
  nonNegativeNumberLike.optional()
);

export const intLike = z.number().or(noEmptyString).pipe(z.coerce.number().int());

export const oIntLike = z.preprocess(value => (value === '' ? undefined : value), intLike.optional());

export const nonNegativeIntLike = z.number().or(noEmptyString).pipe(z.coerce.number().int().nonnegative());

export const oNonNegativeIntLike = z.preprocess(
  value => (value === '' ? undefined : value),
  nonNegativeIntLike.optional()
);

export const booleanLike = z.preprocess(value => {
  if (value === 'false') return false;
  if (value === 'true') return true;
  return value;
}, z.boolean());
export const dateLike = z.preprocess(value => {
  const isDateString = typeof value === 'string' && !isNaN(Date.parse(value));
  const isTimestamp = typeof value === 'number';
  if (isDateString || isTimestamp) {
    return dayjs.utc(value).toDate();
  }
  return value;
}, z.date());

export const epochDateRangeLike = z.array(numberLike).length(2);

export const DayJSTypeForZod = dayjs as unknown as typeof Dayjs;
export const dateRangeSchema = z.tuple([z.instanceof(DayJSTypeForZod), z.instanceof(DayJSTypeForZod)]);

export function zodParseErrorHandler<T>(from: string, error: ZodError<T>): void {
  console.error(from + ': parse failed', error);
}
