import React, { FC, useCallback } from 'react';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { useBidPreview } from 'src/models/bids/BidPreview/hooks';
import { TenderBox, BlockTitle } from 'src/shared';
import { TenderBlockSize, TenderBoxMode } from 'src/models/procurements/Tender/types';
import { faPen, faPlus } from '@fortawesome/pro-light-svg-icons';
import { Button } from 'src/common';
import { useNavigate } from 'react-router';
import { Paths } from 'src/pages/paths';
import { useOverviewBackLink } from 'src/models/procurements/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const CustomBoxes: FC = () => {
  const { t } = useTranslation();
  const { data } = useBidPreview();

  const { set: setBackLink } = useOverviewBackLink();
  const navigate = useNavigate();

  const origin = data?.origin;
  const customBoxes = origin?.customBoxes;

  const onClick = useCallback(() => {
    setBackLink();
    navigate(`${Paths.SETTINGS_PAGE}${Paths.SETTINGS_PAGE_CUSTOM_BID_FIELDS}`);
  }, [navigate, setBackLink]);

  if (!data) return null;

  return (
    <>
      <BlockTitle
        title={t('BiddingTool.myOpportunityFields')}
        icon={{ defaultIcon: faPen, color: 'var(--ui-cyan)' }}
        className={styles.blockTitle}
        noBorder
      />
      {!!customBoxes?.length ? (
        customBoxes.map(box => (
          <TenderBox
            title={box.title}
            isEditable={true}
            actions={false}
            boxes={[box]}
            as={TenderBoxMode.Collection}
            key={box.id}
            size={TenderBlockSize.Third}
          />
        ))
      ) : (
        <Button type="default" onClick={onClick} icon={<FontAwesomeIcon icon={faPlus} />} className={styles.button}>
          {t('CustomBidFields.addNewBidField')}
        </Button>
      )}
    </>
  );
};

export default CustomBoxes;
