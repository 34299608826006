import React, { FC, KeyboardEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../Item/index.module.scss';
import { ChecklistItemStatus } from 'src/models/bids/BidChecklist/types';
import classNames from 'classnames';
import { Form, Input, Button } from 'src/common';
import { useForm } from 'antd/lib/form/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { useBidChecklist, useCreateChecklistItem } from 'src/models/bids/BidChecklist/hooks';
import { trackAddChecklistItem } from 'src/segment/events/checklist';
const { TextArea } = Input;

interface Props {
  onCloseForm: (createAnother: boolean) => void;
}

export const Item: FC<Props> = props => {
  const { onCloseForm } = props;
  const { t } = useTranslation();
  const [form] = useForm();
  const [createItem] = useCreateChecklistItem();

  const { data: model } = useBidChecklist();
  const bidId = model?.id;

  const onAddNewItem = useCallback(() => {
    const itemName = form.getFieldValue('itemName').trim();
    bidId &&
      createItem({
        bidId,
        name: itemName,
        description: '',
        assignedTo: null,
        status: ChecklistItemStatus.ToDo,
        deadline: null
      });
    form.resetFields();
    trackAddChecklistItem();
  }, [createItem, form, bidId]);

  const onCancel = useCallback(() => {
    form.resetFields();
    onCloseForm(false);
  }, [onCloseForm, form]);

  const onBlurItemInput = useCallback(() => {
    const itemName = form.getFieldValue('itemName')?.trim() || null;
    if (!!itemName) {
      onAddNewItem();
      onCloseForm(true);
    } else {
      onCancel();
    }
  }, [form, onAddNewItem, onCloseForm, onCancel]);

  const onEnter = useCallback(
    (e: KeyboardEvent<HTMLTextAreaElement>) => {
      e.preventDefault();
      e.stopPropagation();
      onAddNewItem();
    },
    [onAddNewItem]
  );

  return (
    <div className={classNames(styles.item, styles.newItemForm)} id="new-item-form">
      <div className={styles.itemInner}>
        <Form form={form} className={classNames(styles.itemName, styles.itemNameForm)}>
          <Form.Item name={'itemName'} label={''} className={styles.itemNameInner}>
            <TextArea
              bordered={false}
              autoSize={true}
              placeholder={t('Checklist.addChecklistItem')}
              autoFocus={true}
              className={styles.itemInput}
              onPressEnter={onEnter}
              onBlur={onBlurItemInput}
            />
          </Form.Item>

          <Form.Item label={''} className={styles.itemEditControls}>
            <Button onClick={onAddNewItem} type={'primary'}>
              {t('Common.save')}
            </Button>

            <button className={styles.deleteButton} onClick={onCancel}>
              <FontAwesomeIcon icon={faTimes} className={styles.deleteIcon} />
            </button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Item;
