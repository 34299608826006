import { KeywordWidth, SearchType } from '@tendium/prom-types';
import { ADVANCED_FILTER_LOGIC, DOCUMENTS_SEARCH_TYPE } from 'src/models/matchingProfiles/types';

export function checkIfOptionIsEnabled(
  keywordWidth: KeywordWidth,
  searchType: SearchType,
  MPBroadOrNarrowSearchFlag: boolean | null
): boolean {
  if (DOCUMENTS_SEARCH_TYPE.includes(searchType)) {
    // document search with broad/narrow filter is feature flagged
    if (ADVANCED_FILTER_LOGIC.includes(keywordWidth)) {
      return !!MPBroadOrNarrowSearchFlag;
    }
  }
  // no restrictions for other settings
  return true;
}
