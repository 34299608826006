import React, { FC, useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-light-svg-icons';
import { IBoxFieldCurrency } from 'src/models/procurements/Tender/types';
import styles from './index.module.scss';
import { useKey } from 'react-use';
import EditableBlockCurrency from './EditableBlock';
import ReadOnlyBlockCurrency from './ReadOnlyBlock';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import AddValueButton from '../AddValueButton';
import { IBlockProps, FieldViewMode } from 'src/shared/Tender/Boxes/types';

interface Props extends IBlockProps<IBoxFieldCurrency> {}
export const BlockCurrency: FC<Props> = ({ field, onChange, viewMode, isEditable, config }) => {
  const { t } = useTranslation();
  const [currentMode, setCurrentMode] = useState(viewMode || FieldViewMode.Read);

  const onToggleView = useCallback(() => {
    isEditable && setCurrentMode(prev => (prev === FieldViewMode.Read ? FieldViewMode.Edit : FieldViewMode.Read));
  }, [isEditable]);

  const switchToReadMode = useCallback(() => {
    if (isEditable && currentMode === FieldViewMode.Edit) {
      setCurrentMode(FieldViewMode.Read);
    }
  }, [currentMode, isEditable]);

  useKey('Escape', () => setCurrentMode(FieldViewMode.Read));

  const onFinish = useCallback(
    (val: IBoxFieldCurrency) => {
      onChange?.(val);
      switchToReadMode();
    },
    [onChange, switchToReadMode]
  );
  return (
    <>
      {currentMode === FieldViewMode.Edit && (
        <EditableBlockCurrency field={field} onChange={onFinish} onFinish={switchToReadMode} />
      )}

      {currentMode === FieldViewMode.Read && (
        <>
          {!!field.amount || !!field.currency ? (
            <div className={classNames(styles.toggler, { [styles.isEditable]: isEditable })} onClick={onToggleView}>
              <ReadOnlyBlockCurrency amount={field.amount} currency={field.currency} config={config} />
              {isEditable && (
                <div className={styles.togglerIcon}>
                  <FontAwesomeIcon icon={faPen} />
                </div>
              )}
            </div>
          ) : (
            <AddValueButton
              onToggleView={onToggleView}
              isEditable={!!isEditable}
              title={t('Common.Blocks.addCurrency')}
            />
          )}
        </>
      )}
    </>
  );
};

export default BlockCurrency;
