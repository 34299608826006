import { useEffect } from 'react';
import { Config } from '../config/config';
import * as sessionReplay from '@amplitude/session-replay-browser';

export const useSessionReplay = (): void => {
  const sessionId = localStorage.getItem('analytics_session_id') ?? undefined;

  useEffect(() => {
    if (typeof analytics === 'undefined' || typeof analytics.user !== 'function') {
      return;
    }

    try {
      const anonymousId = analytics.user().anonymousId();
      const initSessionReplay = async (): Promise<void> => {
        await sessionReplay.init(Config.AmplitudeApiKey, {
          deviceId: anonymousId,
          sessionId: sessionId,
          optOut: false,
          sampleRate: 1
        }).promise;

        sessionReplay.setSessionId(sessionId ?? '');
      };
      initSessionReplay();
    } catch (error) {
      console.error('Error initializing session replay', error);
    }
  }, [sessionId]);
};

export default useSessionReplay;
