import React, { FC } from 'react';
import { Button } from 'src/common';
import BlockEmpty from '../Empty';
import { useTranslation } from 'react-i18next';

interface Props {
  isEditable: boolean;
  title?: string;
  onToggleView: () => void;
}

export const AddValueButton: FC<Props> = props => {
  const { isEditable, title, onToggleView } = props;
  const { t } = useTranslation();

  return (
    <>
      {isEditable ? (
        <Button type={'link'} onClick={onToggleView}>
          {title || t('Common.Blocks.addValue')}
        </Button>
      ) : (
        <BlockEmpty />
      )}
    </>
  );
};

export default AddValueButton;
