import React, { FC, useCallback } from 'react';
import ReactMarkdown from 'src/common/Markdown';
import styles from './index.module.scss';
import { Button, Tooltip } from 'src/common';
import { useTranslation } from 'react-i18next';
import { AiDisclaimer } from 'src/common/AiDisclaimer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStars } from '@fortawesome/pro-solid-svg-icons';
import { useOpenSidebar } from 'src/shared/InfoSidebar/hooks';
import { SidebarMode } from 'src/shared/InfoSidebar/types';
import { faBookOpen } from '@fortawesome/pro-thin-svg-icons';
import { usePreviewTabState } from 'src/models/procurements/hooks';
import { ActivePreviewTab } from 'src/models/procurements/types';

interface Props {
  summary: string;
  mode: 'taskPreview' | 'canvas';
  bidId?: string;
  sourcesCount?: number;
}

export const TaskSummary: FC<Props> = ({ summary, bidId, sourcesCount, mode }) => {
  const { t } = useTranslation();
  const openSidebar = useOpenSidebar();
  const [, setActivePreviewTab] = usePreviewTabState();

  const onClick = useCallback(() => {
    if (mode === 'taskPreview') return setActivePreviewTab(ActivePreviewTab.RelatedSources);
    if (mode === 'canvas') return openSidebar({ id: bidId ?? null, mode: SidebarMode.TOOLBOX_RELATED_SOURCES });
  }, [bidId, mode, openSidebar, setActivePreviewTab]);

  if (!summary) return null;

  return (
    <div className={styles.summary}>
      <div className={styles.expand}>
        <div className={styles.summaryContent}>
          <Tooltip title={t('BiddingTool.aiTaskSummary')}>
            <FontAwesomeIcon icon={faStars} size="xl" className={styles.starsIcon} />
          </Tooltip>
          <ReactMarkdown>{summary}</ReactMarkdown>
        </div>
        <div className={styles.containerInner}>
          <AiDisclaimer />
          {!!sourcesCount && sourcesCount > 0 && (
            <Button onClick={onClick} type={'link'} icon={<FontAwesomeIcon icon={faBookOpen} />}>
              <span>
                {sourcesCount} {t('BiddingTool.sourcesCount')}
              </span>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default TaskSummary;
