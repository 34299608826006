import { gql } from '@apollo/client';
import { COMMENT_FIELDS } from 'src/models/comments/queries';
import { ASSIGNED_TO_FIELDS } from 'src/models/users/AssignedTo/queries';
import { USER_NAME_FIELDS } from 'src/models/users/queries';

export const GET_LINKED_BID = gql`
  query getBid($bidId: String!) {
    getBid(input: { bidId: $bidId }) {
      id
      bidStageId
      createdAt
      createdBy {
        ...userNameFields
      }
      comments {
        ...commentFields
      }
      status {
        id
        name
      }
      assignedTo {
        ...assignedToFields
      }
      workspace {
        id
        name
        color
      }
      isRejected
      itemName
    }
  }
  ${USER_NAME_FIELDS}
  ${COMMENT_FIELDS}
  ${ASSIGNED_TO_FIELDS}
`;
