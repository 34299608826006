import React, { FC, useCallback, useState } from 'react';
import { Button, Modal, notification } from 'src/common';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowDown } from '@fortawesome/pro-light-svg-icons';
import {
  useBulkImportContentLibraryUrl,
  useDownloadCardsCsvTemplate
} from 'src/models/contentLibrary/ContentLibrary/hooks';
import { downloadFile } from 'src/helpers/files';
import UploadFile from '../UploadFile';
import { ownership } from 'src/models/ownership';
import styles from './index.module.scss';
import { ApiContentLibraryUploadRequest } from 'src/models/contentLibrary/ContentLibrary/types';
import { FileExt } from 'src/models/file/types';

interface Props {
  onClose: () => void;
  extensions: FileExt[];
}

export const ImportCsvModal: FC<Props> = props => {
  const { onClose, extensions } = props;
  const { t } = useTranslation();

  const [getSignedUrlDownloadTemplate] = useDownloadCardsCsvTemplate();
  const [getUrlBulk] = useBulkImportContentLibraryUrl();

  const [showTemplateDownload, setShowTemplateDownload] = useState<boolean>(true);

  const downloadTemplate = async (): Promise<void> => {
    try {
      const result = await getSignedUrlDownloadTemplate();
      if (result.data && result.data.getSignedUrlDownloadTemplateContentLibrary) {
        const signedUrl = result.data.getSignedUrlDownloadTemplateContentLibrary;
        downloadFile(signedUrl, 'Template');
      } else {
        notification.error({
          description: t('Common.unknownErrorDesc'),
          message: t('Common.unknownError')
        });
      }
    } catch (err) {
      notification.error({
        description: t('Common.unknownErrorDesc'),
        message: t('Common.unknownError')
      });
    }
  };

  const onUpload = useCallback(
    async (queryVariable: ApiContentLibraryUploadRequest): Promise<{ signedUrl: string } | undefined> => {
      try {
        const response = await getUrlBulk({ variables: queryVariable });
        const signedUrl = response?.data?.getSignedUrlBulkImportContentLibrary?.signedUrl;

        if (!signedUrl) {
          return;
        }

        return { signedUrl };
      } catch (err) {
        notification.error({
          description: t('Common.unknownErrorDesc'),
          message: t('Common.unknownError')
        });
        return;
      }
    },
    [getUrlBulk, t]
  );

  const instructions: string[] = t('ContentLibrary.contentUploadModals.intro.instructions', {
    returnObjects: true,
    count: 1000
  });

  return showTemplateDownload ? (
    <Modal
      title={t('ContentLibrary.contentUploadModals.importContentCards')}
      visible
      width={690}
      onCancel={onClose}
      footer={
        <>
          <Button type="text" onClick={onClose}>
            {t('Common.cancel')}
          </Button>
          <Button onClick={() => setShowTemplateDownload(false)} type={'primary'}>
            {t('Common.next')}
          </Button>
        </>
      }
    >
      <div>
        <div className={styles.desc}> {t('ContentLibrary.contentUploadModals.intro.desc')}</div>
        <ul>
          {instructions.map((instruction, index) => (
            <li key={index}>{instruction}</li>
          ))}
        </ul>
        <Button type="link" onClick={downloadTemplate}>
          <FontAwesomeIcon icon={faCloudArrowDown} />
          {t('ContentLibrary.contentUploadModals.intro.downloadTemplate', { ownership: ownership.name })}
        </Button>
      </div>
    </Modal>
  ) : (
    <UploadFile
      extensions={extensions}
      onClose={onClose}
      onUpload={onUpload}
      desc={t('ContentLibrary.contentUploadModals.acceptedFileFormats')}
    />
  );
};
export default ImportCsvModal;
