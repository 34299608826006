import React, { FC, useCallback, useMemo, useState } from 'react';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { Button, DatePicker, Form, UserPicker } from 'src/common';
import classNames from 'classnames';
import { useWsUsersAndTeams } from 'src/models/workspace/hooks';
import TextArea from 'antd/lib/input/TextArea';
import { TimePicker } from 'antd';
import { useCreateReminder, useReminders } from 'src/models/reminders/hooks';
import { ReminderType } from '@tendium/prom-types/schema';
import moment from 'moment';
import { AssignedTo } from 'src/models/users/types';
import { useBidPreview } from 'src/models/bids/BidPreview/hooks';
import { isFieldString } from 'src/models/procurements/Tender/types';
interface FormState {
  message: string;
  date: moment.Moment;
  time: moment.Moment;
  assignedTo: string;
}

export const RemindersInput: FC = () => {
  const { t } = useTranslation();
  const [createReminder] = useCreateReminder();
  const { id, wsId } = useReminders();
  const { data: bidPreviewData } = useBidPreview();

  const wsUsers = useWsUsersAndTeams(wsId);

  const [form] = Form.useForm<FormState>();

  const nameBox = bidPreviewData?.origin.name;
  const name = useMemo(
    () =>
      nameBox && nameBox.firstField && isFieldString(nameBox.firstField) ? nameBox.firstField?.string?.toString() : '',
    [nameBox]
  );

  const [assignedTo, setAssignedTo] = useState<AssignedTo | undefined>(undefined);
  const onAssign = useCallback(
    (user: AssignedTo) => {
      setAssignedTo(user);
      form.setFieldsValue({ assignedTo: user?.id });
    },
    [form]
  );

  const onCancel = useCallback(() => {
    form.resetFields();
    setAssignedTo(undefined);
  }, [form]);

  const onFinish = useCallback(() => {
    const values: FormState = form.getFieldsValue();
    const { message, date, time, assignedTo } = values;
    const dueDate = moment(date.format('YYYY-MM-DD') + 'T' + time.format('HH:mm:ss')).toDate();

    createReminder({
      message,
      dueDate,
      title: name || '',
      type: ReminderType.Bid,
      relationId: id,
      assignedTo
    });

    onCancel();
  }, [form, createReminder, name, id, onCancel]);

  const initialValues: Partial<FormState> = {
    date: moment().startOf('day'),
    time: moment().startOf('hour').add(1, 'hour')
  };

  return (
    <Form form={form} onFinish={onFinish} className={styles.form} initialValues={initialValues}>
      <div>
        <Form.Item
          name="message"
          rules={[{ required: true, message: t('Reminders.required') }]}
          className={styles.textArea}
        >
          <TextArea autoSize={{ minRows: 3 }} placeholder={t('Reminders.inputPlaceholder')} />
        </Form.Item>
      </div>
      <div className={styles.container}>
        <div className={styles.actionsContainer}>
          <Form.Item
            name="date"
            rules={[{ required: true, message: t('Reminders.required') }]}
            className={styles.formItem}
          >
            <DatePicker clearIcon={false} />
          </Form.Item>
          <Form.Item
            name="time"
            rules={[{ required: true, message: t('Reminders.required') }]}
            className={styles.formItem}
          >
            <TimePicker format={'HH:mm'} clearIcon={false} />
          </Form.Item>
          <Form.Item
            name="assignedTo"
            rules={[{ required: true, message: t('Reminders.required') }]}
            className={classNames(styles.formItem, styles.assigned)}
          >
            <UserPicker
              onSelect={onAssign}
              isUpdating={false}
              size={'m'}
              mode={'short'}
              users={wsUsers}
              selected={assignedTo?.id}
              userPickerType="single"
              label={t('Common.unassigned')}
              tooltip={t('Common.assignTo')}
            />
          </Form.Item>
        </div>
        <div className={styles.actionsContainer}>
          <Button type={'text'} onClick={onCancel}>
            {t('Common.cancel')}
          </Button>
          <Button type={'primary'} htmlType={'submit'}>
            {t('Reminders.addReminder')}
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default RemindersInput;
