import { Editor } from '@tiptap/core';
import { CUSTOM_NODE_NAMES } from '../constants';

const getBidTaskNodePosition = (editor: Editor | null): { from: number; to: number } | null => {
  if (!editor) return null;
  const { selection } = editor.state;
  let { $anchor } = selection;

  // Search for the closest BidTaskNode
  while ($anchor.depth > 0) {
    const currentNode = $anchor.node($anchor.depth);
    if (currentNode.type.name === CUSTOM_NODE_NAMES.bidTask) {
      // Resolve the start and end positions of the node using `doc.resolve`
      const from = $anchor.before(); // The starting position of the node
      const to = from + currentNode.nodeSize; // The ending position of the node
      return { from, to };
    }
    $anchor = editor.state.doc.resolve($anchor.before());
  }

  return null;
};

export default getBidTaskNodePosition;
