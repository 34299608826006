import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { AiBadge } from 'src/common';
import ProcurementCard from 'src/common/ProcurementCard';
import { IGenericSubCat, TenderBlockSize } from 'src/models/procurements/Tender/types';
import { TenderSubCat } from 'src/shared';
import styles from './index.module.scss';
import { useGetPreviousTenders } from 'src/models/procurements/PreviousTenders/hooks';
import { useIsMobile } from 'src/reactiveVars';
import { Paths } from 'src/pages/paths';
import classNames from 'classnames';

export const PREVIOUS_PROCUREMENTS_SUB_CAT: IGenericSubCat = {
  id: 'PREVIOUS_PROCUREMENTS',
  title: 'PREVIOUS_PROCUREMENTS',
  badge: <AiBadge />,
  size: TenderBlockSize.Full
};
interface Props {
  procurementId: string;
  onClickAction?: (id: string) => void;
  withoutSection?: boolean;
  isActiveId?: string;
}

export const PreviousProcurements: FC<Props> = props => {
  const { procurementId, onClickAction, withoutSection, isActiveId } = props;
  const { t } = useTranslation();
  const [isMobile] = useIsMobile();

  const { data: previousProcurements } = useGetPreviousTenders(procurementId);

  const onOpenTender = useCallback((id: string) => {
    window.open(`${Paths.TENDER_ROUTE}${id}`, '_blank');
  }, []);

  const onClick = useCallback(
    (id: string) => {
      if (onClickAction) {
        onClickAction(id);
      } else {
        onOpenTender(id);
      }
    },
    [onClickAction, onOpenTender]
  );

  const previousProcurementsContent = previousProcurements.map(previousProcurement => (
    <ProcurementCard
      key={previousProcurement.id}
      id={previousProcurement.id}
      title={previousProcurement.title}
      buyers={previousProcurement.buyers}
      suppliers={previousProcurement.suppliers}
      contractStart={previousProcurement.contractStart}
      contractEnd={previousProcurement.contractEnd}
      onClick={() => onClick(previousProcurement.id)}
      isActive={previousProcurement.id === isActiveId}
    />
  ));

  if (!previousProcurements.length) return null;

  return !!withoutSection ? (
    <div className={classNames(styles.cardsContainer, styles.withoutSection)}>
      <div className={classNames(styles.desc, styles.withoutSection)}>{t('Tenders.previousProcurementDesc')}</div>
      {previousProcurementsContent}
    </div>
  ) : (
    <TenderSubCat subCat={PREVIOUS_PROCUREMENTS_SUB_CAT}>
      <span className={isMobile ? styles.descMobile : styles.desc}>{t('Tenders.previousProcurementDesc')}</span>
      <div className={styles.cardsContainer}>{previousProcurementsContent}</div>
    </TenderSubCat>
  );
};

export default PreviousProcurements;
