import { CREATE_TASKS_TITLE_LENGTH } from './types';

export function getTasksTitle(task: string): string {
  return task.length > CREATE_TASKS_TITLE_LENGTH ? `${task.slice(0, CREATE_TASKS_TITLE_LENGTH)}...` : task;
}

import { TasksFilterArgs } from 'src/models/bids/BidFull/types';
import { BidTask } from 'src/models/bids/BidTask';

export const doesTaskMatchFilters = (
  task: BidTask | null,
  filter: TasksFilterArgs | undefined,
  isTasksFilterApplied: boolean
): boolean => {
  if (!isTasksFilterApplied) return true;
  if (!task) return false;

  // Status Filter Applied: Task must be in the selected statuses
  if (filter?.status?.length && !filter.status.includes(task.status)) return false;

  // Requirement Filter Applied: Task must have a non-null requirement that matches one in the filter
  if (
    filter?.requirementType?.length &&
    (!task.requirementType || !filter.requirementType.includes(task.requirementType))
  )
    return false;

  // Fulfillment Filter Applied: Task must have a non-null fulfillment that matches one in the filter
  if (filter?.fulfillment?.length && (!task.fulfilment || !filter.fulfillment.includes(task.fulfilment))) return false;

  // Assigned To Filter Applied: Task must have a non-null assignedTo that matches one in the filter
  if (filter?.assignedTo?.length && (!task.assignedTo || !filter.assignedTo.includes(task.assignedTo))) return false;

  return true; // Task passes all filters
};
