import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';

export const TaskGroupTableHeader: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.heading}>
      <div className={styles.title}>{t('Common.title')}</div>

      <div className={styles.status}>{t('BiddingTool.status')}</div>
      <div className={styles.fulfillment}>{t('BiddingTool.compliance')}</div>
      <div className={styles.requirementType}>{t('BiddingTool.requirement')}</div>
      <div className={styles.date}>{t('BidSpaces.BidsSort.deadline')}</div>
      <div className={styles.assignedTo}>{t('BiddingTool.assigned')}</div>
    </div>
  );
};

export default TaskGroupTableHeader;
