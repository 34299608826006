import React, { FC, useMemo, useRef } from 'react';
import styles from './index.module.scss';
import { BidTaskRow } from 'src/shared';
import classNames from 'classnames';
import { DndType } from 'src/types/dnd';
import { BidTaskRowProps } from 'src/shared/Bid/BidTaskRow';
import { useDndSortDrop, useTasksFilter } from 'src/models/bids/BidFull/hooks';
import { useDrag } from 'react-dnd';
import { isTasksFilterApplied } from 'src/models/bids/BidFull/helpers';
import { useClickAway } from 'react-use';

interface Props extends BidTaskRowProps {
  dndId: number;
  index: number;
  dropTask: (dragIndex: number, hoverIndex: number) => void;
  moveTask: (dragIndex: number, hoverIndex: number) => void;
}

export const DndTask: FC<Props> = ({
  task,
  selectedTasks,
  onChangeSelection,
  bidId,
  wsId,
  dndId,
  index,
  dropTask,
  moveTask,
  newTask,
  onExitNewTask
}) => {
  const { filter } = useTasksFilter();
  const isFilterApplied = useMemo(() => isTasksFilterApplied(filter), [filter]);

  const ref = useRef<HTMLDivElement>(null);
  const [, drop] = useDndSortDrop(index, ref, dropTask, moveTask, DndType.BidTaskItem);

  const [{ isDragging }, dragTaskToGroup] = useDrag({
    type: DndType.BidTaskItem,
    item: { type: DndType.BidTaskItem, dndItem: task, dndId: dndId, index: index },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    }),
    canDrag: !selectedTasks.length && !isFilterApplied
  });

  drop(ref);
  dragTaskToGroup(ref);

  useClickAway(ref, () => {
    onExitNewTask && onExitNewTask();
  });

  return task ? (
    <div className={classNames(styles.taskContainer)} ref={ref}>
      <BidTaskRow
        task={task}
        selectedTasks={selectedTasks}
        bidId={bidId}
        wsId={wsId}
        newTask={newTask}
        isDragging={isDragging}
        onExitNewTask={onExitNewTask}
        onChangeSelection={onChangeSelection}
      />
    </div>
  ) : null;
};

export default DndTask;
