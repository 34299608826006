import CharacterCount from '@tiptap/extension-character-count';
import TaskItem from '@tiptap/extension-task-item';
import TaskList from '@tiptap/extension-task-list';
import taskListStyles from 'src/pages/CanvasPage/CanvasEditor/components/TaskList/index.module.scss';
import TextAlign from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';
import StarterKit from '@tiptap/starter-kit';
import { Markdown } from 'tiptap-markdown';
import Link from '@tiptap/extension-link';
import Highlight from '@tiptap/extension-highlight';
import highlightStyles from 'src/pages/CanvasPage/CanvasEditor/components/Highlight/index.module.scss';
import BidTaskNode from './customNodes/bidTask/BidTaskNode';

export const CANVAS_EXTENSIONS = [
  StarterKit.configure({
    code: false,
    codeBlock: false,
    gapcursor: false
  }),
  BidTaskNode, // Custom node
  CharacterCount,
  Underline,
  Link,
  TextAlign.configure({
    types: ['heading', 'paragraph']
  }),
  TaskList.configure({
    HTMLAttributes: {
      class: taskListStyles.taskList
    }
  }),
  TaskItem.configure({
    HTMLAttributes: {
      class: taskListStyles.taskListItem
    }
  }),
  Markdown,
  Highlight.configure({
    HTMLAttributes: {
      class: highlightStyles.highlight
    }
  })
];
