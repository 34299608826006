import { TenderNavCats, TenderNavInfo, TenderNavResponseData } from './types';
import { convertApiLotsToLots, filterBoxes, toDocsSubCats } from '../Tender/helpers';
import { CAT_ICON_MAPPER, CATS_ORDER } from '../Tender/mappers';
import { IBidIdentifier } from 'src/models/bids/Bids/types';
import { isNotUndefined } from 'src/helpers';
import { TenderBox, TenderBoxFactory } from '..';
import { fieldFilter, getSortedBuyerBoxes } from '../helpers';
import { IGenericCat, IProcurementFileCategory, ITenderLot, TenderCatName } from '../Tender/types';

export default class TenderNavData {
  readonly id: string;
  readonly bids?: IBidIdentifier[];
  readonly isBfProject: boolean;
  readonly cats: TenderNavCats;
  readonly info: TenderNavInfo;
  readonly filesCats: IProcurementFileCategory[];
  private tenderLots: ITenderLot[] = [];
  constructor(private readonly apiTender: TenderNavResponseData) {
    this.id = apiTender.id;
    this.bids = this.toBids();
    this.isBfProject = !!this.apiTender.bids.length && !!this.apiTender.bids[0].byggfaktaProject;
    this.cats = this.toCats();
    this.info = this.toInfo();
    this.filesCats = this.toDocCat();
    this.tenderLots = convertApiLotsToLots(this.apiTender.lots);
  }

  private toGenericCat(catName: TenderCatName | string): IGenericCat {
    return {
      id: catName,
      title: catName,
      icon: CAT_ICON_MAPPER[catName] || CAT_ICON_MAPPER['OTHER']
    };
  }
  private toBids(): IBidIdentifier[] | undefined {
    if (this.apiTender.bids.length > 0)
      return this.apiTender.bids.map(bid => ({
        id: bid.id,
        wsId: bid.workspace.id
      }));
    else return undefined;
  }

  private toCats(): TenderNavCats {
    const filteredBoxes = this.apiTender.boxes
      .filter(box => !box.category.startsWith(TenderCatName.Overview))
      .filter(box => box.fields.filter(field => fieldFilter(field)).filter(isNotUndefined))
      .filter(box => !!box.fields.length);
    const cats = [
      ...filteredBoxes
        .reduce((catMap, box) => {
          const [catId] = box.category.split('.');
          if (!catMap.has(catId)) {
            catMap.set(catId, {
              id: catId,
              title: catId,
              icon: CAT_ICON_MAPPER[catId] ?? CAT_ICON_MAPPER['OTHER']
            });
          }
          return catMap;
        }, new Map<string, IGenericCat>())
        .values()
    ].sort((aCat: IGenericCat, bCat: IGenericCat) => {
      const aCatIdx = CATS_ORDER.findIndex(catOrder => catOrder === aCat.title);
      const bCatIdx = CATS_ORDER.findIndex(catOrder => catOrder === bCat.title);
      return aCatIdx - bCatIdx;
    });
    return {
      tenderCats: [this.toGenericCat(TenderCatName.Overview), ...cats, this.toGenericCat(TenderCatName.Docs)],
      keywordSearchCat: this.toGenericCat(TenderCatName.KeywordSearch),
      biddingCat: this.toGenericCat(TenderCatName.Bidding)
    };
  }

  private toInfo(): TenderNavInfo {
    return {
      name: this.apiTender.general.name ? new TenderBox(this.apiTender.general.name) : undefined,
      estimatedValue: !!this.apiTender.general.contractValueBoxes.length
        ? filterBoxes(this.apiTender.general.contractValueBoxes, this.tenderLots)[0]
        : undefined,
      linkToBuyer: !!filterBoxes(this.apiTender.general.linkToProcurerBoxes, this.tenderLots).length
        ? filterBoxes(this.apiTender.general.linkToProcurerBoxes, this.tenderLots)[0]
        : new TenderBoxFactory().create('linkToProcurerBoxes'),
      buyers: filterBoxes(getSortedBuyerBoxes(this.apiTender.general.buyerBoxes), this.tenderLots),
      published: this.apiTender.timeline.availableDate
        ? new TenderBox(this.apiTender.timeline.availableDate)
        : undefined,
      deadline: this.apiTender.timeline.deadline
        ? filterBoxes(this.apiTender.timeline.deadline, this.tenderLots)[0]
        : undefined,
      assignedTo: this.apiTender.assignedTo ? this.apiTender.assignedTo.id : null,
      isStarred: this.apiTender.isStarred,
      procurementStatus: this.apiTender.procurementStatus,
      isPlanningProcess: this.apiTender.isPlanningProcess
    };
  }

  private toDocCat(): IProcurementFileCategory[] {
    return toDocsSubCats(this.apiTender.fileCategories).fileCategories || [];
  }
}
