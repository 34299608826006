import React, { FC } from 'react';
import styles from './index.module.scss';
import { Button } from 'src/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareXmark } from '@fortawesome/pro-light-svg-icons';

export interface Props {
  onCancel: () => void;
}
export const ValueActions: FC<Props> = ({ onCancel }) => {
  return (
    <div className={styles.actions}>
      <Button
        type={'text'}
        icon={<FontAwesomeIcon icon={faSquareXmark} className={styles.deleteIcon} />}
        onClick={onCancel}
      />
    </div>
  );
};

export default ValueActions;
