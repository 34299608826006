import { trackWithSessionReplay } from '../helpers';

/** Compliance, Status, Response, Requirement */
export type TaskTrackEventSource =
  | 'Bidding Tool page' /**@deprecated*/
  | 'Task Details Page' /**@deprecated*/
  | 'Canvas'
  | 'Bid Document Draft Page'
  | 'Group'
  | 'Onboarding'
  | 'Overview'
  | 'Task Preview';
export type CreateTaskTrackMode = 'highlight' | 'task' | 'pre-populate' | 'group' | 'bulk';
type TaskTrackProps = 'task stage' | 'task status' | 'task answer type' | 'task requirement' | 'task deadline';
type TaskTrackPickerMode = 'single' | 'bulk';

export type TaskTrackPickerData = {
  groupId?: string;
  bidId?: string;
  eventSource?: TaskTrackEventSource;
  mode: TaskTrackPickerMode;
};

type TaskTrackUpdateData = TaskTrackPickerData & {
  taskProp: TaskTrackProps;
  value?: string;
};

export type TaskTrackCreateData = {
  taskId?: string;
  groupId?: string;
  bidId?: string;
  eventSource: TaskTrackEventSource;
  mode: CreateTaskTrackMode;
};

/** BT: Create task */
export function trackTaskCreate(props: TaskTrackCreateData): void {
  trackWithSessionReplay('BT create task', props);
}

/** BT: Update task: Task stage, Task status, task answer type, task requirement */
export function trackTaskUpdate(props: TaskTrackUpdateData): void {
  const { taskProp, ...restProps } = props;
  trackWithSessionReplay(`BT ${taskProp}`, restProps);
}

/** BT: Delete task */
export function trackBTDeleteTask(props: TaskTrackPickerData): void {
  trackWithSessionReplay('BT delete task', props);
}

// BT upload file
export function trackBTUploadFile(taskId: string, groupId?: string, bidId?: string): void {
  trackWithSessionReplay('BT upload file', {
    bidId,
    groupId,
    taskId
  });
}

// BT saves free text
export function trackBTSavesFreeText(taskId: string, groupId?: string, bidId?: string): void {
  trackWithSessionReplay('BT saves free text', {
    bidId,
    groupId,
    taskId
  });
}

//FIXME WORKFLOW - CHANGE TO TRACK OPEN BID PREVIEW
// BT: open detailed task view
export function trackBTOpenDetailedTaskView(args: {
  taskId: string;
  eventSource: string;
  groupId?: string;
  bidId?: string;
}): void {
  const { taskId, eventSource, groupId, bidId } = args;
  trackWithSessionReplay('BT open detailed task view', {
    bidId,
    groupId,
    taskId,
    eventSource
  });
}

// BT: show more
export function trackBTShowMore(taskId: string, groupId?: string, bidId?: string): void {
  trackWithSessionReplay('BT show more', {
    bidId,
    groupId,
    taskId
  });
}

// BT: Filtering
export function trackFilterTasks({
  bidId,
  taskProp,
  values
}: {
  taskProp: TaskTrackProps;
  bidId?: string;
  values?: string[];
}): void {
  trackWithSessionReplay(`BT Filtering`, {
    bidId,
    taskProp,
    values: values?.toString()
  });
}

// BT: yes/no
export function trackBTYesNo(mode: 'yes' | 'no' | 'reset'): void {
  trackWithSessionReplay('BT yes/no', {
    mode
  });
}
