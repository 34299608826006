import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ContentLibraryFiltersDTO, ContentLibraryType } from '@tendium/prom-types/schema';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TeamName, UserName } from 'src/common';
import { toHSLColor } from 'src/helpers/styles';
import { useFilteredItems } from 'src/models/canvas/hooks';
import { useContentLibrary } from 'src/models/contentLibrary/ContentLibrary/hooks';
import { useLoadContentLibraryRooms } from 'src/models/contentLibrary/ContentLibraryRoom/hooks';
import { useLoadContentLibraryTags } from 'src/models/contentLibrary/ContentLibraryTag/hooks';
import { useActiveUsers } from 'src/models/users/hooks';
import { isTeam } from 'src/models/users/Team';
import { isUser } from 'src/models/users/types';
import ContentLibraryTag from 'src/shared/ContentLibrary/ContentLibraryTags/ContentLibraryTagsArea/ContentLibraryTag';
import FilterList from './FilterList';
import styles from './index.module.scss';

interface Props {
  filters?: ContentLibraryFiltersDTO;
}

const ContentLibraryGenerateFilters: FC<Props> = ({ filters }) => {
  const { t } = useTranslation();
  const { data: roomData } = useLoadContentLibraryRooms();
  const { data: tagsData } = useLoadContentLibraryTags();
  const users = useActiveUsers();

  const { data: state } = useContentLibrary();
  const updateVars = state?.updateVars;
  const currentVars = filters ?? state?.currentVars.filters;

  const rooms = useFilteredItems(roomData, currentVars?.roomIds || [], room => (
    <span>
      <FontAwesomeIcon icon={faCircle} color={toHSLColor(room.id)} /> {room.title}
    </span>
  ));

  const tags = useFilteredItems(tagsData, currentVars?.tagIds || [], tag => (
    <ContentLibraryTag tag={tag} key={tag.id} disabled isListItem />
  ));

  const types = useFilteredItems(
    [
      { label: t('ContentLibrary.contentCard'), id: ContentLibraryType.QA },
      { label: t('Tenders.SearchType.Document'), id: ContentLibraryType.DOCUMENT }
    ],
    currentVars?.types || [],
    type => type.label
  );

  const usersList = useFilteredItems(users, currentVars?.createdBy || [], user =>
    isUser(user) ? <UserName user={user} /> : isTeam(user) ? <TeamName team={user} /> : null
  );

  const assignedUsersList = useFilteredItems(users, currentVars?.assignedTo || [], user =>
    isUser(user) ? <UserName user={user} /> : isTeam(user) ? <TeamName team={user} /> : null
  );

  return (
    <div className={styles.sources}>
      <span>{currentVars?.search ?? ''}</span>
      <FilterList
        items={rooms}
        onRemove={id => updateVars?.({ roomIds: rooms.filter(r => r.id !== id).map(r => r.id) })}
      />

      <FilterList
        items={tags}
        onRemove={id => updateVars?.({ tagIds: tags.filter(t => t.id !== id).map(t => t.id) })}
      />

      <FilterList
        items={types}
        onRemove={id => updateVars?.({ types: types.filter(t => t.id !== id).map(t => t.id) })}
      />

      <FilterList
        items={usersList}
        onRemove={id => updateVars?.({ createdBy: usersList.filter(u => u.id !== id).map(u => u.id) })}
      />

      <FilterList
        items={assignedUsersList}
        onRemove={id => updateVars?.({ assignedTo: assignedUsersList.filter(u => u.id !== id).map(u => u.id) })}
      />
    </div>
  );
};

export default ContentLibraryGenerateFilters;
