import { Node } from '@tiptap/pm/model';

const doesNodeContainHighlightMark = (nodes: readonly Node[]): boolean => {
  return nodes.some(node => {
    // Check if the node has text content and check its marks
    if (node.type.name === 'text' && node.marks?.some(mark => mark.type.name === 'highlight')) return true;

    // If the node has content (it might contain sub-nodes), recurse into it
    if (node.content) return doesNodeContainHighlightMark(node.content.content);

    return false;
  });
};

export default doesNodeContainHighlightMark;
