import { z } from 'zod';
import {
  isTenderVarsDiff,
  tendersSearchSchema,
  TendersSearchSchemaKeyType,
  TendersSearchSchemaType
} from '../tendersSearchSchema';

export const cpvCodeFilterSchema = tendersSearchSchema.pick({ cpvCodes: true, cpvCodesExact: true });
export type CpvCodeFilterSchemaType = z.infer<typeof cpvCodeFilterSchema>;

export const cpvCodeFilterKeys = Object.keys(cpvCodeFilterSchema.shape) as TendersSearchSchemaKeyType[];

export function isCpvFilterDiff(vars1?: TendersSearchSchemaType, vars2?: TendersSearchSchemaType): boolean {
  return isTenderVarsDiff(cpvCodeFilterKeys, vars1, vars2);
}
