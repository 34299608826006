import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useBidding, useOpenTaskGroupsPrefs, useTasksFilter, useUpdateBidGroup } from 'src/models/bids/BidFull/hooks';
import TasksFilter from '../TasksFilter';
import TasksStatistic from '../TasksStatistic';
import styles from './index.module.scss';
import { calculateNewRank, isTasksFilterApplied } from 'src/models/bids/BidFull/helpers';
import { Alert, EmptyFilter, Skeleton } from 'src/common';
import { useTranslation } from 'react-i18next';
import TasksGroup from '../TaskGroup';
import BidOnboarding from '../BidOnboarding';
import { BidTasksGroup, TasksGenerationJobStatus } from 'src/models/bids/BidFull/types';
import update from 'immutability-helper';
import { TasksProvider } from 'src/models/bids/BidFull/providers';
import TaskGroupTableHeader from 'src/shared/Bid/TaskGroupTableHeader';
import { TaskSkeleton } from 'src/shared/Bid/TaskSkeleton';
import TasksGroupTitle from 'src/shared/Bid/TasksGroupTitle';
import { useBidPreview } from 'src/models/bids/BidPreview/hooks';
import { FeatureFlag, useFeatureFlag } from 'src/helpers';

interface DndGroupItem {
  dndId: number;
  group: BidTasksGroup;
}
interface ContainerState {
  dndGroupItems: DndGroupItem[];
}

const mapGroupsToContainerState = (groups: BidTasksGroup[]): ContainerState => ({
  dndGroupItems: groups.map((group, index) => ({
    dndId: index,
    group
  }))
});

export const BidContent: FC = () => {
  const { t } = useTranslation();
  const { filter } = useTasksFilter();
  const { data, loading, statsLoading } = useBidding();
  const [updateGroup] = useUpdateBidGroup();
  const [openTaskGroupsIds] = useOpenTaskGroupsPrefs();
  const { data: bidPreview } = useBidPreview();
  const isTaskGenerationInProgress = bidPreview?.tasksGenerationStatus === TasksGenerationJobStatus.IN_PROGRESS;
  const [isLoadingGroupOpen, setIsLoadingGroupOpen] = useState<boolean>(true);

  const isBidFlowFeature = useFeatureFlag(FeatureFlag.BiddingWorkflow_Tasks);

  const groups = useMemo(() => data?.questionGroups, [data?.questionGroups]);
  const bidId = data?.id;
  const isAllGroupsEmpty = useMemo(() => groups?.every(group => group.totalTasks === 0), [groups]);
  const isFilterApplied = useMemo(() => isTasksFilterApplied(filter), [filter]);
  const filteredGroups = useMemo(
    () => groups?.filter(group => !!group.totalTasks || !isFilterApplied),
    [groups, isFilterApplied]
  );

  const [dndGroups, setDndGroups] = useState<ContainerState>(mapGroupsToContainerState(filteredGroups ?? []));

  useEffect(() => {
    setDndGroups(mapGroupsToContainerState(filteredGroups ?? []));
  }, [filteredGroups, groups]);

  const moveGroup = useCallback((dragIndex: number, hoverIndex: number) => {
    setDndGroups(
      prevState =>
        ({
          dndGroupItems: update(prevState.dndGroupItems, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, prevState.dndGroupItems[dragIndex] as DndGroupItem]
            ]
          })
        } as ContainerState)
    );
  }, []);

  const dropGroup = useCallback(
    (index: number) => {
      if (dndGroups.dndGroupItems.length === 0) {
        return;
      }

      const newRank = calculateNewRank(dndGroups.dndGroupItems, index, groupItem => groupItem.group.rank);

      updateGroup({
        bidId: bidId ?? '',
        groupId: dndGroups.dndGroupItems[index].group.id,
        rank: newRank,
        newCacheSortIndex: index
      });
    },
    [bidId, dndGroups.dndGroupItems, updateGroup]
  );

  const renderGroup = useCallback(
    (dndGroup: { dndId: number; group: BidTasksGroup }, index: number) => {
      const isExpanded = openTaskGroupsIds?.includes(dndGroup.group.id);
      return (
        <TasksProvider groupId={dndGroup.group.id} skip={!isExpanded} key={dndGroup.group.id}>
          <TasksGroup
            groupId={dndGroup.group.id}
            dndId={dndGroup.dndId}
            index={index}
            dropGroup={dropGroup}
            moveGroup={moveGroup}
            key={dndGroup.group.id}
          />
        </TasksProvider>
      );
    },
    [dropGroup, moveGroup, openTaskGroupsIds]
  );

  const taskSkeletons = useMemo(() => [...Array(3)].map((_, i) => <TaskSkeleton key={i} />), []);

  const renderLoading = (): React.JSX.Element => {
    return (
      <div className={styles.loading}>
        <div>
          <TasksGroupTitle
            title={''}
            isGroupOpen={isLoadingGroupOpen}
            groupId={''}
            actionsAvailable={false}
            loading
            onToggle={() => {
              setIsLoadingGroupOpen(prev => !prev);
            }}
          >
            <TaskGroupTableHeader />
            {taskSkeletons}
          </TasksGroupTitle>
        </div>

        <TasksGroupTitle className={styles.loadingGroup} title={''} groupId={''} actionsAvailable={false} loading />
        <TasksGroupTitle className={styles.loadingGroup} title={''} groupId={''} actionsAvailable={false} loading />
      </div>
    );
  };

  if (!isBidFlowFeature) return <Alert message={t('Common.ACL.noAccessFeature')} type={'info'} />;

  return isTaskGenerationInProgress ? (
    renderLoading()
  ) : (
    <>
      {!!loading ? (
        <Skeleton active loading paragraph={{ rows: 2 }} />
      ) : !groups?.length && !isFilterApplied ? (
        <BidOnboarding />
      ) : (
        <>
          <TasksFilter />
          <TasksStatistic />
        </>
      )}
      {loading || statsLoading ? (
        <Skeleton active loading paragraph={{ rows: 2 }} />
      ) : isAllGroupsEmpty && isFilterApplied ? (
        <EmptyFilter title={t('BiddingTool.Filters.noMatch')} desc={t('BiddingTool.Filters.noMatchDesc')} />
      ) : (
        !!groups?.length && (
          <section className={styles.groups}>
            {bidId && dndGroups.dndGroupItems.map((dndGroupItem, i) => renderGroup(dndGroupItem, i))}
          </section>
        )
      )}
    </>
  );
};

export default BidContent;
