import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import styles from './index.module.scss';
import { faPen } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Input, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useClickAway } from 'react-use';

interface EditableBlockProps {
  onSave: (content: string) => void;
  content: string;
  placeholder?: string;
  viewMode?: boolean;
  className?: string;
}

const fieldName = 'editableContent';

const EditableBlock: FC<EditableBlockProps> = ({ content, onSave, placeholder, viewMode, className }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const ref = useRef<HTMLDivElement>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    form.setFieldsValue({ [fieldName]: content });
  }, [content, form]);

  const handleSave = useCallback(() => {
    if (!isChanged) {
      setIsEditing(false);
      return;
    }

    const updatedContent = form.getFieldValue(fieldName);
    onSave(updatedContent);
    setIsEditing(false);
    setIsChanged(false);
  }, [form, isChanged, onSave]);

  const handleCancel = (): void => {
    form.setFieldsValue([fieldName]);

    setIsEditing(false);
    setIsChanged(false);
  };

  const onValuesChange = (): void => {
    setIsChanged(true);
  };

  useClickAway(ref, () => {
    handleSave();
  });

  return (
    <div className={styles.editableContainer} ref={ref}>
      {!isEditing ? (
        <div className={classNames(className, styles.contentWrapper)} onClick={() => !viewMode && setIsEditing(true)}>
          <span
            className={classNames(styles.content, {
              [styles.placeholder]: !content && placeholder
            })}
          >
            {content || placeholder}
          </span>
          {!viewMode && <FontAwesomeIcon icon={faPen} className={styles.pencilIcon} />}
        </div>
      ) : (
        <div className={styles.editWrapper}>
          <Form form={form} layout="vertical" onValuesChange={onValuesChange}>
            <Form.Item name={fieldName} className={classNames(styles.textarea)}>
              <Input.TextArea autoSize={{ minRows: 2 }} />
            </Form.Item>
            <Form.Item className={styles.buttonContainer}>
              <Button type="text" onClick={handleCancel} className={styles.cancelBtn}>
                {t('Common.cancel')}
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
    </div>
  );
};

export default EditableBlock;
