import { gql } from '@apollo/client';
import { FULL_TENDER_FIELDS, AI_SUMMARIES_FIELDS, AI_TITLES_FIELDS, BOX_FIELDS } from './fragments';

export const GET_FULL_TENDER = gql`
  query getTender($id: String!, $includeSummary: Boolean!) {
    getTender(query: { id: $id }) {
      ...fullTenderFields
      summaries @include(if: $includeSummary) {
        ...aiSummariesFields
      }
      titles @include(if: $includeSummary) {
        ...aiTitlesFields
      }
    }
  }
  ${FULL_TENDER_FIELDS}
  ${AI_SUMMARIES_FIELDS}
  ${AI_TITLES_FIELDS}
`;

export const GET_TENDER_FILE = gql`
  query getFile($procurementId: String!, $targetPath: String!, $skipConversion: Boolean!) {
    getFile(query: { procurementId: $procurementId, targetPath: $targetPath, skipConversion: $skipConversion }) {
      url
      conversionResult
    }
  }
`;

export const GET_ZIP = gql`
  query getZip($procurementId: String!, $fileName: String) {
    getZip(procurementId: $procurementId, fileName: $fileName)
  }
`;

export const GET_TENDER_MINIMAL = gql`
  query getTender($id: String!) {
    getTender(query: { id: $id }) {
      procurementStatus
      isPlanningProcess
      timeline {
        availableDate {
          ...boxFields
        }
      }
      general {
        name {
          ...boxFields
        }
      }
    }
  }
  ${BOX_FIELDS}
`;
