import { ContentLibraryEventSource } from './../../models/contentLibrary/ContentLibrary/types';
import { trackWithSessionReplay } from '../helpers';
import { ContentLibraryType } from '@tendium/prom-types/schema';
import { TrackEventSource } from './types';

export enum ContentLibraryTrackFilter {
  assignedTo = 'Assigned to',
  createdBy = 'Created by',
  room = 'Room',
  tags = 'Tags',
  type = 'Type'
}

export function trackSwitchRoomCL(room: { id: string; name: string }): void {
  trackWithSessionReplay('CL switch rooms', {
    room
  });
}

export function trackEditCL(): void {
  trackWithSessionReplay('CL Edit Content Library');
}

export function trackAddRoomCL(room: { id: string; name: string }): void {
  trackWithSessionReplay('CL add room', {
    room
  });
}

export function trackAddTagGroupCL(tagGroup: { id: string; name: string }): void {
  trackWithSessionReplay('CL add tag group', {
    tagGroup
  });
}

export function trackAddTagCL(tag: { tagGroupId: string; tagName: string }): void {
  trackWithSessionReplay('CL add tag', {
    tag
  });
}

export function trackFreeTextSearchCL(room: { id?: string; name?: string }): void {
  trackWithSessionReplay('CL search field', {
    room
  });
}

export function trackFilteringCL(eventSource: ContentLibraryEventSource, filter: ContentLibraryTrackFilter): void {
  trackWithSessionReplay('CL filtering', {
    eventSource,
    filter
  });
}

export function trackSortByCL(eventSource: ContentLibraryEventSource, filter: string): void {
  trackWithSessionReplay('CL sorting', {
    eventSource,
    filter
  });
}

export function trackAddContentCardCL(eventSource: ContentLibraryEventSource, fields?: string[]): void {
  trackWithSessionReplay('CL add content card', {
    eventSource,
    fields
  });
}

export function trackAddFileCL(eventSource: ContentLibraryEventSource, fields?: string[]): void {
  trackWithSessionReplay('CL add file', {
    eventSource,
    fields
  });
}

export function trackEditContentCardCL(
  eventSource: ContentLibraryEventSource,
  mode: 'Single' | 'Bulk',
  fields: string[],
  contentType?: ContentLibraryType
): void {
  trackWithSessionReplay('CL edit content card', {
    eventSource,
    mode,
    fields,
    contentType
  });
}

export function trackDeleteContentCardCL(eventSource: ContentLibraryEventSource, mode: 'Single' | 'Bulk'): void {
  trackWithSessionReplay('CL delete content card', {
    eventSource,
    mode
  });
}

/** @deprecated use trackCLContentLibrary instead */
export function trackOpenContentLibraryCL(): void {
  trackWithSessionReplay('CL open content library');
}

export function trackCLOpenContentLibrary(data: { eventSource: TrackEventSource; taskId?: string }): void {
  trackWithSessionReplay('CL open content library', {
    eventSource: data.eventSource,
    taskId: data.taskId
  });
}

export function trackCLAddContentCard(data: { eventSource: TrackEventSource }): void {
  trackWithSessionReplay('CL add content card', {
    eventSource: data.eventSource
  });
}
