import React, { useCallback, JSX } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import { IPlainDropdownValue } from '../types';
import { Tooltip } from 'src/common';
import { useIsMobile } from 'src/reactiveVars';

interface Props<T extends string> {
  values: IPlainDropdownValue<T>[];
  selectedValue?: string;
  onSelect: (item: T) => void;
}

export function DropdownOverlay<T extends string>(props: Props<T>): JSX.Element {
  const { onSelect, values, selectedValue } = props;
  const [isMobile] = useIsMobile();
  const onSelectItem = useCallback(
    (item: T) => {
      onSelect(item);
    },
    [onSelect]
  );
  return (
    <div
      className={classNames(styles.overlay, {
        [styles.mobile]: isMobile
      })}
    >
      <div
        className={classNames(styles.container, {
          [styles.mobile]: isMobile
        })}
      >
        {values.map(({ value, disabled, tooltip, label }) =>
          tooltip ? (
            <Tooltip title={tooltip} key={value}>
              <button
                disabled={disabled}
                onClick={() => onSelectItem(value)}
                className={classNames(styles.button, {
                  [styles.isActive]: selectedValue === value,
                  [styles.isDisabled]: disabled
                })}
              >
                {label || value}
              </button>
            </Tooltip>
          ) : (
            <button
              key={value}
              disabled={disabled}
              onClick={() => onSelectItem(value)}
              className={classNames(styles.button, {
                [styles.isActive]: selectedValue === value,
                [styles.isDisabled]: disabled,
                [styles.mobile]: isMobile
              })}
            >
              {label || value}
            </button>
          )
        )}
      </div>
    </div>
  );
}

export default DropdownOverlay;
