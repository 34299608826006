import { z } from 'zod';
import {
  isTenderVarsDiff,
  tendersSearchSchema,
  TendersSearchSchemaKeyType,
  TendersSearchSchemaType
} from '../tendersSearchSchema';

export const nutsCodeFilterSchema = tendersSearchSchema.pick({ nutsCodes: true, nutsCodesExact: true });
export type NutsCodeFilterSchemaType = z.infer<typeof nutsCodeFilterSchema>;

export const nutsCodeFilterKeys = Object.keys(nutsCodeFilterSchema.shape) as TendersSearchSchemaKeyType[];

export function isNutsFilterDiff(vars1?: TendersSearchSchemaType, vars2?: TendersSearchSchemaType): boolean {
  return isTenderVarsDiff(nutsCodeFilterKeys, vars1, vars2);
}
