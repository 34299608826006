import { z } from 'zod';
import { tendersSearchSchema, TendersSearchSchemaKeyType } from '../tendersSearchSchema';

export const tenderStatusFilterSchema = tendersSearchSchema.pick({
  onlyStatus: true,
  awarded: true
});
export type TenderStatusFilterSchemaType = z.infer<typeof tenderStatusFilterSchema>;
export const tenderStatusFilterKeys = Object.keys(tenderStatusFilterSchema.shape) as TendersSearchSchemaKeyType[];
export const tenderStatusFilterQPKey = 'tenderStatus';
