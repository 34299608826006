import { BidItemType } from '@tendium/prom-types/tender';
import { trackWithSessionReplay } from '../helpers';
import { TaskTrackEventSource } from './task';

export function trackConnectItemsToBid(
  itemsIds: readonly string[],
  itemType: BidItemType,
  eventSource: string,
  bidId: string
): void {
  trackWithSessionReplay(`Connect ${itemType === BidItemType.Procurement ? `procurements` : `call-offs`} to Bid`, {
    itemsIds,
    eventSource,
    bidId
  });
}

// BT: Delete group
export function trackBTDeleteGroup(groupId: string, bidId?: string): void {
  trackWithSessionReplay('BT delete group', {
    bidId,
    groupId
  });
}

// BT: generate preview document
export function trackBTGeneratePreviewDocument(procurement: { id: string; name?: string }): void {
  trackWithSessionReplay('BT generate preview document', {
    procurement
  });
}

// BT: generate download document
export function trackBTGenerateDownloadDocument(procurement: { id: string; name?: string }): void {
  trackWithSessionReplay('BT generate download document', {
    procurement
  });
}

// Change bid stage
export function trackChangeBidStage(
  workspace: { id: string; name?: string },
  categoryName: string,
  eventSource: string
): void {
  trackWithSessionReplay('WS change bid stage', {
    workspace,
    categoryName,
    eventSource
  });
}

// Reject bid
export function trackRejectBid(workspace: { id: string; name?: string }, categoryName?: string): void {
  trackWithSessionReplay('Reject bid', {
    workspace,
    categoryName
  });
}

// Delete bid from ws bid preveiw
export function trackDeleteBid(workspace: { id: string; name?: string }, categoryName?: string): void {
  trackWithSessionReplay('Delete bid', {
    workspace,
    categoryName
  });
}

// Add manual bid (not calloff)
export function trackCreateManualBid(workspace: { id: string; name?: string }): void {
  trackWithSessionReplay('Create manual bid', {
    workspace
  });
}

/** BT Document Create Task */
export function trackNavigateToDraft(bidId: string, eventSource: TaskTrackEventSource): void {
  trackWithSessionReplay('BT Document Create Task', {
    bidId,
    eventSource
  });
}

/** BT Document Select */
export function trackSelectFileDraft(props: { bidId: string; filePath: string }): void {
  trackWithSessionReplay('BT Document Select', props);
}

/** BT Document Preview */
export function trackPreviewDraft(props: { bidId: string; groupId: string; taskId: string }): void {
  trackWithSessionReplay('BT Document Preview', props);
}

/** BT Document Preview edit */
export function trackPreviewEditDraft(props: {
  bidId: string;
  groupId: string;
  taskId: string;
  mode: 'title' | 'question';
}): void {
  trackWithSessionReplay('BT Document Preview edit', props);
}

/** BT Document switch groups */
export function trackSwitchDraft(props: { bidId: string; groupId?: string; filePath: string }): void {
  trackWithSessionReplay('BT Document switch groups', props);
}

/** BT Document Add Task to Bid */
export function trackPublishDraft(bidId: string): void {
  trackWithSessionReplay('BT Document Add Task to Bid', {
    bidId
  });
}

export function trackGenerateSelection(props: { fileName: string; type: boolean }): void {
  trackWithSessionReplay('BT generate selection', props);
}

export function trackRelatedSources(eventSource: 'preview' | 'editor'): void {
  trackWithSessionReplay('BT related sources', { eventSource });
}
