import { Button, DatePicker } from 'src/common';
import React, { FC, useCallback, useMemo, useState } from 'react';
import styles from './index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import Deadline from 'src/common/Deadline';
import { BidTask } from 'src/models/bids/BidTask';
import { TaskTrackPickerData, trackTaskUpdate } from 'src/segment/events';
import { useUpdateBidTasks } from 'src/models/bids/BidTask/hooks';
import { useTranslation } from 'react-i18next';
import dayjs from 'src/helpers/dayjs';
import { FORMAT_DATE } from 'src/helpers/dates';
import datePickerStyles from 'src/common/DatePicker/index.module.scss';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

interface Props {
  questions: BidTask[];
  groupId: string;
  taskValue?: number | null;
  trackData?: TaskTrackPickerData;
  isTaskDone?: boolean;
  disabled?: boolean;
  setActionsActive?: (actionActive: boolean) => void;
}

export const TaskDatePicker: FC<Props> = props => {
  const { questions, groupId, taskValue, trackData, isTaskDone = false, disabled = false, setActionsActive } = props;

  const { t } = useTranslation();

  const [updateTasks] = useUpdateBidTasks();

  const [menuVisible, setMenuVisible] = useState(false);

  const toggleDatePicker = useCallback(() => {
    setMenuVisible(prev => !prev);
    setActionsActive && setActionsActive(!menuVisible);
  }, [menuVisible, setActionsActive]);

  const onDateChange = useCallback(
    (date: dayjs.Dayjs | null) => {
      const valueOfDate = date?.utc().endOf('day').valueOf() ?? null;
      const textOfDate = date?.format(FORMAT_DATE) ?? '';
      if (!valueOfDate) {
        setMenuVisible(false);
        setActionsActive && setActionsActive(false);
      }
      if (trackData) {
        trackTaskUpdate({ ...trackData, taskProp: 'task deadline', value: textOfDate });
      }
      updateTasks({
        questions: questions.filter(item => item.deadline !== valueOfDate),
        groupId,
        deadline: valueOfDate
      });
      setActionsActive && setActionsActive(false);
    },
    [groupId, questions, setActionsActive, trackData, updateTasks]
  );

  const currentValue = useMemo(() => (taskValue ? dayjs.utc(taskValue) : null), [taskValue]);

  return (
    <div
      className={classNames(styles.taskDatePickerContainer, {
        [styles.isActive]: !!currentValue,
        [styles.isDisabled]: disabled
      })}
    >
      <button className={styles.activeDateButton} onClick={toggleDatePicker} disabled={disabled || menuVisible}>
        {currentValue ? (
          <Deadline
            className={isTaskDone ? styles.isTaskDone : undefined}
            deadline={dayjs(taskValue).valueOf()}
            showExpiredDate
          />
        ) : (
          t('BidSpaces.BidsSort.deadline')
        )}
      </button>
      <DatePicker
        dropdownClassName={classNames(
          datePickerStyles.datePickerDropDown,
          datePickerStyles.datePickerDropDownFooterExtra
        )}
        dropdownAlign={{ points: ['tr', 'cc'], offset: [0, 14] }}
        allowClear={false}
        suffixIcon={<FontAwesomeIcon icon={menuVisible ? faCaretUp : faCaretDown} />}
        className={styles.taskDatePicker}
        open={menuVisible}
        onOpenChange={toggleDatePicker}
        value={currentValue}
        onChange={onDateChange}
        disabled={disabled}
        renderExtraFooter={() => (
          <Button
            type={'text'}
            icon={<FontAwesomeIcon icon={faTimes} />}
            onClick={() => onDateChange(null)}
            className={styles.deleteButton}
          >
            {t('Common.Blocks.deleteDate')}
          </Button>
        )}
        getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
      />
    </div>
  );
};
