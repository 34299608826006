import React, { FC, ReactNode, useMemo, useState } from 'react';
import { ContentLibraryContext } from './context';
import { useContentLibraryCtx } from './hooks';
import { DEFAULT_CONTENT_LIBRARY_LIMIT } from './types';
import { Content } from '.';
interface ContentLibraryProps {
  question?: string;
  children: ReactNode;
}

interface Props {
  children: ReactNode;
}

export const ContentLibraryProvider: FC<Props> = ({ children }) => {
  return <ContentLibraryDataProvider>{children}</ContentLibraryDataProvider>;
};

export const ContentLibraryDataProvider: FC<ContentLibraryProps> = ({ children }) => {
  const [offset, setOffset] = useState<number>(0);
  const query = useContentLibraryCtx();

  const [selectedPairIds, setSelectedPairIds] = useState<string[]>([]);
  const [selectedContent, setSelectedContent] = useState<Content | undefined>(undefined);
  const [showContentCardModal, setShowContentCardModal] = useState(false);

  const value = useMemo(
    () => ({
      limit: DEFAULT_CONTENT_LIBRARY_LIMIT,
      offset: offset,
      data: query,
      setOffset,
      selectedPairIds,
      setSelectedPairIds,
      selectedContent,
      setSelectedContent,
      showContentCardModal,
      setShowContentCardModal
    }),
    [offset, query, selectedPairIds, selectedContent, showContentCardModal]
  );
  return <ContentLibraryContext.Provider value={value}>{children}</ContentLibraryContext.Provider>;
};
