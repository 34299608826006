import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import Button from '../Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

interface SearchFieldTagProps {
  value: string | { id: string; value: string }[];
  color: string;
  className?: string;
  disabled?: boolean;
  onRemove?: () => void;
}

export const SearchFieldTag: FC<SearchFieldTagProps> = props => {
  const { value, color, className, disabled, onRemove } = props;
  const { t } = useTranslation();

  return (
    <span className={classNames(styles.tag, className)} style={{ background: color }}>
      {typeof value === 'string' ? (
        <span className={classNames(styles.text, { [styles.isFullyVisible]: value.length < 6 })}>{value}</span>
      ) : (
        value.map((obj, idx) => (
          <React.Fragment key={obj.id}>
            <span className={classNames(styles.text, { [styles.isFullyVisible]: obj.value.length < 6 })}>
              {obj.value}
            </span>
            {idx !== value.length - 1 && <span className={styles.plusLabel}>&nbsp;{t('Common.and')}&nbsp;</span>}
          </React.Fragment>
        ))
      )}
      {!disabled && onRemove ? (
        <Button type={'text'} onClick={onRemove} icon={<FontAwesomeIcon icon={faTimes} />} className={styles.button} />
      ) : null}
    </span>
  );
};

export default SearchFieldTag;
