import React, { FC, useMemo } from 'react';
import styles from './index.module.scss';
import Tooltip from 'src/common/Tooltip';
import classNames from 'classnames';
import Spinner from '../Spinner';
import { toColor } from 'src/helpers/styles';
import { IUserName } from 'src/models/users/types';

interface Props {
  bordered?: boolean;
  className?: string;
  showTooltip?: boolean;
  size?: (typeof Sizes)[number];
  user: IUserName;
  loading?: boolean;
}

function getLabel(str: string, separator: string): string {
  return str
    .split(separator)
    .map((el, i) => (i < 2 ? el.charAt(0) : ''))
    .join('');
}

export const Avatar: FC<Props> = ({ size = 'm', ...restProps }) => {
  const { className, showTooltip, user, bordered, loading } = restProps;
  const { email, userName, color } = user;

  const userNameString = useMemo(() => {
    return !!userName ? userName : email.split('@')[0];
  }, [email, userName]);

  const label = useMemo(() => {
    return getLabel(userNameString, !!userNameString ? ' ' : '.');
  }, [userNameString]);

  const labelColor = useMemo(() => color ?? toColor(email), [color, email]);

  return (
    <Tooltip title={showTooltip ? userNameString : undefined}>
      <div
        className={classNames(styles.avatar, className, {
          [styles.bordered]: bordered,
          [styles.xSmall]: size === 'xs',
          [styles.small]: size === 's',
          [styles.medium]: size === 'm',
          [styles.large]: size === 'l',
          [styles.xLarge]: size === 'xl',
          [styles.isUpdating]: loading
        })}
        style={!loading ? { backgroundColor: labelColor } : undefined}
      >
        {loading ? <Spinner /> : label}
      </div>
    </Tooltip>
  );
};

export default Avatar;
