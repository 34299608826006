import classNames from 'classnames';
import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PlainDropDown } from 'src/common';
import styles from './index.module.scss';
import { useContentLibrary } from 'src/models/contentLibrary/ContentLibrary/hooks';
import { ContentLibraryTrackFilter, trackFilteringCL } from 'src/segment/events';
import { ContentLibraryEventSource } from 'src/models/contentLibrary/ContentLibrary/types';
import { ContentLibraryType } from '@tendium/prom-types/schema';

export interface Props {
  eventSource: ContentLibraryEventSource;
}

const ContentLibraryFilterType: FC<Props> = ({ eventSource }) => {
  const { t } = useTranslation();
  const { data: state } = useContentLibrary();

  const currentVars = useMemo(() => state?.currentVars.filters ?? {}, [state]);

  const updateVars = state?.updateVars;
  const types = useMemo(
    () => [
      { label: t('ContentLibrary.contentCard'), value: ContentLibraryType.QA },
      { label: t('Tenders.SearchType.Document'), value: ContentLibraryType.DOCUMENT }
    ],
    [t]
  );

  const selectedType = useMemo(() => (currentVars?.types ? currentVars.types : undefined), [currentVars]);

  const onChangeType = useCallback(
    (type: string, checked?: boolean) => {
      const previousValue = selectedType || [];
      const currentValue = checked ? [...previousValue, type] : previousValue.filter(item => item !== type);
      updateVars?.({ types: currentValue });
      trackFilteringCL(eventSource, ContentLibraryTrackFilter.type);
    },
    [eventSource, updateVars, selectedType]
  );

  return (
    <div className={classNames(styles.filter, { [styles.isActive]: selectedType?.length })}>
      <PlainDropDown
        values={types}
        selectedValue={selectedType}
        label={t('ContentLibrary.type')}
        onSelect={onChangeType}
        buttonClassName={styles.label}
        placement="bottomLeft"
        type="multiple"
        button
      />
    </div>
  );
};

export default ContentLibraryFilterType;
