import React, { FC, useCallback, useMemo, useState } from 'react';
import { IProcurementFileCategory } from 'src/models/procurements/Tender/types';
import styles from './index.module.scss';
import { DirectoryTree } from 'src/common';
import { useTranslation } from 'react-i18next';
import { GROUP_LIMIT_COUNT } from 'src/models/bids/BidFull/types';
import { toTreeData } from './toTreeData';
import { useDownloadFile, usePreviewFile } from 'src/models/procurements/Tender/hooks';
import { DocumentViewer } from 'src/shared';
import { getFileNameFromPath } from 'src/models/procurements/helpers';
import {
  trackBTGenerateDownloadDocument,
  trackBTGeneratePreviewDocument,
  trackGenerateSelection
} from 'src/segment/events';
import classNames from 'classnames';
import { EventDataNode } from 'antd/lib/tree';

interface ITenderFilesTreeProps {
  fileCategories: IProcurementFileCategory[];
  precheckFiles: string[];
  checkedFiles: string[];
  disabledFiles: string[];
  restCheckedFiles: string[];
  previousGeneratedEmptyGroupFiles: string[];
  onCheck?: (checkedKeys: string[]) => void;
  hitLimits?: boolean;
  procurementId?: string;
  procurementName?: string;
}

export const TenderFilesTree: FC<ITenderFilesTreeProps> = props => {
  const { t } = useTranslation();
  const {
    fileCategories,
    precheckFiles,
    checkedFiles,
    restCheckedFiles,
    previousGeneratedEmptyGroupFiles,
    onCheck,
    procurementId,
    procurementName,
    disabledFiles
  } = props;
  const hitLimits = props.hitLimits ?? false;
  const { previewFile, closePreview, url } = usePreviewFile(procurementId);
  const { getFile, loading } = useDownloadFile(procurementId);
  const [filePath, setFilePath] = useState<string | undefined>(undefined);

  const onGetFile = useCallback(
    (val: string) => {
      if (procurementId && procurementName) {
        trackBTGenerateDownloadDocument({ id: procurementId, name: procurementName });
      }

      getFile(val);
    },
    [getFile, procurementId, procurementName]
  );

  const onShowDocument = useCallback(
    (val: string) => {
      if (procurementId && procurementName) {
        trackBTGeneratePreviewDocument({ id: procurementId, name: procurementName });
      }
      setFilePath(val);
    },
    [procurementId, procurementName, setFilePath]
  );

  const onCloseDocument = useCallback(() => {
    closePreview();
    setFilePath(undefined);
  }, [closePreview]);

  const seenFiles = new Set<string>();

  const treeData = toTreeData(
    fileCategories,
    t,
    file => {
      const isPreviousGenerated = precheckFiles.includes(file.targetPath);
      const isManuallyCreated = disabledFiles.includes(file.targetPath);
      const disabled =
        isManuallyCreated ?? hitLimits ? !restCheckedFiles.includes(file.targetPath) : isPreviousGenerated;
      const isDuplicate = seenFiles.has(file.fileName);
      seenFiles.add(file.fileName);

      if (isDuplicate) {
        return { disabled: true };
      }

      if (!disabled) {
        return { disabled: false };
      }

      let tooltip: string;
      if (isPreviousGenerated) {
        const isEmptyGroupFile = previousGeneratedEmptyGroupFiles.includes(file.targetPath);
        tooltip = isEmptyGroupFile
          ? t('BiddingTool.prePopulateModalAlreadyGeneratedEmpty')
          : t('BiddingTool.prePopulateModalAlreadyGenerated');
      } else if (isManuallyCreated) {
        tooltip = t('BidDrafts.docAlreadyManual');
      } else {
        tooltip = t('BiddingTool.prePopulateModalHitLimits', { count: GROUP_LIMIT_COUNT });
      }

      return {
        disabled: true,
        tooltips: tooltip
      };
    },
    onGetFile,
    previewFile,
    onShowDocument
  );

  const fileName = useMemo(() => {
    if (!filePath) {
      return '';
    }
    return getFileNameFromPath(filePath);
  }, [filePath]);

  const onCheckCB = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (checkedKeys: any, node: EventDataNode) => {
      onCheck && onCheck(checkedKeys);
      trackGenerateSelection({ type: !node.checked, fileName: getFileNameFromPath(node.key as string) ?? '' });
    },
    [onCheck]
  );

  return (
    <>
      <DirectoryTree
        defaultExpandedKeys={Object.keys(treeData)}
        checkedKeys={checkedFiles}
        className={classNames(styles.tree, styles.publicFiles)}
        selectable={false}
        checkable={true}
        showIcon={false}
        onCheck={(key, { node }) => {
          onCheckCB(key, node);
        }}
        treeData={treeData}
      />
      {!!url && <DocumentViewer loading={loading} title={fileName} url={url} onClose={onCloseDocument} />}
    </>
  );
};

export default TenderFilesTree;
