import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';
import Tooltip from '../Tooltip';

interface Props {
  label: string;
  labelColor?: string;
  bgColor?: string;
  size?: 's' | 'm';
  borderColor?: string;
  className?: string;
  icon?: ReactNode;
  tooltipDesc?: string;
  iconPosition?: 'before' | 'after';
}

export const Tag: FC<Props> = props => {
  const {
    label,
    labelColor,
    bgColor,
    size,
    borderColor,
    className,
    icon,
    tooltipDesc,
    iconPosition = 'before'
  } = props;

  const tagMedium = (
    <span
      className={classNames(styles.tagMedium, className, { [styles.border]: !!borderColor })}
      style={{ backgroundColor: bgColor, borderColor: borderColor }}
    >
      {!!icon && iconPosition === 'before' && (
        <span className={classNames(styles.icon, styles[iconPosition])}> {icon} </span>
      )}

      <span className={styles.label} style={{ color: labelColor }}>
        {label}
      </span>
      {!!icon && iconPosition === 'after' && (
        <span className={classNames(styles.icon, styles[iconPosition])}> {icon} </span>
      )}
    </span>
  );

  const tagSmall = (
    <span
      className={classNames(styles.tagSmall, className, { [styles.border]: !!borderColor })}
      style={{ color: labelColor, backgroundColor: bgColor, borderColor: borderColor }}
    >
      {label}
    </span>
  );

  const tagComponent = size === 's' ? tagSmall : tagMedium;

  return tooltipDesc ? <Tooltip title={tooltipDesc}>{tagComponent}</Tooltip> : tagComponent;
};

export default Tag;
