import React, { FC, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment as faCommentLight, faPen } from '@fortawesome/pro-light-svg-icons';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import {
  AssignToQuestion,
  EditableText,
  TaskFulfillmentPicker,
  TaskRequirementPicker,
  TaskStatusPicker
} from 'src/shared';
import { Button, Checkbox, ConditionalWrapper, Tooltip, confirm } from 'src/common';
import classNames from 'classnames';
import { IBoxFieldString } from 'src/models/procurements/Tender/types';
import { BoxFieldString } from 'src/models/procurements';
import modalStyles from 'src/common/Modal/index.module.scss';
import { useCreateBidTasks, useDeleteBidTasks, useUpdateBidTasks } from 'src/models/bids/BidTask/hooks';
import { TaskTrackPickerData, trackBTOpenDetailedTaskView } from 'src/segment/events';
import { BidTask } from 'src/models/bids/BidTask';
import { TaskDatePicker } from 'src/shared/Bid/TaskDatePicker';
import { isTaskDone } from 'src/models/bids/BidTask/types';
import { getTasksTitle } from 'src/models/bids/BidTask/helpers';
import { faComment as faCommentSolid } from '@fortawesome/pro-solid-svg-icons';
import { SidebarMode } from 'src/shared/InfoSidebar/types';
import { useCloseSidebar, useOpenSidebar } from 'src/shared/InfoSidebar/hooks';
import { useSearchParams } from 'react-router-dom';
import { usePreviewTabState } from 'src/models/procurements/hooks';
import { PageName } from 'src/models/users/Preferences/types';
import { ActivePreviewTab } from 'src/models/procurements/types';

export interface BidTaskRowProps {
  task: BidTask;
  selectedTasks: BidTask[];
  bidId: string;
  wsId: string;
  isDragging?: boolean;
  newTask?: boolean;
  classname?: string;
  onChangeSelection?: (taskId: string, checked: boolean) => void;
  onExitNewTask?: () => void;
}

export const BidTaskRow: FC<BidTaskRowProps> = ({
  task,
  selectedTasks,
  bidId,
  wsId,
  isDragging,
  newTask,
  classname,
  onChangeSelection,
  onExitNewTask
}) => {
  const { id: taskId, groupId } = task;
  const { t } = useTranslation();
  const [updateTasks] = useUpdateBidTasks();
  const [deleteTasks] = useDeleteBidTasks();
  const [createBidTasks] = useCreateBidTasks();
  const openSidebar = useOpenSidebar();
  const closeSidebar = useCloseSidebar();
  const [searchParams] = useSearchParams();
  const [, setActiveTab] = usePreviewTabState(PageName.biddingTool);

  const [isEditing, setIsEditing] = useState<boolean>(newTask || false);
  const [isActionsActive, setIsActionsActive] = useState<boolean>(false);

  const isTaskSelected = useMemo(() => !!selectedTasks.find(item => item.id === task.id), [selectedTasks, task.id]);
  const previewId = searchParams.get('previewId');

  const trackData: TaskTrackPickerData = useMemo(
    () => ({ bidId, eventSource: 'Overview', mode: 'single', groupId }),
    [bidId, groupId]
  );

  useEffect(() => {
    if (newTask) {
      setIsEditing(true);
    } else if (!newTask) setIsEditing(false);
  }, [newTask]);

  const enterEditing = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      e.preventDefault();
      closeSidebar();
      setIsEditing(true);
    },
    [closeSidebar]
  );

  const exitEditing = useCallback(() => {
    setIsEditing(false);
    onExitNewTask && onExitNewTask();
  }, [onExitNewTask]);

  const onChange = useCallback(
    (value: IBoxFieldString) => {
      taskId &&
        updateTasks({
          questions: [task],
          groupId,
          title: value.string ?? ''
        });
      exitEditing();
    },
    [exitEditing, groupId, task, taskId, updateTasks]
  );

  const onDeleteTask = useCallback(() => {
    confirm.confirm({
      title: t('BiddingTool.deleteTask'),
      okText: t('Common.delete'),
      content: t('BiddingTool.deleteTaskWarning'),
      cancelText: t('Common.cancel'),
      className: modalStyles.confirmModal,
      centered: true,
      onOk() {
        deleteTasks({
          questionIds: [taskId],
          groupId,
          mode: 'single',
          eventSource: 'Bidding Tool page'
        });
        previewId === taskId && closeSidebar();
      }
    });
  }, [t, deleteTasks, taskId, groupId, previewId, closeSidebar]);

  const onClickTask = useCallback(
    (activeTab: ActivePreviewTab) => {
      trackBTOpenDetailedTaskView({ taskId, groupId, bidId, eventSource: 'BiddingToolPage' });
      openSidebar({ mode: SidebarMode.TASK_PREVIEW, id: taskId });
      setActiveTab(activeTab);
    },
    [bidId, groupId, openSidebar, setActiveTab, taskId]
  );

  const onPaste = useCallback(
    (e: { clipboardData: { getData: (arg: string) => string } }) => {
      const pastedText = e.clipboardData.getData('text');

      if (pastedText.includes('\n') || pastedText.includes('\r\n')) {
        const rows = pastedText.split(/\r?\n/);
        const updateTask = rows[0];
        const newTasks = rows.slice(1);

        updateTasks({
          questions: [task],
          groupId,
          title: getTasksTitle(updateTask),
          content: updateTask
        });

        createBidTasks({
          questionGroupId: task.groupId,
          tasks: newTasks,
          bidId,
          eventSource: 'Group',
          mode: 'bulk'
        });

        exitEditing();
      }
    },
    [bidId, createBidTasks, exitEditing, groupId, task, updateTasks]
  );

  return (
    <div
      className={classNames(styles.task, classname, {
        [styles.isActive]: isTaskSelected || isDragging || isActionsActive || previewId === taskId
      })}
    >
      {!!onChangeSelection && (
        <Checkbox
          checked={isTaskSelected}
          onChange={e => onChangeSelection(task.id, e.target.checked)}
          className={styles.checkbox}
        />
      )}
      <ConditionalWrapper
        condition={!isEditing}
        wrapper={children => (
          <div className={styles.title} onClick={() => onClickTask(ActivePreviewTab.Info)}>
            {children}
          </div>
        )}
      >
        {isEditing ? (
          <EditableText
            className={classNames(styles.title, styles.isEditing)}
            onChange={onChange}
            onFinish={exitEditing}
            field={new BoxFieldString(task.title ?? '', task.title)}
            autoSize={true}
            autoFocus
            onPaste={onPaste}
          />
        ) : (
          <div className={styles.title}>
            <div className={classNames(styles.label, { [styles.isEmpty]: !task.title })}>
              {!!task.title ? <Tooltip title={task.title}>{task.title}</Tooltip> : t('BiddingTool.newTaskPlaceholder')}
            </div>
            {!selectedTasks.length && (
              <Button
                type={'text'}
                onClick={enterEditing}
                icon={<FontAwesomeIcon icon={faPen} className={styles.icon} />}
                className={styles.editButton}
              />
            )}
          </div>
        )}
      </ConditionalWrapper>
      <Button
        className={classNames(styles.comment, {
          [styles.hasComments]: !!task.comments.length
        })}
        type={'text'}
        icon={<FontAwesomeIcon icon={!!task.comments.length ? faCommentSolid : faCommentLight} />}
        onClick={() => onClickTask(ActivePreviewTab.Comments)}
      />
      <div className={styles.status}>
        <TaskStatusPicker
          groupId={groupId}
          questions={[task]}
          taskValue={task.status}
          disabled={!!selectedTasks.length}
          trackData={trackData}
          setActionsActive={setIsActionsActive}
        />
      </div>
      <div className={classNames(styles.fulfillment, { [styles.isActive]: !!task.fulfilment })}>
        <TaskFulfillmentPicker
          groupId={groupId}
          questions={[task]}
          taskValue={task.fulfilment}
          disabled={!!selectedTasks.length}
          trackData={trackData}
          setActionsActive={setIsActionsActive}
        />
      </div>
      <div className={classNames(styles.requirementType, { [styles.isActive]: !!task.requirementType })}>
        <TaskRequirementPicker
          groupId={groupId}
          questions={[task]}
          taskValue={task.requirementType}
          disabled={!!selectedTasks.length}
          trackData={trackData}
          setActionsActive={setIsActionsActive}
        />
      </div>
      <div className={classNames(styles.date, { [styles.isActive]: !!task.deadline })}>
        <TaskDatePicker
          groupId={groupId}
          questions={[task]}
          taskValue={task.deadline}
          trackData={trackData}
          isTaskDone={isTaskDone(task)}
          disabled={!!selectedTasks.length}
          setActionsActive={setIsActionsActive}
        />
      </div>
      {bidId && (
        <div className={classNames(styles.assignedTo, { [styles.isActive]: !!task.assignedTo })}>
          <AssignToQuestion
            assignedTo={task.assignedTo}
            questions={[task]}
            eventSource={'Bidding Tool'}
            wsId={wsId}
            groupId={groupId}
            bidId={bidId}
            disabled={!!selectedTasks.length}
            setActionsActive={setIsActionsActive}
          />
        </div>
      )}
      <Tooltip title={t('BiddingTool.deleteTask')}>
        <Button
          className={classNames(styles.delete, { [styles.isHidden]: !!selectedTasks.length })}
          type={'text'}
          icon={<FontAwesomeIcon icon={faTrash} onClick={onDeleteTask} />}
          disabled={!!selectedTasks.length}
        />
      </Tooltip>
    </div>
  );
};

export default BidTaskRow;
