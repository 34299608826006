import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BidItemType } from '@tendium/prom-types/tender';
import { faMemoCircleCheck } from '@fortawesome/pro-light-svg-icons';
import { ITenderBox, TenderBlockSize } from 'src/models/procurements/Tender/types';
import { TenderBoxMode } from 'src/models/procurements/Tender/types';
import { BlockTitle, TenderBox } from '..';
import { FeatureFlag, useFeatureFlag } from 'src/helpers';
import styles from './index.module.scss';
import { Translations } from 'src/lib/I18nService/types';

interface ShortDescriptionBoxProps {
  boxes?: ITenderBox[];
  originType?: BidItemType;
  className?: string;
}

export const ShortDescriptionBox: FC<ShortDescriptionBoxProps> = ({ boxes, originType, className }) => {
  const { t } = useTranslation(Translations.procurements);
  const isEditableBidFeature = !!useFeatureFlag(FeatureFlag.BiddingWorkflow_Preview);
  const displayShortDescription = useMemo(
    () => !!originType && [BidItemType.Manual, BidItemType.CallOff].includes(originType),
    [originType]
  );
  if (!displayShortDescription) {
    return null;
  }

  return (
    <div className={className}>
      <BlockTitle
        title={t('Description')}
        icon={{ defaultIcon: faMemoCircleCheck, color: 'var(--ui-cyan)' }}
        noBorder
      />
      <TenderBox
        boxes={boxes}
        title={''}
        as={TenderBoxMode.Collection}
        size={TenderBlockSize.Full}
        actions={false}
        isEditable={isEditableBidFeature}
        className={styles.tenderBox}
      />
    </div>
  );
};

export default ShortDescriptionBox;
