import React, { FC } from 'react';
import styles from './index.module.scss';
import { ReactNode } from 'react-markdown/lib/react-markdown';
import classNames from 'classnames';

interface Props {
  label: ReactNode;
  className?: string;
}
export const BlockLabel: FC<Props> = ({ label, className }) => {
  return <h6 className={classNames(styles.label, !!className && className)}>{label}</h6>;
};

export default BlockLabel;
