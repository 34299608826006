import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { confirm } from 'src/common';
import { QuickActions, QuickActionsItem } from 'src/shared/QuickActions';
import { BidChecklistSaveTemplate, BidChecklistTemplates } from '../Templates';
import modalStyles from 'src/common/Modal/index.module.scss';
import { useBidChecklist, useDeleteChecklistItems } from 'src/models/bids/BidChecklist/hooks';
import { useChecklistTemplates } from 'src/models/bids/BidChecklistTemplates/hooks';
import { trackEmptyChecklist } from 'src/segment/events/checklist';

export const BidChecklistActionMenu: FC = () => {
  const { t } = useTranslation();
  const [saveTmplModalVisible, setSaveTmplModalVisible] = useState(false);
  const [manageTmplsModalVisible, setManageTmplsModalVisible] = useState(false);
  const { data: templates } = useChecklistTemplates();
  const { data: model } = useBidChecklist();
  const bidId = model?.id;
  const [deleteChecklistItems, { loading: deleting }] = useDeleteChecklistItems();

  const onEmptyConfirm = useCallback(() => {
    !deleting && bidId && deleteChecklistItems({ bidId });
    trackEmptyChecklist();
  }, [bidId, deleteChecklistItems, deleting]);

  const onEmpty = useCallback(() => {
    confirm.confirm({
      title: t('Checklist.emptyChecklist'),
      okText: t('Common.confirm'),
      content: t('Checklist.confirmEmptyChecklist'),
      cancelText: t('Common.cancel'),
      className: modalStyles.confirmModal,
      centered: true,
      onOk: () => onEmptyConfirm()
    });
  }, [onEmptyConfirm, t]);

  const checklistActionsDisabled = useMemo(() => {
    return !model?.checklist.length;
  }, [model?.checklist.length]);

  const templateActionsDisabled = useMemo(() => {
    return !templates?.length;
  }, [templates?.length]);

  return (
    <>
      <QuickActions>
        <QuickActionsItem
          key={'saveAsTemplate'}
          title={t('Checklist.saveAsTemplate')}
          onClick={() => !checklistActionsDisabled && setSaveTmplModalVisible(true)}
          disabled={checklistActionsDisabled}
        />
        <QuickActionsItem
          key={'manageTemplates'}
          title={t('Checklist.manageTemplates')}
          onClick={() => !templateActionsDisabled && setManageTmplsModalVisible(true)}
          disabled={templateActionsDisabled}
        />
        <QuickActionsItem
          key={'emptyChecklist'}
          title={t('Checklist.emptyChecklist')}
          onClick={() => !checklistActionsDisabled && onEmpty()}
          disabled={checklistActionsDisabled}
          danger={!checklistActionsDisabled}
        />
      </QuickActions>
      {saveTmplModalVisible && <BidChecklistSaveTemplate onClose={() => setSaveTmplModalVisible(false)} />}
      {manageTmplsModalVisible && <BidChecklistTemplates onClose={() => setManageTmplsModalVisible(false)} />}
    </>
  );
};

export default BidChecklistActionMenu;
