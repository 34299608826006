import React, { FC } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserNinja } from '@fortawesome/pro-light-svg-icons';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import Spinner from 'src/common/Spinner';

interface Props {
  size: (typeof Sizes)[number];
  isActive?: boolean;
  loading?: boolean;
}
export const UserIcon: FC<Props> = ({ size = 'm', isActive, loading }) => {
  return (
    <span
      className={classNames(styles.icon, {
        [styles.isActive]: isActive,
        [styles.isLoading]: loading
      })}
    >
      <span
        className={classNames(styles.userPic, {
          [styles.small]: size === 'xs' || size === 's',
          [styles.medium]: size === 'm' || size === 'l' || size === 'xl'
        })}
      >
        {loading ? <Spinner /> : <FontAwesomeIcon icon={faUserNinja} className={styles.userIcon} />}
      </span>
      <span className={styles.plusPic}>
        <FontAwesomeIcon icon={faPlusCircle} className={styles.plusIcon} />
      </span>
    </span>
  );
};

export default UserIcon;
