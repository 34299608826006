import { gql } from '@apollo/client';
import { LOT_FIELDS } from '../queries';
import { BOX_FIELDS, CATEGORY_FILE_FIELDS } from '../Tender/fragments';

const TENDER_OUTCOME_FIELDS = gql`
  fragment outcomeTenderFields on Tender {
    id
    fileCategories {
      ...categoryFileFields
    }
    lots {
      ...lotFields
    }
    boxes {
      ...boxFields
    }
  }
  ${CATEGORY_FILE_FIELDS}
  ${BOX_FIELDS}
  ${LOT_FIELDS}
`;

export const GET_TENDER_OUTCOME = gql`
  query getTenderOutcome($procurementId: String!) {
    getTender(query: { id: $procurementId }) {
      ...outcomeTenderFields
    }
  }
  ${TENDER_OUTCOME_FIELDS}
`;
