import { faExternalLink, faFiles } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IProcurementFilesCategory } from 'src/models/procurements/Tender/types';
import { TextSelectOptions } from 'src/shared';
import styles from './index.module.scss';
import FilesContent from 'src/pages/TenderPage/ProcurementFiles/FilesContent';
import { useIsMobile } from 'src/reactiveVars';
import TenderFileDownload from 'src/shared/TenderFileDownload';

interface Props {
  procurementFiles: IProcurementFilesCategory;
  procurementName: string;
  procurementId: string;
  eventSource: string;
  showTitle?: boolean;
  className?: string;
  linkToDocsSource?: string;
  onSelectTextInDoc?: (data: TextSelectOptions, filePath: string) => JSX.Element;
}

export const ProcurementFiles: FC<Props> = props => {
  const {
    procurementFiles,
    procurementId,
    procurementName,
    className,
    linkToDocsSource,
    eventSource,
    showTitle = true
  } = props;
  const { filesAmount } = procurementFiles;
  const { t } = useTranslation();
  const [isMobile] = useIsMobile();

  return (
    <div className={classNames(styles.files, className)}>
      <section className={classNames(styles.section, styles.filesSection)}>
        {showTitle && (
          <h3 className={styles.sectionTitle}>
            <FontAwesomeIcon icon={faFiles} />
            {t('KeywordSearch.documents')}
          </h3>
        )}
        <div className={styles.filesContent}>
          {!!filesAmount ? (
            <>
              <FilesContent
                procurementFiles={procurementFiles}
                procurementId={procurementId}
                eventSource={eventSource}
              />
              <span className={styles.warningText}>{t('DetailsPage.docsWarning')}</span>
            </>
          ) : (
            <span className={styles.emptyText}>{t('DetailsPage.noDocsAvailable')}</span>
          )}
          {linkToDocsSource && (
            <div className={styles.filesLink}>
              <a className={styles.tenderLink} rel="noopener noreferrer" target="_blank" href={linkToDocsSource}>
                <FontAwesomeIcon icon={faExternalLink} className={styles.tenderLinkIcon} />
                <span>{t('DetailsPage.linkToDocsSource')}</span>
              </a>
            </div>
          )}
          {!!filesAmount && !isMobile && (
            <TenderFileDownload procurementName={procurementName} procurementId={procurementId} />
          )}
        </div>
      </section>
    </div>
  );
};

export default ProcurementFiles;
