import { gql } from '@apollo/client';
import {
  DECISION_MATRIX_FIELDS,
  DECISION_MATRIX_FIELDS_FULL,
  DECISION_MATRIX_ITEM_FIELDS,
  DECISION_MATRIX_TEMPLATE_FIELDS
} from './fragments';

export const UPDATE_DECISION_MATRIX_ITEMS = gql`
  mutation updateDecisionMatrixItems($input: UpdateDecisionMatrixItemsInput!) {
    updateDecisionMatrixItems(input: $input) {
      ...decisionMatrixItemFields
    }
  }
  ${DECISION_MATRIX_ITEM_FIELDS}
`;

export const CREATE_DECISION_MATRIX_ITEM = gql`
  mutation createDecisionMatrixItem($input: CreateDecisionMatrixItemInput!) {
    createDecisionMatrixItem(input: $input) {
      ...decisionMatrixItemFields
    }
  }
  ${DECISION_MATRIX_ITEM_FIELDS}
`;

export const DELETE_DECISION_MATRIX_ITEM = gql`
  mutation deleteDecisionMatrixItem($itemId: String!) {
    deleteDecisionMatrixItem(itemId: $itemId)
  }
`;

export const UPDATE_DECISION_MATRIX = gql`
  mutation updateDecisionMatrix($input: UpdateDecisionMatrixInput!) {
    updateDecisionMatrix(input: $input) {
      ...decisionMatrixFields
    }
  }
  ${DECISION_MATRIX_FIELDS}
`;

export const DELETE_DECISION_MATRIX_TEMPLATE = gql`
  mutation deleteDecisionMatrixTemplate($templateId: String!) {
    deleteDecisionMatrixTemplate(templateId: $templateId)
  }
`;

export const APPLY_DECISION_MATRIX = gql`
  mutation applyDecisionMatrix($input: CreateDecisionMatrixInput!) {
    applyDecisionMatrix(input: $input) {
      ...decisionMatrixFieldsFull
    }
  }
  ${DECISION_MATRIX_FIELDS_FULL}
`;

export const CREATE_DECISION_MATRIX_TEMPLATE = gql`
  mutation createDecisionMatrixTemplate($input: CreateDecisionMatrixTemplateInput!) {
    createDecisionMatrixTemplate(input: $input) {
      ...decisionMatrixTemplateFields
    }
  }
  ${DECISION_MATRIX_TEMPLATE_FIELDS}
`;

export const UPDATE_DECISION_MATRIX_TEMPLATE = gql`
  mutation updateDecisionMatrixTemplate($input: UpdateDecisionMatrixTemplateInput!) {
    updateDecisionMatrixTemplate(input: $input) {
      ...decisionMatrixTemplateFields
    }
  }
  ${DECISION_MATRIX_TEMPLATE_FIELDS}
`;
