import React, { FC, useMemo } from 'react';
import { AssignedTo, isUser } from 'src/models/users/types';
import { TeamName, UserName } from 'src/common';
import { UserNameProps } from 'src/common/UserName';
import { isTeam } from 'src/models/users/Team';
import { useAllUsersAndTeams } from 'src/models/users/hooks';

interface Props extends Omit<UserNameProps, 'user'> {
  assignedToId?: string | null;
  assignedToData?: AssignedTo | null;
}

export const Assignee: FC<Props> = ({ assignedToId, assignedToData, ...restProps }) => {
  const data = useAllUsersAndTeams();
  const assignedTo = useMemo(
    () => assignedToData ?? (assignedToId ? data.find(item => assignedToId === item.id) : null),
    [assignedToData, assignedToId, data]
  );

  if (!assignedTo) return null;

  return isUser(assignedTo) ? (
    <UserName user={assignedTo} bordered {...restProps} />
  ) : isTeam(assignedTo) ? (
    <TeamName team={assignedTo} bordered {...restProps} />
  ) : null;
};

export default Assignee;
