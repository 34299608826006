import React, { FC } from 'react';
import { Button, Spinner } from 'src/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { faPlus } from '@fortawesome/pro-light-svg-icons';

interface Props {
  isUpdating: boolean;
  onOpenMenu: () => void;
  disabled?: boolean;
}

const MultipleModeLabel: FC<Props> = props => {
  const { isUpdating, onOpenMenu, disabled } = props;
  const { t } = useTranslation();

  return (
    <Button disabled={!!disabled} onClick={onOpenMenu} type={'default'}>
      {isUpdating && <Spinner />}
      {!isUpdating && <FontAwesomeIcon icon={faPlus} />}
      <span> {t('MultipleBids.createAdditionalOpportunity')}</span>
    </Button>
  );
};

export default MultipleModeLabel;
