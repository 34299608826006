import { faCaretDown, faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useMemo } from 'react';
import { Select } from 'src/common';
import styles from './index.module.scss';
import { useRoomsWithAccess } from 'src/models/contentLibrary/ContentLibraryRoom/hooks';
import { toHSLColor } from 'src/helpers/styles';
import { FormInstance, SelectProps } from 'antd';

interface Props {
  placeholder?: string;
  form: FormInstance;
  mode?: SelectProps['mode'];
}

const RoomSelect: FC<Props> = ({ form, placeholder, mode }) => {
  const roomData = useRoomsWithAccess();

  const options = useMemo(() => {
    return roomData
      ? roomData.map(room => ({
          label: (
            <span>
              <FontAwesomeIcon icon={faCircle} color={toHSLColor(room.id)} />
              {` ${room.title}`}
            </span>
          ),
          value: room.id,
          title: room.title
        }))
      : [];
  }, [roomData]);

  return (
    <Select
      showSearch
      optionFilterProp={'value'}
      getPopupContainer={() => document.body}
      listHeight={125}
      dropdownAlign={{ offset: [0, 4] }}
      className={styles.selectRoom}
      filterOption={(input, option) =>
        !!option?.title && option.title.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      placeholder={placeholder}
      allowClear
      onClear={() => form.setFieldsValue({ roomId: undefined })}
      suffixIcon={<FontAwesomeIcon icon={faCaretDown} />}
      onChange={e => form.setFieldsValue({ roomId: e })}
      mode={mode}
    >
      {options.map(option => (
        <Select.Option key={option.value} value={option.value} title={option.title}>
          {option.label}
        </Select.Option>
      ))}
    </Select>
  );
};

export default RoomSelect;
