import { z } from 'zod';
import {
  isTenderVarsDiff,
  tendersSearchSchema,
  TendersSearchSchemaKeyType,
  TendersSearchSchemaType
} from '../tendersSearchSchema';
import { sortKeywordArgs, sortKeywordGroups } from '../../helpers';

export const keywordFilterSchema = tendersSearchSchema.pick({
  filterKeywordLanguages: true,
  highlightKeywordLanguages: true,
  keywordWidth: true,
  keywordArgs: true,
  keywordGroups: true
});
export type KeywordFilterSchemaType = z.infer<typeof keywordFilterSchema>;

export const keywordFilterKeys = Object.keys(keywordFilterSchema.shape) as TendersSearchSchemaKeyType[];

export function isKeywordFilterNoValue(vars?: TendersSearchSchemaType): boolean {
  return !vars?.keywordArgs?.length && !vars?.keywordGroups?.length;
}

export function isKeywordFilterDiff(
  vars?: TendersSearchSchemaType,
  compareToVars?: TendersSearchSchemaType,
  options?: { skipNoValue?: boolean }
): boolean {
  if (options?.skipNoValue && isKeywordFilterNoValue(vars)) {
    return false;
  }

  const parsed1 = vars
    ? {
        ...vars,
        keywordArgs: sortKeywordArgs(vars.keywordArgs),
        keywordGroups: sortKeywordGroups(vars.keywordGroups)
      }
    : undefined;
  const parsed2 = compareToVars
    ? {
        ...compareToVars,
        keywordArgs: sortKeywordArgs(compareToVars.keywordArgs),
        keywordGroups: sortKeywordGroups(compareToVars.keywordGroups)
      }
    : undefined;

  return isTenderVarsDiff(keywordFilterKeys, parsed1, parsed2);
}
