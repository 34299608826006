import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useContentLibrary } from 'src/models/contentLibrary/ContentLibrary/hooks';
import ContentLibrary from 'src/shared/ContentLibrary';
import { Skeleton } from 'src/common';
import { ToolboxHeader } from '../ToolboxHeader';
import { FeatureFlag } from '@tendium/prom-types';
import { useFeatureFlag } from 'src/helpers';

export const ToolboxContentLibrary: FC = () => {
  const { data } = useContentLibrary();
  const { t } = useTranslation();
  const isBiddingGPTContentLibrary = useFeatureFlag(FeatureFlag.BiddingTool_BiddingGPT_ContentLibrary);

  if (!isBiddingGPTContentLibrary) return null;

  return !!data?.loading ? (
    <Skeleton />
  ) : (
    <>
      <ToolboxHeader title={t('ContentLibrary.title')} />
      <ContentLibrary />
    </>
  );
};

export default ToolboxContentLibrary;
