import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useCallback } from 'react';
import { Menu } from 'src/common';
import { faCommentDots, faEye, faMemoCircleCheck } from '@fortawesome/pro-light-svg-icons';
import previewStyles from 'src/shared/InfoSidebar/index.module.scss';
import classNames from 'classnames';
import { ActivePreviewTab } from 'src/models/procurements/types';
import { useTranslation } from 'react-i18next';
import { usePreviewTabState } from 'src/models/procurements/hooks';

interface Props {
  isDraft?: boolean;
}

export const Tabs: FC<Props> = ({ isDraft = false }) => {
  const { t } = useTranslation();
  const [activeTab, setActivePreviewTab] = usePreviewTabState();

  const onChangeTab = useCallback(
    ({ key }: { key: string }) => {
      setActivePreviewTab(key as ActivePreviewTab);
    },
    [setActivePreviewTab]
  );

  return (
    <div className={previewStyles.container}>
      <Menu mode={'horizontal'} onClick={onChangeTab} selectedKeys={[activeTab]} className={previewStyles.navTabs}>
        <Menu.Item key={ActivePreviewTab.Info} className={previewStyles.navTab}>
          <span className={previewStyles.navTab}>
            <FontAwesomeIcon icon={faEye} className={previewStyles.navTabIcon} />
            <span className={previewStyles.navTabLabel}>{t('Tenders.overview')}</span>
          </span>
        </Menu.Item>

        <Menu.Item key={ActivePreviewTab.Comments} className={previewStyles.navTab}>
          <span className={previewStyles.navTab}>
            <FontAwesomeIcon icon={faCommentDots} className={previewStyles.navTabIcon} />
            <span className={previewStyles.navTabLabel}>{t('Comments.comments')}</span>
          </span>
        </Menu.Item>
        {!isDraft && (
          <Menu.Item key={ActivePreviewTab.RelatedSources} className={classNames(previewStyles.navTab)}>
            <span className={previewStyles.navTab}>
              <FontAwesomeIcon icon={faMemoCircleCheck} className={previewStyles.navTabIcon} />
              <span className={previewStyles.navTabLabel}>{t('BiddingTool.sources')}</span>
            </span>
          </Menu.Item>
        )}
      </Menu>
    </div>
  );
};
