import React, { ReactNode, FC } from 'react';
import { TenderNavContext } from './context';
import { useTenderNavData } from './hooks';
import { SidebarContext } from 'src/shared';

interface Props {
  children: ReactNode;
}

export const TenderNavDataProvider: FC<Props> = ({ children }) => {
  const tenderNavData = useTenderNavData();

  return <TenderNavContext.Provider value={tenderNavData}>{children}</TenderNavContext.Provider>;
};

export const TenderNavProvider: FC<Props> = ({ children }) => {
  return (
    <SidebarContext>
      <TenderNavDataProvider>{children}</TenderNavDataProvider>
    </SidebarContext>
  );
};
