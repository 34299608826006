import { gql } from '@apollo/client';

export const ON_TASK_GENERATION = gql`
  subscription onTaskGeneration {
    tasksGenerated {
      bidId
      status
      taskCount
    }
  }
`;
