import React, { FC, useMemo, ReactNode, useState, useEffect } from 'react';
import { TaskContext, TaskFormContext, TaskNavContext } from './context';
import { useLoadTask, useTask, useTaskNavCtx } from './hooks';
import { Form } from 'src/common';
import { useLocation } from 'react-router';

interface Props {
  children: ReactNode;
  taskId?: string;
}
export const TaskNavProvider: FC<Props> = ({ children }) => {
  const value = useTaskNavCtx();

  return <TaskNavContext.Provider value={value}>{children}</TaskNavContext.Provider>;
};

export type AnswerFormState = {
  yesNo?: boolean | null;
  freeText: string;
};

export const TaskProvider: FC<Props> = ({ children, taskId }) => {
  const { data, loading } = useLoadTask(taskId);
  const value = useMemo(() => ({ data: data ?? null, loading }), [data, loading]);

  return <TaskContext.Provider value={value}>{children}</TaskContext.Provider>;
};

export const TaskFormProvider: FC<Props> = ({ children }) => {
  const { pathname } = useLocation();
  const [, , taskId] = pathname.split('/');
  const [id, setId] = useState(taskId);
  const [isFocused, setIsFocused] = useState(false);

  const { data: task } = useTask();

  const [form] = Form.useForm<AnswerFormState>();
  const value = useMemo(() => ({ form, isFocused, setIsFocused }), [form, isFocused]);

  useEffect(() => {
    if (id !== taskId) {
      // Only reset form if we navigate to a new task
      // Avoid resetting when updating other task properties
      setId(taskId);
      form?.setFieldsValue({
        yesNo: task?.answer ? task.answer.answer : undefined,
        freeText: task?.answer?.content || ''
      });
    }
  }, [id, taskId, form, task]);

  return <TaskFormContext.Provider value={value}>{children}</TaskFormContext.Provider>;
};
