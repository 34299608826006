import React, { FC, useCallback } from 'react';
import { Input, Form } from 'src/common';
import styles from '../index.module.scss';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { FormInstance } from 'antd/lib/form';

interface IKeywordsInputProps {
  disabled?: boolean;
  label: string;
  existingSingleKeywords: string[];
  form: FormInstance<{ keyword: string }>;
  onPressEnter: (input: string) => void;
}

export const KeywordsInput: FC<IKeywordsInputProps> = props => {
  const { disabled, label, form, onPressEnter, existingSingleKeywords } = props;
  const { t } = useTranslation();

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>): void => {
      const value = form.getFieldValue('keyword');

      if (event.key === 'Enter' && value) {
        onPressEnter(value);
      }
    },
    [form, onPressEnter]
  );

  return (
    <li className={classNames(styles.keywordsItem, styles.isWide)}>
      <Form.Item
        className={styles.keywordCreateInput}
        name={'keyword'}
        rules={[
          () => ({
            validateTrigger: 'onBlur',
            async validator(_, value) {
              if (
                existingSingleKeywords.includes(String(value).toLowerCase()) ||
                String(value)
                  .split(',')
                  .some(item => existingSingleKeywords.includes(item.trim().toLowerCase()))
              )
                throw new Error(t('KeywordSearch.keywordAlreadyAddedError'));
              else if (
                String(value)
                  .split(',')
                  .some(item => item.split('+').length > 3)
              )
                throw new Error(t('Tenders.Keywords.youCanCombineThreeWordsError'));
            }
          })
        ]}
      >
        <Input type={'text'} placeholder={label} disabled={disabled} onKeyDown={onKeyDown} />
      </Form.Item>
    </li>
  );
};

export default KeywordsInput;
