import * as sessionReplay from '@amplitude/session-replay-browser';

/**
 * Analytics can be blocked by add-block or antivirus.
 * We can not be even sure that `analytics` variable is exist.
 * @param cb
 */
export function safeCallSegment(cb: () => void): void {
  if (typeof analytics === 'undefined') {
    return;
  }
  try {
    cb();
  } catch (e) {
    console.error(e);
  }
}

export const trackWithSessionReplay = (eventName: string, properties?: Record<string, unknown>): void => {
  safeCallSegment(() => {
    const sessionReplayProperties = sessionReplay.getSessionReplayProperties();
    analytics.track(eventName, {
      ...sessionReplayProperties,
      ...properties
    });
  });
};
