import React, { FC, useCallback, useMemo } from 'react';
import { useTask, useUpdateBidTasks } from 'src/models/bids/BidTask/hooks';
import { BlockTitle, TenderFile } from 'src/shared';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import EditableBlock from '../EditableBlock';
import { TASK_EDITOR_ICON_MAPPER } from 'src/models/bids/BidTask/mappers';
import TaskSummary from '../Summary';
import { useGetBidAttachments } from 'src/models/bids/BidAttachments/hooks';
import { PrivateFilesView } from '../PrivateFilesView';
import TaskAnswerEditor from '../TaskAnswerEditor';
import { emptyEditor } from 'src/models/canvas/constants';
import AttachmentList from 'src/pages/CanvasPage/CanvasEditor/components/CanvasBidTask/AttachmentList';

interface TaskOverviewProps {
  isDraft: boolean;
}

export const TaskOverview: FC<TaskOverviewProps> = ({ isDraft }) => {
  const { t } = useTranslation();

  const { data: task } = useTask();
  const { data: bidAttachmentsData } = useGetBidAttachments(task?.bidId ?? '');

  const [updateTasks] = useUpdateBidTasks();

  const primaryReference = task?.primaryReferences;
  const fileId = task?.groupFilePath?.split('/').at(-1);
  const privateFile = bidAttachmentsData?.find(att => att.document.id === fileId);

  const content = useMemo(() => {
    return task?.answer?.richContent;
  }, [task]);

  const onDescriptionSave = useCallback(
    (field?: string) => {
      task &&
        task.groupId &&
        updateTasks({
          questions: [task],
          groupId: task.groupId,
          content: field
        });
    },
    [task, updateTasks]
  );

  return (
    task && (
      <div className={styles.documentSection} key={task.id}>
        <div className={styles.section}>
          <div>
            <BlockTitle title={t('BiddingTool.description')} icon={TASK_EDITOR_ICON_MAPPER['description']} noBorder />
            <EditableBlock
              onSave={cont => onDescriptionSave(cont)}
              content={task?.content ?? ''}
              placeholder={t('BiddingTool.addTaskDescriptionPlaceholder')}
              className={styles.editableBlock}
            />
          </div>
          {task?.aiSummary && (
            <TaskSummary
              summary={task.aiSummary}
              mode={'taskPreview'}
              sourcesCount={task.secondaryReferences?.length}
            />
          )}
          {!privateFile && !!(task.filePath || (primaryReference && primaryReference.length > 0)) && (
            <TenderFile
              key={task.id}
              pdfHighlights={task.filePath ? task.highlights : primaryReference?.[0].allHighlights}
              procurementId={task.originId}
              targetPath={(task.filePath || primaryReference?.[0]?.reference.path) ?? ''}
              eventSource="Question - Task Page"
              flexAuto
            />
          )}
          {privateFile && (
            <PrivateFilesView
              attachment={privateFile}
              highlights={primaryReference?.[0]?.allHighlights ?? task?.highlights}
            />
          )}
          {!isDraft && (
            <div>
              <BlockTitle title={t('BiddingTool.answer')} icon={TASK_EDITOR_ICON_MAPPER['answer']} noBorder />
              <TaskAnswerEditor
                editorContent={content ? { type: 'doc', content: JSON.parse(content) } : emptyEditor}
                groupId={task.groupId}
                taskId={task.id}
              />
              {task?.answer?.id && (
                <AttachmentList attachments={task.answer?.attachments?.list} bidTaskAnswerId={task.answer.id} />
              )}
            </div>
          )}
        </div>
      </div>
    )
  );
};
