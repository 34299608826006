import React, { FC, useCallback, useMemo } from 'react';
import { Button, Checkbox, confirm } from 'src/common';
import { AssignToQuestion, TaskFulfillmentPicker, TaskRequirementPicker, TaskStatusPicker } from 'src/shared';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { useBidding, useBiddingGroup, useOpenTaskGroupsPrefs, useTasks } from 'src/models/bids/BidFull/hooks';
import modalStyles from 'src/common/Modal/index.module.scss';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDeleteBidTasks } from 'src/models/bids/BidTask/hooks';
import { TaskTrackPickerData } from 'src/segment/events';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { TaskDatePicker } from 'src/shared/Bid/TaskDatePicker';
import { TasksState } from 'src/models/bids/BidFull/context';
import TasksGroupTitle from 'src/shared/Bid/TasksGroupTitle';

interface Props {
  groupId: string;
}
export const TaskGroupTitleBulk: FC<Props> = ({ groupId }) => {
  const { t } = useTranslation();
  const [openTaskGroupsIds, setOpenTasksGroups] = useOpenTaskGroupsPrefs();

  const { data } = useBidding();
  const bidId = data?.id;
  const group = useBiddingGroup(groupId);

  const tasksState = useTasks();
  const { selectedTasks, setSelectedTaskIds, setOffset } = tasksState;
  const [deleteTasks] = useDeleteBidTasks();

  const selectedTaskIds = useMemo(() => selectedTasks.map(item => item.id), [selectedTasks]);
  const isExpanded = openTaskGroupsIds?.includes(groupId);
  const trackData: TaskTrackPickerData = useMemo(
    () => ({ bidId, eventSource: 'Overview', mode: 'bulk', groupId }),
    [bidId, groupId]
  );

  const toggleGroup = useCallback(() => {
    setOpenTasksGroups(groupId);
  }, [setOpenTasksGroups, groupId]);

  const getTaskIds = useCallback((tasks: TasksState) => {
    const { tasksResponseData, offset, limit } = tasks;

    if (tasksResponseData && tasksResponseData.data) {
      const taskIdsWithFilters = tasksResponseData.data.tasks.map(item => (item ? item.id : null));

      return taskIdsWithFilters.slice(offset, offset + limit).filter(Boolean) as string[];
    }

    return [];
  }, []);

  const onSelectAllTasks = useCallback(
    (e: CheckboxChangeEvent) => {
      const isSelected = e.target.checked;
      if (isSelected) {
        const taskIds = getTaskIds(tasksState);
        const taskIdsSet = new Set([...selectedTaskIds, ...taskIds]);

        setSelectedTaskIds(Array.from(taskIdsSet));
      } else {
        setSelectedTaskIds([]);
      }
    },
    [getTaskIds, tasksState, selectedTaskIds, setSelectedTaskIds]
  );

  const isAllSelected = getTaskIds(tasksState).every(item => selectedTaskIds.includes(item));

  const onRemoveTasks = useCallback(() => {
    confirm.confirm({
      title: t('BiddingTool.BulkActions.deleteTasks'),
      okText: t('Common.delete'),
      content: t('BiddingTool.BulkActions.deleteTasksWarning'),
      cancelText: t('Common.cancel'),
      className: modalStyles.confirmModal,
      centered: true,
      onOk() {
        if (groupId) {
          deleteTasks({
            questionIds: selectedTasks.map(item => item.id),
            groupId,
            mode: 'bulk',
            eventSource: 'Bidding Tool page'
          });

          setOffset(0);
        }
      }
    });
  }, [t, groupId, deleteTasks, selectedTasks, setOffset]);

  return group ? (
    <>
      <TasksGroupTitle
        title={group.title}
        groupId={group.id}
        isGroupOpen={isExpanded}
        actionsAvailable={true}
        onToggle={toggleGroup}
      />
      {selectedTasks.length > 0 && (
        <div className={styles.heading}>
          <Checkbox
            indeterminate={!!selectedTasks.length && !isAllSelected}
            onChange={onSelectAllTasks}
            checked={isAllSelected}
            className={styles.checkbox}
          />
          <span className={styles.toolsLabel}>{`${selectedTasks.length} ${
            selectedTasks.length === 1
              ? t('BiddingTool.BulkActions.selectedSingular')
              : t('BiddingTool.BulkActions.selectedPlural')
          }`}</span>

          <div className={styles.status}>
            <TaskStatusPicker
              groupId={groupId}
              questions={selectedTasks}
              trackData={trackData}
              label={t('Common.status')}
            />
          </div>
          <div className={styles.fulfillment}>
            <TaskFulfillmentPicker groupId={groupId} questions={selectedTasks} trackData={trackData} />
          </div>
          <div className={styles.requirementType}>
            <TaskRequirementPicker groupId={groupId} questions={selectedTasks} trackData={trackData} />
          </div>
          <div className={styles.date}>
            <TaskDatePicker groupId={groupId} questions={selectedTasks} trackData={trackData} />
          </div>
          {bidId && (
            <div className={styles.assignedTo}>
              <AssignToQuestion
                questions={selectedTasks}
                wsId={data.workspaceId}
                groupId={groupId}
                bidId={bidId}
                eventSource={'Bidding Tool'}
              />
            </div>
          )}
          <Button type={'text'} icon={<FontAwesomeIcon icon={faTrash} />} onClick={() => onRemoveTasks()} />
        </div>
      )}
    </>
  ) : null;
};

export default TaskGroupTitleBulk;
