import { useCallback, useMemo } from 'react';
import { usePagePreferences } from '../users/Preferences/hooks';
import {
  ApiDecisionMatrix,
  ApiDecisionMatrixTemplate,
  ApiDecisionMatrixTemplateResponse,
  CreateDecisionMatrixItemResponse,
  CreateDecisionMatrixTemplate,
  DecisionMatrixItem,
  UpdateBidDecisionMatrixInput
} from './types';
import { QueryResult, useMutation, useQuery } from '@apollo/client';
import {
  APPLY_DECISION_MATRIX,
  CREATE_DECISION_MATRIX_ITEM,
  CREATE_DECISION_MATRIX_TEMPLATE,
  DELETE_DECISION_MATRIX_ITEM,
  DELETE_DECISION_MATRIX_TEMPLATE,
  UPDATE_DECISION_MATRIX,
  UPDATE_DECISION_MATRIX_ITEMS,
  UPDATE_DECISION_MATRIX_TEMPLATE
} from './mutations';
import { GET_DECISION_MATRIX_TEMPLATES } from './queries';
import { DecisionMatrixTemplate } from '.';
import { useTranslation } from 'react-i18next';
import { notification } from 'src/common';
import {
  updateCacheCreateDecisionMatrixItem,
  updateCacheCreateDecisionMatrixTemplate,
  updateCacheOnApplyDecisionMatrix,
  updateCacheOnDeleteDecisionMatrixItem,
  updateCacheOnDeleteDecisionMatrixTemplate
} from './cacheHandlers';
import { useWorkspace } from '../workspace/hooks';
import { WorkspaceStatusCategory } from '@tendium/prom-types/tender';
import { PageName } from '../users/Preferences/types';

export function useDefaultDecisionMatrixTemplate(): [string | undefined, (templateId?: string) => void] {
  const [page, updatePage] = usePagePreferences(PageName.bids);

  const defaultDecisionMatrixTemplateId = useMemo(() => {
    if (!page || !page.defaultDecisionMatrixTemplate) {
      return;
    }
    return page.defaultDecisionMatrixTemplate;
  }, [page]);

  const saveDefaultDecisionMatrixTemplate = useCallback(
    (templateId?: string) => {
      updatePage({ defaultDecisionMatrixTemplate: templateId });
    },
    [updatePage]
  );

  return useMemo(() => {
    return [defaultDecisionMatrixTemplateId, saveDefaultDecisionMatrixTemplate];
  }, [defaultDecisionMatrixTemplateId, saveDefaultDecisionMatrixTemplate]);
}

export function useGetDecisionMatrixTemplates(skip?: boolean): Omit<
  QueryResult<ApiDecisionMatrixTemplateResponse>,
  'data'
> & {
  data?: ApiDecisionMatrixTemplate[];
} {
  const query = useQuery<ApiDecisionMatrixTemplateResponse>(GET_DECISION_MATRIX_TEMPLATES, {
    skip: !!skip
  });
  return useMemo(
    () => ({
      ...query,
      data: query.data
        ? query.data.getDecisionMatrixTemplates.map(template => new DecisionMatrixTemplate(template))
        : undefined
    }),
    [query]
  );
}

export function useUpdateDecisionMatrixItems(): [
  (items: DecisionMatrixItem[]) => void,
  { loading: boolean; error: Error | undefined }
] {
  const { t } = useTranslation();
  const [updateDecisionMatrixItems, { loading, error }] = useMutation(UPDATE_DECISION_MATRIX_ITEMS);

  const updateDecisionMatrixItemsFn = useCallback(
    (items: DecisionMatrixItem[]) => {
      updateDecisionMatrixItems({
        variables: { input: { items } }
      }).catch(() => {
        notification.error({
          description: t('Common.unknownErrorDesc'),
          message: t('Common.unknownError')
        });
      });
    },
    [updateDecisionMatrixItems, t]
  );
  return [updateDecisionMatrixItemsFn, { loading, error }];
}

export function useCreateDecisionMatrixItem(): [
  (matrixId: string, title: string, onComplete?: (id: string) => void) => void,
  { loading: boolean; error: Error | undefined }
] {
  const { t } = useTranslation();
  const [createDecisionMatrixItem, { loading, error }] = useMutation<
    CreateDecisionMatrixItemResponse,
    { input: { matrixId: string; title: string } }
  >(CREATE_DECISION_MATRIX_ITEM);

  const createDecisionMatrixItemFn = useCallback(
    (matrixId: string, title: string, onComplete?: (id: string) => void) => {
      createDecisionMatrixItem({
        variables: { input: { matrixId, title } },
        update: updateCacheCreateDecisionMatrixItem(matrixId)
      })
        .then(({ data }) => {
          if (data && data.createDecisionMatrixItem.id) {
            onComplete?.(data.createDecisionMatrixItem.id);
          }
        })
        .catch(() => {
          notification.error({
            description: t('Common.unknownErrorDesc'),
            message: t('Common.unknownError')
          });
        });
    },
    [createDecisionMatrixItem, t]
  );
  return [createDecisionMatrixItemFn, { loading, error }];
}

export function useDeleteDecisionMatrixItem(): [
  (matrixId: string, itemId: string) => void,
  { loading: boolean; error: Error | undefined }
] {
  const { t } = useTranslation();
  const [deleteDecisionMatrixItem, { loading, error }] = useMutation(DELETE_DECISION_MATRIX_ITEM);

  const deleteDecisionMatrixItemFn = useCallback(
    (matrixId: string, itemId: string) => {
      deleteDecisionMatrixItem({
        variables: { itemId },
        update: updateCacheOnDeleteDecisionMatrixItem(matrixId, itemId)
      }).catch(() => {
        notification.error({
          description: t('Common.unknownErrorDesc'),
          message: t('Common.unknownError')
        });
      });
    },
    [deleteDecisionMatrixItem, t]
  );
  return [deleteDecisionMatrixItemFn, { loading, error }];
}

export function useUpdateDecisionMatrix(): [
  (input: UpdateBidDecisionMatrixInput) => void,
  { loading: boolean; error: Error | undefined }
] {
  const { t } = useTranslation();
  const [updateDecisionMatrix, { loading, error }] = useMutation(UPDATE_DECISION_MATRIX);

  const updateDecisionMatrixFn = useCallback(
    (input: UpdateBidDecisionMatrixInput) => {
      updateDecisionMatrix({
        variables: { input }
      }).catch(() => {
        notification.error({
          description: t('Common.unknownErrorDesc'),
          message: t('Common.unknownError')
        });
      });
    },
    [updateDecisionMatrix, t]
  );
  return [updateDecisionMatrixFn, { loading, error }];
}

export function useDeleteDecisionMatrixTemplate(): [
  (templateId: string) => void,
  { loading: boolean; error: Error | undefined }
] {
  const { t } = useTranslation();
  const [deleteDecisionMatrixTemplate, { loading, error }] = useMutation(DELETE_DECISION_MATRIX_TEMPLATE);

  const deleteDecisionMatrixTemplateFn = useCallback(
    (templateId: string) => {
      deleteDecisionMatrixTemplate({
        variables: { templateId },
        update: updateCacheOnDeleteDecisionMatrixTemplate(templateId)
      }).catch(() => {
        notification.error({
          description: t('Common.unknownErrorDesc'),
          message: t('Common.unknownError')
        });
      });
    },
    [deleteDecisionMatrixTemplate, t]
  );
  return [deleteDecisionMatrixTemplateFn, { loading, error }];
}

export function useApplyDecisionMatrix(): [
  (bidId: string, templateId: string) => void,
  { loading: boolean; error: Error | undefined }
] {
  const { t } = useTranslation();
  const [applyDecisionMatrix, { loading, error }] = useMutation(APPLY_DECISION_MATRIX);

  const applyDecisionMatrixFn = useCallback(
    (bidId: string, templateId: string) => {
      applyDecisionMatrix({
        variables: { input: { bidId, templateId } },
        update: updateCacheOnApplyDecisionMatrix(bidId)
      }).catch(() => {
        notification.error({
          description: t('Common.unknownErrorDesc'),
          message: t('Common.unknownError')
        });
      });
    },
    [applyDecisionMatrix, t]
  );
  return [applyDecisionMatrixFn, { loading, error }];
}

export function useCreateDecisionMatrixTemplate(): [
  (input: CreateDecisionMatrixTemplate) => void,
  { loading: boolean; error: Error | undefined }
] {
  const { t } = useTranslation();
  const [createDecisionMatrixTemplate, { loading, error }] = useMutation(CREATE_DECISION_MATRIX_TEMPLATE);

  const createDecisionMatrixTemplateFn = useCallback(
    (input: CreateDecisionMatrixTemplate) => {
      createDecisionMatrixTemplate({
        variables: { input },
        update: updateCacheCreateDecisionMatrixTemplate()
      }).catch(() => {
        notification.error({
          description: t('Common.unknownErrorDesc'),
          message: t('Common.unknownError')
        });
      });
    },
    [createDecisionMatrixTemplate, t]
  );
  return [createDecisionMatrixTemplateFn, { loading, error }];
}

export function useUpdateDecisionMatrixTemplate(): [
  (title: string, id: string) => void,
  { loading: boolean; error: Error | undefined }
] {
  const { t } = useTranslation();
  const [updateDecisionMatrixTemplate, { loading, error }] = useMutation(UPDATE_DECISION_MATRIX_TEMPLATE);

  const updateDecisionMatrixTemplateFn = useCallback(
    (title: string, id: string) => {
      updateDecisionMatrixTemplate({
        variables: { input: { title, id } }
      }).catch(() => {
        notification.error({
          description: t('Common.unknownErrorDesc'),
          message: t('Common.unknownError')
        });
      });
    },
    [updateDecisionMatrixTemplate, t]
  );
  return [updateDecisionMatrixTemplateFn, { loading, error }];
}

export function useIsBidStatusInteresting(bidStatusId?: string, workspaceId?: string): boolean {
  const { data: workspace } = useWorkspace(workspaceId);

  return useMemo(() => {
    return (
      workspace?.statuses.find(status => status.id === bidStatusId)?.category === WorkspaceStatusCategory.Interesting
    );
  }, [bidStatusId, workspace?.statuses]);
}

export function useDecisionMatrixScores(decisionMatrix?: ApiDecisionMatrix): {
  totalScore?: number;
  avgScore?: number;
} {
  const totalScore = useMemo(() => {
    return decisionMatrix?.items.reduce((total, item) => total + item.score, 0);
  }, [decisionMatrix]);

  const avgScoreRounded = useMemo(() => {
    if (!totalScore || !decisionMatrix) return;
    return (totalScore / decisionMatrix.items.length).toFixed(2);
  }, [decisionMatrix, totalScore]);

  const avgScore = avgScoreRounded ? parseFloat(avgScoreRounded) : undefined;

  return { totalScore, avgScore };
}
