import React, { FC } from 'react';
import { Translations } from 'src/lib/I18nService/types';
import { useExistingTranslation } from 'src/common/hooks';
import classNames from 'classnames';
import styles from './index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightFromSquare } from '@fortawesome/pro-light-svg-icons';
import { IBoxFieldUrl } from 'src/models/procurements/Tender/types';
import BlockEmpty from '../../Empty';
import { IBlockReadProps } from 'src/shared/Tender/Boxes/types';
import { Tooltip } from 'src/common';
import { useIsMobile } from 'src/reactiveVars';

interface Props extends IBlockReadProps, Pick<IBoxFieldUrl, 'url' | 'title'> {}
export const ReadOnlyBlockLink: FC<Props> = ({ url, title, translation, className, config }) => {
  const { existingT: t } = useExistingTranslation(translation ?? Translations.procurements);
  const [isMobile] = useIsMobile();

  return url ? (
    config?.isTooltip ? (
      <Tooltip title={url}>
        <a
          className={classNames(styles.block, {
            [styles.blockMobile]: isMobile
          })}
          href={url}
          rel={'noopener noreferrer'}
          target={'_blank'}
        >
          <FontAwesomeIcon icon={faUpRightFromSquare} />
          <span className={classNames(styles.title, className)}>{title ? t(title) : url}</span>
        </a>
      </Tooltip>
    ) : (
      <a
        className={classNames(styles.block, {
          [styles.blockMobile]: isMobile
        })}
        href={url}
        rel={'noopener noreferrer'}
        target={'_blank'}
      >
        <FontAwesomeIcon icon={faUpRightFromSquare} />
        <span className={classNames(styles.title, className)}>{title ? t(title) : url}</span>
      </a>
    )
  ) : (
    <BlockEmpty />
  );
};

export default ReadOnlyBlockLink;
