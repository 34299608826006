import React, { CSSProperties, FC, useEffect, useMemo } from 'react';
import { useDrag, DragSourceMonitor } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { DndType } from 'src/types/dnd';
import { IBids, IBidsTableDndItem } from 'src/models/bids/Bids/types';
import useSelectedBids from 'src/reactiveVars/SelectedBidsVar';

interface Props {
  record: IBids;
  className?: string;
  style?: CSSProperties;
  [x: string]: unknown;
}

export const BidsDrag: FC<Props> = ({ record, className, style, ...restProps }: Props) => {
  const [selectedBids] = useSelectedBids();

  const dndBidsTableItem: IBidsTableDndItem = useMemo(() => {
    const bidIds = !!selectedBids.length
      ? Array.from(
          new Set([
            ...selectedBids.map(bid => {
              return { id: bid.id, wsId: bid.workspace.id };
            })
          ])
        )
      : [{ id: record.id, wsId: record.workspace.id }];
    return {
      type: DndType.BidsTableItem,
      dndItem: record,
      bidIds
    };
  }, [record, selectedBids]);
  const [{ opacity }, drag, preview] = useDrag({
    type: DndType.BidsTableItem,
    item: dndBidsTableItem,

    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
      item: monitor.getItem(),
      opacity: monitor.isDragging() ? 0.4 : 1
    })
  });

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  return <tr ref={drag} className={className} style={{ cursor: 'move', opacity, ...style }} {...restProps} />;
};

const RowComponent: FC<Props> = props => {
  const { record } = props;
  if (record) {
    return <BidsDrag {...props} />;
  } else {
    return <tr {...props} />;
  }
};

export default RowComponent;
