import { z } from 'zod';
import {
  isTenderVarsDiff,
  tendersSearchSchema,
  TendersSearchSchemaKeyType,
  TendersSearchSchemaType
} from '../tendersSearchSchema';

export const onlyUnhandledFilterSchema = tendersSearchSchema.pick({ onlyUnhandled: true });
export type OnlyUnhandledSchemaType = z.infer<typeof onlyUnhandledFilterSchema>;
export const onlyUnhandledFilterKeys = Object.keys(onlyUnhandledFilterSchema.shape) as TendersSearchSchemaKeyType[];

export function isOnlyUnhandledFilterDiff(vars1?: TendersSearchSchemaType, vars2?: TendersSearchSchemaType): boolean {
  return isTenderVarsDiff(onlyUnhandledFilterKeys, vars1, vars2);
}
