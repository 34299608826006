import { isEmpty, isPlainObject } from 'lodash';

/**
 * Filter keys in the object with a specified handle function
 */
export function objectFilter<T extends Record<string, unknown>>(
  obj: T,
  filter: (key: keyof T, value: unknown) => boolean
): Partial<T> {
  return Object.fromEntries(Object.entries(obj).filter(([key, value]) => filter(key, value))) as Partial<T>;
}

/**
 * Only include specified keys in the object
 */
export function objectIncludeKeysFilter<T extends Record<string, unknown>>(obj: T, includedKeys: string[]): Partial<T> {
  return Object.fromEntries(
    Object.entries(obj).filter(([key]) => includedKeys.some(field => field === key))
  ) as Partial<T>;
}

/**
 * Exclude specified keys in the object
 */
export function objectExcludeKeysFilter<T extends Record<string, unknown>>(obj: T, excludedKeys: string[]): Partial<T> {
  return Object.fromEntries(
    Object.entries(obj).filter(([key]) => !excludedKeys.some(field => field === key))
  ) as Partial<T>;
}

function isObject(value: unknown): boolean {
  return isPlainObject(value) && value !== null;
}

export function isEmptyObject(value: unknown): boolean {
  return isObject(value) && isEmpty(value);
}

export function isEmptyArray(value: unknown): boolean {
  return Array.isArray(value) && value.length === 0;
}
/**
 * Remove keys from the object with empty object/array, null and undefined value
 */
export function objectDeepClean<T extends Record<string, unknown>>(obj: T | undefined | null): Partial<T> | undefined {
  if (!obj) {
    return;
  }
  const cleanedObj = Object.entries(obj)
    .filter(([, value]) => {
      return value !== undefined && value !== null && !isEmptyArray(value) && !isEmptyObject(value);
    })
    .reduce((acc, [key, value]) => {
      if (isObject(value)) {
        const cleanedValue = objectDeepClean(value as Record<string, unknown>);
        if (cleanedValue && !isEmptyObject(cleanedValue)) {
          acc[key as keyof T] = cleanedValue as T[keyof T];
        }
      } else {
        acc[key as keyof T] = value as T[keyof T];
      }
      return acc;
    }, {} as Partial<T>);

  if (isEmptyObject(cleanedObj)) {
    return;
  }
  return cleanedObj;
}
