import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, EmptyFilter, Skeleton } from 'src/common';
import { useContentLibrary } from 'src/models/contentLibrary/ContentLibrary/hooks';
import styles from './index.module.scss';
import ContentLibraryStatusBar from './ContentLibraryStatusBar';
import { Content } from 'src/models/contentLibrary/ContentLibrary';
import { ContentLibraryFilters } from './ContentLibaryFilters';
import ContentLibraryCard from './ContentLibraryCard';
import { faCardsBlank } from '@fortawesome/pro-light-svg-icons';
import { trackBTShowMore } from 'src/segment/events';
import { useTask } from 'src/models/bids/BidTask/hooks';
import { FeatureFlag, useFeatureFlag } from 'src/helpers';
import { ContentLibraryDocumentCard } from './ContentLibraryDocumentCard/index';
import { ContentLibraryType } from '@tendium/prom-types/schema';
import { Grid } from '@tendium/component-library';
import { useCurrentSidebar } from '../InfoSidebar/hooks';
import { SidebarMode } from '../InfoSidebar/types';
import classNames from 'classnames';

export const ContentLibrary: FC = () => {
  const { t } = useTranslation();
  const { data: task } = useTask(); // TODO Deprecated?
  const { data: state, offset, limit, setOffset, setShowContentCardModal, setSelectedContent } = useContentLibrary();

  const sidebarState = useCurrentSidebar();
  const isSidebarMode =
    sidebarState?.mode === SidebarMode.CONTENT_LIBRARY || sidebarState?.mode === SidebarMode.TOOLBOX_CONTENT_LIBRARY;

  const isBiddingGPTContentLibrary = useFeatureFlag(FeatureFlag.BiddingTool_BiddingGPT_ContentLibrary);
  const content = useMemo(() => {
    return state?.data ? state.data.content : [];
  }, [state?.data]);

  const isMore = useMemo(() => {
    if (content.length === state?.data?.totalCount) return false;
    return state?.data && offset + limit <= state.data.totalCount;
  }, [content.length, limit, offset, state?.data]);

  const onShowMore = useCallback(() => {
    if (task) {
      trackBTShowMore(task.id, task.groupId, task.bidId);
    }
    state?.fetchMore({ variables: { offset: offset + limit } }).then(() => setOffset(offset + limit));
  }, [task, state, offset, limit, setOffset]);

  const onEditContentCard = useCallback(
    (content: Content) => {
      setSelectedContent(content);
      setShowContentCardModal(true);
    },
    [setSelectedContent, setShowContentCardModal]
  );

  const noCards = useMemo(() => {
    return !state?.isFiltersApplied && content.length <= 0;
  }, [content.length, state?.isFiltersApplied]);

  const noSearchResult = useMemo(() => {
    return !state?.currentVars.filters.search?.length;
  }, [state?.currentVars.filters]);

  return (
    <div className={classNames(styles.container, { [styles.isSidebarMode]: isSidebarMode })}>
      {isBiddingGPTContentLibrary && <ContentLibraryFilters />}
      {!isSidebarMode && <ContentLibraryStatusBar />}
      {state?.loading ? (
        <Skeleton active={state.loading} />
      ) : (
        <Grid className={styles.grid}>
          {content.length ? (
            content.map(c =>
              c ? (
                c.type === ContentLibraryType.DOCUMENT ? (
                  <Grid.Item key={c.id} cols={{ sm: 12, lg: !isSidebarMode ? 6 : undefined }}>
                    <ContentLibraryDocumentCard
                      key={c.id}
                      content={c}
                      id={c.id}
                      onEditContentCard={onEditContentCard}
                    />
                  </Grid.Item>
                ) : (
                  <Grid.Item key={c.id} cols={{ sm: 12, lg: !isSidebarMode ? 6 : undefined }}>
                    <ContentLibraryCard key={c.id} content={c} id={c.id} onEditContentCard={onEditContentCard} />
                  </Grid.Item>
                )
              ) : null
            )
          ) : (
            <Grid.Item cols={{ sm: 12 }}>
              <EmptyFilter
                title={
                  noCards
                    ? t('ContentLibrary.noCards')
                    : noSearchResult
                    ? t('ContentLibrary.noMatch')
                    : t('ContentLibrary.noSearch')
                }
                desc={
                  noCards
                    ? t('ContentLibrary.noCardsDesc')
                    : noSearchResult
                    ? t('ContentLibrary.noMatchDesc')
                    : t('ContentLibrary.noSearchDesc')
                }
                className={styles.noResults}
                icon={noCards ? faCardsBlank : undefined}
              />
            </Grid.Item>
          )}
        </Grid>
      )}

      {isMore && (
        <div className={styles.showMore}>
          <Button type={'link'} onClick={onShowMore} loading={state?.fetchingMore}>
            {t('Common.showMore')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default ContentLibrary;
