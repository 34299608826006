import { Button, Form, Input, Modal } from 'src/common';
import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useBidChecklist } from 'src/models/bids/BidChecklist/hooks';
import { useCreateChecklistTemplate } from 'src/models/bids/BidChecklistTemplates/hooks';
import { trackSaveChecklistTemplate } from 'src/segment/events/checklist';

interface Props {
  onClose: () => void;
}

export const BidChecklistSaveTemplate: FC<Props> = props => {
  const { onClose } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [createTemplate, { loading }] = useCreateChecklistTemplate();
  const { data: model } = useBidChecklist();
  const bidId = model?.id;
  const initialValues = useMemo(() => {
    return {
      title: ''
    };
  }, []);
  const onSaveTemplate = useCallback(
    ({ title }: { title: string }) => {
      bidId && createTemplate({ bidId, name: title }, onClose);
    },
    [bidId, createTemplate, onClose]
  );

  const formSubmit = (): void => {
    form.submit();
    trackSaveChecklistTemplate();
  };
  return (
    <Modal
      visible
      title={t('Checklist.saveAsNewTemplate')}
      onCancel={onClose}
      footer={[
        <Button key="cancel" type={'text'} onClick={onClose}>
          {t('Common.cancel')}
        </Button>,
        <Button key={'save'} type={'primary'} onClick={formSubmit} disabled={loading} loading={loading}>
          {t('Common.save')}
        </Button>
      ]}
    >
      <Form form={form} initialValues={initialValues} layout={'vertical'} onFinish={onSaveTemplate}>
        <Form.Item
          label={t('Checklist.nameOfTemplate')}
          name="title"
          rules={[{ required: true, message: t('Checklist.templateNameIsRequired') }]}
        >
          <Input name="title" type="text" maxLength={30} placeholder={t('Common.name')} autoFocus />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default BidChecklistSaveTemplate;
