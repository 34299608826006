import { i18n } from 'i18next';

export interface II18nService {
  readonly i18n: i18n;
}

export enum Translations {
  common = 'common',
  country = 'country',
  cpvCodes = 'cpvCodes',
  nutsCodes = 'nutsCodes',
  procurements = 'procurements',
  cognitoErrors = 'cognitoErrors',
  errors = 'errors'
}

interface ServerTranslation {
  backend: Record<string, string>;
}

export interface ServerTranslations {
  en: ServerTranslation;
  sv: ServerTranslation;
}

// TODO: move all date-formats to this enum and check which of theme we need/use/name it
export enum DateFormatTranslation {
  full = 'Common.Format.detailsDateFormat',
  shortMonth = 'Common.Format.tableDateFormatForShortMonths'
}
