import { FeatureFlag } from '@tendium/prom-types';
import React, { FC } from 'react';
import { useFeatureFlag } from 'src/helpers';
import { RemindersProvider } from 'src/models/reminders/providers';
import styles from './index.module.scss';
import { RemindersList, RemindersInput } from 'src/shared/Reminders';
import { useTranslation } from 'react-i18next';
import { useBidding } from 'src/models/bids/BidFull/hooks';
import { useReminders } from 'src/models/reminders/hooks';
import { Skeleton } from 'src/common';
import { ToolboxHeader } from '../ToolboxHeader';

interface Props {
  bidId: string;
}

export const ToolboxReminders: FC<Props> = props => {
  const { bidId } = props;
  const { t } = useTranslation();

  const { data } = useBidding();
  const { loading } = useReminders();

  const isRemindersFeature = useFeatureFlag(FeatureFlag.Reminders);

  return !!loading ? (
    <Skeleton />
  ) : (
    <>
      {!!isRemindersFeature && (
        <RemindersProvider relationId={bidId} id={bidId} wsId={data?.workspaceId}>
          <ToolboxHeader title={t('Toolbox.reminders')} />

          <div className={styles.remindersList}>
            <RemindersList />
          </div>

          <div className={styles.remindersInput}>
            <RemindersInput />
          </div>
        </RemindersProvider>
      )}
    </>
  );
};

export default ToolboxReminders;
