import React, { FC, useMemo, useEffect, useRef, useLayoutEffect } from 'react';
import styles from './index.module.scss';
import { IApiComment, CommentParentType } from 'src/models/comments/types';
import CommentCreate from 'src/shared/Comments/CommentCreate';
import { useMarkCommentAsRead } from 'src/models/comments/hooks';
import CommentItem from './CommentItem';
import { useQPComment } from 'src/models/procurements/Tender/hooks';
import { AssignedTo } from 'src/models/users/types';
import { useEffectOnce } from 'react-use';
import { scrollToItem } from 'src/helpers/scrollToElement';
import classNames from 'classnames';
import { TrackEventSource } from 'src/segment/events';
import { useIsMobile } from 'src/reactiveVars';
import { Alert } from 'src/common';
import { useTranslation } from 'react-i18next';

export type CommentsMode = 'single' | 'multiple';

interface Props {
  comments: IApiComment[];
  parentId: string;
  parentType: CommentParentType;
  mode?: CommentsMode;
  onCancel?: () => void;
  users: AssignedTo[];
  eventSource: TrackEventSource;
}

export const Comments: FC<Props> = (props: Props) => {
  const { comments, parentId, parentType, mode = 'single', onCancel, users, eventSource } = props;
  const { t } = useTranslation();

  const [isMobile] = useIsMobile();

  const [markCommentAsRead] = useMarkCommentAsRead();
  const qpComment = useQPComment();

  const unreadCommentsIds = useMemo(() => {
    return comments.filter(comment => !comment.isRead).map(comment => comment.id);
  }, [comments]);
  const prevCommentsCount = useRef(comments.length);
  const commentsReadOnly = [
    CommentParentType.Procurement,
    CommentParentType.ProcurementBox,
    CommentParentType.TenderBox
  ].includes(parentType);

  useEffectOnce(() => {
    if (qpComment?.id && comments.map(_ => _.id).includes(qpComment?.id)) {
      setTimeout(() => scrollToItem(qpComment?.id));
    }
  });

  useEffect(() => {
    if (!!unreadCommentsIds.length) {
      unreadCommentsIds.forEach(commentId => {
        markCommentAsRead(commentId);
      });
    }
  }, [markCommentAsRead, unreadCommentsIds]);

  // Scroll to the last comment only when a new comment is added
  useLayoutEffect(() => {
    if (comments.length > prevCommentsCount.current && isMobile) {
      const lastCommentId = comments[comments.length - 1].id;
      setTimeout(() => scrollToItem(lastCommentId), 0);
    }
    prevCommentsCount.current = comments.length;
  }, [comments, isMobile]);

  return (
    <div className={classNames(styles.content, { [styles[mode]]: isMobile ? undefined : mode })}>
      {commentsReadOnly && (
        <Alert
          showIcon
          type={'warning'}
          className={styles.highlightWarning}
          message={t('Tenders.tenderRemovalDisclaimer')}
        />
      )}

      {!!comments.length && (
        <ul className={styles.commentList} data-name={'commentList'}>
          {comments.map(comment => (
            <CommentItem
              comment={comment}
              key={comment.id}
              isHighlighted={qpComment?.id === comment.id || undefined}
              parentType={parentType}
            />
          ))}
        </ul>
      )}

      {!commentsReadOnly && (
        <CommentCreate
          parentId={parentId}
          existingComments={comments}
          parentType={parentType}
          mode={mode}
          onCancel={onCancel}
          users={users}
          eventSource={eventSource}
        />
      )}
    </div>
  );
};

export default Comments;
