import { gql } from '@apollo/client';

export const ON_EXPORT_FILE_READY = gql`
  subscription onFilesExported {
    filesExported {
      file {
        url
        name
      }
      operationId
      error
      eventType
      html
    }
  }
`;
