import { useCallback, useMemo } from 'react';
import { CREATE_CONTENT_LIBRARY_TAG, DELETE_CONTENT_LIBRARY_TAG, GET_CONTENT_LIBRARY_TAGS } from './queries';
import { ApiCreateTagResponse, ApiDeleteTagResponse, ApiTagResponse, ContentLibraryTagResponseData } from './types';
import { ApolloError, MutationUpdaterFn, Reference, useMutation, useQuery } from '@apollo/client';
import { Tag } from '.';
import { useTranslation } from 'react-i18next';
import { notification } from 'src/common';
import { DefaultInput } from '../ContentLibrary/types';
import { CreateContentLibraryTagDTO, DeleteContentLibraryTagDTO } from '@tendium/prom-types/schema';
import { trackAddTagCL } from 'src/segment/events';
import { getLoadingStatesForQuery } from 'src/lib/API/graphql/helpers';

export function useLoadContentLibraryTags(): ContentLibraryTagResponseData {
  const query = useQuery<ApiTagResponse>(GET_CONTENT_LIBRARY_TAGS, {
    variables: { query: {} }
  });

  return useMemo(
    () => ({
      ...query,
      ...getLoadingStatesForQuery(query),
      data: query.data?.getContentLibraryTags.map(tag => new Tag(tag))
    }),
    [query]
  );
}

export function useDeleteContentLibraryTag(): [
  (data: DeleteContentLibraryTagDTO, onFinish?: () => void) => void,
  { loading: boolean; error?: ApolloError }
] {
  const { t } = useTranslation();
  const [deleteContentLibraryTag, { loading, error }] = useMutation<
    ApiDeleteTagResponse,
    DefaultInput<DeleteContentLibraryTagDTO>
  >(DELETE_CONTENT_LIBRARY_TAG);
  const deleteContentLibraryTagFn = useCallback(
    (data: DeleteContentLibraryTagDTO, onFinish?: () => void) => {
      deleteContentLibraryTag({
        variables: { input: data },
        update: updateCacheOnDeleteContentLibraryTag(data.id)
      })
        .then(() => {
          onFinish && onFinish();
        })
        .catch(() => {
          notification.error({
            description: t('Common.unknownErrorDesc'),
            message: t('Common.unknownError')
          });
        });
    },
    [deleteContentLibraryTag, t]
  );

  return useMemo(() => [deleteContentLibraryTagFn, { loading, error }], [error, loading, deleteContentLibraryTagFn]);
}

function updateCacheOnDeleteContentLibraryTag(id: string): MutationUpdaterFn<ApiDeleteTagResponse> {
  return (cache, { data }) => {
    if (!data) {
      return null;
    }

    const normalizedId = cache.identify({ id, __typename: 'ContentLibraryTag' });
    cache.evict({ id: normalizedId });

    cache.gc();
  };
}

export function useCreateContentLibraryTag(): [
  (data: CreateContentLibraryTagDTO, onFinish?: () => void) => void,
  { loading: boolean; error?: ApolloError }
] {
  const { t } = useTranslation();
  const [createContentLibraryTag, { loading, error }] = useMutation<
    ApiCreateTagResponse,
    DefaultInput<CreateContentLibraryTagDTO>
  >(CREATE_CONTENT_LIBRARY_TAG);
  const createContentLibraryTagFn = useCallback(
    (data: CreateContentLibraryTagDTO, onFinish?: () => void) => {
      createContentLibraryTag({
        variables: { input: data },
        update: updateCacheOnCreateContentLibraryTag(data.tagGroupId)
      })
        .then(d => {
          onFinish && onFinish();
          if (d.data?.createContentLibraryTag) {
            trackAddTagCL({
              tagGroupId: data.tagGroupId,
              tagName: d.data.createContentLibraryTag.title
            });
          }
        })
        .catch(() => {
          notification.error({
            description: t('Common.unknownErrorDesc'),
            message: t('Common.unknownError')
          });
        });
    },
    [createContentLibraryTag, t]
  );

  return useMemo(() => [createContentLibraryTagFn, { loading, error }], [error, loading, createContentLibraryTagFn]);
}

function updateCacheOnCreateContentLibraryTag(tagGroupId: string): MutationUpdaterFn<ApiCreateTagResponse> {
  return (cache, { data }) => {
    if (!data) {
      return null;
    }

    const tagGroupRef = cache.identify({ __typename: 'ContentLibraryTagGroup', id: tagGroupId });
    const newTagRef = cache.identify({ __typename: 'ContentLibraryTag', id: data.createContentLibraryTag.id });
    if (newTagRef) {
      cache.modify({
        id: tagGroupRef,
        fields: {
          tags: (existing: Reference[], { toReference }) => {
            return [...existing, toReference(newTagRef)];
          }
        }
      });

      cache.modify({
        fields: {
          getContentLibraryTags: (existing, { toReference }) => {
            return [...existing, toReference(newTagRef)];
          }
        }
      });
    }
  };
}
