import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo } from 'react';
import { debounce } from 'lodash';
import { GenericSearchDropdown } from 'src/shared/index';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { Checkbox, InfoIcon, Select } from 'src/common';
import { trackECContractValue } from 'src/segment/events/expiringContracts';
import {
  contractValueFilterSchema,
  contractValueRangeSchema,
  TendersSearchSchemaType
} from 'src/models/procurements/TendersSearch/schemas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDash } from '@fortawesome/pro-light-svg-icons';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { CURRENCIES } from 'src/helpers/constants';
import { zodResolver } from '@hookform/resolvers/zod';
import { useController, useForm } from 'react-hook-form';
import CurrencyInput from './CurrencyInput';
import { TenderFilterRefProps, TendersFilterProps } from '../types';
import { oNonNegativeNumberLike, stringLike, zodParseErrorHandler } from 'src/helpers/zod';
import { z } from 'zod';

type Props = Pick<TendersFilterProps, 'values' | 'onChange' | 'isDiffFromInit' | 'isDiffFromProfile' | 'disabled'>;

const formSchema = contractValueFilterSchema.extend({
  contractValueRange: contractValueRangeSchema
    .extend({
      max: oNonNegativeNumberLike.or(z.string()),
      min: oNonNegativeNumberLike.or(z.string()),
      currency: stringLike.nullish()
    })
    .optional()
});
type FormSchemaType = z.infer<typeof formSchema>;

const FilterContractValue = forwardRef<TenderFilterRefProps, Props>(
  ({ values, onChange, isDiffFromProfile, isDiffFromInit, disabled }, ref) => {
    const { t } = useTranslation();

    const toFormSchema = useCallback(
      (values: TendersSearchSchemaType): FormSchemaType => ({
        contractValueRange: {
          min: values?.contractValueRange?.min ?? '',
          max: values?.contractValueRange?.max ?? '',
          currency: values?.contractValueRange?.currency ?? null
        },
        contractValueExcludeMissingValue: values?.contractValueExcludeMissingValue ?? false
      }),
      []
    );
    const defaultValues = useMemo(() => toFormSchema(values), [toFormSchema, values]);

    const { control, watch, reset, getValues } = useForm<FormSchemaType>({
      resolver: zodResolver(formSchema),
      defaultValues
    });

    const { field: contractValueExcludeMissingValue } = useController({
      name: 'contractValueExcludeMissingValue',
      control
    });
    const { field: currency } = useController({ name: 'contractValueRange.currency', control });

    const onValuesChange = useMemo(
      () =>
        debounce((values: FormSchemaType) => {
          let formValue = { ...values };
          if (!values.contractValueRange?.currency) {
            formValue = { ...formValue, contractValueRange: { ...formValue.contractValueRange, currency: undefined } };
          }
          const parsed = contractValueFilterSchema.safeParse(formValue);
          if (!parsed.success) {
            zodParseErrorHandler('FilterContractValue', parsed.error);
            return;
          }
          onChange(parsed.data);
          trackECContractValue(
            parsed.data.contractValueRange?.min,
            parsed.data.contractValueRange?.max,
            parsed.data.contractValueRange?.currency,
            parsed.data.contractValueExcludeMissingValue
          );
        }, 300),
      [onChange]
    );

    useEffect(() => {
      const subscription = watch(value => onValuesChange(value));
      return () => subscription.unsubscribe();
    }, [onValuesChange, watch]);

    const handleReset = useCallback(
      (updatedVars: TendersSearchSchemaType) => {
        reset(toFormSchema(updatedVars));
      },
      [reset, toFormSchema]
    );
    useImperativeHandle(ref, () => ({ handleReset }), [handleReset]);

    return (
      <GenericSearchDropdown
        label={
          <span className={styles.label}>
            <InfoIcon desc={t('ExpiringContracts.estContractValueTooltip')} />
            <span className={styles.estimatedContractValue}>{t('ExpiringContracts.estimatedContractValue')}</span>
          </span>
        }
        overlay={
          <form>
            <div className={styles.inputContainer}>
              <div className={styles.maxMin}>
                <CurrencyInput
                  control={control}
                  name={'contractValueRange.min'}
                  placeholder={t('ExpiringContracts.min')}
                  className={styles.input}
                  disabled={disabled}
                  inputValue={values?.contractValueRange?.min}
                />
                <FontAwesomeIcon icon={faDash} className={styles.icon} />
                <CurrencyInput
                  control={control}
                  name={'contractValueRange.max'}
                  placeholder={t('ExpiringContracts.max')}
                  className={styles.input}
                  disabled={disabled}
                  inputValue={values?.contractValueRange?.max}
                />
              </div>

              <div className={styles.currencySelect}>
                <Select
                  {...currency}
                  showSearch
                  optionFilterProp={'value'}
                  getPopupContainer={trigger => trigger.parentNode}
                  className={styles.select}
                  listHeight={125}
                  filterOption={(input, option) =>
                    !!option &&
                    !!option.value &&
                    option.value.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder={t('ExpiringContracts.currency')}
                  allowClear
                  disabled={disabled}
                  suffixIcon={<FontAwesomeIcon className={styles.selectIcon} icon={faCaretDown} />}
                >
                  {CURRENCIES.map(c => (
                    <Select.Option key={c.code} value={c.code}>
                      <span className={styles.currencySymbol}>{c.symbol_native}</span>
                      <span className={styles.currencyCode}>{c.code}</span>
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
            <div className={styles.missingValueCheckbox}>
              <Checkbox
                {...contractValueExcludeMissingValue}
                disabled={disabled}
                checked={getValues('contractValueExcludeMissingValue')}
              >
                {t('ExpiringContracts.excludeMissingValue')}
              </Checkbox>
            </div>
          </form>
        }
        overlayClassName={styles.overlay}
        isChanged={isDiffFromInit}
        special={isDiffFromProfile}
      />
    );
  }
);

FilterContractValue.displayName = 'FilterContractValue';

export default FilterContractValue;
