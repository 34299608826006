import { trackWithSessionReplay } from '../helpers';

export function trackIdentify(): void {
  trackWithSessionReplay('User:identified', {});
}

// RG: Press contact tendium
export function trackRGContact(): void {
  trackWithSessionReplay('RG contact');
}
