import { DocsNodeType, IDocumentsNode } from 'src/models/documentsLibrary/types';
import { IUserName } from 'src/models/users/types';
import { NativeTypes } from 'react-dnd-html5-backend';
import { DndType } from 'src/types/dnd';
import { isKeyInObject, isObject } from 'src/helpers';

export interface IFileTask {
  id: string;
  name: string;
}

export interface IDocumentsTableProps {
  id: string;
  name: string;
  createdBy: IUserName | null;
  nodeType: DocsNodeType;
  createDateTime: string;
  putUrl?: string;
  getUrl?: string;
  childNodes?: IDocumentsNode[];
  attachedTo?: IFileTask[];
  status?: FileStatus;
}

export enum DocumentsColumnsName {
  name = 'name',
  createdAt = 'createdAt',
  createdBy = 'createdBy',
  actions = 'actions',
  attach = 'attach'
}

export enum FileStatus {
  NoFile = 'no-file', // before S3 upload
  WaitingForUpload = 'waiting-for-upload', // S3 upload started but was not done
  Done = 'done' // S3 upload succeeded
}

export interface IDocumentsDndItem {
  type: DndType.DocumentsItem;
  docsNode: IDocumentsTableProps;
}

export interface IUploadDndItem {
  type: typeof NativeTypes.FILE;
  files: File[];
}

export function isDocumentsDndItem(u: unknown): u is IDocumentsDndItem {
  return isObject(u) && 'type' in u && u.type === DndType.DocumentsItem;
}

export function isUploadDndItem(u: unknown): u is IUploadDndItem {
  return isKeyInObject('files', u);
}

export const FILE_NAME_PATTERN = new RegExp('^[^<>:;?"\\*|/]+$');
export const FOLDER_NAME_PATTERN = new RegExp('^[^<>:"/\\|?*]');
