import {
  BidTasksGroup,
  IApiBidding,
  ApiTasksResponseData,
  ApiBiddingStats,
  BiddingStats,
  BiddingStatistic
} from './types';
import { IUserName } from 'src/models/users/types';
import { IWorkspaceStatus } from 'src/models/workspace/WorkspaceStatus/types';
import { IApiComment } from 'src/models/comments/types';
import { Stage } from 'src/models/workspace/WorkspaceStatus';
import { User } from 'src/models/users';
import { GROUP_COLORS, STATISTIC_MAPPER, STATISTIC_ORDER } from './mappers';
import { BidItemType } from '@tendium/prom-types/tender';
import { isNotUndefined } from 'src/helpers';
import { IIcon } from 'src/types/icon';
import { BidTask } from '../BidTask';
import { ApiDecisionMatrix } from 'src/models/decisionMatrix/types';

export class Bidding {
  public readonly id: string;
  public readonly stageId: string;
  public readonly originType: BidItemType;
  public readonly originId: string;
  public readonly status: IWorkspaceStatus | null;
  public readonly workspaceId: string;
  public readonly assignedTo: string | null;
  public readonly createdBy: IUserName | null;
  public readonly createdAt: number;
  public readonly comments: IApiComment[];
  public readonly questionGroups: BidTasksGroup[];
  public readonly statistic?: BiddingStatistic;
  public readonly previousGeneratedFiles: string[];
  public readonly emptyQuestionGroupsFiles: string[];
  public readonly manuallyCreatedFiles: string[];
  public readonly decisionMatrix?: ApiDecisionMatrix;

  constructor(private readonly apiBid: IApiBidding, private readonly apiStats?: ApiBiddingStats[]) {
    this.id = apiBid.id;
    this.stageId = apiBid.bidStageId;
    this.originType = apiBid.itemType;
    this.originId = apiBid.itemId;
    this.status = apiBid.status ? new Stage(apiBid.status) : null;
    this.workspaceId = apiBid.workspace.id;
    this.assignedTo = apiBid.assignedTo ? apiBid.assignedTo.id : null;
    this.createdBy = apiBid.createdBy ? new User(apiBid.createdBy) : null;
    this.createdAt = apiBid.createdAt;
    this.comments = apiBid.comments;
    this.questionGroups = this.toQuestionGroups();
    this.emptyQuestionGroupsFiles = this.toEmptyQuestionGroupsFiles();
    this.statistic = this.toStatistic();
    this.previousGeneratedFiles = this.toPreviousGeneratedFiles();
    this.manuallyCreatedFiles = this.toManuallyCreatedFiles();
    this.decisionMatrix = this.apiBid.decisionMatrix;
  }

  private mapQuestionGroups(): BidTasksGroup[] {
    return this.apiBid.questionGroups.map((group, index) => {
      const stats: ApiBiddingStats | undefined = this.apiStats?.find(stat => stat.groupId === group.id);
      return {
        ...group,
        totalTasks: stats?.totalTasks ?? 0,
        totalDoneTasks: stats?.done ?? 0,
        color: GROUP_COLORS[index % GROUP_COLORS.length]
      };
    });
  }

  private toQuestionGroups(): BidTasksGroup[] {
    return this.mapQuestionGroups().filter(group => !(group.totalTasks === 0 && group.filePath));
  }

  private toEmptyQuestionGroupsFiles(): string[] {
    return this.mapQuestionGroups()
      .filter(group => group.totalTasks === 0 && group.filePath)
      .map(group => group.filePath)
      .filter(isNotUndefined);
  }

  private toStatistic(): BiddingStatistic {
    if (!this.apiStats) return { stats: [], total: 0 };

    const apiStats: BiddingStats = {
      inProgress: 0,
      review: 0,
      done: 0,
      todo: 0,
      unAssigned: 0,
      totalTasks: 0
    };
    this.apiStats.forEach(stat => {
      apiStats.todo += stat.todo;
      apiStats.unAssigned += stat.unAssigned;
      apiStats.totalTasks += stat.totalTasks;
      apiStats.review += stat.review;
      apiStats.done += stat.done;
      apiStats.inProgress += stat.inProgress;
    });

    const stats = Object.keys(STATISTIC_MAPPER)
      .map(
        key =>
          [
            key as keyof BiddingStats,
            new BiddingStat(apiStats[key as keyof BiddingStats], key as keyof BiddingStats)
          ] as [keyof BiddingStats, BiddingStat]
      )
      .sort(([a], [b]) => {
        return STATISTIC_ORDER.findIndex(order => order === a) - STATISTIC_ORDER.findIndex(order => order === b);
      });

    return {
      stats,
      total: apiStats.totalTasks
    };
  }
  private toPreviousGeneratedFiles(): string[] {
    const existingGroups = this.apiBid.questionGroups;
    return existingGroups.map(group => group.filePath).filter(isNotUndefined);
  }
  private toManuallyCreatedFiles(): string[] {
    const existingGroups = this.apiBid.questionGroups;
    return existingGroups
      .filter(group => !!group.filePath)
      .map(group => group.filePath)
      .filter(isNotUndefined);
  }
}

export class TasksData {
  readonly totalCount: number;
  readonly nextRank: string;
  readonly tasks: (BidTask | null)[];
  constructor(private readonly raw: ApiTasksResponseData) {
    this.totalCount = raw.totalCount;
    this.nextRank = raw.nextRank;
    this.tasks = this.toTasksData();
  }

  private toTasksData(): (BidTask | null)[] {
    const tasks = this.raw.questions;
    if (!tasks) {
      return [];
    }
    return tasks.map(task => (task ? new BidTask(task) : null));
  }
}
export class BiddingStat {
  public readonly count: number;
  public readonly icon: IIcon;
  constructor(count: number, field: keyof BiddingStats) {
    this.count = count;
    this.icon = STATISTIC_MAPPER[field] ?? STATISTIC_MAPPER['totalTasks'];
  }
}
