import React, { FC } from 'react';
import styles from './index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDownloadTenderZipFile } from 'src/models/procurements/Tender/hooks';
import { faCloudDownload } from '@fortawesome/pro-light-svg-icons';
import { Button } from 'src/common';
import { useTranslation } from 'react-i18next';

interface Props {
  procurementName: string;
  procurementId: string;
}

export const TenderFileDownload: FC<Props> = ({ procurementName, procurementId }) => {
  const { getFile: getZipFile, loading: zipFileLoading } = useDownloadTenderZipFile();
  const { t } = useTranslation();

  return (
    <div className={styles.downloadContainer}>
      <Button
        type={'primary'}
        onClick={() => getZipFile(procurementName, procurementId)}
        loading={zipFileLoading}
        icon={<FontAwesomeIcon icon={faCloudDownload} />}
      >
        {t('DetailsPage.downloadAllDocuments')}
      </Button>
    </div>
  );
};

export default TenderFileDownload;
