import React, { FC, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { Button, UserPicker, PlainDropDown, Skeleton } from 'src/common';
import { useBidding, useTasksFilter } from 'src/models/bids/BidFull/hooks';
import { QuestionStatus, RequirementType, TaskFulfillment } from 'src/models/bids/BidTask/types';
import { useWsUsersAndTeams } from 'src/models/workspace/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { FULFILLMENT_MAPPER } from 'src/models/bids/BidTask/mappers';
import { trackFilterTasks } from 'src/segment/events';
import CreateTasksDropdown from '../CreateTasksDropdown';

interface Props {
  className?: string;
  filtersClassName?: string;
  hideCreateTask?: boolean;
}

export const TasksFilter: FC<Props> = ({ className, filtersClassName, hideCreateTask = false }) => {
  const { t } = useTranslation();
  const { data, loading } = useBidding();
  const wsId = data?.workspaceId;
  const bidId = data?.id;
  const { filter, updateFilter, resetFilter } = useTasksFilter();

  const users = useWsUsersAndTeams(wsId);
  const selectedUsers = useMemo(() => (filter?.assignedTo ? filter.assignedTo : undefined), [filter]);
  const selectedStatuses = useMemo(() => (filter?.status ? filter.status : undefined), [filter]);
  const selectedRequirementTypes = useMemo(
    () => (filter?.requirementType ? filter.requirementType : undefined),
    [filter]
  );
  const selectedFulfillment = useMemo(() => (filter?.fulfillment ? filter.fulfillment : undefined), [filter]);

  const onChangeFulfillment = useCallback(
    (fulfillment: TaskFulfillment, checked?: boolean) => {
      const previousValue = selectedFulfillment || [];
      const currentValue = checked
        ? [...previousValue, fulfillment]
        : previousValue.filter(item => item !== fulfillment);
      updateFilter?.('fulfillment', currentValue);
      trackFilterTasks({ bidId, taskProp: 'task stage', values: currentValue });
    },
    [bidId, selectedFulfillment, updateFilter]
  );
  const fulfillmentValues = useMemo(
    () =>
      Object.values(TaskFulfillment).map(type => {
        const label = Object.keys(TaskFulfillment).find(
          key => TaskFulfillment[key as keyof typeof TaskFulfillment] === type
        );
        return {
          value: type,
          label: label ? t(`BiddingTool.TaskFulfillment.${label}`) : undefined,
          children: (
            <span className={styles.fulfillmentItem}>
              <FontAwesomeIcon
                icon={type ? FULFILLMENT_MAPPER[type].defaultIcon : FULFILLMENT_MAPPER['null'].defaultIcon}
                style={{ color: type ? FULFILLMENT_MAPPER[type].color : FULFILLMENT_MAPPER['null'].color }}
              />
              <span>{label ? t(`BiddingTool.TaskFulfillment.${label}`) : type}</span>
            </span>
          )
        };
      }),
    [t]
  );

  const onChangeReqType = useCallback(
    (requirementType: RequirementType, checked?: boolean) => {
      const previousValue = selectedRequirementTypes || [];
      const currentValue = checked
        ? [...previousValue, requirementType]
        : previousValue.filter(item => item !== requirementType);
      updateFilter?.('requirementType', currentValue);
      trackFilterTasks({ bidId, taskProp: 'task requirement', values: currentValue });
    },
    [bidId, selectedRequirementTypes, updateFilter]
  );
  const requirementTypeValues = useMemo(
    () =>
      Object.values(RequirementType).map(type => {
        const label = Object.keys(RequirementType).find(
          key => RequirementType[key as keyof typeof RequirementType] === type
        );
        return {
          value: type,
          label: label ? t(`BiddingTool.RequirementType.${label}`) : undefined
        };
      }),
    [t]
  );

  const onChangeStatus = useCallback(
    (status: QuestionStatus, checked?: boolean) => {
      const previousValue = selectedStatuses || [];
      const currentValue = checked ? [...previousValue, status] : previousValue.filter(item => item !== status);
      updateFilter?.('status', currentValue);
      trackFilterTasks({ bidId, taskProp: 'task status', values: currentValue });
    },
    [bidId, selectedStatuses, updateFilter]
  );
  const statusValues = useMemo(
    () =>
      Object.values(QuestionStatus).map(type => {
        const label = Object.keys(QuestionStatus).find(
          key => QuestionStatus[key as keyof typeof QuestionStatus] === type
        );
        return {
          value: type,
          label: label ? t(`BiddingTool.QuestionStatus.${label}`) : undefined,
          children: (
            <div className={classNames(styles.statusLabel, styles[type])}>
              {label ? t(`BiddingTool.QuestionStatus.${label}`) : type}
            </div>
          )
        };
      }),
    [t]
  );

  const onChangeAssigned = useCallback(
    ({ id: assignedTo }: { id: string }, checked?: boolean) => {
      const previousValue = selectedUsers || [];
      const currentValue = checked ? [...previousValue, assignedTo] : previousValue.filter(item => item !== assignedTo);
      updateFilter?.('assignedTo', currentValue);
    },
    [selectedUsers, updateFilter]
  );

  return loading ? (
    <Skeleton loading active />
  ) : (
    <section className={classNames(styles.content, className)}>
      <div className={classNames(styles.filters, filtersClassName)}>
        <div className={classNames(styles.filter, { [styles.isActive]: !!selectedStatuses })}>
          <PlainDropDown
            values={statusValues}
            selectedValue={selectedStatuses}
            label={t('BiddingTool.status')}
            onSelect={onChangeStatus}
            buttonClassName={styles.label}
            placement="bottomLeft"
            type="multiple"
            button
          />
        </div>
        <div className={classNames(styles.filter, { [styles.isActive]: !!selectedFulfillment })}>
          <PlainDropDown
            values={fulfillmentValues}
            selectedValue={selectedFulfillment}
            label={t('BiddingTool.compliance')}
            onSelect={onChangeFulfillment}
            buttonClassName={styles.label}
            placement="bottomLeft"
            type="multiple"
            button
          />
        </div>
        <div className={classNames(styles.filter, { [styles.isActive]: !!selectedRequirementTypes })}>
          <PlainDropDown
            values={requirementTypeValues}
            selectedValue={selectedRequirementTypes}
            label={t('BiddingTool.requirement')}
            onSelect={onChangeReqType}
            buttonClassName={styles.label}
            placement="bottomLeft"
            type="multiple"
            button
          />
        </div>
        <div className={classNames(styles.filter, { [styles.isActive]: !!selectedUsers })}>
          <UserPicker
            label={t('BiddingTool.assigned')}
            users={users}
            onSelect={onChangeAssigned}
            selected={selectedUsers}
            className={styles.label}
            userPickerType="multiple"
            renderMode={'button'}
          />
        </div>
        <Button className={styles.clearButton} type={'link'} onClick={resetFilter}>
          {t('Common.clear')}
        </Button>
      </div>

      {!hideCreateTask && <CreateTasksDropdown />}
    </section>
  );
};

export default TasksFilter;
