import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { FC } from 'react';
import styles from './index.module.scss';

export const SwitcherIcon: FC<{ expanded: boolean }> = ({ expanded }) => {
  return (
    <FontAwesomeIcon
      icon={faChevronRight}
      className={classNames(styles.expandIcon, { [styles.isExpanded]: expanded })}
    />
  );
};

export default SwitcherIcon;
