export interface IApiCompetitor {
  orgId: string;
}
export interface IOrgPayments {
  sumAmount: number;
  sumCurrency: string;
}
export interface IApiOrgWithPayments {
  supplierOrgId: string;
  supplierOrgName: string;
  transactions?: IOrgPayments;
}

export interface IOrgWithPayments extends Pick<IApiOrgWithPayments, 'supplierOrgId' | 'supplierOrgName'> {
  sumValue: number | null;
  sumCurrency?: string;
  color: string;
}

export const PAYMENTS_YEARS_RANGE = 4;

export const COMPETITORS_COLORS = [
  '#FFC53D',
  '#73D13D',
  '#9254DE',
  '#36CFC9',
  '#BAE637',
  '#F759AB',
  '#597EF7',
  '#FADB14',
  '#FF7A45',
  '#7E86F7',
  '#FFA940'
];
