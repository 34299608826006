import React, { FC, useMemo } from 'react';
import styles from './index.module.scss';
import { useBidPreview } from 'src/models/bids/BidPreview/hooks';
import {
  AssignToBid,
  BidQuickActions,
  BlockHeading,
  ByggfaktaProjectButton,
  MultiBuyerBoxes,
  TenderBox,
  WorkspacePicker,
  WorkspaceStatusPicker
} from 'src/shared';
import { FeatureFlag, useFeatureFlag } from 'src/helpers/featureFlag';
import { isFieldCurrency, isFieldDate, isFieldString, TenderBoxMode } from 'src/models/procurements/Tender/types';
import { Skeleton, TenderStatusBadge, Tooltip } from 'src/common';
import { BidItemType } from '@tendium/prom-types/tender';
import BidWebhooksButton from 'src/shared/Bid/BidWebhooksButton';
import { useLoadWebhooks } from 'src/models/company/Webhooks/hooks';
import InformationFields from 'src/shared/Tender/InformationFields';
import { useTenderActionEventSource } from 'src/segment/hooks';
import { IBidIdentifier } from 'src/models/bids/Bids/types';

export const BidHeader: FC = () => {
  const { data, loading } = useBidPreview();
  const { data: webhooks } = useLoadWebhooks();

  const eventSource = useTenderActionEventSource();

  const bidId = data?.id;
  const workspaceId = data?.workspaceId;
  const workspaceStageCategory = data?.status?.category;
  const nameBox = data?.origin.name;
  const webhookStatus = useMemo(() => data?.webhookStatus, [data]);

  const name = useMemo(
    () =>
      nameBox && nameBox.firstField && isFieldString(nameBox.firstField) ? nameBox.firstField?.string?.toString() : '',
    [nameBox]
  );
  const buyerBoxes = data?.origin.buyerBoxes;
  const buyerLinkBox = data?.origin.buyerLinkBox;

  const estValue =
    data?.origin.contractValueBoxes && isFieldCurrency(data.origin.contractValueBoxes[0].firstField)
      ? data.origin.contractValueBoxes[0].firstField
      : null;
  const deadline =
    data?.origin.deadline &&
    isFieldDate(data.origin.deadline[0].firstField) &&
    !!data.origin.deadline[0].firstField.date
      ? data.origin.deadline[0].firstField.date
      : null;

  const isEditableBidFeature = useFeatureFlag(FeatureFlag.BiddingWorkflow_Preview);

  if (loading) return <Skeleton active paragraph={{ rows: 1 }} />;

  return data ? (
    <div className={styles.header}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>
          <TenderStatusBadge statuses={data?.origin.procurementStatus} isPIN={data?.origin.isPlanningProcess} />
          <Tooltip title={name}>
            {nameBox && isEditableBidFeature && (
              <TenderBox
                actions={false}
                box={nameBox}
                title={false}
                className={styles.titleBox}
                as={TenderBoxMode.Custom}
                isEditable
              />
            )}
            {name && !isEditableBidFeature && <BlockHeading title={name} className={styles.titleValue} />}
          </Tooltip>
        </div>
        {workspaceId && bidId && (
          <BidQuickActions
            originId={data.origin.id}
            originType={data.origin.originType}
            wsId={workspaceId}
            bidId={bidId}
            categoryName={workspaceStageCategory}
          />
        )}
      </div>

      <div className={styles.bar}>
        <MultiBuyerBoxes
          buyerBoxes={buyerBoxes}
          linkBox={buyerLinkBox}
          isEditable={!!isEditableBidFeature && data.origin.originType !== BidItemType.Procurement}
        />
        <div className={styles.actions}>
          <WorkspacePicker
            originIds={[data.origin.id]}
            originType={data.origin.originType}
            bidIds={[{ id: data.id, wsId: data.workspaceId } as IBidIdentifier]}
            eventSource={eventSource}
            eventType={'OverviewSingleTender'}
            mode={'full'}
            disabled={true}
          />
          {bidId && (
            <WorkspaceStatusPicker
              bidId={bidId}
              colorful
              className={styles.wsStatusPicker}
              eventSource="Bidding tool"
            />
          )}
          {webhooks &&
            webhooks.map(webhook => (
              <BidWebhooksButton
                key={webhook.id}
                webhook={webhook}
                webhookStatus={webhookStatus}
                bidId={bidId}
                size={'l'}
              />
            ))}
          <ByggfaktaProjectButton bidId={bidId} isActive={data?.isBfProject} />
          {bidId && (
            <AssignToBid
              assignedTo={data.assignedTo}
              bidId={bidId}
              wsId={workspaceId}
              size={'m'}
              eventSource={'Bid Preview'}
            />
          )}
          <InformationFields estimationValue={estValue} deadline={deadline} shortMode />
        </div>
      </div>
    </div>
  ) : null;
};

export default BidHeader;
