import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import styles from './index.module.scss';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';

export const AiDisclaimer: FC = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.aiDisclaimer}>
      <FontAwesomeIcon className={styles.infoIcon} icon={faInfoCircle} />
      <span className={styles.warningMessage}>{t('BiddingTool.aiWarning')}</span>
    </div>
  );
};
