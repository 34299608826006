import React, { FC, useState, useRef, useCallback, useMemo, ReactNode } from 'react';
import { Button, Tag, Tooltip, confirm } from 'src/common';
import { EditableText, QuickActions, QuickActionsDivider, QuickActionsItem } from 'src/shared';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { faAngleDown, faPen, faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useClickAway, useKey } from 'react-use';
import { BoxFieldString } from 'src/models/procurements';
import { IBoxFieldString } from 'src/models/procurements/Tender/types';
import { useUpdateBidGroup, useDeleteBidGroup } from 'src/models/bids/BidFull/hooks';
import modalStyles from 'src/common/Modal/index.module.scss';
import { useParams } from 'react-router';
import Skeleton from '../TaskSkeleton/skeleton';

interface Props {
  title: string;
  groupId: string;
  statuses?: { label: string; labelColor: string; bgColor: string }[];
  isGroupOpen?: boolean;
  children?: ReactNode;
  actionsAvailable?: boolean;
  onToggle?: () => void;
  className?: string;
  loading?: boolean;
}

export const TasksGroupTitle: FC<Props> = props => {
  const { children, title, groupId, statuses, isGroupOpen, actionsAvailable, onToggle, className, loading } = props;
  const { t } = useTranslation();

  const [updateGroup] = useUpdateBidGroup();
  const [deleteGroup] = useDeleteBidGroup();

  const [isEditing, setEditing] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { id: bidId } = useParams<{ id?: string }>();

  const ref = useRef<HTMLDivElement>(null);

  const isDisabled = useMemo(() => /*group.questions.length === 0*/ false, []);
  const isExpanded = useMemo(() => !!isOpen || !!isGroupOpen, [isGroupOpen, isOpen]);

  const toggleGroup = useCallback(() => {
    if (onToggle) {
      return onToggle();
    } else {
      return setIsOpen(prev => !prev);
    }
  }, [onToggle]);

  const enterEditing = useCallback(() => {
    setEditing(() => true);
  }, []);
  const exitEditing = useCallback(() => {
    setEditing(() => false);
  }, []);
  useKey('Escape', () => exitEditing());
  useClickAway(ref, e => {
    e.stopPropagation();
    e.preventDefault();
    exitEditing();
  });
  const onChange = useCallback(
    (value: IBoxFieldString) => {
      bidId &&
        updateGroup({
          bidId,
          groupId,
          title: value.string ?? ''
        });
      exitEditing();
    },
    [bidId, exitEditing, groupId, updateGroup]
  );

  const onDeleteGroup = useCallback(() => bidId && deleteGroup({ bidId, groupId }), [bidId, deleteGroup, groupId]);
  const onShowDeleteGroup = useCallback(() => {
    confirm.confirm({
      title: t('BiddingTool.deleteGroup'),
      okText: t('Common.delete'),
      content: t('BiddingTool.deleteGroupWarning'),
      cancelText: t('Common.cancel'),
      className: modalStyles.confirmModal,
      centered: true,
      onOk: onDeleteGroup
    });
  }, [onDeleteGroup, t]);

  return (
    <>
      <div className={classNames(styles.heading, className)}>
        {!!actionsAvailable && isEditing ? (
          <EditableText
            className={styles.title}
            containerRef={ref}
            onChange={onChange}
            onFinish={exitEditing}
            field={new BoxFieldString(title, title)}
            autoSize={true}
            autoFocus
          />
        ) : (
          <Button
            className={classNames(styles.button, { [styles.isDisabled]: isDisabled })}
            disabled={isDisabled}
            icon={
              <FontAwesomeIcon
                icon={faAngleDown}
                className={classNames(styles.icon, { [styles.isExpanded]: isExpanded })}
              />
            }
            type={'text'}
            onClick={toggleGroup}
          >
            {loading ? (
              <Skeleton width="286px" height="22px" className={styles.skeleton} />
            ) : !!title ? (
              <Tooltip title={title}>
                <span className={styles.label}>{title}</span>
              </Tooltip>
            ) : (
              t('BiddingTool.newGroupPlaceholder')
            )}
            {!!statuses &&
              statuses.map(status => (
                <div key={status.label} className={styles.tag}>
                  <Tag
                    key={status.label}
                    label={status.label}
                    labelColor={status.labelColor}
                    bgColor={status.bgColor}
                    size="m"
                  />
                </div>
              ))}
          </Button>
        )}
        {!!actionsAvailable && (
          <QuickActions>
            <QuickActionsItem
              key={'renameGroup'}
              onClick={enterEditing}
              title={t('BiddingTool.renameGroup')}
              icon={faPen}
            />
            <QuickActionsDivider />
            <QuickActionsItem
              onClick={onShowDeleteGroup}
              title={t('BiddingTool.deleteGroup')}
              key={'deleteGroup'}
              icon={faTrashCan}
              danger
            />
          </QuickActions>
        )}
      </div>
      {isExpanded && !!children && <div>{children}</div>}
    </>
  );
};

export default TasksGroupTitle;
