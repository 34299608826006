import { FC, useCallback, useState } from 'react';
import React from 'react';
import { IHighlight } from 'react-pdf-highlighter';
import styles from './index.module.scss';
import { Button } from 'src/common';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownload } from '@fortawesome/pro-light-svg-icons';
import { DocumentViewer } from 'src/shared';
import { downloadFile } from 'src/helpers';
import { AttachFileToBidResponse } from 'src/models/bids/BidAttachments/types';
import { getIconByFileType } from 'src/helpers/fileTypeIcon';

interface Props {
  highlights?: IHighlight[];
  attachment?: AttachFileToBidResponse;
  trackHandler?: () => void;
}

export const PrivateFilesView: FC<Props> = ({ highlights, attachment, trackHandler }) => {
  const [previewUrl, setPreviewUrl] = useState<string>();

  const fileType = attachment?.document.name.split('.').pop();
  const fileUrl = attachment?.document.getUrl ?? '';
  const fileName = attachment?.document.name ?? '';
  const iconFileType = attachment?.document.name.split('.').pop();

  const onDownload = (url: string, fileName: string): void => {
    if (url) {
      downloadFile(url, fileName);
    }
  };

  const onClose = useCallback(() => {
    setPreviewUrl('');
  }, []);

  const onClick = useCallback(() => {
    trackHandler?.();
    if (fileType === 'pdf') {
      setPreviewUrl(attachment?.document.getUrl);
    } else {
      downloadFile(fileUrl, fileName);
    }
  }, [attachment?.document.getUrl, fileName, fileType, fileUrl, trackHandler]);

  return (
    <>
      {attachment && (
        <div className={styles.container} onClick={onClick}>
          <div className={styles.icon}>{getIconByFileType(iconFileType)}</div>
          <span className={styles.docTitle}>{fileName}</span>
          <Button
            type={'text'}
            className={classNames(styles.docAction, styles.fileAction)}
            onClick={e => {
              e.stopPropagation();
              if (attachment) {
                onDownload(fileUrl, fileName);
              }
            }}
            icon={<FontAwesomeIcon icon={faCloudDownload} />}
          />
        </div>
      )}
      {!!previewUrl && (
        <DocumentViewer
          url={previewUrl}
          title={attachment?.document.name ?? ''}
          onClose={onClose}
          highlights={highlights}
        />
      )}
    </>
  );
};
