import { useEffect } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { Paths } from 'src/pages/paths';
import { safeCallSegment } from '../helpers';
import qs from 'qs';
import {
  trackOpenDocumentSearch,
  trackOpenInAppNotification,
  trackOpenNotificationFromEmail,
  trackOpenProcurementFromEmail,
  trackSwitchCallOffs
} from 'src/segment/events';
import { useTrackEntryWithUTMSource } from './hooks';
import { TenderCatName } from 'src/models/procurements/Tender/types';
import { isString } from 'src/helpers';
import { usePrevious } from 'react-use';
import * as sessionReplay from '@amplitude/session-replay-browser';

const PAGES_MAPPER: Record<string, string> = {
  [Paths.MY_WORK]: 'My work',
  [Paths.BIDDING_GPT]: 'Response Generator',
  [Paths.MONITORING]: 'MP view navigation',
  [Paths.BIDSPACES]: 'AB view navigation Page',
  [Paths.CALL_OFFS]: 'CO view navigation',
  [Paths.CONTENT_LIBRARY]: 'CL view navigation',
  [Paths.ALL_TENDERS]: 'All tenders view',
  [Paths.STARRED_TENDERS]: 'Starred view',
  [Paths.DOCS_LIB]: 'Document library',
  [`${Paths.ANALYTICS_PAGE}${Paths.MARKET_DASHBOARD}`]: 'Market insight page',
  [`${Paths.ANALYTICS_PAGE}${Paths.SUPPLIERS_PAGE}`]: 'Supplier profile empty state',
  [`${Paths.ANALYTICS_PAGE}${Paths.BUYER_PAGE}`]: 'Buyer profile empty state'
};

function handleDetailsPage(args: { id?: string; qp: qs.ParsedQs; hash?: string }): void {
  const { id, qp, hash } = args;
  const { utm_source, commentId } = qp;
  if (id) {
    analytics.page('Procurement Details', {
      procurementId: id, // backward compatibility
      procurement: {
        id
      }
    });
    if (isString(commentId)) {
      if (utm_source === 'email') {
        trackOpenNotificationFromEmail({ id }, { id: commentId });
      } else if (utm_source === 'notification') {
        trackOpenInAppNotification({ id }, { id: commentId });
      } else {
        trackOpenProcurementFromEmail({ id });
      }
    } else if (hash === TenderCatName.KeywordSearch) {
      trackOpenDocumentSearch({ id });
    }
  }
}

/** @todo: should be rewritten */
function handlePages(pathname: string, qp: qs.ParsedQs, hash?: string): void {
  const tenderPageMatch = matchPath({ path: `${Paths.TENDER_ROUTE}${Paths.TENDER_PAGE}` }, pathname);
  const biddingToolMatch = matchPath({ path: `${Paths.OPPORTUNITY}` }, pathname);
  const taskPageMatch = matchPath({ path: `${Paths.TASK_ROUTE}${Paths.TASK_PAGE}` }, pathname);
  const inboxPageMatch = matchPath({ path: `${Paths.INBOX_PAGE}` }, pathname);
  const expiringContractsPageMatch = matchPath(
    { path: `${Paths.ANALYTICS_PAGE}${Paths.EXPIRING_CONTRACTS_ROUTE}` },
    pathname
  );
  const supplierPageMatch = matchPath({ path: `${Paths.ANALYTICS_PAGE}${Paths.SUPPLIER_PAGE}` }, pathname);
  const buyerPageMatch = matchPath({ path: `${Paths.ANALYTICS_PAGE}${Paths.BUYER_PAGE}` }, pathname);

  const sessionReplayProperties = sessionReplay.getSessionReplayProperties();

  if (tenderPageMatch) {
    handleDetailsPage({ id: tenderPageMatch.params.id, qp, hash });
  } else if (biddingToolMatch) {
    analytics.page('Bidding Tool', {
      bidId: biddingToolMatch.params.id,
      ...sessionReplayProperties
    });
  } else if (taskPageMatch) {
    analytics.page('Task Details Page', {
      taskId: taskPageMatch.params.id,
      ...sessionReplayProperties
    });
  } else if (inboxPageMatch && inboxPageMatch.params.inboxId) {
    trackSwitchCallOffs({ id: inboxPageMatch.params.inboxId });
  } else if (expiringContractsPageMatch) {
    analytics.page('Expiring Contracts Page', {
      ...sessionReplayProperties
    });
  } else if (supplierPageMatch && supplierPageMatch.params.orgId) {
    analytics.page('Supplier profile main page', {
      supplier: supplierPageMatch.params.orgId,
      ...sessionReplayProperties
    });
  } else if (buyerPageMatch && buyerPageMatch.params.orgId) {
    analytics.page('Supplier profile main page', {
      supplier: buyerPageMatch.params.orgId,
      ...sessionReplayProperties
    });
  } else {
    analytics.page(pathname in PAGES_MAPPER ? PAGES_MAPPER[pathname] : undefined, {
      ...sessionReplayProperties
    });
  }
}

export default function usePageWatcher(): void {
  const [trackEntryWithUTMSource] = useTrackEntryWithUTMSource();
  const location = useLocation();
  const prevLocation = usePrevious(location);

  useEffect(() => {
    const qp = qs.parse(location.search.replace('?', ''));
    const { utm_source, inbox, tab, matchingProfileId: mpId } = qp;
    const url = window.location.href;
    const prevQp = qs.parse(prevLocation?.search.replace('?', '') ?? '');
    const { tab: prevTab, inbox: prevInbox, matchingProfileId: prevMpId } = prevQp;

    if (utm_source === 'email') {
      trackEntryWithUTMSource(url);
    }
    /** @todo: should be rewritten in PROM-1266 */
    if (location.pathname !== prevLocation?.pathname || tab !== prevTab || mpId !== prevMpId || inbox !== prevInbox) {
      safeCallSegment(() => {
        handlePages(location.pathname, qp, location.hash);
      });
    }
  }, [
    location,
    location.pathname,
    location.search,
    prevLocation?.pathname,
    prevLocation?.search,
    trackEntryWithUTMSource
  ]);
}
