import React, { FC } from 'react';
import previewStyles from 'src/shared/InfoSidebar/index.module.scss';
import BidPreviewHeader from '../Header';
import BidPreviewNav from '../Nav';
import { ActivePreviewTab } from 'src/models/procurements/types';
import BidPreviewInfo from '../Info';
import BidPreviewComments from '../Comments';
import { usePreviewTabState } from 'src/models/procurements/hooks';
import { useBidPreview } from 'src/models/bids/BidPreview/hooks';
import { Skeleton } from 'src/common';
import BidFiles from 'src/shared/BidFiles';
import TaskGroupsStats from '../TaskGroupsStats';
import { FeatureFlag, useFeatureFlag } from 'src/helpers';
import { BidPreviewMatchData } from '../Matches';

export const BidPreviewContent: FC = () => {
  const { data, loading } = useBidPreview();
  const [activeTab] = usePreviewTabState();
  const isBidFlowFeature = useFeatureFlag(FeatureFlag.BiddingWorkflow_Tasks);
  const isCompanyFitFeature = useFeatureFlag(FeatureFlag.Bidding_CompanyFit);

  return loading ? (
    <Skeleton active loading />
  ) : (
    <>
      <div className={previewStyles.header}>
        <BidPreviewHeader />
        <BidPreviewNav />
      </div>
      <div className={previewStyles.content}>
        {activeTab === ActivePreviewTab.Info && <BidPreviewInfo />}
        {activeTab === ActivePreviewTab.Comments && <BidPreviewComments />}
        {activeTab === ActivePreviewTab.DocumentsBidPreview && <BidFiles eventSource={'Bid preview'} />}
        {isBidFlowFeature && activeTab === ActivePreviewTab.Tasks && data?.id && <TaskGroupsStats bidId={data.id} />}
        {isCompanyFitFeature && activeTab === ActivePreviewTab.CompanyFit && <BidPreviewMatchData />}
      </div>
    </>
  );
};

export default BidPreviewContent;
