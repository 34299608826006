import {
  IBidPreviewOrigin,
  IApiBidPreviewTenderOrigin,
  IApiBidPreviewCallOffOrigin,
  IApiBidPreviewManualOrigin,
  IApiBidPreview,
  MatchData
} from './types';
import { IUserName } from 'src/models/users/types';
import { IApiComment } from 'src/models/comments/types';
import { IWorkspaceStatus } from 'src/models/workspace/WorkspaceStatus/types';
import { IInboxEmail } from 'src/models/callOffs/types';
import { filterBoxes, convertApiLotsToLots } from 'src/models/procurements/Tender/helpers';
import { isApiOriginCallOff } from '../types';
import { BoxFieldBoolean, TenderBox, TenderBoxFactory } from 'src/models/procurements';
import { isNotUndefined } from 'src/helpers';
import { CUSTOM_BID_FIELDS_ORDER } from '../../procurements/Tender/mappers';
import { User } from 'src/models/users';
import { Stage } from 'src/models/workspace/WorkspaceStatus';
import { WebhookStatus } from 'src/models/company/Webhooks/types';
import { getSortedBuyerBoxes } from 'src/models/procurements/helpers';
import { ApiDecisionMatrix } from 'src/models/decisionMatrix/types';
import { TasksGenerationJobStatus } from '../BidFull/types';
import { Interactions } from '@tendium/prom-types/schema';
import { isFieldString, isFieldUrl } from 'src/models/procurements/Tender/types';

export class BidPreviewData {
  public readonly id: string;
  public readonly bidStageId: string;
  public readonly bidContext: Interactions.BidContext | null;
  public readonly bidContextId: string | null;
  public readonly matchData: MatchData | null;
  public readonly origin: IBidPreviewOrigin;
  public readonly status: IWorkspaceStatus;
  public readonly assignedTo: string | null;
  public readonly createdBy: IUserName | null;
  public readonly createdAt: number;
  public readonly comments: IApiComment[];
  public readonly workspaceId: string;
  public readonly mails: IInboxEmail[];
  public readonly isRejected: boolean;
  public readonly isBfProject: boolean;
  public readonly webhookStatus?: WebhookStatus;
  public readonly decisionMatrix?: ApiDecisionMatrix;
  public readonly tasksGenerationStatus?: TasksGenerationJobStatus;

  constructor(
    private readonly apiBid: IApiBidPreview,
    private readonly tasksGenerationJobStatus?: TasksGenerationJobStatus
  ) {
    this.id = apiBid.id;
    this.status = new Stage(apiBid.status);
    this.bidStageId = apiBid.bidStageId;
    this.bidContext = apiBid.bidContext;
    this.bidContextId = apiBid.bidContextId;
    this.matchData = apiBid.matchContext ?? null;
    this.workspaceId = apiBid.workspace.id;
    this.origin = this.toBidOrigin();
    this.assignedTo = apiBid.assignedTo ? apiBid.assignedTo.id : null;
    this.createdBy = apiBid.createdBy ? new User(apiBid.createdBy) : null;
    this.createdAt = apiBid.createdAt;
    this.comments = apiBid.comments;
    this.mails = this.toBidMails();
    this.isRejected = apiBid.isRejected;
    this.isBfProject = !!this.apiBid.byggfaktaProject;
    this.webhookStatus = this.apiBid.webhookStatus;
    this.decisionMatrix = this.apiBid.decisionMatrix;
    this.tasksGenerationStatus = this.tasksGenerationJobStatus;
  }

  private toBidMails(): IInboxEmail[] {
    const origin = this.apiBid.item;
    const mails = this.apiBid.mails;
    const callOffMail = isApiOriginCallOff<IApiBidPreviewCallOffOrigin>(origin)
      ? ({
          mailId: origin.mailId,
          date: origin.date,
          subject: origin.subject,
          htmlContent: origin.htmlContent,
          content: origin.content,
          attachments: origin.attachments,
          zip: origin.zip
        } as IInboxEmail)
      : undefined;
    return callOffMail ? [callOffMail, ...mails] : mails;
  }

  private toBidOrigin(): IBidPreviewOrigin {
    const originType = this.apiBid.itemType;
    const origin = this.apiBid.item;
    const itemId = this.apiBid.itemId;

    const general = origin.general;
    const timeline = origin.timeline;
    const lots = convertApiLotsToLots(origin.lots);

    return {
      id:
        (origin as IApiBidPreviewCallOffOrigin).mailId ??
        ((origin as IApiBidPreviewTenderOrigin).id ? itemId : '') ?? //itemId=tenderId bc is used for some URLs, id=bidId#tenderId
        (origin as IApiBidPreviewManualOrigin).id ??
        '',

      originType,
      name: general.name ? new TenderBox(general.name) : new TenderBoxFactory().create('name'),
      buyerLinkBox: !!filterBoxes(general.linkToProcurerBoxes, lots).length
        ? filterBoxes(general.linkToProcurerBoxes, lots)[0]
        : new TenderBoxFactory().create('linkToProcurerBoxes'),
      buyerBoxes: !!filterBoxes(general.buyerBoxes, lots).length
        ? filterBoxes(getSortedBuyerBoxes(general.buyerBoxes), lots)
        : [new TenderBoxFactory().create('buyerBoxes')],
      description: !!filterBoxes(general.description, lots).length
        ? filterBoxes(general.description, lots)
        : [new TenderBoxFactory().create('description')],
      locationBoxes: !!filterBoxes(general.locationBoxes, lots).length
        ? filterBoxes(general.locationBoxes, lots)
        : [new TenderBoxFactory().create('locationBoxes')],
      placeOfPerformanceNutsCode:
        general.placeOfPerformanceNutsCode && new TenderBox(general.placeOfPerformanceNutsCode),
      availableDate: timeline.availableDate
        ? new TenderBox(timeline.availableDate)
        : new TenderBoxFactory().create('availableDate'),
      deadline: !!filterBoxes(timeline.deadline, lots).length
        ? filterBoxes(timeline.deadline, lots)
        : [new TenderBoxFactory().create('deadline')],
      deadlineOfClarificationQuestions: !!filterBoxes(timeline.deadlineOfClarificationQuestions, lots).length
        ? filterBoxes(timeline.deadlineOfClarificationQuestions, lots)
        : [new TenderBoxFactory().create('deadlineOfClarificationQuestions')],
      contractDurationBoxes: !!filterBoxes(general.contractDurationBoxes, lots).length
        ? filterBoxes(general.contractDurationBoxes, lots)
        : [new TenderBoxFactory().create('contractDurationBoxes')],
      contractRenewalBoxes: !!filterBoxes(general.contractRenewalBoxes, lots).length
        ? filterBoxes(general.contractRenewalBoxes, lots)
        : [new TenderBoxFactory().create('contractRenewalBoxes')],
      contractValueBoxes: !!filterBoxes(general.contractValueBoxes, lots).length
        ? filterBoxes(general.contractValueBoxes, lots)
        : [new TenderBoxFactory().create('contractValueBoxes')],
      typeOfProcedureBoxes: !!filterBoxes(general.typeOfProcedureBoxes, lots).length
        ? filterBoxes(general.typeOfProcedureBoxes, lots)
        : [new TenderBoxFactory().create('typeOfProcedureBoxes')],
      frameworkAgreement: !!filterBoxes(general.frameworkAgreement, lots).length
        ? filterBoxes(general.frameworkAgreement, lots)
        : [new TenderBoxFactory().create('frameworkAgreement', [new BoxFieldBoolean('')])],
      linkForSubmittingTenderBoxes: !!filterBoxes(general.linkForSubmittingTenderBoxes, lots).length
        ? filterBoxes(general.linkForSubmittingTenderBoxes, lots)
        : [new TenderBoxFactory().create('linkForSubmittingTenderBoxes')],
      linkToTenderDocumentBoxes: !!filterBoxes(general.linkToTenderDocumentBoxes, lots).length
        ? filterBoxes(general.linkToTenderDocumentBoxes, lots)
        : [new TenderBoxFactory().create('linkToTenderDocumentBoxes')],
      referenceNumberBoxes: !!filterBoxes(general.referenceNumberBoxes, lots).length
        ? filterBoxes(general.referenceNumberBoxes, lots)
        : [new TenderBoxFactory().create('referenceNumberBoxes')],
      numberOfSuppliersAssignedContract: !!filterBoxes(general.numberOfSuppliersAssignedContract, lots).length
        ? filterBoxes(general.numberOfSuppliersAssignedContract, lots)
        : [new TenderBoxFactory().create('numberOfSuppliersAssignedContract')],
      contactPersonBoxes: !!filterBoxes(general.contactPersonBoxes, lots).length
        ? filterBoxes(general.contactPersonBoxes, lots)
        : [new TenderBoxFactory().create('contactPersonBoxes')],
      phoneBoxes: !!filterBoxes(general.phoneBoxes, lots).length
        ? filterBoxes(general.phoneBoxes, lots)
        : [new TenderBoxFactory().create('phoneBoxes')],
      emailBoxes: !!filterBoxes(general.emailBoxes, lots).length
        ? filterBoxes(general.emailBoxes, lots)
        : [new TenderBoxFactory().create('emailBoxes')],
      postcode: general.postcode ? new TenderBox(general.postcode) : new TenderBoxFactory().create('postcode'),
      city: general.city ? new TenderBox(general.city) : new TenderBoxFactory().create('city'),
      postalAddress: general.postalAddress
        ? new TenderBox(general.postalAddress)
        : new TenderBoxFactory().create('postalAddress'),
      buyerNutsCode: general.buyerNutsCode
        ? new TenderBox(general.buyerNutsCode)
        : new TenderBoxFactory().create('buyerNutsCode'),
      customBoxes: origin.custom
        .map(box => !!box && new TenderBox(box))
        .filter(isNotUndefined)
        .sort((a, b) => {
          if (!a) {
            return -1;
          }
          if (!b) {
            return 1;
          }
          const aTypeIdx = CUSTOM_BID_FIELDS_ORDER.findIndex(fieldOrder => fieldOrder === a.firstField?.type);
          const bTypeIdx = CUSTOM_BID_FIELDS_ORDER.findIndex(fieldOrder => fieldOrder === b.firstField?.type);

          return aTypeIdx - bTypeIdx;
        }),
      contractStartAndEndStart: timeline.contractStartAndEndStart
        ? new TenderBox(timeline.contractStartAndEndStart)
        : new TenderBoxFactory().create('contractStartAndEndStart'),
      contractStartAndEndEnd: timeline.contractStartAndEndEnd
        ? new TenderBox(timeline.contractStartAndEndEnd)
        : new TenderBoxFactory().create('contractStartAndEndEnd'),
      procurementStatus: (origin as IApiBidPreviewTenderOrigin).procurementStatus ?? [],
      isPlanningProcess: (origin as IApiBidPreviewTenderOrigin).isPlanningProcess,
      summaries: (origin as IApiBidPreviewTenderOrigin).summaries,
      titles: (origin as IApiBidPreviewTenderOrigin).titles,
      scopeOfContract:
        general?.scopeOfContract && general.scopeOfContract.length
          ? filterBoxes(general.scopeOfContract, lots).length
            ? filterBoxes(general.scopeOfContract, lots)
            : [new TenderBoxFactory().create('scopeOfContract')]
          : undefined
    };
  }

  get title(): string {
    return this.origin.name && isFieldString(this.origin.name.firstField)
      ? this.origin.name.firstField.string ?? ''
      : '';
  }

  get sourceUrl(): string | undefined {
    const linkToDocumentBoxes = this.origin.linkToTenderDocumentBoxes;
    return linkToDocumentBoxes?.length && isFieldUrl(linkToDocumentBoxes[0].firstField)
      ? linkToDocumentBoxes[0].firstField.url ?? undefined
      : undefined;
  }
}
