import { useContentLibrary, useContentLibraryNav } from 'src/models/contentLibrary/ContentLibrary/hooks';
import { ContentLibraryEventSource } from 'src/models/contentLibrary/ContentLibrary/types';
import ContentLibraryFilterAssignedTo from '../ContentLibraryFilterAssignedTo';
import ContentLibraryFilterCreatedBy from '../ContentLibraryFilterCreatedBy';
import UploadFileModal from '../ContentLibraryUploadModal/UploadFileModal';
import ImportCsvModal from '../ContentLibraryUploadModal/ImportCsvModal';
import ContentLibraryFilterSearch from '../ContentLibraryFilterSearch';
import { faCloudArrowDown } from '@fortawesome/pro-regular-svg-icons';
import ContentLibraryFilterRoom from '../ContentLibraryFilterRoom';
import ContentLibraryFilterType from '../ContentLibraryFilterType';
import React, { FC, useCallback, useMemo, useState } from 'react';
import ContentLibraryFilterTag from '../ContentLibraryFilterTag';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonGroupDropdown from 'src/common/ButtonGroupDropdown';
import { faFiles, faFolder } from '@fortawesome/pro-light-svg-icons';
import { FileExt } from 'src/models/file/types';
import { useTranslation } from 'react-i18next';
import { Button, InfoIcon } from 'src/common';
import ContentModal from '../ContentModal';
import styles from './index.module.scss';
import UploadFolderModal from '../ContentLibraryUploadModal/UploadFolderModal';
import { ContentLibraryUploadModalType } from '../ContentLibraryUploadModal/types';
import { useCurrentSidebar } from 'src/shared/InfoSidebar/hooks';
import { SidebarMode } from 'src/shared/InfoSidebar/types';

export const ContentLibraryFilters: FC = () => {
  const { t } = useTranslation();
  const { isRoomPage, isTaskPage } = useContentLibraryNav();
  const {
    data: state,
    showContentCardModal,
    setShowContentCardModal,
    selectedContent,
    setSelectedContent
  } = useContentLibrary();

  const sidebarState = useCurrentSidebar();
  const isSidebarMode =
    sidebarState?.mode === SidebarMode.CONTENT_LIBRARY || sidebarState?.mode === SidebarMode.TOOLBOX_CONTENT_LIBRARY;

  const clearVars = state?.clearVars;

  const [menuVisible, setMenuVisible] = useState(false);
  const [showModal, setShowModal] = useState<ContentLibraryUploadModalType | null>(null);

  const onClose = useCallback(() => {
    setSelectedContent(undefined);
    setShowContentCardModal(false);
    setShowModal(null);
  }, [setSelectedContent, setShowContentCardModal, setShowModal]);

  const eventSource = useMemo(() => {
    if (isRoomPage) return ContentLibraryEventSource.room;
    if (isTaskPage) return ContentLibraryEventSource.taskPage;
    return ContentLibraryEventSource.contentLibrary;
  }, [isRoomPage, isTaskPage]);

  const toggleMenu = useCallback(() => {
    setMenuVisible(prevState => !prevState);
  }, []);

  const onContentCardModalOpen = useCallback(() => {
    setShowContentCardModal(true);
    setMenuVisible(false);
  }, [setShowContentCardModal]);

  const onCsvModalOpen = useCallback(() => {
    setShowModal(ContentLibraryUploadModalType.ImportCsv);
    setMenuVisible(false);
  }, [setShowModal]);

  const onFileModalOpen = useCallback(() => {
    setShowModal(ContentLibraryUploadModalType.UploadFile);
    setMenuVisible(false);
  }, [setShowModal]);

  const onFolderModalOpen = useCallback(() => {
    setShowModal(ContentLibraryUploadModalType.UploadFolder);
    setMenuVisible(false);
  }, [setShowModal]);

  return (
    <>
      <div className={styles.bar}>
        <div className={styles.filters}>
          {!isSidebarMode && <ContentLibraryFilterSearch />}
          {!isRoomPage && <ContentLibraryFilterRoom eventSource={eventSource} />}
          <ContentLibraryFilterTag eventSource={eventSource} />
          <ContentLibraryFilterType eventSource={eventSource} />
          <ContentLibraryFilterCreatedBy eventSource={eventSource} />
          <div className={styles.infoIconContainer}>
            <ContentLibraryFilterAssignedTo eventSource={eventSource} />
            {isTaskPage && <InfoIcon desc={t('ContentLibrary.filterInfo')} />}
          </div>
          <Button className={styles.clearButton} type={'link'} onClick={clearVars}>
            {t('Common.clear')}
          </Button>
        </div>
        {!isSidebarMode && (
          <ButtonGroupDropdown
            mainAction={onContentCardModalOpen}
            mainTitle={t('ContentLibrary.newContentCard')}
            menuVisible={menuVisible}
            toggleMenu={toggleMenu}
            dropdownItems={
              <>
                <Button
                  className={styles.dropdownButton}
                  icon={<FontAwesomeIcon icon={faCloudArrowDown} />}
                  onClick={() => onCsvModalOpen()}
                  type={'text'}
                  disabled={state?.isFiltersApplied}
                >
                  <span className={styles.addButtonText}>{t('ContentLibrary.importFromFile')}</span>
                </Button>
                <Button
                  className={styles.dropdownButton}
                  icon={<FontAwesomeIcon icon={faFiles} />}
                  onClick={() => onFileModalOpen()}
                  type={'text'}
                  disabled={state?.isFiltersApplied}
                >
                  <span className={styles.addButtonText}>{t('ContentLibrary.uploadFiles')}</span>
                </Button>
                <Button
                  className={styles.dropdownButton}
                  icon={<FontAwesomeIcon icon={faFolder} />}
                  onClick={() => onFolderModalOpen()}
                  type={'text'}
                  disabled={state?.isFiltersApplied}
                >
                  <span className={styles.addButtonText}>{t('Common.uploadFolder')}</span>
                </Button>
              </>
            }
          />
        )}
        {showContentCardModal && <ContentModal onClose={onClose} content={selectedContent} />}
        {showModal === ContentLibraryUploadModalType.UploadFile && (
          <UploadFileModal onClose={onClose} extensions={[FileExt.pdf, FileExt.docx]} />
        )}
        {showModal === ContentLibraryUploadModalType.ImportCsv && (
          <ImportCsvModal onClose={onClose} extensions={[FileExt.xlsx, FileExt.csv]} />
        )}
        {showModal === ContentLibraryUploadModalType.UploadFolder && (
          <UploadFolderModal onClose={onClose} extensions={[FileExt.pdf, FileExt.docx]} />
        )}
      </div>
      {isSidebarMode && (
        <div className={styles.searchContainer}>
          <ContentLibraryFilterSearch />
        </div>
      )}
    </>
  );
};
