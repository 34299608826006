import { Editor } from '@tiptap/core';
import { Node } from '@tiptap/pm/model';
import { CUSTOM_NODE_NAMES } from 'src/models/canvas/constants';

const getActiveBidTask = (editor: Editor | null): Node | null => {
  if (!editor || !editor.state || !editor.state.selection) return null;

  const { selection } = editor.state;
  let { $anchor } = selection;

  // Traverse up to find the nearest node that matches the bidTask type
  while ($anchor.depth > 0) {
    const node = $anchor.node($anchor.depth);
    if (node.type.name === CUSTOM_NODE_NAMES.bidTask) return node;
    $anchor = editor.state.doc.resolve($anchor.before());
  }

  return null; // No active BidTask found
};

export default getActiveBidTask;
