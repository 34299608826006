import React, { FC, useCallback, useMemo } from 'react';
import styles from './index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots, faAngleLeft, faBooks, faListCheck, faFiles, faBell } from '@fortawesome/pro-light-svg-icons';
import classNames from 'classnames';
import { useCurrentSidebar, useOpenSidebar, useCloseSidebar } from 'src/shared/InfoSidebar/hooks';
import { SidebarMode } from 'src/shared/InfoSidebar/types';
import SidebarView from 'src/shared/InfoSidebar/SidebarView';
import BidComments from './BidComments';
import { useBidding } from 'src/models/bids/BidFull/hooks';
import { useTranslation } from 'react-i18next';
import ToolboxContentLibrary from './ToolboxContentLibrary';
import ToolboxChecklist from './ToolboxChecklist';
import ToolboxDocuments from './ToolboxDocuments';
import ToolboxReminders from './ToolboxReminders';
import { FeatureFlag } from '@tendium/prom-types';
import { useFeatureFlag } from 'src/helpers';
import { ContentLibraryProvider } from 'src/models/contentLibrary/ContentLibrary/providers';
import ToolboxCanvasTaskSources from './ToolboxCanvasTaskSources';
import { useActiveCanvasTask } from 'src/models/canvas/hooks';
import { trackCLOpenContentLibrary, TrackEventSource } from 'src/segment/events';

interface Props {
  previewClassName?: string;
  eventSource: TrackEventSource;
}

export const BidSidebar: FC<Props> = ({ eventSource, previewClassName }) => {
  const { data } = useBidding();
  const bidId = data?.id;
  const sidebarState = useCurrentSidebar();
  const openSidebar = useOpenSidebar();
  const closeSidebar = useCloseSidebar();
  const { t } = useTranslation();

  const { activeTaskId } = useActiveCanvasTask(); // activeTaskId is null if it is not canvas page (expected behavior)

  const isChecklistAvailable = useFeatureFlag(FeatureFlag.Checklist);
  const isRemindersFeature = useFeatureFlag(FeatureFlag.Reminders);
  const isBiddingGPTContentLibrary = useFeatureFlag(FeatureFlag.BiddingTool_BiddingGPT_ContentLibrary);

  const commentsCount = useMemo(() => {
    return (data && data.comments && data.comments.length) || 0;
  }, [data]);

  const onClick = useCallback(
    (mode: SidebarMode) => {
      if (sidebarState?.mode === mode) {
        closeSidebar();
      } else {
        openSidebar({ id: bidId || null, mode });
        if (mode === SidebarMode.TOOLBOX_CONTENT_LIBRARY) {
          trackCLOpenContentLibrary({ eventSource, taskId: activeTaskId ?? undefined });
        }
      }
    },
    [activeTaskId, bidId, closeSidebar, eventSource, openSidebar, sidebarState?.mode]
  );

  return (
    <div className={classNames(styles.sidebar, { [styles.isOpen]: sidebarState })}>
      <div className={styles.sidebarNav}>
        <div className={styles.tabs}>
          <button
            className={classNames(styles.sidebarSwitchButton, styles.tab, {
              [styles.isSwitched]: sidebarState
            })}
            onClick={
              !!sidebarState?.mode
                ? () => closeSidebar()
                : () => onClick(isChecklistAvailable ? SidebarMode.TOOLBOX_CHECKLIST : SidebarMode.TOOLBOX_COMMENT)
            }
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </button>

          {isChecklistAvailable && (
            <button
              onClick={() => onClick(SidebarMode.TOOLBOX_CHECKLIST)}
              className={classNames(styles.tab, {
                [styles.isActive]: sidebarState?.mode === SidebarMode.TOOLBOX_CHECKLIST
              })}
            >
              <div
                className={classNames({
                  [styles.iconBackground]: sidebarState?.mode === SidebarMode.TOOLBOX_CHECKLIST
                })}
              >
                <FontAwesomeIcon icon={faListCheck} className={styles.icon} />
              </div>
              {t('Toolbox.checklist')}
            </button>
          )}

          {isRemindersFeature && (
            <button
              onClick={() => onClick(SidebarMode.TOOLBOX_REMINDERS)}
              className={classNames(styles.tab, {
                [styles.isActive]: sidebarState?.mode === SidebarMode.TOOLBOX_REMINDERS
              })}
            >
              <div
                className={classNames({
                  [styles.iconBackground]: sidebarState?.mode === SidebarMode.TOOLBOX_REMINDERS
                })}
              >
                <FontAwesomeIcon icon={faBell} className={styles.icon} />
              </div>
              {t('Toolbox.reminders')}
            </button>
          )}

          <button
            onClick={() => onClick(SidebarMode.TOOLBOX_DOCUMENTS)}
            className={classNames(styles.tab, {
              [styles.isActive]: sidebarState?.mode === SidebarMode.TOOLBOX_DOCUMENTS
            })}
          >
            <div
              className={classNames({
                [styles.iconBackground]:
                  sidebarState?.mode === SidebarMode.TOOLBOX_DOCUMENTS ||
                  sidebarState?.mode === SidebarMode.TOOLBOX_RELATED_SOURCES
              })}
            >
              <FontAwesomeIcon icon={faFiles} className={styles.icon} />
            </div>
            {t('Toolbox.files')}
          </button>

          {isBiddingGPTContentLibrary && (
            <button
              onClick={() => onClick(SidebarMode.TOOLBOX_CONTENT_LIBRARY)}
              className={classNames(styles.tab, {
                [styles.isActive]:
                  sidebarState?.mode === SidebarMode.TOOLBOX_CONTENT_LIBRARY ||
                  sidebarState?.mode === SidebarMode.CANVAS_TASK_SOURCES
              })}
            >
              <div
                className={classNames({
                  [styles.iconBackground]:
                    sidebarState?.mode === SidebarMode.TOOLBOX_CONTENT_LIBRARY ||
                    sidebarState?.mode === SidebarMode.CANVAS_TASK_SOURCES
                })}
              >
                <FontAwesomeIcon icon={faBooks} className={styles.icon} />
              </div>
              {t('Toolbox.content')}
            </button>
          )}

          <button
            onClick={() => onClick(SidebarMode.TOOLBOX_COMMENT)}
            className={classNames(styles.tab, {
              [styles.isActive]: sidebarState?.mode === SidebarMode.TOOLBOX_COMMENT
            })}
          >
            <div
              className={classNames({
                [styles.iconBackground]: sidebarState?.mode === SidebarMode.TOOLBOX_COMMENT
              })}
            >
              <FontAwesomeIcon icon={faCommentDots} className={styles.icon} />
              {!!commentsCount && <span className={styles.tabCount}>{commentsCount}</span>}
            </div>
            {t('Toolbox.comments')}
          </button>
        </div>
      </div>

      <SidebarView className={classNames(styles.preview, previewClassName, { [styles.isOpen]: sidebarState })}>
        {isChecklistAvailable &&
          sidebarState &&
          sidebarState.mode === SidebarMode.TOOLBOX_CHECKLIST &&
          sidebarState.id && <ToolboxChecklist bidId={sidebarState.id} key={sidebarState.id} />}
        {isRemindersFeature &&
          sidebarState &&
          sidebarState.mode === SidebarMode.TOOLBOX_REMINDERS &&
          sidebarState.id && <ToolboxReminders bidId={sidebarState.id} key={sidebarState.id} />}
        {sidebarState &&
          (sidebarState.mode === SidebarMode.TOOLBOX_DOCUMENTS ||
            sidebarState.mode === SidebarMode.TOOLBOX_RELATED_SOURCES) &&
          sidebarState.id && <ToolboxDocuments key={sidebarState.id} />}
        {isBiddingGPTContentLibrary &&
          sidebarState &&
          (sidebarState.mode === SidebarMode.TOOLBOX_CONTENT_LIBRARY ||
            sidebarState.mode === SidebarMode.CANVAS_TASK_SOURCES) &&
          sidebarState.id && (
            <ContentLibraryProvider>
              {sidebarState.mode === SidebarMode.TOOLBOX_CONTENT_LIBRARY && (
                <ToolboxContentLibrary key={sidebarState.id} />
              )}
              {sidebarState.mode === SidebarMode.CANVAS_TASK_SOURCES && (
                <ToolboxCanvasTaskSources key={sidebarState.id} />
              )}
            </ContentLibraryProvider>
          )}
        {sidebarState && sidebarState.mode === SidebarMode.TOOLBOX_COMMENT && sidebarState.id && (
          <BidComments bidId={sidebarState.id} key={sidebarState.id} eventSource={eventSource} />
        )}
      </SidebarView>
    </div>
  );
};

export default BidSidebar;
