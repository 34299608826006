import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'src/common';
import styles from './index.module.scss';
import { supplierFilterSchema, TendersSearchSchemaType } from 'src/models/procurements/TendersSearch/schemas';
import { JunctionLogic } from '@tendium/prom-types';
import { zodParseErrorHandler } from 'src/helpers/zod';
import { z } from 'zod';

const schema = supplierFilterSchema.pick({
  supplierSearchLogic: true
});

type SchemaType = z.infer<typeof schema>;

interface Props {
  values: TendersSearchSchemaType;
  onChange: (values: TendersSearchSchemaType) => void;
}

const SupplierSearchLogic: FC<Props> = ({ values, onChange }) => {
  const { t } = useTranslation();

  const onValuesChange = useCallback(
    (values: SchemaType) => {
      const parsed = schema.safeParse(values);
      if (!parsed.success) {
        zodParseErrorHandler('SupplierSearchLogic', parsed.error);
        return;
      }
      onChange(parsed.data);
    },
    [onChange]
  );

  return (
    <form className={styles.form}>
      <Checkbox
        checked={values.supplierSearchLogic === JunctionLogic.AND}
        onChange={e => {
          onValuesChange({ supplierSearchLogic: e.target.checked ? JunctionLogic.AND : JunctionLogic.OR });
        }}
      >
        {t('ExpiringContracts.supplierJunctionLogic')}
      </Checkbox>
    </form>
  );
};

export default SupplierSearchLogic;
