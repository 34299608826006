import React, { FC, useMemo } from 'react';
import BidContent from './BidContent';
import { Footer, FooterView } from 'src/common';
import { Navigate, useParams, useLocation } from 'react-router-dom';
import { Paths } from '../paths';
import { useBidWorkspace } from 'src/models/bids/Bids/hooks';
import { Header, TendersNav } from 'src/shared';
import { BidPreviewProvider } from 'src/models/bids/BidPreview/providers';
import styles from './index.module.scss';
import BidHeader from './BidHeader';
import BidSidebar from './BidSidebar';
import BidOverviewContent from './BidOverviewContent';
import { BiddingToolTab } from 'src/models/bids/BidFull/types';
import BidNav from './BidNav';
import { useCurrentSidebar } from 'src/shared/InfoSidebar/hooks';
import { SidebarMode } from 'src/shared/InfoSidebar/types';
import classNames from 'classnames';
import { TaskPreview } from 'src/shared/TaskPreview/TaskPreview';

export const BidPage: FC = () => {
  const sidebarState = useCurrentSidebar();
  const isTaskPreviewMode = sidebarState?.mode === SidebarMode.TASK_PREVIEW;

  const { hash } = useLocation();
  const currentHash = useMemo(() => (!!hash ? hash.slice(1) : null), [hash]);

  const { id: bidId } = useParams<{ id: string }>();
  const { didFail } = useBidWorkspace(bidId);

  if (didFail) return <Navigate to={Paths.BIDSPACES} />;

  return (
    <BidPreviewProvider id={bidId ?? ''}>
      <div className={styles.layout}>
        <Header />
        <div
          className={classNames(styles.page, {
            [styles.fullWidth]: isTaskPreviewMode
          })}
          id="tender"
        >
          <TendersNav backToButton />
          <main className={styles.container}>
            <BidHeader />
            <BidNav />
            <div className={styles.content}>
              {(currentHash === BiddingToolTab.Overview || !currentHash) && <BidOverviewContent />}
              {currentHash === BiddingToolTab.Tasks && <BidContent />}
              {isTaskPreviewMode ? null : <BidSidebar eventSource="Task Preview" />}
            </div>
            <Footer view={FooterView.logo} />
          </main>
          <TaskPreview />
        </div>
      </div>
    </BidPreviewProvider>
  );
};

export default BidPage;
