import React, { FC, useCallback } from 'react';
import { Button, Spinner, Tooltip } from 'src/common';
import styles from './index.module.scss';
import { useCreateByggfaktaProject } from 'src/models/bids/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane as faPaperPlaneLight } from '@fortawesome/pro-light-svg-icons';
import { faPaperPlane as faPaperPlaneSolid } from '@fortawesome/pro-solid-svg-icons';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { FeatureFlag, useFeatureFlag } from 'src/helpers';

type Props = { bidId?: string; isActive?: boolean; className?: string };
export const ByggfaktaProjectButton: FC<Props> = ({ bidId, isActive, className }) => {
  const { t } = useTranslation();

  const [createProjectFn, { loading }] = useCreateByggfaktaProject();
  const createProject = useCallback(() => {
    bidId && !loading && createProjectFn({ bidId });
  }, [bidId, createProjectFn, loading]);
  const isBfProjectAvailable = useFeatureFlag(FeatureFlag.ByggfaktaPrivate);

  return bidId && isBfProjectAvailable ? (
    <Tooltip title={t(isActive ? 'BiddingTool.createBfProjectNote' : 'BiddingTool.createBfProject')}>
      <Button
        icon={
          loading ? (
            <Spinner />
          ) : (
            <FontAwesomeIcon icon={!isActive ? faPaperPlaneLight : faPaperPlaneSolid} className={styles.icon} />
          )
        }
        type={'text'}
        className={classNames(styles.button, className, { [styles.isActive]: isActive })}
        onClick={createProject}
      />
    </Tooltip>
  ) : null;
};

export default ByggfaktaProjectButton;
