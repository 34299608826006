import {
  ApiBidTaskAnswer,
  ApiBidTask,
  RequirementType,
  TaskFulfillment,
  QuestionStatus,
  TaskReferences,
  ReferenceGroup
} from './types';
import { IApiComment } from 'src/models/comments/types';
import { BidItemType } from '@tendium/prom-types/tender';
import { toPdfHighlightsContentData, toPdfHighlightsData } from 'src/shared/DocumentViewer/helpers';
import { IHighlight } from 'react-pdf-highlighter';

export class BidTask {
  public readonly id: string;
  public readonly title: string | null;
  public readonly question: string | null;
  public readonly groupId: string;
  public readonly groupTitle: string;
  public readonly groupFilePath?: string;
  public readonly assignedTo: string | null;
  public readonly requirementType: RequirementType | null;
  public readonly fulfilment: TaskFulfillment | null;
  public readonly deadline: number | null;
  public readonly answer: ApiBidTaskAnswer | null;
  public readonly headline: string | null;
  public readonly content: string | null;
  public readonly status: QuestionStatus;
  public readonly isSubmitted: boolean | null;
  public readonly comments: IApiComment[];
  public readonly highlights: IHighlight[];
  public readonly filePath: string;
  public readonly bidId: string;
  public readonly originId: string;
  public readonly originType: BidItemType;
  public readonly rank: string;
  public readonly classificationId?: string;
  public readonly primaryReferences?: TaskReferences[];
  public readonly secondaryReferences?: TaskReferences[];
  public readonly aiSummary?: string;

  constructor(private readonly apiBidQuestion: ApiBidTask) {
    const { questionGroup } = apiBidQuestion;
    this.id = apiBidQuestion.id;
    this.bidId = questionGroup.bid.id;
    this.originId = questionGroup.bid.itemId;
    this.originType = questionGroup.bid.itemType;
    this.classificationId = apiBidQuestion.classificationId;
    this.groupId = questionGroup.id;
    this.groupTitle = questionGroup.title;
    this.groupFilePath = questionGroup.filePath;
    this.headline = apiBidQuestion.document ? apiBidQuestion.document.headline : null;
    this.filePath = apiBidQuestion.document ? apiBidQuestion.document.path : '';
    this.title = apiBidQuestion.title;
    this.assignedTo = apiBidQuestion.assignedTo ? apiBidQuestion.assignedTo.id : null;
    this.requirementType = apiBidQuestion.requirementType;
    this.fulfilment = apiBidQuestion.fulfilment;
    this.answer = apiBidQuestion.answer;
    this.content = !!apiBidQuestion.content ? apiBidQuestion.content : null;
    this.question = !!apiBidQuestion.question ? apiBidQuestion.question : null;
    this.status = apiBidQuestion.status;
    this.isSubmitted = apiBidQuestion.isSubmitted;
    this.comments = apiBidQuestion.comments;
    this.rank = apiBidQuestion.rank;
    this.highlights = this.apiBidQuestion.document
      ? toPdfHighlightsData(this.apiBidQuestion.document.headlinePosition, this.apiBidQuestion.document.contentPosition)
      : [];
    this.deadline = !!apiBidQuestion.deadline ? apiBidQuestion.deadline : null;
    this.primaryReferences = this.mapReferences(apiBidQuestion.primaryReferences);
    this.secondaryReferences = this.mapReferences(apiBidQuestion.secondaryReferences);
    this.aiSummary = apiBidQuestion.aiSummary;
  }

  private mapReferences(references: ApiBidTask['primaryReferences' | 'secondaryReferences']): TaskReferences[] {
    const referencesMap = this.groupReferencesByPath(references);
    return this.convertReferencesMapToArray(referencesMap);
  }

  private groupReferencesByPath(
    references: ApiBidTask['primaryReferences' | 'secondaryReferences'] | undefined
  ): Map<string, ReferenceGroup> {
    const referencesMap = new Map<string, ReferenceGroup>();

    references?.forEach(ref => {
      const path = ref.path;
      const page = ref.contentPosition[0]?.page ?? 0;
      const highlights = toPdfHighlightsContentData(ref.contentPosition) || [];

      const existing = referencesMap.get(path);
      if (existing) {
        existing.allHighlights.push(...highlights);
        if (existing.highlightsByPage[page]) {
          existing.highlightsByPage[page].push(...highlights);
        } else {
          existing.highlightsByPage[page] = [...highlights];
        }
      } else {
        referencesMap.set(path, {
          reference: ref,
          allHighlights: [...highlights],
          highlightsByPage: { [page]: [...highlights] }
        });
      }
    });

    return referencesMap;
  }

  private convertReferencesMapToArray(referencesMap: Map<string, ReferenceGroup>): TaskReferences[] {
    return Array.from(referencesMap.values()).map(value => ({
      reference: value.reference,
      allHighlights: value.allHighlights,
      highlightsByPage: Object.entries(value.highlightsByPage).map(([page, highlights]) => ({
        page: Number(page),
        highlights
      }))
    }));
  }
}
