import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { GET_TENDER_FILES } from './query';
import { ApiTenderFilesResponse, ApiTenderFilesRequest } from '../TenderFiles/types';
import { TenderFilesData } from '.';

export function useTenderFiles(
  id?: string,
  skip?: boolean
): {
  data: TenderFilesData | null;
  loading: boolean;
} {
  const { data, loading } = useQuery<ApiTenderFilesResponse, ApiTenderFilesRequest>(GET_TENDER_FILES, {
    variables: {
      procurementId: id ?? ''
    },
    skip: !id || !!skip
  });

  return useMemo(
    () => ({
      data: data?.getTender ? new TenderFilesData(data.getTender) : null,
      loading
    }),
    [loading, data?.getTender]
  );
}
