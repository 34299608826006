import { IChecklistItem, IApiBidChecklist, IApiChecklistItem } from './types';

export class BidChecklist {
  public readonly id: string;
  public readonly workspaceId: string;
  public readonly checklist: IChecklistItem[];

  constructor(private readonly apiBidChecklist: IApiBidChecklist) {
    this.id = apiBidChecklist.id;
    this.workspaceId = apiBidChecklist.workspace.id;
    this.checklist = this.toChecklist();
  }

  private toChecklist(): IChecklistItem[] {
    return this.apiBidChecklist.checklist
      .filter((item: IApiChecklistItem) => item.isActive)
      .map(
        (item: IApiChecklistItem) =>
          ({ ...item, assignedTo: item.assignedTo ? item.assignedTo.id : null } as IChecklistItem)
      );
  }
}
