import React, { FC } from 'react';
import { Spinner, Tooltip } from 'src/common';
import styles from './index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { faPlusSquare, faSquareExclamation } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';

interface Props {
  isUpdating: boolean;
  wsBadge: React.ReactNode | null;
  onOpenMenu: () => void;
  menuVisible: boolean;
  inNoAccessWorkspace?: boolean;
  disabled?: boolean;
}

const ShortModeLabel: FC<Props> = props => {
  const { isUpdating, wsBadge, onOpenMenu, menuVisible, inNoAccessWorkspace, disabled } = props;
  const { t } = useTranslation();

  return (
    <button
      className={classNames(styles.button, { [styles.isOpen]: menuVisible, [styles.isDisabled]: disabled })}
      onClick={onOpenMenu}
      disabled={!!inNoAccessWorkspace || disabled}
    >
      {!!wsBadge ? (
        wsBadge
      ) : !!inNoAccessWorkspace ? (
        <Tooltip title={t('BidSpaces.inNoAccessWorkspace')}>
          <FontAwesomeIcon icon={faSquareExclamation} className={styles.noAccessWorkspaceIcon} />
        </Tooltip>
      ) : (
        <>
          {isUpdating && <Spinner className={styles.spinner} />}
          {!isUpdating && (
            <>
              <FontAwesomeIcon icon={faPlusSquare} className={classNames(styles.icon, styles.lineIcon)} />
              <FontAwesomeIcon icon={faPlusSquare} className={classNames(styles.icon, styles.fillIcon)} />
            </>
          )}
        </>
      )}
    </button>
  );
};

export default ShortModeLabel;
