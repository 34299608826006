import React, { FC, ReactNode } from 'react';
import BaseMarkdown from 'react-markdown';
import styles from './index.module.scss';
import classNames from 'classnames';
import { ReactMarkdownOptions } from 'react-markdown/lib/react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

interface IMarkdownTableCellProps {
  children: ReactNode;
}
const MarkdownTableCell: FC<IMarkdownTableCellProps> = props => {
  const { children } = props;
  return (
    <td>
      <span>{children}</span>
    </td>
  );
};

export interface Props extends ReactMarkdownOptions {
  patched?: boolean;
}

const renderers = {
  td: ({ children }: { children: ReactNode }) => <MarkdownTableCell>{children}</MarkdownTableCell>
};

export const ReactMarkdown: FC<Props> = ({ patched = true, ...originalProps }) => {
  const { className, children, ...restProps } = originalProps;
  // Markdown can behave differently. In our case we patch data to look like Helium's one
  const patchedField =
    !!children && typeof children === 'string' ? (patched ? children.replace(/•|➢/g, '-') : children) : '';

  // TODO: array of plugins
  return (
    <BaseMarkdown
      className={classNames(className, styles.markdown)}
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw]}
      components={renderers}
      linkTarget={'_blank'}
      {...restProps}
    >
      {patchedField}
    </BaseMarkdown>
  );
};

export default ReactMarkdown;
