import React, { FC } from 'react';
import { NumberInput, NumberInputProps } from '../NumberInput';

interface IntegerInputProps extends Omit<NumberInputProps, 'disabledKeys'> {
  allowNegative?: boolean;
}

export const IntegerInput: FC<IntegerInputProps> = React.forwardRef(({ allowNegative, ...restProps }, ref) => {
  const disabledKeys = ['.', ...(allowNegative ? [] : ['-'])];

  return <NumberInput {...restProps} ref={ref} disabledKeys={disabledKeys} />;
});

IntegerInput.displayName = 'IntegerInput';
export default IntegerInput;
