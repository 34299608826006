import { WorkspaceStatusCategory } from '@tendium/prom-types/tender';
import { trackWithSessionReplay } from '../helpers';
import { PageView } from 'src/models/users/Preferences/types';

export type AllBidspacesFilter = 'Bidspace' | 'Status' | 'Assigned to' | 'Bid type';

export function trackABFilter(filter: AllBidspacesFilter): void {
  trackWithSessionReplay('AB filtering', {
    filter
  });
}

export function trackABSwitchMode(mode: PageView): void {
  trackWithSessionReplay('AB Switch mode', {
    mode
  });
}

export function trackGraphInteraction(category: WorkspaceStatusCategory): void {
  trackWithSessionReplay('AB graph interaction', {
    category
  });
}
