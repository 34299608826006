import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { TenderBlockSize, OCDSEstimatedContractValue, IBoxFieldCurrency } from 'src/models/procurements/Tender/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faCoin, faRadar } from '@fortawesome/pro-light-svg-icons';
import { InfoBox } from '../Boxes';
import ReadOnlyBlockCurrency from '../../Blocks/Currency/ReadOnlyBlock';
import { BlockEmpty } from '../../Blocks';
import { usePublishedDate } from 'src/helpers';
import classNames from 'classnames';
import { ConditionalWrapper, Tooltip } from 'src/common';
import TooltipKeywordsItem from './TooltipKeywordItem';
import { MpKeywordAndGroup } from 'src/models/matchingProfiles/types';
import Deadline from 'src/common/Deadline';
import { OcdsValue } from 'src/models/procurements/TenderFree/types';

interface Props {
  published?: number | null;
  deadline: number | null;
  estimationValue?: OCDSEstimatedContractValue | IBoxFieldCurrency | OcdsValue | null;
  keywords?: MpKeywordAndGroup[];
  className?: string;
  shortMode?: boolean;
}

const InformationFields: FC<Props> = ({ published, deadline, estimationValue, keywords, className, shortMode }) => {
  const { t } = useTranslation();
  const publishedDate = usePublishedDate(published ?? null);

  return (
    <div className={classNames(styles.info, className)}>
      {published && (
        <InfoBox
          title={t('Tenders.published')}
          className={styles.infoBox}
          size={TenderBlockSize.Quarter}
          value={
            <div className={styles.content}>
              <FontAwesomeIcon icon={faClock} className={styles.icon} />
              <div className={styles.value}>{publishedDate}</div>
            </div>
          }
        />
      )}
      <InfoBox
        title={t('Tenders.deadline')}
        className={styles.infoBox}
        size={TenderBlockSize.Quarter}
        value={
          <div className={styles.content}>
            <Deadline deadline={deadline} showIcon />
          </div>
        }
      />
      <InfoBox
        title={t('Tenders.estValue')}
        className={styles.infoBox}
        size={TenderBlockSize.Quarter}
        value={
          estimationValue ? (
            <ReadOnlyBlockCurrency
              currency={estimationValue.currency}
              amount={estimationValue.amount}
              config={{ icon: faCoin, currency: { isShort: shortMode } }}
            />
          ) : (
            <BlockEmpty />
          )
        }
      />
      {keywords && (
        <ConditionalWrapper
          condition={!!keywords?.length}
          wrapper={children => (
            <Tooltip
              placement={'bottomRight'}
              title={keywords?.map(group => (
                <TooltipKeywordsItem
                  key={group.groupId ?? group.values[0].id}
                  keywords={group.values}
                  color={group.highlightColor}
                />
              ))}
            >
              {children}
              {''}
            </Tooltip>
          )}
        >
          <InfoBox
            title={t('Tenders.keywords')}
            className={styles.infoBox}
            size={TenderBlockSize.Quarter}
            value={
              !!keywords?.length ? (
                <div className={styles.content}>
                  <FontAwesomeIcon icon={faRadar} className={styles.icon} />
                  <div className={styles.value}>{keywords?.length}</div>
                </div>
              ) : (
                <BlockEmpty />
              )
            }
          />
        </ConditionalWrapper>
      )}
    </div>
  );
};

export default InformationFields;
