import { gql } from '@apollo/client';
import { BID_BF_PROJECT_FIELDS, BID_BOX_FIELDS, BID_BOX_FULL_FIELDS } from '../queries';
import { UPDATE_TENDER_BOX_FIELDS, LOT_FIELDS } from 'src/models/procurements/queries';
import { INBOX_EMAIL_BASIC_FIELDS } from 'src/models/callOffs/queries';
import { USER_NAME_FIELDS } from 'src/models/users/queries';
import { COMMENT_FIELDS } from '../../comments/queries';
import { ASSIGNED_TO_FIELDS } from 'src/models/users/AssignedTo/queries';
import { WEBHOOKS_CREATE_STATUS_FIELDS } from 'src/models/company/Webhooks/queries';
import { AI_SUMMARIES_FIELDS, AI_TITLES_FIELDS } from 'src/models/procurements/Tender/fragments';
import { DECISION_MATRIX_FIELDS_FULL } from 'src/models/decisionMatrix/fragments';

export const BIDS_GENERAL_ITEM_FIELDS = gql`
  fragment bidsGeneralItemFields on General {
    id
    name {
      ...bidBoxFullFields
    }
    buyerBoxes {
      ...bidBoxFullFields
    }
    contractValueBoxes {
      ...bidBoxFullFields
    }
    scopeOfContract {
      ...bidBoxFullFields
      dataSource {
        filePath
        origin
        text
        headline
      }
    }
    description {
      ...bidBoxFullFields
    }
    contractDurationBoxes {
      ...bidBoxFullFields
    }
    locationBoxes {
      ...bidBoxFullFields
    }
    placeOfPerformanceNutsCode {
      ...bidBoxFullFields
    }
    linkToProcurerBoxes {
      ...bidBoxFullFields
    }
    contractRenewalBoxes {
      ...bidBoxFullFields
    }
    typeOfProcedureBoxes {
      ...bidBoxFullFields
    }
    frameworkAgreement {
      ...bidBoxFullFields
    }
    linkForSubmittingTenderBoxes {
      ...bidBoxFullFields
    }
    linkToTenderDocumentBoxes {
      ...bidBoxFullFields
    }
    referenceNumberBoxes {
      ...bidBoxFullFields
    }
    numberOfSuppliersAssignedContract {
      ...bidBoxFullFields
    }
    contactPersonBoxes {
      ...bidBoxFullFields
    }
    phoneBoxes {
      ...bidBoxFullFields
    }
    emailBoxes {
      ...bidBoxFullFields
    }
    postcode {
      ...bidBoxFullFields
    }
    city {
      ...bidBoxFullFields
    }
    postalAddress {
      ...bidBoxFullFields
    }
    buyerNutsCode {
      ...bidBoxFullFields
    }
  }
  ${BID_BOX_FULL_FIELDS}
`;

export const BIDS_TIMELINE_ITEM_FIELDS = gql`
  fragment bidsTimelineItemFields on TimelineRefactored {
    id
    availableDate {
      ...bidBoxFullFields
    }
    contractStartAndEndStart {
      ...bidBoxFullFields
    }
    contractStartAndEndEnd {
      ...bidBoxFullFields
    }
    deadlineOfClarificationQuestions {
      ...bidBoxFullFields
    }
    deadline {
      ...bidBoxFullFields
    }
  }
  ${BID_BOX_FULL_FIELDS}
`;

export const BIDS_FIELDS_PREVIEW = gql`
  fragment bidsFieldsPreview on BidV2 {
    id
    bidStageId
    bidContext
    bidContextId
    matchContext {
      matchedKeywords {
        keywords
        highlights {
          value
          color
          context
          targetPath
        }
      }
      matchedCpvs
    }
    decisionMatrix {
      ...decisionMatrixFieldsFull
    }
    createdAt
    createdBy {
      ...userNameFields
    }
    comments {
      ...commentFields
    }
    status {
      id
      name
    }
    assignedTo {
      ...assignedToFields
    }
    workspace {
      id
      name
      color
    }
    webhookStatus @include(if: $isWebHooksFeature) {
      ...webhooksCreateStatusFields
    }
    isRejected
    itemType
    itemId
    ...BidByggfaktaProjectFields
    mails {
      ...inboxEmailBasicFields
    }
    item {
      ... on Tender {
        id
        procurementStatus
        isPlanningProcess
        assignedTo {
          ...assignedToFields
        }
        timeline {
          ...bidsTimelineItemFields
        }
        general {
          ...bidsGeneralItemFields
        }
        lots {
          ...lotFields
        }
        custom {
          ...bidBoxFields
        }
        summaries @include(if: $includeSummary) {
          ...aiSummariesFields
        }
        titles @include(if: $includeSummary) {
          ...aiTitlesFields
        }
      }
      ... on UserMail {
        ...inboxEmailBasicFields
        timeline {
          ...bidsTimelineItemFields
        }
        general {
          ...bidsGeneralItemFields
        }
        custom {
          ...bidBoxFields
        }
      }
      ... on ManualBidItem {
        id
        timeline {
          ...bidsTimelineItemFields
        }
        general {
          ...bidsGeneralItemFields
        }
        custom {
          ...bidBoxFields
        }
      }
    }
  }
  ${USER_NAME_FIELDS}
  ${BIDS_GENERAL_ITEM_FIELDS}
  ${BIDS_TIMELINE_ITEM_FIELDS}
  ${BID_BOX_FIELDS}
  ${COMMENT_FIELDS}
  ${INBOX_EMAIL_BASIC_FIELDS}
  ${LOT_FIELDS}
  ${BID_BF_PROJECT_FIELDS}
  ${ASSIGNED_TO_FIELDS}
  ${WEBHOOKS_CREATE_STATUS_FIELDS}
  ${AI_SUMMARIES_FIELDS}
  ${AI_TITLES_FIELDS}
  ${DECISION_MATRIX_FIELDS_FULL}
`;

export const GET_BID_PREVIEW = gql`
  query getPreviewBid($bidId: String!, $isWebHooksFeature: Boolean!, $includeSummary: Boolean!) {
    getBid(input: { bidId: $bidId }) {
      ...bidsFieldsPreview
    }
  }
  ${BIDS_FIELDS_PREVIEW}
`;

export const UPDATE_TENDER_BOX = gql`
  mutation updateTenderBox($id: String!, $bidId: String!, $box: TenderBoxInput!) {
    updateTenderBox(input: { id: $id, bidId: $bidId, box: $box }) {
      id
      specificationId
      category
      ...boxFieldsFieldResult
    }
  }
  ${UPDATE_TENDER_BOX_FIELDS}
`;

export const UPDATE_CUSTOM_TENDER_BOX = gql`
  mutation updateCustomBidFieldBox($id: String!, $bidId: String!, $box: CustomBidFieldBoxInput!) {
    updateCustomBidFieldBox(input: { id: $id, bidId: $bidId, box: $box }) {
      id
      specificationId
      ...boxFieldsFieldResult
    }
  }
  ${UPDATE_TENDER_BOX_FIELDS}
`;

export const REJECT_BID = gql`
  mutation rejectBid($id: String!, $rejected: Boolean!) {
    rejectBid(input: { id: $id, rejected: $rejected }) {
      id
      isRejected
    }
  }
`;

// Subset of BIDS_FIELDS
export const BID_CACHE_UPDATE_FIELDS = gql`
  fragment fields on BidV2 {
    workspace {
      id
    }
    assignedTo {
      id
    }
    status {
      id
      category
    }
    bidStageId
    itemType
    item {
      general {
        name {
          fields {
            ... on ProcurementBoxStringField {
              string
            }
          }
        }
        contractValueBoxes {
          fields {
            ... on ProcurementBoxMoneyFieldDTO {
              name
              type
              amount
              currency
            }
          }
        }
      }
    }
  }
`;
