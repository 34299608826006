import React, { FC, useCallback, useMemo } from 'react';
import { Button, Modal, Radio, SearchFieldTag } from 'src/common';
import { useTranslation } from 'react-i18next';
import KeywordsArea from '../KeywordsArea';
import KeywordsTable from '../KeywordsTable';
import styles from './index.module.scss';
import { RadioChangeEvent, Space } from 'antd';
import { useKeywords } from 'src/models/keywords/hooks';
import { FeatureFlag, FilterLogic, KeywordWidth } from '@tendium/prom-types';
import { trackMPEditKeywordDone, trackMPEditKeywordWidth } from 'src/segment/events';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { HighlightColorDict } from 'src/models/matchingProfiles/types';
import KeywordsInternationalSearchToggle from '../KeywordsInternationalSearchToggle';
import { useFeatureFlag } from 'src/helpers';

interface Props {
  onClose: () => void;
  disabled?: boolean;
}

export const AdvancedSettingsModal: FC<Props> = props => {
  const { onClose, disabled } = props;
  const { t } = useTranslation();
  const { data, updateKeywordWidth } = useKeywords();
  const isInternationalSearchEnabled = useFeatureFlag(FeatureFlag.MatchingProfile_InternationalSearch);

  const onCloseModal = useCallback(() => {
    onClose();
    trackMPEditKeywordDone(data.eventNamePrefix, data.filterLogic, data.mp);
  }, [data.eventNamePrefix, data.filterLogic, data.mp, onClose]);

  const onUpdateKeywordWidth = useCallback(
    (e: RadioChangeEvent) => {
      updateKeywordWidth(e.target.value);
      trackMPEditKeywordWidth(data.eventNamePrefix, data.filterLogic, e.target.value, data.mp);
    },
    [data.eventNamePrefix, data.filterLogic, data.mp, updateKeywordWidth]
  );

  const genericCopyKeywords = useMemo(() => {
    if (data.keywords?.length) {
      return {
        values: data.keywords[0].values,
        highlightColor: data.keywords[0].highlightColor,
        total: data.keywords.length + data.newKeywords.length - 1
      };
    }

    if (data.newKeywords.length) {
      return {
        values: data.newKeywords[0].values,
        highlightColor: data.newKeywords[0].highlightColor,
        total: (data.keywords?.length || 0) + data.newKeywords.length - 1
      };
    }
  }, [data.keywords, data.newKeywords]);

  const cpvCodesWithExact = [...(data.cpvCodes ?? []), ...(data.cpvCodesExact ?? [])];

  return (
    <Modal
      title={data.texts.advancedModalTitle}
      visible
      onCancel={onClose}
      width={890}
      className={styles.modal}
      footer={
        <Button type={'primary'} loading={false} onClick={onCloseModal}>
          {t('Common.done')}
        </Button>
      }
    >
      {data.filterLogic === FilterLogic.Filter ? (
        <div className={styles.filterLogic}>
          {t('Tenders.filterLogic')}
          <Radio.Group onChange={onUpdateKeywordWidth} className={styles.radioGroup} value={data.keywordWidth}>
            <Space direction="horizontal">
              <span className={styles.radioLeft}>
                <Radio value={KeywordWidth.Broad}>
                  <div className={styles.title}>{t('Tenders.Keywords.broadTitle')}</div>
                  <div className={styles.desc}>{t('Tenders.Keywords.broadDesc')}</div>
                </Radio>
              </span>
              <span className={styles.radioRight}>
                <Radio value={KeywordWidth.Narrow}>
                  <div className={styles.title}>{t('Tenders.Keywords.narrowTitle')}</div>
                  <div className={styles.desc}>{t('Tenders.Keywords.narrowDesc')}</div>
                </Radio>
              </span>
            </Space>
          </Radio.Group>
        </div>
      ) : null}
      <div className={styles.searchField}>
        <KeywordsArea
          keywords={data.keywords}
          newKeywords={data.newKeywords}
          disabled={!!disabled}
          placeholder={data.texts.searchFieldPlaceholder}
          extendedVersion
          addButtonLabel={data.texts.searchFieldButtonLabel}
        />
      </div>
      {!!isInternationalSearchEnabled && <KeywordsInternationalSearchToggle />}
      <div className={styles.table}>
        <KeywordsTable disabled={disabled} deleteDescText={data.texts.deleteButtonDesc} />
      </div>
      {genericCopyKeywords ? (
        <div className={styles.genericCopyContainer}>
          <FontAwesomeIcon icon={faInfoCircle} className={styles.infoIcon} />
          <span>
            {data.filterLogic === FilterLogic.Filter
              ? t('Tenders.Keywords.broadAndNarrowGenericCopyDesc1')
              : t('Tenders.Keywords.highlightGenericCopyDesc1')}
          </span>
          <SearchFieldTag
            value={genericCopyKeywords.values}
            color={HighlightColorDict[genericCopyKeywords.highlightColor]}
            className={styles.keywords}
          />
          {!!genericCopyKeywords.total ? (
            <SearchFieldTag
              value={`+${genericCopyKeywords.total} ${t('Common.word', { count: genericCopyKeywords.total })}`}
              color={HighlightColorDict[genericCopyKeywords.highlightColor]}
              className={styles.moreWords}
            />
          ) : null}
          <span>
            {data.filterLogic === FilterLogic.Filter
              ? !!cpvCodesWithExact.length
                ? data.keywordWidth === KeywordWidth.Broad
                  ? t('Tenders.Keywords.broadGenericCopyDesc2')
                  : t('Tenders.Keywords.narrowGenericCopyDesc2')
                : t('Tenders.Keywords.broadAndNarrowGenericCopyDesc2')
              : t('Tenders.Keywords.highlightGenericCopyDesc2')}
          </span>
          {data.filterLogic === FilterLogic.Filter && cpvCodesWithExact.length ? (
            <>
              <SearchFieldTag value={cpvCodesWithExact[0]} color={'#efdbff'} />
              {cpvCodesWithExact.length > 1 ? (
                <SearchFieldTag
                  value={`+${cpvCodesWithExact.length - 1} ${t('Common.code', {
                    count: cpvCodesWithExact.length - 1
                  })}`}
                  color={'#efdbff'}
                  className={styles.moreWords}
                />
              ) : null}
            </>
          ) : null}
        </div>
      ) : null}
    </Modal>
  );
};

export default AdvancedSettingsModal;
