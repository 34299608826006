import React, { FC, useMemo } from 'react';
import styles from './index.module.scss';
import { Tooltip } from 'src/common';
import { ITenderLot } from 'src/models/procurements/Tender/types';

const lotsToLotsRanges = (lots: ITenderLot[]): ITenderLot[][] => {
  const isInRange = (a: ITenderLot, b: ITenderLot): boolean => {
    return b.lotItem - a.lotItem <= 1;
  };
  const result: ITenderLot[][] = [];
  let prevElem: ITenderLot | undefined = undefined;
  let chunk: ITenderLot[] = [];
  for (const elem of lots) {
    if (!prevElem || isInRange(prevElem, elem)) {
      chunk.push(elem);
    } else {
      result.push(chunk);
      chunk = [elem];
    }
    prevElem = elem;
  }
  result.push(chunk);
  return result;
};

interface ILotsRangeDescription {
  lotsRange: ITenderLot[];
}

const LotsRangeDescription: FC<ILotsRangeDescription> = (props: ILotsRangeDescription) => {
  const { lotsRange } = props;
  return (
    <ul className={styles.lots}>
      {lotsRange.map((lot: ITenderLot) => (
        <li className={styles.lot} key={lot.id}>
          <span className={styles.index}>{`${lot.lotItem}.`}</span>
          <span className={styles.desc}>{`${lot.name}`}</span>
        </li>
      ))}
    </ul>
  );
};

interface Props {
  lots?: ITenderLot[];
}

export const LotsSups: FC<Props> = props => {
  const { lots } = props;
  const lotsLength = lots?.length;
  const lotsLimit = 2;
  const lotsRanges = useMemo(() => (!!lots ? lotsToLotsRanges(lots) : []), [lots]);

  return !!lotsLength ? (
    <>
      {lotsLength <= lotsLimit ? (
        <div className={styles.lotSups}>
          {lots?.map((lot, index) => (
            <Tooltip title={lot.name} key={lot.id}>
              <sup className={styles.lotSup}>
                {`${lot.lotItem}`}
                {index !== lotsLength - 1 && `,`}
              </sup>
            </Tooltip>
          ))}
        </div>
      ) : (
        <div className={styles.lotSups}>
          {lotsRanges.map((lotsRange, index) => (
            <Tooltip
              title={<LotsRangeDescription lotsRange={lotsRange} />}
              key={`lotsRange_${index}`}
              overlayClassName={styles.tooltip}
            >
              <sup className={styles.lotSup}>
                {lotsRange.length > 1
                  ? `${lotsRange[0].lotItem} - ${lotsRange[lotsRange.length - 1].lotItem}`
                  : `${lotsRange[0].lotItem}`}
                {index !== lotsRanges.length - 1 && `,`}
              </sup>
            </Tooltip>
          ))}
        </div>
      )}
    </>
  ) : null;
};

export default LotsSups;
