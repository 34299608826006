import React, { FC, useCallback, useMemo, useState } from 'react';
import { DirectoryTree, Button, notification, confirm, Skeleton } from 'src/common';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { DataNode, EventDataNode } from 'rc-tree/lib/interface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownload, faTrashCan } from '@fortawesome/pro-light-svg-icons';
import classNames from 'classnames';
import { getIconByFileType } from 'src/helpers/fileTypeIcon';
import {
  getUpdateCacheOnDeleteBidAttachment,
  useGetBidAttachments,
  useRemoveBidAttachment
} from 'src/models/bids/BidAttachments/hooks';
import { downloadFile } from 'src/helpers/files';
import { AttachFileToBidResponse } from 'src/models/bids/BidAttachments/types';
import modalStyles from 'src/common/Modal/index.module.scss';
import { DocumentViewer } from 'src/shared';

interface ICategoryEventDataNode extends EventDataNode {
  getUrl: string;
  fileType: string;
  fileName: string;
}

const getTreeData = (
  directoryName: string,
  attachments: AttachFileToBidResponse[],
  onDeleteAttachment: (id: string) => Promise<void>
): DataNode[] => {
  const onDownload = (url: string, fileName: string): void => {
    if (url) {
      downloadFile(url, fileName);
    }
  };

  return attachments.map((attachment, index) => ({
    key: `${directoryName}-${index}`,
    icon: <>{getIconByFileType(attachment.document.name.split('.').pop())}</>,
    title: (
      <>
        <span className={styles.docTitle}>{attachment.document.name}</span>
        <Button
          type={'text'}
          className={classNames(styles.docAction, styles.fileAction)}
          onClick={e => {
            e.stopPropagation();
            if (attachment) {
              onDownload(attachment.document.getUrl, attachment.document.name);
            }
          }}
          icon={<FontAwesomeIcon icon={faCloudDownload} />}
        />
        <Button
          type={'text'}
          className={classNames(styles.docAction, styles.fileAction)}
          onClick={e => {
            e.stopPropagation();
            if (attachment) {
              onDeleteAttachment(attachment.id);
            }
          }}
          icon={<FontAwesomeIcon icon={faTrashCan} />}
        />
      </>
    ),
    getUrl: attachment.document.getUrl,
    fileType: attachment.document.name.split('.').pop(),
    filePath: `${directoryName}/${attachment.document.name}`,
    fileName: attachment.document.name
  }));
};

interface Props {
  bidId: string;
}

export const MyFilesContent: FC<Props> = props => {
  const { bidId } = props;
  const { t } = useTranslation();

  const { data: bidAttachmentsData, loading } = useGetBidAttachments(bidId);

  const [removeBidAttachment] = useRemoveBidAttachment();

  const onDeleteAttachment = useCallback(
    async (id: string) => {
      confirm.confirm({
        title: t('DocumentLibrary.deleteFile'),
        content: t('DocumentLibrary.deleteFileConfirm'),
        okText: t('Common.delete'),
        cancelText: t('Common.cancel'),
        className: modalStyles.confirmModal,
        centered: true,
        onOk() {
          removeBidAttachment({
            variables: { id: id },
            update: getUpdateCacheOnDeleteBidAttachment(id, bidId)
          }).catch(() => {
            notification.error({
              description: t('Common.unknownErrorDesc'),
              message: t('Common.unknownError')
            });
          });
        }
      });
    },
    [bidId, removeBidAttachment, t]
  );

  const [previewUrl, setPreviewUrl] = useState('');
  const [fileName, setFileName] = useState('');

  const onClick = useCallback(
    (event: React.MouseEvent<Element, MouseEvent>, element: EventDataNode | ICategoryEventDataNode) => {
      if ('getUrl' in element) {
        if (element.fileType === 'pdf') {
          setPreviewUrl(element.getUrl);
          setFileName(element.fileName);
        } else {
          downloadFile(element.getUrl, element.fileName);
        }
      }
    },
    []
  );

  const onClose = useCallback(() => {
    setPreviewUrl('');
    setFileName('');
  }, []);

  const treeData: DataNode[] = useMemo(() => {
    return bidAttachmentsData ? getTreeData('myFiles', bidAttachmentsData, onDeleteAttachment) : [];
  }, [bidAttachmentsData, onDeleteAttachment]);

  return loading ? (
    <Skeleton active={loading} />
  ) : (
    <>
      <DirectoryTree
        defaultExpandedKeys={treeData.map(node => node.key)}
        className={classNames(styles.directoryTree, loading ? styles.loading : null)}
        selectable={false}
        onClick={onClick}
        treeData={treeData}
      />
      {!!previewUrl && <DocumentViewer loading={loading} url={previewUrl} title={fileName} onClose={onClose} />}
    </>
  );
};

export default MyFilesContent;
