import React, { FC, useCallback, useMemo, useRef } from 'react';
import styles from './index.module.scss';
import { Form, Input, Select, Tooltip, Button } from 'src/common';
import { BoxFieldRangeUnit, IBoxFieldNumber } from 'src/models/procurements/Tender/types';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { Maybe } from 'src/helpers/typescript';
import { IBlockEditProps } from 'src/shared/Tender/Boxes/types';
import ValueActions from '../../ValueActions';
import classNames from 'classnames';
import { useClickAway } from 'react-use';

interface IFormData {
  number: number;
  unit?: Maybe<BoxFieldRangeUnit>;
}

export interface Props extends IBlockEditProps<IBoxFieldNumber> {}

export const EditableBlockNumber: FC<Props> = ({ field, onChange, onFinish, className }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const ref = useRef<HTMLDivElement>(null);

  const { id: fieldId, number, unit } = field;

  const onSubmit = useCallback(() => {
    const value: IFormData = form.getFieldValue(fieldId);
    value.number !== field.number || value.unit !== field.unit
      ? onChange && onChange(field.update(Number(value.number) ?? null, value.unit ?? null))
      : onFinish && onFinish();
  }, [field, fieldId, form, onChange, onFinish]);

  const onChangeUnit = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  const initialValues = useMemo(() => {
    return {
      [fieldId]: {
        number,
        unit
      }
    };
  }, [fieldId, number, unit]);

  const onCancel = useCallback(() => {
    form.resetFields();
    onFinish && onFinish();
  }, [form, onFinish]);

  useClickAway(ref, e => {
    e.stopPropagation();
    e.preventDefault();
    onSubmit();
  });

  return (
    <div ref={ref} className={className}>
      <Form form={form} initialValues={initialValues} className={classNames(styles.container, styles.fields)}>
        <div className={styles.field}>
          <Form.Item name={[fieldId, 'number']}>
            <Input
              onPressEnter={onSubmit}
              type={'number'}
              placeholder={t('Common.Blocks.number')}
              className={styles.control}
            />
          </Form.Item>
          <ValueActions onCancel={onCancel} />
        </div>
        <div className={styles.field}>
          <Form.Item name={[fieldId, 'unit']}>
            <Select
              optionFilterProp={'value'}
              onChange={onChangeUnit}
              getPopupContainer={trigger => trigger.parentNode}
              className={styles.control}
              dropdownClassName={styles.select}
              listHeight={125}
              placeholder={t('Common.unit')}
              allowClear
              clearIcon={
                <Tooltip title={t('Common.Blocks.deleteUnit')}>
                  <Button type={'text'} icon={<FontAwesomeIcon icon={faTimes} />} />
                </Tooltip>
              }
            >
              {Object.values(BoxFieldRangeUnit).map(rangeUnit => (
                <Select.Option key={rangeUnit} value={rangeUnit}>
                  <span>{t(`Tenders.BoxFieldRangeUnit.${rangeUnit}`)}</span>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default EditableBlockNumber;
