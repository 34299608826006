import React, { FC, useCallback } from 'react';
import { Menu } from 'src/common';
import { useTranslation } from 'react-i18next';
import { usePreviewTabState } from 'src/models/procurements/hooks';
import { ActivePreviewTab } from 'src/models/procurements/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartSimple, faCommentDots, faEye, faFiles, faBullseyeArrow } from '@fortawesome/pro-light-svg-icons';
import previewStyles from 'src/shared/InfoSidebar/index.module.scss';
import classNames from 'classnames';
import { useBidPreview } from 'src/models/bids/BidPreview/hooks';
import { FeatureFlag } from '@tendium/prom-types';
import { useFeatureFlag } from 'src/helpers';

export const BidPreviewNav: FC = () => {
  const { t } = useTranslation();
  const { data } = useBidPreview();
  const bidId = data?.id;
  const [activeTab, setActiveTab] = usePreviewTabState();

  const isBidFlowFeature = useFeatureFlag(FeatureFlag.BiddingWorkflow_Tasks);
  const isCompanyFitFeature = useFeatureFlag(FeatureFlag.Bidding_CompanyFit);

  const hasMatchData = !!data?.matchData;

  const onChangeTab = useCallback(
    ({ key }: { key: string }) => {
      setActiveTab(key as ActivePreviewTab);
    },
    [setActiveTab]
  );

  return (
    <div className={previewStyles.container}>
      <Menu mode={'horizontal'} onClick={onChangeTab} selectedKeys={[activeTab]} className={previewStyles.navTabs}>
        <Menu.Item key={ActivePreviewTab.Info} className={previewStyles.navTab}>
          <span className={previewStyles.navTab}>
            <FontAwesomeIcon icon={faEye} className={previewStyles.navTabIcon} />
            <span className={previewStyles.navTabLabel}>{t('Tenders.overview')}</span>
          </span>
        </Menu.Item>
        <Menu.Item key={ActivePreviewTab.Comments} className={previewStyles.navTab} disabled={!bidId}>
          <span className={previewStyles.navTab}>
            <FontAwesomeIcon icon={faCommentDots} className={previewStyles.navTabIcon} />
            <span className={previewStyles.navTabLabel}>{t('Comments.comments')}</span>
          </span>
        </Menu.Item>
        {isBidFlowFeature && (
          <Menu.Item key={ActivePreviewTab.Tasks} className={classNames(previewStyles.navTab)}>
            <span className={previewStyles.navTab}>
              <FontAwesomeIcon icon={faChartSimple} className={previewStyles.navTabIcon} />
              <span className={previewStyles.navTabLabel}>{t('BiddingTool.tasksTitle')}</span>
            </span>
          </Menu.Item>
        )}
        {isCompanyFitFeature && hasMatchData && (
          <Menu.Item key={ActivePreviewTab.CompanyFit} className={classNames(previewStyles.navTab)}>
            <span className={previewStyles.navTab}>
              <FontAwesomeIcon icon={faBullseyeArrow} className={previewStyles.navTabIcon} />
              <span className={previewStyles.navTabLabel}>{t('Tenders.matches')}</span>
            </span>
          </Menu.Item>
        )}
        <Menu.Item key={ActivePreviewTab.DocumentsBidPreview} className={classNames(previewStyles.navTab)}>
          <span className={previewStyles.navTab}>
            <FontAwesomeIcon icon={faFiles} className={previewStyles.navTabIcon} />
            <span className={previewStyles.navTabLabel}>{t('Toolbox.files')}</span>
          </span>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default BidPreviewNav;
