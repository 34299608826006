import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, InfoIcon, Switch } from 'src/common';
import styles from './index.module.scss';
import { useKeywords } from 'src/models/keywords/hooks';
import { FilterLogic, Language } from '@tendium/prom-types';

const KeywordsInternationalSearchToggle: FC = () => {
  const { data, updateKeywordSearchLanguages } = useKeywords();
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const initialValues = useMemo(() => {
    return {
      internationalSearch:
        data.filterLogic === FilterLogic.Filter
          ? data.filterKeywordLanguages?.includes(Language.en) ?? false
          : data.highlightKeywordLanguages?.includes(Language.en) ?? false
    };
  }, [data.filterKeywordLanguages, data.filterLogic, data.highlightKeywordLanguages]);

  const onValuesChange = (changedValues: typeof initialValues): void => {
    const toggleValue = changedValues.internationalSearch ? [Language.en] : [];
    const values =
      data.filterLogic === FilterLogic.Filter
        ? { filterKeywordLanguages: toggleValue }
        : { highlightKeywordLanguages: toggleValue };
    updateKeywordSearchLanguages(values);
  };

  return (
    <div className={styles.internationalSearchToggle}>
      <div className={styles.labelGroup}>
        <span>{t('Tenders.Keywords.englishSearch')}</span>
        <InfoIcon desc={t('Tenders.Keywords.englishSearchInfo')} />
      </div>
      <Form form={form} initialValues={initialValues} onValuesChange={onValuesChange} className={styles.switchField}>
        <Form.Item name="internationalSearch" valuePropName={'checked'}>
          <Switch className={styles.toggle} />
        </Form.Item>
      </Form>
    </div>
  );
};

export default KeywordsInternationalSearchToggle;
