import React, { FC, useMemo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { Dropdown, Tooltip } from 'src/common';
import { useUpdateBidTasks } from 'src/models/bids/BidTask/hooks';
import { TaskFulfillment } from 'src/models/bids/BidTask/types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FULFILLMENT_MAPPER } from 'src/models/bids/BidTask/mappers';
import { TaskTrackPickerData, trackTaskUpdate } from 'src/segment/events';
import { BidTask } from 'src/models/bids/BidTask';

interface Props {
  questions: BidTask[];
  groupId: string;
  taskValue?: TaskFulfillment | null;
  className?: string;
  disabled?: boolean;
  trackData?: TaskTrackPickerData;
  setActionsActive?: (actionActive: boolean) => void;
}
const DEFAULT_FULFILLMENT = { value: null, label: 'Common.notSet' };
export const TaskFulfillmentPicker: FC<Props> = ({
  questions,
  groupId,
  taskValue,
  className,
  disabled,
  trackData,
  setActionsActive
}) => {
  const { t } = useTranslation();
  const [menuVisible, setMenuVisible] = useState(false);
  const onVisibleChange = useCallback(() => {
    setMenuVisible(prevState => !prevState);
    setActionsActive && setActionsActive(!menuVisible);
  }, [menuVisible, setActionsActive]);

  const [updateTasks] = useUpdateBidTasks();
  const values = useMemo(
    () => [
      DEFAULT_FULFILLMENT,
      ...Object.values(TaskFulfillment).map(type => {
        const label = Object.keys(TaskFulfillment).find(
          key => TaskFulfillment[key as keyof typeof TaskFulfillment] === type
        );
        return {
          value: type,
          label: label ? `BiddingTool.TaskFulfillment.${label}` : DEFAULT_FULFILLMENT.label
        };
      })
    ],
    []
  );
  const currentValue = useMemo(
    () => values.find(value => value.value === taskValue) ?? DEFAULT_FULFILLMENT,
    [taskValue, values]
  );
  const onChangeFulfillment = useCallback(
    (fulfillment: TaskFulfillment | null) => {
      if (trackData) {
        trackTaskUpdate({ ...trackData, taskProp: 'task stage', value: fulfillment ?? undefined });
      }
      updateTasks({
        questions: questions.filter(item => item.fulfilment !== fulfillment),
        groupId,
        fulfilment: fulfillment
      });
      onVisibleChange();
    },
    [trackData, updateTasks, questions, groupId, onVisibleChange]
  );

  const disabledIcon = useMemo(
    () =>
      currentValue.value
        ? FULFILLMENT_MAPPER[currentValue.value].disabledIcon
        : FULFILLMENT_MAPPER['null'].disabledIcon,
    [currentValue.value]
  );

  const defaultIcon = useMemo(
    () =>
      currentValue.value ? FULFILLMENT_MAPPER[currentValue.value].defaultIcon : FULFILLMENT_MAPPER['null'].defaultIcon,
    [currentValue.value]
  );

  return (
    <div className={classNames(styles.container, { [styles.isDisabled]: disabled }, className)}>
      <Dropdown
        trigger={['click']}
        overlay={
          <div className={styles.overlay}>
            <div className={styles.content}>
              {values.map(({ value, label }) => (
                <button
                  key={value}
                  onClick={() => onChangeFulfillment(value)}
                  className={classNames(styles.button, {
                    [styles.isActive]: taskValue === value
                  })}
                >
                  <FontAwesomeIcon
                    icon={value ? FULFILLMENT_MAPPER[value].defaultIcon : FULFILLMENT_MAPPER['null'].defaultIcon}
                    style={{ color: value ? FULFILLMENT_MAPPER[value].color : FULFILLMENT_MAPPER['null'].color }}
                  />
                  <span>{t(label)}</span>
                </button>
              ))}
            </div>
          </div>
        }
        onVisibleChange={onVisibleChange}
        visible={menuVisible}
        placement={'bottomRight'}
        getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
        disabled={disabled}
      >
        <Tooltip title={t(currentValue.label)}>
          <button className={styles.button}>
            <FontAwesomeIcon
              icon={disabled && disabledIcon ? disabledIcon : defaultIcon}
              style={{
                color: disabled
                  ? '#BCBCC2'
                  : currentValue.value
                  ? FULFILLMENT_MAPPER[currentValue.value].color
                  : FULFILLMENT_MAPPER['null'].color
              }}
            />
          </button>
        </Tooltip>
      </Dropdown>
    </div>
  );
};

export default TaskFulfillmentPicker;
