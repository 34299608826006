import React, { FC } from 'react';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';
import NavSidebarLink from '../NavSidebarLink';

type Props = { href: string; onClick?: () => void; isDisabled?: boolean; isExpanded?: boolean; iconOnly?: boolean };
export const BackButton: FC<Props> = ({ href, onClick, isDisabled, isExpanded = true, iconOnly }) => {
  const { t } = useTranslation();

  return (
    <NavSidebarLink
      to={href}
      onClick={onClick}
      icon={{ defaultIcon: faArrowLeft, activeIcon: faArrowLeft }}
      title={!!iconOnly ? undefined : t('Common.back')}
      isDisabled={isDisabled}
      isActive={false}
      isExpanded={isExpanded}
    />
  );
};

export default BackButton;
