import React, { useCallback, useState } from 'react';
import { FC } from 'react';
import { Button, Tag } from 'src/common';
import { useBidPreview } from 'src/models/bids/BidPreview/hooks';
import styles from './index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsFromDottedLine, faArrowsToDottedLine, faPlus } from '@fortawesome/pro-light-svg-icons';
import DecisionMatrixQuickActions from './DecisionMatrixQuickActions';
import MatrixItem from './MatrixItem';
import { useTranslation } from 'react-i18next';
import {
  useCreateDecisionMatrixItem,
  useDecisionMatrixScores,
  useIsBidStatusInteresting
} from 'src/models/decisionMatrix/hooks';
import MatrixComment from './MatrixComment';
import MatrixScore from './MatrixScore';
import { FeatureFlag } from '@tendium/prom-types';
import { useFeatureFlag } from 'src/helpers';

const getColorSchema = (avgScore?: number): string | undefined => {
  if (avgScore === undefined) return undefined;

  const roundedScore = Math.floor(avgScore);

  if (roundedScore <= 2) return 'danger';
  if (roundedScore === 3) return 'yellow';
  return 'success';
};

export const DecisionMatrix: FC = () => {
  const { t } = useTranslation();

  const isDecisionMatrixFeature = useFeatureFlag(FeatureFlag.Bidding_DecisionMatrix);

  const { data: bidPreviewData } = useBidPreview();
  const decisionMatrix = bidPreviewData?.decisionMatrix;

  const isBidStatusInterestingCat = useIsBidStatusInteresting(bidPreviewData?.status.id, bidPreviewData?.workspaceId);
  const [createDecisionMatrixItem] = useCreateDecisionMatrixItem();
  const { avgScore } = useDecisionMatrixScores(decisionMatrix);

  const [isOpen, setIsOpen] = useState(isBidStatusInterestingCat);
  const [initEditId, setInitEditId] = useState<string | undefined>(undefined);

  const colorSchema = getColorSchema(avgScore);

  const toggleMatrix = useCallback(() => {
    return setIsOpen(prev => !prev);
  }, []);

  const onComplete = useCallback((id: string) => {
    setInitEditId(id);
  }, []);

  return decisionMatrix && isDecisionMatrixFeature ? (
    <div className={styles.container}>
      <div className={styles.heading}>
        <Button className={styles.titleButton} type={'text'} onClick={toggleMatrix}>
          <div className={styles.headingInner}>
            <div className={styles.titleContainer}>
              <div className={styles.title}>{decisionMatrix.title}</div>
              {!isBidStatusInterestingCat && (
                <>
                  {!bidPreviewData.isRejected && (
                    <Tag
                      className={styles.tag}
                      size="s"
                      label={t('DecisionMatrix.go')}
                      labelColor="var(--ui-success)"
                      bgColor="var(--ui-success-light)"
                      borderColor="var(--ui-success)"
                    />
                  )}
                  {avgScore && (
                    <Tag
                      className={styles.tag}
                      size="s"
                      label={`${t('DecisionMatrix.avgScore')} ${avgScore}`}
                      labelColor={`var(--ui-${colorSchema})`}
                      bgColor={`var(--ui-${colorSchema}-light)`}
                      borderColor={`var(--ui-${colorSchema})`}
                    />
                  )}
                </>
              )}
            </div>
            <FontAwesomeIcon icon={isOpen ? faArrowsToDottedLine : faArrowsFromDottedLine} />
          </div>
        </Button>
        <DecisionMatrixQuickActions />
      </div>
      {isOpen && (
        <>
          <div>
            {decisionMatrix.items.map(item => (
              <MatrixItem
                item={item}
                key={item.id}
                matrixId={decisionMatrix.id}
                initEdit={item.id === initEditId}
                onExitInitEdit={() => setInitEditId(undefined)}
              />
            ))}
            <Button
              onClick={() => createDecisionMatrixItem(decisionMatrix.id, '', onComplete)}
              type={'default'}
              icon={<FontAwesomeIcon icon={faPlus} />}
              className={styles.addItemButton}
            >
              {t('DecisionMatrix.addItem')}
            </Button>
          </div>
          <MatrixScore />
          <MatrixComment />
        </>
      )}
    </div>
  ) : null;
};

export default DecisionMatrix;
