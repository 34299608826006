import { BidspaceQpVars } from './types';
import { BidItemType, WorkspaceStatusCategory } from '@tendium/prom-types/tender';
import { BidTextSearch, BidTextSearchField, GetBidsFilters } from '@tendium/prom-types/schema';
import { isString, isStrings, notUndefined } from 'src/helpers';
import { ApolloCache } from '@apollo/client';
export function isWorkspaceStatusCategory(u: unknown): u is WorkspaceStatusCategory {
  return isString(u) && Object.values(WorkspaceStatusCategory).some(c => c === u);
}

export function isWorkspaceStatusCategories(u: unknown): u is WorkspaceStatusCategory[] {
  return Array.isArray(u) && u.some(item => isWorkspaceStatusCategory(item));
}

export function isBidItemType(u: unknown): u is BidItemType {
  return isString(u) && Object.values(BidItemType).some(c => c === u);
}

export function isBidItemTypes(u: unknown): u is BidItemType[] {
  return Array.isArray(u) && u.some(item => isBidItemType(item));
}

export const evictBidStatisticsCache = <T = unknown>(cache: ApolloCache<T>): void => {
  cache.evict({ fieldName: 'getBidCategoryStatistics' });
  cache.gc();
};

export function sumTotalBidsValue(currentVal: number | undefined, term: number): number | undefined {
  return notUndefined(currentVal) ? currentVal + term : undefined;
}

export function subsTotalBidsValue(currentVal: number | undefined, term: number): number | undefined {
  return notUndefined(currentVal) ? currentVal - term : undefined;
}

export function isBidspaceFiltersApplied(filter?: BidspaceQpVars): boolean {
  return (
    !!filter?.assignedTo?.length ||
    !!filter?.bidType?.length ||
    !!filter?.bidspaceId?.length ||
    !!filter?.category?.length ||
    !!filter?.search?.length
  );
}
export function transformQpVars(vars: BidspaceQpVars): GetBidsFilters {
  return {
    statusCategories: isWorkspaceStatusCategories(vars.category) ? vars.category : undefined,
    bidItemTypes: isBidItemTypes(vars.bidType) ? vars.bidType : undefined,
    workspaceIds: isStrings(vars.bidspaceId) ? vars.bidspaceId : undefined
  };
}

export function transformAssignedToQpVars(vars: BidspaceQpVars): string[] | undefined {
  return isStrings(vars.assignedTo) ? vars.assignedTo : undefined;
}

export function transformSearchQpVars(search: string | undefined): BidTextSearch | undefined {
  return { fields: [BidTextSearchField.Title, BidTextSearchField.Buyer], text: search ?? '' };
}
