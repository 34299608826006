import { z } from 'zod';
import {
  isTenderVarsDiff,
  tendersSearchSchema,
  TendersSearchSchemaKeyType,
  TendersSearchSchemaType
} from '../tendersSearchSchema';

export const buyerFilterSchema = tendersSearchSchema.pick({
  buyerOrgIds: true,
  buyerSearchStrings: true,
  procuringAgencyTypes: true
});
export type BuyerFilterSchemaType = z.infer<typeof buyerFilterSchema>;

export const buyerFilterKeys = Object.keys(buyerFilterSchema.shape) as TendersSearchSchemaKeyType[];

export function isBuyerFilterDiff(vars1?: TendersSearchSchemaType, vars2?: TendersSearchSchemaType): boolean {
  return isTenderVarsDiff(buyerFilterKeys, vars1, vars2);
}
