import { gql } from '@apollo/client/core';
import { USER_NAME_FIELDS } from 'src/models/users/queries';
import { COMMENT_FIELDS } from 'src/models/comments/queries';
import { ASSIGNED_TO_FIELDS } from 'src/models/users/AssignedTo/queries';
import { DECISION_MATRIX_FIELDS_FULL } from 'src/models/decisionMatrix/fragments';

export const BID_GROUP_FIELDS = gql`
  fragment bidQuestionGroupFields on ProcurementBidQuestionGroup {
    id
    title
    filePath
    bid {
      id
    }
    rank
  }
`;

export const BIDDING_FIELDS = gql`
  fragment biddingFields on BidV2 {
    id
    bidStageId
    decisionMatrix {
      ...decisionMatrixFieldsFull
    }
    createdAt
    createdBy {
      ...userNameFields
    }
    comments {
      ...commentFields
    }
    status {
      id
      name
    }
    assignedTo {
      ...assignedToFields
    }
    workspace {
      id
      name
      color
    }
    itemType
    itemId
    questionGroups {
      ...bidQuestionGroupFields
    }
    comments {
      ...commentFields
    }
  }
  ${USER_NAME_FIELDS}
  ${BID_GROUP_FIELDS}
  ${COMMENT_FIELDS}
  ${ASSIGNED_TO_FIELDS}
  ${DECISION_MATRIX_FIELDS_FULL}
`;
