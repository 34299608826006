import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList } from '@fortawesome/pro-light-svg-icons';
import { Skeleton, useLanguage } from 'src/common';
import { KPI } from 'src/common/KPI';
import { KPIs } from 'src/common/KPIs';
import { useBidspaceBANSStatistics } from 'src/models/bids/Bids/hooks';
import { formatCurrencyValue } from 'src/models/procurements/Tender/helpers';
import useCurrency from 'src/common/hooks/useCurrency';

export const BidspacesStatistic: FC = () => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const { data, loading } = useBidspaceBANSStatistics(); // Returns amount in passed in currency
  const { currency } = useCurrency();

  return loading ? (
    <Skeleton loading active />
  ) : data?.statistics ? (
    <KPIs>
      {data?.statistics.map(({ key, value }) => (
        <KPI
          key={key}
          name={t(`BidSpaces.WorkspaceStatusCategory.${key}`)}
          icon={<FontAwesomeIcon icon={value.icon.defaultIcon ?? faList} style={{ color: value.icon.color }} />}
          count={value.count}
          additional={formatCurrencyValue(value.amount, language, currency)}
        />
      ))}
    </KPIs>
  ) : null;
};

export default BidspacesStatistic;
