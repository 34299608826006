import { makeVar } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { useReactiveVariable } from 'src/helpers/reactiveVariables';
import {
  DEFAULT_CANVAS_GROUP_GENERATION_STATUS,
  DEFAULT_CANVAS_ITERATION_CONTENT_STATUS,
  DEFAULT_CANVAS_TASK_GENERATION_STATUS
} from 'src/models/canvas/constants';
import {
  CanvasGroupGenerationStatus,
  CanvasIterationContentStatus,
  CanvasTaskGenerationStatus,
  UpdateCanvasStatusParams,
  UseCanvasGenerationStatusReturn
} from 'src/models/canvas/types';

export const taskStatusesVar = makeVar<CanvasTaskGenerationStatus>(DEFAULT_CANVAS_TASK_GENERATION_STATUS);
export const groupIdStatusVar = makeVar<CanvasGroupGenerationStatus>(DEFAULT_CANVAS_GROUP_GENERATION_STATUS);
export const iterateContentStatusVar = makeVar<CanvasIterationContentStatus>(DEFAULT_CANVAS_ITERATION_CONTENT_STATUS);

function useCanvasGenerationStatus(): UseCanvasGenerationStatusReturn {
  const [taskStatuses] = useReactiveVariable(taskStatusesVar);
  const [groupIdStatus] = useReactiveVariable(groupIdStatusVar);
  const [iterateContentStatus, setIterateContentStatus] = useReactiveVariable(iterateContentStatusVar);

  const updateCanvasStatus = useCallback(
    ({ taskId, groupId, iterateContentId, iterationPendingContent, status }: UpdateCanvasStatusParams) => {
      const isUpdateTaskStatus = taskId !== undefined && !iterateContentId;
      const isUpdateGroupStatus = groupId !== undefined;
      const isUpdateIterateContentStatus = iterateContentId !== undefined;

      if (isUpdateTaskStatus) {
        taskStatusesVar({
          ...taskStatusesVar(), // Get the latest state directly
          [taskId]: status
        });
        return;
      }

      if (isUpdateGroupStatus) {
        groupIdStatusVar({ groupId, status });
        return;
      }

      if (isUpdateIterateContentStatus) {
        iterateContentStatusVar({
          iterateContentId,
          status,
          iterationPendingContent: iterationPendingContent ?? '',
          iterationContentTaskId: taskId
        });
        return;
      }
    },
    []
  );

  const resetIterationContentStatus = useCallback(() => {
    setIterateContentStatus(DEFAULT_CANVAS_ITERATION_CONTENT_STATUS);
  }, [setIterateContentStatus]);

  return useMemo(() => {
    return {
      canvasStatus: {
        taskStatuses,
        groupIdStatus,
        iterateContentStatus
      },
      updateCanvasStatus,
      resetIterationContentStatus
    };
  }, [taskStatuses, groupIdStatus, iterateContentStatus, updateCanvasStatus, resetIterationContentStatus]);
}

export default useCanvasGenerationStatus;
