import { safeCallSegment } from './helpers';
import { trackIdentify } from './events';
import { v4 } from 'uuid';
import { UserType } from 'src/models/auth';
import { StorageKey } from 'src/types/enums';
import { ownership } from 'src/models/ownership';
import * as sessionReplay from '@amplitude/session-replay-browser';

export default function identifyUser(
  email: string,
  userMeta: {
    name: string;
    lastName: string;
    userType: UserType;
    ownership?: string;
  }
): void {
  const sessionReplayProperties = sessionReplay.getSessionReplayProperties();
  safeCallSegment(() => {
    analytics.identify(email, {
      email,
      name: userMeta.name,
      lastName: userMeta.lastName,
      domain: ownership.name,
      userType: userMeta.userType,
      ownership: userMeta.ownership,
      ...sessionReplayProperties
    });

    trackIdentify();
  });
}

let skipAnalyticsCheck = import.meta.env.MODE === 'development';
let warned = false;

let promAnonymousUserId = localStorage.getItem(StorageKey.AnonUserId);

if (!promAnonymousUserId) {
  promAnonymousUserId = v4();
  localStorage.setItem(StorageKey.AnonUserId, promAnonymousUserId);
}

export async function getAnonymousUserId(): Promise<string | null> {
  try {
    if (!skipAnalyticsCheck) {
      // if analytics script is broken by an adblocker we will not receive onReady event, so fallback is wait(500)
      await Promise.race([waitAnalyticsReady(), wait(500)]);
      skipAnalyticsCheck = true;
    }

    const user = analytics?.user();
    if (!user && !warned) {
      console.warn('Analytics is disabled, can not get anonymousUserId. ');
      console.debug('Fallback value', promAnonymousUserId);

      warned = true;
    }

    return user?.anonymousId() ?? promAnonymousUserId;
  } catch (e) {
    console.error(e);
    return null;
  }
}

async function waitAnalyticsReady(): Promise<void> {
  await new Promise(resolve => {
    // ensure analytics is loaded and .user is existing
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    analytics.ready(resolve);
  });
}

async function wait(ms: number): Promise<void> {
  await new Promise(resolve => {
    setTimeout(resolve, ms);
  });
}
