import { UpdateNotificationSettingsInput } from 'src/models/users/NotificationSettings/types';
import { trackWithSessionReplay } from '../helpers';

export function trackClickOnNotificationsList(): void {
  trackWithSessionReplay('Click on notifications list', {});
}

export function trackClickOnShowAllNotifications(): void {
  trackWithSessionReplay('Click on show all notifications', {});
}

export function trackOpenNotificationFromEmail(
  procurement: {
    id: string;
    name?: string;
  },
  comment: { id: string }
): void {
  trackWithSessionReplay('Open comment notification from email', {
    procurement,
    comment
  });
}

export function trackOpenInAppNotification(
  procurement: {
    id: string;
    name?: string;
  },
  comment: { id: string }
): void {
  trackWithSessionReplay('Open comment notification in app', {
    procurement,
    comment
  });
}

export function trackNotificationsDropdown(
  pageSource: string,
  notificationsOptions: UpdateNotificationSettingsInput
): void {
  const { itemId, schedule, mode, value, name } = notificationsOptions;
  trackWithSessionReplay('Dropdown notifications', {
    eventSource: pageSource,
    id: itemId,
    schedule,
    mode,
    value,
    typeOfNotification: name
  });
}
