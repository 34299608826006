import React, { FC } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';

interface Props {
  name: string;
  value?: string;
  className?: string;
}
export const BlockKeyValue: FC<Props> = ({ name, value, className }) => {
  return (
    <div key={value} className={classNames(styles.block, !!className && className)}>
      <span className={styles.kye}>{name}</span>
      {value && <span className={styles.value}>{value}</span>}
    </div>
  );
};

export default BlockKeyValue;
