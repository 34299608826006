import React, { FC } from 'react';
import { Input } from 'src/common/Input';
import { InputProps, InputRef } from 'antd/lib/input/Input';

export interface NumberInputProps
  extends Omit<InputProps & React.RefAttributes<InputRef>, 'type' | 'onKeyDown' | 'onWheel'> {
  disabledKeys?: string[];
}

export const NumberInput: FC<NumberInputProps> = React.forwardRef(({ disabledKeys, ...restProps }, ref) => {
  const DisabledKeys = [',', 'e', 'E', 'ArrowDown', 'ArrowUp', ...(disabledKeys ?? [])];
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (DisabledKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  const onWheel = (e: React.WheelEvent<HTMLInputElement>): void => {
    if (e.currentTarget) {
      e.currentTarget.blur();
      e.stopPropagation();
      setTimeout(() => {
        e.currentTarget.focus();
      }, 0);
    }
  };

  return <Input {...restProps} ref={ref} type={'number'} onKeyDown={onKeyDown} onWheel={onWheel} />;
});

NumberInput.displayName = 'NumberInput';

export default NumberInput;
