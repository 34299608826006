import React, { FC } from 'react';
import { useOverviewBackLink } from 'src/models/procurements/hooks';
import { BackButton } from 'src/common';

interface Props {
  iconOnly?: boolean;
}

export const BackToButton: FC<Props> = ({ iconOnly }) => {
  const { backLink, reset } = useOverviewBackLink();

  return <BackButton href={backLink} onClick={reset} iconOnly={iconOnly} />;
};

export default BackToButton;
