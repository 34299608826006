import { gql } from '@apollo/client';
import { FILE_NODE_FIELDS } from 'src/models/documentsLibrary/fragments';

export const ATTACH_FILES_TO_BID = gql`
  mutation attachFilesToBid($fileNames: [String!]!, $bidId: String!) {
    attachFilesToBid(input: { fileNames: $fileNames, bidId: $bidId }) {
      document {
        ...fileNodeFields
        putUrl
      }
      hasChunkedData
      id
    }
  }
  ${FILE_NODE_FIELDS}
`;

export const GET_BID_ATTACHMENTS = gql`
  query getBidAttachments($bidId: String!) {
    getBidAttachments(input: { bidId: $bidId }) {
      document {
        ...fileNodeFields
      }
      hasChunkedData
      id
    }
  }
  ${FILE_NODE_FIELDS}
`;

export const GET_BID_ATTACHMENTS_STATUS = gql`
  query getBidAttachmentsStatus($bidId: String!) {
    getBidAttachments(input: { bidId: $bidId }) {
      id
      hasChunkedData
    }
  }
`;

export const REMOVE_BID_ATTACHMENT = gql`
  mutation removeBidAttachment($id: String!) {
    removeBidAttachment(input: { id: $id })
  }
`;
