import { ApiTenderFiles } from './types';
import { toDocsSubCats } from '../Tender/helpers';
import { IProcurementFilesCategory } from '../Tender/types';
import { FileCategory } from '../types';

export class TenderFilesData {
  public readonly id: string;
  public readonly filesCats: IProcurementFilesCategory;
  public readonly tenderDocuments: IProcurementFilesCategory;

  constructor(public readonly apiTenderFiles: ApiTenderFiles) {
    this.id = this.apiTenderFiles.id;
    this.filesCats = this.toFilesCat();
    this.tenderDocuments = this.toTenderDocumentsFilesCat();
  }
  private toFilesCat(): IProcurementFilesCategory {
    return toDocsSubCats(this.apiTenderFiles.fileCategories);
  }

  private toTenderDocumentsFilesCat(): IProcurementFilesCategory {
    return toDocsSubCats(
      this.apiTenderFiles.fileCategories.filter(fileCategory => fileCategory.name === FileCategory.TenderDocuments)
    );
  }
}
