import { Upload as BaseUpload } from 'antd';
import { DraggerProps } from 'antd/lib/upload';
import React, { FC, PropsWithChildren } from 'react';

// FIXME: we should create our own Upload component to encapsulate ant-design and make it easier to replace later.
//  But forms is a too complex topic and I'm not ready to predict what should be allowed to use from ant-design and what should not.
//   So, at least for a while, it is ok to use the whole API. Later with getting experience (use cases), we will be able to make a decision.
// https://ant.design/components/form/v3/
const DraggerComponent = BaseUpload.Dragger;

const Dragger: FC<PropsWithChildren & DraggerProps> = ({ children, ...rest }) => {
  return <DraggerComponent {...rest}>{children}</DraggerComponent>;
};

export { Dragger };
export default Dragger;
