import React, { FC, useCallback, useMemo } from 'react';
import styles from './index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { faUserLock } from '@fortawesome/pro-regular-svg-icons';
import { IApiComment, CommentParentType } from 'src/models/comments/types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useMe, confirm, UserName } from 'src/common';
import { useDeleteComment } from 'src/models/comments/hooks';
import { isUser } from 'src/models/users/types';
import modalStyles from 'src/common/Modal/index.module.scss';
import { User } from 'src/models/users';
import { useIsMobile } from 'src/reactiveVars';

export interface Props {
  comment: IApiComment;
  isHighlighted?: boolean;
  parentType: CommentParentType;
}

const CommentItem: FC<Props> = (props: Props) => {
  const { comment, isHighlighted = false, parentType } = props;
  const { id, content, user: apiUser, createdAt, isRead, parentId, sharedWith } = comment;
  const { t } = useTranslation();
  const [isMobile] = useIsMobile();

  const { data: meData } = useMe();
  const currentEmail = meData?.email;
  const user = useMemo(() => (isUser(apiUser) ? new User(apiUser) : apiUser), [apiUser]);
  const [deleteComment] = useDeleteComment();

  const isAuthor = useMemo(() => {
    return user && user.email === currentEmail;
  }, [currentEmail, user]);

  const metaInfo = t('Common.Format.dateFormat', { date: new Date(createdAt) });

  const onDelete = useCallback(async () => {
    confirm.confirm({
      title: t('Comments.deleteCommentConfirmTitle'),
      content: t('Comments.confirmDeleteComment'),
      okText: t('Common.delete'),
      cancelText: t('Common.cancel'),
      className: modalStyles.confirmModal,
      onOk() {
        deleteComment(id, parentId, parentType);
      }
    });
  }, [deleteComment, id, parentId, parentType, t]);

  const userName = useMemo(() => {
    return user ? (isUser(user) ? user.userName : user.email) : t('Comments.deletedUser');
  }, [t, user]);

  return (
    <li
      className={classNames(styles.commentItem, {
        [styles.isRead]: isRead || isAuthor,
        [styles.isHighlighted]: isHighlighted
      })}
      id={id}
    >
      <div className={styles.commentInner}>
        {isUser(user) ? (
          <UserName mode={'onlyAvatar'} user={user} className={styles.commentPic} />
        ) : (
          <span className={styles.commentPic}>
            <FontAwesomeIcon icon={faUserLock} className={styles.commentIcon} fixedWidth />
          </span>
        )}
        <div className={styles.commentContent}>
          <div className={styles.commentHeader}>
            <div className={styles.commentInfo}>
              <span className={styles.commentAuthor}>{userName}</span>
              <span className={styles.commentMeta}>{metaInfo}</span>
            </div>
            {isAuthor && !isMobile && (
              <div className={styles.commentActions}>
                <button className={styles.deleteButton} onClick={onDelete}>
                  <FontAwesomeIcon icon={faTrash} className={styles.deleteIcon} />
                </button>
              </div>
            )}
          </div>
          {!!sharedWith.length && (
            <div className={styles.shared}>
              <span className={styles.sharedLabel}>{t('Comments.sharedTo')}</span>
              <span className={styles.sharedValue}>{sharedWith.map(shared => shared.email).join(', ')}</span>
            </div>
          )}
          <div className={styles.commentBody}>
            <p className={styles.commentText} dangerouslySetInnerHTML={{ __html: content }}></p>
          </div>
        </div>
      </div>
    </li>
  );
};

export default CommentItem;
