import { gql } from '@apollo/client';

export const PROMPT_BATTERY_GROUPED_DOCUMENT_POSITIONS_FIELDS = gql`
  fragment promptBatteryGroupedDocumentPositionsFields on GroupedDocumentPositions {
    page
    referenceIds
    documentPositions {
      page
      start {
        x
        y
      }
      end {
        x
        y
      }
      pageSize {
        width
        height
      }
    }
  }
`;

export const PROMPT_BATTERY_QUESTION_FIELDS = gql`
  fragment promptBatteryQuestionFields on Question {
    id
    question
    companyId
    isLocal
  }
`;

export const PROMPT_BATTERY_ANSWER_FIELDS = gql`
  fragment promptBatteryAnswerFields on Answer {
    id
    answer
    status
    groupedReferences {
      displayName
      filePath
      groupedDocumentPositions {
        page
        referenceIds
      }
    }
  }
`;

export const PROMPT_BATTERY_QUESTION_ANSWER_FIELDS = gql`
  fragment promptBatteryQuestionAnswerFields on QuestionAnswer {
    id
    procurementId
    createdAt
    question {
      ...promptBatteryQuestionFields
    }
    answer {
        ...promptBatteryAnswerFields
    }
  }
  ${PROMPT_BATTERY_QUESTION_FIELDS},
  ${PROMPT_BATTERY_ANSWER_FIELDS}
`;

export const CREATE_PROMPT_BATTERY_QUESTION = gql`
  mutation createPromptBatteryQuestion($question: String!) {
    createPromptBatteryQuestion(input: { question: $question }) {
      ...promptBatteryQuestionFields
    }
  }
  ${PROMPT_BATTERY_QUESTION_FIELDS}
`;

export const UPDATE_PROMPT_BATTERY_QUESTION = gql`
  mutation updatePromptBatteryQuestion($id: String!, $question: String!) {
    updatePromptBatteryQuestion(input: { id: $id, question: $question }) {
      ...promptBatteryQuestionFields
    }
  }
  ${PROMPT_BATTERY_QUESTION_FIELDS}
`;

export const DELETE_PROMPT_BATTERY_QUESTION = gql`
  mutation deletePromptBatteryQuestion($id: String!) {
    deletePromptBatteryQuestion(input: { id: $id })
  }
`;

export const GET_PROMPT_BATTERY_QUESTIONS = gql`
  query getPromptBatteryQuestions {
    getPromptBatteryQuestions {
      ...promptBatteryQuestionFields
    }
  }
  ${PROMPT_BATTERY_QUESTION_FIELDS}
`;

export const GET_PROMPT_BATTERY_QUESTION = gql`
  query getPromptBatteryQuestion($id: String!) {
    getPromptBatteryQuestion(input: { id: $id }) {
      ...promptBatteryQuestionFields
    }
  }
  ${PROMPT_BATTERY_QUESTION_FIELDS}
`;

export const GET_PROMPT_BATTERY_ANSWERS = gql`
  query getPromptBatteryAnswers($procurementId: String!) {
    getPromptBatteryAnswers(input: { procurementId: $procurementId }) {
      ...promptBatteryQuestionAnswerFields
    }
  }
  ${PROMPT_BATTERY_QUESTION_ANSWER_FIELDS}
`;

export const GENERATE_PROMPT_BATTERY_ANSWERS = gql`
  mutation generatePromptBatteryAnswers($procurementId: String!) {
    generatePromptBatteryAnswers(input: { procurementId: $procurementId }) {
      ...promptBatteryQuestionAnswerFields
    }
  }
  ${PROMPT_BATTERY_QUESTION_ANSWER_FIELDS}
`;

export const CREATE_PROMPT_BATTERY_LOCAL_QUESTION = gql`
  mutation createPromptBatteryProcurementSpecificQuestionAnswer($procurementId: String!, $question: String!) {
    createPromptBatteryProcurementSpecificQuestionAnswer(
      input: { procurementId: $procurementId, question: $question }
    ) {
      ...promptBatteryQuestionAnswerFields
    }
  }
  ${PROMPT_BATTERY_QUESTION_ANSWER_FIELDS}
`;

export const GET_PROMPT_BATTERY_ANSWER = gql`
  query getPromptBatteryAnswer($id: String!) {
    getPromptBatteryAnswer(input: { id: $id }) {
      ...promptBatteryQuestionAnswerFields
    }
  }
  ${PROMPT_BATTERY_QUESTION_ANSWER_FIELDS}
`;

export const CONVERT_PROMPT_BATTERY_QUESTION_TO_GLOBAL = gql`
  mutation ConvertPromptBatteryQuestionToGlobal($id: String!) {
    convertPromptBatteryQuestionToGlobal(input: { id: $id }) {
      ...promptBatteryQuestionAnswerFields
    }
  }
  ${PROMPT_BATTERY_QUESTION_ANSWER_FIELDS}
`;

export const ON_PROMPT_BATTERY_GENERATE_ANSWER = gql`
  subscription MarsAnswer {
    marsAnswer {
      questionAnswerId
      status
      procurementId
      answerData {
        content
        order
      }
    }
  }
`;

export const COUNT_PROMPT_BATTERY_QUESTIONS_ASKED_THIS_YEAR = gql`
  query CountPromptBatteryQuestionsAskedThisYear {
    countPromptBatteryQuestionsAskedThisYear {
      count
      limit
    }
  }
`;

export const PROMPT_BATTERY_CHUNK_STATUS = gql`
  query GetPromptBatteryChunkStatus($procurementId: String!) {
    getPromptBatteryChunkStatus(input: { procurementId: $procurementId }) {
      chunkExists
    }
  }
`;

export const PROMPT_BATTERY_GROUPED_DOCUMENT_POSITIONS = gql`
  query getPromptBatteryGroupReferences($input: GetPromptBatteryGroupReferencesInput!) {
    getPromptBatteryGroupReferences(input: $input) {
      ...promptBatteryGroupedDocumentPositionsFields
    }
  }
  ${PROMPT_BATTERY_GROUPED_DOCUMENT_POSITIONS_FIELDS}
`;
