import React, { FC, ReactNode } from 'react';
import { InfoIcon } from 'src/common';
import styles from './index.module.scss';
import classNames from 'classnames';

interface Props {
  title: string;
  desc?: ReactNode;
  className?: string;
}

export const InfoLabel: FC<Props> = ({ title, desc, className }) => {
  return (
    <span className={classNames(styles.label, className)}>
      <span>{title}</span>
      {desc && <InfoIcon desc={desc} />}
    </span>
  );
};

export default InfoLabel;
