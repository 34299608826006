import splitByQuotation from 'src/helpers/splitByQuotation';
import { MatchingProfile } from '../../matchingProfiles';
import { ApiTendersVars } from '../Tenders/types';
import { toKeywordGroups, toSupplierIds, toSupplierStrings } from './helpers';
import { MpVars } from '../../matchingProfiles/types';
import {
  buyerFilterSchema,
  BuyerFilterSchemaType,
  contractEndFilterSchema,
  ContractEndFilterSchemaType,
  contractValueFilterSchema,
  ContractValueFilterSchemaType,
  cpvCodeFilterSchema,
  CpvCodeFilterSchemaType,
  keywordFilterSchema,
  KeywordFilterSchemaType,
  nutsCodeFilterSchema,
  NutsCodeFilterSchemaType,
  supplierFilterSchema,
  SupplierFilterSchemaType,
  TendersSearchSchemaType
} from './schemas';
import { isEmptyObject } from 'src/helpers/objectFilter';
import { toContractEnd } from '../../matchingProfiles/helpers';

export function profileToSearchVars(profile?: MatchingProfile): TendersSearchSchemaType | undefined {
  if (!profile) return;

  const contractValueMin = profile.contractValueMin ?? undefined;
  const contractValueMax = profile.contractValueMax ?? undefined;
  const contractValueCurrency = profile.contractValueCurrency ?? undefined;
  const contractValueFilterData: ContractValueFilterSchemaType = {
    contractValueRange:
      contractValueMin || contractValueMax || contractValueCurrency
        ? {
            min: contractValueMin,
            max: contractValueMax,
            currency: contractValueCurrency
          }
        : undefined,
    contractValueExcludeMissingValue: profile.contractValueExcludeMissingValue ?? undefined
  };

  const contractEndFilterData: ContractEndFilterSchemaType = {
    contractEnd: profile.contractEnd ?? undefined
  };

  const nutsCodeFilterData: NutsCodeFilterSchemaType = {
    nutsCodes: profile.nutsCodes ?? [],
    nutsCodesExact: profile.nutsCodesExact ?? []
  };

  const cpvCodeFilterData: CpvCodeFilterSchemaType = {
    cpvCodes: profile.cpvCodes ?? [],
    cpvCodesExact: profile.cpvCodesExact ?? []
  };

  const buyerFilterData: BuyerFilterSchemaType = {
    buyerOrgIds: profile.buyerOrgIds ?? [],
    buyerSearchStrings: profile.buyerSearchStrings ?? [],
    procuringAgencyTypes: profile.buyerTypes ?? []
  };

  const supplierFilterData: SupplierFilterSchemaType = {
    supplierOrgIds: toSupplierIds(profile.supplierSearchTerms) ?? [],
    supplierSearchStrings: toSupplierStrings(profile.supplierSearchTerms) ?? [],
    supplierSearchLogic: profile.supplierSearchLogic ?? undefined
  };

  const keywordFilterData: KeywordFilterSchemaType = {
    keywordArgs: profile.apiKeywords ?? [],
    keywordGroups: toKeywordGroups(profile.apiKeywordGroups) ?? [],
    keywordWidth: profile.keywordWidth ?? undefined,
    filterKeywordLanguages: profile.filterKeywordLanguages ?? [],
    highlightKeywordLanguages: profile.highlightKeywordLanguages ?? []
  };

  const result = {
    ...(keywordFilterSchema.safeParse(keywordFilterData)?.data ?? undefined),
    ...(buyerFilterSchema.safeParse(buyerFilterData)?.data ?? undefined),
    ...(supplierFilterSchema.safeParse(supplierFilterData)?.data ?? undefined),
    ...(cpvCodeFilterSchema.safeParse(cpvCodeFilterData)?.data ?? undefined),
    ...(nutsCodeFilterSchema.safeParse(nutsCodeFilterData)?.data ?? undefined),
    ...(contractValueFilterSchema.safeParse(contractValueFilterData)?.data ?? undefined),
    ...(contractEndFilterSchema.safeParse(contractEndFilterData)?.data ?? undefined)
  };

  if (isEmptyObject(result)) return undefined;

  return result;
}

/**
 * Mapping from tenders search vars to getTenders vars
 */
export function searchVarsToGetTendersApiVars(vars: TendersSearchSchemaType): ApiTendersVars {
  return {
    advanced: {
      assignedTo: vars.assignedTo,
      awarded: vars.awarded,
      buyerOrgIds: vars.buyerOrgIds,
      buyerSearchStrings: vars.buyerSearchStrings,
      contractEnd: toContractEnd(vars.contractEnd),
      contractValueRange: vars.contractValueRange,
      cpvCodesExact: vars.cpvCodesExact,
      keywordWidth: vars.keywordWidth,
      filterKeywordLanguages: vars.filterKeywordLanguages,
      highlightKeywordLanguages: vars.highlightKeywordLanguages,
      keywordGroups: vars.keywordGroups?.map(group => ({ ...group, groupId: undefined })),
      keywordArgs: vars.keywordArgs,
      nutsCodesExact: vars.nutsCodesExact,
      onlyStatus: vars.onlyStatus,
      procuringAgencyTypes: vars.procuringAgencyTypes,
      publicationDate: vars.published
        ? {
            start: vars.published[0],
            end: vars.published[1]
          }
        : undefined,
      search: vars.search ? splitByQuotation(vars.search) : undefined,
      submissionDeadline: vars.deadline
        ? {
            start: vars.deadline[0],
            end: vars.deadline[1]
          }
        : undefined,
      supplierOrgIds: vars.supplierOrgIds,
      supplierSearchLogic: vars.supplierSearchLogic,
      supplierSearchStrings: vars.supplierSearchStrings,
      contractValueExcludeMissingValue: vars.contractValueExcludeMissingValue,
      cpvCodes: vars.cpvCodes,
      nutsCodes: vars.nutsCodes,
      partyFilter: vars.partyFilter,
      /**
       * isPlanningProcess: true - Only include PINs
       * isPlanningProcess: false - Exclude PINs
       * isPlanningProcess: undefined - Don't filter on PINs
       */
      isPlanningProcess: vars.isPlanningProcess
    },
    sortOnField: vars.sort,
    amount: vars.amount,
    offset: vars.offset,
    matchingProfileId: vars.profileId,
    /**
     * isUnhandled: true - only unhandled
     * isUnhandled: false - only handled
     * isUnhandled: undefined - all
     */
    isUnhandled: vars.onlyUnhandled ? true : undefined,
    isStarred: vars.isStarred
  };
}

/**
 * Mapping from tenders search vars to saved search filter vars
 * - Used for create or update saved search
 */
export function searchVarsToSavedSearchProfile(vars: TendersSearchSchemaType): MpVars {
  return {
    cpvCodes: vars.cpvCodes,
    cpvCodesExact: vars.cpvCodesExact,
    nutsCodes: vars.nutsCodes,
    nutsCodesExact: vars.nutsCodesExact,
    filterKeywordLanguages: vars.filterKeywordLanguages,
    highlightKeywordLanguages: vars.highlightKeywordLanguages,
    keywordWidth: vars.keywordWidth,
    keywordGroups: vars.keywordGroups?.map(group => ({ ...group, groupId: undefined })),
    keywords: vars.keywordArgs,
    contractValueRange: vars.contractValueRange,
    contractValueExcludeMissingValue: vars.contractValueExcludeMissingValue,
    contractEnd: toContractEnd(vars.contractEnd),
    buyerOrgIds: vars.buyerOrgIds,
    buyerSearchStrings: vars.buyerSearchStrings,
    buyerTypes: vars.procuringAgencyTypes,
    // only saved search
    supplierOrgIds: vars.supplierOrgIds,
    supplierSearchStrings: vars.supplierSearchStrings,
    supplierSearchLogic: vars.supplierSearchLogic
  };
}

/**
 * Mapping from a profile to tenders search vars
 * {
 *   "query": {
 *      amount: undefined, number
 *      offset: undefined, number
 *      sortOnField: { // undefined
 *        key: one of enum value
 *        sort: one of enum value
 *      },
 *      matchingProfileId: undefined, ['some id'],
 *      isStarred: true, false, undefined
 *      isUnhandled: true, false, undefined
 *   },
 *   "advanced": {
 *     "assignedTo": undefined, ['some id'],
 *     "awarded": undefined, true, false
 *     "buyerOrgId":  // only one usage in billing project. Not related here
 *     "buyerOrgIds": undefined, ['some id'],
 *     "buyerSearchStrings": undefined, ['some string']
 *     "contractEnd": { // undefined
 *       "searchMode": should have default value
 *       "rangeMode": should have default value
 *       "start": undefined, date // rangeMode: absolute
 *       "end": undefined, date // rangeMode: absolute
 *       "relativeStart": undefined, +int/0 rangeMode: relative
 *       "relativeEnd": undefined, +int/0 rangeMode: relative
 *       "strictPeriodOverlap": undefined, true, false, searchMode: overlap,
 *       "contractDurationYearsFallback": undefined, 100, searchMode: overlap, strictPeriodOverlap: true
 *     },
 *     "contractValueExcludeMissingValue": undefined, true
 *     "contractValueRange": { // undefined
 *       "currency": undefined, some currency from the list
 *       "min": undefined, +number/0
 *       "max": undefined, +number/0
 *     },
 *     "cpvCodes": undefined, ['some string']
 *     "cpvCodesExact": undefined, ['some string']
 *     "documentSearch": no usage, double check deprecation
 *     "filterKeywordLanguages": undefined, ['some string']
 *     "highlight": no usage,
 *     "highlightKeywordLanguages": undefined, ['some string']
 *     "keywordArgs": { // undefined
 *       "filterLogic": undefined, one of enum value
 *       "highlightColor": undefined, one of enum value
 *       "searchLogic": undefined, one of enum value
 *       "searchType": undefined, one of enum value
 *       "value": "some value", string
 *     },
 *     "keywordGroups": { // undefined
 *       "filterLogic": undefined, one of enum value
 *       "highlightColor": undefined, one of enum value
 *       "searchLogic": undefined, one of enum value
 *       "searchType": undefined, one of enum value
 *       "values": ["some value"], string
 *     },
 *     "keywordWidth": "broad" or "narrow"
 *     "keywords": no usage
 *     "name": undefined, "some string" // no usage
 *     "nutsCodes": undefined, ['some string']
 *     "nutsCodesExact": undefined, ['some string']
 *     "onlyFilledData": no usage,
 *     "onlyStatus": deps on tender status,
 *     "partyFilter": debt
 *     "procuringAgency": no usage, deprecated
 *     "procuringAgencyTypes": undefined, [one of enum value]
 *     "publicationDate": { // undefined
 *       "start": epoch
 *       "end": epoch
 *     },
 *     "search": undefined, ['some string'],
 *     "status": no usage, double check
 *     "submissionDeadline": { // undefined
 *       "start": epoch
 *       "end": epoch
 *     },
 *     "supplierSearchLogic": undefined, AND
 *   }
 * }
 */
