import React, { FC, useCallback, useState, useRef } from 'react';
import styles from './index.module.scss';
import { EditableText } from 'src/shared';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-light-svg-icons';
import { faPen as faPenRegular } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { BoxFieldString } from 'src/models/procurements';
import { IBoxFieldString } from 'src/models/procurements/Tender/types';
import { useTask, useUpdateBidTasks } from 'src/models/bids/BidTask/hooks';
import { Button, Skeleton } from 'src/common';
import { getCanvasURL } from 'src/models/canvas/helpers';
import { useNavigate } from 'react-router';
import { trackBTOpenEditor } from 'src/segment/events/canvas';

interface BidTaskHeaderProps {
  isDraft?: boolean;
}

export const BidTaskHeader: FC<BidTaskHeaderProps> = ({ isDraft = false }) => {
  const { data: task, loading } = useTask();
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [updateTasks] = useUpdateBidTasks();

  const [editMode, setEditMode] = useState(false);

  const navigate = useNavigate();

  const canvasUrl = getCanvasURL(task?.bidId ?? '', task?.groupId ?? '', task?.id);

  const onEditResponse = (): void => {
    trackBTOpenEditor({ eventSource: 'Task' });
    navigate(canvasUrl);
  };

  const onSubmit = useCallback(
    (field: IBoxFieldString) => {
      task &&
        task.groupId &&
        updateTasks({
          questions: [task],
          groupId: task.groupId,
          title: field.string ?? ''
        });
      setEditMode(false);
    },
    [task, updateTasks]
  );

  return loading ? (
    <Skeleton />
  ) : (
    <>
      {task && (
        <div className={styles.title}>
          {editMode ? (
            <EditableText
              className={styles.editableName}
              containerRef={ref}
              onChange={onSubmit}
              onFinish={() => setEditMode(false)}
              field={new BoxFieldString(task.title ?? '', task.title)}
              autoSize={true}
              autoFocus
            />
          ) : (
            <div className={styles.header}>
              <div className={styles.plainName} onClick={() => setEditMode(true)}>
                <span className={styles.text}>{!!task.title ? task.title : t('BiddingTool.newTaskPlaceholder')}</span>
                <FontAwesomeIcon icon={faPen} className={styles.editIcon} />
              </div>
              {!isDraft && (
                <Button className={styles.editBtn} type={'primary'} onClick={onEditResponse}>
                  <FontAwesomeIcon icon={faPenRegular} className={styles.pencilIcon} />
                  {t('BiddingTool.openEditor')}
                </Button>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default BidTaskHeader;
