import React, { FC, ReactNode } from 'react';
import styles from './index.module.scss';
import { Tooltip } from 'src/common';
import { faInfoCircle, IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

interface Props {
  title: string;
  info?: string;
  actions?: ReactNode;
  icon?: IconDefinition;
}

export const ToolboxHeader: FC<Props> = props => {
  const { title, info, actions, icon } = props;

  return (
    <div
      className={classNames(styles.heading, {
        [styles.hasActions]: actions
      })}
    >
      {icon && <FontAwesomeIcon icon={icon} className={styles.headerIcon} />}
      {title}
      {info && (
        <Tooltip title={info}>
          <FontAwesomeIcon icon={faInfoCircle} className={classNames(styles.icon, styles.infoIcon)} />
        </Tooltip>
      )}

      {actions && <div className={styles.actions}>{actions}</div>}
    </div>
  );
};

export default ToolboxHeader;
