export interface ApiDecisionMatrix {
  comment?: string;
  id: string;
  items: DecisionMatrixItem[];
  title?: string;
}

export interface DecisionMatrixItem {
  id: string;
  score: number;
  title: string;
}

export interface DecisionMatrixTemplateItem {
  id: string;
  title: string;
}

export interface ApiDecisionMatrixTemplate extends Omit<ApiDecisionMatrix, 'comment' | 'items'> {
  items: DecisionMatrixTemplateItem[];
}

export interface ApiDecisionMatrixTemplateResponse {
  getDecisionMatrixTemplates: ApiDecisionMatrix[];
}

export interface CreateDecisionMatrixItemResponse {
  createDecisionMatrixItem: ApiCreateDecisionMatrixItem;
}

export interface ApiCreateDecisionMatrixItem {
  id: string;
  score: number;
  title: string;
  __typename: 'DecisionMatrixItem';
}

export interface DeleteDecisionMatrixItemResponse {
  deleteDecisionMatrixItem: boolean;
}

export type UpdateBidDecisionMatrixInput = Omit<ApiDecisionMatrix, 'items'>;

export enum TendiumDefaultTemplateId {
  DEFAULT_TEMPLATE_ID = 'DEFAULT_TEMPLATE_ID'
}

export interface DeleteDecisionMatrixTemplateResponse {
  deleteDecisionMatrixTemplate: boolean;
}

export interface ApplyDecisionMatrixTemplateResponse {
  applyDecisionMatrix: ApiDecisionMatrix & {
    __typename: 'DecisionMatrix';
  };
}

export interface CreateDecisionMatrixTemplate {
  items: { title: string }[];
  title: string;
}

export interface CreateDecisionMatrixTemplateResponse {
  createDecisionMatrixTemplate: ApiDecisionMatrix & {
    id: string;
    title: string;
    __typename: 'DecisionMatrixTemplate';
  };
}

export const DECISION_MATRIX_ITEM_SCORES = [1, 2, 3, 4, 5];
export const DefaultItemPrefix = 'DEFAULT_TENDIUM_MATRIX_ITEM_TITLE_';

export enum DecisionMatrixModals {
  ManageTemplates = 'ManageTemplates',
  SaveAsTemplate = 'SaveAsTemplate'
}
