import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleInfo, IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons';

import styles from './index.module.scss';
import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useBidDraftUrl } from 'src/models/procurements/hooks';

type NotificationType = 'success' | 'loading' | 'noTasks';

interface Props {
  type: NotificationType;
  bidId?: string;
}

interface NotificationConfig {
  icon: IconDefinition;
  title: string;
  message: string;
  showLink?: boolean;
  iconClass?: string;
}

const notificationConfig: Record<NotificationType, NotificationConfig> = {
  success: {
    icon: faCircleCheck,
    title: 'BidSpaces.CreateTaskNotification.SuccessTitle',
    message: 'BidSpaces.CreateTaskNotification.SuccessMessage',
    showLink: false,
    iconClass: styles.success
  },
  loading: {
    icon: faSpinnerThird,
    title: 'BidSpaces.CreateTaskNotification.LoadingTitle',
    message: 'BidSpaces.CreateTaskNotification.LoadingMessage',
    showLink: false,
    iconClass: styles.loading
  },
  noTasks: {
    icon: faCircleInfo,
    title: 'BidSpaces.CreateTaskNotification.NoTasksTitle',
    message: 'BidSpaces.CreateTaskNotification.NoTasksMessage',
    showLink: true,
    iconClass: styles.noTasks
  }
};

const CreateTaskNotification: React.FC<Props> = ({ type, bidId }) => {
  const { icon, title, message, iconClass, showLink } = notificationConfig[type];
  const { t } = useTranslation();
  const bidDraftUrl = useBidDraftUrl(bidId ?? '');

  return (
    <div className={styles.notificationContainer}>
      <div className={styles.notificationHeader}>
        <FontAwesomeIcon icon={icon} className={classNames(styles.icon, iconClass)} />
        <span>{t(title)}</span>
      </div>
      <div className={styles.notificationDesc}>
        <span className={styles.message}>{t(message)}</span>
        {showLink && (
          <a href={bidDraftUrl} className={styles.link}>
            {t('BidSpaces.CreateTaskNotification.LinkText')}
          </a>
        )}
      </div>
    </div>
  );
};

export default CreateTaskNotification;
