import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { useBidPreview } from 'src/models/bids/BidPreview/hooks';
import { BlockLabel } from '..';
import { UserName } from 'src/common';
import ReadOnlyBlockDate from '../Blocks/Date/ReadOnlyBlock';

export const BidPreviewCreation: FC = () => {
  const { t } = useTranslation();
  const { data } = useBidPreview();

  return (
    <div className={styles.container}>
      {<BlockLabel label={t('BidPreview.overviewCreationHeader')} />}
      <div className={styles.creationContainer}>
        {data?.createdBy && <UserName className={styles.name} user={data?.createdBy} mode="onlyFullName" size="s" />}
        <ReadOnlyBlockDate className={styles.createdAt} date={data?.createdAt} />
      </div>
    </div>
  );
};

export default BidPreviewCreation;
