import { useQuery } from '@apollo/client';
import { useContext, useMemo } from 'react';
import { GET_TENDER_NAV } from './queries';
import { useLocation } from 'react-router-dom';
import { Paths } from 'src/pages/paths';
import { TenderNavContextData, TenderNavResponseData } from './types';
import TenderNavData from '.';
import { TenderNavContext } from './context';

type TenderNavResponse = { readonly getTender: TenderNavResponseData | null };
type TenderNavRequest = { id: string };

export function useTenderNavData(): TenderNavContextData {
  const { pathname } = useLocation();
  const [, tenderPathName] = Paths.TENDER_ROUTE.split('/');
  const [, routeSlug, pageSlug] = pathname.split('/');
  const isTender = tenderPathName === routeSlug;

  const tenderId = useMemo(() => (isTender ? pageSlug : undefined), [isTender, pageSlug]);

  const { data: tenderData, loading } = useQuery<TenderNavResponse, TenderNavRequest>(GET_TENDER_NAV, {
    variables: { id: tenderId ?? '' },
    skip: !tenderId
  });

  const data = useMemo(
    () => (tenderData as { readonly getTender: TenderNavResponseData | null })?.getTender ?? undefined,
    [tenderData]
  );

  return useMemo(() => ({ data: data ? new TenderNavData(data) : null, loading }), [data, loading]);
}

export function useTenderNav(): TenderNavContextData {
  return useContext(TenderNavContext);
}
