import { z } from 'zod';
import {
  isTenderVarsDiff,
  tendersSearchSchema,
  TendersSearchSchemaKeyType,
  TendersSearchSchemaType
} from '../tendersSearchSchema';

export const searchFilterSchema = tendersSearchSchema.pick({ search: true });
export type SearchFilterSchemaType = z.infer<typeof searchFilterSchema>;
export const searchFilterKeys = Object.keys(searchFilterSchema.shape) as TendersSearchSchemaKeyType[];

export function isSearchFilterDiff(vars1?: TendersSearchSchemaType, vars2?: TendersSearchSchemaType): boolean {
  return isTenderVarsDiff(searchFilterKeys, vars1, vars2);
}
