import React, { FC, useCallback } from 'react';
import classNames from 'classnames';
import styles from '../../index.module.scss';
import { SearchFieldTag } from 'src/common';
import { HighlightColorDict } from 'src/models/matchingProfiles/types';
import { HighlightColor } from '@tendium/prom-types';
import { useKeywords } from 'src/models/keywords/hooks';
import { trackMPEditKeywordDelete } from 'src/segment/events';

interface IPreferencesKeywordsItemProps {
  keywords: { values: { id: string; value: string }[]; groupId: string | null };
  highlightColor: HighlightColor;
  disabled: boolean;
}

export const KeywordsItem: FC<IPreferencesKeywordsItemProps> = props => {
  const { keywords, disabled, highlightColor } = props;
  const { data, deleteKeywords } = useKeywords();

  const onRemove = useCallback(() => {
    deleteKeywords([keywords]);
    trackMPEditKeywordDelete(data.eventNamePrefix, data.filterLogic, 'single', data.mp);
  }, [data.eventNamePrefix, data.filterLogic, data.mp, deleteKeywords, keywords]);

  return (
    <li className={classNames(styles.keywordsItem, disabled ? styles.isDisabled : '')}>
      <SearchFieldTag
        value={keywords.values}
        color={HighlightColorDict[highlightColor]}
        disabled={disabled}
        onRemove={onRemove}
      />
    </li>
  );
};

export default KeywordsItem;
