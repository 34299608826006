import React, { FC } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';

interface Props {
  width?: string;
  height?: string;
  variant?: 'rect' | 'circle';
  className?: string;
}

const Skeleton: FC<Props> = ({ width = '100%', height = '22px', variant = 'rect', className }) => {
  return <div className={classNames(styles.skeleton, styles[variant], className)} style={{ width, height }} />;
};

export default Skeleton;
