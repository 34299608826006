import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, ReactNode } from 'react';
import styles from './index.module.scss';
import Dropdown from '../Dropdown';
import Button from '../Button';

interface Props {
  mainAction: () => void;
  mainTitle: string;
  dropdownItems: ReactNode;
  menuVisible: boolean;
  toggleMenu: () => void;
}

export const ButtonGroupDropdown: FC<Props> = ({ mainAction, mainTitle, menuVisible, toggleMenu, dropdownItems }) => {
  return (
    <div className={styles.buttonsContainer}>
      <Button className={styles.mainButton} onClick={mainAction} type={'default'}>
        {mainTitle}
      </Button>
      <Dropdown
        trigger={['click']}
        placement="bottomRight"
        visible={menuVisible}
        onVisibleChange={toggleMenu}
        overlay={<div className={styles.dropdownContainer}>{dropdownItems}</div>}
      >
        <Button
          className={styles.chevronButton}
          icon={menuVisible ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
          type={'default'}
        />
      </Dropdown>
    </div>
  );
};
export default ButtonGroupDropdown;
