import { IIcon } from 'src/types/icon';
import { BiddingStats } from './types';
import { faCheckDouble, faList, faPen, faSeedling, faUser, faUserCheck } from '@fortawesome/pro-light-svg-icons';

export const GROUP_COLORS = ['#0C62D4', '#F759AB', '#52C41A', '#9254DE', '#FF0000', '#FAAD14'];

export const STATISTIC_MAPPER: Record<keyof BiddingStats, IIcon> = {
  totalTasks: { defaultIcon: faList, color: '#d3adf7' },
  unAssigned: { defaultIcon: faUser, color: '#dbdbdd' },
  todo: { defaultIcon: faSeedling, color: '#dbdbdd' },
  inProgress: { defaultIcon: faPen, color: '#ffe58f' },
  review: { defaultIcon: faUserCheck, color: '#d4d9ff' },
  done: { defaultIcon: faCheckDouble, color: '#b7eb8f' }
};
export const STATISTIC_ORDER: (keyof BiddingStats)[] = [
  'totalTasks',
  'unAssigned',
  'todo',
  'inProgress',
  'review',
  'done'
];
