import { gql } from '@apollo/client';
import { ASSIGNED_TO_FIELDS } from '../users/AssignedTo/queries';

export const CODE_FIELDS = gql`
  fragment codeFields on Code {
    code
  }
`;

export const KEYWORD_GROUP_BASE_FIELDS = gql`
  fragment keywordGroupBaseFields on KeywordIdAndValue {
    id
    value
  }
`;

export const KEYWORD_BASE_FIELDS = gql`
  fragment keywordsBaseFields on KeywordDTO {
    id
    value
  }
`;

export const KEYWORD_FIELDS = gql`
  fragment keywordsFields on KeywordDTO {
    ...keywordsBaseFields
    searchType
    searchLogic
    filterLogic
    highlightColor
  }
  ${KEYWORD_BASE_FIELDS}
`;

export const KEYWORD_GROUPS_FIELDS = gql`
  fragment keywordGroupsFields on KeywordGroupDTO {
    values {
      ...keywordGroupBaseFields
    }
    searchType
    searchLogic
    filterLogic
    highlightColor
    groupId
  }
  ${KEYWORD_GROUP_BASE_FIELDS}
`;

export const MP_FIELDS = gql`
  fragment matchingProfileFields on MatchingProfile {
    id
    name
    owners {
      ...assignedToFields
    }
    subscribers {
      ...assignedToFields
    }
    accessToEdit
    accessControl {
      editPolicy
      subscribePolicy
    }
    bTagIds
    nutsCodes
    nutsCodesExact
    cpvCodes
    cpvCodesExact
    buyerOrgIds
    buyerSearchStrings
    keywordWidth
    filterKeywordLanguages
    highlightKeywordLanguages
    keywordGroups {
      ...keywordGroupsFields
    }
    keywords {
      ...keywordsFields
    }
    profileType
    supplierSearchTerms {
      id
      searchType
      status
      value
    }
    supplierSearchLogic
    buyerTypes
    contractValueCurrency
    contractValueMin
    contractValueMax
    contractValueExcludeMissingValue
    contractEnd {
      rangeMode
      searchMode
      start
      end
      relativeStart
      relativeEnd
    }
    matchingProfileEmbedding {
      id
      text
    }
    companies @client
  }
  ${KEYWORD_FIELDS}
  ${ASSIGNED_TO_FIELDS}
  ${KEYWORD_GROUPS_FIELDS}
`;

export const MP_SHORT_FIELDS = gql`
  fragment matchingProfileShortFields on MatchingProfile {
    id
    name
    profileType
  }
`;

export const UPDATE_MP_POLICY = gql`
  mutation updateMatchingProfile($id: String!, $policy: AccessControlPolicy!) {
    updateMatchingProfile(id: $id, valuesToBeUpdated: { accessControl: { subscribePolicy: $policy } }) {
      id
      accessControl {
        editPolicy
        subscribePolicy
      }
    }
  }
`;

export const UPDATE_MP_OWNERS_POLICY = gql`
  mutation updateMatchingProfile($id: String!, $policy: AccessControlPolicy!) {
    updateMatchingProfile(id: $id, valuesToBeUpdated: { accessControl: { editPolicy: $policy } }) {
      id
      accessControl {
        editPolicy
        subscribePolicy
      }
    }
  }
`;

export const UPDATE_MP_OWNERS = gql`
  mutation updateMatchingProfile($id: String!, $owners: [String!]) {
    updateMatchingProfile(id: $id, valuesToBeUpdated: { owners: $owners, accessControl: { editPolicy: ONLY_ADDED } }) {
      id
      owners {
        ...assignedToFields
      }
      subscribers {
        ...assignedToFields
      }
    }
  }
  ${ASSIGNED_TO_FIELDS}
`;

export const UPDATE_MP_SUBSCRIBERS = gql`
  mutation updateMatchingProfile($id: String!, $subscribers: [String!]) {
    updateMatchingProfile(id: $id, valuesToBeUpdated: { subscribers: $subscribers }) {
      id
      subscribers {
        ...assignedToFields
      }
      owners {
        ...assignedToFields
      }
    }
  }
  ${ASSIGNED_TO_FIELDS}
`;

export const MOVE_MP_MEMBERS = gql`
  mutation updateMatchingProfile($id: String!, $owners: [String!], $subscribers: [String!]) {
    updateMatchingProfile(id: $id, valuesToBeUpdated: { subscribers: $subscribers, owners: $owners }) {
      id
      subscribers {
        ...assignedToFields
      }
      owners {
        ...assignedToFields
      }
    }
  }
  ${ASSIGNED_TO_FIELDS}
`;

export const SEARCH_REGIONS = gql`
  query searchNutCodes($input: CodesSearchDTO!) {
    searchNutCodes(input: $input) {
      ...codeFields
      children {
        ...codeFields
        children {
          ...codeFields
          children {
            ...codeFields
            children {
              ...codeFields
              children {
                ...codeFields
              }
            }
          }
        }
      }
    }
  }
  ${CODE_FIELDS}
`;

export const GET_NUTS_FILTER = gql`
  query getNutsFilter {
    getNutsFilter {
      codes: nutsCodes
      codesExact: nutsCodesExact
    }
  }
`;

export const UPDATE_MP_NUTS_CODES = gql`
  mutation updateMatchingProfile($id: String!, $nutsCodes: [String!], $nutsCodesExact: [String!]) {
    updateMatchingProfile(id: $id, valuesToBeUpdated: { nutsCodes: $nutsCodes, nutsCodesExact: $nutsCodesExact }) {
      id
      nutsCodes
      nutsCodesExact
    }
  }
`;

export const UPDATE_MP_CPV_CODES = gql`
  mutation updateMatchingProfile($id: String!, $cpvCodes: [String!], $cpvCodesExact: [String!]) {
    updateMatchingProfile(id: $id, valuesToBeUpdated: { cpvCodes: $cpvCodes, cpvCodesExact: $cpvCodesExact }) {
      id
      cpvCodes
      cpvCodesExact
    }
  }
`;

export const SEARCH_CPV_CODES = gql`
  query searchCpvCodes($input: CodesSearchDTO!) {
    searchCpvCodes(input: $input) {
      ...codeFields
      children {
        ...codeFields
        children {
          ...codeFields
          children {
            ...codeFields
            children {
              ...codeFields
              children {
                ...codeFields
                children {
                  ...codeFields
                  children {
                    ...codeFields
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${CODE_FIELDS}
`;

export const UPDATE_MP_BTAGS = gql`
  mutation updateMatchingProfile($id: String!, $bTagIds: [String!]) {
    updateMatchingProfile(id: $id, valuesToBeUpdated: { bTagIds: $bTagIds }) {
      id
      bTagIds
    }
  }
`;

export const UPDATE_MP_KEYWORD_WIDTH = gql`
  mutation updateMatchingProfile($id: String!, $keywordWidth: KeywordWidth!) {
    updateMatchingProfile(id: $id, valuesToBeUpdated: { keywordWidth: $keywordWidth }) {
      id
      keywordWidth
    }
  }
`;

export const UPDATE_MP_FILTER_KEYWORD_LANGUAGE = gql`
  mutation updateMatchingProfile(
    $id: String!
    $filterKeywordLanguages: [Language!]
    $highlightKeywordLanguages: [Language!]
  ) {
    updateMatchingProfile(
      id: $id
      valuesToBeUpdated: {
        filterKeywordLanguages: $filterKeywordLanguages
        highlightKeywordLanguages: $highlightKeywordLanguages
      }
    ) {
      id
      filterKeywordLanguages
      highlightKeywordLanguages
    }
  }
`;

export const CREATE_MP_MULTIPLE_KEYWORD_GROUPS = gql`
  mutation createMultipleKeywordGroups(
    $matchingProfileId: String!
    $values: [[String!]!]!
    $filterLogic: KeywordsFilterLogic
  ) {
    createMultipleKeywordGroups(
      input: { matchingProfileId: $matchingProfileId, values: $values, filterLogic: $filterLogic }
    ) {
      ...keywordGroupsFields
    }
  }
  ${KEYWORD_GROUPS_FIELDS}
`;

export const CREATE_MULTIPLE_MP_KEYWORD = gql`
  mutation createMultipleKeywords($matchingProfileId: String!, $values: [String!]!, $filterLogic: KeywordsFilterLogic) {
    createMultipleKeywords(
      input: { matchingProfileId: $matchingProfileId, values: $values, filterLogic: $filterLogic }
    ) {
      ...keywordsFields
    }
  }
  ${KEYWORD_FIELDS}
`;

export const UPDATE_MP_MULTIPLE_KEYWORDS = gql`
  mutation updateMultipleKeywords(
    $matchingProfileId: String!
    $keywordIds: [String!]!
    $keywordGroupIds: [String!]!
    $searchType: KeywordsSearchType
    $searchLogic: KeywordsSearchLogic
    $highlightColor: KeywordsHighlightColor
  ) {
    updateMultipleKeywords(
      input: {
        matchingProfileId: $matchingProfileId
        keywordIds: $keywordIds
        keywordGroupIds: $keywordGroupIds
        searchLogic: $searchLogic
        searchType: $searchType
        highlightColor: $highlightColor
      }
    ) {
      keywordGroups {
        ...keywordGroupsFields
      }
      keywords {
        ...keywordsFields
      }
    }
  }
  ${KEYWORD_GROUPS_FIELDS}
  ${KEYWORD_FIELDS}
`;

export const DELETE_MP_MULTIPLE_KEYWORDS = gql`
  mutation deleteMultipleKeywords($matchingProfileId: String!, $keywordIds: [String!]!, $keywordGroupIds: [String!]!) {
    deleteMultipleKeywords(
      input: { matchingProfileId: $matchingProfileId, keywordIds: $keywordIds, keywordGroupIds: $keywordGroupIds }
    ) {
      keywordIds
      keywordGroupIds
    }
  }
`;

export const UPDATE_MP_BUYERS = gql`
  mutation updateMatchingProfile($id: String!, $buyerOrgIds: [String!], $buyerSearchStrings: [String!]) {
    updateMatchingProfile(
      id: $id
      valuesToBeUpdated: { buyerOrgIds: $buyerOrgIds, buyerSearchStrings: $buyerSearchStrings }
    ) {
      id
      buyerOrgIds
      buyerSearchStrings
    }
  }
`;

export const UPDATE_MP_BUYER_TYPES = gql`
  mutation updateMatchingProfile($id: String!, $buyerTypes: [BuyerType!]) {
    updateMatchingProfile(id: $id, valuesToBeUpdated: { buyerTypes: $buyerTypes }) {
      id
      buyerTypes
    }
  }
`;

export const UPDATE_MP_RELEVANCE_DESCRIPTION = gql`
  mutation updateMatchingProfile($id: String!, $relevanceDescription: String) {
    updateMatchingProfile(id: $id, valuesToBeUpdated: { relevanceDescription: $relevanceDescription }) {
      id
      matchingProfileEmbedding {
        id
        text
      }
    }
  }
`;

export const UPDATE_MP_CONTRACT_VALUE = gql`
  mutation updateMatchingProfile(
    $id: String!
    $contractValueRange: ContractValueRangeDTO
    $contractValueExcludeMissingValue: Boolean
  ) {
    updateMatchingProfile(
      id: $id
      valuesToBeUpdated: {
        contractValue: $contractValueRange
        contractValueExcludeMissingValue: $contractValueExcludeMissingValue
      }
    ) {
      id
      contractValueCurrency
      contractValueMin
      contractValueMax
      contractValueExcludeMissingValue
    }
  }
`;

export const LOAD_BTAGS = gql`
  query getBTags {
    getBTags {
      id
      name
      description
    }
  }
`;

export const LOAD_ALL_MY_MP = gql`
  query getAllMyMatchingProfiles($input: GetMatchingProfileDTO) {
    getAllMyMatchingProfiles(input: $input) {
      ...matchingProfileFields
    }
  }
  ${MP_FIELDS}
`;

export const LOAD_ALL_MY_MP_IDS = gql`
  query getAllMyMatchingProfilesShort($input: GetMatchingProfileDTO) {
    getAllMyMatchingProfiles(input: $input) {
      ...matchingProfileShortFields
    }
  }
  ${MP_SHORT_FIELDS}
`;

export const LOAD_ALL_SEARCH_PROFILES = gql`
  query getAllSearchProfilesShort {
    getAllSearchProfiles {
      ...matchingProfileShortFields
    }
  }
  ${MP_SHORT_FIELDS}
`;

export const LOAD_MP = gql`
  query getMatchingProfile($id: String!) {
    getMatchingProfile(id: $id) {
      ...matchingProfileFields
    }
  }
  ${MP_FIELDS}
`;

export const LOAD_MP_KEYWORDS = gql`
  query getMatchingProfile($id: String!) {
    getMatchingProfile(id: $id) {
      id
      name
      keywords {
        ...keywordsFields
      }
      keywordGroups {
        ...keywordGroupsFields
      }
    }
  }
  ${KEYWORD_FIELDS}
  ${KEYWORD_GROUPS_FIELDS}
`;

export const CREATE_MP = gql`
  mutation createMatchingProfile(
    $name: String!
    $profileType: ProfileType
    $nutsCodes: [String!]
    $nutsCodesExact: [String!]
    $cpvCodes: [String!]
    $cpvCodesExact: [String!]
    $keywordWidth: KeywordWidth
    $filterKeywordLanguages: [Language!]
    $highlightKeywordLanguages: [Language!]
    $keywordGroups: [NewKeywordGroupDTO!]
    $keywords: [NewKeywordDTO!]
    $buyerOrgIds: [String!]
    $buyerSearchStrings: [String!]
    $buyerTypes: [BuyerType!]
    $supplierOrgIds: [SupplierOrgIdEntry!]
    $supplierSearchStrings: [SupplierSearchStringEntry!]
    $supplierSearchLogic: JunctionLogic
    $contractValueRange: ContractValueRangeDTO
    $contractValueExcludeMissingValue: Boolean
    $contractEnd: ContractEndFilterInput
  ) {
    createMatchingProfile(
      initialValues: {
        name: $name
        profileType: $profileType
        nutsCodes: $nutsCodes
        nutsCodesExact: $nutsCodesExact
        cpvCodes: $cpvCodes
        cpvCodesExact: $cpvCodesExact
        keywordWidth: $keywordWidth
        filterKeywordLanguages: $filterKeywordLanguages
        highlightKeywordLanguages: $highlightKeywordLanguages
        keywordGroups: $keywordGroups
        keywords: $keywords
        buyerOrgIds: $buyerOrgIds
        buyerSearchStrings: $buyerSearchStrings
        buyerTypes: $buyerTypes
        supplierOrgIds: $supplierOrgIds
        supplierSearchStrings: $supplierSearchStrings
        supplierSearchLogic: $supplierSearchLogic
        contractValue: $contractValueRange
        contractValueExcludeMissingValue: $contractValueExcludeMissingValue
        contractEnd: $contractEnd
      }
    ) {
      ...matchingProfileFields
    }
  }
  ${MP_FIELDS}
`;

export const DELETE_MP = gql`
  mutation deleteMatchingProfile($id: String!) {
    deleteMatchingProfile(id: $id)
  }
`;

export const UPDATE_MP_NAME = gql`
  mutation updateMatchingProfile($id: String!, $name: String!) {
    updateMatchingProfile(id: $id, valuesToBeUpdated: { name: $name }) {
      id
      name
    }
  }
`;

export const UPDATE_MP = gql`
  mutation updateMatchingProfile(
    $id: String!
    $nutsCodes: [String!]
    $nutsCodesExact: [String!]
    $cpvCodes: [String!]
    $cpvCodesExact: [String!]
    $keywordWidth: KeywordWidth!
    $keywordGroups: [NewKeywordGroupDTO!]
    $keywords: [NewKeywordDTO!]
    $filterKeywordLanguages: [Language!]
    $highlightKeywordLanguages: [Language!]
    $buyerOrgIds: [String!]
    $buyerSearchStrings: [String!]
    $buyerTypes: [BuyerType!]
    $supplierOrgIds: [SupplierOrgIdEntry!]
    $supplierSearchStrings: [SupplierSearchStringEntry!]
    $supplierSearchLogic: JunctionLogic
    $contractValueRange: ContractValueRangeDTO
    $contractValueExcludeMissingValue: Boolean
    $contractEnd: ContractEndFilterInput
    $relevanceDescription: String
  ) {
    updateMatchingProfile(
      id: $id
      valuesToBeUpdated: {
        nutsCodes: $nutsCodes
        nutsCodesExact: $nutsCodesExact
        cpvCodes: $cpvCodes
        cpvCodesExact: $cpvCodesExact
        keywordWidth: $keywordWidth
        keywordGroups: $keywordGroups
        keywords: $keywords
        filterKeywordLanguages: $filterKeywordLanguages
        highlightKeywordLanguages: $highlightKeywordLanguages
        buyerOrgIds: $buyerOrgIds
        buyerSearchStrings: $buyerSearchStrings
        buyerTypes: $buyerTypes
        supplierOrgIds: $supplierOrgIds
        supplierSearchStrings: $supplierSearchStrings
        supplierSearchLogic: $supplierSearchLogic
        contractValue: $contractValueRange
        contractValueExcludeMissingValue: $contractValueExcludeMissingValue
        contractEnd: $contractEnd
        relevanceDescription: $relevanceDescription
      }
    ) {
      ...matchingProfileFields
    }
  }
  ${MP_FIELDS}
`;
