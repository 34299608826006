import { trackWithSessionReplay } from '../helpers';

export function trackOpenChecklist(bidId: string): void {
  trackWithSessionReplay('Checklist opened', { bidId });
}

export function trackCloseChecklist(bidId: string): void {
  trackWithSessionReplay('Checklist closed', { bidId });
}

export function trackAddChecklistItem(): void {
  trackWithSessionReplay('Checklist item added');
}

export function trackSaveChecklistTemplate(): void {
  trackWithSessionReplay('Checklist template saved');
}

export function trackEmptyChecklist(): void {
  trackWithSessionReplay('Checklist emptied');
}

export function trackUseChecklistTemplate(): void {
  trackWithSessionReplay('Checklist template used');
}

export function trackChecklistCheckmark(status: boolean): void {
  trackWithSessionReplay('Checklist checkmark clicked', { status });
}

export function trackAssignedChecklistItem(bidId: string): void {
  trackWithSessionReplay('Checklist item assigned', { bidId });
}
