import { Dispatch, SetStateAction, createContext } from 'react';
import { Bidding } from '.';
import { TasksFilterArgs } from './types';

import { TasksResponseData } from './types';
import { BidTask } from '../BidTask';

export type TasksState = {
  groupId: string;
  offset: number;
  limit: number;
  tasksResponseData?: TasksResponseData;
  setOffset: (offset: number) => void;
  selectedTasks: BidTask[];
  setSelectedTaskIds: React.Dispatch<React.SetStateAction<string[]>>;
};

export type BiddingContextValue = {
  data: Bidding | null;
  loading: boolean;
  statsLoading: boolean;
};
export const BiddingContext = createContext<BiddingContextValue>({
  data: null,
  loading: false,
  statsLoading: false
});
BiddingContext.displayName = 'BiddingContext';

export const GroupTasksFilterContext = createContext<TasksFilterArgs | undefined>(undefined);
GroupTasksFilterContext.displayName = 'GroupTasksFilterContext';

export const UpdateGroupTasksFilterContext = createContext<Dispatch<
  SetStateAction<TasksFilterArgs | undefined>
> | null>(null);
UpdateGroupTasksFilterContext.displayName = 'UpdateGroupTasksFilterContext';

export const GroupTasksContext = createContext<TasksState | null>(null);
GroupTasksContext.displayName = 'GroupTasksContext';
